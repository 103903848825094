/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { saveForm } from 'reducers/modules/Application/index';
import {
  getApplicationQueryString,
  ppcpPageLoading,
  isFirstPage,
  isLastPage
} from 'reducers/modules/Application/selectors';
import * as ppcp from '../../../constants/PPCP';

class ButtonPanel extends Component {
  onSave = () => {
    const { saveFormAction, history } = this.props;
    saveFormAction({ current: true, history });
  }

  onSaveAndPrevious = (event) => {
    const {
      saveFormAction, history, queryString, prevButtonEnabled
    } = this.props;
    event.preventDefault();
    if (!prevButtonEnabled) {
      return;
    }
    saveFormAction({ goToPrevious: true, queryString, history });
  }

  onSaveAndNext = (event) => {
    const {
      saveFormAction, history, queryString, nextButtonEnabled
    } = this.props;
    event.preventDefault();
    if (!nextButtonEnabled) {
      return;
    }
    saveFormAction({ goToNext: true, queryString, history });
  }

  cancelChanges = (event) => {
    const { history } = this.props;
    event.preventDefault();
    history.go();
  }

  render() {
    const { prevButtonEnabled, nextButtonEnabled, currentform } = this.props;
    if (currentform.name === ppcp.REVIEW_ID || this.props.loading) {
      return null;
    }
    return (
      <>
        <div className="row pad-top text-right">
          <Link to="#" id="cancelChanges" onClick={this.cancelChanges}>
            <span className="cancel-icon" />
            Cancel Changes
          </Link>
        </div>
        <div className="row bg-color-light pad-top text-center">
          {prevButtonEnabled ? <Link to="#" className={prevButtonEnabled ? 'back-button' : 'back-button-disabled'} onClick={this.onSaveAndPrevious} id="saveGoBack">Save and Go Back</Link> : null}
          <button type="button" className="action-button" onClick={this.onSave} id="saveButton">Save</button>
          {nextButtonEnabled ? <Link to="#" className={nextButtonEnabled ? 'forward-button' : 'forward-button-disabled'} onClick={this.onSaveAndNext} id="saveAndContinue">Save and Continue</Link> : null}
        </div>
      </>
    );
  }
}

ButtonPanel.propTypes = {
  prevButtonEnabled: PropTypes.bool,
  nextButtonEnabled: PropTypes.bool,
  saveFormAction: PropTypes.func,
  queryString: PropTypes.string,
  currentform: PropTypes.object,
  forms: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool
};

ButtonPanel.defaultProps = {
  prevButtonEnabled: true,
  nextButtonEnabled: true
};

const mapStateToProps = state => ({
  forms: state.application.applicationLayout.forms,
  currentform: state.application.applicationLayout.currentForm,
  queryString: getApplicationQueryString(state),
  prevButtonEnabled: !isFirstPage(state),
  nextButtonEnabled: !isLastPage(state),
  loading: ppcpPageLoading(state)
});

const mapDispatchToProps = dispatch => ({
  saveFormAction: (args) => dispatch(saveForm.base(args)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ButtonPanel));
