import { combineReducers } from 'redux';
import autoUpgradeAdminReducer from './AutoUpgradeAgencyAdmin/AutoUpgradeAdminReducer';
import userProfileNavigationReducer from './UserNavigation/userNavigation';

export {
  autoUpgradeActionSaga,
  watchAutoUpgradeActionSaga,
  autoUpgradeAction,
  declineUpgradeActionSaga,
  watchDeclineUpgradeActionSaga,
  declineUpgradeAction,
  watchCheckRolePromotionSaga,
  checkRolePromotionSaga,
  checkRolePromotion,
  hideRolePromoteModal
} from './AutoUpgradeAgencyAdmin/AutoUpgradeAdminReducer';

export {
  watchuserProfileNavigationActionSaga,
  userProfileNavigationAction
} from './UserNavigation/userNavigation';

const auth = combineReducers({
  autoUpgradeAdminReducer,
  userProfileNavigation: userProfileNavigationReducer
});

export default auth;
