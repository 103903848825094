import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import fetchEntity from '../../../utils/fetchEntitySaga';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export const FETCH_PRODUCT_SELECT_LIST = createFetchTypes('Marketplace/Profile/SalesReport/FETCH_PRODUCT_SELECT_LIST');

export const productSelectListReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_PRODUCT_SELECT_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null
      };
    case FETCH_PRODUCT_SELECT_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };
    case FETCH_PRODUCT_SELECT_LIST.FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    default:
      return state;
  }
};

export const fetchProductSelectList = {
  base: (params) => ({
    type: FETCH_PRODUCT_SELECT_LIST.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_PRODUCT_SELECT_LIST.REQUEST
  }),
  success: (data) => ({
    type: FETCH_PRODUCT_SELECT_LIST.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_PRODUCT_SELECT_LIST.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchProductSelectListSaga(action) {
  try {
    const { params } = action.payload;
    yield call(() => fetchEntity(fetchProductSelectList, ApiClient.httpClient.get, apiConfig.GET_PRODUCTS_SELECT_LIST, { params }));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchFetchProductSelectListSaga() {
  yield takeLatest(FETCH_PRODUCT_SELECT_LIST.BASE, fetchProductSelectListSaga);
}
