/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getBuilds,
  unreleaseOption,
  releaseOption,
  deleteUnreleasedVersion,
  getUserDetails,
  enableAutoUpdate,
  clear
} from 'reducers/modules/Administration/BuildVersionControl/buildVersionControl';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import { Link } from 'react-router-dom';
import ReviewVerification from '../ReviewVerification/ReviewVerification';
import CreateVerification from '../CreateVerification/CreateVerification';
import UploadBuild from '../UploadBuild/UploadBuild';
import ToolTip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';

const Badge = ({ text }) => (<span className="label label-success">{text}</span>);

Badge.propTypes = {
  text: PropTypes.string
};

class BuildVersionControl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModalReviewVerification: false,
      showModalCreateVerification: false,
      showModalUploadBuild: false,
      buildRow: {},
    };
  }

  componentDidMount() {
    const { getBuildsAction } = this.props;
    const versionId = utils.getValueFromQueryString(this.props.location, 'versionId');
    getBuildsAction(versionId);
  }

  componentWillUnmount() {
    this.props.clear();
  }

  getDate = (dateString) => (dateString && moment(dateString).format('MM/DD/YYYY') !== '01/01/0001' ? moment(dateString.split('T')[0]).format('MM/DD/YYYY') : '');

  createVerificationOpen = (e, data) => {
    e.preventDefault();
    this.setState({ showModalCreateVerification: true, buildRow: data });
  }

  createVerificationClose = () => this.setState({ showModalCreateVerification: false });

  uploadBuildOpen = () => this.setState({ showModalUploadBuild: true });

  uploadBuildClose = () => this.setState({ showModalUploadBuild: false });

  reviewVerificationOpen = (e, data) => {
    e.preventDefault();
    this.props.getUserDetails(data.verifiedUserName);
    this.setState({ showModalReviewVerification: true, buildRow: data });
  }

  reviewVerificationClose = () => this.setState({ showModalReviewVerification: false });

  unreleaseHandler = (e) => {
    const { unreleaseAction, location } = this.props;
    const versionId = utils.getValueFromQueryString(location, 'versionId');
    e.preventDefault();
    unreleaseAction(versionId);
  }

  releaseHandler = (e, build) => {
    const { releaseAction, posVersionId } = this.props;
    const buildId = build.id;
    const { changeOrderNumber } = build;
    e.preventDefault();
    releaseAction({ buildId, posVersionId, changeOrderNumber });
  }

  deleteUnreleasedVersion = (e, build) => {
    e.preventDefault();
    const buildId = build.id;
    const { versionNumber } = build;
    this.props.deleteUnreleasedVersionAction({ buildId, versionNumber });
  }

  enableAutoUpdate = (buildId, isAutoUpdate) => {
    const { posVersionId } = this.props;
    this.props.enableAutoUpdate({ buildId, isAutoUpdate, posVersionId });
  }

  actionCell = (build, canUpdateBuilds) => (
    <>
      {(!build.dateReleased && canUpdateBuilds) && <a onClick={(e) => this.releaseHandler(e, build)}>Release</a>}
      {(build.dateReleased && !build.isReleased && canUpdateBuilds) && <span>Unreleased</span>}
      {(build.isLatestReleasedBuild && canUpdateBuilds) && <a onClick={(e) => this.unreleaseHandler(e)}>Un&#8209;Release</a>}
      {(!build.dateReleased && canUpdateBuilds) && (
        <span>
          &nbsp;|&nbsp;
          <a onClick={(e) => this.deleteUnreleasedVersion(e, build)}>Delete</a>
        </span>
      )}
    </>
  );

  renderRow = (build, canUpdateBuilds) => {
    const { posversions } = this.props.location.state;
    const autoUpdate = this.enableAutoUpdate;
    const row = posversions && posversions.map(c => {
      if (c.version === build.versionName) {
        return (
          <React.Fragment key={build.id}>
            <tr>
              <td>{build.versionNumber}</td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {build.versionName}
                {build.isCurrentReleasedBuild
                  && <Badge text="Current Product" />}
                {build.isLatestAutoUpdate
                    && <Badge text="Current API" />}
              </td>
              <td>{this.getDate(build.dateAddedToSmartpoint)}</td>
              <td>{this.getDate(build.dateReleased)}</td>
              <td>{build.buildStatusString}</td>
              <td>
                <SimplePrettyCheckBox
                  enabled={build.isReleased}
                  value={build.isAutoUpdateEnabled}
                  displayText=""
                  onChange={(checked) => { autoUpdate(build.id, checked); }}
                  tooltip=""
                />
              </td>
              <td>
                {(build.changeOrderNumber === 0 && canUpdateBuilds)
                  ? <a href="#" onClick={(e) => this.createVerificationOpen(e, build)}>Create Verification</a>
                  : <a href="#" onClick={(e) => this.reviewVerificationOpen(e, build)}>Review Verification</a>}
              </td>
              <td>{this.actionCell(build, canUpdateBuilds)}</td>
              <td>
                {(build.buildStatus === 1) ? (
                  <span>
                    <a onClick={(e) => this.deleteUnreleasedVersion(e, build)}>Delete</a>
                    &nbsp;|&nbsp;
                    <a onClick={(e) => this.releaseHandler(e, build)}>Re-Release</a>
                  </span>
                ) : ''}
              </td>
            </tr>
          </React.Fragment>
        );
      }
      return null;
    });
    return row;
  }

  renderTable = (previousBuilds, canUpdateBuilds) => (
    <div className="row">
      <div className="tablesorter-container">
        <table id="previous-sp-versions-table" className="text-centered-table tablesorter tablesorter-travelport tablesorter-odd-even">
          <thead>
            <tr className="tablesorter-headerRow">
              <th className="sorter-false tablesorter-header">Build #</th>
              <th className="sorter-false tablesorter-header">Version Name</th>
              <th className="sorter-false tablesorter-header">Date Uploaded</th>
              <th className="sorter-false tablesorter-header">Release Date</th>
              <th className="sorter-false tablesorter-header">Status</th>
              <th className="sorter-false tablesorter-header">
                Enabled AutoUpdate
                {' '}
                <ToolTip
                  labelName="selecting this option determines the marketplace version that will be used during the web service call from Smartpoint or any other apps"
                />
              </th>
              <th className="sorter-false tablesorter-header">
                Release Verification
                {' '}
                <ToolTip
                  labelName="Your file needs to be verified before it can be released as a Platform Product downloadable"
                />
              </th>
              <th className="sorter-false tablesorter-header">
                Action
                {' '}
                <ToolTip
                  labelName="You are allowed to delete an UnReleased Build"
                />
              </th>
              <th className="sorter-false tablesorter-header" />
            </tr>
          </thead>
          <tbody>
            {previousBuilds && previousBuilds.map(build => this.renderRow(build, canUpdateBuilds))}
          </tbody>
        </table>
      </div>
    </div>
  )

  render() {
    const {
      previousBuilds,
      canUpdateBuilds,
    } = this.props;
    return (
      <>
        {(this.state.showModalReviewVerification)
          && (
            <ReviewVerification
              showModal={this.state.showModalReviewVerification}
              popUpHide={this.reviewVerificationClose}
              buildRow={this.state.buildRow}
            />
          )}
        {(this.state.showModalUploadBuild)
          && <UploadBuild showModal={this.state.showModalUploadBuild} popUpHide={this.uploadBuildClose} />}
        {(this.state.showModalCreateVerification)
          && (
            <CreateVerification
              showModal={this.state.showModalCreateVerification}
              popUpHide={this.createVerificationClose}
              buildRow={this.state.buildRow}
            />
          )}
        <h2>{`${this.props.location.state.productname}: ${this.props.location.state.posversions[0].version} File Upload`}</h2>
        <div className="container">
          <div className="row">
            <Link to="/admin/distribution"> &lt; Back to Platform Product Version Control</Link>
          </div>
          {this.props.canUpdateBuilds
            && (
              <div className="row">
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="col-sm-12 space-top-sm">
                        Upload Platform Package&nbsp;
                        <button type="button" className="cancel-button space-left-sm no-save" onClick={this.uploadBuildOpen}>Upload</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="row no-space">
            <h4>File Upload History</h4>
          </div>
          {this.renderTable(previousBuilds, canUpdateBuilds)}
        </div>
      </>
    );
  }
}

BuildVersionControl.propTypes = {
  previousBuilds: PropTypes.array,
  canUpdateBuilds: PropTypes.bool,
  getBuildsAction: PropTypes.func,
  enableAutoUpdate: PropTypes.func,
  location: PropTypes.object,
  unreleaseAction: PropTypes.func,
  releaseAction: PropTypes.func,
  posVersionId: PropTypes.number,
  clear: PropTypes.func,
  deleteUnreleasedVersionAction: PropTypes.func,
  getUserDetails: PropTypes.func
};

BuildVersionControl.defaultProps = {
  previousBuilds: [],
  canUpdateBuilds: null
};

const mapStateToProps = state => ({
  previousBuilds: state.administration.buildVersionControl.previousBuilds,
  canUpdateBuilds: state.administration.buildVersionControl.canUpdateBuilds,
  posVersionId: state.administration.buildVersionControl.posVersionId,
});

const mapDispatchToProps = dispatch => ({
  getBuildsAction: versionId => dispatch(getBuilds.base(versionId)),
  unreleaseAction: (data) => dispatch(unreleaseOption.base(data)),
  releaseAction: (data) => dispatch(releaseOption.base(data)),
  deleteUnreleasedVersionAction: (data) => dispatch(deleteUnreleasedVersion.base(data)),
  getUserDetails: (username) => dispatch(getUserDetails.base(username)),
  clear: () => dispatch(clear()).BuildVersionControl,
  enableAutoUpdate: (data) => dispatch(enableAutoUpdate.base(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildVersionControl);
