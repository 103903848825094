import { takeLatest, put } from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

const SAVE_FEEDBACK = 'SAVE_FEEDBACK';

export const saveFeedbackAction = (data, successHandler) => ({
  type: SAVE_FEEDBACK,
  payload: {
    data,
    successHandler
  }
});

export default function feedbackReducer(state = {}, action = {}) {
  switch (action.type) {
    case SAVE_FEEDBACK:
      return {
        ...state
      };
    default:
      return state;
  }
}

export function* feedbackSaga({ payload }) {
  try {
    const url = 'contactus/feedback';
    const response = yield ApiClient.httpClient.post(url, { ...payload.data });
    payload.successHandler(response.data);
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    put(showAlertAction(error));
  }
}

export function* watchFeedbackSaga() {
  yield takeLatest(SAVE_FEEDBACK, feedbackSaga);
}
