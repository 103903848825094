import {
  takeLatest, select, call, put, race, take
} from 'redux-saga/effects';
import * as Constants from 'constants/PPCP';
import URLSearchParams from 'url-search-params';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showConfirmationModal, CONFIRMATION_ACCEPTED, CONFIRMATION_REJECTED } from 'reducers/popUps/confirmationModalReducer';
import * as SeoHelper from 'utils/SeoHelper';
import initialState from './initialState';
import {
  createClassificationRequest
} from './Classification/selector';
import { createSupportRequest } from './Support/selector';
import { createDistributionRequest } from './Distribution/selector';
import { createMediaRequest } from './Media/selector';
import { createCommunicationRequest } from './Communication/selector';
import { createBasicInformationRequest } from './BasicInformation/selector';
import { CLEAR } from './applicationCommonReducer';
import { publicIdSelector, reviewSelector, isInternalPPCP } from './selectors';

const SAVE_REVIEWSUBMIT = createFetchTypes('Marketplace/Application/SAVE_REVIEWSUBMIT');
const UPLOAD_CLOUD_BUILD = createFetchTypes('Marketplace/Application/UPLOAD_CLOUD_BUILD');
const SAVE_FORM_APPLICATION = createFetchTypes('Marketplace/Application/SAVE_FORM_APPLICATION');
const UPDATE_CURRENT_FORM = 'Marketplace/Application/UPDATE_CURRENT_FORM';
const NEW_APPLICATION_IDS = 'Marketplace/Application/NEW_APPLICATION_IDS';
const SHOW_SERVER_ERRORS = 'Marketplace/Application/SHOW_SERVER_ERRORS';
const DISMISS_SERVER_ERRORS = 'Marketplace/Application/DISMISS_SERVER_ERRORS';
const UPDATE_ID = 'Marketplace/Application/UPDATE_ID';
const UPDATE_FORM_APPLICATION = 'Marketplace/Application/UPDATE_FORM_APPLICATION';
const GO_TO_ROUTE = 'Marketplace/Application/GO_TO_ROUTE';
const PPCP_FORM_MODIFIED = createFetchTypes('Marketplace/Application/PPCP_FORM_MODIFIED');
const CHECK_PPCP_CONFIRMATION = 'Marketplace/Application/CHECK_PPCP_CONFIRMATION';
const GET_UPLOADSOLNGUIDEURL = createFetchTypes('Marketplace/Application/GET_UPLOADSOLNGUIDEURL');

function getCompletedInfo(form, response) {
  if (response) {
    switch (form) {
      case Constants.BASIC_INFORMATION_ID:
        return response.basicInformationComplete;
      case Constants.CLASSIFICATION_ID:
        return response.classificationComplete;
      case Constants.DISTRIBUTION_ID:
        return response.pricingAndDistributionComplete;
      case Constants.COMMUNICATION_ID:
        return response.communicationComplete;
      case Constants.AUDIENCE_ID:
        return response.audienceComplete;
      case Constants.MEDIA_ID:
        return response.mediaComplete;
      case Constants.SUPPORT_ID:
        return response.supportComplete;
      default:
        return false;
    }
  }
  return false;
}

function getValueFromQueryString(location, key) {
  if (location && key) {
    const { search } = location;
    if (search) {
      const params = new URLSearchParams(search.toLowerCase());
      return params.get(key.toLowerCase());
    }
  }
  return '';
}

function getPageType(payload) {
  if (payload.isInternal) {
    return { pageType: Constants.INTERNAL };
  }
  if (payload.isTravelportPOSApplication) {
    return { pageType: Constants.TRAVELPORT_POS_APPLICATION };
  }
  return { pageType: Constants.GENERAL };
}

function constructApplicationForm(response, stateValue) {
  const currentForm = stateValue.currentForm.name;
  const state = { ...stateValue.forms };
  const forms = Object.keys(state);
  for (let i = 0; i < forms.length; i += 1) {
    state[forms[i]].completed = getCompletedInfo(forms[i], (currentForm === Constants.CLASSIFICATION_ID || currentForm === Constants.SUPPORT_ID) ? response.model : response);
  }
  return state;
}

function applicationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_CURRENT_FORM:
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          name: action.route, //  getFormByRoute(action.route, state.forms),
          modified: action.dirty
        }
      };
    case NEW_APPLICATION_IDS:
      return {
        ...state,
        appId: action.payload.appId,
        isProductPageComplete: action.payload.isProductPageComplete,
        percentageCompletion: action.payload.percentageCompletion,
        isInternal: action.payload.isInternal,
        isTravelportPOSApplication: action.payload.isTravelportPOSApplication,
        ...getPageType(action.payload)
      };
    case SHOW_SERVER_ERRORS:
      return {
        ...state,
        errorMessages: action.errors,
        showErrorModal: true
      };
    case DISMISS_SERVER_ERRORS:
      return {
        ...state,
        showErrorModal: false
      };
    case SAVE_FORM_APPLICATION.REQUEST:
      return {
        ...state,
        loading: true
      };
    case SAVE_FORM_APPLICATION.SUCCESS: {
      const currentPageName = state.currentForm.name;
      return {
        ...state,
        ...action.payload,
        forms: {
          ...state.forms,
          [currentPageName]: {
            ...state.forms[currentPageName],
            completed: getCompletedInfo(currentPageName, action.payload.pageCompletions)
          }
        },
        loading: false
      };
    }
    case SAVE_FORM_APPLICATION.FAILURE:
      return {
        ...state,
        loading: false
      };
    case UPDATE_FORM_APPLICATION:
      return {
        ...state,
        isProductPageComplete: action.data.model ? action.data.model.isProductPageComplete : action.data.isProductPageComplete,
        percentageCompletion: action.data.model ? action.data.model.percentageCompletion : action.data.percentageCompletion,
        isInternal: action.data.model ? action.data.model.isInternal : action.data.isInternal,
        isTravelportPOSApplication: action.data.model ? action.data.model.isTravelportPOSApplication : action.data.isTravelportPOSApplication,
        ...getPageType(action.data.model || action.data),
        forms: {
          ...constructApplicationForm(action.data, state)
        }
      };
    case GET_UPLOADSOLNGUIDEURL.REQUEST:
      return state;
    case GET_UPLOADSOLNGUIDEURL.SUCCESS:
      return {
        ...state,
        solnGuideUrl: action.payload.uploadSolnGuideUrl
      };
    case GET_UPLOADSOLNGUIDEURL.FAILURE:
      return {
        error: action.payload.error
      };
    case SAVE_REVIEWSUBMIT.REQUEST:
      return state;
    case SAVE_REVIEWSUBMIT.SUCCESS:
      return {
        ...state,
        submitted: true,
        completionPoints: action.payload.completionPoints,
        isActivated: action.payload.isActivated,
        isPublished: action.payload.isPublished,
        isReviewCompleted: true,
      };
    case SAVE_REVIEWSUBMIT.FAILURE:
      return state;
    case UPLOAD_CLOUD_BUILD.REQUEST:
      return state;
    case UPLOAD_CLOUD_BUILD.SUCCESS:
      return {
        ...state
      };
    case UPLOAD_CLOUD_BUILD.FAILURE:
      return state;
    case UPDATE_ID:
      return {
        ...state,
        [action.key]: action.value
      };
    case CLEAR:
      return {
        ...state,
        isProductPageComplete: false,
        percentageCompletion: 0
      };
    case GO_TO_ROUTE:
      return state;
    case CHECK_PPCP_CONFIRMATION:
      return state;
    default:
      return state;
  }
}

export const ppcpFormModified = () => ({
  type: PPCP_FORM_MODIFIED.BASE
});

export const updateId = (key, value) => ({
  type: UPDATE_ID,
  key,
  value
});

export const newApplicationIds = (payload) => ({
  type: NEW_APPLICATION_IDS,
  payload
});

export const updateCurrentForm = (route, dirty) => ({
  type: UPDATE_CURRENT_FORM,
  route,
  dirty: dirty || false
});

export const showServerErrors = (errors) => ({
  type: SHOW_SERVER_ERRORS,
  errors
});

export const dismissServerErrors = () => ({
  type: DISMISS_SERVER_ERRORS,
});

export const saveForm = {
  base: (params) => ({
    type: SAVE_FORM_APPLICATION.BASE,
    payload: {
      params
    }
  }),
  request: () => ({ type: SAVE_FORM_APPLICATION.REQUEST }),
  success: payload => ({ type: SAVE_FORM_APPLICATION.SUCCESS, payload }),
  failure: error => ({ type: SAVE_FORM_APPLICATION.FAILURE, error }),
};

export const saveReviewSubmit = {
  base: (data) => ({
    type: SAVE_REVIEWSUBMIT.BASE,
    url: 'application/savereviewandsubmit',
    data
  }),
  request: () => ({ type: SAVE_REVIEWSUBMIT.REQUEST }),
  success: payload => ({ type: SAVE_REVIEWSUBMIT.SUCCESS, payload }),
  failure: error => ({ type: SAVE_REVIEWSUBMIT.FAILURE, error }),
};

export const uploadCloudBuild = {
  base: (data) => ({
    type: UPLOAD_CLOUD_BUILD.BASE,
    url: 'application/uploadcloudbuild',
    data
  }),
  request: () => ({ type: UPLOAD_CLOUD_BUILD.REQUEST }),
  success: payload => ({ type: UPLOAD_CLOUD_BUILD.SUCCESS, payload }),
  failure: error => ({ type: UPLOAD_CLOUD_BUILD.FAILURE, error }),
};

export const getSolnGuideUrl = {
  base: () => ({
    type: GET_UPLOADSOLNGUIDEURL.BASE,
    url: 'application/howtouploadyoursolutionurl'
  }),
  request: () => ({ type: GET_UPLOADSOLNGUIDEURL.REQUEST }),
  success: payload => ({ type: GET_UPLOADSOLNGUIDEURL.SUCCESS, payload }),
  failure: error => ({ type: GET_UPLOADSOLNGUIDEURL.FAILURE, error })
};
export const goToRoute = {
  base: (data) => ({
    type: GO_TO_ROUTE,
    ...data
  })
};

export const ppcpFormConfirmation = {
  base: (history) => ({
    type: CHECK_PPCP_CONFIRMATION,
    history
  })
};

const currentFormSelector = state => state.application.applicationLayout.currentForm;
const formsSelector = state => state.application.applicationLayout.forms;
const currentFormType = state => state.application.applicationLayout.pageType;
const productTitle = state => state.form.basicInformation.values.title;
const support = state => state.form.support.values.supportPage;
const isPPCPFormModified = state => state.application.applicationLayout.currentForm.modified;
const review = state => state.application.review.data;
const audience = state => state.application.audienceAccess.audience;
const media = state => state.application.media;
const classification = state => state.application.classification;
const communication = state => state.application.communication.data;
const distribution = state => state.application.distribution;
const isAppInternal = state => state.application.applicationLayout.isInternal;

export const updateFormApplication = (response) => ({
  type: UPDATE_FORM_APPLICATION,
  data: response
});

function* getCurrentRequest(namePage) {
  switch (namePage) {
    case Constants.BASIC_INFORMATION_ID:
      return yield createBasicInformationRequest();
    case Constants.CLASSIFICATION_ID:
      return yield createClassificationRequest();
    case Constants.DISTRIBUTION_ID:
      return yield createDistributionRequest();
    case Constants.COMMUNICATION_ID:
      return yield createCommunicationRequest();
    case Constants.AUDIENCE_ID:
      return yield select(audience);
    case Constants.MEDIA_ID:
      return yield createMediaRequest();
    case Constants.SUPPORT_ID:
      return yield createSupportRequest();
    case Constants.REVIEW_ID:
      return yield select(review);
    default:
      return '';
  }
}

function getPrevRoute({ currentOrder, forms, pageType }) {
  const form = Object.keys(forms).reverse().find(key => forms[key].order < currentOrder && forms[key].pageType.includes(pageType));
  return form && forms[form].route;
}

function getNextRoute({ currentOrder, forms, pageType }) {
  const form = Object.keys(forms).find(key => forms[key].order > currentOrder && forms[key].pageType.includes(pageType));
  return form && forms[form].route;
}

function decideRoute(params, currentOrder, currentPage, forms, pageType) {
  let route;
  if (params.goToNext) {
    route = getNextRoute({ currentOrder, forms, pageType });
    return route;
  }
  if (params.current) {
    route = currentPage.name;
    return route;
  }
  if (params.selectedRoute) {
    route = (params.selectedRoute === '/') ? '' : params.selectedRoute; // send empty string incase of default route
    return route;
  }
  route = getPrevRoute({ currentOrder, forms, pageType });
  return route;
}
function isInternalApp(params, currentRequest) {
  const value = getValueFromQueryString(params.history.location, 'isInternal');
  if (value) {
    return true;
  }
  return currentRequest.isInternal;
}
function getQueryParam(params, publicId) {
  if (params.queryString) {
    return params.queryString;
  }
  return `?publicId=${publicId}`;
}

function* validateTitle() {
  try {
    const title = yield select(productTitle);
    if (!title) {
      yield put(showServerErrors(['Product Title is required']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* validateSupport() {
  try {
    const supportPageUrl = yield select(support);
    if (!supportPageUrl) {
      yield put(showServerErrors(['Support page url is required']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* validateDistribution() {
  try {
    const viewModel = yield select(distribution);
    if (!viewModel.isDirectDownloadVisible && !viewModel.isIntegrationDownloadVisible && !viewModel.isNotDistributedVisible
      && !viewModel.isDistributedElsewhereVisible && viewModel.platformId !== 2) {
      yield put(showServerErrors(['Please select a distribution method']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* validateMedia() {
  try {
    const mediaData = yield select(media);
    const logo = mediaData.data.hasLogo;
    const screenshot = mediaData.data.screenshot1ThumbnailUrl;
    const isApplicationInternal = yield select(isAppInternal);
    if ((!logo || screenshot == null) && !isApplicationInternal) {
      yield put(showServerErrors(['Please complete media page']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* validateClassification() {
  try {
    const classificationData = yield select(classification);
    if (classificationData.classficaitonModel.platformId === null || classificationData.classficaitonModel.platformId === undefined) {
      yield put(showServerErrors(['Please select platform supported']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* validateCommunication() {
  try {
    const communicationObj = yield select(communication);
    if (communicationObj.purchaseConfirmationMessage === null && communicationObj.sendEmailOnOrder) {
      yield put(showServerErrors(['Please enter a confirmation email message']));
      return false;
    }
  } catch (e) {
    return true;
  }
  return true;
}

function* saveFormSaga(action) {
  yield put(showLoader());
  try {
    const isValid = yield validateTitle();
    if (!isValid) {
      yield put(hideLoader());
      return;
    }
    const { params } = action.payload;
    const currentPage = yield select(currentFormSelector);
    const forms = yield select(formsSelector);
    const pageType = yield select(currentFormType);
    let publicId = yield select(publicIdSelector);
    let queryString = `?publicId=${publicId}`;
    const currentRequest = yield getCurrentRequest(currentPage.name);
    if (currentPage.name !== Constants.AUDIENCE_ID && currentPage.name !== Constants.REVIEW_ID) {
      const isInternal = isInternalApp(params, currentRequest);
      const result = yield call(() => fetchEntity(saveForm, ApiClient.httpClient.post, forms[currentPage.name].saveUrl, { ...currentRequest, isInternal }));
      if (result.data.appId) {
        publicId = result.data.publicId;
        queryString = getQueryParam(params, publicId);
        yield put(newApplicationIds(result.data));
      }
    }
    if (params) {
      const currentOrder = forms[currentPage.name].order;
      const url = params.queryString || queryString;
      const route = decideRoute(params, currentOrder, currentPage, forms, pageType);
      yield put(updateCurrentForm(currentPage.name, false));
      yield put(updateId('publicId', publicId));
      yield put(hideLoader());
      params.history.push(`${route}${url}`);
    }
    if (params.saveAndPreview) {
      const product = currentRequest.title;
      if (product) {
        window.open(`/Preview/${SeoHelper.SEOFriendly(product)}`, '_blank');
      }
    }
  } catch (error) {
    if (error.response
      && error.response.status === 400
      && error.response.data
      && error.response.data.length > 0) {
      yield put(showServerErrors(error.response.data));
    } else {
      put(showAlertAction(error.response.data.responseMessage));
    }
    yield put(hideLoader());
  }
}

function* saveReviewSaga({ url, data }) {
  try {
    const isInternal = yield select(isInternalPPCP);
    if (isInternal) {
      yield put(showLoader());
      const params = yield select(reviewSelector);
      const publicId = yield select(publicIdSelector);
      yield call(() => fetchEntity(saveReviewSubmit, ApiClient.httpClient.post, url, { ...params }));
      data.history.push(`/application/InternalAppTerms?publicId=${publicId}`);
      yield put(hideLoader());
    } else {
      yield put(
        showConfirmationModal('Are you sure you want to submit for product approval process?')
      );
      const { accept } = yield race({
        accept: take(CONFIRMATION_ACCEPTED),
        reject: take(CONFIRMATION_REJECTED)
      });
      if (accept) {
        yield put(showLoader());
        const params = yield select(reviewSelector);
        if (params.isDirectDownload || params.isIntegrationDownload) {
          const doc = data.review.certificationDocuments[data.review.certificationDocuments.length - 1];
          params.certificateDocumentId = doc.id;
        }

        const publicId = yield select(publicIdSelector);
        yield call(() => fetchEntity(saveReviewSubmit, ApiClient.httpClient.post, url, { ...params }));
        if (isInternal) {
          data.history.push(`/application/InternalAppTerms?publicId=${publicId}`);
        } else {
          data.history.push(`/application/ReviewSubmitCompleted?publicId=${publicId}`);
        }
        yield put(hideLoader());
      }
    }
  } catch (error) {
    yield put(hideLoader());
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
}

function* ppcpFormModifiedSaga(data) {
  try {
    yield put(showConfirmationModal('Save Changes?', ''));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      const params = { queryString: data.queryString, history: data.history };
      if (data.current) {
        params.current = data.current;
      } else {
        params.selectedRoute = data.route;
      }
      yield put(saveForm.base(params));
    } else {
      const queryString = (data.queryString === '/') ? '' : data.queryString;
      const route = data.route === '/' ? '/' : data.route;
      const currentPage = yield select(currentFormSelector);
      yield put(updateCurrentForm(currentPage.name, false));
      yield call(() => data.history.push(`${route}${queryString}`));
    }
  } catch (e) {
    yield put(showAlertAction(e));
  }
}

function* ppcpFormConfirmationSaga(params) {
  const formModified = yield select(isPPCPFormModified);
  if (formModified) {
    yield put(showConfirmationModal('Save Changes?', ''));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      yield put(saveForm.base({ current: true, history: params.history }));
      params.history.go();
    } else {
      const currentPage = yield select(currentFormSelector);
      yield put(updateCurrentForm(currentPage.name, false));
    }
  }
}
function* getSolutionUploadGuideUrl() {
  try {
    yield put(getSolnGuideUrl.request());
    const response = yield call(() => ApiClient.httpClient.get(getSolnGuideUrl.base().url));
    yield put(getSolnGuideUrl.success({
      uploadSolnGuideUrl: response.data
    }));
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
}

function* goToRouteSaga(data) {
  try {
    const currentPage = data.history.location.pathname ? data.history.location.pathname : '';
    const isCurrentPageClassification = currentPage.includes('classification');
    const isCurrentPageSupport = currentPage.includes('support');
    const isCurrentPageDistribution = currentPage.includes('distribution');
    const isCurrentPageCommunication = currentPage.includes('communication');
    const isCurrentPageMedia = currentPage.includes('media');
    const isCurrentPageBasicInformation = currentPage.includes('basicInformation');
    if (isCurrentPageBasicInformation) {
      const isValid = yield validateTitle();
      if (!isValid) {
        return;
      }
    } else if (isCurrentPageMedia) {
      const isMediaCompleted = yield validateMedia();
      if (!isMediaCompleted) {
        return;
      }
    } else if (isCurrentPageSupport) {
      const isSupportCompleted = yield validateSupport();
      if (!isSupportCompleted) {
        return;
      }
    } else if (isCurrentPageClassification) {
      const isClassificationCompleted = yield validateClassification();
      if (!isClassificationCompleted) {
        return;
      }
    } else if (isCurrentPageDistribution) {
      const isDistributionCompleted = yield validateDistribution();
      if (!isDistributionCompleted) {
        return;
      }
    } else if (isCurrentPageCommunication) {
      const isCommunicationCompleted = yield validateCommunication();
      if (!isCommunicationCompleted) {
        return;
      }
    }

    const formModified = yield select(isPPCPFormModified);

    if (formModified) {
      yield ppcpFormModifiedSaga(data);
    } else {
      yield call(() => data.history.push(`${data.route}${data.queryString}`));
    }
  } catch (error) {
    yield put(showAlertAction(error));
  }
}

function* uploadCloudBuildSaga(params) {
  try {
    yield put(uploadCloudBuild.request());
    const response = yield call(() => ApiClient.httpClient.post(uploadCloudBuild.base().url, { ...params.data }));
    yield put(uploadCloudBuild.success({
      uploadCloudBuild: response.data
    }));
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
}

export function* watchSaveFormSaga() {
  yield takeLatest(SAVE_FORM_APPLICATION.BASE, saveFormSaga);
}

export function* watchSaveReviewSaga() {
  yield takeLatest(SAVE_REVIEWSUBMIT.BASE, saveReviewSaga);
}

export function* watchGoToRouteSaga() {
  yield takeLatest(GO_TO_ROUTE, goToRouteSaga);
}

export function* watchppcpFormModifiedSaga() {
  yield takeLatest(PPCP_FORM_MODIFIED.BASE, ppcpFormModifiedSaga);
}

export function* watchCheckPPCPConfirmation() {
  yield takeLatest(CHECK_PPCP_CONFIRMATION, ppcpFormConfirmationSaga);
}

export function* watchGetSolnUploadGuideUrl() {
  yield takeLatest(GET_UPLOADSOLNGUIDEURL.BASE, getSolutionUploadGuideUrl);
}
export function* watchUploadCloudBuildSaga() {
  yield takeLatest(UPLOAD_CLOUD_BUILD.BASE, uploadCloudBuildSaga);
}
export default applicationReducer;
