import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@atlas-design-system/react';
import { withRouter } from 'react-router';
import AgencyDetails from './AgencyDetails';
import AgencyHierarchy from './AgencyHierarchy/AgencyHierarchy';
import * as utils from '../../../utils/Common.Utils';

class AgencyTab extends Component {
  constructor(props) {
    super(props);
    const selectedTab = utils.getValueFromQueryString(
      props.location,
      'selectedTab'
    );
    this.state = {
      selectedTab: selectedTab ? selectedTab.toLowerCase() : ''
    };
  }

  render() {
    // commenting code for issue PSA-7940 ands added the next line
    // const canShowAgencyHierarchy = this.props.permission && this.props.permission.showAgencyHierarchyTab;
    const canShowAgencyHierarchy = false;
    // const canShowAgencyTab = this.props.permission
    //   && this.props.permission.canUserViewAgencyTab;

    return (
      <>
        <AgencyDetails />
        <Tabs defaultActiveKey="0" id="gtm-myprofile-subnav-agencyTabs">
          {canShowAgencyHierarchy
            ? (
              <>
                <Tabs.Tab eventKey="certified" title="My Profile">
                  <AgencyDetails />
                </Tabs.Tab>

                <Tabs.Tab eventKey="certified" title="Agency Hierarchy">
                  <AgencyHierarchy />
                </Tabs.Tab>
              </>
            )
            : (<></>)}
        </Tabs>
      </>
    );
  }
}

AgencyTab.propTypes = {
  permission: PropTypes.object,
  location: PropTypes.object,
  setView: PropTypes.func
};

export default withRouter(AgencyTab);
