/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveFeedbackAction } from 'reducers/Feedback/FeedbackFormReducer';
import { RECAPTCHA_CLIENT_SECRET_KEY } from 'config/apiConfig';

const CompleteCaptcha = () => (
  <div
    className="row feedback-alert feedback-alert-danger"
    id="fb-captcha-error"
  >
    Please complete the CAPTCHA
    <br />
  </div>
);

const RateFeedback = () => (
  <div
    className="row feedback-alert feedback-alert-danger"
    id="fb-nps-not-selected"
  >
    Please rate Travelport Marketplace
    <br />
  </div>
);

const InvalidEmail = () => (
  <div
    className="row feedback-alert feedback-alert-danger"
    id="fb-email-notcorrect"
  >
    Email Address invalid
    <br />
  </div>
);

const SuccessMessage = () => (
  <div
    className="row"
    id="fb_thanks"
    style={{ width: '100%', height: '100%' }}
  >
    <h2>Thank you!</h2>
  </div>
);

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: false,
      moveLeft: false,
      recaptchaResponse: '',
      netPromoterScore: null,
      emailAddress: '',
      showNetScoreMessage: false,
      showEmailMessage: false,
      showCaptchaMessage: false,
      successMessage: false
    };
    this._reCaptchaRef = React.createRef();
  }

  validateCaptcha = (token) => {
    if (token) {
      this.setState({
        recaptchaResponse: token
      });
    }
  }

  toggleFeedback = (e) => {
    e.preventDefault();
    this.setState({
      moveLeft: !this.state.moveLeft,
      activeClass: !this.state.activeClass
    });
  }

  handleChangeValue = (e, type) => {
    this.setState({
      [type]: e.target.value
    });
  }

  feedBackSubmit = (e) => {
    e.preventDefault();
    if (!this.state.netPromoterScore) {
      this.setState({
        showNetScoreMessage: true,
        showCaptchaMessage: false
      });
      this._reCaptchaRef.current.reset();
      return;
    }
    if (!this.state.recaptchaResponse) {
      this.setState({
        showCaptchaMessage: true,
        showNetScoreMessage: false
      });
      this._reCaptchaRef.current.reset();
      return;
    }
    const data = {
      recaptchaResponse: this.state.recaptchaResponse,
      netPromoterScore: this.state.netPromoterScore,
      emailAddress: this.state.emailAddress
    };
    this.props.saveFeedback(data, this.successHandler);
  }

  successHandler = (data) => {
    if (!data.status) {
      this._reCaptchaRef.current.reset();
      if (data.invalidEmail) {
        this.setState({
          showEmailMessage: true
        });
      }
      if (data.invalidNps) {
        this.setState({
          showNetScoreMessage: true
        });
      }
    } else {
      this.setState({
        successMessage: true
      });
      setTimeout(() => {
        this.resetForm();
      }, 3000);
    }
  }

  resetForm = () => {
    this._reCaptchaRef.current.reset();
    this.formRef.reset();
    this.setState({
      recaptchaResponse: '',
      netPromoterScore: null,
      emailAddress: '',
      showNetScoreMessage: false,
      showEmailMessage: false,
      showCaptchaMessage: false,
      successMessage: false,
      activeClass: false,
      moveLeft: false
    });
  }

  render() {
    const { moveLeft, activeClass } = this.state;
    return (
      <>
        <div id="feedback-overlay" onClick={this.toggleFeedback} style={{ display: activeClass ? 'block' : 'none' }} />
        <div id="feedback" className={moveLeft ? 'animate' : ''}>
          <Link to="#" onClick={this.toggleFeedback} id="feedback-tab" className={activeClass ? 'active' : ''}><span>Feedback</span></Link>
          <div id="feedback-container">
            {this.state.successMessage ? <SuccessMessage /> : null}
            <form
              id="feedback-form"
              name="feedback-form"
              onSubmit={this.feedBackSubmit}
              ref={(el) => this.formRef = el}
              style={{ display: this.state.successMessage ? 'none' : 'block' }}
            >
              <h2>Tell us what you think</h2>
              <div className="row" id="fb-form-container">
                <label htmlFor="fb_1">How likely are you to recommend Travelport Marketplace to a friend or colleague?</label>
                <div id="nps_radios">
                  <span className="fb-nps-zero-container">
                    Not Likely
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_0"
                      value="0"
                    />
                    <br />
                    <label htmlFor="nps_0">0</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_1"
                      value="1"
                    />
                    <br />
                    <label htmlFor="nps_0">1</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_2"
                      value="2"
                    />
                    <br />
                    <label htmlFor="nps_0">2</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_3"
                      value="3"
                    />
                    <br />
                    <label htmlFor="nps_0">3</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_4"
                      value="4"
                    />
                    <br />
                    <label htmlFor="nps_0">4</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_5"
                      value="5"
                    />
                    <br />
                    <label htmlFor="nps_0">5</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_6"
                      value="6"
                    />
                    <br />
                    <label htmlFor="nps_0">6</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_7"
                      value="7"
                    />
                    <br />
                    <label htmlFor="nps_0">7</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_8"
                      value="8"
                    />
                    <br />
                    <label htmlFor="nps_0">8</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_9"
                      value="9"
                    />
                    <br />
                    <label htmlFor="nps_0">9</label>
                  </span>
                  <span className="fb-nps-radio-container">
                    <input
                      type="radio"
                      name="nps_score"
                      onClick={(e) => this.handleChangeValue(e, 'netPromoterScore')}
                      id="nps_10"
                      value="10"
                    />
                    <br />
                    <label htmlFor="nps_0">10</label>
                  </span>
                  <span className="fb-nps-ten-container">
                    Very Likely
                  </span>
                </div>
              </div>
              <div className="row">
                <label htmlFor="fb_email">Your Email (optional)</label>
                <input type="text" maxLength="256" onChange={(e) => this.handleChangeValue(e, 'emailAddress')} value={this.state.emailAddress} id="fb_email" name="fb_email" />
              </div>
              <div className="row" id="recaptchaRow">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_CLIENT_SECRET_KEY}
                  onChange={(token) => this.validateCaptcha(token)}
                  ref={this._reCaptchaRef}
                />
              </div>
              {this.state.showNetScoreMessage ? <RateFeedback /> : null}
              {this.state.showCaptchaMessage ? <CompleteCaptcha /> : null}
              {this.state.showEmailMessage ? <InvalidEmail /> : null}
              <div className="row buttons">
                <Link to="#" className="blue-button fb-cancel" onClick={this.toggleFeedback}>Cancel</Link>
                <input id="fb_submit" type="submit" className="green-button" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
FeedbackForm.propTypes = {
  saveFeedback: PropTypes.func
};

const mapStateToProps = (state) => ({
  feedback: state.feedback
});

const mapDispatchToProps = (dispatch) => ({
  saveFeedback: bindActionCreators(saveFeedbackAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
