import { put } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

export const CREATE_AGENCY_USER = 'CREATE_AGENCY_USER';
const CREATE_AGENCY_USER_SUCCESS = 'CREATE_AGENCY_USER_SUCCESS';
const CREATE_AGENCY_USER_FAILURE = 'CREATE_AGENCY_USER_FAILURE';
const FETCH_AGENCY_USER_SET = 'FETCH_AGENCY_USER_SET';

const fetchAgencyUserSet = {
  base: (params) => ({
    type: FETCH_AGENCY_USER_SET,
    payload: {
      params
    }
  })
};

export const createAgencyUser = {
  base: (data) => ({
    type: CREATE_AGENCY_USER,
    payload: {
      ...data
    }
  })
};

const createAgencyUserSuccess = () => ({
  type: CREATE_AGENCY_USER_SUCCESS,
});

const createAgencyUserFailure = (error) => ({
  type: CREATE_AGENCY_USER_FAILURE,
  payload: {
    error
  }
});

const agencyUserCreateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CREATE_AGENCY_USER:
      return {
        loading: true,
        error: null
      };

    case CREATE_AGENCY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_AGENCY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* createAgencyUserWatcher(action) {
  yield put(showLoader());
  try {
    const { orgId, askTravelportId } = action.payload;
    yield ApiClient.agencyUser.create({ orgId, askTravelportId });
    yield put(createAgencyUserSuccess());
    yield put(fetchAgencyUserSet.base({ orgId }));
    yield put(showAlertAction('User has been successfully added'));
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.errorMessage) {
      yield put(showAlertAction(error.response.data.errorMessage));
    }
    yield put(createAgencyUserFailure());
  }
  yield put(hideLoader());
}

export default agencyUserCreateReducer;
