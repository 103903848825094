import { put, race, take } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import ApiClient from '../../../../../Services/ApiClient';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal,
} from '../../../../popUps/confirmationModalReducer';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { hideLoader, showLoader } from '../../../../popUps/loaderReducer';

export const DELETE_AGENCY_GROUP = 'DELETE_AGENCY_GROUP';
export const deleteAgencyGroup = (data) => ({
  type: DELETE_AGENCY_GROUP,
  payload: {
    data
  }
});

export const DELETE_AGENCY_GROUP_SUCCESS = 'DELETE_AGENCY_GROUP_SUCCESS';
const deleteAgencyGroupSuccess = (params) => ({
  type: DELETE_AGENCY_GROUP_SUCCESS,
  payload: {
    params
  }
});

export const DELETE_AGENCY_GROUP_FAILURE = 'DELETE_AGENCY_GROUP_FAILURE';
const deleteAgencyGroupFailure = (error) => ({
  type: DELETE_AGENCY_GROUP_FAILURE,
  payload: {
    error
  }
});

const agencyGroupDeleteReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case DELETE_AGENCY_GROUP:
      return {
        loading: true,
        error: null
      };

    case DELETE_AGENCY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payload: action.payload,
      };

    case DELETE_AGENCY_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* deleteAgencyGroupSaga(action) {
  yield put(showConfirmationModal('Are you sure you want to delete this agency group?'));
  const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
  if (accept) {
    yield put(showLoader());
    try {
      yield ApiClient.agencyGroup.delete({ groupId: action.payload.data.groupId });
      yield put(deleteAgencyGroupSuccess(action.payload.data.orgId));
      yield put(showAlertAction('Group has been deleted successfully.'));
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        handleUnauthorizedResponse();
      }
      yield put(deleteAgencyGroupFailure());
    }
    yield put(hideLoader());
  }
}

export default agencyGroupDeleteReducer;
