import {
  fork, takeLatest, call, put
} from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { manageAdminsMiddleware } from 'reducers/modules/Administration/OrganizationDirectory/ManageAdmins/middleware';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient';
import {
  GET_ORGANIZATION_LIST,
  getOrganizationReducerWatcher
} from './getOrganizationList';
import {
  GET_ALL_FILTER_OPTIONS,
  getAllFilterOptionsReducerWatcher
} from './getFilters';

import { GET_CHILD_ORGS, getChildOrgReducerWatcher } from './getChildOrgs';

import {
  ADD_ORGANIZATION,
  addOrganizationSaga
} from './addOrganization';

import {
  CHANGE_ORGANIZATION_VISIBILITY,
  setOrganizationVisibilityReducerWatcher
} from './setOrganiztionVisibility';

const EXPORT_TO_EXCEL_URL_ORG = 'organizationdirectory/GetExcelReportForOrgDirectory';
export const ListSelector = state => state.administration.organizationDirectory;
export const EXPORT_TO_EXCEL = createFetchTypes('Marketplace/Administration/organizationDirectory/EXPORT_TO_EXCEL');
export const exportToExcel = {
  base: (params) => ({
    type: EXPORT_TO_EXCEL.BASE,
    params,
    url: EXPORT_TO_EXCEL_URL_ORG
  }),
  request: () => ({ type: EXPORT_TO_EXCEL.REQUEST }),
  success: (data) => ({ type: EXPORT_TO_EXCEL.SUCCESS, data }),
  failure: (error) => ({ type: EXPORT_TO_EXCEL.FAILURE, error })
};

function* exportToExcelSaga({ params, url }) {
  yield put(showLoader());
  try {
    yield put(exportToExcel.request());
    const response = yield call(ApiClient.httpClient.post, url, { ...params });
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      let filename = 'report.csv';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
    yield put(exportToExcel.success());
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* organizationDirectoryMiddleware() {
  yield takeLatest(GET_ALL_FILTER_OPTIONS.BASE, getAllFilterOptionsReducerWatcher);
  yield takeLatest(GET_ORGANIZATION_LIST.BASE, getOrganizationReducerWatcher);
  yield takeLatest(GET_CHILD_ORGS.BASE, getChildOrgReducerWatcher);
  yield takeLatest(ADD_ORGANIZATION.BASE, addOrganizationSaga);
  yield takeLatest(EXPORT_TO_EXCEL.BASE, exportToExcelSaga);
  yield takeLatest(
    CHANGE_ORGANIZATION_VISIBILITY.BASE,
    setOrganizationVisibilityReducerWatcher
  );

  yield fork(manageAdminsMiddleware);
}
