/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';

const UploadInstructions = props => {
  const showDirectDownload = props.name === 'directDownload' ? true : false;
  const showMarketplaceIntegration = props.name === 'marketplaceIntegration' ? true : false;
  return (
    <>
      <Modal
        id="sbModal"
        title="Downloadable File Instructions"
        hidden={!showDirectDownload}
        size="small"
        onDismiss={props.popUpHide}
      >
        <div className="row">
          <div className="row">
            <p>
              These instructions describe how to package your product files so
              they can be downloaded and installed by a customer directly from
              the Travelport Marketplace website.
            </p>
            <p>
              <u>Package Format</u>
            </p>
            <ul>
              <li>
                The package can be in any file format, but we recommend that
                is should be or include an installer. This could be either an
                {' '}
                <em>.exe</em>
                {' '}
                or an
                <em>.msi</em>
              </li>
              <li>
                The package can have any file name, EXCEPT
                {' '}
                <em>plugin.zip</em>
              </li>
              <li>
                The package size should not exceed
                {' '}
                <em>500 MB</em>
              </li>
              <li>
                The package can be an individual file, or if you wish to
                include additional documentation or files with the plug-in,
                they should be contained within a
                {' '}
                <em>.zip</em>
              </li>
            </ul>
            <p />
            <p>
              Please refer to the
              `&apos;s`
              User Guide or the Uploading
              Your Solution guide (available on the left side of the
              Distribution page) for more information.
            </p>
          </div>
        </div>
        <div className="text-center">
          <a
            href="#"
            onClick={props.popUpHide}
            className="cancel-button no-save"
            data-dismiss="modal"
          >
            Close
          </a>
        </div>
      </Modal>

      <Modal
        id="sbModal"
        title="Integration File Instructions"
        hidden={!showMarketplaceIntegration}
        size="small"
        onDismiss={props.popUpHide}

      >
        <div className="row">
          <div className="row">
            <p>
              These instructions describe how to package up your product files
              so they can be delivered directly to a customer’s Travelport
              Smartpoint instance.
            </p>
            <p>
              <u>Package Format</u>
            </p>
            <ul>
              <li>
                The package must be a
                {' '}
                <em>.zip</em>
                {' '}
                file
              </li>
              <li>
                The package must be called,
                {' '}
                <em>plugin.zip</em>
              </li>
              <li>
                The package size should not exceed
                {' '}
                <em>500 MB</em>
              </li>
              <li>
                plugin.zip can contain any file types EXCEPT
                {' '}
                <em>.msi</em>
                {' '}
                {'&'}
                {' '}
                <em>.exe</em>
              </li>
              <li>
                <em>.msi</em>
                {' '}
                {'&'}
                {' '}
                <em>.exe</em>
                {' '}
                are NOT supported for
                integration
              </li>
              <li>
                All required files should be in the root directory of the
                {' '}
                <em>.zip</em>
              </li>
            </ul>
            <p />
            <p>
              If you need to use common files, then you have to create a new
              folder called “common” in the root directory of the .zip and put
              all of these files inside that folder.
            </p>
            <p>
              Please refer to the
              `&apos;s`
              User Guide or the Uploading
              Your Solution guide (available on the left side of the
              Distribution page) for more information.
            </p>
          </div>
        </div>
        <div className="text-center">
          <a
            href="#"
            onClick={props.popUpHide}
            className="cancel-button no-save"
            data-dismiss="modal"
          >
            Close
          </a>
        </div>
      </Modal>
    </>
  );
};

UploadInstructions.propTypes = {
  popUpHide: PropTypes.func,
  name: PropTypes.string
};

export default UploadInstructions;
