import { takeLatest, call, put } from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { createSelector } from 'reselect';

export const GET_ALL_REGIONS = createFetchTypes('Marketplace/Application/GET_ALL_REGIONS');

function regions(state = [], action = {}) {
  switch (action.type) {
    case GET_ALL_REGIONS.SUCCESS:
      return action.payload;
    case GET_ALL_REGIONS.FAILURE:
      return state;
    default:
      return state;
  }
}

// ********************************************** SELECTORS **********************************************/
// getRegionsSelector -> Formatted for the react-select typeahead control
const regionsSelector = state => state.regions;
export const getRegionsSelector = createSelector(
  [regionsSelector],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getAllRegions = {
  base: (data) => ({
    type: GET_ALL_REGIONS.BASE,
    url: 'productaccess/getallregions',
    data
  }),
  request: () => ({ type: GET_ALL_REGIONS.REQUEST }),
  success: payload => ({ type: GET_ALL_REGIONS.SUCCESS, payload }),
  failure: error => ({ type: GET_ALL_REGIONS.FAILURE, error }),
};

function* getAllRegionsSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getAllRegions, ApiClient.httpClient.get, url, { data }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
}

export function* watchGetAllRegionsSaga() {
  yield takeLatest(GET_ALL_REGIONS.BASE, getAllRegionsSaga);
}

export default regions;
