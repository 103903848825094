import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAgencyProfile } from 'reducers/modules/Profile/Agency/AgencyProfile/AgencyProfile';
import Authorization from 'components/Authorization/Authorization';
import Profile from 'components/Profile/Profile';
import PropTypes from 'prop-types';
import './AgencyProfile.css';

class AgencyProfile extends Component {
  constructor(props) {
    super(props);
    this.props.getAgencyProfile(this.props.match.params.id);
  }

  render() {
    const newProps = { showUserProfile: false, ...this.props };
    return (
      <div>
        <h2>
          Agency Profile:
          {this.props.agencyName}
        </h2>
        <Link className="TitleMarginLeft" to="/admin/home/organizationDirectory">
          &lt; Back to Organization Directory
        </Link>
        <Profile {...newProps} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  agencyName: state.profile.getAgencyProfile.data.name
});

const mapDispatchToProps = dispatch => ({
  getAgencyProfile: id => dispatch(getAgencyProfile.base(id))
});

AgencyProfile.propTypes = {
  getAgencyProfile: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization(AgencyProfile, { path: false }));
