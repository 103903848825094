import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DetailsSpecs extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className="col-xs-12 details-specs">
        {this.props.items.map((item) => item.data
            && (
            <p key={item.name}>
              <strong>
                {item.name}
                :
              </strong>
              <br />
              {item.data}
            </p>
            ))}
      </div>
    );
  }
}

DetailsSpecs.propTypes = {
  items: PropTypes.array
};

export default DetailsSpecs;
