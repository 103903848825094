import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CoreLoader extends Component {
  render() {
    if (this.props.showLoader) {
      return (
        <div className="loader-container">
          <div id="ajax-loading" className="loader-component">
            <img src={require('../../../../Styles/img/loader.gif').default} alt="" />
            &nbsp;Loading...
          </div>
        </div>
      );
    }
    return null;
  }
}

CoreLoader.propTypes = {
  showLoader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  showLoader: state.coreLoader.showLoader,
});

export default connect(mapStateToProps, null)(CoreLoader);
