/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RatingsReplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reply: ''
    };
  }

  submitReply = () => {
    if (!this.state.reply) {
      return;
    }
    this.props.onSubmit(this.state.reply);
  }

  render() {
    return (
      <div className="col-sm-12" id="replyReview">
        <div className="row">
          <div className="col-sm-2 left">
            <label htmlFor="reviewText" className="col-sm-offset-6">Reply:</label>
&nbsp;
          </div>
          <div className="col-sm-10 left">
            <textarea
              rows="4"
              value={this.state.reply}
              onChange={(e) => this.setState({ reply: e.target.value })}
              placeholder="optional"
              name="reviewText"
              className="text-input"
              cols="50"
              maxLength="1000"
              style={{ height: '110px' }}
            />
          </div>
        </div>
        <div className="row no-space-bottom pad-top-sm right">
          <a className="blue-button" onClick={this.submitReply}>Reply</a>
          <a className="blue-button" onClick={this.props.cancel}>Cancel</a>
        </div>
      </div>
    );
  }
}

RatingsReplyForm.propTypes = {
  onSubmit: PropTypes.func,
  cancel: PropTypes.func
};

export default RatingsReplyForm;
