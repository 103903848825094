import { put, call, all } from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import licenseTypes from 'constants/licenseTypes';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { getAssignedMembers, getUnassignedMembers } from '../Members/members';
import { getAssignedPccs, getUnassignedPccs } from '../Pccs/pccs';

export const LICENSE_INFORMATION = createFetchTypes('Marketplace/admin/licenses/licenseInformation');
const SEAT_SITE_LICENSE_INFORMATION = createFetchTypes('Marketplace/admin/licenses/seatSiteLicenseInformation');
const UPDATE_ASSIGNED_SEAT_SITE_LICENSES = 'Marketplace/admin/licenses/updateAssignedSeatSiteLicenses';
const EMPTY_ASSIGNED_SEAT_SITE_LICENSES = 'Marketplace/admin/licenses/emptyAssignedSeatSiteLicenses';
export const IS_SPC_USER_ACCESS = createFetchTypes('Marketplace/admin/licenses/licenseInformations');
const initialState = {
  licenseId: 0,
  orgId: 0,
  applicationName: '',
  licenseType: '',
  applicationDeveloperName: '',
  isSpcUsrAccessProduct: ''
};

const licenseInformation = (state = initialState, action = {}) => {
  switch (action.type) {
    case LICENSE_INFORMATION.REQUEST:
    case SEAT_SITE_LICENSE_INFORMATION.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LICENSE_INFORMATION.SUCCESS:
      return {
        ...state,
        loading: false,
        licenseId: action.data.licenseId,
        licenseType: action.data.licenseType,
        orgId: action.data.organisationId,
        applicationName: action.data.applicationName,
        applicationDeveloperName: action.data.applicationDeveloperName,
        isSpcUsrAccessProduct: action.data.isSpcUsrAccessProduct,
      };
    case SEAT_SITE_LICENSE_INFORMATION.SUCCESS:
      return {
        ...state,
        seatSiteLicenseInfo: action.data,
        loading: false
      };
    case LICENSE_INFORMATION.FAILURE:
    case SEAT_SITE_LICENSE_INFORMATION.FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch license information. Please try again'
      };
    case UPDATE_ASSIGNED_SEAT_SITE_LICENSES:
      return {
        ...state,
        seatSiteLicenseInfo: {
          ...state.seatSiteLicenseInfo,
          assignedSeatSiteLicenses: state.seatSiteLicenseInfo.assignedSeatSiteLicenses + action.value
        }
      };
    case EMPTY_ASSIGNED_SEAT_SITE_LICENSES:
      return {
        ...state,
        seatSiteLicenseInfo: null
      };

    case IS_SPC_USER_ACCESS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case IS_SPC_USER_ACCESS.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case IS_SPC_USER_ACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getlicenseInformation = {
  base: (licId) => ({
    type: LICENSE_INFORMATION.BASE,
    url: 'license/managelicenseassignments',
    licId
  }),
  request: () => ({ type: LICENSE_INFORMATION.REQUEST }),
  success: data => ({ type: LICENSE_INFORMATION.SUCCESS, data }),
  failure: error => ({ type: LICENSE_INFORMATION.FAILURE, error }),
};

export const updatelicenseSpcAccess = {
  base: (licId, isAccess, callback) => ({
    type: IS_SPC_USER_ACCESS.BASE,
    url: 'license/updatespcuseraccess',
    licId,
    isAccess,
    callback
  }),
  request: () => ({ type: IS_SPC_USER_ACCESS.REQUEST }),
  success: data => ({ type: IS_SPC_USER_ACCESS.SUCCESS, data }),
  failure: error => ({ type: IS_SPC_USER_ACCESS.FAILURE, error }),
};

export const updateAssignedSeatSiteLicenses = (value) => ({
  type: UPDATE_ASSIGNED_SEAT_SITE_LICENSES,
  value
});

export const emptyAssignedSeatSiteLicenses = () => ({
  type: EMPTY_ASSIGNED_SEAT_SITE_LICENSES
});

const getseatSiteLicenseInformation = {
  base: () => ({
    type: SEAT_SITE_LICENSE_INFORMATION.BASE
  }),
  request: () => ({ type: SEAT_SITE_LICENSE_INFORMATION.REQUEST }),
  success: data => ({ type: SEAT_SITE_LICENSE_INFORMATION.SUCCESS, data }),
  failure: error => ({ type: SEAT_SITE_LICENSE_INFORMATION.FAILURE, error }),
};

export function* UpdateSpcUserAccessSaga({
  url,
  licId,
  isAccess,
  callback
}) {
  try {
    // url = `Profile/GetOrderedTPSolutionsForAgencyOrg?orgId=${action.payload.params1}`;
    updatelicenseSpcAccess.request();
    const data = { LicId: licId, IsAccess: isAccess };
    yield call(() => fetchEntity(updatelicenseSpcAccess, ApiClient.httpClient.post, url, data));
    yield put(updatelicenseSpcAccess.success('records updated successfully'));
    callback();
  } catch (error) {
    yield put(updatelicenseSpcAccess.failure(error));
  }
}

export function* getlicenseInformationSaga({ url, licId }) {
  try {
    yield put(emptyAssignedSeatSiteLicenses());
    const response = yield call(() => fetchEntity(getlicenseInformation, ApiClient.httpClient.get, url, { params: { licId } }));
    if (response.data.licenseType === licenseTypes.enterprise) {
      yield all([
        put(getAssignedMembers.base()),
        put(getUnassignedMembers.base(null)),
        put(getAssignedPccs.base()),
        put(getUnassignedPccs.base()),
      ]);
    } else if (response.data.licenseType === licenseTypes.seat) {
      yield call(() => fetchEntity(getseatSiteLicenseInformation, ApiClient.httpClient.get, 'license/getseatsitelicensesinformation', { params: { licId } }));
      yield all([
        put(getAssignedMembers.base()),
        put(getUnassignedMembers.base(null))
      ]);
    } else if (response.data.licenseType === licenseTypes.site) {
      yield call(() => fetchEntity(getseatSiteLicenseInformation, ApiClient.httpClient.get, 'license/getseatsitelicensesinformation', { params: { licId } }));
      yield all([
        put(getAssignedPccs.base()),
        put(getUnassignedPccs.base()),
      ]);
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export default licenseInformation;
