import { combineReducers } from 'redux';
import applicationLayout from './applicationReducer';
import distributionReducer from './Distribution/distributionReducer';
import priceByLocationReducer from './Distribution/priceByLocationReducer';
import { communicationReducer } from './Communication/communicationReducer';
import { classificationReducer } from './Classification/classificationReducer';
import { supportReducer } from './Support/supportReducer';
import { basicInformationReducer } from './BasicInformation/basicInformationReducer';
import { mediaReducer } from './Media/mediaReducer';
import audienceReducer from './Audience/audienceReducer';
import applicationCommonReducer from './applicationCommonReducer';
import { reviewReducer } from './Review/reviewReducer';
import termsAndConditionsDisplay from './TermsAndConditionsDisplay/termsAndConditionsDisplay';

export {
  watchGetUserNamesSaga,
  // watchGetOrganizationNamesSaga,
  // watchGetPccOrgSaga,
  watchInitAudiencePageSaga,
  watchSaveAudiencePageSaga,
  watchDeleteAccessCategorySaga,
  watchVisibilityNonParticipantsSaga,
  watchVisibilityEmployeesSaga,
  watchGlobalVisibilitySaga,
  // watchGetOrgNameFromPccSaga,
  // watchGetPccListSaga
} from './Audience/audienceReducer';

export {
  getUserNamesSelector,
  getOrgNamesSelector,
  getPccOrgNamesSelector,
  getAccessCategorySelectorForApplication,
  productAccessListSelector,
  totalPagesSelector,
  getVisibilitySelector,
  getPccListSelector
} from './Audience/selectors';

export {
  initAudiencePage,
  deleteAudienceAccess,
  changeGlobalAssignment,
  changeVisibilityNonParticipants,
  changeVisibilityTPEmployees,
  // getOrganizationNames,
  // getPccOrg,
  getUserNames,
  saveAudienceAccess,
  // getPccList,
  // getOrgNameFromPcc
} from './Audience/actions';

export {
  clearValues,
  CLEAR
} from './applicationCommonReducer';

export {
  createClassificationRequest,
  getClassificationSelector
} from './Classification/selector';

export {
  savePriceByLocation,
  getPriceByLocation,
  deletePriceByLocation,
  watchSavePriceByLocationSaga,
  watchDeletePriceByLocationSaga,
  watchGetPriceByLocationSaga
} from './Distribution/priceByLocationReducer';

export {
  createDistributionRequest,
  getDistributionSelector,
  getListsDistributionSelector,
  priceByLocationSelector
} from './Distribution/selector';

export {
  createCommunicationRequest,
} from './Communication/selector';

export {
  getDistributionForm,
  uploadPlugin,
  watchGetDistributionSaga,
  watchUploadPluginSaga
} from './Distribution/distributionReducer';

export {
  fetchCommunicationDetails,
  fetchCommunicationDetailsSaga,
  watchFetchCommunicationDetailsSaga
} from './Communication/communicationReducer.js';

export {
  fetchClassificationDetails,
  fetchClassificationDetailsSaga,
  watchFetchClassificationDetailsSaga,
  fetchListForClassificationDetails,
  fetchListForClassificationDetailsSaga,
  watchFetchListForClassificationDetailsSaga,
  displayErrorMessage,
  hostIdsEmpty,
  storeKeywords,
  storeIds
} from './Classification/classificationReducer';

export {
  fetchBasicInformationDetails,
  fetchBasicInformationDetailsSaga,
  watchFetchBasicInformationDetailsSaga
} from './BasicInformation/basicInformationReducer.js';

export {
  createBasicInformationRequest
} from './BasicInformation/selector';

export {
  fetchMediaDetails,
  fetchMediaDetailsSaga,
  watchFetchMediaDetailsSaga,
  saveMediaApplicationLogo,
  removeMediaApplicationLogo,
  saveMediaApplicationScreenshot,
  removeMediaApplicationScreenshot,
  addVideoMedia,
  watchSaveApplicationLogoSaga,
  watchRemoveApplicationLogoSaga,
  watchSaveApplicationScreenshotSaga,
  watchRemoveApplicationScreenshotSaga,
  watchAddVideoMediaSaga
} from './Media/mediaReducer.js';

export {
  getReviewSubmit,
  uploadCertificate,
  deleteCertificate,
  downloadCertificate,
  watchGetReviewSaga,
  watchUploadCertificateSaga,
  watchDeleteCetificateSaga,
  watchDownloadCertificateSaga,
  clear
} from './Review/reviewReducer';

export {
  createMediaRequest,
  mediaSelector
} from './Media/selector';

export {
  saveReviewSubmit,
  updateId,
  saveForm,
  newApplicationIds,
  updateCurrentForm,
  showServerErrors,
  dismissServerErrors,
  // watchGetFormSaga,
  watchSaveFormSaga,
  watchGoToRouteSaga,
  goToRoute,
  getSolnGuideUrl,
  uploadCloudBuild,
  ppcpFormConfirmation,
  watchppcpFormModifiedSaga,
  watchCheckPPCPConfirmation,
  watchSaveReviewSaga,
  watchGetSolnUploadGuideUrl,
  watchUploadCloudBuildSaga
} from './applicationReducer';

export {
  fetchSupportDetails,
  fetchSupportDetailsSaga,
  watchFetchSupportDetailsSaga,
  saveSupportDocumentation,
  saveSupportDocumentationSaga,
  watchSaveSupportDocumentationSaga,
  addSupportDocument,
  addSupportDocumentSaga,
  watchAddSupportDocumentSaga,
  deleteSupportDocument,
  deleteSupportDocumentSaga,
  watchDeleteSupportDocumentSaga,
  updateSupportDocuments,
  updateSupportContactFields
} from './Support/supportReducer.js';

export {
  watchTermsAndConditionsDisplaySaga
} from './TermsAndConditionsDisplay/termsAndConditionsDisplay';

export {
  applicationSelector
} from './selectors';

export {
  createSupportRequest,
} from './Support/selector';

export default combineReducers({
  applicationLayout,
  priceByLocation: applicationCommonReducer(priceByLocationReducer),
  distribution: applicationCommonReducer(distributionReducer),
  communication: applicationCommonReducer(communicationReducer),
  media: applicationCommonReducer(mediaReducer),
  audienceAccess: applicationCommonReducer(audienceReducer),
  classification: applicationCommonReducer(classificationReducer),
  basicInformation: applicationCommonReducer(basicInformationReducer),
  support: supportReducer,
  review: reviewReducer,
  termsAndConditionsDisplay
});
