import {
  takeLatest, call, put, select
} from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import fetchEntity from '../../../utils/fetchEntitySaga';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';
import { mediaSelector } from './selector';
import { updateFormApplication } from '../applicationReducer';

export const FETCH_MEDIA_DETAILS = createFetchTypes('Marketplace/Application/Media/FETCH_MEDIA_DETAILS');
export const SAVE_MEDIA_APPLICATION_LOGO = createFetchTypes('Marketplace/Application/Media/SAVE_MEDIA_APPLICATION_LOGO');
export const REMOVE_MEDIA_APPLICATION_LOGO = createFetchTypes('Marketplace/Application/Media/REMOVE_MEDIA_APPLICATION_LOGO');
export const SAVE_MEDIA_APPLICATION_SCREENSHOT = createFetchTypes('Marketplace/Application/Media/SAVE_MEDIA_APPLICATION_SCREENSHOT');
export const REMOVE_MEDIA_APPLICATION_SCREENSHOT = createFetchTypes('Marketplace/Application/Media/REMOVE_MEDIA_APPLICATION_SCREENSHOT');
export const ADD_VIDEO_MEDIA = createFetchTypes('Marketplace/Application/Media/ADD_VIDEO_MEDIA');
const getMediaURL = 'application/getmedia';

const findImageURLs = (screenshotId) => {
  let thumbnailUrl;
  let previewUrl;
  let hasScreenShot;
  let title;
  if (screenshotId === 1) {
    thumbnailUrl = 'screenshot1ThumbnailUrl';
    previewUrl = 'screenshot1PreviewUrl';
    hasScreenShot = 'hasScreenshot1';
    title = 'screenshot1Title';
  } else if (screenshotId === 2) {
    thumbnailUrl = 'screenshot2ThumbnailUrl';
    previewUrl = 'screenshot2PreviewUrl';
    hasScreenShot = 'hasScreenshot2';
    title = 'screenshot2Title';
  } else if (screenshotId === 3) {
    thumbnailUrl = 'screenshot3ThumbnailUrl';
    previewUrl = 'screenshot3PreviewUrl';
    hasScreenShot = 'hasScreenshot3';
    title = 'screenshot3Title';
  } else if (screenshotId === 4) {
    thumbnailUrl = 'screenshot4ThumbnailUrl';
    previewUrl = 'screenshot4PreviewUrl';
    hasScreenShot = 'hasScreenshot4';
    title = 'screenshot4Title';
  }
  return {
    previewUrl,
    thumbnailUrl,
    hasScreenShot,
    title
  };
};

export const mediaReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_MEDIA_DETAILS.REQUEST:
    case SAVE_MEDIA_APPLICATION_LOGO.REQUEST:
    case REMOVE_MEDIA_APPLICATION_LOGO.REQUEST:
    case SAVE_MEDIA_APPLICATION_SCREENSHOT.REQUEST:
    case REMOVE_MEDIA_APPLICATION_SCREENSHOT.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_MEDIA_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.data
        },
        error: null
      };
    case FETCH_MEDIA_DETAILS.FAILURE:
    case SAVE_MEDIA_APPLICATION_LOGO.FAILURE:
    case REMOVE_MEDIA_APPLICATION_LOGO.FAILURE:
    case SAVE_MEDIA_APPLICATION_SCREENSHOT.FAILURE:
    case REMOVE_MEDIA_APPLICATION_SCREENSHOT.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SAVE_MEDIA_APPLICATION_LOGO.SUCCESS:
      return {
        data: {
          ...state.data,
          hasLogo: true,
          logoUrl: action.payload.data
        },
        loading: false,
        error: null
      };
    case REMOVE_MEDIA_APPLICATION_LOGO.SUCCESS:
      return {
        data: {
          ...state.data,
          hasLogo: false,
          logoUrl: ''
        },
        loading: false,
        error: null
      };
    case SAVE_MEDIA_APPLICATION_SCREENSHOT.SUCCESS:
    case REMOVE_MEDIA_APPLICATION_SCREENSHOT.SUCCESS:
      return {
        data: {
          ...state.data,
          ...action.payload.data
        },
        loading: false,
        error: null
      };
    case ADD_VIDEO_MEDIA.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          videoURLs: [...action.payload.data]
        }
      };
    default:
      return state;
  }
};

export const fetchMediaDetails = {
  base: (params) => ({
    type: FETCH_MEDIA_DETAILS.BASE,
    url: getMediaURL,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_MEDIA_DETAILS.REQUEST
  }),
  success: (data) => ({
    type: FETCH_MEDIA_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_MEDIA_DETAILS.FAILURE,
    payload: {
      error
    }
  })
};

export const saveMediaApplicationLogo = {
  base: (params) => ({
    type: SAVE_MEDIA_APPLICATION_LOGO.BASE,
    url: 'application/savelogo',
    params
  }),
  request: () => ({
    type: SAVE_MEDIA_APPLICATION_LOGO.REQUEST
  }),
  success: (data) => ({
    type: SAVE_MEDIA_APPLICATION_LOGO.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: SAVE_MEDIA_APPLICATION_LOGO.FAILURE,
    payload: {
      error
    }
  })
};

export const removeMediaApplicationLogo = {
  base: (params) => ({
    type: REMOVE_MEDIA_APPLICATION_LOGO.BASE,
    url: 'application/removelogo',
    params
  }),
  request: () => ({
    type: REMOVE_MEDIA_APPLICATION_LOGO.REQUEST
  }),
  success: (data) => ({
    type: REMOVE_MEDIA_APPLICATION_LOGO.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: REMOVE_MEDIA_APPLICATION_LOGO.FAILURE,
    payload: {
      error
    }
  })
};

function* updateMediaComplete(action) {
  const params = { publicId: action.params.publicId, organizationId: action.params.organizationId };
  const getMedia = yield ApiClient.httpClient.get(getMediaURL, { params: { ...params } });
  const mediaData = yield select(mediaSelector);
  const resposeData = { ...getMedia.data, videoURLs: mediaData.videoURLs };
  yield put(fetchMediaDetails.success(resposeData));
  yield put(updateFormApplication(resposeData));
}

export const saveMediaApplicationScreenshot = {
  base: (params) => ({
    type: SAVE_MEDIA_APPLICATION_SCREENSHOT.BASE,
    url: 'application/savescreenshot',
    params
  }),
  request: () => ({
    type: SAVE_MEDIA_APPLICATION_SCREENSHOT.REQUEST
  }),
  success: (data) => ({
    type: SAVE_MEDIA_APPLICATION_SCREENSHOT.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: SAVE_MEDIA_APPLICATION_SCREENSHOT.FAILURE,
    payload: {
      error
    }
  })
};

export const addVideoMedia = {
  base: (params) => ({
    type: ADD_VIDEO_MEDIA.BASE,
    params
  }),
  success: (data) => ({
    type: ADD_VIDEO_MEDIA.SUCCESS,
    payload: {
      data
    }
  }),
};

export const removeMediaApplicationScreenshot = {
  base: (params) => ({
    type: REMOVE_MEDIA_APPLICATION_SCREENSHOT.BASE,
    url: 'application/removescreenshot',
    params
  }),
  request: () => ({
    type: REMOVE_MEDIA_APPLICATION_SCREENSHOT.REQUEST
  }),
  success: (data) => ({
    type: REMOVE_MEDIA_APPLICATION_SCREENSHOT.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: REMOVE_MEDIA_APPLICATION_SCREENSHOT.FAILURE,
    payload: {
      error
    }
  })
};

function* removeScreenShotAPI(formData, successResponse, url, action) {
  try {
    yield put(removeMediaApplicationScreenshot.request());
    const response = yield ApiClient.httpClient.delete(url, { params: formData });
    yield put(removeMediaApplicationScreenshot.success(successResponse));
    if (response) {
      yield call(() => updateMediaComplete(action));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(removeMediaApplicationScreenshot.failure(error));
    throw error;
  }
}

export function* removeApplicationScreenshotSaga(action) {
  try {
    const { url } = action;
    yield put(showLoader());

    const formData = {
      id: action.params.publicId,
      screenshotIndex: action.params.screenshotIndex
    };
    const previewURL = findImageURLs(action.params.screenshotIndex).previewUrl;
    const thumbnailURL = findImageURLs(action.params.screenshotIndex).thumbnailUrl;
    const hasScreenshot = findImageURLs(action.params.screenshotIndex).hasScreenShot;
    const { title } = findImageURLs(action.params.screenshotIndex);

    const value = {
      [previewURL]: '',
      [thumbnailURL]: '',
      [hasScreenshot]: false,
      [title]: ''
    };
    yield call(() => removeScreenShotAPI(formData, value, url, action));
    yield put(hideLoader());
  } catch (error) {
    put(showAlertAction(error));
    yield put(hideLoader());
  }
}

function* saveScreenShotAPI(formData, action, url) {
  try {
    yield put(saveMediaApplicationScreenshot.request());

    const imageUrl = yield ApiClient.httpClient.post(url, formData);
    const previewURL = findImageURLs(action.params.screenshotIndex).previewUrl;
    const thumbnailURL = findImageURLs(action.params.screenshotIndex).thumbnailUrl;
    const hasScreenshot = findImageURLs(action.params.screenshotIndex).hasScreenShot;
    const { title } = findImageURLs(action.params.screenshotIndex);
    const response = imageUrl.data;
    const value = {
      [previewURL]: response.previewUrl,
      [thumbnailURL]: response.thumbnailUrl,
      [hasScreenshot]: true,
      [title]: action.params.screenshotTitle
    };
    yield put(saveMediaApplicationScreenshot.success(value));
    if (response) {
      yield call(() => updateMediaComplete(action));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(saveMediaApplicationScreenshot.failure(error));
    throw error;
  }
}

export function* saveApplicationScreenshotSaga(action) {
  try {
    const { url } = action;
    yield put(showLoader());

    const formData = new FormData();
    formData.append('id', action.params.publicId);
    formData.append('screenshot', action.params.image);
    formData.append('screenshotIndex', action.params.screenshotIndex);
    formData.append('screenshotTitle', action.params.screenshotTitle);

    yield call(() => saveScreenShotAPI(formData, action, url));
    yield put(hideLoader());
  } catch (error) {
    put(showAlertAction(error));
    yield put(hideLoader());
  }
}

export function* removeApplicationLogoSaga(action) {
  try {
    yield put(showLoader());
    const formData = {
      id: action.params.publicId
    };
    const { url } = action;
    const response = yield call(() => fetchEntity(removeMediaApplicationLogo, ApiClient.httpClient.delete, url, { params: formData }));
    if (response) {
      yield call(() => updateMediaComplete(action));
    }
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* fetchMediaDetailsSaga(action) {
  try {
    const { params } = action.payload;
    let { url } = action;
    if (params) {
      url = `${action.url}?publicId=${params.publicId}&organizationId=${params.organizationId}&previewAfterSave=${params.previewAfterSave}`;
    }
    yield call(() => fetchEntityPPCP(fetchMediaDetails, ApiClient.httpClient.get, url));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* saveApplicationLogoSaga(action) {
  try {
    yield put(showLoader());
    const formData = new FormData();
    formData.append('id', action.params.publicId);
    formData.append('appIcon', action.params.image);
    const { url } = action;
    const response = yield call(() => fetchEntity(saveMediaApplicationLogo, ApiClient.httpClient.post, url, formData));
    if (response) {
      yield call(() => updateMediaComplete(action));
    }
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* addVideoMediaSaga(action) {
  try {
    yield put(addVideoMedia.success(action.params.videoList));
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* watchFetchMediaDetailsSaga() {
  yield takeLatest(FETCH_MEDIA_DETAILS.BASE, fetchMediaDetailsSaga);
}

export function* watchSaveApplicationLogoSaga() {
  yield takeLatest(SAVE_MEDIA_APPLICATION_LOGO.BASE, saveApplicationLogoSaga);
}

export function* watchRemoveApplicationLogoSaga() {
  yield takeLatest(REMOVE_MEDIA_APPLICATION_LOGO.BASE, removeApplicationLogoSaga);
}

export function* watchSaveApplicationScreenshotSaga() {
  yield takeLatest(SAVE_MEDIA_APPLICATION_SCREENSHOT.BASE, saveApplicationScreenshotSaga);
}

export function* watchRemoveApplicationScreenshotSaga() {
  yield takeLatest(REMOVE_MEDIA_APPLICATION_SCREENSHOT.BASE, removeApplicationScreenshotSaga);
}

export function* watchAddVideoMediaSaga() {
  yield takeLatest(ADD_VIDEO_MEDIA.BASE, addVideoMediaSaga);
}
