import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ToolTip from '../../common/ToolTip';

const TooltipField = ({
  tooltip,
  ...props
}) => (
  <div>
    <Field {...props} />
&nbsp;
    <ToolTip labelName={tooltip} />
  </div>
);

TooltipField.propTypes = {
  tooltip: PropTypes.string
};

export default TooltipField;
