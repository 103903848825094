/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PagingState,
  CustomPaging,
  SortingState,
  TableColumnResizing
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Table,
  PagingPanel,
  VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  exportProductEventReport,
  fetchproductEventReport,
  fetchProductSelectList
} from 'reducers/modules/Profile/index';
import FilterForm from './FilterForm';
import DateTimeHelper from '../../../utils/dateHelper';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import ExcelDownloadPresentation from '../../common/Reports/ExcelDownloadPresentation';

class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'event', title: 'Event' },
      { name: 'product', title: 'Product' },
      { name: 'customerEmail', title: 'Email' },
      { name: 'organization', title: 'Organization' },
      { name: 'pcc', title: 'PCC' },
      { name: 'signOn', title: 'SignOn' },
      { name: 'country', title: 'Country' },
      { name: 'dateTime', title: 'DateTime' },
    ];
    this.state = {
      filters: {
        productId: 0,
        searchString: '',
        startDate: moment(new Date(moment.utc().format())).subtract(2, 'years'),
        endDate: new Date(moment.utc().format()),
        excludeDemos: false,
        excludeDownloads: false,
        excludeOrders: false,
        excludeTPEmployees: true,
        excludeAutoUpdates: true
      },
      sorting: {
        sortBy: 'DateTime',
        direction: 'asc',
      },
      paging: {
        currentPage: 0,
        pageSize: 100,
      },
      columnWidths: [
        { columnName: 'event', width: 100 },
        { columnName: 'product', width: 200 },
        { columnName: 'customerEmail', width: 200 },
        { columnName: 'organization', width: 200 },
        { columnName: 'pcc', width: 100 },
        { columnName: 'signOn', width: 100 },
        { columnName: 'country', width: 100 },
        { columnName: 'dateTime', width: 200 }
      ]
    };
  }

  componentDidMount() {
    document.title = 'Sales Report';
    if (this.props.username) {
      this.props.getProductSelectList({ username: this.props.username });
      this.getProductEventReport();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.username && this.props.username !== prevProps.username) {
      this.props.getProductSelectList({ username: this.props.username });
      this.getProductEventReport();
    }
  }

  getFiltersToApply = () => (
    {
      ...this.state.filters,
      sortAsc: this.state.sorting.direction === 'asc',
      sortOnColumn: this.state.sorting.sortBy,
      currentPage: this.state.paging.currentPage,
      username: this.props.username
    }
  );

  getProductEventReport = () => (this.props.getProductEventReport(this.getFiltersToApply()))

  datehandler = (e, field) => {
    const stateFilters = this.state.filters;
    stateFilters[field] = DateTimeHelper.getDate(e);
    this.setState({
      filters: stateFilters
    });
  };

  exportProductEventReport = () => (this.props.exportProductEventReport(this.getFiltersToApply()))

  filterSalesReport = (filterValues) => {
    const filters = {
      ...filterValues,
      // startDate: filterValues.startDate && DateTimeHelper.getDate(filterValues.startDate),
      // endDate: filterValues.endDate && DateTimeHelper.getDate(filterValues.endDate),
      startDate: this.state.filters.startDate,
      endDate: this.state.filters.endDate,
      excludeDemos: !filterValues.includeDemos,
      excludeDownloads: !filterValues.includeDownloads,
      excludeOrders: !filterValues.includeOrders,
    };
    this.state.paging.currentPage = 0;
    this.setState({ filters }, () => this.getProductEventReport());
  }

  resetFilterSalesReport = () => {
    const filters = {
      productId: 0,
      searchString: '',
      startDate: moment(new Date(moment.utc().format())).subtract(2, 'years'),
      endDate: new Date(moment.utc().format()),
      includeDemos: true,
      includeDownloads: true,
      includeOrders: true,
      excludeTPEmployees: true,
      excludeAutoUpdates: true
    };
    this.setState({ filters }, () => this.getProductEventReport());
  }

  sortHandler = (newSorting) => {
    let sortByCol = newSorting[0].columnName;
    sortByCol = sortByCol.charAt(0).toUpperCase() + sortByCol.slice(1);
    if (sortByCol === 'Product') {
      sortByCol = 'ProductName';
    } else if (sortByCol === 'Event') {
      sortByCol = 'EventType';
    } else if (sortByCol === 'UserId') {
      sortByCol = 'UserName';
    } else if (sortByCol === 'Organization') {
      sortByCol = 'OrganizationName';
    } else if (sortByCol === 'Country') {
      sortByCol = 'UserCountry';
    }
    this.setState({
      sorting: {
        sortBy: sortByCol,
        direction: newSorting[0].direction
      }
    }, () => this.getProductEventReport());
  }

  pageHandler = (currentPage) => this.setState({ paging: { ...this.state.paging, currentPage } }, () => this.getProductEventReport());

  pageSizeHandler = (pageSize) => this.setState({ paging: { ...this.state.paging, pageSize } }, () => this.getProductEventReport());

  render() {
    if (this.props.productSelectList.loading === undefined || this.props.productEventReport.loading === undefined) {
      return <LoaderComponent show />;
    }
    return (
      <div className="container">
        {/* Loader */}
        {(this.props.productSelectList.loading || this.props.productEventReport.loading || this.props.export.loading)
          && <LoaderComponent show />}
        <div className="space-top">
          {/* Filter Section */}
          {!this.props.productSelectList.loading
            && <FilterForm productList={this.props.productSelectList.data} onSubmit={this.filterSalesReport} startDate={this.state.filters.startDate} endDate={this.state.filters.endDate} datehandler={this.datehandler} resetHandler={this.resetFilterSalesReport} />}
          {/* ExcelDownloadLink */}
          {!this.props.productEventReport.loading && this.props.productEventReport.data.length > 0
            && <ExcelDownloadPresentation onClick={() => this.exportProductEventReport()} />}
          {/* Sales Report Table */}
          <div className="travelport-table-sticky travelport-table-sticky-height">
            <Grid columns={this.columnDefinitions} rows={this.props.productEventReport.data}>
              <VirtualTable />
              <PagingState
                currentPage={this.state.paging.currentPage}
                onCurrentPageChange={this.pageHandler}
                pageSize={this.state.paging.pageSize}
                onPageSizeChange={this.pageSizeHandler}
              />
              <CustomPaging totalCount={this.props.productEventReport.totalPages * this.state.paging.pageSize} />
              <SortingState
                sorting={this.sorting}
                onSortingChange={this.sortHandler}
              />
              <Table />
              <TableColumnResizing
                columnWidths={this.state.columnWidths}
                onColumnWidthsChange={this.state.onColumnWidthsChange}
              />
              <TableHeaderRow showSortingControls />
              <PagingPanel pageSizes={[]} />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

SalesReport.propTypes = {
  productSelectList: PropTypes.object,
  productEventReport: PropTypes.object,
  export: PropTypes.object,
  getProductEventReport: PropTypes.func,
  getProductSelectList: PropTypes.func,
  exportProductEventReport: PropTypes.func,
  currentUser: PropTypes.object,
  username: PropTypes.string
};

const mapStateToProps = (state) => ({
  productSelectList: state.profile.salesReport.productSelectList,
  productEventReport: state.profile.salesReport.productEventReport,
  export: state.profile.salesReport.export,
  currentUser: state.oidc.user,
  username: state.profile.userProfile.userDetails.username
});

const mapDispatchToProps = (dispatch) => ({
  getProductEventReport: (args) => dispatch(fetchproductEventReport.base(args)),
  getProductSelectList: (args) => dispatch(fetchProductSelectList.base(args)),
  exportProductEventReport: (args) => dispatch(exportProductEventReport.base(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);
