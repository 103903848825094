/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UpdateLink = (props) => (
  <div className="update-image-effect">
    <Link to="#" onClick={() => props.showModal()}>update</Link>
  </div>
);

const AgencyLogo = props => (
  <div className="row">
    <div className="col-sm-1" id="profile-org-logo">
      <img alt="Logo" id="profile-org-image" src={props.orgImg} />
      <UpdateLink showModal={() => props.showModal()} />
    </div>
  </div>
);

AgencyLogo.propTypes = {
  orgImg: PropTypes.string,
  editAgencyProfile: PropTypes.bool,
  showModal: PropTypes.func,
  orgName: PropTypes.string
};

UpdateLink.propTypes = {
  showModal: PropTypes.func
};

export default AgencyLogo;
