import {
  takeLatest, put, call, take, race
} from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

const GET_BUILDS = createFetchTypes('Marketplace/admin/GET_BUILDS');
const UNRELEASE_OPTION = createFetchTypes('Marketplace/admin/UNRELEASE_OPTION');
const UPLOAD_BUILD = createFetchTypes('Marketplace/admin/UPLOAD_BUILD');
const CREATE_VERIFICATION = createFetchTypes('Marketplace/admin/CREATE_VERIFICATION');
const RELEASE_OPTION = createFetchTypes('Marketplace/admin/RELEASE_OPTION');
const CLEAR = 'Marketplace/admin/buildVersionControl/CLEAR';
const ENABLE_AUTOUPDATE = createFetchTypes('Marketplace/admin/ENABLE_AUTOUPDATE');
const DELETE_UNRELEASE_VERISON = createFetchTypes('Marketplace/admin/DELETE_UNRELEASE_OPTION');
const GET_USER_DETAILS = createFetchTypes('Marketplace/admin/buildVersionControl/GET_USER_DETAILS');

const buildVersionControl = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_BUILDS.REQUEST:
    case UNRELEASE_OPTION.REQUEST:
    case CREATE_VERIFICATION.REQUEST:
    case RELEASE_OPTION.REQUEST:
    case DELETE_UNRELEASE_VERISON.REQUEST:
    case GET_USER_DETAILS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_BUILD.REQUEST:
      return {
        ...state,
        uploading: true,
        loading: true,
      };
    case GET_BUILDS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UNRELEASE_OPTION.SUCCESS: {
      return {
        ...state,
        ...action.payload.buildsInformation
      };
    }
    case UPLOAD_BUILD.SUCCESS:
      return {
        ...state,
        previousBuilds: [action.payload.build, ...state.previousBuilds],
        currentBuild: { ...action.payload.build },
        uploading: false,
        loading: false
      };
    case CREATE_VERIFICATION.SUCCESS:
    case DELETE_UNRELEASE_VERISON.SUCCESS:
      return {
        ...state,
        ...action.payload.buildsInformation,
        loading: false,
      };
    case RELEASE_OPTION.SUCCESS: {
      return {
        ...state,
        ...action.payload.response.data.buildsInformation,
        loading: false,
      };
    }
    case GET_USER_DETAILS.SUCCESS:
      return {
        ...state,
        userDetails: action.data
      };
    case GET_BUILDS.FAILURE:
    case RELEASE_OPTION.FAILURE:
    case GET_USER_DETAILS.FAILURE:
    case ENABLE_AUTOUPDATE.SUCCESS:
    case ENABLE_AUTOUPDATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UNRELEASE_OPTION.FAILURE:
    case CREATE_VERIFICATION.FAILURE:
    case DELETE_UNRELEASE_VERISON.FAILURE:
      return {
        ...state,
        error: action.error && action.error.response && action.error.response.data,
        loading: false,
      };
    case UPLOAD_BUILD.FAILURE:
      return {
        ...state,
        error: action.error.response.data,
        uploading: false,
        loading: false
      };
    case ENABLE_AUTOUPDATE.REQUEST:
      return {
        ...state,
        loading: true
      };
    case CLEAR:
      return {};
    default:
      return state;
  }
};

export const getBuilds = {
  base: (data) => ({
    type: GET_BUILDS.BASE,
    url: 'distribution/getbuilds',
    data
  }),
  request: () => ({ type: GET_BUILDS.REQUEST }),
  success: payload => ({ type: GET_BUILDS.SUCCESS, payload }),
  failure: error => ({ type: GET_BUILDS.FAILURE, error }),
};

export const enableAutoUpdate = {
  base: (data) => ({
    type: ENABLE_AUTOUPDATE.BASE,
    url: 'distribution/enableautoupdate',
    data
  }),
  request: () => ({ type: ENABLE_AUTOUPDATE.REQUEST }),
  success: payload => ({ type: ENABLE_AUTOUPDATE.SUCCESS, payload }),
  failure: error => ({ type: ENABLE_AUTOUPDATE.FAILURE, error })
};

export const unreleaseOption = {
  base: (data) => ({
    type: UNRELEASE_OPTION.BASE,
    url: 'distribution/unreleasebuild',
    data
  }),
  request: () => ({ type: UNRELEASE_OPTION.REQUEST }),
  success: payload => ({ type: UNRELEASE_OPTION.SUCCESS, payload }),
  failure: error => ({ type: UNRELEASE_OPTION.FAILURE, error })
};

export const createVerification = {
  base: (data) => ({
    type: CREATE_VERIFICATION.BASE,
    url: 'distribution/addbuildverification',
    data
  }),
  request: () => ({ type: CREATE_VERIFICATION.REQUEST }),
  success: payload => ({ type: CREATE_VERIFICATION.SUCCESS, payload }),
  failure: error => ({ type: CREATE_VERIFICATION.FAILURE, error })
};

export const uploadBuild = {
  base: (data) => ({
    type: UPLOAD_BUILD.BASE,
    url: 'distribution/uploadbuild',
    data
  }),
  request: () => ({ type: UPLOAD_BUILD.REQUEST }),
  success: payload => ({ type: UPLOAD_BUILD.SUCCESS, payload }),
  failure: error => ({ type: UPLOAD_BUILD.FAILURE, error })
};

export const releaseOption = {
  base: (data) => ({
    type: RELEASE_OPTION.BASE,
    url: 'distribution/releasebuild',
    data
  }),
  request: () => ({ type: RELEASE_OPTION.REQUEST }),
  success: payload => ({ type: RELEASE_OPTION.SUCCESS, payload }),
  failure: error => ({ type: RELEASE_OPTION.FAILURE, error })
};

export const deleteUnreleasedVersion = {
  base: (data) => ({
    type: DELETE_UNRELEASE_VERISON.BASE,
    url: 'distribution/removebuild',
    data
  }),
  request: () => ({ type: DELETE_UNRELEASE_VERISON.REQUEST }),
  success: payload => ({ type: DELETE_UNRELEASE_VERISON.SUCCESS, payload }),
  failure: error => ({ type: DELETE_UNRELEASE_VERISON.FAILURE, error })
};

export const getUserDetails = {
  base: (username) => ({
    type: GET_USER_DETAILS.BASE,
    url: 'distribution/getusersdetails',
    username
  }),
  request: () => ({ type: GET_USER_DETAILS.REQUEST }),
  success: data => ({ type: GET_USER_DETAILS.SUCCESS, data }),
  failure: error => ({ type: GET_USER_DETAILS.FAILURE, error }),
};

export const clear = () => ({ type: CLEAR });

export function* getBuildsSaga({ url, data }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getBuilds, ApiClient.httpClient.get, url, { params: { versionId: data } }));
  } catch (error) {
    yield put(getBuilds.failure());
  }
  yield put(hideLoader());
}

export function* unreleaseOptionSaga({ url, data }) {
  yield put(
    showConfirmationModal('Are you sure you want to un-release this build?',
      'Once the build is un-released, it will be available to be deleted or re-released again and AutoUpdate Status will be turned off.')
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });
  if (accept) {
    yield put(showLoader());
    try {
      yield call(() => fetchEntity(unreleaseOption, ApiClient.httpClient.post, `${url}/${data}`));
      yield put(showAlertAction('Successfully unreleased the build'));
    } catch (error) {
      yield put(showAlertAction(error && error.response && error.response.data));
    }
    yield put(hideLoader());
  }
}

export function* createVerificationSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(createVerification, ApiClient.httpClient.post, url, data));
    yield put(showAlertAction('Build has been Verified successfully'));
    data.onSuccess();
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
    data.onFail();
  }
}

export function* uploadBuildSaga({ url, data }) {
  try {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('buildVersion', data.version);
    formData.append('posVersionId', data.posVersionId);
    formData.append('autoUpdate', data.autoUpdate);
    yield put(uploadBuild.request());
    yield call(() => fetchEntity(uploadBuild, ApiClient.httpClient.post, url, formData, { params: { fileName: data.file.name } }));
    data.onSuccess();
    yield put(showAlertAction(`Build #${data.version} has been successfully uploaded`));
  } catch (error) {
    data.onFail();
    yield put(uploadBuild.failure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* releaseOptionSaga({ url, data }) {
  try {
    if (data.changeOrderNumber) {
      yield put(showLoader());
      releaseOption.request();
      const response = yield ApiClient.httpClient.post(url, { ...data });
      const { buildId } = data;
      yield put(releaseOption.success({ buildId, response }));
      yield put(showAlertAction(response.data.responseMessage));
    } else {
      yield put(showAlertAction('You must have ChangeOrderNumber to the build before it can be released.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(releaseOption.failure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

export function* deleteUnreleaseOptionSaga({ url, data }) {
  try {
    yield put(showLoader());
    deleteUnreleasedVersion.request();
    yield call(() => fetchEntity(deleteUnreleasedVersion, ApiClient.httpClient.delete, `${url}/${data.buildId}`));
    yield put(showAlertAction(`Build ${data.versionNumber} has been successfully removed.`));
  } catch (error) {
    yield put(deleteUnreleasedVersion.failure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

export function* getUserDetailsSaga({ url, username }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getUserDetails, ApiClient.httpClient.get, url, { params: { username } }));
  } catch (error) {
    yield put(getUserDetails.failure(error));
    yield put(showAlertAction('Unable to fetch user details'));
  }
  yield put(hideLoader());
}

export function* enableAutoUpdateSaga({ url, data }) {
  yield put(
    showConfirmationModal('Are you sure you want to change AutoUpdate Status?', '')
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });
  if (accept) {
    yield put(showLoader());
    try {
      enableAutoUpdate.request();
      const response = yield ApiClient.httpClient.post(url, { ...data });
      if (response) {
        yield call(() => fetchEntity(getBuilds, ApiClient.httpClient.get, getBuilds.base().url, { params: { versionId: data.posVersionId } }));
        yield put(enableAutoUpdate.success());
        yield put(showAlertAction(response.data, 4000));
      }
    } catch (error) {
      yield put(enableAutoUpdate.failure(error));
      yield put(showAlertAction(error));
    }
    yield put(hideLoader());
  }
}

export function* watchGetBuildsSaga() {
  yield takeLatest(GET_BUILDS.BASE, getBuildsSaga);
}

export function* watchUnreleaseOptionSaga() {
  yield takeLatest(UNRELEASE_OPTION.BASE, unreleaseOptionSaga);
}

export function* watchCreateVerificationSaga() {
  yield takeLatest(CREATE_VERIFICATION.BASE, createVerificationSaga);
}

export function* watchUploadBuildSaga() {
  yield takeLatest(UPLOAD_BUILD.BASE, uploadBuildSaga);
}

export function* watchReleaseOptionSaga() {
  yield takeLatest(RELEASE_OPTION.BASE, releaseOptionSaga);
}

export function* watchDeleteUnReleaseVersionOptionSaga() {
  yield takeLatest(DELETE_UNRELEASE_VERISON.BASE, deleteUnreleaseOptionSaga);
}

export function* watchGetUserDetailsSaga() {
  yield takeLatest(GET_USER_DETAILS.BASE, getUserDetailsSaga);
}

export function* watchAutoUpdateSaga() {
  yield takeLatest(ENABLE_AUTOUPDATE.BASE, enableAutoUpdateSaga);
}

export default buildVersionControl;
