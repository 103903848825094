/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';

const CustomDevelopment = props => (
  <>
    <label htmlFor="none" className="label-h4">Custom Development</label>
    <ul className="no-list-style no-margin" id="edit-organisation-custom-dev">
      <SimplePrettyCheckBox
        displayText="Offered by our company"
        value={props.isCustomDevelopmentSupported}
        onChange={
          (val) => props.getCustomDevelopment(val)
        }
      />
    </ul>
  </>
);

CustomDevelopment.propTypes = {
  isCustomDevelopmentSupported: PropTypes.bool,
  getCustomDevelopment: PropTypes.func
};

export default CustomDevelopment;
