import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { redirectToProductsPage } from '../../../reducers/Product/Product/productReducers';

const ProductDetailNavigate = (props) => {
  useEffect(() => {
    props.redirectToProductsPage(props.match.params.appId);
  });
  return (
    <div>
      Redirecting to.........
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  redirectToProductsPage: (appId) => dispatch(redirectToProductsPage.base(appId))
});

ProductDetailNavigate.propTypes = {
  match: PropTypes.object,
  redirectToProductsPage: PropTypes.func
};
export default connect(null, mapDispatchToProps)(ProductDetailNavigate);
