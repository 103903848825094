import * as actionType from '../actionType';

export const detailsForTermsLoadSuccess = (data) => ({
  type: actionType.DETAILS_FOR_TERMS_LOAD_SUCCESS,
  payload: {
    data
  }
});

export const detailsForTermsLoadFailure = (error) => ({
  type: actionType.DETAILS_FOR_TERMS_LOAD_FAILURE,
  payload: {
    error
  }
});

export const loadDetailsForTerms = () => ({
  type: actionType.LOAD_DETAILS_FOR_TERMS
});

export const buyApplication = () => ({
  type: actionType.BUY_APPLICATION
});

export const buyApplicationSuccess = (data) => ({
  type: actionType.BUY_APPLICATION_SUCCESS,
  payload: {
    data
  }
});

export const buyApplicationFailure = (error) => ({
  type: actionType.BUY_APPLICATION_FAILURE,
  payload: {
    error
  }
});
