import { put } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import { hideLoader, showLoader } from '../../../../popUps/loaderReducer';
import ApiClient from '../../../../../Services/ApiClient';
import { parseFromTextToTextValueObject } from '../../../../../utils/ObjectManipulators';

export const FETCH_AGENCY_GROUP_SET = 'FETCH_AGENCY_GROUP_SET';
export const fetchAgencyGroupSet = params => ({
  type: FETCH_AGENCY_GROUP_SET,
  payload: {
    params
  }
});
export const FETCH_AGENCY_GROUP_SET_SUCCESS = 'FETCH_AGENCY_GROUP_SET_SUCCESS';
const fetchAgencyGroupSetSuccess = data => ({
  type: FETCH_AGENCY_GROUP_SET_SUCCESS,
  payload: {
    data
  }
});

export const FETCH_AGENCY_GROUP_SET_FAILURE = 'FETCH_AGENCY_GROUP_SET_FAILURE';
const fetchAgencyGroupSetError = error => ({
  type: FETCH_AGENCY_GROUP_SET_FAILURE,
  payload: {
    error
  }
});
export const AGENCY_PLUGIN_SET_FETCHED = 'AGENCY_PLUGIN_SET_FETCHED';
const agencyPluginSetFetched = data => ({
  type: AGENCY_PLUGIN_SET_FETCHED,
  payload: {
    data
  }
});

export const agencyGroupMetaReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case AGENCY_PLUGIN_SET_FETCHED: {
      return {
        ...state,
        assignablePlugins: action.payload.data
      };
    }
    default:
      return state;
  }
};

const agencyGroupIndexReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_AGENCY_GROUP_SET:
      return {
        ...state,
        loading: true,
        data: [],
        error: null
      };
    case FETCH_AGENCY_GROUP_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };

    case FETCH_AGENCY_GROUP_SET_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* fetchAgencyGroupSetSaga(action) {
  yield put(showLoader());
  try {
    const agencyGroupSetResponse = yield ApiClient.agencyGroup.index({
      orgId: action.payload.params
    });

    const pluginSet = yield ApiClient.httpClient.get(
      '/groups/GetSelectListForProductsPurchasedByOrg',
      { params: { orgId: action.payload.params } }
    );
    const agencyGroupSet = agencyGroupSetResponse.data.map(group => ({
      groupId: group.groupId,
      name: group.name,
      members: group.members,
      dateCreatedString: group.dateCreatedString,
      assignedPlugins: parseFromTextToTextValueObject(
        group.commaSeparatedListPlugins,
        pluginSet.data
      )
    }));
    yield put(fetchAgencyGroupSetSuccess(agencyGroupSet));
    yield put(agencyPluginSetFetched(pluginSet.data));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(fetchAgencyGroupSetError(error));
  }
  yield put(hideLoader());
}

export default agencyGroupIndexReducer;
