/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLicenseHistory } from 'reducers/modules/Administration/ManageLicenses/EditLicenseStatus/editLicenseStatus';
import licenseStatusCode from 'constants/licenseStatusCode';

const HandleNotesSection = (props) => (
  <td>
    <i>{props.row.notes}</i>
    {props.row.licenseHistoryCount > 0
      && (
      <>
        <br />
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            props.getLicenseHistory(props.row.rowId);
          }}
        >
          View History
        </Link>
      </>
      )}
  </td>
);

export const handleLicenseCodeUnInstalled = (props) => (props.row.statusType === licenseStatusCode.Uninstalled
  && <div className="prettycheck" />);

HandleNotesSection.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  getLicenseHistory: PropTypes.func
};
handleLicenseCodeUnInstalled.propTypes = {
  row: PropTypes.object
};
const mapDispatchToProps = (dispatch) => ({
  getLicenseHistory: (licenseId) => dispatch(getLicenseHistory.base(licenseId)),
});

export default connect(null, mapDispatchToProps)(HandleNotesSection);
