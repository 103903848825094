import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs } from '@atlas-design-system/react';
import Authorization from 'components/Authorization/Authorization';
import { accessingUserProfile } from 'reducers/modules/Profile/accessingUserProfile';
import { setView } from 'reducers/modules/Auth/Authorization/authorizationReducer';
import { cleanUserProfile } from 'reducers/modules/Profile/getfulluserprofileReducer';
// import UserProfile from './UserProfile/UserProfile';
import { loadUserProfile, loadAllCountry } from '../../actions';
import * as CommonService from '../../Services/Common.Service';
import AgencyTab from './MyAgency/AgencyTab';
import AgencyUsers from './MyAgency/AgencyUsers/AgencyUsers';
import MySolutions from './MySolutions/MySolutions';
import SalesReport from './SalesReport/SalesReport';
import DeveloperProfile from './DeveloperProfile/DeveloperProfile';
import AccountSetting from './UserProfile/AccountSettingTab/AccountSetting';
import * as utils from '../../utils/Common.Utils';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

const TABS = {
  AGENCY_USER: 1,
  MY_SOLUTIONS: 2,
  SALES_REPORT: 3,
  DEVELOPER_PROFILE: 4,
  ACCOUNT_SETTINGS: 5
};

class Profile extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    const selectedTab = utils.getValueFromQueryString(
      props.location,
      'selectedTab'
    );
    this.state = {
      selectedTab: selectedTab ? selectedTab.toLowerCase() : '',
      currentUser: {},
      userId: null,
      defaultActiveTab: '0',
      accessingUserProfilePermission: {},
      agencyUserComponent: null,
      mySolutionsComponent: null,
      developerProfileComponent: null,
      accountSettingsComponent: null
    };
  }

  componentDidMount = () => {
    CommonService.SSOProcessing(this.props.currentUser);
    this.props.cleanUserProfile();
    const redirectSpcloudTab = utils.getValueFromQueryString(
      this.props.location,
      'selectedTab'
    );
    if (redirectSpcloudTab === 'mysolutions') {
      this.setState({ defaultActiveTab: '2' });
      this.loadTabs('2');
    }
    this.props.setView('agencyDetails');
    if (this.props.currentUser) {
      this.initialLoad();
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser !== this.props.currentUser) {
      this.initialLoad();
    }
  }

  initialLoad = () => {
    if (
      this.props.accessingUserProfilePermission
      !== this.state.accessingUserProfilePermission
    ) {
      this.setState({
        accessingUserProfilePermission: this.props
          .accessingUserProfilePermission
      });
    }
    CommonService.getAllCountry().then(res => {
      this.props.loadGetAllCountryAction(res);
    });

    if (this.props.match.path === '/Profile/AgencyProfile/:id') {
      return this.props.accessingUserProfile(
        { id: this.props.match.params.id },
        'agencyProfile'
      );
    }

    if (this.props.match.path === '/Profile/UserProfile/:id') {
      return this.props.accessingUserProfile(
        { id: this.props.match.params.id },
        'userProfile'
      );
    }

    return this.props.accessingUserProfile({ id: null }, 'myAccount');
  };

  handleSelect = key => {
    this.props.setView(key);
  };

  selectTab = (eventkey) => {
    this.props.history.push(`${this.props.location.pathname}?selectedTab=${eventkey}`);
    this.props.setView(eventkey);
  }

  loadTabs = (key) => {
    switch (parseInt(key)) {
      case TABS.AGENCY_USER:
        this.setState({ agencyUserComponent: <AgencyUsers /> });
        break;
      case TABS.MY_SOLUTIONS:
        this.setState({
          mySolutionsComponent: <MySolutions
            onSelect={this.handleSelect}
            setView={this.props.setView}
          />
        });
        break;
      case TABS.DEVELOPER_PROFILE:
        this.setState({ developerProfileComponent: <DeveloperProfile /> });
        break;
      case TABS.ACCOUNT_SETTINGS:
        this.setState({ accountSettingsComponent: <AccountSetting /> });
        break;
      default:
        return '';
    }
    return '';
  }

  render() {
    const permission = this.props.accessingUserProfilePermission.model
      ? this.props.accessingUserProfilePermission.model
      : this.props.accessingUserProfilePermission;
    if (Object.keys(permission).length === 0) {
      return null;
    }
    const canShowMySolution = permission.canUserManageDeveloperProducts
      || permission.canUserManageInternalProducts
      || permission.canUserViewPurchasedSolutionsForOrg
      || permission.canUserViewSolutions;

    const canShowAgencyUsers = (this.props.orgId !== 1) && (permission && permission.canUserViewPurchasedSolutionsForOrg);

    let canShowSalesReport = permission.canViewSalesReport;

    const canShowDeveloperProfile = permission.canViewDeveloperProfile;
    let canShowAccountSettings = permission.canUserViewSettings;

    if (this.props.match.path === '/Profile/AgencyProfile/:id') {
      canShowSalesReport = false;
      canShowAccountSettings = false;
    }
    return (
      <div className="container " id="profileContainer">
        <h2 className="page-title">Profile</h2>
        <div className="row relative-position">
          <div className="alert alert-success alert-style fade alert-background">
            <span />
          </div>
        </div>
        <div className="row profile-parent-box">
          <div className="row profile-child-box margin-remove">
            <Tabs
              defaultActiveKey={this.state.defaultActiveTab}
              id="gtm-myprofile-subnav-add-assign-tab"
              mountOnEnter
              onSelect={this.selectTab}
              onChange={(e) => this.loadTabs(e)}
            >
              <Tabs.Tab eventKey="myagency" title="My Agency">
                <div className="row profile-child-box child-nav-tab no-margin">
                  <AgencyTab
                    permission={permission}
                    onSelect={this.handleSelect}
                    setView={this.props.setView}
                  />
                </div>
              </Tabs.Tab>
              {canShowAgencyUsers ? (
                <Tabs.Tab eventKey="agencyusers" title="Agency Users">
                  <div className="row profile-child-box child-nav-tab no-margin">
                    {this.state.agencyUserComponent}
                  </div>
                </Tabs.Tab>
              ) : <p hidden />}
              {canShowMySolution ? (
                <Tabs.Tab eventKey="mysolutions" title="My Solutions">
                  <div className="row profile-child-box child-nav-tab no-margin">
                    {this.state.mySolutionsComponent}
                  </div>
                </Tabs.Tab>
              ) : <p hidden />}
              {canShowSalesReport ? (
                <Tabs.Tab eventKey="salesreport" title="Sales Report">
                  <SalesReport />
                </Tabs.Tab>
              ) : <p hidden />}
              {canShowDeveloperProfile ? (
                <Tabs.Tab eventKey="developer-profile" title="Developer Profile">
                  {this.state.developerProfileComponent}
                </Tabs.Tab>
              ) : <p hidden />}
              {canShowAccountSettings && this.props.userProfile.userDetails && this.props.userProfile.agencyDetails ? (
                <Tabs.Tab eventKey="accountsettings" title="Account Settings">
                  {this.state.accountSettingsComponent}
                </Tabs.Tab>
              ) : <p hidden />}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.profile.userProfile,
  currentUser: state.oidc.user,
  userId: state.profile.userProfile.userDetails.userId,
  orgId: state.profile.userProfile.userDetails.organizationId,
  accessingUserProfilePermission: state.profile.accessingUserProfileReducer
});

const mapDispatchToProps = dispatch => ({
  loadUserProfileAction: bindActionCreators(loadUserProfile, dispatch),
  loadGetAllCountryAction: bindActionCreators(loadAllCountry, dispatch),
  accessingUserProfile: bindActionCreators(accessingUserProfile.base, dispatch),
  setView: bindActionCreators(setView, dispatch),
  cleanUserProfile: bindActionCreators(cleanUserProfile, dispatch)
});

Profile.propTypes = {
  loadUserProfileAction: PropTypes.func,
  loadGetAllCountryAction: PropTypes.func,
  currentUser: PropTypes.object,
  userProfile: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  showUserProfile: PropTypes.bool,
  accessingUserProfilePermission: PropTypes.object,
  accessingUserProfile: PropTypes.func,
  userId: PropTypes.number,
  orgId: PropTypes.number,
  setView: PropTypes.func,
  cleanUserProfile: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization(Profile, { path: false }));
