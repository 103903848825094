import React, { Component } from 'react';
import { Collapsible } from '@atlas-design-system/react';
import GoOutLink from '../common/GoOutLink';
import '@atlas-design-system/core/dist/styles/partials/collapsible.css';

class TravelAgencyFaqs extends Component {
  render() {
    /* eslint-disable max-len */
    return (
      <>
        <Collapsible header="What is Travelport Marketplace?">
          <hr />
          <div className="sb-collapsible-content">
            <p>
              Travelport Marketplace is an e-commerce website where 3rd party developers and Travelport market solutions to travel agencies worldwide. Travelport Marketplace
              gives immediate access to revenue generating, easy-to-use, time saving products, as well as direct contact to the travel technology talent that our open platform
              attracts.
            </p>
            <p>Travelport Marketplace also provides Agencies with access management, product assignment and reporting tools.</p>
          </div>
        </Collapsible>
        <Collapsible header="What kind of products and services are listed on Travelport Marketplace?">
          <hr />
          <p className="sb-collapsible-content">
            Travelport Marketplace offers travel agencies the latest travel solutions created by Travelport and by Travelport Developer Network members. On Travelport
            Marketplace, agencies can browse, order and experiment solutions for Smartpoint Desktop as well as Mobile and Standalone solutions (back-office systems,
            commission tracking, booking engines, itinerary solutions).
          </p>
        </Collapsible>
        <Collapsible header="What are the recommended browsers to access Travelport Marketplace?">
          <hr />
          <div className="sb-collapsible-content">
            <p>
              Marketplace supports the two most recent versions of
              <b>
                {' '}
                Google Chrome
              </b>
              {' '}
              and
              {' '}
              <b>Internet Explorer</b>
              .
            </p>
            <p>While the site may work on other browsers, you may encounter limitations, such as when attempting to download files.</p>
          </div>
        </Collapsible>
        <Collapsible header="I am signed in but I don’t see the Order/Download button for the product I want. What do I need to do?">
          <hr />
          <p className="sb-collapsible-content">
            To place an order and download products and solutions, your Travelport Account Manager must designate the Agency Administrator role. Please contact your
            Travelport Account Manager to get this role set up for your Agency. As soon as your role is upgraded you’ll be able to order and download products from the website.
            If you already have the Agency Administrator role on the website and you’re still unable to order and download products please raise a ticket via
            {' '}
            <a href="https://newmy.travelport.com/">MyTravelport</a>
            {' '}
            site.
          </p>
        </Collapsible>
        <Collapsible header="Is Travelport Marketplace a global product?">
          <hr />
          <p className="sb-collapsible-content">
            Yes, Travelport Marketplace is available online globally. On the website, agencies can find solutions that are supported globally as well as solutions that are
            relevant to specific countries or regions, worldwide.
          </p>
        </Collapsible>
        <Collapsible header="Does Travelport Marketplace offer Agency Solutions for all Travelport Commerce Platforms (GDS)?">
          <hr />
          <p className="sb-collapsible-content">Yes, it offers solutions for Travelport Apollo, Travelport Galileo, and Travelport Worldspan.</p>
        </Collapsible>
        <Collapsible header="Who can access Travelport Marketplace?">
          <hr />
          <p className="sb-collapsible-content">
            Travelport Marketplace is a public facing website. However, in order to access the administration tools customers need to sign in with their MyTravelport
            credentials.
          </p>
        </Collapsible>
        <Collapsible header="I am a customer. How can I register to use Travelport Marketplace?">
          <hr />
          <p className="sb-collapsible-content">
            Travelport Marketplace requires an active MyTravelport account. To create your MyTravelport account, please
            {' '}
            <GoOutLink linkText="click here" link="https://my.travelport.com/" />
            {' '}
            and register for a new account.
          </p>
        </Collapsible>
        <Collapsible header="What happens when I place an Order?">
          <hr />
          <div className="sb-collapsible-content">
            <p>
              There are two types of listings in Marketplace: products and solutions that are distributed to your agency immediately and products or services that are
              fulfilled by Travelport Developer Network members.
            </p>
            <p>
              In general, solutions for Smartpoint Desktop can be downloaded immediately by agencies (*) and/or assigned and distributed within an agency
              automatically (**).
            </p>
            <p>
              We also offer products and services that are fulfilled through our partner’s websites or through a mobile store. You will receive details after ordering
              and through a confirmation email.
            </p>
            <p>Where additional information may be needed, Travelport or our development partner will contact you within a few days of your request.</p>
            <p>
              *To place an order and/or download products and solutions, your Travelport representative must designate the Agency Administrator role. Please contact your
              Travelport Sales Account Manager to get this role set up for your Agency.
              <br />
              ** Requires installation of Travelport Marketplace on Smartpoint Desktop 7.2 (or greater).
            </p>
          </div>
        </Collapsible>
        <Collapsible header="Who can I contact if I need further information about Travelport Marketplace?">
          <hr />
          <p className="sb-collapsible-content">Please contact your Travelport Sales Account Manager.</p>
        </Collapsible>
      </>
    );
  }
}

export default TravelAgencyFaqs;
