import { put, call } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient.js';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

export const ADD_ORGANIZATION = createFetchTypes('Marketplace/Administration/OrganizationDirectory/ManageAdmins/ADD_ORGANIZATION');

export const addOrganization = {
  base: (customerNumber) => ({
    type: ADD_ORGANIZATION.BASE,
    customerNumber,
    url: 'organizationdirectory/addorganisation'
  }),
  request: () => ({ type: ADD_ORGANIZATION.REQUEST }),
  success: (data) => ({ type: ADD_ORGANIZATION.SUCCESS, data }),
  failure: (error) => ({ type: ADD_ORGANIZATION.FAILURE, error })
};

export function* addOrganizationSaga({ customerNumber, url }) {
  yield put(showLoader());
  try {
    const response = yield call(ApiClient.httpClient.post, `${url}/${customerNumber}`);
    const responseValues = response.data.value ? response.data.value : { ...response.data };
    if (responseValues && responseValues.responseMessage) {
      yield put(showAlertAction(responseValues.responseMessage));
    }
    if (responseValues && responseValues.isAdded) {
      yield put(addOrganization.success(responseValues));
    }
  } catch (error) {
    yield put(showAlertAction('Some error occured. Please try again.'));
    yield put(addOrganization.failure(error));
  }
  yield put(hideLoader());
}
