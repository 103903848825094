/* eslint-disable react/no-did-update-set-state */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHostEntries } from 'reducers/modules/Profile/selectors';
import { getHostList } from 'reducers/modules/Profile/index';
import {
  saveHostEntry,
  removeHostEntry
} from 'reducers/modules/Profile/getfulluserprofileReducer';

import {
  EditingState,
  IntegratedSorting,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-bootstrap4';

import Select from 'react-select';
import SelectHSO from './selectHSO';
import SelectPcc from './SelectPcc';
import './AgencyDetailsHost.less';

const getRowId = row => row.id;

class AgencyDetailsHost extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hostEntries !== prevState.hostEntries) {
      return { hostEntries: nextProps.hostEntries };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'host', title: 'Host', width: 130 },
        { name: 'son', title: 'Host Sign-On', width: 130 },
        { name: 'pcc', title: 'PCC', width: 130 }
      ],
      tableColumnExtensions: [{ columnName: 'id', width: 60 }],
      rows: this.props.hostEntries,
      editingRowIds: [],
      addedRows: [],
      rowChanges: {}
    };
  }

  componentDidMount = () => {
    this.props.getHostListAction();
    this.setState({ rows: this.props.hostEntries });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.hostEntries !== this.props.hostEntries) {
      this.setState({ rows: this.props.hostEntries });
    }
  };

  getRows = (host, transformHostDataValue, changed, changeObj, key, rows) => {
    let rowData;
    if (host) {
      rowData = transformHostDataValue(changed, changeObj, key, rows);
    } else {
      rowData = rows.map(
        row => (changed[row.id] ? { ...row, ...changed[row.id] } : row)
      );
    }
    return rowData;
  }

  changeEditingRowIds = editingRowIds => {
    this.setState({ editingRowIds });
  };

  changeRowChanges = rowChanges => {
    this.setState({ rowChanges });
  };

  normalizeSaveUpdateRow = (row, update) => {
    if (update) {
      const {
        son, pcc, host, hostValue, id
      } = row;
      return {
        username: this.props.userProfile.username,
        hostEntry: {
          ModifyMode: true,
          id: id || null,
          son: son || null,
          pcc: pcc || null,
          host: {
            text: host,
            value: hostValue,
            isSelected: true
          }
        }
      };
    }

    const { son, pcc, host } = row;
    return {
      username: this.props.userProfile.username,
      hostEntry: {
        son: son || null,
        pcc: pcc || null,
        host: {
          value: host,
          isSelected: true
        }
      }
    };
  };

  callUpdateHostEntry = updateRow => {
    this.updateHostEntryForUserProfile(updateRow);
  };

  transformHostDataValue = (changed, changeObj, key, rows) => {
    const hostValue = changeObj.host;
    const hostText = this.props.hostList.filter(
      hostItem => hostItem.value === hostValue
    )[0].text;

    const cloneChangeObj = changeObj;
    cloneChangeObj.host = hostText;
    cloneChangeObj.hostValue = hostValue;

    const tmpObj = { ...changed };

    tmpObj[key] = cloneChangeObj;

    return rows.map(
      row => (tmpObj[row.id] ? { ...row, ...tmpObj[row.id] } : row)
    );
  };

  commitChanges = ({ added, changed, deleted }) => {
    let { rows } = this.state;
    if (added) {
      const row = added[0];
      this.setState({ addedRows: added });
      if (this.isValidRow(row)) {
        this.saveHostEntryForUserProfile(row);
      }
    }

    if (changed) {
      const key = Object.keys(changed)[0];
      const changeObj = changed[Object.keys(changed)[0]];

      if (changeObj) {
        const { host } = changeObj;
        rows = this.getRows(host, this.transformHostDataValue, changed, changeObj, key, rows);
        const updateRow = rows.filter(
          rowItem => Number(rowItem.id) === Number(Object.keys(changed)[0])
        )[0];
        this.callUpdateHostEntry(updateRow);
      }
    }
    if (deleted) {
      const row = rows.filter(rowItem => rowItem.id === deleted[0])[0];
      this.removeHostEntryForUserProfile(row);
    }
  };

  isValidRow = row => {
    if (Object.keys(row).length > 0) {
      return true;
    }
    return false;
  };

  saveHostEntryForUserProfile = row => {
    const saveObj = this.normalizeSaveUpdateRow(row, false);
    this.props.saveHostEntry(saveObj);
  };

  updateHostEntryForUserProfile = row => {
    const tmpHost = this.props.hostList.filter(
      host => host.value === row.host
    )[0];

    const tmpRow = row;
    if (row.length) {
      tmpRow.host = tmpHost.text;
      tmpRow.hostValue = tmpHost.value;
    }
    const updateObj = this.normalizeSaveUpdateRow(tmpRow, true);
    this.props.saveHostEntry(updateObj);
  };

  removeHostEntryForUserProfile = row => {
    const {
      id, son, pcc, hostValue
    } = row;

    const objTmp = {
      username: this.props.userProfile.username,
      hostEntry: {
        id,
        son: son || null,
        pcc: pcc || null,
        host: {
          value: hostValue,
          isSelected: true
        }
      }
    };

    this.props.removeHostEntry(objTmp);
    return false;
  };

  EditCell = props => {
    const { hostList } = this.props;
    if (props.column.name === 'host' && hostList) {
      return this.selectHost(props, hostList);
    }

    if (props.column.name === 'son') {
      return <SelectHSO {...props} />;
    }

    if (props.column.name === 'pcc') {
      return <SelectPcc {...props} />;
    }
    return (
      <div className="inputPcc">
        {' '}
        <TableEditRow.Cell style={{ width: '100%' }} {...props} />
        {' '}
      </div>
    );
  };

  selectHost = (props, hostList) => {
    const hostOptions = hostList.map(data => ({
      value: data.value,
      label: data.text
    }));

    const tmp = hostOptions.filter(option => option.label === props.value);
    let tmpValue = '';
    if (tmp.length > 0) {
      tmpValue = tmp[0].value;
    } else {
      tmpValue = props.value;
    }

    return (
      <td>
        <Select
          className="travelport-select"
          escapeClearsValue={false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          resetValue=""
          value={tmpValue}
          onChange={e => props.onValueChange(e.value)}
          options={hostOptions}
        />
      </td>
    );
  };

  changeAddedRows = row => {
    this.setState({ addedRows: row });
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      editingRowIds,
      rowChanges,
      addedRows
    } = this.state;

    return (
      <div className="travelport-table">
        <div className="row space-top">
          <div className="col-sm-6">
            <span className="agency-details-title">My Host Information</span>
          </div>
        </div>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.commitChanges}
          />
          <SortingState />
          <IntegratedSorting />
          <Table columnExtensions={tableColumnExtensions} />
          <TableHeaderRow showSortingControls />
          <TableEditRow cellComponent={this.EditCell} />
          <TableEditColumn
            showAddCommand={!addedRows.length}
            showEditCommand
            showDeleteCommand
          />
        </Grid>
      </div>
    );
  }
}

AgencyDetailsHost.propTypes = {
  getHostListAction: PropTypes.func,
  hostEntries: PropTypes.array,
  hostList: PropTypes.array,
  userProfile: PropTypes.object,
  saveHostEntry: PropTypes.func,
  removeHostEntry: PropTypes.func
};

const mapStateToProps = state => ({
  hostEntries: getHostEntries(state),
  hostList: state.profile.agency.agencyDetails.hostList,
  userProfile: state.profile.userProfile.userDetails
});

const mapDispatchToProps = dispatch => ({
  getHostListAction: () => dispatch(getHostList.base()),
  saveHostEntry: params => dispatch(saveHostEntry.base(params)),
  removeHostEntry: params => dispatch(removeHostEntry.base(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyDetailsHost);
