/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';

const {
  Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
  AlignLeft, AlignCenter, AlignRight, AlignJustify,
  Indent, Outdent, OrderedList, UnorderedList,
  Undo, Redo, FontSize, FontName, FormatBlock, Link,
  Unlink, InsertImage, ViewHtml,
  InsertTable,
  AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
  DeleteRow, DeleteColumn, DeleteTable,
  MergeCells, SplitCell
} = EditorTools;
const editor = React.createRef();

const MPEditor = ({ getContent, content }) => (
  <Editor
    tools={[
      [Bold, Italic, Underline, Strikethrough],
      [Subscript, Superscript],
      [AlignLeft, AlignCenter, AlignRight, AlignJustify],
      [Indent, Outdent],
      [OrderedList, UnorderedList],
      FontSize, FontName, FormatBlock,
      [Undo, Redo],
      [Link, Unlink, InsertImage, ViewHtml],
      [InsertTable],
      [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
      [DeleteRow, DeleteColumn, DeleteTable],
      [MergeCells, SplitCell]
    ]}
    contentStyle={{ height: 300 }}
    defaultContent={content}
    ref={editor}
    // defaultEditMode="div"
    onBlur={() => {
      const { view } = editor.current;
      const htmlContent = EditorUtils.getHtml(view.state);
      if (htmlContent === '<p></p>') {
        getContent('');
      } else {
        getContent(htmlContent);
      }
    }}
  />
);

MPEditor.propTypes = {
  getContent: PropTypes.func,
  content: PropTypes.string
};
export default MPEditor;
