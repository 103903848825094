import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs } from '@atlas-design-system/react';
import TravelAgencyFaqs from './TravelAgencyFaqs';
import DeveloperFaqs from './DeveloperFaqs';
import * as CommonServices from '../../Services/Common.Service';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      defaultTab: '0',
    };
  }

  componentDidMount() {
    document.title = 'Frequently Asked Questions';
    CommonServices.SSOProcessing(this.props.user);
    window.scrollTo(0, 0);
  }

  handleTabSelect = (key) => {
    if (parseInt(key) !== 2) {
      this.setState({ defaultTab: key });
    }
  }

  render() {
    return (
      <div className="container">
        <Tabs activeKey={this.state.defaultTab} id="faq-tabs" onChange={(e) => this.handleTabSelect(e)}>
          <Tabs.Tab title="Travel Agency FAQs">
            <TravelAgencyFaqs />
          </Tabs.Tab>
          <Tabs.Tab title="Developer FAQs">
            <DeveloperFaqs />
          </Tabs.Tab>
          <Tabs.Tab title={<a rel="noopener noreferrer" target="_blank" href="https://support.travelport.com/webhelp/TravelportMarketplace">Online User Guide</a>} />
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user
});
Faqs.propTypes = {
  user: PropTypes.object
};
export default connect(mapStateToProps, null)(Faqs);
