import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductTile from './ProductTile';

const BrowseAll = () => (
  <div className="col-xs-12 col-sm-3">
    <a className="pop-products-tile pop-products-all" href="/products">
      <span style={{ textDecoration: 'underline' }}>BROWSE ALL PRODUCTS</span>
&nbsp;&gt;
    </a>
  </div>
);

class PopularProducts extends Component {
  render() {
    const products = { ...this.props };
    const productsAvailable = (products.data && products.data.length > 0);

    products.data = (productsAvailable && products.data.length > 11)
      ? products.data.splice(0, 11)
      : products.data;

    return (
      <div className="row pop-products-row">
        {
          productsAvailable
          && products.data.map((v, i) => (
            <ProductTile
              key={i.toString()}
              {...v}
              gtmFeatured={this.props.gtmFeatured}
              showWhiteTile={this.props.showWhiteTile}
            />
          ))
        }
        {this.props.showBrowseAll && <BrowseAll />}
      </div>
    );
  }
}

PopularProducts.propTypes = {
  data: PropTypes.array,
  showBrowseAll: PropTypes.bool,
  gtmFeatured: PropTypes.bool,
  showWhiteTile: PropTypes.bool
};

PopularProducts.defaultProps = {
  data: [],
  showBrowseAll: true,
  gtmFeatured: true,
  showWhiteTile: true
};

export default PopularProducts;
