/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AuthLinks = (props) => {
  if (props.isSmallView) {
    return (
      <>
        {!props.userIsLoggedIn && (
          <>
            <Link
              className="global-nav-item gtm-nav-item"
              onClick={props.loginHandler}
              id="small-nav-login"
              to="#"
            >
              Login
            </Link>
          </>
        )}
      </>
    );
  }
  return (
    <>
      {!props.userIsLoggedIn && (
        <>
          <Link
            id="user-nav-login"
            onClick={props.loginHandler}
            className="gtm-nav-item"
            to="#"
          >
            Login
          </Link>
        </>
      )}
    </>
  );
};

AuthLinks.propTypes = {
  userIsLoggedIn: PropTypes.bool,
  isSmallView: PropTypes.bool,
  loginHandler: PropTypes.func,
  logoutHandler: PropTypes.func,
  userDetails: PropTypes.object,
  ppcpFormConfirmation: PropTypes.func,
  isPPCPFormModified: PropTypes.bool,
  linkAction: PropTypes.func,
  changeUserRole: PropTypes.func,
};

export default AuthLinks;
