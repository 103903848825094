/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DateTimeHelper from 'utils/dateHelper';
import StarComponent from '../../common/StarComponent';
import MpTextCollapse from '../../common/MpTextCollapse/MpTextCollapse';
import RatingsReplyForm from './RatingsReplyForm';

const ProductReviewPresentation = (props) => {
  let ratingLinks = '';
  let replyLinks = '';
  const {
    review, canUserAccessRatingLinks, onDeleteReview, onEditReview
  } = props;
  if (review.isCurrentUser) {
    ratingLinks = (
      <>
        <div className="left pad-left pad-right border-right"><Link to="#" onClick={onEditReview}>Edit My Review</Link></div>
        <div className="left pad-left pad-right"><Link to="#" onClick={() => onDeleteReview(review)}>Delete My Review</Link></div>
      </>
    );
  } else if (canUserAccessRatingLinks) {
    ratingLinks = (
      <div className="left pad-left pad-right">
        <Link to="#" onClick={() => onDeleteReview(review)}>Delete Review</Link>
      </div>
    );
  }
  replyLinks = (
    <>
      {!review.reviewsReply && review.canReplyToReview
        ? <div className="left pad-left pad-right border-left"><Link to="#" onClick={() => props.onReplyClick(props.review.id)}>Reply</Link></div>
        : null}
      {review.repliedOn
        ? (
          <div className="left pad-left border-left">
            <span style={{ color: '#159CDF' }}>
              Replied On
              {DateTimeHelper.getDate(review.repliedOn)}
            </span>
          </div>
        )
        : null}
    </>
  );
  return (
    <div className={`row no-space review ${review.isCurrentUser ? 'self-review' : ''}`} key={review.id}>
      <div className="row no-space pad-bottom-sm review-header">
        <div className="col-sm-10 review-title">{review.reviewTitle}</div>
        <div className="col-sm-2">{review.productReviewDate}</div>
      </div>
      <div className="row review-info no-space pad-bottom-sm">
        <div className="left rating-star-div">
          <StarComponent size={22} edit={false} value={review.rating} color2="#4E6659" />
        </div>
        <div className={`left pad-left-sm pad-right ${(review.isCurrentUser || canUserAccessRatingLinks) ? 'border-right' : ''}`}>
          {' '}
          by&nbsp;
          {canUserAccessRatingLinks
            ? <Link to={`/Profile/UserProfile/${review.reviewerUserID}`}>{review.reviewerFirstName}</Link>
            : <span>{review.reviewerFirstName}</span>}
        </div>
        {ratingLinks}
        {replyLinks}
      </div>
      <div className="row no-space pad-bottom-sm review-details">
        <MpTextCollapse text={review.productReview} />
      </div>
      {review.reviewsReply && (
        <div className="row no-space pad-bottom-sm review-response">
          <p>
            {review.reviewsReply}
            {' '}
          </p>
        </div>
      )}
      {(props.replyForm && props.reviewId === props.review.id) && <RatingsReplyForm onSubmit={(reply) => props.onSubmit(reply)} cancel={() => props.cancel()} />}
    </div>
  );
};

ProductReviewPresentation.propTypes = {
  review: PropTypes.object,
  canUserAccessRatingLinks: PropTypes.bool,
  onDeleteReview: PropTypes.func,
  onEditReview: PropTypes.func,
  replyForm: PropTypes.bool,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  onReplyClick: PropTypes.func,
  reviewId: PropTypes.number
};

export default ProductReviewPresentation;
