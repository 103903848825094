export const BASIC_INFORMATION_ROUTE = '/application/basicInformation';
export const CLASSIFICATION_ROUTE = '/application/classification';
export const DISTRIBUTION_ROUTE = '/application/distribution';
export const COMMUNICATION_ROUTE = '/application/communication';
export const AUDIENCE_ROUTE = '/application/audience';
export const MEDIA_ROUTE = '/application/media';
export const SUPPORT_ROUTE = '/application/support';
export const REVIEW_ROUTE = '/application/review';

export const BASIC_INFORMATION_ID = 'basicInformation';
export const CLASSIFICATION_ID = 'classification';
export const DISTRIBUTION_ID = 'distribution';
export const COMMUNICATION_ID = 'communication';
export const AUDIENCE_ID = 'audience';
export const MEDIA_ID = 'media';
export const SUPPORT_ID = 'support';
export const REVIEW_ID = 'review';

export const PRICEBYLOCATION_SALETYPE_NONE = 'None';
export const PRICEBYLOCATION_SALETYPE_RESELLER = 'Reseller';
export const PRICEBYLOCATION_SALETYPE_REFERRAL = 'Referral';

export const DISTRIBUTION_FREE = 'free';
export const DISTRIBUTION_FIXED_PRICE = 'fixedPrice';
export const DISTRIBUTION_CUSTOM = 'custom';
export const DISTRIBUTION_CUSTOM_REQUEST = 'Price available upon request';
export const DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION = 'Custom Pricing Description';
export const DISTRIBUTION_CUSTOM_PRICING_LOCATION = 'Pricing by Location';

export const IS_BILLED_MARKETPLACE = 'isBilledInMarketplace';
export const IS_BILLED_ELSEWHERE = 'isBilledElsewhere';

export const GENERAL = 'general';
export const INTERNAL = 'isInternal';
export const TRAVELPORT_POS_APPLICATION = 'isTravelportPOSApplication';

export const hidePOSApplicationMenus = ['DISTRIBUTION', 'COMMUNICATION', 'AUDIENCE'];
export const hideInternalMenus = ['COMMUNICATION'];
export const REVIEW_AND_SUBMIT = 'REVIEW AND SUBMIT';

export const APPLICATION_CERTIFICATION_ID = 'application_id';
