const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

export const showLoader = () => ({
  type: SHOW_LOADER
});

export const hideLoader = () => ({
  type: HIDE_LOADER
});

export const loaderReducer = (state = { showLoader: false }, action = {}) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { showLoader: true };
    case HIDE_LOADER:
      return { showLoader: false };
    default:
      return state;
  }
};
