/* eslint-disable func-names */
import 'components/Product/ProductPreview/ProductPreview.less';
import React, { Component } from 'react';

export default function (ComposedComponent) {
  return class ProductPreview extends Component {
    componentDidMount() {
      document.title = 'Product Preview';
      document.body.classList.add('previewMargin');
    }

    handleClose = () => {
      window.close();
    };

    render() {
      return (
        <>
          <div id="preview-bar">
            <button type="button" onClick={this.handleClose} className="action-button">
              Close Preview
            </button>
          </div>
          <div className="previewWrapper">
            <ComposedComponent {...this.props} isPreview />
            ;
          </div>
        </>
      );
    }
  };
}
