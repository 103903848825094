import { takeLatest } from 'redux-saga/effects';

import {
  GET_ADMINS_FOR_ORGS,
  ADD_ADMINS_FOR_ORGS,
  UNASSIGN_ADMIN_ROLE_FOR_USER,
  getAdminsForOrgsReducerWatcher,
  addAdminsForOrgsReducerWatcher,
  unassignAdminRoleForUserReducerWatcher
} from './getAdminsForOrg';

export function* manageAdminsMiddleware() {
  yield takeLatest(GET_ADMINS_FOR_ORGS.BASE, getAdminsForOrgsReducerWatcher);
  yield takeLatest(ADD_ADMINS_FOR_ORGS.BASE, addAdminsForOrgsReducerWatcher);
  yield takeLatest(
    UNASSIGN_ADMIN_ROLE_FOR_USER.BASE,
    unassignAdminRoleForUserReducerWatcher
  );
}
