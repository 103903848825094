/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  getDistributionForm,
  updateCurrentForm,
  getPriceByLocation,
  applicationSelector,
  getDistributionSelector,
  getListsDistributionSelector
} from 'reducers/modules/Application';
import { getAllCountries } from 'reducers/modules/countries';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  FormHeader,
  TooltipField,
  SolutionDistribution,
  DistributionPrice
} from 'components/Application';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import {
  DISTRIBUTION_ID,
  DISTRIBUTION_FREE
} from 'constants/PPCP';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import Tooltip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';

class Distribution extends PureComponent {
  componentDidMount() {
    const {
      getDistributionFormAction,
      updateCurrentFormAction,
      getPriceByLocationAction,
      getAllCountriesAction
    } = this.props;
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    updateCurrentFormAction(DISTRIBUTION_ID);
    getPriceByLocationAction({ publicId, organizationId });
    getDistributionFormAction({ publicId, organizationId });
    getAllCountriesAction();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dirty !== this.props.dirty) {
      this.props.updateCurrentFormAction(DISTRIBUTION_ID, this.props.dirty);
    }
  }

  additionalInfoChange = (e, checked) => {
    if (checked) {
      this.props.showMsg(`
        <span>We'll request customer the following additional information and send it to you on the order confirmation email:</span>
        <span>Number of licenses (mandatory); Signon and PCC for each license; Billing address and entity name (mandatory);
Billing e-mail address; VAT number; Billing contact name (mandatory)</span>
      `);
    }
  }

  renderRadioButton = ({
    input, displayText, tooltip, enabled
  }) => (
    <SimplePrettyRadioButton
      {...input}
      displayText={displayText}
      onChange={input.onChange}
      tooltip={tooltip}
      enabled={enabled}
    />
  );

  renderSimplePrettyCheckBox = ({
    input, displayText, enabled, tooltip
  }) => (
    <SimplePrettyCheckBox
      {...input}
      enabled={enabled}
      displayText={displayText}
      tooltip={tooltip}
    />
  );

  renderSolutionDistribution = ({
    input,
    currentVersion,
    uploadVersion,
    displayText,
    instructionsLink,
    tooltip,
    isSpPlugin
  }) => (
    <SolutionDistribution
      {...input}
      currentVersion={currentVersion}
      uploadVersion={uploadVersion}
      displayText={displayText}
      instructionsLink={instructionsLink}
      onChange={input.onChange}
      tooltip={tooltip}
      isSpPlugin={isSpPlugin}
    />
  );

  renderNonInternalForm = () => {
    const {
      isPurchased,
      price,
      priceCustom,
      countries,
      dropdownLists,
      licenseInfoIconText,
      isLicenseChangeDisabled,
      platformId
    } = this.props;

    return (
      <>

        {isPurchased && platformId !== 2 && (
          <div className="pad-bottom-double">
            <em>
              NOTE: This solution has been ordered at least once, if you remove
              a distribution method it could adversly affect customers.
            </em>
          </div>
        )}
        <DistributionPrice
          price={price}
          priceCustom={priceCustom}
          countries={countries}
          currencyList={dropdownLists.currencyList}
          trialDayList={dropdownLists.trialDayList}
        />
        <div className="row pad-top">
          <div className="col-sm-3">
            <div className="pull-left pad-right-sm">
              <h4>
                LICENSING&nbsp;
                <Tooltip labelName="Select the License Type (Enterprise, Site or Seat) granting the end user permission to use one or more copies of your software." />
              </h4>
              <TooltipField
                id="licenseID"
                name="licenseID"
                className="select"
                component="select"
                disabled={isLicenseChangeDisabled}
                value="licenseID"
                tooltip={licenseInfoIconText}
              >
                {dropdownLists.licenseTypeList
                  && dropdownLists.licenseTypeList.map(l => {
                    if (price === DISTRIBUTION_FREE) {
                      /* value 0 is "License Type" and value 1 is "Enterprise" */
                      // if (l.value === '0') {
                      //   return null;
                      // }
                      if (l.value === '0' || l.value === '1') {
                        return (
                          <option key={l.value} value={l.value} disabled={l.value === '0'}>
                            {l.text}
                          </option>
                        );
                      }
                      return null;
                    }
                    return (
                      <option key={l.value} value={l.value} disabled={l.value === '0'}>
                        {l.text}
                      </option>
                    );
                  })}
              </TooltipField>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      handleSubmit,
      distributeElsewhere,
      isNotDistributedVisible,
      isIntegrationDownloadVisible,
      isDistributionElsewhereVisible,
      isDirectDownloadVisible,
      directDownload,
      marketplaceIntegration,
      loading,
      isInternal,
      platformId
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <div hidden={platformId !== 2 ? 'hidden' : ''}>
            <FormHeader
              header="Distribution"
              infoText="Choose the pricing options for your solution."
            />
          </div>
          {loading && <LoaderComponent show />}
          <div hidden={platformId === 2 ? 'hidden' : ''}>
            <FormHeader
              header="Distribution"
              infoText="Choose the distribution and pricing options for your solution."
            />
            <h4 className="required-field-star">SOLUTION DISTRIBUTION</h4>
            {isDirectDownloadVisible && (
              <Field
                id="directDownload"
                name="directDownload"
                type="checkbox"
                displayText="Direct Download from Marketplace"
                isSpPlugin={false}
                component={this.renderSolutionDistribution}
                tooltip={
                  isInternal
                    ? `Select this option if you want your Agency users to be able to download your solution from Travelport Marketplace.
     The file you upload should include an installer.`
                    : 'Select if you wish Users to be able to download your solution directly from Travelport Marketplace. The file you upload here should include an installer.'
                }
              />
            )}
            {isIntegrationDownloadVisible && (
              <Field
                id="marketplaceIntegration"
                name="marketplaceIntegration"
                type="checkbox"
                displayText="Marketplace Integration Plug-in"
                isSpPlugin
                component={this.renderSolutionDistribution}
                tooltip={
                  isInternal
                    ? `Select this option if you want your Agency user to utilize the Solution Management function in Smartpoint to download and install your solution.
                  NOTE:  users will have to have the Markerplace plug-in installed for this function to work.`
                    : `Select if you wish Users to be able to download and install your solution directly into Smartpoint  via the Marketplace Integration Plug-in.
                  Not all users will have access to this plug-in in Smartpoint The file you upload here should just be the .dll file.`
                }
              />
            )}
            <div className="row no-space-bottom">
              <div className="col-sm-4">
                <div className="pull-left pad-right-sm">
                  {isDistributionElsewhereVisible && (
                    <Field
                      id="distributeElsewhere"
                      name="distributeElsewhere"
                      component={this.renderSimplePrettyCheckBox}
                      displayText={
                        !isInternal
                          ? 'Distribute Elsewhere'
                          : 'Distribute via Support Document'
                      }
                      type="checkbox"
                      tooltip={
                        !isInternal
                          ? 'Select this if you wish to distribute your solution by other means.  You will be space to provide an explanation fo your distribution method.'
                          : 'Select this option if you want to distribute some documentation to your Agency users via the internal product page.'
                      }
                    />
                  )}

                  {distributeElsewhere && (
                    <Field
                      className="distributionExplanation"
                      placeholder="Distribution Explanation"
                      id="distributionExplanation"
                      name="distributionExplanation"
                      component="textarea"
                    />
                  )}
                </div>
              </div>
            </div>
            {isNotDistributedVisible && (
              <div className="row">
                <div
                  className={`col-sm-4 ${directDownload
                    || marketplaceIntegration
                    || distributeElsewhere
                    ? 'disabled-control'
                    : ''
                  }`}
                >
                  <div className="pull-left pad-right-sm">
                    <Field
                      id="noDistribution"
                      name="noDistribution"
                      component={this.renderSimplePrettyCheckBox}
                      displayText="No Distribution Needed"
                      type="checkbox"
                      tooltip="Select this if your solution does not require any sort of distribution to users."
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isInternal && this.renderNonInternalForm()}
          {!isInternal
            && (
              <div className="row pad-top">
                <label htmlFor="additionalInfo">
                  ADDITIONAL CUSTOMER INFORMATION
                </label>
                <Field
                  id="showAdditionalInformation"
                  name="showAdditionalInformation"
                  component={this.renderSimplePrettyCheckBox}
                  displayText="Request additional information from customer before confirming sales order has been placed"
                  type="checkbox"
                  onChange={(e, val) => this.additionalInfoChange(e, val)}
                />
              </div>
            )}
        </div>
      </form>
    );
  }
}

Distribution.propTypes = {
  priceCustom: PropTypes.string,
  handleSubmit: PropTypes.func,
  getDistributionFormAction: PropTypes.func,
  updateCurrentFormAction: PropTypes.func,
  getPriceByLocationAction: PropTypes.func,
  getAllCountriesAction: PropTypes.func,
  directDownload: PropTypes.bool,
  marketplaceIntegration: PropTypes.bool,
  distributeElsewhere: PropTypes.bool,
  price: PropTypes.string,
  countries: PropTypes.array,
  location: PropTypes.object,
  dropdownLists: PropTypes.object,
  directDownloadLastUploadedVersion: PropTypes.string,
  directDownloadCurrentVersion: PropTypes.string,
  integrationDownloadLastUploadedVersion: PropTypes.string,
  integrationDownloadCurrentVersion: PropTypes.string,
  isNotDistributedVisible: PropTypes.bool,
  isIntegrationDownloadVisible: PropTypes.bool,
  isDistributionElsewhereVisible: PropTypes.bool,
  isDirectDownloadVisible: PropTypes.bool,
  licenseInfoIconText: PropTypes.string,
  isPurchased: PropTypes.bool,
  platformId: PropTypes.number,
  loading: PropTypes.bool,
  isInternal: PropTypes.bool,
  dirty: PropTypes.bool,
  name: PropTypes.string,
  isLicenseChangeDisabled: PropTypes.bool,
  showAdditionalInformation: PropTypes.bool,
  showMsg: PropTypes.func
};

const selector = formValueSelector(DISTRIBUTION_ID);
const mapStateToProps = state => ({
  initialValues: getDistributionSelector(state),
  loading: getDistributionSelector(state).loading,
  isInternal: applicationSelector(state).isInternal,
  dropdownLists: getListsDistributionSelector(state),
  isPurchased: selector(state, 'isPurchased'),
  platformId: getDistributionSelector(state).platformId,
  directDownload: selector(state, 'directDownload'),
  marketplaceIntegration: selector(state, 'marketplaceIntegration'),
  distributeElsewhere: selector(state, 'distributeElsewhere'),
  noDistribution: selector(state, 'noDistribution'),
  price: selector(state, 'price'),
  priceCustom: selector(state, 'priceCustom'),
  directDownloadLastUploadedVersion: selector(
    state,
    'directDownloadLastUploadedVersion'
  ),
  directDownloadCurrentVersion: selector(state, 'directDownloadCurrentVersion'),
  integrationDownloadLastUploadedVersion: selector(
    state,
    'integrationDownloadLastUploadedVersion'
  ),
  integrationDownloadCurrentVersion: selector(
    state,
    'integrationDownloadCurrentVersion'
  ),
  licenseInfoIconText: getDistributionSelector(state).licenseInfoIconText,
  isNotDistributedVisible: getDistributionSelector(state).isNotDistributedVisible,
  isIntegrationDownloadVisible: getDistributionSelector(state).isIntegrationDownloadVisible,
  isDistributionElsewhereVisible: getDistributionSelector(state).isDistributionElsewhereVisible,
  isDirectDownloadVisible: getDistributionSelector(state).isDirectDownloadVisible,
  isLicenseChangeDisabled: getDistributionSelector(state).isLicenseChangeDisabled,
  showAdditionalInformation: selector(state, 'showAdditionalInformation'),
});

const mapDispatchToProps = dispatch => ({
  getDistributionFormAction: (args) => dispatch(getDistributionForm.base(args)),
  updateCurrentFormAction: (idForm, dirty) => dispatch(updateCurrentForm(idForm, dirty)),
  getPriceByLocationAction: (args) => dispatch(getPriceByLocation.base(args)),
  getAllCountriesAction: () => dispatch(getAllCountries.base()),
  showMsg: (msg) => dispatch(showAlertAction(msg, 5000))
});

/* eslint-disable import/no-mutable-exports */
let DistributionConnected = reduxForm({
  form: DISTRIBUTION_ID,
  enableReinitialize: true
})(Distribution);

DistributionConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionConnected);

export default DistributionConnected;
