/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getApplicationForms,
  getApplicationQueryString,
  isInternalPPCP,
  isTravelportPOSApplicationPPCP
} from 'reducers/modules/Application/selectors';
import { goToRoute } from '../../../reducers/modules/Application/applicationReducer';
import * as ppcp from '../../../constants/PPCP';

const LeftNavigationPanel = ({
  forms, queryString, location, history, goToRoutes, isInternal, isTravelportPOSApplication
}) => (
  <ul id="create-product-list" className="shop-products-list">
    {forms && forms.map(
      form => {
        if (isInternal && ppcp.hideInternalMenus.indexOf(form.name) !== -1) {
          return null;
        }
        if (isTravelportPOSApplication && ppcp.hidePOSApplicationMenus.indexOf(form.name) !== -1) {
          return null;
        }
        return (
          <li key={form.name}>
            <Link
              className={location.pathname === form.route ? 'current-product' : ''}
              onClick={(e) => {
                e.preventDefault();
                goToRoutes(form.route, queryString, history);
              }}
              to="#"
            >
              {(isInternal || isTravelportPOSApplication) && form.name === ppcp.REVIEW_AND_SUBMIT ? 'Review' : form.name}
              {form.completed ? <span /> : null}
            </Link>
          </li>
        );
      }
    )}
  </ul>
);

LeftNavigationPanel.propTypes = {
  forms: PropTypes.array,
  queryString: PropTypes.string,
  location: PropTypes.any,
  history: PropTypes.object,
  goToRoutes: PropTypes.func,
  isInternal: PropTypes.bool,
  isTravelportPOSApplication: PropTypes.bool
};

LeftNavigationPanel.defaultProps = {
  queryString: ''
};

const mapStateToProps = state => ({
  forms: getApplicationForms(state),
  queryString: getApplicationQueryString(state),
  isInternal: isInternalPPCP(state),
  isTravelportPOSApplication: isTravelportPOSApplicationPPCP(state)
});

const mapDispatchToProps = (dispatch) => ({
  goToRoutes: (route, queryString, history) => dispatch(goToRoute.base({ route, queryString, history }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftNavigationPanel));
