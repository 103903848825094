import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableHeaderRow,
  Table,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  SortingState,
  PagingState,
  CustomPaging
} from '@devexpress/dx-react-grid';
import DataCells from './TableCellComponents/DataCells';

const DisplayTable = (props) => (
  <div id="table-div" className="row">
    <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
      <Grid columns={props.columns} rows={props.rows}>
        <PagingState
          currentPage={props.currentPage}
          pageSize={props.pageSize}
          onCurrentPageChange={props.pageHandler}
        />
        <CustomPaging
          totalCount={
            props.totalCount
          }
        />
        <SortingState
          sorting={props.sorting}
          onSortingChange={props.sortHandler}
        />
        <Table cellComponent={DataCells} />
        <TableHeaderRow showSortingControls={props.showSortingControls} />
        <PagingPanel pageSizes={[]} />
      </Grid>
    </div>
  </div>
);

DisplayTable.propTypes = {
  sorting: PropTypes.object,
  showSortingControls: PropTypes.bool,
  sortHandler: PropTypes.func,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageHandler: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array
};
export default DisplayTable;
