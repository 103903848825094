import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';
import Header from './header/Header';
import Footer from './footer/Footer';
import Router from './Router';
import AlertMessage from './common/CorePopUps/AlertMessage/AlertMessage';
import CoreLoader from './common/CorePopUps/CoreLoader/CoreLoader';
import ConfirmationModal from './common/CorePopUps/CoreConfirmationModal/CoreConfirmationModal';
import FeedbackForm from './common/FeedbackForm/FeedbackForm';
import CookiePopup from './common/CookiePopup/CookiePopup';

class App extends Component {
  constructor() {
    super();
    localStorage.isLoggingOut = false;
  }

  componentDidMount() {
    var reactPlugin = new ReactPlugin();
    if (window.location.pathname !== '/callback') {
      this.props.loadUserInfo();
    }

    // Code to capture page views for ApplicationInsights
    const instrumentKey = window.enviroment.APP_INSIGHTS_KEY;
    if (instrumentKey) {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentKey,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: this.props.history }
          }
        }
      });
      appInsights.loadAppInsights();
    }
  }

  render() {
    return (
      <>
        <AlertMessage />
        <ConfirmationModal />
        <CoreLoader />
        <FeedbackForm />
        <Header />
        <Router />
        <Footer />
        <CookiePopup />
      </>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  loadUserInfo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  loadUserInfo: () => dispatch(loadUserInfo.base())
});

export default withRouter(withAITracking(ReactPlugin, connect(null, mapDispatchToProps)(App)));
