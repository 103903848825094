import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as SeoHelper from '../../utils/SeoHelper';
import Image from '../common/Image/Image';

class DeveloperTileLayout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Link className="developers-tile" to={`/DeveloperDirectory/${SeoHelper.SEOFriendly(this.props.organisationName)}`}>
        <span className="developers-tile-min-height">
          <span className="developers-tile-title">{this.props.organisationName}</span>
          <span className="developers-tile-content">
            <Image
              data={{
                applicationName: this.props.organisationName,
                cdnLogoURL: this.props.logoUrl,
                className: 'developers-tile-logo'
              }}
            />
            <span className="developers-tile-description">
              {this.props.shortDescription}
            </span>
          </span>
        </span>
        <span className="developers-tile-footer">
          <span className="developers-tile-footer-left">
            {this.props.partnerLevel === 1
              ? (
                <span className="developers-tile-partner">
                  <span className="icon-partner-silver" />
                  {' '}
                  {this.props.partnerLevel}
                  {' '}
                  Partner
                </span>
              ) : null}
            {this.props.partnerLevel === 2
              ? (
                <span className="developers-tile-partner">
                  <span className="icon-partner-gold" />
                  {' '}
                  {this.props.partnerLevel}
                  {' '}
                  Partner
                </span>
              ) : null}
            {this.props.partnerLevel === 3
              ? (
                <span className="developers-tile-partner">
                  <span className="icon-partner-platinum" />
                  {' '}
                  {this.props.partnerLevel}
                  {' '}
                  Partner
                </span>
              ) : null}
          </span>
          <span className="developers-tile-footer-right">
            {this.props.isCustomDevelopmentSupported
              ? (
                <span className="developers-tile-custom">
                  <span className="icon-checkmark-black" />
                  Custom Development
                </span>
              ) : null}

          </span>
        </span>
      </Link>

    );
  }
}

DeveloperTileLayout.propTypes = {
  organisationName: PropTypes.string,
  shortDescription: PropTypes.string,
  vendorPublisher: PropTypes.string,
  logoUrl: PropTypes.string,
  isCustomDevelopmentSupported: PropTypes.bool,
  partnerLevel: PropTypes.number,
  onChange: PropTypes.func
};

export default DeveloperTileLayout;
