import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { confirmationAccepted, confirmationRejected } from '../../../../reducers/popUps/confirmationModalReducer';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';

class ConfirmationModal extends Component {
  render() {
    return (
      <Dialog
        appearance="information"
        id="sbDialog"
        hidden={!this.props.showModal}
        buttons={
          (
            <>
              <Button
                className="action-button"
                appearance="primary"
                onClick={this.props.accept}
              >
                Yes
              </Button>
              <Button
                className="cancel-button space-left"
                onClick={this.props.reject}
              >
                No
              </Button>
            </>
          )
        }
      >
        {this.props.text}
      </Dialog>
    );
  }
}

ConfirmationModal.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  accept: PropTypes.func,
  reject: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: state.confirmationModal.text,
  subtext: state.confirmationModal.subtext,
  showModal: state.confirmationModal.showConfirmationModal,
});

const mapDispatchToProps = (dispatch) => ({
  accept: bindActionCreators(confirmationAccepted, dispatch),
  reject: bindActionCreators(confirmationRejected, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
