let baseUrlPort = '';
if (window.location.port) {
  baseUrlPort = `:${window.location.port}`;
}
export const BASE_URL = `${window.location.protocol}//${window.location.hostname}${baseUrlPort}/`;
export const SP_CLOUD_API_ENDPOINT = window.enviroment.SPC_BASE_URL; // 'https://spwebplatformservices.marketplace-dev1.tvlport.com';
export const API_PREFIX = window.enviroment.API_PREFIX || 'api';
export const API_NAME = 'Travelport.Marketplace.API';
export const REGISTER_URL = window.enviroment.REGISTER_URL
  || 'https://my.dv.tvlport.com/web/guest/sign-in?p_p_id=58&p_p_lifecycle=0&p_p_'
  + 'state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_count=1&_58_struts_action=%2Flogin%2Fcreate_account';
export const CLIENT_ID = 'MarketplaceWeb';
export const ENVIRONMENT = window.enviroment.ENVIRONMENT || 'Development';
export const GET_FEATURED_PRODUCTS = 'api/Home/Getfeaturedproducts';
export const GET_SEARCH_RESULTS = 'api/Application/SearchResults';
export const GET_FILTER_DETAILS = 'api/application/GetFilters';
export const GET_BROWSE_PRODUCT = 'api/application/BrowseProduct';
export const GET_ORGANIZATION_DETAILS = 'api/DeveloperDirectory';
export const GET_PRODUCT_DETAILS = 'api/Product';
export const GET_PRODUCT_SUPPORT_DOCUMENT_URL = 'api/application/getsupportfileurl';
export const GET_APP_RATING_DETAILS = 'api/application/GetApplicationRatingDetails';
export const GET_APP_REVIEWS = 'api/application/GetProductReviewsDetails';
export const GET_USER_PROFILE = 'api/profile/getfulluserprofile';
export const SAVE_USER_PROFILE = 'api/profile/saveuserprofiledetails';
export const SAVE_USER_PROFILE_IMAGE = 'api/profile/saveuserprofileimage';
export const GET_FILTER_DEVELOPER_DETAILS = 'api/DeveloperDirectory/GetDeveloperFilters';
export const GET_DEVELOPER_ALL = 'api/DeveloperDirectory/GetDevelopers';
export const GET_ALL_COUNTRY = 'api/profile/getallcountries';
export const CHANGE_USER_ROLE = ''; // To be substituted after API is ready
export const GET_DETAILS_FOR_TERMS = 'api/Application/GetDetailsForTerms';
export const BUY_APPLICATION = 'api/Buy/Application';
export const GET_DOWNLOAD_URL = 'api/downloads/getdownloadurl';
export const SAVE_AGENCY_PROFILE_DETAILS = 'api/Profile/SaveAgencyProfileDetails';

export const GET_DEVELOPER_ORGANISATION = 'api/organisation/getdevorgdetails';
export const GET_FIELDSFOR_DEVELOPER_EDIT = 'api/organisation/getfieldsfordeveloperedit';
export const SAVE_SCREENSHOTS = 'api/organisation/savescreenshots';
export const REMOVE_SCREENSHOTS = 'api/organisation/removercreenshot';
export const EDIT_DEVELOPER_ORGANISATION = 'api/organisation/edit';

export const SAVE_PRODUCT_REVIEW = 'api/Application/SaveProductReview';
export const DELETE_PRODUCT_REVIEW = 'api/Application/DeleteProductReview';

export const GET_PRODUCTS_SELECT_LIST = 'Reports/GetProductsSelectListForProductDownloadReport';
export const GET_FILTERED_PRODUCT_EVENT_REPORT = 'Reports/GetFilteredProductEventReport';
export const EXPORT_FILTERED_PRODUCT_EVENT_REPORT = 'Reports/ExportProductEventReportToExcel';

export const SAVE_AGENCY_LOGO = 'api/organisation/savelogo';

export const GET_USER_PERMISSION = 'api/profile/userprofile';
export const GET_HIERARCHY = 'Profile/GetHierarchy';

export const PUT_UPDATE_EMAIL_OPTION_FOR_NEW_VERSION = 'profile/updateusernotificationemailoptionfornewversion';
export const PUT_UPDATE_EMAIL_OPTION_FOR_NEW_SOLUTION = 'profile/updateusernotificationemailoptionfornewsolution';
export const PUT_UPDATE_PLUGIN_ADMIN_OPTION_FOR_AGENCY = 'profile/updatepluginadministrationoptionforagency';
export const PUT_UPDATE_ASSIGNED_SOLUTIONS_VISIBLITY_FOR_AGENCY = 'profile/updateassignedsolutionsvisibilityofagentsforagency';
export const FETCH_COMMUNICATION_DETAILS = 'Application/GetCommunication';
export const SAVE_COMMUNICATION_DETAILS = 'Application/SaveCommunication';

export const API_GET_ALL_FILTER_OPTIONS = 'organizationdirectory/getallfilteroptions';
export const API_GET_ORGANIZATION_LIST = 'organizationdirectory/organizationlist';
export const API_GET_CHILD_ORGS = 'organizationdirectory/getchildorgs';
export const API_CHANGE_ORGANIZATION_VISIBILITY = 'organizationdirectory/changedevorgvisibility';
export const FETCH_CLASSIFICATION_DETAILS = 'application/getclassifications';
export const FETCH_LIST_FOR_CLASSIFICATION_DETAILS = 'application/getlistsforclassifications';
export const SAVE_CLASSIFICATION_DETAILS = 'application/saveclassifications';

export const FETCH_BASICINFORMATION_DETAILS = 'Application/GetBasicInformation';
export const SAVE_BASICINFORMATION_DETAILS = 'Application/SaveBasicInformation';

export const RECAPTCHA_CLIENT_SECRET_KEY = window.enviroment.RECAPTCHA_CLIENT_SECRET_KEY || '6LdaURYUAAAAAN6GalYU8spEK3oH5rAOLNE_qUwT';

export const GET_DEVELOPER_GUIDE_URL = 'api/application/getdeveloperguideurl';
export const GET_HOW_TO_UPLOAD_YOUR_SOLUTION = 'api/application/howtouploadyoursolutionurl';
export const BUILD_NUMBER = window.enviroment.BUILD_NUMBER || 'v0.0.0.0'; // 'evolveregistry.azurecr.io/marketplacenextgen/mp-web-develop:v0.0.0.0000';
// export const SPLUNK_RUM_TOKEN = window.enviroment.SPLUNK_RUM_TOKEN;
