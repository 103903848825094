import { select } from 'redux-saga/effects';

/* SAGA SELECTOR */

const basicInformationSelector = state => state.form.basicInformation;

export function* createBasicInformationRequest() {
  const basicInformationState = yield select(basicInformationSelector);
  return basicInformationState.values;
}

export function getBasicInformationData(state) {
  return {
    data: state.application.basicInformation.data,
    loading: state.application.basicInformation.loading || state.application.applicationLayout.loading
  };
}
