/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field, FieldArray, reduxForm, formValueSelector, change
} from 'redux-form';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  fetchCommunicationDetails,
  updateCurrentForm
} from 'reducers/modules/Application/index';
import '../../../Styles/datepicker.css';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import ToolTip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import * as ppcp from '../../../constants/PPCP';
import FormHeader from '../FormHeader/FormHeader';

const emailLengthValidation = (value) => (value && value.length > 255 ? 'Email\'s length Should not exceed 255 characters' : undefined);
const validEmailValidation = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter a valid email id' : undefined);
const purchaseConfirmationMessageLengthValidation = (value) => (value && value.length > 2000 ? 'Confirmation Message\'s length Should not exceed 2000 characters' : undefined);

const renderField = ({
  input,
  type,
  meta: { touched, error }
}) => (
  <>
    <input className={touched && error ? 'input-validation-error text-input' : 'text-input'} {...input} type={type} />
    {touched
        && (error && <span className="field-validation-error">{error}</span>)}
  </>
);

const renderTextArea = ({
  input,
  className,
  cols,
  meta: { touched, error }
}) => (
  <>
    <textarea {...input} onChange={input.onChange} className={className} cols={cols} />
    {touched
        && (error && <span className="field-validation-error">{error}</span>)}
  </>
);

const renderCheckbox = ({
  input,
  displayText,
  tooltip
}) => (
  <SimplePrettyCheckBox
    {...input}
    displayText={displayText}
    onChange={input.onChange}
    tooltip={tooltip}
  />
);

const removeOrderEmail = (fields, index) => {
  const needsInsertion = (index === 0 && fields.length === 1);
  fields.remove(index);
  if (needsInsertion) {
    fields.push('');
  }
};

const renderOrderEmails = ({ fields }) => (
  fields.map((orderEmail, index) => (
    <div className="row" key={index.toString()}>
      <div className="col-sm-8">
        <Field name={orderEmail} component={renderField} type="text" validate={[emailLengthValidation, validEmailValidation]} />
      </div>
      <div className="col-sm-1 pad-left-sm pad-top-sm">
        <ToolTip labelName="Enter an email address to receive notifications when a (non-travelport) Marketplace user orders your product." />
      </div>
      <div className="col-sm-3">
        {index < 4 && index === fields.length - 1
          && (
          <>
            <button type="button" className="action-button" onClick={() => fields.push('')}>Add</button>
&nbsp;
          </>
          )}
        <button type="button" className="action-button" onClick={() => removeOrderEmail(fields, index)}>Remove</button>
      </div>
    </div>
  ))
);
const renderEmailSection = () => (
  <>
    <div className="col-sm-2 no-pad-right">
      <label className="required-field-star" htmlFor="salesEmail">Support Email Address:</label>
    </div>
    <div className="col-sm-8">
      <div className="row">
        <div className="col-sm-8">
          <Field name="salesEmail" component={renderField} type="text" validate={[emailLengthValidation, validEmailValidation]} />
        </div>
        <div className="col-sm-1 pad-left-sm pad-top-sm">
          <ToolTip labelName="Enter the email address you wish to have displayed on the product page for product support communication." />
        </div>
      </div>
    </div>
  </>
);
class Communication extends Component {
  componentDidMount() {
    document.title = 'Communication';
    this.props.updateCurrentFormAction(false);
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.fetchCommunicationDetails({ publicId, organizationId });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.dirty) !== JSON.stringify(this.props.dirty)) {
      this.props.updateCurrentFormAction(this.props.dirty);
    }
  }

  removeProductTnC = (event) => {
    event.preventDefault();
    this.props.changeFieldValue('termsAndConditions', null);
    this.props.changeFieldValue('hasTermsAndConditions', false);
  }

  render() {
    if (this.props.initialValues === undefined) {
      return <LoaderComponent show />;
    }
    return (
      <form onSubmit={this.props.handleSubmit}>
        {this.props.loading && <LoaderComponent show />}
        <FormHeader
          header="Communication"
          infoText="Choose the communications you want to receive about your solution and the message you want to display when your solution is ordered."
        />
        <h4>EMAIL NOTIFICATIONS</h4>
        <div className="row">
          {renderEmailSection()}
        </div>

        <div className="row">
          <div className="col-sm-2 no-pad-right">
            <label htmlFor="orderEmails">Order Notification Email:</label>
          </div>
          <div className="col-sm-8">
            <FieldArray name="orderEmails" component={renderOrderEmails} />
          </div>
        </div>

        <h4>Notification Options</h4>
        <div className="row">
          <Field
            name="receiveEmailOnTravelportOrder"
            displayText="Receive an email when a Travelport user orders the solution?"
            tooltip="This option allows you to be notified whenever a Travelport Employee orders the product."
            type="checkbox"
            component={renderCheckbox}
          />
        </div>
        <div className="row">
          <Field
            name="demoRequestEmailCheck"
            displayText="Provide potential customers the ability to request a Product Demo."
            tooltip={'Select this option if you would like to offer customers the ability to contact you or a member of your sales team directly about possibly '
              + 'scheduling a Product Demo.'}
            type="checkbox"
            component={renderCheckbox}
          />
        </div>
        {this.props.isDemoRequestEmailChecked
          && (
          <div className="row">
            <div className="col-sm-2 no-pad-right">
              <label htmlFor="demoRequestEmail" className="required-field-star">Product Demo Request email:</label>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-8">
                  <Field name="demoRequestEmail" component={renderField} type="text" validate={[emailLengthValidation, validEmailValidation]} />
                </div>
                <div className="col-sm-2 pad-left-sm pad-top-sm">
                  <ToolTip labelName="Enter the email address of the user whom should receive the product demo requests." />
                </div>
              </div>
            </div>
          </div>
          )}

        <div className="row">
          <Field
            name="sendEmailOnOrder"
            displayText="Add a message to the Order Confirmation email when someone orders your solution?"
            tooltip="Select this option if you'd like to add a personalized message to the order confirmation email."
            type="checkbox"
            component={renderCheckbox}
          />
        </div>
        {this.props.isSendEmailOnOrderChecked
          && (
          <div className="row">
            <label className="required-field-star" htmlFor="purchaseConfirmationMessage">
              Order Confirmation Message
              <ToolTip labelName={'Any message added here will appear to a Customer on the Order Confirmation page as well as be included in the '
              + 'product&quot;s Order Confirmation email.'}
              />
            </label>
            <Field
              name="purchaseConfirmationMessage"
              className="textarea small-textarea"
              cols="20"
              component={renderTextArea}
              validate={[purchaseConfirmationMessageLengthValidation]}
            />
          </div>
          )}

        <h4>TERMS AND CONDITIONS</h4>
        <div className="row">
          <label htmlFor="none">
            Travelport Marketplace Agreement
            {' '}
            <ToolTip labelName="Detailed information on the terms and conditions between Travelport Marketplace and your company." />
          </label>
          <p className="x-small-text">
            <em>The Travelport Marketplace Agreement will be displayed on all orders, including referrals.</em>
          </p>
          <textarea className="textarea" readOnly disabled>
            {/* eslint-disable */}
            {`   IMPORTANT – READ THIS TRAVELPORT MARKETPLACE AGREEMENT ("Agreement") CAREFULLY BEFORE USING THIS
             SITE: Your use of travelportmarketplace.com (“Travelport Marketplace”) and any associated software, 
             applications, and other digital products ("Marketplace Products") provided by Travelport or a third 
             party developer ("Developer"), is subject to the terms and conditions of this Agreement, which 
             include the provisions of the subscriber agreement between your company and Travelport 
             ("Subscriber Agreement") (if applicable), the Travelport Application and Website Privacy Policy at
              https://www.travelport.com/privacy (“Travelport Privacy Policy”), and the Travelport Website Terms
               of Use  at http://www.travelport.com/terms-conditions ("Travelport Website Terms of Use"). Any 
               conflict in the terms of these documents will be resolved in the following order of precedence: 
               Subscriber Agreement (if applicable), this Agreement, Travelport Website Terms of Use, and Privacy
                Policy. For purposes of this Agreement, "you" refers collectively to you and your company.
You may use the Travelport Marketplace and purchase Marketplace Products only if i) you are an authorized 
representative of your company, ii) your company is a current subscriber to the Travelport GDS 
(also known as the “Travelport System”), either through a valid Subscriber Agreement with Travelport, or a 
Travelport-appointed operator within a specific territory, and iii) your company's account is in good standing. 
       BY CLICKING THE "ACCEPT" BUTTON BELOW, YOU REPRESENT THAT YOU MEET THESE CRITERIA AND AGREE TO THE TERMS 
       AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT MEET THESE CRITERIA OR YOU DO NOT SO AGREE, DO NOT CLICK 
       “ACCEPT,” AND DO NOT PURCHASE, DOWNLOAD, INSTALL OR USE ANY OF THE MARKETPLACE PRODUCTS. BY PURCHASING, 
       DOWNLOADING, INSTALLING OR OTHERWISE USING ANY MARKETPLACE PRODUCTS, YOU AGREE TO PAY TRAVELPORT, ITS 
       APPOINTED OPERATOR, OR THE DEVELOPER, AS APPLICABLE, THE FEES SET FORTH ON TRAVELPORT MARKETPLACE OR 
       OTHERWISE ADVISED TO YOU FOR THOSE MARKETPLACE PRODUCTS.           
        1.  MARKETPLACE PRODUCT ACCESS.
              1.	Grant of Access. Provided that you are in material compliance with the terms and conditions 
              of this Agreement and your Subscriber Agreement (as applicable), including but not limited to all 
              payment obligations, Travelport grants to you a nonexclusive, nontransferable, revocable right to 
              access and use the Marketplace Products you select in the purchase process. For those of you who 
              have a Subscriber Agreement with Travelport, Marketplace Products that are owned by Travelport shall
               be considered "Software Products", "Products and Services" or "Services", and Marketplace Products
                that are owned by Developers shall be considered "Third Party Products" as those terms are used 
                in your Subscriber Agreement.
              2.	Restrictions on Use. You agree to use the Marketplace Products only to operate your own travel business, 
              including in conjunction with your affiliates, and in conjunction with Travelport’s directions. Under no 
              circumstances may you permit any third parties to use the Marketplace Products. Use of the Marketplace 
              Products may be subject to additional terms depending on the nature of the Marketplace Product itself and 
              subject to any Travelport or Developer specific product terms.
              3.	Modifications; Reverse Engineering. You agree that you will not directly or through a third party alter, maintain, enhance, disassemble, decompile, 
              reverse engineer, create derivative works or otherwise modify any Marketplace Product.
              4.	Product Ratings.  You are solely responsible for the content of any Marketplace Product 
              ratings that you submit to Travelport.
        2.	PAYMENT
              You agree to pay all fees and taxes applicable to the Marketplace Products you purchase. 
              Unless otherwise stated, payment for Marketplace Products will be made in accordance with your Subscriber 
              Agreement or directly to the Developer through separate arrangements between yourself and the Developer. 
              Marketplace Products may have a free trial period and if so, the free trial period will be described in 
              the product description on Travelport Marketplace. Following any trial period, all sales are final and 
              non-refundable unless otherwise specified. Travelport may offset amounts you owe under this Agreement from 
              amounts Travelport may owe you under any agreement between you and Travelport.
        3.	SUPPORT
              You agree that Travelport has no responsibility or liability with respect to i) your use of a
               Marketplace Product and ii) any content or functionality in the Marketplace Product. Travelport or 
               a Developer may provide help desk support for various Marketplace Products from time to time, and
                you agree to pay any applicable separate help desk fees. Any applicable fees and support 
                information will be provided alongside product information on Travelport Marketplace. 
                These fees and support information are hereby incorporated by reference into this Agreement.
        4.	GEOGRAPHIC RESTRICTIONS
                Nothing in this Agreement permits you to access or use a Marketplace Product where such access or use is prohibited by law or regulation or the terms of your Subscriber Agreement.
                 Travelport or a Developer may restrict access to the Travelport Marketplace from time to time.
        5.	INTELLECTUAL PROPERTY RIGHTS
              1.	Title. 1.	Marketplace Products are protected by copyright and other intellectual property laws and 
              treaties. Unless otherwise expressly stated, Travelport, the Developers or their licensors own all title,
               copyright and other intellectual property rights in the Marketplace Products.
               No title to any Marketplace Product is transferred to you.
              2.	Transfers. Under no circumstances may you sell, license, publish, display, distribute, or otherwise
               transfer to a third party any Marketplace Product or any 
              copy thereof, in whole or in part, without Travelport's prior written consent.
        6.	DISCLAIMER OF WARRANTIES
              THERE ARE NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, FOR ANY MARKETPLACE PRODUCT, INCLUDING, 
              BUT NOT LIMITED TO, WARRANTIES AS TO TITLE OR INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY OR FITNESS 
              FOR A PARTICULAR PURPOSE. THE MARKETPLACE PRODUCTS, DOCUMENTATION AND OTHER FILES ARE PROVIDED "AS IS." 
              TRAVELPORT AND ITS DEVELOPERS DO NOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE 
              MARKETPLACE PRODUCTS. YOU AGREE THAT TRAVELPORT OR A DEVELOPER MAY MODIFY / RESTRICT ACCESS TO A MARKETPLACE 
              PRODUCT AND THAT SUCH MODIFICATION MAY IMPACT YOUR ABILITY TO ACCESS OR USE THE MARKETPLACE PRODUCT. TRAVELPORT MAY RELEASE NEW VERSIONS OF THE TRAVELPORT SYSTEM THAT DO NOT SUPPORT MARKETPLACE PRODUCTS YOU HAVE DOWNLOADED. Some jurisdictions do not allow the exclusion of implied warranties, 
              so the above exclusions may not apply to you if prohibited by applicable law.            
        7.	EXCLUSION OF LIABILITY/LIMITATION OF LIABILITY
             IN NO EVENT WILL TRAVELPORT BE LIABLE TO YOU OR ANY THIRD PARTY FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, EXEMPLARY OR OTHER SIMILAR DAMAGES, INCLUDING LOST PROFITS, ARISING FROM THE USE OF OR INABILITY
               TO USE ANY MARKETPLACE PRODUCT, EVEN IF TRAVELPORT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
               TRAVELPORT IS NOT RESPONSIBLE FOR ANY DEVELOPERS PROVIDING PRODUCTS OR SERVICES IN THE TRAVELPORT MARKETPLACE. 
             IN NO EVENT (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW) WILL TRAVELPORT'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE MARKETPLACE PRODUCTS EXCEED THE LESSER OF (i) THE AMOUNT SPECIFIED IN YOUR SUBSCRIBER AGREEMENT, OR (ii) THE PURCHASE PRICE OF THE MARKETPLACE PRODUCT GIVING RISE TO THE CLAIM FOR LIABILITY.           
        8.	GENERAL PROVISIONS
              1.	Modifications. Travelport may alter or revise the terms of this Agreement from time to time. If you do not agree to accept the changes, your sole remedy shall be to 
              discontinue your use of the Travelport Marketplace and the Marketplace Products.          
              2.	Choice of Law and Location for Resolving Disputes. The choice of law and dispute resolution provisions in your Subscriber Agreement will control any 
              disputes under this Agreement, or where your access to the Travelport System is provided through a 
              Travelport-appointed operator, then the governing law shall be the laws of England and Wales and the
               English courts will have non-exclusive jurisdiction over any disputes arising out of or in connection with 
               this Agreement.
              3.	Termination. Unless otherwise terminated as set forth in this Agreement, this Agreement will
               terminate as of the termination of your Subscriber Agreement or as of the date in which your access to
                the Travelport System ceases to be provided by a Travelport-appointed operator in a specific territory.
                 Travelport may terminate this Agreement if you fail to comply with any terms and conditions of this 
                 Agreement, or any provision of your Subscriber Agreement (if applicable), the Travelport Privacy Policy,
                  or the Travelport Website Terms of Use, or if it receives notice from a Travelport-appointed operator
                   in a specific territory to terminate this Agreement. Upon any such termination, Travelport may 
                   immediately deny access to the Marketplace Products, and you must discontinue use of the Marketplace
                    Products. 
              Travelport may modify, suspend or discontinue any Marketplace Product and/or the Travelport Marketplace, in whole or in part, at any time without notice.
              4.	Severability. Except as otherwise set forth in this Agreement, the provisions of this Agreement are 
              severable, and if any one or more such provisions shall be determined to be invalid, illegal or 
              unenforceable, in whole or in part, the validity, legality and enforceability of any of the remaining 
              provisions or portions thereof shall not in any way be affected and shall nevertheless be binding between 
              the parties. Any such invalid, illegal or unenforceable provision or portion of such provision shall be 
              changed and interpreted so as to best accomplish the 
              objectives of such provision or portion thereof within the limits of applicable law.
              5.	Waiver. Any waiver, either expressed or implied, by either party of any default by the other in the observance and performance of any of the conditions and/or covenants of duties set forth in this Agreement shall 
              not constitute or be construed as a waiver of any subsequent or other default.
              6.	Read and Understood. You hereby acknowledge that you have read and understand this Agreement and agree to be bound by its terms.`}
          </textarea>
        </div>

        <div id="additionaTCButtonDiv">
          <button type="button" className="cancel-button"
          onClick={() => this.props.changeFieldValue('hasTermsAndConditions', true)}>Add Product Specific Terms and Conditions
          </button>
        </div>
        {this.props.isAdditionalTncVisible &&
          <div className="row">
            <label htmlFor="termsAndConditions">Product Specific Terms and Conditions
            <ToolTip labelName="Product Specific Terms and Conditions will be displayed to a buyer in the purchase flow." /></label>
            <p className="x-small-text">
              <em>Add additional Terms and Conditions specific to your product and/or company.</em>
            </p>
            <Field
              name="termsAndConditions"
              className="textarea"
              cols="20"
              component={renderTextArea}
            />
            <br />
            <p>
              <Link to="#" onClick={(event) => this.removeProductTnC(event)}><span className="cancel-icon" /> Cancel Product Specific Terms and Conditions</Link>
            </p>
          </div>
        }
      </form>
    );
  }
}

const selector = formValueSelector('communication')

const mapStateToProps = (state) => ({
  initialValues: state.application.communication.data,
  loading: state.application.communication.loading || state.application.applicationLayout.loading,
  isDemoRequestEmailChecked: selector(state, 'demoRequestEmailCheck'),
  isSendEmailOnOrderChecked: selector(state, 'sendEmailOnOrder'),
  purchaseConfirmationMessage: selector(state,'purchaseConfirmationMessage'),
  isAdditionalTncVisible: selector(state, 'hasTermsAndConditions')
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommunicationDetails: (args) => dispatch(fetchCommunicationDetails.base(args)),
  changeFieldValue: (field, value) => dispatch(change('communication', field, value)),
  updateCurrentFormAction: dirty => dispatch(updateCurrentForm(ppcp.COMMUNICATION_ID, dirty)),
});

Communication.propTypes = {
  handleSubmit: PropTypes.func,
  fetchCommunicationDetails: PropTypes.func,
  isDemoRequestEmailChecked: PropTypes.bool,
  isSendEmailOnOrderChecked: PropTypes.bool,
  isAdditionalTncVisible: PropTypes.bool,
  purchaseConfirmationMessage: PropTypes.string,
  changeFieldValue: PropTypes.func,
  initialValues: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  updateCurrentFormAction: PropTypes.func,
  dirty: PropTypes.bool
};

renderField.propTypes = {
  input: PropTypes.obj,
  type: PropTypes.string,
  meta: PropTypes.obj
};

renderTextArea.propTypes = {
  input: PropTypes.obj,
  meta: PropTypes.obj,
  className: PropTypes.string,
  cols: PropTypes.string
};

renderCheckbox.propTypes = {
  input: PropTypes.obj,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
  checked: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'communication',
    enableReinitialize: true
  })(Communication)
);
