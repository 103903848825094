/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import {
  deleteUserErrorSelector,
  associatedPluginModalAction
} from 'reducers/modules/Administration/UserRoles/userRoles';

const AssociatedPluginModal = (props) => {
  if (!props.deleteUserError) {
    return '';
  }
  return (
    <Modal
      id="sbModal"
      title="Action can not be done"
      hidden={!props.deleteUserError.showModal}
      size="small"
      onDismiss={props.hideModal}
    >
      <div className="text-center">
        <p>
          {props.deleteUserError.userName}
          {' '}
          can not be deleted due to an association with the following product(s):
        </p>
        <br />
        <div className="row assc-app-user-scroll">
          <table>
            <thead>
              <tr>
                <th className="text-center">Product</th>
                <th className="text-center">Organization</th>
                <th className="text-center">GUID</th>
              </tr>
            </thead>
            <tbody>
              {props.deleteUserError.associatedApplications.map((associatedApplication, i) => (
                <tr key={i.toString()}>
                  <td>{associatedApplication.productName}</td>
                  <td>{associatedApplication.organisationName}</td>
                  <td>{associatedApplication.guid}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <a href="#" onClick={props.hideModal} className="action-button">OK</a>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  deleteUserError: deleteUserErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(associatedPluginModalAction.hide()),
});

AssociatedPluginModal.propTypes = {
  deleteUserError: PropTypes.object,
  hideModal: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedPluginModal);
