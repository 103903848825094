/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getUserIDSelector,
  getPccSelector
} from 'reducers/modules/Administration';
import ActiveToggle from '../../ActiveToggle/ActiveToggle';

const getFormattedValue = (label, value) => ({ label, value });

const checkTypeaheadData = (
  value,
  action,
  length
) => {
  if (value && value.length > length) {
    action({ value });
  }
};

const defaultCellControl = (props, name) => (
  <td>
    <input
      style={{ width: '100%' }}
      placeholder={name}
      className="form-control"
      onChange={(e) => props.onValueChange(e.target.value)}
      value={props.value}
    />
  </td>
);

const selectControl = ({
  value,
  items,
  onInputChange,
  onValueChange,
  placeholder
}) => {
  let currentValue;
  if (value) {
    currentValue = getFormattedValue(value, '');
  }
  const handleOnChange = (e) => {
    if (e && e.value) {
      onValueChange(e.value);
      if (onInputChange) {
        onInputChange(e.value);
      }
      return;
    }
    onValueChange('');
  };

  return (
    <td>
      <Select
        style={{ width: '100%' }}
        resetValue=""
        value={currentValue || ''}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        onFocus={onInputChange}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        options={items}
        placeholder={placeholder}
        clearable={false}
        escapeClearsValue={false}
      />
    </td>
  );
};

const handleToggle = (props, value) => {
  props.onValueChange(!value);
};

const getSettingValue = (attributeSettings, attributeSettingSelected) => {
  if (attributeSettingSelected) {
    if (attributeSettingSelected.length <= 2) {
      return attributeSettingSelected;
    }
    return attributeSettings.find(c => c.label === attributeSettingSelected).value;
  }
  return 0;
};

const attributeValueOneColumn = (props) => {
  const setting = getSettingValue(props.attributeSettings, props.attributeSettingSelected);
  if (setting === '1' || setting === '2' || setting === '3' || setting === '4') { // client ID
    return defaultCellControl(props, 'Client ID');
  }
  if (setting === '5' || setting === '6') {
    // User ID
    return selectControl({
      placeholder: 'User ID',
      items: props.userID,
      onInputChange: (val) => { checkTypeaheadData(val, props.getUsersIDAction, 2); },
      ...props,
    });
  }
  if (setting === '7' || setting === '8') {
    // PCC
    return selectControl({
      placeholder: 'PCC',
      items: props.pccList,
      onInputChange: (val) => checkTypeaheadData(val, props.getPccsAction, 2),
      ...props,
    });
  }
  if (setting === '9' || setting === '10') {
    // Group
    return selectControl({
      placeholder: 'Group',
      items: props.groups,
      ...props
    });
  }
  if (setting === '11' || setting === '12' || setting === '13' || setting === '14') {
    // region
    return selectControl({
      placeholder: 'Region',
      items: props.regions,
      ...props
    });
  }
  if (setting === '15' || setting === '16') {
    // country
    return selectControl({
      placeholder: 'Country',
      items: props.countries,
      ...props
    });
  }
  return defaultCellControl(props);
};

const attributeValueTwoColumn = (attributeSettingSelected, attributeSettings, userID, getUsersIDAction, props, countries) => {
  const setting = getSettingValue(attributeSettings, attributeSettingSelected);
  if (setting === '1' || setting === '2') {
    // User ID
    return selectControl({
      items: userID,
      placeholder: 'User ID',
      onInputChange: (val) => checkTypeaheadData(val, getUsersIDAction, 2),
      ...props,
    });
  }
  if (setting === '3' || setting === '5' || setting === '7' || setting === '9' || setting === '13' || setting === '15') {
    // SP version
    return defaultCellControl(props, 'SP Version');
  }
  if (setting === '11' || setting === '12') {
    // country
    return selectControl({
      placeholder: 'Country',
      items: countries,
      ...props
    });
  }
  return <td><span /></td>;
};

const attributeValueThirdColumn = (attributeSettingSelected, attributeSettings, props) => {
  const setting = getSettingValue(attributeSettings, attributeSettingSelected);
  if (setting === '1' || setting === '11') {
    // SPVersion
    return defaultCellControl(props, 'SP Version');
  }
  return <td><span /></td>;
};

const EditCell = (props) => {
  let items;
  let value;
  const handleOnChange = selectOption => {
    if (selectOption) {
      if (props.column.name === 'settingAttributeText') {
        props.clearAttributeValues();
      } else if (props.column.name === 'smartpointSettingText') {
        props.getSettingValuesAction({
          value: selectOption.value,
          successHandler: () => {
            props.smartpointSettingChangeAction();
            return props.onValueChange(selectOption.label || selectOption);
          }
        });
      }
      return props.onValueChange(selectOption.label || selectOption);
    }
    return props.onValueChange('');
  };
  switch (props.column.name) {
    case 'activated': {
      const selectedValue = props.value === undefined ? true : props.value;
      return (
        <td className="align-edit-toggle">
          <div onClick={() => handleToggle(props, selectedValue)}>
            <ActiveToggle
              active={selectedValue}
            />
          </div>
        </td>
      );
    }
    case 'smartpointSettingText': {
      items = props.spSettings;
      const selectedText = props.value;
      const selectedOption = props.spSettings.find(a => a.label === selectedText);
      const selectedValue = selectedOption && selectedOption.value;
      value = getFormattedValue(selectedText, selectedValue);
      break;
    }
    case 'settingValue': {
      items = props.settingsValue.settingValues || [];
      return selectControl({
        items,
        placeholder: '',
        ...props,
        onFocus: () => { checkTypeaheadData(props.tableRow.row.smartpointSettingValue, props.getSettingValuesAction, 0); },
        onInputChange: () => { checkTypeaheadData(props.tableRow.row.smartpointSettingValue, props.getSettingValuesAction, -1); }
      });
    }
    case 'settingAttributeText': {
      items = props.attributeSettings;
      const selectedText = props.value;
      const selectedOption = props.attributeSettings.find(a => a.label === selectedText);
      const selectedValue = selectedOption && selectedOption.value;
      value = getFormattedValue(selectedText, selectedValue);
      break;
    }
    case 'attributeValueOne':
      return attributeValueOneColumn(props);
    case 'attributeValueTwo':
      return attributeValueTwoColumn(props.attributeSettingSelected, props.attributeSettings, props.userID, props.getUsersIDAction, props, props.countries);
    case 'attributeValueThree':
      return attributeValueThirdColumn(props.attributeSettingSelected, props.attributeSettings, props);
    default:
      return defaultCellControl(props);
  }
  return (
    <td>
      <Select
        style={{ width: '100%' }}
        resetValue=""
        value={value}
        onInputChange={props.onInputChange}
        onChange={handleOnChange}
        options={items}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        clearable={false}
        escapeClearsValue={false}
      />
    </td>
  );
};

selectControl.propTypes = {
  onValueChange: PropTypes.func,
  value: PropTypes.string,
  items: PropTypes.array,
  row: PropTypes.object,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string
};

attributeValueOneColumn.propTypes = {
  onValueChange: PropTypes.func,
};

attributeValueTwoColumn.propTypes = {
  onValueChange: PropTypes.func,
};

defaultCellControl.propTypes = {
  row: PropTypes.array,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  value: PropTypes.string,
  editingEnabled: PropTypes.bool
};

EditCell.propTypes = {
  rows: PropTypes.array,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  value: PropTypes.any,
  items: PropTypes.array,
  onInputChange: PropTypes.func,
  action: PropTypes.func,
  tableRow: PropTypes.object,
  row: PropTypes.object,
  clearAttributeValues: PropTypes.func,
  getSettingValuesAction: PropTypes.func,
  spSettings: PropTypes.array,
  attributeSettings: PropTypes.array,
  settingsValue: PropTypes.object,
  attributeSettingSelected: PropTypes.any,
  userID: PropTypes.array,
  pccList: PropTypes.array,
  getUsersIDAction: PropTypes.func,
  getPccsAction: PropTypes.func,
  countries: PropTypes.any,
  regions: PropTypes.any,
  groups: PropTypes.any,
  smartpointSettingChangeAction: PropTypes.func
};

const mapStateToProps = state => ({
  userID: getUserIDSelector(state),
  pccList: getPccSelector(state)
});

export default connect(mapStateToProps, null)(EditCell);
