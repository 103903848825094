import { put } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import ApiClient from '../../../../../Services/ApiClient';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { hideLoader, showLoader } from '../../../../popUps/loaderReducer';

export const UPDATE_AGENCY_GROUP = 'UPDATE_AGENCY_GROUP';
export const updateAgencyGroup = (data) => ({
  type: UPDATE_AGENCY_GROUP,
  payload: {
    ...data
  }
});

export const UPDATE_AGENCY_GROUP_SUCCESS = 'UPDATE_AGENCY_GROUP_SUCCESS';
const updateAgencyGroupSuccess = (params) => ({
  type: UPDATE_AGENCY_GROUP_SUCCESS,
  payload: {
    params
  }
});

export const UPDATE_AGENCY_GROUP_FAILURE = 'UPDATE_AGENCY_GROUP_FAILURE';
const updateAgencyGroupFailure = (error) => ({
  type: UPDATE_AGENCY_GROUP_FAILURE,
  payload: {
    error
  }
});

const agencyGroupUpdateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_AGENCY_GROUP:
      return {
        loading: true,
        error: null
      };

    case UPDATE_AGENCY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case UPDATE_AGENCY_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};
function handleError(error) {
  if (error && error.response && error.response.status === 401) {
    handleUnauthorizedResponse();
  }
}
export function* updateAgencyGroupSaga(action) {
  try {
    const { groupId, name, assignedPlugins } = action.payload.entity;
    if (!name) {
      yield put(showAlertAction('Please enter the group name.'));
    } else {
      yield put(showLoader());
      let { members, previousMembers } = action.payload.entity;
      const { orgId } = action.payload;
      const { groupChanged } = action.payload.entity;
      const agencyGroupRequest = {
        groupId,
        orgId,
        groupName: name,
        assignedPluginIds: assignedPlugins.map(plugin => plugin.value),
      };

      // edition of group name and plugins
      if (groupChanged) { // edition of group only when group properties are changed
        yield ApiClient.agencyGroup.update({ ...agencyGroupRequest });
      }

      let data = {};
      // addition of new members to group
      members = members || [];
      previousMembers = previousMembers || [];
      const addedMembers = members.filter(item => !previousMembers.includes(item)); // pick the elements that are in the new array but not in the old one
      if (addedMembers.length > 0) {
        data = {
          groupId,
          orgId,
          memberIds: addedMembers.map(member => member.id),
        };
        yield ApiClient.httpClient.post('groups/addmemberstogroup', data);
      }
      // removal of members from group
      const removedMembers = previousMembers.filter(item => !members.includes(item)); // pick the elements that are in the old array but not in the new one
      if (removedMembers.length > 0) {
        data = {
          groupId,
          orgId,
          memberIds: removedMembers.map(member => member.id),
        };
        yield ApiClient.httpClient.delete('groups/removemembersfromgroup', { data });
      }
      yield put(updateAgencyGroupSuccess(action.payload.orgId));
      yield put(showAlertAction('Changes to the group has been saved successfully.'));
    }
  } catch (error) {
    let errorMessage = 'Some error occurred. Please try again.';
    handleError(error);
    if (error && error.response && error.response.data.result) {
      errorMessage = error.response.data.result;
    }
    yield put(showAlertAction(errorMessage));
    yield put(updateAgencyGroupFailure());
  }
  yield put(hideLoader());
}

export default agencyGroupUpdateReducer;
