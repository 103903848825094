/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import GoOutLink from '../../common/GoOutLink';

/* eslint-disable max-len */
const StandardTermsPresentation = (props) => {
  return (
    <>
      <h2>
        Terms & Conditions
        {props.hasSpecificTerms ? '(1 of 2)' : '(1 of 1)'}
      </h2>
      <div className="terms-container">
        <h2 className="text-center">
          TRAVELPORT MARKETPLACE AGREEMENT
        </h2>
        <p>
          IMPORTANT – READ THIS TRAVELPORT MARKETPLACE AGREEMENT
          (&quot;
          <b>Agreement</b>
          &quot;)
          CAREFULLY BEFORE USING THIS SITE: Your use of travelportmarketplace.com
          (&quot;
          <b>Travelport Marketplace</b>
          &quot;)
          and any associated software, applications, and other digital products
          (&quot;
          <b>Marketplace Products</b>
          &quot;) provided by Travelport or a third party developer
          (&quot;
          <b>Developer</b>
          &quot;),
          is subject to the terms and conditions of this Agreement, which include the provisions of the subscriber agreement
          between your company and Travelport
          (&quot;
          <b>Subscriber Agreement</b>
          &quot;) (if applicable), the Travelport Application and Website Privacy Policy at
          {' '}
          <GoOutLink
            linkText="https://www.travelport.com/privacy"
            link="https://www.travelport.com/privacy"
          />
          {' '}
          (&quot;
          <b>Travelport Privacy Policy</b>
          &quot;), and the Travelport Website Terms of Use  at
          {' '}
          <GoOutLink
            linkText="http://www.travelport.com/terms-conditions"
            link="http://www.travelport.com/terms-conditions"
          />
          {' '}
          (&quot;
          <b>Travelport Website Terms of Use</b>
          &quot;). Any conflict in the terms of these documents will be resolved in the following order
          of precedence: Subscriber Agreement (if applicable), this Agreement, Travelport Website Terms of Use,
          and Privacy Policy. For purposes of this Agreement,
          &quot;
          <b>you</b>
          &quot; refers collectively to you and your company.
        </p>
        <p>
          You may use the Travelport Marketplace and purchase Marketplace Products only if i) you are an authorized
          representative of your company, ii) your company is a current subscriber to the Travelport GDS
          (also known as the
          &quot;
          <b>Travelport System</b>
          &quot;), either through a valid Subscriber Agreement with Travelport, or a
          Travelport-appointed operator within a specific territory, and iii) your company&apos;s account is in
          good standing.
        </p>
        <p>
          BY CLICKING THE &quot;ACCEPT&quot; BUTTON BELOW, YOU REPRESENT THAT YOU MEET THESE CRITERIA AND AGREE
          TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT MEET THESE CRITERIA OR YOU DO NOT SO AGREE,
          DO NOT CLICK &quot;ACCEPT,&quot; AND DO NOT PURCHASE, DOWNLOAD, INSTALL OR USE ANY OF THE MARKETPLACE PRODUCTS.
          BY PURCHASING, DOWNLOADING, INSTALLING OR OTHERWISE USING ANY MARKETPLACE PRODUCTS,
          YOU AGREE TO PAY TRAVELPORT, ITS APPOINTED OPERATOR, OR THE DEVELOPER, AS APPLICABLE,
          THE FEES SET FORTH ON TRAVELPORT MARKETPLACE OR OTHERWISE ADVISED TO YOU FOR THOSE MARKETPLACE PRODUCTS.
        </p>
        <ol>
          <li>
            <p>MARKETPLACE PRODUCT ACCESS</p>
            <ol>
              <li>
                <u>Grant of Access</u>
                .  Provided that you are in material compliance with the terms and conditions of this Agreement
                and your Subscriber Agreement (as applicable), including but not limited to all payment
                obligations, Travelport grants to you a nonexclusive, nontransferable, revocable right to
                access and use the Marketplace Products you select in the purchase process. For those of you
                who have a Subscriber Agreement with Travelport, Marketplace Products that are owned by
                Travelport shall be considered &quot;Software Products&quot;, &quot;Products and Services&quot;
                or &quot;Services&quot;, and Marketplace Products that are owned by Developers shall be
                considered &quot;Third Party Products&quot; as those terms are used in your Subscriber
                Agreement.
              </li>
              <li>
                <u>Restrictions on Use</u>
                .  You agree to use the Marketplace Products only to operate your own travel business,
                including in conjunction with your affiliates, and in conjunction with Travelport&apos;s
                directions. Under no circumstances may you permit any third parties to use the Marketplace
                Products. Use of the Marketplace Products may be subject to additional terms depending on the
                nature of the Marketplace Product itself and subject to any Travelport or Developer specific
                product terms.
              </li>
              <li>
                <u>Modifications; Reverse Engineering</u>
                .  You agree that you will not directly or through a third party alter, maintain, enhance,
                disassemble, decompile, reverse engineer, create derivative works or otherwise modify any
                Marketplace Product.
              </li>
              <li>
                <u>Product Ratings</u>
                .  You are solely responsible for the content of any Marketplace Product ratings that you submit to Travelport.
              </li>
            </ol>
          </li>
          <li>
            <p>PAYMENT</p>
            You agree to pay all fees and taxes applicable to the Marketplace Products you purchase. Unless
            otherwise stated, payment for Marketplace Products will be made in accordance with your Subscriber
            Agreement or directly to the Developer through separate arrangements between yourself and the
            Developer. Marketplace Products may have a free trial period and if so, the free trial period will be
            described in the product description on Travelport Marketplace. Following any trial period, all sales
            are final and non-refundable unless otherwise specified. Travelport may offset amounts you owe under
            this Agreement from amounts Travelport may owe you under any agreement between you and Travelport.
          </li>
          <li>
            <p>SUPPORT</p>
            You agree that Travelport has no responsibility or liability with respect to i) your use of a
            Marketplace Product and ii) any content or functionality in the Marketplace Product. Travelport or a
            Developer may provide help desk support for various Marketplace Products from time to time, and you
            agree to pay any applicable separate help desk fees. Any applicable fees and support information will
            be provided alongside product information on Travelport Marketplace. These fees and support
            information are hereby incorporated by reference into this Agreement.
          </li>
          <li>
            <p>GEOGRAPHIC RESTRICTIONS</p>
            Nothing in this Agreement permits you to access or use a Marketplace Product where such access or use
            is prohibited by law or regulation or the terms of your Subscriber Agreement. Travelport or a
            Developer may restrict access to the Travelport Marketplace from time to time.
          </li>
          <li>
            <p>INTELLECTUAL PROPERTY RIGHTS</p>
            <ol>
              <li>
                <u>Title</u>
                .  Marketplace Products are protected by copyright and other intellectual property laws and treaties.
                Unless otherwise expressly stated, Travelport, the Developers or their licensors own all title, copyright
                and other intellectual property rights in the Marketplace Products. No title to any Marketplace Product is
                transferred to you.
              </li>
              <li>
                <u>Transfers</u>
                .  Under no circumstances may you sell, license, publish, display, distribute, or otherwise
                transfer to a third party any Marketplace Product or any copy thereof, in whole or in part,
                without Travelport&apos;s prior written consent.
              </li>
            </ol>
          </li>
          <li>
            <p>DISCLAIMER OF WARRANTIES</p>
            THERE ARE NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, FOR ANY MARKETPLACE PRODUCT,
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO TITLE OR INFRINGEMENT OF THIRD-PARTY RIGHTS,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THE MARKETPLACE PRODUCTS, DOCUMENTATION AND
            OTHER FILES ARE PROVIDED &quot;AS IS.&quot; TRAVELPORT AND ITS DEVELOPERS DO NOT WARRANT THE
            PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE MARKETPLACE PRODUCTS. YOU AGREE THAT TRAVELPORT OR
            A DEVELOPER MAY MODIFY / RESTRICT ACCESS TO A MARKETPLACE PRODUCT AND THAT SUCH MODIFICATION MAY
            IMPACT YOUR ABILITY TO ACCESS OR USE THE MARKETPLACE PRODUCT. TRAVELPORT MAY RELEASE NEW VERSIONS
            OF THE TRAVELPORT SYSTEM THAT DO NOT SUPPORT MARKETPLACE PRODUCTS YOU HAVE DOWNLOADED.
            Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may
            not apply to you if prohibited by applicable law.
          </li>
          <li>
            <p>EXCLUSION OF LIABILITY/LIMITATION OF LIABILITY</p>
            IN NO EVENT WILL TRAVELPORT BE LIABLE TO YOU OR ANY THIRD PARTY FOR DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER SIMILAR DAMAGES, INCLUDING LOST PROFITS, ARISING FROM
            THE USE OF OR INABILITY TO USE ANY MARKETPLACE PRODUCT, EVEN IF TRAVELPORT HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. TRAVELPORT IS NOT RESPONSIBLE FOR ANY DEVELOPERS PROVIDING PRODUCTS OR
            SERVICES IN THE TRAVELPORT MARKETPLACE. IN NO EVENT (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW)
            WILL TRAVELPORT&apos;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OR
            INABILITY TO USE THE MARKETPLACE PRODUCTS EXCEED THE LESSER OF (i) THE AMOUNT SPECIFIED IN YOUR
            SUBSCRIBER AGREEMENT, OR (ii) THE PURCHASE PRICE OF THE MARKETPLACE PRODUCT GIVING RISE TO THE CLAIM
            FOR LIABILITY.

          </li>
          <li>
            <p>GENERAL PROVISIONS</p>
            <ol>
              <li>
                <u>Modifications</u>
                .  Travelport may alter or revise the terms of this Agreement from time to time. If you do not
                agree to accept the changes, your sole remedy shall be to discontinue your use of the Travelport
                Marketplace and the Marketplace Products.
              </li>
              <li>
                <u>Choice of Law and Location for Resolving Disputes</u>
                .  The choice of law and dispute resolution provisions in your Subscriber Agreement will control
                any disputes under this Agreement, or where your access to the Travelport System is provided
                through a Travelport-appointed operator, then the governing law shall be the laws of England and
                Wales and the English courts will have non-exclusive jurisdiction over any disputes arising out
                of or in connection with this Agreement.
              </li>
              <li>
                <u>Termination</u>
                .  Unless otherwise terminated as set forth in this Agreement, this Agreement will terminate as of
                the termination of your Subscriber Agreement or as of the date in which your access to the
                Travelport System ceases to be provided by a Travelport-appointed operator in a specific territory.
                Travelport may terminate this Agreement if you fail to comply with any terms and conditions of
                this Agreement, or any provision of your Subscriber Agreement (if applicable), the Travelport
                Privacy Policy, or the Travelport Website Terms of Use, or if it receives notice from a
                Travelport-appointed operator in a specific territory to terminate this Agreement. Upon any such
                termination, Travelport may immediately deny access to the Marketplace Products, and you must
                discontinue use of the Marketplace Products. Travelport may modify, suspend or discontinue any
                Marketplace Product and/or the Travelport Marketplace, in whole or in part, at any time without
                notice.
              </li>
              <li>
                <u>Severability</u>
                .  Except as otherwise set forth in this Agreement, the provisions of this Agreement are
                severable, and if any one or more such provisions shall be determined to be invalid, illegal or
                unenforceable, in whole or in part, the validity, legality and enforceability of any of the
                remaining provisions or portions thereof shall not in any way be affected and shall nevertheless
                be binding between the parties. Any such invalid, illegal or unenforceable provision or portion
                of such provision shall be changed and interpreted so as to best accomplish the objectives of
                such provision or portion thereof within the limits of applicable law.
              </li>
              <li>
                <u>Waiver</u>
                .  Any waiver, either expressed or implied, by either party of any default by the other in the
                observance and performance of any of the conditions and/or covenants of duties set forth in this
                Agreement shall not constitute or be construed as a waiver of any subsequent or other default.
              </li>
              <li>
                <u>Read and Understood</u>
                .  You hereby acknowledge that you have read and understand this Agreement and agree to be bound by its terms.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </>
  );
};

StandardTermsPresentation.propTypes = {
  hasSpecificTerms: PropTypes.bool
};

export default StandardTermsPresentation;
