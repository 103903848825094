/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import VideoScreenShot from './VideoScreenShot';
import RemoveEntityPopUp from './RemoveEntityPopUp';

const VideoScreenShotPreview = (props) => (
  <Modal
    id="details-product-video-upload-modal"
    title={props.title}
    size="small"
    onDismiss={props.popUpHide}
    hidden={false}
    className="center-screen tp-video"
  >
    <iframe
      title="title"
      id="product-details-video"
      src={`//www.youtube.com/embed/${props.videoURL}`}
    />
  </Modal>
);

class VideoScreenShotComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: this.formVideoItems(props.videoList),
      showPreview: false,
      showRemovePopup: false,
      selectedVideoURL: '',
      selectedIndexToRemove: -1
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (this.props.videoList && this.props.videoList.length < nextProps.videoList.length) {
        this.setState({
          videoList: this.formVideoItems(nextProps.videoList),
          showPreview: false,
          showRemovePopup: false,
          selectedVideoURL: '',
          selectedIndexToRemove: -1
        });
      }
    }
  }

  getYoutubeCodeFromUrl(url) {
    let youtubeId = null;
    // Case 1: URL with v=XXXX
    const idxofV = url.indexOf('v=');
    if (idxofV !== -1) {
      [youtubeId] = url.split('v=');
      const ampersandPosition = youtubeId[1].indexOf('&');
      if (ampersandPosition !== -1) {
        youtubeId = youtubeId[1].substring(0, ampersandPosition);
      }
    } else {
      // Check for youtu.be/xxx URL
      const tokens = url.split('/');
      if (!tokens || tokens.length === 0) return null;
      youtubeId = tokens[tokens.length - 1];
    }
    return youtubeId || url;
  }

  getChangehandler(e, index) {
    const list = this.state.videoList;
    list[index].tempVideoUrl = e.target.value;
    this.setState({
      videoList: list
    });
    // this.props.getVideoList(this.formVideoList(this.state.videoList));
  }

  formVideoItems(items = []) {
    const itemsList = [];
    if (items.length > 0) {
      items.forEach((val) => {
        itemsList.push({
          tempVideoUrl: this.getYoutubeCodeFromUrl(val),
          videoURL: this.getYoutubeCodeFromUrl(val),
          screenShotsource: this.createYoutubeScreenshot(val)
        });
      });
    }

    if (items.length < 3 && items[items.length - 1] !== '') {
      itemsList.push({
        tempVideoUrl: '',
        videoURL: '',
        screenShotsource: ''
      });
    }

    return itemsList;
  }

  addVideo(index) {
    const list = this.state.videoList;
    const videoURL = this.getYoutubeCodeFromUrl(list[index].tempVideoUrl);
    if (!videoURL) {
      return;
    }
    list[index].tempVideoUrl = videoURL;
    list[index].screenShotsource = this.createYoutubeScreenshot(videoURL);
    if (list.length < 3) {
      list.push({
        tempVideoUrl: '',
        videoURL: '',
        screenShotsource: ''
      });
    }
    this.setState({
      videoList: list
    }, () => {
      this.props.getVideoList(this.formVideoList(list));
    });
  }

  createYoutubeScreenshot(id) {
    return `//img.youtube.com/vi/${id}/0.jpg`;
  }

  formVideoList(videoList) {
    const videos = [];
    for (let i = 0; i < videoList.length; i += 1) {
      if (videoList[i].tempVideoUrl !== '') {
        videos.push(videoList[i].tempVideoUrl);
      }
    }
    return videos;
  }

  youtubeRemove(index) {
    this.setState({
      showRemovePopup: true,
      selectedIndexToRemove: index
    });
  }

  youtubePreview(index) {
    const list = this.state.videoList;
    const selectedVideoURL = this.getYoutubeCodeFromUrl(list[index].tempVideoUrl);
    this.setState({
      showPreview: true,
      selectedVideoURL
    });
  }

  removeVideo() {
    const list = this.state.videoList;
    const { selectedIndexToRemove } = this.state;
    list.splice(selectedIndexToRemove, 1);
    this.setState({
      videoList: list,
      showRemovePopup: false
    });
    this.props.getVideoList(this.formVideoList(list));
  }

  renderUI() {
    return (
      this.state.videoList && this.state.videoList.map((videoObject, index) => (
        <div className="row" key={index.toString()}>
          <div className="row horizontal-input-group space-bottom">
            <div>
              <input type="text" value={videoObject.tempVideoUrl} onChange={(e) => this.getChangehandler(e, index)} className="text-input" placeholder="paste link" />
            </div>
            <Link
              to="#"
              className="cancel-button small-text"
              onClick={(e) => {
                e.preventDefault();
                this.addVideo(index);
              }}
            >
              Add
            </Link>
          </div>
          {
              videoObject.screenShotsource
                ? (
                  <VideoScreenShot
                    VideoScreenshotSource={videoObject.screenShotsource}
                    youtubePreview={() => this.youtubePreview(index)}
                    youtubeRemove={() => this.youtubeRemove(index)}
                  />
                ) : null
            }
        </div>
      ))
    );
  }

  renderPreviewPopUp() {
    return (
      this.state.showPreview
        ? (
          <VideoScreenShotPreview
            videoURL={this.state.selectedVideoURL}
            popUpHide={() => this.setState({ showPreview: false })}
          />
        )
        : null
    );
  }

  renderRemoveVideoPopUp() {
    return (
      this.state.showRemovePopup
        ? (
          <RemoveEntityPopUp
            title="Remove video"
            bodyContent="Are you sure you want to remove this video?"
            remove={() => this.removeVideo()}
            popUpHide={() => this.setState({ showRemovePopup: false })}
          />
        )
        : null
    );
  }

  render() {
    return (
      <>
        {this.renderUI()}
        {this.renderPreviewPopUp()}
        {this.renderRemoveVideoPopUp()}
      </>
    );
  }
}

VideoScreenShotPreview.propTypes = {
  videoURL: PropTypes.string,
  popUpHide: PropTypes.func
};

VideoScreenShotComponent.propTypes = {
  VideoUrl: PropTypes.string,
  TempVideoUrl: PropTypes.string,
  VideoScreenshotSource: PropTypes.string,
  videoList: PropTypes.array,
  getVideoList: PropTypes.func
};

VideoScreenShotComponent.defaultProps = {
  videoList: []
};

export default VideoScreenShotComponent;
