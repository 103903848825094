/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import UploadPlugin from '../UploadPlugin/UploadPlugin';
import UploadInstructions from '../UploadInstructions/UploadInstructions';

class SolutionDistribution extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showInstructions: false
    };
  }

  onHideUpload = () => this.setState({ showModal: false });

  onHideInstructions = (event) => {
    event.preventDefault();
    this.setState({ showInstructions: false });
  }

  showInstructions = () => this.setState({ showInstructions: true });

  upload = () => this.setState({ showModal: true });

  render() {
    const {
      currentVersion,
      uploadVersion,
      displayText,
      instructionsLink,
      isSpPlugin,
      onChange,
      tooltip,
      ...input
    } = this.props;

    return (
      <div className="row">
        <div className="col-lg">
          {(this.state.showModal) && <UploadPlugin showModal={this.state.showModal} popUpHide={this.onHideUpload} name={this.props.name} isSpPlugin={isSpPlugin} />}
          {(this.state.showInstructions) && <UploadInstructions showModal={this.state.showModal} popUpHide={this.onHideInstructions} name={this.props.name} />}
          <div className="row no-space">
            <div className="col-sm-4">
              <SimplePrettyCheckBox
                {...input}
                value={input.checked}
                displayText={displayText}
                onChange={onChange}
                tooltip={tooltip}
              />
            </div>
            {input.value
            && (
            <div className="col-sm-4">
              <a onClick={this.showInstructions}>{instructionsLink}</a>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SolutionDistribution.propTypes = {
  currentVersion: PropTypes.string,
  uploadVersion: PropTypes.string,
  displayText: PropTypes.string,
  instructionsLink: PropTypes.string,
  isSpPlugin: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  name: PropTypes.string
};

export default SolutionDistribution;
