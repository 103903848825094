import { combineReducers } from 'redux';
import hostList from './hostList';

export const getPccsSelector = state => state.profile.userProfile.pccsForUser;

export const trasnformPccData = data => data.map(item => ({
  value: item,
  label: item
}));

const agencyDetails = combineReducers({
  hostList,
});

export {
  getHostList,
  watchHostListSaga
} from './hostList';

export default agencyDetails;
