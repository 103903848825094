import {
  takeLatest, call, put, all
} from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export const DISTRIBUTION_ADMIN_ASSIGNMENTS = createFetchTypes('Marketplace/Administration/distributionAdminAssignments/FETCH_DISTRIBUTION_ADMIN');
export const PLATFORM_LIST = createFetchTypes('Marketplace/Administration/distributionAdminAssignments/PLATFORM_LIST');
export const UPDATE_DISTRIBUTION_ASSIGNMENTS = createFetchTypes('Marketplace/Administration/distributionAdminAssignments/UPDATE_DISTRIBUTION_ASSIGNMENTS');
export const distributionAdministratorSelector = state => state.administration.distributionAdminAssignments;
const FETCH_URL = 'superadmin/getdisributionadmins';
const platformListURL = 'superadmin/getallplatformproductsselectlist';

function distributionAdminAssignments(state = {}, action = {}) {
  switch (action.type) {
    case DISTRIBUTION_ADMIN_ASSIGNMENTS.REQUEST:
    case UPDATE_DISTRIBUTION_ASSIGNMENTS.REQUEST:
    case PLATFORM_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DISTRIBUTION_ADMIN_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.users,
        totalPages: action.data.totalPages,
        error: null
      };
    case DISTRIBUTION_ADMIN_ASSIGNMENTS.FAILURE:
      return state;
    case PLATFORM_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        platformList: action.data,
        error: null
      };
    case UPDATE_DISTRIBUTION_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case PLATFORM_LIST.FAILURE:
    case UPDATE_DISTRIBUTION_ASSIGNMENTS.FAILURE:
      return state;
    default:
      return state;
  }
}

export const fetchdistributionAdminAssignments = {
  base: (params) => ({
    type: DISTRIBUTION_ADMIN_ASSIGNMENTS.BASE,
    params,
    url: FETCH_URL
  }),
  request: () => ({ type: DISTRIBUTION_ADMIN_ASSIGNMENTS.REQUEST }),
  success: (data) => ({ type: DISTRIBUTION_ADMIN_ASSIGNMENTS.SUCCESS, data }),
  failure: (error) => ({ type: DISTRIBUTION_ADMIN_ASSIGNMENTS.FAILURE, error })
};

export const fetchPlatformList = {
  base: (params) => ({
    type: PLATFORM_LIST.BASE,
    params,
    url: platformListURL
  }),
  request: () => ({ type: PLATFORM_LIST.REQUEST }),
  success: (data) => ({ type: PLATFORM_LIST.SUCCESS, data }),
  failure: (error) => ({ type: PLATFORM_LIST.FAILURE, error })
};

export const updateDistributionAssignments = {
  base: (params) => ({
    type: UPDATE_DISTRIBUTION_ASSIGNMENTS.BASE,
    params,
    url: 'superadmin/updatedistributionassignmentsforuser'
  }),
  request: () => ({ type: UPDATE_DISTRIBUTION_ASSIGNMENTS.REQUEST }),
  success: (data) => ({ type: UPDATE_DISTRIBUTION_ASSIGNMENTS.SUCCESS, data }),
  failure: (error) => ({ type: UPDATE_DISTRIBUTION_ASSIGNMENTS.FAILURE, error })
};

function* fetchdistributionAdminSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield all([
      call(() => fetchEntity(fetchdistributionAdminAssignments, ApiClient.httpClient.get, url, { params: { ...params } })),
      call(() => fetchEntity(fetchPlatformList, ApiClient.httpClient.get, platformListURL))
    ]);
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

function* fetchPlatformListSaga({ url }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchPlatformList, ApiClient.httpClient.get, url));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

function* updatedistributionAdminSaga({ url, params }) {
  yield put(showLoader());
  try {
    const payload = {
      userId: params.userId,
      platformProductIds: params.platformProductIds
    };
    yield call(() => fetchEntity(updateDistributionAssignments, ApiClient.httpClient.post, url, payload));
    yield call(() => fetchEntity(fetchdistributionAdminAssignments, ApiClient.httpClient.get, FETCH_URL, { params: { ...params.queryString } }));
    yield put(showAlertAction('Assigned Platform Product(s) have been updated for the user.'));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* distributionAdminMiddleware() {
  yield takeLatest(DISTRIBUTION_ADMIN_ASSIGNMENTS.BASE, fetchdistributionAdminSaga);
  yield takeLatest(PLATFORM_LIST.BASE, fetchPlatformListSaga);
  yield takeLatest(UPDATE_DISTRIBUTION_ASSIGNMENTS.BASE, updatedistributionAdminSaga);
}

export default distributionAdminAssignments;
