/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const VideoScreenShot = (props) => (
  <div className="row no-space add-video-screenshot">
    <img src={props.VideoScreenshotSource} alt="" />
    <div className="add-video-actions">
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          props.youtubeRemove();
        }}
      >
        Remove
      </Link>
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          props.youtubePreview();
        }}
      >
        Preview
      </Link>
    </div>
  </div>
);

VideoScreenShot.propTypes = {
  VideoScreenshotSource: PropTypes.string,
  youtubePreview: PropTypes.func,
  youtubeRemove: PropTypes.func
};

export default VideoScreenShot;
