export const manageLicensesSearchSelector = state => state.administration.manageLicenses.manageLicenseSearch;

export const manageLicensesSearchPluginsSelector = state => (
  state.administration.manageLicenses.manageLicenseSearch.plugins
);

export const manageLicensesSearchAgenciesSelector = state => (
  state.administration.manageLicenses.manageLicenseSearch.agencies
);

export const editLicenseStatusSelector = state => (
  state.administration.manageLicenses.editLicenseStatus
);
