import { combineReducers } from 'redux';
import manageLicenseSearch from './ManageLicenseSearch/manageLicenseSearch';
import editLicenseStatus from './EditLicenseStatus/editLicenseStatus';

export {
  manageLicensesSearchSagaWatcher
} from './ManageLicenseSearch/manageLicenseSearch';

export {
  editLicenseStatusMiddleWare
} from './EditLicenseStatus/editLicenseStatus';

const manageLicenses = combineReducers({
  manageLicenseSearch,
  editLicenseStatus
});

export default manageLicenses;
