import {
  takeLatest, call, put, select
} from 'redux-saga/effects';
import createFetchTypes from '../../utils/createFetchTypes';
import fetchEntity from '../../utils/fetchEntitySaga';
import ApiClient from '../../../Services/ApiClient';
import { showAlertAction } from '../../popUps/alertPopUpReducer';
import { recommendationSelectors, filterBuyersEmail } from '../selectors';
import { showLoader, hideLoader } from '../../popUps/loaderReducer';

export const FETCH_RECOMMENDS = createFetchTypes('Marketplace/Product/Product/RecommendReducer/FETCH_RECOMMENDS');
export const SAVE_RECOMMENDS = createFetchTypes('Marketplace/Product/Product/RecommendReducer/SAVE_RECOMMENDS');
const ADD_SELECTED_BUYERS = 'Marketplace/Product/Product/RecommendReducer/ADD_SELECTED_BUYERS';
const CHANGE_SELECTED_BUYERS = 'Marketplace/Product/Product/RecommendReducer/CHANGE_SELECTED_BUYERS';

function filterEmails(emailList) {
  const emails = [];
  const list = emailList.buyers;
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].email) {
      emails.push({ email: list[i].email, checked: true });
    }
  }
  return emails;
}
const recommendReducer = (state = [], action = {}) => {
  switch (action.type) {
    case FETCH_RECOMMENDS.REQUEST:
    case SAVE_RECOMMENDS.REQUEST:
      return {
        ...state
      };
    case FETCH_RECOMMENDS.SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          selectedBuyers: filterEmails(action.payload.data)
        },
        error: null
      };
    case FETCH_RECOMMENDS.ERROR:
    case SAVE_RECOMMENDS.ERROR:
      return {
        ...state,
        error: action.error
      };
    case CHANGE_SELECTED_BUYERS:
      return {
        ...state,
        data: {
          ...state.data,
          selectedBuyers: action.payload.params.splice(0, action.payload.params.length)
        }
      };
    case ADD_SELECTED_BUYERS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.params
        }
      };
    case SAVE_RECOMMENDS.SUCCESS:
      return {
        ...state,
        success: true,
        error: null
      };
    default:
      return state;
  }
};

export const addSelectedBuyers = {
  base: (params) => ({
    type: ADD_SELECTED_BUYERS,
    payload: {
      params
    }
  })
};

export const changeSelectedBuyers = {
  base: (params) => ({
    type: CHANGE_SELECTED_BUYERS,
    payload: {
      params
    }
  })
};

export const fetchRecommendations = {
  base: (params) => ({
    type: FETCH_RECOMMENDS.BASE,
    url: 'buy/recommend',
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_RECOMMENDS.REQUEST
  }),
  success: (data) => ({
    type: FETCH_RECOMMENDS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_RECOMMENDS.FAILURE,
    payload: {
      error
    }
  })
};

export const saveRecommendations = {
  base: () => ({
    type: SAVE_RECOMMENDS.BASE,
    url: 'buy/sendrecommentation'
  }),
  request: () => ({
    type: SAVE_RECOMMENDS.REQUEST
  }),
  success: (data) => ({
    type: SAVE_RECOMMENDS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_RECOMMENDS.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchRecommendationsSaga(action) {
  try {
    yield put(showLoader());
    const { params } = action.payload;
    yield call(() => fetchEntity(fetchRecommendations, ApiClient.httpClient.get, action.url, { params: { id: params.publicId } }));
    yield put(hideLoader());
  } catch (error) {
    put(showAlertAction(error));
    yield put(hideLoader());
  }
}

export function* saveRecommendationsSaga(action) {
  try {
    yield put(showLoader());
    const { url } = action;
    const selectors = yield select(recommendationSelectors);
    const selectedBuyers = yield select(filterBuyersEmail);
    const payload = {
      id: selectors.publicGuid,
      selectedBuyers,
      userComments: selectors.userComments,
      data: {
        selectedBuyers
      }
    };
    yield call(() => fetchEntity(saveRecommendations, ApiClient.httpClient.post, url, payload));
    yield put(hideLoader());
  } catch (error) {
    put(showAlertAction(error));
    yield put(hideLoader());
  }
}

export function* watchFetchRecommendationsSaga() {
  yield takeLatest(FETCH_RECOMMENDS.BASE, fetchRecommendationsSaga);
}

export function* watchSaveRecommendationsSaga() {
  yield takeLatest(SAVE_RECOMMENDS.BASE, saveRecommendationsSaga);
}

export default recommendReducer;
