export function getRequestDemoData(state) {
  const stateData = state.product.details.data.applicationDetails;
  return {
    firstname: `${stateData.userDetails.firstName} ${stateData.userDetails.lastName}`,
    emailaddress: stateData.userDetails.email,
    phonenumber: stateData.agencyDetails.officePhoneNumber,
    organization: stateData.userDetails.organizationName,
    location: stateData.userDetails.country,
    organisationcountry: stateData.agencyDetails.country,
    productname: stateData.name,
    productid: stateData.applicationId,
    userid: stateData.userDetails.userId,
    hosts: stateData.agencyDetails.hostString,
    salescontactname: stateData.agencyDetails.salesContactName,
    salesemailaddress: stateData.agencyDetails.salesEmailAddress,
    pccsstring: stateData.agencyDetails.pccsString,
    demoemailreq: stateData.demoEmailAddress
  };
}

export const recommendationSelectors = state => state.product.recommend.data;
export const navigate = state => state.product.recommend.success;
export const filterBuyersEmail = (state) => {
  const emailList = [];
  state.product.recommend.data.selectedBuyers.filter((item) => {
    if (item.checked) {
      emailList.push(item.email);
    }
    return item;
  });
  return emailList;
};
