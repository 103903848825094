/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Tabs } from '@atlas-design-system/react';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';
import * as Config from '../../config/apiConfig';
import { organizationLoad, organizationLoadSuccess, organizationLoadFailure } from '../../actions/index';
import BulletDetails from './BulletDetails';
import SocialShareIcons from '../common/SocialShareIcons';
import * as CommonServices from '../../Services/Common.Service';
import DetailsSpecs from '../common/DetailsSpecs';
import DetailsMedia from '../common/DetailsMedia';
import GoOutLink from '../common/GoOutLink';
import TileLayout from '../common/TileLayout';
import LoaderComponent from '../common/Loader/LoaderComponent';

class DeveloperDetails extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount() {
    const response = this.loadOrganization();
    response.then((res) => {
      this.props.organizationLoadSuccess(res);
    }).catch((error) => {
      this.props.organizationLoadFailure(error);
    });
  }

  componentDidMount() {
    CommonServices.SSOProcessing(this.props.user);
  }

  getDetailSpecItems() {
    return (
      [
        { name: 'Travelport Tools', data: this.props.organization.organisationTools },
        { name: 'Primary Developer Language', data: this.props.organization.organisationDevelopmentLanguages },
        { name: 'Regions covered', data: this.props.organization.organisationRegions },
        { name: 'Product Categories', data: this.props.organization.productCategories },
        { name: 'GDS Supported', data: this.props.organization.organisationHosts },
      ]
    );
  }

  getDetailsSpacingClasses() {
    return (this.props.organization.hasVideos || this.props.organization.hasScreenshots)
      ? 'col-xs-12 col-sm-8 col-sm-push-4'
      : 'col-xs-12';
  }

  loadOrganization() {
    this.props.organizationLoad();
    return fetch(`${Config.BASE_URL}${Config.GET_ORGANIZATION_DETAILS}/${this.props.match.params.seoname}`)
      .then((response) => (this.isValidResponse(response) ? response.json() : []));
  }

  isValidResponse(response) {
    if (response.status === 200 || response.status === 201) {
      return true;
    }
    return false;
  }

  customDevelopmentSupportedIcon() {
    return (
      <span className="inverse-text inverse-text--tp-dev-custom space-right-sm" title="">
        <span className="icon-checkmark-black" />
        {' '}
        Custom Development
      </span>
    );
  }

  tpCertifiedDevNetworkIcon() {
    return (
      <span id="tp-certifited" className="inverse-text inverse-text--tp-dev-net" title="">
        <span className="icon-travelport-certified" />
        {' '}
        Developer Network
      </span>
    );
  }

  partnerSection() {
    let partnerIcon = '';
    if (this.props.organization.partnerLevel === 1) {
      partnerIcon = (
        <span className="developers-details-partner">
          <span className="icon-partner-silver" />
          {' '}
          Silver Partner
        </span>
      );
    } else if (this.props.organization.partnerLevel === 2) {
      partnerIcon = (
        <span className="developers-details-partner">
          <span className="icon-partner-gold" />
          {' '}
          Gold Partner
        </span>
      );
    } else if (this.props.organization.partnerLevel === 3) {
      partnerIcon = (
        <span className="developers-details-partner">
          <span className="icon-partner-platinum" />
          {' '}
          Platinum Partner
        </span>
      );
    }
    return (
      <div className="details-summary-header">
        {partnerIcon}
      </div>
    );
  }

  bulletedDetails() {
    return (
      <ul className="details-summary-ul">
        <BulletDetails name="Website">
          {this.props.organization.url
            && <GoOutLink id="websiteLink" developername={this.props.organization.name} linkText={this.props.organization.url} link={this.props.organization.url} />}
        </BulletDetails>
        {this.props.organization.phoneOffice
          && <BulletDetails name="Phone">{this.props.organization.phoneOffice}</BulletDetails>}
        {this.props.organization.emailAddress
          && (
            <BulletDetails name="Email">
              <a id="emailLink" developername={this.props.organization.name} href={`mailto:${this.props.organization.emailAddress}`}>{this.props.organization.emailAddress}</a>
            </BulletDetails>
          )}
        {this.props.organization.headquarters
          && <BulletDetails name="Headquarters">{this.props.organization.headquarters}</BulletDetails>}
        <BulletDetails name="Privacy Policy">
          {this.props.organization.privacyPolicyLink
            && (
              <GoOutLink
                linkText={this.props.organization.privacyPolicyLink}
                link={this.props.organization.privacyPolicyLink}
              />
            )}
        </BulletDetails>

        {this.props.organization.organisationCountries
          && <BulletDetails name="Other office Locations">{this.props.organization.organisationCountries}</BulletDetails>}
      </ul>
    );
  }

  header() {
    return (
      <div className="row details-main-info-row">
        <div className="col-xs-12 details-main-info">
          <img className="details-logo" src={this.props.organization.logoUrl || require('Styles/img/logo-placeholder-square.jpg')} alt={this.props.organization.name} />
          <div className="details-name">
            <h2>{this.props.organization.name}</h2>
          </div>
        </div>
      </div>
    );
  }

  detailsSummary() {
    return (
      <div className="col-xs-12 details-summary">
        <div className="details-summary-content">
          {this.partnerSection()}
          {this.bulletedDetails()}
          <div className="no-margin">
            {this.props.organization.isCustomDevelopmentSupported
              && this.customDevelopmentSupportedIcon()}
            {this.tpCertifiedDevNetworkIcon()}
            <SocialShareIcons url={window.location.href} title={`Travelport Marketplace Developer - ${this.props.organization.name}`} />
          </div>
        </div>
      </div>
    );
  }

  developerDetailsSummary() {
    return (
      <div className="row details-summary-row">
        {this.detailsSummary()}
        <DetailsSpecs items={this.getDetailSpecItems()} />
      </div>
    );
  }

  developerDetails() {
    return (
      <div className="row developers-detail-tabs-row">
        <div className="col-xs-12 developers-detail-tabs">
          <Tabs defaultActiveKey="0" animation={false} id="faq-tabs">
            <Tabs.Tab eventKey={1} title="Description">
              <p className="pd-all">
                {/* eslint-disable react/no-array-index-key */}
                {this.props.organization.fullDescription
                  ? this.props.organization.fullDescription.split('\n').map((line, k) => (
                    <React.Fragment key={k}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                  : 'No developer description'}
              </p>
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    );
  }

  backButton() {
    return (
      <div className="row">
        <a href="#" onClick={() => this.props.history.push({ pathname: '/developers', cleanFilter: false })} className="prod-detail-back-to-results">
          <svg viewBox="0 0 9 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M9,13L2.8,6.5,9,0H6.2L0,6.5,6.2,13H9Z" />
          </svg>
          Back to Developers
        </a>
      </div>
    );
  }

  productsByDeveloper() {
    if (this.props.organization.applicationsByOrganisation.length > 0) {
      return (
        <>
          <div>
            <h2 className="popular-products-title text-center">Products Offered by this Developer</h2>
          </div>
          <div className="rowwrap">
            {this.props.organization.applicationsByOrganisation.map((v, i) => (
              <div key={i.toString()} className="col-sm-4">
                <TileLayout {...v} linkFor="DeveloperDirectory" />
              </div>
            ))}
          </div>
        </>
      );
    }
    return '';
  }

  render() {
    if (this.props.loading) {
      return (
        <LoaderComponent show />
      );
    }
    return (
      <div className="container details-layout">
        <div className="row details-media-row">
          <div className={`${this.getDetailsSpacingClasses()} details-main-info-col`}>
            {this.header()}
            {this.developerDetailsSummary()}
          </div>
          {(this.props.organization.hasVideos || this.props.organization.hasScreenshots)
            && (
              <DetailsMedia
                screenshotCarousels={this.props.organization.screenshotCarouselUrls}
                screenshotPreviews={this.props.organization.screenshotPreviewUrls}
                screenshotThumbnails={this.props.organization.screenshotThumbnailUrls}
                videos={this.props.organization.organizationVideoUrls}
              />
            )}
        </div>
        {this.developerDetails()}
        {/* {this.backButton()} */}
        {this.props.organization.applicationsByOrganisation && this.productsByDeveloper()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization.orgDetails,
  loading: state.organization.loading,
  user: state.oidc.user
});

const mapDispatchToProps = (dispatch) => ({
  organizationLoad: bindActionCreators(organizationLoad, dispatch),
  organizationLoadSuccess: bindActionCreators(organizationLoadSuccess, dispatch),
  organizationLoadFailure: bindActionCreators(organizationLoadFailure, dispatch),
  loadUserInfo: () => dispatch(loadUserInfo.base())
});

DeveloperDetails.propTypes = {
  organizationLoad: PropTypes.func,
  organizationLoadSuccess: PropTypes.func,
  organizationLoadFailure: PropTypes.func,
  loading: PropTypes.bool,
  loadUserInfo: PropTypes.func,
  organization: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperDetails);
