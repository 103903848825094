import { combineReducers } from 'redux';
import { exportProductEventReportReducer } from './exportProductEventReportReducer';
import { productEventReportReducer } from './productEventReportReducer';
import { productSelectListReducer } from './productSelectListReducer';

const salesReport = combineReducers({
  export: exportProductEventReportReducer,
  productEventReport: productEventReportReducer,
  productSelectList: productSelectListReducer
});

export {
  exportProductEventReport,
  watchExportProductEventReportSaga
} from './exportProductEventReportReducer';

export {
  fetchproductEventReport,
  watchFetchproductEventReportSaga
} from './productEventReportReducer';

export {
  fetchProductSelectList,
  watchFetchProductSelectListSaga
} from './productSelectListReducer';

export default salesReport;
