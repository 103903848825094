import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CarouselItemBox extends PureComponent {
  getContent = (isLargeView) => {
    if (isLargeView) {
      return (
        <div className="carousel-item-tile">
          <div>
            <h2>{this.props.heading}</h2>
            <p>{this.props.description}</p>
          </div>
          <button type="button" className="carousel-item-button">{this.props.buttonText}</button>
        </div>
      );
    }
    return (
      <div className="carousel-item-tile">
        <h2>{this.props.heading}</h2>
        <p>{this.props.description}</p>
        <button type="button" className="carousel-item-button">{this.props.buttonText}</button>
      </div>
    );
  }

  render() {
    const componentClassName = `${this.props.isLargeView ? 'col-xs-4' : ''} carousel-item-box ${this.props.componentClassName}`;
    if (this.props.navigationPath.startsWith('http')) {
      return (
        <a className={componentClassName} href={this.props.navigationPath} target="_blank" rel="noreferrer">
          <div className="carousel-item-img">
            <img src={this.props.imageFile} alt="" />
          </div>
          {this.getContent(this.props.isLargeView)}
        </a>
      );
    }
    return (
      <Link to={this.props.navigationPath} className={componentClassName}>
        <div className="carousel-item-img">
          <img src={this.props.imageFile} alt="" />
        </div>
        {this.getContent(this.props.isLargeView)}
      </Link>
    );
  }
}

CarouselItemBox.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageFile: PropTypes.string.isRequired,
  componentClassName: PropTypes.string.isRequired,
  isLargeView: PropTypes.bool,
  navigationPath: PropTypes.string,
  buttonText: PropTypes.string
};

export default CarouselItemBox;
