import {
  takeLatest, call, put, take, race, all
} from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import * as actionTypes from 'actions/actionType';
import { cleanGetHostMatchingId } from 'reducers/modules/Profile/Agency/AgencyDetails/getHostIdsReducer';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';

const GET_FULL_PROFILE = createFetchTypes(
  'Marketplace/Agency/GET_FULL_PROFILE'
);

const SAVE_HOST_ENTRY = createFetchTypes(
  'Marketplace/Profile/Agency/AgencyDetails/SAVE_HOST_ENTRY'
);
const REMOVE_HOST_ENTRY = createFetchTypes(
  'Marketplace/Profile/Agency/AgencyDetails/REMOVE_HOST_ENTRY'
);

export const CLEAN_USER_PROFILE = 'CLEAN_USER_PROFILE';

export const cleanUserProfile = () => ({
  type: CLEAN_USER_PROFILE
});

export const getFullProfileReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CLEAN_USER_PROFILE:
      return {
        userDetails: {},
        agencyDetails: {}
      };
    case GET_FULL_PROFILE.REQUEST:
    case GET_FULL_PROFILE.FAILURE:
    case SAVE_HOST_ENTRY.REQUEST:
    case REMOVE_HOST_ENTRY.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_FULL_PROFILE.SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        userDetails: action.payload.userDetails,
        hostEntries: action.payload.hostEntries,
        agencyDetails: action.payload.agencyDetails
      };
    case SAVE_HOST_ENTRY.SUCCESS:
    case REMOVE_HOST_ENTRY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
        hostEntries: action.payload.data.hostEntries
      };
    case SAVE_HOST_ENTRY.FAILURE:
    case REMOVE_HOST_ENTRY.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        hostEntries:
          (action.payload.error.response
            && action.payload.error.response.data
            && action.payload.error.response.data.hostEntries)
          || state.hostEntries
      };
    default:
      return state;
  }
};
export const getFullProfile = {
  base: data => ({
    type: GET_FULL_PROFILE.BASE,
    data,
    url: 'profile/getfulluserprofile'
  }),
  request: () => ({ type: GET_FULL_PROFILE.REQUEST }),
  success: payload => ({ type: GET_FULL_PROFILE.SUCCESS, payload }),
  failure: error => ({ type: GET_FULL_PROFILE.FAILURE, error })
};

function* getFullProfileSaga(action) {
  yield put(showLoader());
  try {
    if (typeof action.data !== 'undefined') {
      yield put(cleanUserProfile());
      const params = { username: action.data };
      const [response, pccsForUserResponse] = yield all([
        call(() => fetchEntity(getFullProfile, ApiClient.httpClient.get, action.url, {
          params
        })),
        call(ApiClient.httpClient.get, 'profile/getavailablepccswithhostforuser', { params })
      ]);
      yield put({ type: actionTypes.LOAD_USER_PROFILE, data: { ...response.data, pccsForUser: pccsForUserResponse.data } });
    }
  } catch (error) {
    console.error(error);
  }
  yield put(hideLoader());
}

export function* watchGetFullProfileSaga() {
  yield takeLatest(GET_FULL_PROFILE.BASE, getFullProfileSaga);
}

export const saveHostEntry = {
  base: params => ({
    type: SAVE_HOST_ENTRY.BASE,
    url: 'profile/savehostentryforuserprofile',
    payload: {
      params
    }
  }),
  request: () => ({
    type: SAVE_HOST_ENTRY.REQUEST
  }),
  success: data => ({
    type: SAVE_HOST_ENTRY.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: SAVE_HOST_ENTRY.FAILURE,
    payload: {
      error
    }
  })
};

export function* saveHostEntrySaga(action) {
  yield put(showLoader());
  try {
    const { params } = action.payload;
    const response = yield call(() => fetchEntity(saveHostEntry, ApiClient.httpClient.post, action.url, params));

    yield put(showAlertAction(response.data.responseMessage));
    yield put(cleanGetHostMatchingId());
  } catch (error) {
    if (
      error.response
      && error.response.data
      && error.response.data.responseMessage
    ) {
      yield put(showAlertAction(error.response.data.responseMessage));
    } else {
      yield put(showAlertAction(error.message));
    }
  }
  yield put(hideLoader());
}

export function* watchHostInformationSaga() {
  yield takeLatest(SAVE_HOST_ENTRY.BASE, saveHostEntrySaga);
}

export const removeHostEntry = {
  base: params => ({
    type: REMOVE_HOST_ENTRY.BASE,
    url: 'profile/removehostentryforuserprofile',
    payload: {
      params
    }
  }),
  request: () => ({
    type: REMOVE_HOST_ENTRY.REQUEST
  }),
  success: data => ({
    type: REMOVE_HOST_ENTRY.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: REMOVE_HOST_ENTRY.FAILURE,
    payload: {
      error
    }
  })
};

export function* removeHostEntryReducerWatcher(action) {
  yield put(
    showConfirmationModal(
      'Are you sure you want to delete this host information?'
    )
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });

  if (accept) {
    yield put(showLoader());
    try {
      const data = action.payload.params;
      const response = yield call(() => fetchEntity(removeHostEntry, ApiClient.httpClient.delete, action.url, {
        data
      }));
      yield put(showAlertAction(response.data.responseMessage));
    } catch (error) {
      if (
        error.response
        && error.response.data
        && error.response.data.responseMessage
      ) {
        yield put(showAlertAction(error.response.data.responseMessage));
      } else {
        yield put(showAlertAction(error.message));
      }
    }
    yield put(hideLoader());
  }
}

export function* watchDeleteHostInformationSaga() {
  yield takeLatest(REMOVE_HOST_ENTRY.BASE, removeHostEntryReducerWatcher);
}

export default getFullProfileReducer;
