import React from 'react';
import PropTypes from 'prop-types';
import {
  TableEditRow
} from '@devexpress/dx-react-grid-bootstrap4';
import Select from 'react-select';
import {
  CELL_DROPDOWN_TYPE,
  CELL_MULTISELECT_TYPE
} from '../constants/cells';

const EditCell = (props) => {
  const editcell = props.editcell[props.column.name];
  if (editcell) {
    if (editcell.nonEditableCells && editcell.nonEditableCells.includes(props.value)) {
      return <td><span>{props.value}</span></td>;
    }
    switch (editcell.editCellTypeCell) {
      case CELL_DROPDOWN_TYPE:
        return (
          <td>
            <select
              className="select"
              value={props.value}
              onChange={e => props.onValueChange(e.target.value)}
              style={{ width: '100%' }}
              placeholder={editcell.placeholder || 'Select...'}
            >
              {editcell.editCellList.map(item => <option key={item.text} value={item.text}>{item.text}</option>)}
            </select>
          </td>
        );
      case CELL_MULTISELECT_TYPE:
        return (
          <td>
            <Select
              style={{ width: '100%' }}
              resetValue=""
              value={props.value}
              onChange={e => props.onValueChange(e.value)}
              placeholder={editcell.placeholder || 'Select...'}
              options={editcell.editCellList}
            />
          </td>
        );
      default: {
        if (editcell.placeholder) {
          return (
            <td>
              <input
                type="text"
                placeholder={editcell.placeholder}
                onChange={e => props.onValueChange(e.target.value)}
                className="form-control"
                style={{ width: '100%' }}
                value={props.value}
              />
            </td>
          );
        }
        return <TableEditRow.Cell style={{ width: '100%' }} {...props} />;
      }
    }
  }

  return <TableEditRow.Cell style={{ width: '100%' }} {...props} />;
};

EditCell.propTypes = {
  column: PropTypes.object,
  editcell: PropTypes.object,
  onValueChange: PropTypes.func,
  value: PropTypes.string
};

export default EditCell;
