export default (arr, index, values) => { // eslint-disable-line arrow-body-style
  return (index === 0 && arr.length === 1)
    ? [
      { ...arr[index], ...values }
    ]
    : [
      ...arr.slice(0, index),
      { ...arr[index], ...values },
      ...arr.slice(index + 1),
    ];
};
