import { put } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient.js';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

import { API_GET_ALL_FILTER_OPTIONS } from 'config/apiConfig';

export const GET_ALL_FILTER_OPTIONS = createFetchTypes('Marketplace/Administration/OrganizationDirectory/ManageAdmins/GET_ALL_FILTER_OPTIONS');

export const getAllFilterOptions = {
  base: (params) => ({
    type: GET_ALL_FILTER_OPTIONS.BASE,
    params
  }),
  request: () => ({
    type: GET_ALL_FILTER_OPTIONS.REQUEST
  }),
  success: (data) => ({
    type: GET_ALL_FILTER_OPTIONS.SUCCESS,
    payload: { data }
  }),
  failure: (error) => ({
    type: GET_ALL_FILTER_OPTIONS.FAILURE,
    payload: {
      error
    }
  })
};

const getAllFilterOptionsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_ALL_FILTER_OPTIONS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_ALL_FILTER_OPTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data
      };

    case GET_ALL_FILTER_OPTIONS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export function* getAllFilterOptionsReducerWatcher() {
  yield put(showLoader());
  try {
    const response = yield ApiClient.httpClient.get(API_GET_ALL_FILTER_OPTIONS);

    yield put(getAllFilterOptions.success(response.data));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(getAllFilterOptions.failure(error));
  }
  yield put(hideLoader());
}

export const getAllFilterOptionsSelector = state => state.administration.organizationDirectory.getAllFilterOptionsReducer.data;

export default getAllFilterOptionsReducer;
