import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AgencyDetailsPccs extends Component {
  render() {
    return (
      <>
        <span className="agency-details-title">
          Agency PCCs
        </span>
        <div className="agency-details-box space-top-sm">
          <div className="col-sm-5">
            { this.props.pccsString }
          </div>
        </div>
      </>
    );
  }
}

AgencyDetailsPccs.propTypes = {
  pccsString: PropTypes.string,
};

AgencyDetailsPccs.defaultProps = {
  pccsString: '',
};

export default AgencyDetailsPccs;
