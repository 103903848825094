export default class CarouselItem {
  constructor(componentClassName, imageFile, heading, description, navigationPath, buttonText) {
    this.componentClassName = componentClassName;
    this.imageFile = imageFile;
    this.heading = heading;
    this.description = description;
    this.navigationPath = navigationPath;
    this.buttonText = buttonText;
  }
}
