import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as utils from '../../../utils/Common.Utils';

/* eslint-disable max-len */
const InternalAppTerms = () => {
  const publicId = utils.getValueFromQueryString('', 'publicid');
  return (
    <>
      <h2>Terms &amp; Conditions</h2>
      <div className="terms-container">
        <h2 className="text-center">
          TRAVELPORT MARKETPLACE AGREEMENT
        </h2>
        <p>
          IMPORTANT – READ THIS TRAVELPORT MARKETPLACE AGREEMENT (&quot;
          <b>Agreement</b>
          &quot;) CAREFULLY BEFORE USING THIS SITE: Your use of the Travelport Marketplace and
          any associated software, applications, and other digital products (&quot;
          <b>Marketplace Apps</b>
          &quot;) provided by Travelport or an authorized third party developer
          (&quot;
          <b>Developer</b>
          &quot;), is subject to the terms and conditions of this Agreement, which include the provisions of the subscriber agreement between your company
          and Travelport (&quot;
          <b>Subscriber Agreement</b>
          &quot;), the Travelport Privacy Policy at
          {' '}
          {' '}
          <Link
            to="http://www.travelport.com/Privacy-Policy/Travelport-Websites"
            target="_blank"
            className="goOutLink"
          >
            http://www.travelport.com/Privacy-Policy/Travelport-Websites
          </Link>
          {' '}
          (&quot;
          <b>Travelport Privacy Policy</b>
          &quot;), and the
          Travelport Terms and Conditions at
          <Link
            to="http://www.travelport.com/Terms-and-Conditions"
            target="_blank"
            className="goOutLink"
          >
            http://www.travelport.com/Terms-and-Conditions
          </Link>
          {' '}
          (&quot;
          <b>Travelport Terms and Conditions</b>
          &quot;). Any conflict in the terms of these
          documents will be resolved in the following order of precedence:  Subscriber Agreement, this Agreement, Travelport Terms and Conditions, and Privacy Policy.
        </p>
        <p>
          You may use the Travelport Marketplace and purchase Marketplace Apps only if you are an authorized
          representative of your company, your company is a current subscriber to the Travelport GDS
          under a valid Subscriber Agreement with Travelport, and your company&quot;s account
          is in good standing.  For purposes of this Agreement, &quot;
          <b>you</b>
          &quot; refers collectively to you and your company.
        </p>
        <p>
          BY CLICKING THE &quot;ACCEPT&quot; BUTTON BELOW, YOU REPRESENT THAT YOU MEET THESE CRITERIA AND AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.
          IF YOU DO NOT MEET THESE CRITERIA OR YOU DO NOT SO AGREE, DO NOT PURCHASE, DOWNLOAD, INSTALL OR USE ANY OF THE MARKETPLACE APPS.  BY PURCHASING, DOWNLOADING,
          INSTALLING OR OTHERWISE USING ANY MARKETPLACE APPS, YOU AGREE TO PAY TRAVELPORT OR THE DEVELOPER, AS APPLICABLE, THE FEES SET FORTH ON THE TRAVELPORT
          MARKETPLACE WEBSITE OR OTHERWISE ADVISED TO YOU FOR THOSE MARKETPLACE APPS.
        </p>
        <ol>
          <li>
            <p>MARKETPLACE APP LICENSE</p>
            <ol>
              <li>
                <u>Grant of License</u>
                .  Provided that you are in material compliance with the terms and conditions of this Agreement, including but not limited to all
                payment obligations, Travelport grants to you a nonexclusive, nontransferable license to use the Marketplace Apps you select in the purchase process.
                Marketplace Apps that are owned by Travelport shall be considered &quot;Products and Services&quot; or &quot;Services&quot; and Marketplace Apps that
                are owned by Developers shall be considered &quot;Third Party Products&quot; as those terms are used in your Subscriber Agreement.
              </li>
              <li>
                <u>Restrictions on Use</u>
                .  You agree to use the Marketplace Apps only for your own business, including in conjunction with your affiliates.  Under no
                circumstances may you permit any third parties to use the Marketplace Apps.  Use of the Marketplace Apps may be subject to additional terms depending on
                the nature of the Marketplace App itself and subject to any Travelport or Developer specific product terms.
              </li>
              <li>
                <u>Modifications; Reverse Engineering</u>
                .  You agree that you will not directly or through a third party alter, maintain, enhance, disassemble, decompile,
                reverse engineer, create derivative works or otherwise modify any Marketplace App.
              </li>
            </ol>
          </li>
          <li>
            <p>PAYMENT</p>
            You agree to pay all fees and taxes applicable to the Marketplace Apps you purchase.  Unless otherwise stated, payment for Marketplace Apps will be made in
            accordance with your Subscriber Agreement.  Marketplace Apps may have a free trial period and if so, the free trial period will be described in the product
            description on the Travelport Marketplace Website. .  Following any trial period, all sales are final and non-refundable unless otherwise specified.  Travelport
            may offset amounts you owe under this Agreement from amounts Travelport may owe you under any agreement between you and Travelport.
          </li>
          <li>
            <p>SUPPORT</p>
            You agree that Travelport has no responsibility or liability with respect to your use of a Marketplace App or any content or functionality in the Marketplace
            App.  Travelport or a Developer may provide help desk support for various Marketplace Apps from time to time and you agree to pay the separate help desk fee,
            if applicable.  Any applicable fees and support information will be provided alongside product information on the Travelport Marketplace Website.  These fees
            and support information are hereby incorporated by reference into this Agreement.
          </li>
          <li>
            <p>GEOGRAPHIC RESTRICTIONS</p>
            Nothing in this Agreement permits you to access or use a Marketplace App where such access or use is prohibited by law or regulation.  Travelport or a Developer
            may restrict access to the Travelport Marketplace from time to time.  Usage of some Marketplace Apps may not limited to stated geographical areas.
          </li>
          <li>
            <p>INTELLECTUAL PROPERTY RIGHTS</p>
            <ol>
              <li>
                <u>Title</u>
                .  Marketplace Apps are protected by copyright and other intellectual property laws and treaties.  Unless otherwise expressly stated, Travelport,
                the Developers or their licensors own all title, copyright and other intellectual property rights in the Marketplace Apps.  No title to any Marketplace App is
                transferred to you.
              </li>
              <li>
                <u>Transfers</u>
                .  Under no circumstances may you sell, license, publish, display, distribute, or otherwise transfer to a third party any Marketplace App or
                any copy thereof, in whole or in part, without Travelport&quot;s prior written consent.
              </li>
            </ol>
          </li>
          <li>
            <p>DISCLAIMER OF WARRANTIES</p>
            THERE ARE NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, FOR ANY MARKETPLACE APP, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO TITLE OR INFRINGEMENT OF
            THIRD-PARTY RIGHTS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.  THE MARKETPLACE APPS, DOCUMENTATION AND OTHER FILES ARE PROVIDED &quot;AS IS.&quot;
            TRAVELPORT AND ITS DEVELOPERS DO NOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE MARKETPLACE APPS.  YOU AGREE THAT TRAVELPORT OR A DEVELOPER
            MAY MODIFY A MARKETPLACE APP AND THAT SUCH MODIFICATION MAY IMPACT YOUR ABILITY TO ACCESS OR USE THE MARKETPLACE APP.  TRAVELPORT MAY RELEASE NEW DESKTOP VERSIONS
            OF THE TRAVELPORT GDS THAT DO NOT SUPPORT MARKETPLACE APPS YOU HAVE DOWNLOADED.  Some jurisdictions do not allow the exclusion of implied warranties, so the above
            exclusions may not apply to you if prohibited by applicable law.
          </li>
          <li>
            <p>EXCLUSION OF LIABILITY/LIMITATION OF LIABILITY</p>
            IN NO EVENT WILL TRAVELPORT BE LIABLE TO YOU OR ANY THIRD PARTY FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER SIMILAR DAMAGES,
            INCLUDING LOST PROFITS, ARISING FROM THE USE OF OR INABILITY TO USE ANY MARKETPLACE APP, EVEN IF TRAVELPORT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            TRAVELPORT IS NOT RESPONSIBLE FOR ANY DEVELOPERS PROVIDING PRODUCTS OR SERVICES IN THE TRAVELPORT MARKETPLACE.  IN NO EVENT (OTHER THAN AS MAY BE REQUIRED BY
            APPLICABLE LAW) WILL TRAVELPORT&quot;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE MARKETPLACE APPS
            EXCEED THE LESSER OF (i) THE AMOUNT SPECIFIED IN YOUR SUBSCRIBER AGREEMENT, OR (ii) THE PURCHASE PRICE OF THE MARKETPLACE APP GIVING RISE TO THE CLAIM FOR LIABILITY.
          </li>
          <li>
            <p>GENERAL PROVISIONS</p>
            <ol>
              <li>
                <u>Modifications</u>
                .  Travelport may alter or revise the terms of this Agreement by giving you prior notice of the changes to be made.  If you do not agree
                to accept the changes, your sole remedy shall be to discontinue your use of the Travelport Marketplace and the Marketplace Apps.
              </li>
              <li>
                <u>Choice of Law and Location for Resolving Disputes</u>
                .  The choice of law and dispute resolution provisions in your Subscriber Agreement will control any
                disputes under this Agreement.
              </li>
              <li>
                <u>Termination</u>
                .  Unless otherwise terminated as set forth in this Agreement, this Agreement will terminate as of the termination of your Subscriber
                Agreement.  Travelport may terminate this Agreement if you fail to comply with the terms and conditions of this Agreement, including the provisions of your
                Subscriber Agreement, the Travelport Privacy Policy, and the Travelport Terms and Conditions.  Upon any such termination, Travelport may deny access to the
                Marketplace Apps and you must discontinue use of the Marketplace Apps.  Travelport may modify, suspend or discontinue the Travelport Marketplace, in whole
                or in part, at any time without notice.
              </li>
              <li>
                <u>Severability</u>
                .  Except as otherwise set forth in this Agreement, the provisions of this Agreement are severable, and if any one or more such provisions
                shall be determined to be invalid, illegal or unenforceable, in whole or in part, the validity, legality and enforceability of any of the remaining provisions
                or portions thereof shall not in any way be affected and shall nevertheless be binding between the parties.  Any such invalid, illegal or unenforceable provision
                or portion of such provision shall be changed and interpreted so as to best accomplish the objectives of such provision or portion thereof within the limits of
                applicable law.
              </li>
              <li>
                <u>Waiver</u>
                .  Any waiver, either expressed or implied, by either party of any default by the other in the observance and performance of any of the conditions
                and/or covenants of duties set forth in this Agreement shall not constitute or be construed as a waiver of any subsequent or other default.
              </li>
              <li>
                <u>Read and Understood</u>
                .  You hereby acknowledge that you have read and understand this Agreement and agree to be bound by its terms.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="row text-center">
        <Link id="termsBackButton" to={`/application/review?publicId=${publicId}`} className="blue-button">&lt; Back</Link>
        <Link
          id="termsDoNotAgreeButton"
          gtm-event-label="Solution:Test / Developer:Travelport"
          to={`/application/review?publicId=${publicId}`}
          className="blue-button gtm-do-not-agree-button"
        >
          Do Not Agree
        </Link>
        <a
          id="termsAgreeButton"
          gtm-event-label="Solution:Test / Developer:Travelport"
          href="/Account/MyProfile"
          className="green-button gtm-agree-button"
        >
          Agree to Terms &amp; Conditions
        </a>
      </div>
    </>
  );
};

InternalAppTerms.propTypes = {
  publicId: PropTypes.string
};

export default InternalAppTerms;
