import { createSelector } from 'reselect';

export const getVisibilitySelector = state => state.application.audienceAccess;
const accessCategories = state => state.application.audienceAccess.accessCategories;
const productAccessList = state => state.application.audienceAccess.productAccessList && state.application.audienceAccess.productAccessList.pagedCollection;
const userNames = state => state.application.audienceAccess.userNames;
const orgNames = state => state.application.audienceAccess.orgNames;
const pccOrg = state => state.application.audienceAccess.pccOrg;
const pcc = state => state.application.audienceAccess.pcc;
export const totalPagesSelector = state => state.application.audienceAccess.productAccessList
  && state.application.audienceAccess.productAccessList.totalPages;

export const productAccessListSelector = createSelector(
  [productAccessList],
  (items) => items && items.map(item => ({
    id: item.id,
    accessCategoryValue: item.accessCategory.value,
    accessCategory: item.accessCategory.text,
    categoryValueOne: item.categoryValueOne,
    categoryValueTwo: item.categoryValueTwo,
  }))
);

export const getAccessCategorySelectorForApplication = createSelector(
  [accessCategories],
  (items) => items && items.map(item => ({
    label: item.text,
    value: item.value
  }))
);

export const getPccOrgNamesSelector = createSelector(
  [pccOrg],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getPccListSelector = createSelector(
  [pcc],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getUserNamesSelector = createSelector(
  [userNames],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getOrgNamesSelector = createSelector(
  [orgNames],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);
