import {
  takeLatest, put, select, call, all, race, take
} from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { getAllCountries } from 'reducers/modules/countries';
import { getAllRegions } from 'reducers/modules/regions';
import ApiClient from 'Services/ApiClient';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal,
} from 'reducers/popUps/confirmationModalReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import {
  INIT_AUDIENCE_PAGE,
  GET_USER_NAMES,
  GET_PRODUCT_ACCESS,
  SAVE_AUDIENCE_ACCESS,
  GLOBAL_VISIBILITY,
  VISIBILITY_NON_PARTICIPANTS,
  VISIBILITY_EMPLOYEES,
  DELETE_AUDIENCE_ACCESS,
  GET_AUDIENCE,
  getProductAccess,
  getUserNames,
  saveAudienceAccess,
  changeGlobalAssignment,
  changeVisibilityNonParticipants,
  changeVisibilityTPEmployees,
  deleteAudienceAccess,
  initAudiencePage,
  getAudience,
} from './actions';
import { publicIdSelector, organizationIdSelector, isInternalPPCP } from '../selectors';
import { getAccessCategorySelectorForApplication } from './selectors';
import { updateFormApplication } from '../applicationReducer';
import { requestProductAccess } from '../../Administration/ProductAccess/productAccess';

const DELETE_CONFIRM_MSG = 'Are you sure you want to delete the entry?';

const countriesSelector = state => state.countries;
const regionsSelector = state => state.regions;

const typeaheadCalls = (state, action) => {
  switch (action.type) {
    case GET_USER_NAMES.SUCCESS:
      return {
        ...state,
        userNames: action.payload
      };
    case GET_USER_NAMES.FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
};

const audienceReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_PRODUCT_ACCESS.REQUEST:
    case SAVE_AUDIENCE_ACCESS.REQUEST:
    case VISIBILITY_EMPLOYEES.REQUEST:
    case VISIBILITY_NON_PARTICIPANTS.REQUEST:
    case GLOBAL_VISIBILITY.REQUEST:
    case DELETE_AUDIENCE_ACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AUDIENCE.SUCCESS:
      return {
        ...state,
        audience: action.payload
      };
    case GET_PRODUCT_ACCESS.SUCCESS:
      return {
        ...state,
        ...action.payload.productAccess,
        loading: false,
      };
    case VISIBILITY_EMPLOYEES.SUCCESS:
      return {
        ...state,
        loading: false,
        isVisibleToTPEmployees: !state.isVisibleToTPEmployees
      };
    case VISIBILITY_NON_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        loading: false,
        isVisibleToNonParticipants: !state.isVisibleToNonParticipants
      };
    case GET_PRODUCT_ACCESS.FAILURE:
    case SAVE_AUDIENCE_ACCESS.FAILURE:
    case GLOBAL_VISIBILITY.FAILURE:
    case VISIBILITY_EMPLOYEES.FAILURE:
    case VISIBILITY_NON_PARTICIPANTS.FAILURE:
    case DELETE_AUDIENCE_ACCESS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case GLOBAL_VISIBILITY.SUCCESS:
      return {
        ...state,
        isGlobal: !state.isGlobal,
        loading: false,
      };
    case DELETE_AUDIENCE_ACCESS.SUCCESS:
      return {
        ...state,
        productAccessList: {
          ...state.productAccessList,
          pagedCollection: state.productAccessList.pagedCollection.filter(item => item.id !== action.payload.id)
        },
        loading: false,
      };
    default:
      return typeaheadCalls(state, action);
  }
};

const getQueryParamsProductAccess = (data) => ({
  params: {
    appPublicId: data.appPublicId,
    filter: '',
    sortOnColumn: '',
    sortAsc: false,
    currentPage: data.currentPage || 0,
    isPos: false
  }
});

const getQueryParamsForPageLoad = (data) => ({
  params: {
    publicId: data.appPublicId,
    organizationId: data.organizationId,
    previewAfterSave: data.previewAfterSave || false
  }
});

function* initAudiencePageSaga({ data }) {
  try {
    yield put(getProductAccess.request());

    const countriesList = yield select(countriesSelector);
    const regionsList = yield select(regionsSelector);

    if (countriesList.length === 0 || regionsList.length === 0) {
      const [audiencePageInfo, countries, regions, productAccess] = yield all([
        call(() => ApiClient.httpClient.get('application/getaudience', getQueryParamsForPageLoad(data))),
        call(() => ApiClient.httpClient.get(getAllCountries.base().url)),
        call(() => ApiClient.httpClient.post(getAllRegions.base().url)),
        call(() => ApiClient.httpClient.get(getProductAccess.base().url, getQueryParamsProductAccess(data))),
      ]);

      yield all([
        put(getProductAccess.success({ productAccess: productAccess.data })),
        put(getAllRegions.success(regions.data)),
        put(getAllCountries.success(countries.data)),
        put(getAudience.success(audiencePageInfo.data.model)),
        put(updateFormApplication(audiencePageInfo.data.model))
      ]);
    } else {
      const [audiencePageInfo, productAccess] = yield all([
        call(() => ApiClient.httpClient.get('application/getaudience', getQueryParamsForPageLoad(data))),
        call(() => ApiClient.httpClient.get(getProductAccess.base().url, getQueryParamsProductAccess(data))),
      ]);
      yield all([
        put(getProductAccess.success({ productAccess: productAccess.data })),
        put(getAudience.success(audiencePageInfo.data.model)),
        put(updateFormApplication(audiencePageInfo.data.model))
      ]);
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getUserNamesSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getUserNames, ApiClient.httpClient.get, url, { params: { userName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* saveAudienceAccessSaga({ url, data }) {
  yield put(showLoader());
  try {
    const appPublicId = yield select(publicIdSelector);
    const accessCategoryItems = yield select(getAccessCategorySelectorForApplication);
    const request = requestProductAccess({ appPublicId, accessCategoryItems, data });
    const result = yield ApiClient.httpClient.post(url, { ...request });
    const rows = (data.addedRows && data.addedRows[0]) || data.updatedRows;
    yield put(saveAudienceAccess.success({
      ...rows,
      ...result.data
    }));
    yield put(showAlertAction('Product access setting has been successfully added.'));
    yield put((initAudiencePage({ appPublicId: data.appPublicId, organizationId: data.organizationId, currentPage: data.currentPage || 0 })));
    data.onSuccess();
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    data.onSuccess();
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* deleteAudienceSaga({ url, data }) {
  try {
    yield put(showConfirmationModal(DELETE_CONFIRM_MSG, ''));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      deleteAudienceAccess.request();
      yield put(showLoader());
      const result = yield ApiClient.httpClient.delete(url, { params: { productAccessId: data.row.id } });
      yield put(deleteAudienceAccess.success({
        id: data.row.id,
        message: result.data
      }));
      yield put((initAudiencePage({ appPublicId: data.appPublicId, organizationId: data.organizationId, currentPage: data.currentPage || 0 })));
      yield put(showAlertAction('Product access setting has been successfully deleted.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

const isGlobalSelector = state => state.application.audienceAccess.isGlobal;
const visibilityNonParticipantsSelector = state => state.application.audienceAccess.isVisibleToNonParticipants;
const visibilityEmployeesSelector = state => state.application.audienceAccess.isVisibleToTPEmployees;
function* globalVisibilitySaga({ url }) {
  try {
    const appPublicId = yield select(publicIdSelector);
    const organizationId = yield select(organizationIdSelector);
    const data = { appPublicId };
    yield call(() => fetchEntity(changeGlobalAssignment, ApiClient.httpClient.post, url, { ...data }));
    yield put((initAudiencePage({ appPublicId, organizationId })));
    const isGlobal = yield select(isGlobalSelector);
    const isInternal = yield select(isInternalPPCP);
    if (isGlobal) {
      yield put(showAlertAction(`${isInternal ? 'Agency wide' : 'Global'} Assignment has been successfully done`));
    } else {
      yield put(showAlertAction(`${isInternal ? 'Agency wide' : 'Global'} Assignment has been successfully removed`));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* visibilityNonParticipantsSaga({ url }) {
  try {
    const appPublicId = yield select(publicIdSelector);
    const data = { appPublicId };
    yield call(() => fetchEntity(changeVisibilityNonParticipants, ApiClient.httpClient.post, url, { ...data }));
    const visibilityNonParticipants = yield select(visibilityNonParticipantsSelector);
    if (visibilityNonParticipants) {
      yield put(showAlertAction('The product page is now viewable to non-list members'));
    } else {
      yield put(showAlertAction('The product page is now not viewable to non-list members'));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* visibilityEmployeesSaga({ url }) {
  try {
    const appPublicId = yield select(publicIdSelector);
    const data = { appPublicId };
    yield call(() => fetchEntity(changeVisibilityTPEmployees, ApiClient.httpClient.post, url, { ...data }));
    const visibilityEmployees = yield select(visibilityEmployeesSelector);
    if (visibilityEmployees) {
      yield put(showAlertAction('The product page is now viewable to TP Employees'));
    } else {
      yield put(showAlertAction('The product page is now not viewable to TP Employees'));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* watchGetUserNamesSaga() {
  yield takeLatest(GET_USER_NAMES.BASE, getUserNamesSaga);
}

export function* watchInitAudiencePageSaga() {
  yield takeLatest(INIT_AUDIENCE_PAGE, initAudiencePageSaga);
}

export function* watchDeleteAccessCategorySaga() {
  yield takeLatest(DELETE_AUDIENCE_ACCESS.BASE, deleteAudienceSaga);
}

export function* watchSaveAudiencePageSaga() {
  yield takeLatest(SAVE_AUDIENCE_ACCESS.BASE, saveAudienceAccessSaga);
}

export function* watchGlobalVisibilitySaga() {
  yield takeLatest(GLOBAL_VISIBILITY.BASE, globalVisibilitySaga);
}

export function* watchVisibilityNonParticipantsSaga() {
  yield takeLatest(VISIBILITY_NON_PARTICIPANTS.BASE, visibilityNonParticipantsSaga);
}

export function* watchVisibilityEmployeesSaga() {
  yield takeLatest(VISIBILITY_EMPLOYEES.BASE, visibilityEmployeesSaga);
}

export default audienceReducer;
