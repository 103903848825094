/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  reduxForm, Field, formValueSelector, change, SubmissionError
} from 'redux-form';
import { Link } from 'react-router-dom';
import {
  fetchRecommendations, addSelectedBuyers, saveRecommendations, changeSelectedBuyers
} from '../../../reducers/Product/Recommend/recommendReducer';
import { recommendationSelectors, navigate } from '../../../reducers/Product/selectors';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import RecommendationSuccess from './RecommendationSuccess';
import * as SeoHelper from '../../../utils/SeoHelper';

const emailRegex = /\S+@\S+\.\S+/;
const validEmail = (value) => {
  if (!emailRegex.test(value)) {
    throw new SubmissionError('Invalid');
  }
};

const EmailError = ({ show }) => (
  show ? (
    <div className="row" id="recommend-email-notcorrect">
      Email Address invalid.
      <br />
      Please reenter the correct email address.
    </div>
  ) : null
);

EmailError.propTypes = {
  show: PropTypes.bool
};

class Recommend extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEmailError: false
    };

    this.addToList = this.addToList.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchRecommendations({ publicId: match.params.id });
  }

  changeSelectedBuyers = (email, checked, index) => {
    const emailList = this.props.recommendations.selectedBuyers;
    emailList[index].checked = checked;
    this.props.changeSelectedBuyers(emailList);
  }

  userCommentsHandler = (e) => {
    this.props.addSelectedBuyers({ userComments: e.target.value });
  }

  addToList = async (event) => {
    event.preventDefault();
    this.setState({ showEmailError: false });

    try {
      validEmail(this.props.email);
    } catch (e) {
      this.setState({ showEmailError: true });
      return;
    }
    const emailList = this.props.recommendations.selectedBuyers.concat([{
      email: this.props.email,
      checked: true
    }]);
    this.props.addSelectedBuyers({ selectedBuyers: emailList });
    this.props.clearEmail();
  }

  sendEmail = async (e) => {
    await this.addToList(e);
    if (!this.state.showEmailError || this.props.recommendations.selectedBuyers.length > 0) this.props.saveRecommendations();
  }

  filterItems(buyersList, listItem) {
    return buyersList.filter(item => item.checked === listItem);
  }

  render() {
    const { recommendations } = this.props;
    if (recommendations === undefined) {
      return <LoaderComponent show />;
    }
    if (this.props.navigate) {
      return <RecommendationSuccess />;
    }
    return (
      <>
        <h2>
          Recommend
          {recommendations.applicationName}
        </h2>
        <div className="container">
          <form onSubmit={this.sendEmail}>
            <h4>Email Your Contacts</h4>
            <div id="buyers-container" className="row">
              {
                recommendations.selectedBuyers.map((v, i) => (
                  <React.Fragment key={i.toString()}>
                    <SimplePrettyCheckBox
                      displayText={v.email}
                      value={v.checked}
                      onChange={(checked) => this.changeSelectedBuyers(v, checked, i)}
                    />
                    <br />
                  </React.Fragment>
                ))
              }
            </div>
            <h4 className="required-field-star">Add Email</h4>
            <div className="row">
              <div className="horizontal-input-group col-sm-4">
                <div>
                  <Field type="email" id="add-email-input" name="email" component="input" className="text-input" placeholder="Add additional email" />
                </div>
                <Link id="add-email-submit" className="cancel-button space-left" to="#" onClick={this.addToList}>Add</Link>
              </div>
            </div>
            <EmailError show={this.state.showEmailError} />
            <h4>Message</h4>
            <div className="row">
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="userComments"
                  onChange={this.userCommentsHandler}
                  component="textarea"
                  className="textarea space-bottom"
                  placeholder="Add comments here (optional)"
                />
              </div>
            </div>
            <div>
              <input type="submit" id="not-auth-inquiry-button" onClick={this.sendEmail} className="action-button" value="Send Email" />
              <Link to={`/Product/${SeoHelper.SEOFriendly(recommendations.applicationName)}`} className="cancel-button space-left-sm">Cancel</Link>
            </div>
          </form>
        </div>
      </>
    );
  }
}

Recommend.propTypes = {
  match: PropTypes.object,
  fetchRecommendations: PropTypes.func,
  recommendations: PropTypes.object,
  addSelectedBuyers: PropTypes.func,
  email: PropTypes.string,
  clearEmail: PropTypes.func,
  userComments: PropTypes.string,
  saveRecommendations: PropTypes.func,
  navigate: PropTypes.bool,
  applicationName: PropTypes.string,
  changeSelectedBuyers: PropTypes.func
};

const selector = formValueSelector('recommend');
const mapStateToProps = (state) => ({
  recommendations: recommendationSelectors(state),
  email: selector(state, 'email'),
  userComments: selector(state, 'userComments'),
  navigate: navigate(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecommendations: (args) => dispatch(fetchRecommendations.base(args)),
  addSelectedBuyers: (args) => dispatch(addSelectedBuyers.base(args)),
  saveRecommendations: () => dispatch(saveRecommendations.base()),
  clearEmail: () => dispatch(change('recommend', 'email', '')),
  changeSelectedBuyers: (args) => dispatch(changeSelectedBuyers.base(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'recommend',
  })(Recommend)
);
