import * as actionTypes from '../actions/actionType';

const initialOrganizationState = {
  loading: true,
  orgDetails: null,
  error: null
};

const organizationReducer = (state = initialOrganizationState, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION:
      return {
        ...state,
        loading: true,
        orgDetails: null,
        error: null
      };
    case actionTypes.LOAD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        orgDetails: action.data,
        error: null
      };
    case actionTypes.LOAD_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        orgDetails: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default organizationReducer;
