import React from 'react';
import PropTypes from 'prop-types';

const SpecificTermsPresentation = (props) => (
  <>
    <h2>Terms & Conditions (2 of 2)</h2>
    <div className="terms-container">
      <p className="text-center"><img alt={`${props.applicationName} logo`} src={props.logoUrl} /></p>
      <h2 className="text-center">
        {props.applicationName}
        {' '}
        END-USER LICENSE AGREEMENT
      </h2>
      <pre className="terms-pre">
        {props.specificTerms}
      </pre>
    </div>
  </>
);

SpecificTermsPresentation.propTypes = {
  applicationName: PropTypes.string,
  logoUrl: PropTypes.string,
  specificTerms: PropTypes.string
};

export default SpecificTermsPresentation;
