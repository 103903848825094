import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Tabs } from '@atlas-design-system/react';
import {
  updatePublishStatus,
  fetchInHouseSolutionSet,
  fetchOrderedSolutionSet,
  fetchOrderedThirdPartySolutionSet,
  updateStatus,
  deleteDraft
} from 'reducers/modules/Profile/Solution/mySolution';
import SolutionTile from './SolutionTile';
import './MySolutions.less';
import SolutionHeader from './SolutionHeader';
import { getOrgId, getRoleId, getOrgGuid } from '../../../reducers/modules/Profile/selectors';
import { directDownloadProduct } from '../../../reducers/Product/Product/productReducers';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import * as utils from '../../../utils/Common.Utils';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

class MySolutions extends Component {
  constructor(props) {
    super(props);
    const selectedTab = utils.getValueFromQueryString(
      props.location,
      'selectedTab'
    );
    this.state = {
      selectedTab: selectedTab ? selectedTab.toLowerCase() : ''
    };
  }

  componentDidMount() {
    if (this.props.match.params.id || this.props.orgId) {
      this.fetchInHouse();
      this.fetchOrderedTP();
      this.fetchOrderedThirdParty();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgId && this.props.orgId !== prevProps.orgId) {
      this.fetchInHouse();
      this.fetchOrderedTP();
      this.fetchOrderedThirdParty();
    }
  }

  fetchInHouse = () => {
    const orgId = this.props.orgId || this.props.match.params.id;
    this.props.fetchInHouse({ orgId });
  };

  fetchOrderedTP = () => {
    const orgId = this.props.orgId || this.props.match.params.id;
    this.props.fetchOrderedTP({ orgId });
  };

  fetchOrderedThirdParty = () => {
    const orgId = this.props.orgId || this.props.match.params.id;
    this.props.fetchOrderedThirdParty({ orgId });
  };

  render() {
    if (this.props.loading) return <LoaderComponent show />;
    let ownProfile = true;
    if (
      this.props.match.path === '/Profile/AgencyProfile/:id'
      || this.props.match.path === '/Profile/UserProfile/:id'
    ) {
      ownProfile = false;
    }
    return (
      <>
        <Tabs id="gtm-myprofile-subnav-solutionTabs" defaultActiveKey="0">
          <Tabs.Tab eventKey="inHouseSolutions" title={this.props.orgId !== 1 ? 'Our solutions' : 'Travelport solutions'}>
            <div id="solutions">
              <div className="solutions-header">
                <SolutionHeader
                  solutionsLength={this.props.inhouseSolutions.length}
                  ownProfile={ownProfile}
                  role={this.props.profileRole}
                  orgGuid={this.props.orgGuid}
                  emulatedOrgId={this.props.emulatedOrgId}
                  orgId={this.props.orgId}
                  profile={this.props.profile}
                  showAddSolution
                />
              </div>
              <div className="solutions-body">
                {this.props.inhouseSolutions.map((solution, id) => (
                  <React.Fragment key={id.toString()}>
                    <SolutionTile
                      {...solution}
                      updatePublishStatus={this.props.updatePublishStatus}
                      fetch={this.fetchInHouse}
                      directDownloadProduct={
                        this.props.directDownloadProduct
                      }
                      roleId={this.props.roleId}
                      updateStatusAction={this.props.updateStatusAction}
                      deleteDraftAction={this.props.deleteDraftAction}
                      selectedTab={this.state.selectedTab}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Tabs.Tab>
          {this.props.orgId !== 1 ? (
            <Tabs.Tab eventKey="orderedTPSolutions" title="Travelport solutions">
              <div id="solutions">
                <div className="solutions-header">
                  <SolutionHeader
                    solutionsLength={this.props.orderedTPSolutions.length}
                    ownProfile={ownProfile}
                    role={this.props.profileRole}
                    emulatedOrgId={this.props.emulatedOrgId}
                    orgGuid={this.props.orgGuid}
                    orgId={this.props.orgId}
                    showAddSolution={false}
                    profile={this.props.profile}
                  />
                </div>
                <div className="solutions-body">
                  {this.props.orderedTPSolutions.map((solution, id) => (
                    <React.Fragment key={id.toString()}>
                      <SolutionTile
                        {...solution}
                        updatePublishStatus={this.props.updatePublishStatus}
                        fetch={this.fetchOrderedTP}
                        directDownloadProduct={
                          this.props.directDownloadProduct
                        }
                        roleId={this.props.roleId}
                        updateStatusAction={this.props.updateStatusAction}
                        deleteDraftAction={this.props.deleteDraftAction}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </Tabs.Tab>
          ) : <></>}
          <Tabs.Tab eventKey="orderedThirtPartySolutions" title="Third-party solutions">
            <div id="solutions">
              <div className="solutions-header">
                <SolutionHeader
                  solutionsLength={this.props.orderedThirdPartySolutions.length}
                  ownProfile={ownProfile}
                  role={this.props.profileRole}
                  orgGuid={this.props.orgGuid}
                  emulatedOrgId={this.props.emulatedOrgId}
                  orgId={this.props.orgId}
                  profile={this.props.profile}
                  showAddSolution={false}
                />
              </div>
              <div className="solutions-body">
                {this.props.orderedThirdPartySolutions.map((solution, id) => (
                  <React.Fragment key={id.toString()}>
                    <SolutionTile
                      {...solution}
                      updatePublishStatus={this.props.updatePublishStatus}
                      fetch={this.fetchOrderedThirdParty}
                      directDownloadProduct={
                        this.props.directDownloadProduct
                      }
                      roleId={this.props.roleId}
                      updateStatusAction={this.props.updateStatusAction}
                      deleteDraftAction={this.props.deleteDraftAction}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Tabs.Tab>
        </Tabs>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchInHouse: bindActionCreators(fetchInHouseSolutionSet, dispatch),
  fetchOrderedTP: bindActionCreators(fetchOrderedSolutionSet, dispatch),
  fetchOrderedThirdParty: bindActionCreators(fetchOrderedThirdPartySolutionSet, dispatch),
  updatePublishStatus: bindActionCreators(updatePublishStatus, dispatch),
  directDownloadProduct: id => dispatch(directDownloadProduct(id)),
  updateStatusAction: bindActionCreators(updateStatus, dispatch),
  deleteDraftAction: id => dispatch(deleteDraft(id))
});
const mapStateToProps = state => ({
  orgId: getOrgId(state),
  inhouseSolutions: state.solution.inhouse.data,
  orgGuid: getOrgGuid(state),
  orderedTPSolutions: state.solution.ordered.data,
  orderedThirdPartySolutions: state.solution.thirdParty.data,
  roleId: getRoleId(state),
  loading: state.solution.inhouse.loading,
  profileRole: state.oidc.user.profile.role,
  emulatedOrgId: state.profile.accessingUserProfileReducer.orgId,
  profile: state.profile
});

MySolutions.propTypes = {
  fetchInHouse: PropTypes.func,
  fetchOrderedTP: PropTypes.func,
  fetchOrderedThirdParty: PropTypes.func,
  updatePublishStatus: PropTypes.func,
  inhouseSolutions: PropTypes.array,
  orderedTPSolutions: PropTypes.array,
  orderedThirdPartySolutions: PropTypes.array,
  orgGuid: PropTypes.string,
  orgId: PropTypes.number,
  directDownloadProduct: PropTypes.func,
  roleId: PropTypes.number,
  updateStatusAction: PropTypes.func,
  match: PropTypes.object,
  deleteDraftAction: PropTypes.func,
  loading: PropTypes.bool,
  profileRole: PropTypes.string,
  emulatedOrgId: PropTypes.number,
  profile: PropTypes.object
};

MySolutions.defaultProps = {
  inhouseSolutions: [],
  orderedTPSolutions: [],
  orderedThirdPartySolutions: []
};
MySolutions.propTypes = {
  location: PropTypes.object,
  setView: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(MySolutions)
);
