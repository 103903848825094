/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLicenseHistory } from '../../../../reducers/modules/Profile/Solution/mySolution';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

class IntegrationStatusHistoryModel extends PureComponent {
  componentDidMount() {
    const { getLicenseHistoryAction, licenseId } = this.props;
    getLicenseHistoryAction({
      licId: licenseId
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  displayStatusHistory() {
    const { licenseHistory } = this.props;
    let cont = 0;
    const statusHistory = licenseHistory.data.map(c => (
      <React.Fragment key={cont += 1}>
        <p>{`${c}`}</p>
      </React.Fragment>
    ));
    return statusHistory || null;
  }

  render() {
    const {
      showModal,
      licenseHistory
    } = this.props;
    if (!licenseHistory && !licenseHistory.data) {
      return (
        <LoaderComponent show />
      );
    }
    return (
      <Modal
        id="sbModal"
        title="Status History"
        hidden={!showModal}
        size="small"
        onDismiss={this.cancel}
      >
        <div className="modal-custom-text space-top-double">
          {licenseHistory.data && this.displayStatusHistory()}
        </div>
        <div className="text-center space-top-double">
          <button type="button" className="action-button no-save" onClick={this.cancel}>CLOSE WINDOWS</button>
        </div>
      </Modal>
    );
  }
}

IntegrationStatusHistoryModel.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  licenseId: PropTypes.number,
  getLicenseHistoryAction: PropTypes.func,
  licenseHistory: PropTypes.object,
};

IntegrationStatusHistoryModel.defaultProps = {
  licenseId: null,
};

const mapStateToProps = state => ({
  loading: state.solution.loading,
  error: state.solution.error,
  licenseHistory: state.solution.licenseHistory,
});

const mapDispatchToProps = dispatch => ({
  getLicenseHistoryAction: bindActionCreators(getLicenseHistory, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationStatusHistoryModel);
