import { combineReducers } from 'redux';
import productReviews from './ProductReviews/productReviews';
import userLoginReport from './UserLoginReport/userLoginReportReducer';
import userFeedbackReport from './UserFeedbackReport/userFeedbackReportReducer';
import devStatsReport from './DevStatsReport/devStatsReportReducer';

export {
  fetchProductReviews,
  deleteProductReviews,
  productReviewReportMiddleware,
  // productReviewDownloadExcel,
} from './ProductReviews/productReviews';
export {
  exportToExcelMiddleware
} from './ExportToExcel/ExportToExcel';

export {
  fetchUserLoginReport,
  userLoginReportMiddleware,
  // userLoginReportDownloadExcel
} from './UserLoginReport/userLoginReportReducer';

export {
  fetchDevStatsReport,
  devStatsReportMiddleware
} from './DevStatsReport/devStatsReportReducer';

export {
  fetchUserFeedbackReport,
  userFeedbackReportMiddleware,
  // userFeedbackReportDownloadExcel
} from './UserFeedbackReport/userFeedbackReportReducer';

const reports = combineReducers({
  productReviews,
  userLoginReport,
  userFeedbackReport,
  devStatsReport
});

export default reports;
