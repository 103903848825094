import * as actionType from '../actionType';

export const productRatingLoad = () => ({
  type: actionType.LOAD_APP_RATING
});

export const productRatingLoadSuccess = (data) => ({
  type: actionType.LOAD_APP_RATING_SUCCESS,
  data
});

export const productRatingLoadFailure = (error) => ({
  type: actionType.LOAD_APP_RATING_FAILURE,
  error
});

export const productReviewsLoad = () => ({
  type: actionType.LOAD_APP_REVIEWS
});

export const productReviewsLoadSuccess = (data) => ({
  type: actionType.LOAD_APP_REVIEWS_SUCCESS,
  data
});

export const productReviewsLoadFailure = (error) => ({
  type: actionType.LOAD_APP_REVIEWS_FAILURE,
  error
});

export const saveProductReview = () => ({
  type: actionType.SAVE_PRODUCT_REVIEW
});

export const saveProductReviewSuccess = () => ({
  type: actionType.SAVE_PRODUCT_REVIEW_SUCCESS
});

export const saveProductReviewFailure = (error) => ({
  type: actionType.SAVE_PRODUCT_REVIEW_FAILURE,
  error
});

export const deleteProductReview = () => ({
  type: actionType.DELETE_PRODUCT_REVIEW
});

export const deleteProductReviewSuccess = () => ({
  type: actionType.DELETE_PRODUCT_REVIEW_SUCCESS
});

export const deleteProductReviewFailure = (error) => ({
  type: actionType.DELETE_PRODUCT_REVIEW_FAILURE,
  error
});
