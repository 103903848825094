import {
  put, select, call, all
} from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import licenseTypes from 'constants/licenseTypes';
import {
  licenseIdSelector,
  orgIdSelector,
  pccsSelector,
  licenseInfoSelector
} from '../selector';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { updateAssignedSeatSiteLicenses } from '../LicenseInformation/licenseInformation';

export const GET_ASSIGNED_PCCS = createFetchTypes('Marketplace/admin/licenses/pccs/getAssignedpccs');
export const GET_UNASSIGNED_PCCS = createFetchTypes('Marketplace/admin/licenses/pccs/getUnassignedpccs');
export const SELECT_ASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/selectedAssignedPccs';
export const SELECT_UNASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/selectedUnassignedPccs';
export const UNSELECT_ASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/unselectedAssignedPccs';
export const UNSELECT_UNASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/unselectedUnassignedPccs';
export const TOGGLE_ALL_SELECT_ASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/toggleAllSelectAssignedPccs';
export const TOGGLE_ALL_SELECT_UNASSIGNED_PCCS = 'Marketplace/admin/licenses/pccs/toggleAllSelectUnassignedPccs';
export const ADD_PCC_ASSIGNMENTS = createFetchTypes('Marketplace/admin/licenses/pccs/addPccAssignments');
export const REMOVE_PCC_ASSIGNMENTS = createFetchTypes('Marketplace/admin/licenses/pccs/removePccAssignments');

const getpccsAfterSelect = (pccs, entityId) => (
  pccs.map((pcc) => {
    if (pcc.id === entityId) {
      const selectedPcc = pcc;
      selectedPcc.isSelected = true;
      return selectedPcc;
    }
    return pcc;
  })
);

const getpccsAfterSelectAll = (pccs) => (
  pccs.map((pcc) => {
    const selectedPcc = pcc;
    selectedPcc.isSelected = true;
    return selectedPcc;
  })
);

const getpccsAfterUnselect = (pccs, entityId) => (
  pccs.map((pcc) => {
    if (pcc.id === entityId) {
      const unselectedPcc = pcc;
      unselectedPcc.isSelected = false;
      return unselectedPcc;
    }
    return pcc;
  })
);

const getpccsAfterUnselectAll = (pccs) => (
  pccs.map((pcc) => {
    const unselectedPcc = pcc;
    unselectedPcc.isSelected = false;
    return unselectedPcc;
  })
);

const intialState = {
  assignedPccs: [],
  unassignedPccs: [],
};

const pccs = (state = intialState, action = {}) => {
  switch (action.type) {
    case GET_ASSIGNED_PCCS.REQUEST:
    case GET_UNASSIGNED_PCCS.REQUEST:
    case ADD_PCC_ASSIGNMENTS.REQUEST:
    case REMOVE_PCC_ASSIGNMENTS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ASSIGNED_PCCS.SUCCESS:
      return {
        ...state,
        loading: false,
        assignedPccs: action.data
      };
    case GET_UNASSIGNED_PCCS.SUCCESS:
      return {
        ...state,
        loading: false,
        unassignedPccs: action.data
      };
    case GET_ASSIGNED_PCCS.FAILURE:
    case GET_UNASSIGNED_PCCS.FAILURE:
    case ADD_PCC_ASSIGNMENTS.FAILURE:
    case REMOVE_PCC_ASSIGNMENTS.FAILURE:
    case ADD_PCC_ASSIGNMENTS.SUCCESS:
    case REMOVE_PCC_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SELECT_ASSIGNED_PCCS:
      return {
        ...state,
        assignedPccs: getpccsAfterSelect(state.assignedPccs, action.entityId)
      };
    case SELECT_UNASSIGNED_PCCS:
      return {
        ...state,
        unassignedPccs: getpccsAfterSelect(state.unassignedPccs, action.entityId)
      };
    case UNSELECT_ASSIGNED_PCCS:
      return {
        ...state,
        assignedPccs: getpccsAfterUnselect(state.assignedPccs, action.entityId)
      };
    case UNSELECT_UNASSIGNED_PCCS:
      return {
        ...state,
        unassignedPccs: getpccsAfterUnselect(state.unassignedPccs, action.entityId)
      };
    case TOGGLE_ALL_SELECT_ASSIGNED_PCCS:
      return {
        ...state,
        assignedPccs: action.value
          ? getpccsAfterSelectAll(state.assignedPccs)
          : getpccsAfterUnselectAll(state.assignedPccs)
      };
    case TOGGLE_ALL_SELECT_UNASSIGNED_PCCS:
      return {
        ...state,
        unassignedPccs: action.value
          ? getpccsAfterSelectAll(state.unassignedPccs)
          : getpccsAfterUnselectAll(state.unassignedPccs)
      };
    default:
      return state;
  }
};

export const getAssignedPccs = {
  base: () => ({
    type: GET_ASSIGNED_PCCS.BASE,
    url: 'license/getassignedpccs',
  }),
  request: () => ({ type: GET_ASSIGNED_PCCS.REQUEST }),
  success: data => ({ type: GET_ASSIGNED_PCCS.SUCCESS, data }),
  failure: error => ({ type: GET_ASSIGNED_PCCS.FAILURE, error }),
};

export const getUnassignedPccs = {
  base: () => ({
    type: GET_UNASSIGNED_PCCS.BASE,
    url: 'license/getnonassignedpccs'
  }),
  request: () => ({ type: GET_UNASSIGNED_PCCS.REQUEST }),
  success: data => ({ type: GET_UNASSIGNED_PCCS.SUCCESS, data }),
  failure: error => ({ type: GET_UNASSIGNED_PCCS.FAILURE, error }),
};

export const pccSelection = {
  selectAssignedPcc: entityId => ({ type: SELECT_ASSIGNED_PCCS, entityId }),
  selectUnassignedPcc: entityId => ({ type: SELECT_UNASSIGNED_PCCS, entityId }),
  unselectAssignedPcc: entityId => ({ type: UNSELECT_ASSIGNED_PCCS, entityId }),
  unselectUnassignedPcc: entityId => ({ type: UNSELECT_UNASSIGNED_PCCS, entityId }),
  toggleAllSelectAssignedPcc: value => ({ type: TOGGLE_ALL_SELECT_ASSIGNED_PCCS, value }),
  toggleAllSelectUnassignedPcc: value => ({ type: TOGGLE_ALL_SELECT_UNASSIGNED_PCCS, value }),
};

export const addPccAssignments = {
  base: () => ({
    type: ADD_PCC_ASSIGNMENTS.BASE
  }),
  request: () => ({ type: ADD_PCC_ASSIGNMENTS.REQUEST }),
  success: data => ({ type: ADD_PCC_ASSIGNMENTS.SUCCESS, data }),
  failure: error => ({ type: ADD_PCC_ASSIGNMENTS.FAILURE, error }),
};

export const removePccAssignments = {
  base: () => ({
    type: REMOVE_PCC_ASSIGNMENTS.BASE,
    url: 'license/removelicensetopccs'
  }),
  request: () => ({ type: REMOVE_PCC_ASSIGNMENTS.REQUEST }),
  success: data => ({ type: REMOVE_PCC_ASSIGNMENTS.SUCCESS, data }),
  failure: error => ({ type: REMOVE_PCC_ASSIGNMENTS.FAILURE, error }),
};

export function* getAssignedPccsSaga({ url }) {
  try {
    const licId = yield select(licenseIdSelector);
    yield call(() => fetchEntity(getAssignedPccs, ApiClient.httpClient.get, url, { params: { licId } }));
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to fetch assigned pccs. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* getUnassignedPccsSaga({ url }) {
  try {
    const licId = yield select(licenseIdSelector);
    const orgId = yield select(orgIdSelector);
    yield call(() => fetchEntity(getUnassignedPccs, ApiClient.httpClient.get, url, { params: { licId, orgId } }));
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to fetch unassigned pccs. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* addPccAssignmentsSaga() {
  try {
    const licType = (yield select(licenseInfoSelector)).licenseType;
    let url = '';
    if (licType === licenseTypes.enterprise) {
      url = 'license/setenterpriselicensetopccs';
    } else if (licType === licenseTypes.site) {
      url = 'license/setsitelicense';
    }
    if (url) {
      const licId = yield select(licenseIdSelector);
      const idList = (yield select(pccsSelector)).unassignedPccs.filter(m => m.isSelected).map(m => m.id);
      yield call(() => fetchEntity(addPccAssignments, ApiClient.httpClient.post, url, { idList, licId }));
      yield put(updateAssignedSeatSiteLicenses(idList.length));
      yield all([
        put(getAssignedPccs.base()),
        put(getUnassignedPccs.base(null))
      ]);
    }
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to assign license to selected pccs. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* removePccAssignmentsSaga({ url }) {
  try {
    const licType = (yield select(licenseInfoSelector)).licenseType;
    if (licType === licenseTypes.enterprise || licType === licenseTypes.site) {
      const licId = yield select(licenseIdSelector);
      const idList = (yield select(pccsSelector)).assignedPccs.filter(m => m.isSelected).map(m => m.id);
      yield call(() => fetchEntity(removePccAssignments, ApiClient.httpClient.post, url, { idList, licId }));
      yield put(updateAssignedSeatSiteLicenses(0 - idList.length));
      yield all([
        put(getAssignedPccs.base()),
        put(getUnassignedPccs.base(null))
      ]);
    }
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to unassign license for selected pccs. Please try again';
    yield put(showAlertAction(message));
  }
}

export default pccs;
