import React from 'react';
import ToolTip from '../../common/ToolTip';
/* eslint-disable max-len */
const ImageRequiredMessage = () => (
  <>
    <h4>
      Images
      <ToolTip labelName={`Click on the first empty slot to upload your image.
 A thumbnail of your image will appear. You can edit or delete any image. Adding an image is completely optional.`}
      />
    </h4>
    <p>
      <em>Up to 4 images can be added to your profile page. Recommended image size is 800x600 pixels at 72 dpi resolution.</em>
    </p>
  </>
);

export default ImageRequiredMessage;
