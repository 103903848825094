/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const DirectDownloadSection = (props) => (
  <>
    <hr />

    <p className="purchase-success-distro-method">
      <span className="icon-direct-download" />
      {' '}
      Direct Download
    </p>

    <p>The solution you have ordered can be downloaded now by clicking the download button or later from the My Solutions section in your profile or the product page.</p>

    <p>Once the solution is downloaded:</p>
    <ol>
      <li>Unzip the File if needed.</li>
      <li>Right click on the .msi and ensure the file is unblocked by going to properties.</li>
      <li>Run the .msi file and complete installation by choosing default options.</li>
    </ol>

    <p><Link to="#" onClick={props.directDownloadHandler} className="green-button gtm-direct-download" gtm-event-label={props.gtmAppVendorTriggerDetails}>Download Now</Link></p>
  </>
);

const IntegrationDownloadSection = (props) => (
  <>
    <hr />

    <p className="purchase-success-distro-method">
      <span className="icon-automatic-install" />
      {' '}
      Automatic Install
    </p>

    <p>
      {`This solution is available to be installed directly to Smartpoint, if you have Marketplace enabled.
You must assign this solution to your Agents by clicking the Assignments button below. You can change or update your
assignments at a later time from the Purchased Solutions tab in your profile.`}
    </p>

    <p>For your assigned Agents, once they open Smartpoint they will receive a notification that the solution is Ready for Installation.</p>

    <p>
      <Link
        to={{
          pathname: '/admin/licenses/manageLicenseAssignments',
          search: `?licId=${props.licenseId}`
        }}
        className="green-button gtm-plugin-assignment-link"
        gtm-event-label={props.gtmAppVendorTriggerDetails}
      >
        Assign Agents
      </Link>
    </p>
  </>
);

const DistributedElsewhereSection = (props) => (
  <>
    <hr />

    <p className="purchase-success-distro-method">
      <span className="icon-3rd-party-download" />
      {' '}
      3rd Party Download
    </p>

    <p>{`This application is distributed by ${props.developerName}. They will be contacting you shortly to complete your transaction.`}</p>
  </>
);

const PurchaseSuccessPresentation = (props) => (
  <div className="container">
    <h2>Purchase Success</h2>
    <div className="purchase-success-container">
      <div className="purchase-success-content">
        <p className="purchase-success-msg">
          Thank you for your interest in
          {props.applicationName}
          {' '}
          by
          {props.developerName}
          !
        </p>

        <p>
          The information below will be sent to you at
          <a href={`mailto:${props.userEmail}`}>{props.userEmail}</a>
          .
        </p>

        {props.showDirectDownload
          && (
          <DirectDownloadSection
            directDownloadHandler={props.directDownloadHandler}
            gtmAppVendorTriggerDetails={props.gtmAppVendorTriggerDetails}
          />
          )}

        {props.showIntegrationDownload && props.licenseId
          && (
          <IntegrationDownloadSection
            licenseId={props.licenseId}
            gtmAppVendorTriggerDetails={props.gtmAppVendorTriggerDetails}
          />
          )}

        {props.isDistributedElsewhere
          && (
          <DistributedElsewhereSection
            developerName={props.developerName}
          />
          )}
        <hr />

        {props.welcomeText
          && (
          <p>
            {props.welcomeText.split('\n').map((line, k) => (
              <React.Fragment key={k.toString()}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
          )}

        <hr />

        {props.quantity && props.licenseType
          && (
          <p>
            You have purchased
            {props.quantity}
            {' '}
            new
            {props.licenseType}
            {' '}
            license(s).
          </p>
          )}
      </div>
    </div>
    <div className="row">
      <Link to="/Products" className="prod-detail-back-to-results">
        <svg viewBox="0 0 9 13" xmlns="http://www.w3.org/2000/svg">
          <path d="M9,13L2.8,6.5,9,0H6.2L0,6.5,6.2,13H9Z" />
        </svg>
        Back to Products
      </Link>
    </div>
  </div>
);

PurchaseSuccessPresentation.propTypes = {
  applicationName: PropTypes.string,
  developerName: PropTypes.string,
  userEmail: PropTypes.string,
  showDirectDownload: PropTypes.bool,
  showIntegrationDownload: PropTypes.bool,
  isDistributedElsewhere: PropTypes.bool,
  licenseId: PropTypes.number,
  directDownloadHandler: PropTypes.func,
  gtmAppVendorTriggerDetails: PropTypes.string,
  welcomeText: PropTypes.string,
  quantity: PropTypes.number,
  licenseType: PropTypes.string,
};

DirectDownloadSection.propTypes = {
  directDownloadHandler: PropTypes.func,
  gtmAppVendorTriggerDetails: PropTypes.string
};

IntegrationDownloadSection.propTypes = {
  gtmAppVendorTriggerDetails: PropTypes.string,
  licenseId: PropTypes.number
};

DistributedElsewhereSection.propTypes = {
  developerName: PropTypes.string,
};

export default PurchaseSuccessPresentation;
