import * as actionTypes from '../actions/actionType';

export const disableLinksReducer = (state = false, action = {}) => {
  switch (action.type) {
    case actionTypes.DISABLE_LINKS:
      return true;
    case actionTypes.ENABLE_LINKS:
      return false;
    default:
      return state;
  }
};

export const canNotifyDisableLinksReducer = (state = false, action = {}) => {
  switch (action.type) {
    case actionTypes.SEND_LINK_CLICK_MESSAGE:
      return true;
    case actionTypes.HIDE_SEND_LINK_CLICK_MESSAGE:
      return false;
    default:
      return state;
  }
};
