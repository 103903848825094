import { createSelector } from 'reselect';

export const licenseInfoSelector = (state) => state.administration.licenseAssignments.licenseInformation;
export const membersSelector = (state) => state.administration.licenseAssignments.members;
export const pccsSelector = (state) => state.administration.licenseAssignments.pccs;
export const licenseIdSelector = (state) => licenseInfoSelector(state).licenseId;
export const orgIdSelector = (state) => licenseInfoSelector(state).orgId;
export const seatSiteLicenseInfoSelector = (state) => licenseInfoSelector(state).seatSiteLicenseInfo;

const assignedMembers = (state) => membersSelector(state).assignedMembers;
const unassignedMembers = (state) => membersSelector(state).unassignedMembers;
const assignedPccs = (state) => pccsSelector(state).assignedPccs;
const unassignedPccs = (state) => pccsSelector(state).unassignedPccs;

const getMembersAsEntities = (members) => (
  members.map((member) => (
    {
      id: member.id,
      name: member.fullName,
      details: member.hostDetails,
      isSelected: member.isSelected
    }
  ))
);

const getPccsAsEntities = (pccs) => (
  pccs.map((pcc) => (
    {
      id: pcc.id,
      name: pcc.name,
      isSelected: pcc.isSelected
    }
  ))
);

export const assignedMembersEntitiesSelector = createSelector(
  [assignedMembers],
  (members) => getMembersAsEntities(members)
);

export const unassignedMembersEntitiesSelector = createSelector(
  [unassignedMembers],
  (members) => getMembersAsEntities(members)
);

export const assignedPccsEntitiesSelector = createSelector(
  [assignedPccs],
  (pccs) => getPccsAsEntities(pccs)
);

export const unassignedPccsEntitiesSelector = createSelector(
  [unassignedPccs],
  (pccs) => getPccsAsEntities(pccs)
);
