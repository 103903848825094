import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import agencyGroupIndexReducer, {
  agencyGroupMetaReducer,
  FETCH_AGENCY_GROUP_SET,
  fetchAgencyGroupSetSaga
} from './agencyGroupFetchReducer';
import agencyGroupDeleteReducer, {
  DELETE_AGENCY_GROUP, DELETE_AGENCY_GROUP_SUCCESS,
  deleteAgencyGroupSaga
} from './agencyGroupDeletionReducer';
import agencyGroupCreateReducer, {
  CREATE_AGENCY_GROUP, CREATE_AGENCY_GROUP_SUCCESS,
  createAgencyGroupSaga
} from './agencyGroupCreationReducer';
import agencyGroupUpdateReducer, {
  UPDATE_AGENCY_GROUP, UPDATE_AGENCY_GROUP_SUCCESS,
  updateAgencyGroupSaga
} from './agencyGroupEditionReducer';

export function* agencyGroupMiddleware() {
  yield takeLatest(FETCH_AGENCY_GROUP_SET, fetchAgencyGroupSetSaga);
  yield takeLatest(CREATE_AGENCY_GROUP, createAgencyGroupSaga);
  yield takeLatest(UPDATE_AGENCY_GROUP, updateAgencyGroupSaga);
  yield takeLatest(DELETE_AGENCY_GROUP, deleteAgencyGroupSaga);

  yield takeLatest(CREATE_AGENCY_GROUP_SUCCESS, fetchAgencyGroupSetSaga);
  yield takeLatest(UPDATE_AGENCY_GROUP_SUCCESS, fetchAgencyGroupSetSaga);
  yield takeLatest(DELETE_AGENCY_GROUP_SUCCESS, fetchAgencyGroupSetSaga);
}

const agencyGroupReducer = combineReducers({
  index: agencyGroupIndexReducer,
  create: agencyGroupCreateReducer,
  update: agencyGroupUpdateReducer,
  delete: agencyGroupDeleteReducer,
  meta: agencyGroupMetaReducer,
});

export default agencyGroupReducer;
