/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

class DeleteDraftModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productLicenseStatusInEditMode: false,
      productLicenseStatusValue: null,
    };
  }

  confirm = (e) => {
    const { applicationGuid, deleteDraftAction, popUpHide } = this.props;
    e.preventDefault();
    deleteDraftAction({
      applicationGuid,
      popUpHide
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  render() {
    const {
      showModal,
      loading,
    } = this.props;
    return (
      <Dialog
        appearance="warning"
        id="sbDialog"
        hidden={!showModal}
        buttons={
          (
            <>
              <Button
                className="action-button no-save"
                onClick={this.confirm}
                data-appearance="primary"
              >
                CONFIRM
              </Button>
              <Button
                className="cancel-button space-left no-save"
                onClick={this.cancel}
                data-appearance="secondary"
              >
                CANCEL
              </Button>
            </>
          )
        }
      >
        <h2>Delete Draft</h2>
        {(loading) && <LoaderComponent show />}
      </Dialog>
    );
  }
}

DeleteDraftModal.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  loading: PropTypes.bool,
  applicationGuid: PropTypes.string,
  deleteDraftAction: PropTypes.func,
};

export default DeleteDraftModal;
