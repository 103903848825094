import {
  put, take, race, select, call
} from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient.js';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';

import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';

export const GET_ADMINS_FOR_ORGS = createFetchTypes(
  'Marketplace/Administration/OrganizationDirectory/ManageAdmins/GET_ADMINS_FOR_ORGS'
);
export const ADD_ADMINS_FOR_ORGS = createFetchTypes(
  'Marketplace/Administration/OrganizationDirectory/ManageAdmins/ADD_ADMINS_FOR_ORGS'
);
export const UNASSIGN_ADMIN_ROLE_FOR_USER = createFetchTypes(
  'Marketplace/Administration/OrganizationDirectory/ManageAdmins/UNASSIGN_ADMIN_ROLE_FOR_USER'
);

export const getAdminsForOrgs = {
  base: params => ({
    type: GET_ADMINS_FOR_ORGS.BASE,
    params,
    url: '/organizationdirectory/getadminsfororg'
  }),
  request: () => ({
    type: GET_ADMINS_FOR_ORGS.REQUEST
  }),
  success: (data, organizationId) => ({
    type: GET_ADMINS_FOR_ORGS.SUCCESS,
    payload: { data },
    organizationId
  }),
  failure: error => ({
    type: GET_ADMINS_FOR_ORGS.FAILURE,
    payload: {
      error
    }
  })
};

export const addAdminsForOrgs = {
  base: params => ({
    type: ADD_ADMINS_FOR_ORGS.BASE,
    params,
    url: '/organizationdirectory/addadminfororg'
  }),
  request: () => ({
    type: ADD_ADMINS_FOR_ORGS.REQUEST
  }),
  success: data => ({
    type: ADD_ADMINS_FOR_ORGS.SUCCESS,
    payload: { data }
  }),
  failure: error => ({
    type: ADD_ADMINS_FOR_ORGS.FAILURE,
    payload: {
      error
    }
  })
};

export const unassignAdminRoleForUser = {
  base: params => ({
    type: UNASSIGN_ADMIN_ROLE_FOR_USER.BASE,
    params,
    url: `/organizationdirectory/unassignadminroleforuser/${params.internalId}`
  }),
  request: () => ({
    type: UNASSIGN_ADMIN_ROLE_FOR_USER.REQUEST
  }),
  success: data => ({
    type: UNASSIGN_ADMIN_ROLE_FOR_USER.SUCCESS,
    payload: { data }
  }),
  failure: error => ({
    type: UNASSIGN_ADMIN_ROLE_FOR_USER.FAILURE,
    payload: {
      error
    }
  })
};

export default function getAdminsForOrgsReducer(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_ORG_ID':
      return {
        ...state,
        organizationId: action.payload
      };

    case GET_ADMINS_FOR_ORGS.REQUEST:
    case ADD_ADMINS_FOR_ORGS.REQUEST:
    case UNASSIGN_ADMIN_ROLE_FOR_USER.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_ADMINS_FOR_ORGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data
      };

    case GET_ADMINS_FOR_ORGS.FAILURE:
    case ADD_ADMINS_FOR_ORGS.FAILURE:
    case UNASSIGN_ADMIN_ROLE_FOR_USER.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case ADD_ADMINS_FOR_ORGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        addAdminsForOrgsSuccessResponse: action.payload.data
      };

    case UNASSIGN_ADMIN_ROLE_FOR_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        unassignAdminsForOrgsSuccessResponse: action.payload.data
      };

    default:
      return state;
  }
}

export function* getAdminsForOrgsReducerWatcher(action) {
  yield put(showLoader());
  try {
    yield put({ type: 'SET_ORG_ID', payload: action.params.orgId });

    yield call(() => fetchEntity(
      getAdminsForOrgs,
      ApiClient.httpClient.get,
      action.url,
      action
    ));
  } catch (error) {
    console.error(error);
    yield put(getAdminsForOrgs.failure(error));
  }
  yield put(hideLoader());
}
export const getAdminsForOrgsSelector = state => state.administration.organizationDirectory.manageAdmins
  .getAdminsForOrgsReducer.data;

export const organizationIdSelector = state => state.administration.organizationDirectory.manageAdmins
  .getAdminsForOrgsReducer.organizationId;

export function* addAdminsForOrgsReducerWatcher(action) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(
      addAdminsForOrgs,
      ApiClient.httpClient.post,
      action.url,
      action.params
    ));

    const transforAction = action;
    delete transforAction.params.askTravelportId;

    yield call(() => fetchEntity(
      getAdminsForOrgs,
      ApiClient.httpClient.get,
      getAdminsForOrgs.base().url,
      transforAction
    ));
  } catch (error) {
    console.error(error);
    yield put(addAdminsForOrgs.failure(error));
  }
  yield put(hideLoader());
}

export function* unassignAdminRoleForUserReducerWatcher(action) {
  yield put(
    showConfirmationModal(
      `Are you sure you want to remove ${action.params.name} as Admin?`
    )
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });
  if (accept) {
    try {
      yield call(() => fetchEntity(
        unassignAdminRoleForUser,
        ApiClient.httpClient.put,
        action.url
      ));

      const organizationId = yield select(organizationIdSelector);
      const params = { params: { orgId: organizationId } };

      yield call(() => fetchEntity(
        getAdminsForOrgs,
        ApiClient.httpClient.get,
        getAdminsForOrgs.base().url,
        params
      ));
    } catch (error) {
      console.error(error);
      yield put(unassignAdminRoleForUser.failure(error));
    }
  }
}
