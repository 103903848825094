import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import * as CommonServices from '../../Services/Common.Service';
import { loadSearchResults, getSearchResultText } from '../../actions';
import SearchBox from '../common/SearchBox/SearchBox';
import TileLayout from '../common/TileLayout';

const queryString = require('query-string');

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.history = props.history;
    this.search = this.getTextFromQueryString(props);
    this.searchBox = {
      type: 'footer',
      inputStyle: {
        id: 'search-text-below', class: 'text-inputs', placeholder: 'search', name: 'searchText'
      },
      inputStyleButton: {
        id: 'search-input-below', class: 'action-button', placeholder: 'Search', value: 'Search'
      },
      formAttr: {
        action: 'application/browse', id: 'search-form-below', class: '', method: 'get'
      }
    };
    this.props = props;
  }

  componentDidMount() {
    this.loadSearchResults();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getTextFromQueryString(nextProps).searchText !== this.search.searchText) {
      this.search = this.getTextFromQueryString(nextProps);
      this.loadSearchResults();
    }
  }

  getSearchedCharacter = (searchText) => {
    const queryStr = queryString.stringify({ searchText });
    this.history.push(`/searchresults?${queryStr}`);
  }

  getTextFromQueryString(props) {
    return queryString.parse(props.location.search);
  }

  loadSearchResults() {
    this.props.showLoader();
    const response = CommonServices.fetchSearchResults(queryString.stringify(this.search));
    response.then((res) => {
      this.props.SearchTextAction(this.search.searchText);
      this.props.SearchResultAction(res);
      this.props.hideLoader();
    }).catch(() => {
      this.props.SearchResultAction([]);
      this.props.hideLoader();
    });
  }

  isProductDataExist(props) {
    return props.searchResult
      && props.searchResult.productTileViewModel
      && props.searchResult.productTileViewModel.length > 0;
  }

  isdeveloperDataExists(props) {
    return props.searchResult
      && props.searchResult.developerTileViewModel
      && props.searchResult.developerTileViewModel.length > 0;
  }

  formDeveloperData(props) {
    return {
      applicationName: props.organisationName,
      logoUrl: props.logoUrl,
      basicDescription: props.shortDescription,
    };
  }

  render() {
    return (
      <>
        <div>
          <h2>Search Results</h2>
          <h3>
            Search results for:
            <strong><em id="search-term">{this.props.searchResultText}</em></strong>
          </h3>
          <div className="row search-results-row">
            <div className="col-sm-6">
              <h4>Products</h4>
              <div className="search-results products-tile-results">
                {
                  this.isProductDataExist(this.props)
                  && this.props.searchResult.productTileViewModel.map((v, i) => (
                    <TileLayout
                      key={i.toString()}
                      {...v}
                      linkFor="Product"
                    />
                  ))
                }
                {
                  !this.isProductDataExist(this.props)
                    ? <div className="search-results-none">Sorry, no results found.</div>
                    : null
                }
              </div>
            </div>
            <div className="col-sm-6">
              <h4>Developers</h4>
              <div className="search-results developers-tile-results">
                {
                  this.isdeveloperDataExists(this.props)
                  && this.props.searchResult.developerTileViewModel.map((v, i) => (
                    <TileLayout
                      key={i.toString()}
                      {...this.formDeveloperData(v)}
                      isDeveloperTile
                      linkFor="DeveloperDirectory"
                    />
                  ))
                }
                {
                  !this.isdeveloperDataExists(this.props)
                    ? <div className="search-results-none">Sorry, no results found.</div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row top-border-hr pad-top">
          <div className="col-sm-5">
            <SearchBox
              clearSearchBoxAfterSubmit
              onFormSubmit={this.getSearchedCharacter}
              clearSearchBox
              {...this.searchBox}
            />
          </div>
        </div>
      </>
    );
  }
}

SearchResults.propTypes = {
  SearchResultAction: PropTypes.func,
  location: PropTypes.any,
  SearchTextAction: PropTypes.func,
  searchResultText: PropTypes.string,
  searchResult: PropTypes.any,
  history: PropTypes.any,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func
};

const mapStateToProps = (state) => ({
  searchResult: state.searchResult,
  searchResultText: state.searchResultText
});

const mapDispatchToProps = (dispatch) => ({
  SearchResultAction: bindActionCreators(loadSearchResults, dispatch),
  SearchTextAction: bindActionCreators(getSearchResultText, dispatch),
  showLoader: bindActionCreators(showLoader, dispatch),
  hideLoader: bindActionCreators(hideLoader, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults));
