import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';

export const FETCH_COMMUNICATION_DETAILS = createFetchTypes('Marketplace/Application/Communication/FETCH_COMMUNICATION_DETAILS');

export const communicationReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_COMMUNICATION_DETAILS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_COMMUNICATION_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.data,
          orderEmails: action.payload.data.orderEmails && action.payload.data.orderEmails.length > 0 ? action.payload.data.orderEmails : ['']
        },
        error: null
      };
    case FETCH_COMMUNICATION_DETAILS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export const fetchCommunicationDetails = {
  base: (params) => ({
    type: FETCH_COMMUNICATION_DETAILS.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_COMMUNICATION_DETAILS.REQUEST
  }),
  success: (data) => ({
    type: FETCH_COMMUNICATION_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_COMMUNICATION_DETAILS.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchCommunicationDetailsSaga(action) {
  try {
    const { params } = action.payload;
    let url = apiConfig.FETCH_COMMUNICATION_DETAILS;
    if (params) {
      url = `${apiConfig.FETCH_COMMUNICATION_DETAILS}?publicId=${params.publicId}&organizationId=${params.organizationId}&previewAfterSave=${params.previewAfterSave}`;
    }
    yield call(() => fetchEntityPPCP(fetchCommunicationDetails, ApiClient.httpClient.get, url));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchFetchCommunicationDetailsSaga() {
  yield takeLatest(FETCH_COMMUNICATION_DETAILS.BASE, fetchCommunicationDetailsSaga);
}
