import * as actionTypes from '../actions/actionType';

export const searchResultReducer = (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.SEARCHRESULTS:
      return action.data;
    default:
      return state;
  }
};

export const searchResultText = (state = '', action = {}) => {
  switch (action.type) {
    case actionTypes.SEARCHRESULTSTEXT:
      return action.data;
    default:
      return state;
  }
};
