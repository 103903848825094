import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default class MultiSelectComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value
    };
  }

  getAllOption = () => this.props.allOption || { label: 'All', value: 'All' };

  getOptions = () => {
    if (this.props.showSelectAll) {
      return [this.getAllOption(), ...this.props.options];
    }
    return this.props.options;
  };

  handleChange = value => {
    if (
      value.length > 0
      && value[value.length - 1].value === this.getAllOption().value
    ) {
      this.setState(
        { value: this.props.options },
        this.props.onValueChange(this.props.options)
      );
    } else {
      this.setState({ value }, this.props.onValueChange(value));
    }
  };

  renderValue = () => {
    let textDescription = '';
    if (this.state.value.length > 1) {
      textDescription = this.props.textDescription.replace('(s)', 's');
    } else {
      textDescription = this.props.textDescription.replace('(s)', '');
    }

    textDescription = textDescription.replace('(n)', this.state.value.length);

    return textDescription;
  };

  render() {
    return (
      <Select
        multi
        placeholder={this.props.placeholder}
        closeOnSelect={false}
        removeSelected={false}
        value={this.state.value}
        onChange={this.handleChange}
        options={this.getOptions()}
        className="travelport-select select-custom-selectedoptions"
        valueRenderer={this.renderValue}
      />
    );
  }
}

MultiSelectComponent.propTypes = {
  options: PropTypes.array,
  showSelectAll: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  allOption: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  onValueChange: PropTypes.func,
  textDescription: PropTypes.string,
  placeholder: PropTypes.string
};
