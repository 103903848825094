import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const getLink = (navigationPath, buttonText) => {
  if (navigationPath.startsWith('http')) {
    return <a href={navigationPath} target="_blank" className="green-button" rel="noreferrer">{buttonText}</a>;
  } if (navigationPath.startsWith('mail')) {
    return <a href={navigationPath} className="green-button">{buttonText}</a>;
  }
  return <Link exact="true" to={navigationPath} className="green-button">{buttonText}</Link>;
};

const HomeBrandRow = (props) => (
  <div className="row home-brand-row">
    <div className="col-sm-4">
      <h2>{props.heading}</h2>
    </div>
    <div className="col-sm-5">
      <p>{props.description}</p>
    </div>
    <div className="col-sm-3 text-center">
      {getLink(props.navigationPath, props.buttonText)}
    </div>
  </div>
);

HomeBrandRow.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  navigationPath: PropTypes.string,
  buttonText: PropTypes.string
};

export default HomeBrandRow;
