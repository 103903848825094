import React, { Component } from 'react';
import { Button } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import NavigationConfirmationPopup from './MPModal/NavigationConfirmationPopup';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';

class GoOutLink extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showModal: false
    };
  }

  handleClick = () => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction();
    }
    this.openModal();
  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  render() {
    return (
      <>
        <Button
          href="#"
          id={this.props.id}
          developername={this.props.developername}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => this.handleClick()}
          className="goOutLink linkbtnDesign"
        >
          {this.props.linkText}
        </Button>
        <NavigationConfirmationPopup
          showModal={this.state.showModal}
          NetworkURL={this.props.link}
          onHideModal={() => this.setState({ showModal: false })}
          blockContinueButton={this.props.disableLinks}
        />
      </>
    );
  }
}

GoOutLink.propTypes = {
  id: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  developername: PropTypes.string
};

export default GoOutLink;
