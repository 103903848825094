import React from 'react';
import PropTypes from 'prop-types';
import {
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { roleNameConstants } from 'utils/rolesHelper';
import ToolTip from 'components/common/ToolTip';

const CommandCell = (props) => {
  const userRoleName = props.row.roles && props.row.roles[0] && props.row.roles[0].text;
  const rowIsNotInEditMode = props.children.find(c => c.props && c.props.id === 'edit');
  const isRoleEditable = (userRoleName === roleNameConstants.AgencySuperAdmin.name && props.row.canUserEditSuperAgencyAdmins)
    || (userRoleName !== roleNameConstants.AgencySuperAdmin.name && props.row.canUserEditAgencyMembers);

  const showInfoinEdit = (props.row.roles.map(data => (data.value === 'Many Roles' && data.text !== roleNameConstants.AgencySuperAdmin.name)));

  if (rowIsNotInEditMode && (!isRoleEditable || !props.row.checkRoleEdit.includes(true))) {
    const allChildrenExceptEdit = props.children.find(c => !c.props || c.props.id !== 'edit');
    return (
      <VirtualTable.Cell>
        {allChildrenExceptEdit}
        <span style={{ marginLeft: '10px' }} className="space-right-sm">Edit</span>
        {showInfoinEdit.includes(true) && (
          <ToolTip labelName={"If the Edit link is disabled for a specific user, it is because that user's role was set by "
          + "a Travelport Marketplace Administrator.Please contact Travelport if you wish to change that user's role."}
          />
        )}
      </VirtualTable.Cell>
    );
  }
  return <VirtualTable.Cell {...props} />;
};

CommandCell.propTypes = {
  row: PropTypes.object,
  children: PropTypes.array
};

export default CommandCell;
