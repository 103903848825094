/* eslint-disable react/no-danger */
/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateTimeHelper from 'utils/dateHelper';
import {
  Grid,
  TableHeaderRow,
  Table,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  SortingState,
  PagingState,
  CustomPaging,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import './AuditLog.less';
import { auditLogSelector, auditLogTotalPages } from '../../../reducers/modules/Administration/AuditLog/selectors';
import { fetchAuditLog } from '../../../reducers/modules/Administration/AuditLog/AuditLog';
import DataCells from '../Reports/CommonParts/TableCellComponents/DataCells';
import { exportToExcelFunc } from '../../../reducers/modules/Administration/Reports/ExportToExcel/ExportToExcel';
import { DatePanel, SearchPanel, ExportToExcelPanel } from '../Reports/CommonParts/CommonPartsInReports';

const queryString = require('query-string');

const EnhanceText = ({ value }) => (
  <div dangerouslySetInnerHTML={{ __html: value }} />
);

EnhanceText.propTypes = {
  value: PropTypes.any
};

const TypeProvider = props => (
  <DataTypeProvider formatterComponent={EnhanceText} {...props} />
);

class AuditLog extends PureComponent {
  constructor(props) {
    super(props);
    this.sortingStateColumnExtensions = [
      { columnName: 'text', sortingEnabled: false },
      { columnName: 'action', sortingEnabled: false }
    ];
    this.state = {
      startDate: DateTimeHelper.getPriorDate('MM/DD/YYYY', 30),
      endDate: DateTimeHelper.getCurrentDate('MM/DD/YYYY'),
      sortAsc: false,
      logFkId: 0,
      sortOnColumn: '',
      category: '',
      sorting: {
        columnName: '',
        direction: 'asc'
      },
      paging: {
        currentPage: 0,
        pageSize: 20
      }
    };
    this.columnDefinitions = [
      { name: 'userName', title: 'User Name' },
      { name: 'createdDateTime', title: 'Date(UTC)' },
      { name: 'action', title: 'Context' },
      { name: 'text', title: 'Text' }
    ];
  }

  componentDidMount() {
    document.title = 'Audit Log Report';
    this.getFilteredAuditLog();
  }

  getFilteredAuditLog = e => {
    if (e) {
      e.preventDefault();
    }
    const queryStr = queryString.parse(this.props.location.search);
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      logFkId: queryStr.id,
      category: queryStr.category,
      currentPage: this.state.paging.currentPage
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.auditLogAction(params);
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  };

  getSortDirection = () => this.state.sorting.direction === 'asc';

  sortHandler = newSorting => this.setState(
    { sorting: { ...newSorting[0] } },
    this.getFilteredAuditLog
  );

  pageHandler = currentPage => this.setState(
    { paging: { ...this.state.paging, currentPage } },
    this.getFilteredAuditLog
  );

  exportToExcel = (e) => {
    e.preventDefault();

    const queryStr = queryString.parse(this.props.location.search);
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      logFkId: queryStr.id,
      sortOnColumn: this.getSortColumn() || '',
      sortAsc: this.getSortColumn() ? this.getSortDirection() : false,
      category: queryStr.category
    };

    if (this.state.sorting.columnName !== '') {
      params.sortOnColumn = this.state.sorting.columnName;
    }
    params.typeCaller = 'auditLog';
    this.props.auditLogDownloadExcel(params);
  };

  datehandler = (e, field) => {
    this.setState({
      [field]: DateTimeHelper.getDate(e)
    });
  };

  render() {
    return (
      <>
        <h2>Audit Logs</h2>
        <div className="container">
          <div className="no-margin">
            <div className="row no-space">
              {/* <h4>FILTER</h4> */}
            </div>
            <div id="filter-report-generator" className="row">
              <DatePanel
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                datehandler={this.datehandler}
              />
              <SearchPanel getFilteredAuditLog={this.getFilteredAuditLog} />
            </div>
            <ExportToExcelPanel exportToExcel={this.exportToExcel} />
            <div id="table-div" className="row">
              <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
                <Grid columns={this.columnDefinitions} rows={this.props.rows}>
                  <TypeProvider for={['text']} />
                  <PagingState
                    currentPage={this.state.paging.currentPage}
                    pageSize={this.state.paging.pageSize}
                    onCurrentPageChange={this.pageHandler}
                  />
                  <CustomPaging
                    totalCount={
                      this.props.totalPages * this.state.paging.pageSize
                    }
                  />
                  <SortingState
                    sorting={this.sorting}
                    onSortingChange={this.sortHandler}
                    columnExtensions={this.sortingStateColumnExtensions}
                  />
                  <Table cellComponent={DataCells} />
                  <TableHeaderRow showSortingControls />
                  <PagingPanel pageSizes={[]} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AuditLog.propTypes = {
  auditLogAction: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
  rows: PropTypes.array,
  totalPages: PropTypes.number,
  auditLogDownloadExcel: PropTypes.func
};

const mapStateToProps = state => ({
  rows: auditLogSelector(state),
  totalPages: auditLogTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  auditLogAction: args => dispatch(fetchAuditLog.base(args)),
  auditLogDownloadExcel: args => dispatch(exportToExcelFunc.auditLog(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditLog);
