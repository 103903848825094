import { createSelector } from 'reselect';
import { roleNameConstants } from 'utils/rolesHelper';

const hostEntriesSelector = state => state.profile.fullProfile.hostEntries;

export const hostListSelector = state => state.profile.myAgency.hostList;

export const isTpProfile = state => state.profile.accessingUserProfileReducer.isTPProfile;

export const getHostEntries = createSelector(
  [hostEntriesSelector],
  (hostEntries) => hostEntries && hostEntries.map(entry => ({
    id: entry.id,
    son: entry.son,
    pcc: entry.pcc,
    host: entry.host && entry.host.text,
    hostValue: entry.host && entry.host.value,
  }))
);

export const getOrgId = state => {
  try {
    return state.profile.userProfile.userDetails.organizationId || state.profile.userProfile.agencyDetails.id;
  } catch (error) {
    return null;
  }
};

export const getOrgGuid = state => {
  try {
    return state.profile.userProfile.agencyDetails.publicId;
  } catch (error) {
    return null;
  }
};

export const getRoleId = state => roleNameConstants[state.oidc.user.profile.role].id;

export const getUserNameFromProfile = (state) => state.profile
&& state.profile.userProfile
&& state.profile.userProfile.userDetails
&& state.profile.userProfile.userDetails.username;

export const isTravelportEmployee = state => state.oidc.user.profile.IsTravelportEmployee;
