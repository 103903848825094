import * as actionTypes from '../actions/actionType';

const filterDetailsReducer = (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.FILTERDETAILS:
      return action.data;
    default:
      return state;
  }
};

export default filterDetailsReducer;
