/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { toast } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dismissServerErrors } from 'reducers/modules/Application';
import '@atlas-design-system/core/dist/styles/partials/toast.css';

const ErrorPopup = ({ errorMessages, showErrorModal, clearErrorMess }) => (
  (errorMessages && errorMessages.length > 0 && showErrorModal)
    ? toast.error(errorMessages, {
      heading: 'Product could not be saved',
      id: 'Product could not be saved'.replaceAll(' ', '-'),
      position: 'top-center',
      showProgressBar: true,
      content: errorMessages
    }, clearErrorMess()) : null
);
class ServerErrorPopup extends Component {
  render() {
    if (!this.props.errorMessages || !this.props.showErrorModal) {
      return null;
    }
    return (
      <>
        {this.props.errorMessages && this.props.errorMessages.length > 0 && this.props.showErrorModal ? (
          <ErrorPopup
            showErrorModal={this.props.showErrorModal}
            errorMessages={this.props.errorMessages.length === 1 ? this.props.errorMessages[0] : 'Please fill in all mandatory fields'}
            clearErrorMess={this.props.dismissServerErrors}
          />
        ) : null}
      </>
    );
  }
}

ServerErrorPopup.propTypes = {
  showErrorModal: PropTypes.bool,
  errorMessages: PropTypes.array,
  dismissServerErrors: PropTypes.func,
  timeout: PropTypes.number
};

ServerErrorPopup.defaultProps = {
  timeout: 2000
};

const mapStateToProps = (state) => ({
  showErrorModal: state.application.applicationLayout.showErrorModal,
  errorMessages: state.application.applicationLayout.errorMessages,
});

const mapDispatchToProps = (dispatch) => ({
  dismissServerErrors: bindActionCreators(dismissServerErrors, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerErrorPopup);
