import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import fetchEntity from '../../../utils/fetchEntitySaga';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export const FETCH_PRODUCT_EVENT_REPORT = createFetchTypes('Marketplace/Profile/SalesReport/FETCH_PRODUCT_EVENT_REPORT');

export const productEventReportReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_PRODUCT_EVENT_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        totalPages: 0,
        error: null
      };
    case FETCH_PRODUCT_EVENT_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.pagedCollection,
        totalPages: action.payload.data.totalPages,
        error: null
      };
    case FETCH_PRODUCT_EVENT_REPORT.FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        totalPages: 0,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export const fetchproductEventReport = {
  base: (params) => ({
    type: FETCH_PRODUCT_EVENT_REPORT.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_PRODUCT_EVENT_REPORT.REQUEST
  }),
  success: (data) => ({
    type: FETCH_PRODUCT_EVENT_REPORT.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_PRODUCT_EVENT_REPORT.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchproductEventReportSaga(action) {
  try {
    const { params } = action.payload;
    yield call(() => fetchEntity(fetchproductEventReport, ApiClient.httpClient.post, apiConfig.GET_FILTERED_PRODUCT_EVENT_REPORT, { ...params }));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchFetchproductEventReportSaga() {
  yield takeLatest(FETCH_PRODUCT_EVENT_REPORT.BASE, fetchproductEventReportSaga);
}
