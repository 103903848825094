import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

export default class UserNameCell extends React.Component {
  render() {
    const { row } = this.props;
    return (
      <td className="stagnant-cell">
        <Link to={`/Profile/UserProfile/${row.userId}`} onClick={this.openModal}>{row.email}</Link>
      </td>
    );
  }
}

UserNameCell.propTypes = {
  row: PropTypes.object,
};
