import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import fetchEntity from '../../../utils/fetchEntitySaga';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';

export const FETCH_CLASSIFICATION_DETAILS = createFetchTypes('Marketplace/Application/Classification/FETCH_CLASSIFICATION_DETAILS');
export const FETCH_LIST_FOR_CLASSIFICATION_DETAILS = createFetchTypes('Marketplace/Application/Classification/FETCH_LIST_FOR_CLASSIFICATION_DETAILS');
export const ERROR_EXCEED_LIMIT = createFetchTypes('Marketplace/Application/Classification/error_exceed_limit');
export const HOST_ID_EMPTY = createFetchTypes('Marketplace/Application/Classification/host_id_empty');
export const STORE_KEYWORD = createFetchTypes('Marketplace/Application/Classification/store_keyword_id');
export const STORE_IDS = createFetchTypes('Marketplace/Application/Classification/STORE_IDS');

export const classificationReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_CLASSIFICATION_DETAILS.REQUEST:
    case FETCH_LIST_FOR_CLASSIFICATION_DETAILS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_CLASSIFICATION_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        classficaitonPlatform: {
          ...action.payload.data.platforms,
        },
        classficaitonHost: {
          ...action.payload.data.hosts,
        },
        classficaitonModel: {
          ...action.payload.data.model,
        },
        error: null
      };
    case FETCH_CLASSIFICATION_DETAILS.FAILURE:
    case FETCH_LIST_FOR_CLASSIFICATION_DETAILS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_LIST_FOR_CLASSIFICATION_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        productCategoryAndRegion: {
          ...action.payload.data,
        },
        error: null
      };
    case ERROR_EXCEED_LIMIT:
      return {
        ...state,
        loading: false,
        showProductCategoryErrorMessage: action.display
      };
    case HOST_ID_EMPTY:
      return {
        ...state,
        classficaitonModel: {
          ...state.classficaitonModel,
          hostIds: [1],
          platformId: action.platformId
        }
      };
    case STORE_KEYWORD:
      return {
        ...state,
        classficaitonModel: {
          ...state.classficaitonModel,
          keywords: action.keywords.join(';')
        }
      };
    case STORE_IDS: {
      if (action.payload.key === 'platformId' || action.payload.key === 'versionSupported') {
        return {
          ...state,
          classficaitonModel: {
            ...state.classficaitonModel,
            [action.payload.key]: action.payload.value
          }
        };
      }
      const [length] = state.classficaitonModel[action.payload.key];
      const keyValueArr = state.classficaitonModel[action.payload.key].slice(0, length);
      const index = keyValueArr.indexOf(action.payload.value);
      if (index === -1) {
        keyValueArr.push(action.payload.value);
      } else {
        keyValueArr.splice(index, 1);
      }
      return {
        ...state,
        classficaitonModel: {
          ...state.classficaitonModel,
          [action.payload.key]: keyValueArr
        }
      };
    }
    default:
      return state;
  }
};

export const displayErrorMessage = (fieldValue) => ({ type: ERROR_EXCEED_LIMIT, display: fieldValue });
export const hostIdsEmpty = (id) => ({ type: HOST_ID_EMPTY, platformId: id });
export const storeKeywords = (data) => ({ type: STORE_KEYWORD, keywords: data });
export const storeIds = (data) => (
  {
    type: STORE_IDS,
    payload: { ...data }
  });

export const fetchClassificationDetails = {
  base: (params) => ({
    type: FETCH_CLASSIFICATION_DETAILS.BASE,
    payload: {
      params
    }
  }),
  request: () => ({ type: FETCH_CLASSIFICATION_DETAILS.REQUEST }),
  success: (data) => ({
    type: FETCH_CLASSIFICATION_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: FETCH_CLASSIFICATION_DETAILS.FAILURE })
};

export const fetchListForClassificationDetails = {
  base: () => ({ type: FETCH_LIST_FOR_CLASSIFICATION_DETAILS.BASE }),
  request: () => ({ type: FETCH_LIST_FOR_CLASSIFICATION_DETAILS.REQUEST }),
  success: (data) => ({
    type: FETCH_LIST_FOR_CLASSIFICATION_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: FETCH_LIST_FOR_CLASSIFICATION_DETAILS.FAILURE })
};

export function* fetchClassificationDetailsSaga(action) {
  try {
    yield put(showLoader());
    const { params } = action.payload;
    let url = apiConfig.FETCH_CLASSIFICATION_DETAILS;
    if (params) {
      url = `${apiConfig.FETCH_CLASSIFICATION_DETAILS}?publicId=${params.publicId}&organizationId=${params.organizationId}&previewAfterSave=${params.previewAfterSave}`;
    }

    yield call(() => fetchEntityPPCP(fetchClassificationDetails, ApiClient.httpClient.get, url));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* fetchListForClassificationDetailsSaga() {
  try {
    yield put(showLoader());
    yield call(() => fetchEntity(fetchListForClassificationDetails, ApiClient.httpClient.get, apiConfig.FETCH_LIST_FOR_CLASSIFICATION_DETAILS));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* watchFetchClassificationDetailsSaga() {
  yield takeLatest(FETCH_CLASSIFICATION_DETAILS.BASE, fetchClassificationDetailsSaga);
}

export function* watchFetchListForClassificationDetailsSaga() {
  yield takeLatest(FETCH_LIST_FOR_CLASSIFICATION_DETAILS.BASE, fetchListForClassificationDetailsSaga);
}
