import { select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  DISTRIBUTION_FREE,
  DISTRIBUTION_FIXED_PRICE,
  DISTRIBUTION_CUSTOM,
  DISTRIBUTION_CUSTOM_REQUEST,
  DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION,
  DISTRIBUTION_CUSTOM_PRICING_LOCATION,
  IS_BILLED_MARKETPLACE,
  IS_BILLED_ELSEWHERE
} from 'constants/PPCP';

const distributionFormSelector = state => state.form.distribution.values;
const applicationSelector = state => state.application.applicationLayout;
const distributionSelector = state => state.application.distribution;
const countriesSelector = state => state.countries;
const priceByLocSelector = state => state.application.priceByLocation;

const customTypeType = (value) => {
  if (typeof value === 'string') {
    switch (value) {
      case DISTRIBUTION_CUSTOM_REQUEST:
        return 0;
      case DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION:
        return 1;
      case DISTRIBUTION_CUSTOM_PRICING_LOCATION:
        return 2;
      default:
        return 0;
    }
  } else {
    switch (value) {
      case 0:
        return DISTRIBUTION_CUSTOM_REQUEST;
      case 1:
        return DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION;
      case 2:
        return DISTRIBUTION_CUSTOM_PRICING_LOCATION;
      default:
        return DISTRIBUTION_CUSTOM_REQUEST;
    }
  }
};

const priceType = (value) => {
  if (typeof value === 'string') {
    switch (value) {
      case DISTRIBUTION_FREE:
        return 0;
      case DISTRIBUTION_CUSTOM:
        return 1;
      case DISTRIBUTION_FIXED_PRICE:
        return 2;
      default:
        return 0;
    }
  } else {
    switch (value) {
      case 0:
        return DISTRIBUTION_FREE;
      case 1:
        return DISTRIBUTION_CUSTOM;
      case 2:
        return DISTRIBUTION_FIXED_PRICE;
      default:
        return DISTRIBUTION_FREE;
    }
  }
};

export const priceByLocationSelector = createSelector(
  [priceByLocSelector],
  (items) => {
    if (!items || !Array.isArray(items)) return undefined;
    // if there is no default item All Others, we add it.
    // If it exist, we make sure that countryName is not null
    // we also must ensure that All Others item is the first in the array
    if (!items.find(i => i.isAllOtherSetting)) {
      return [
        {
          id: 1,
          countryName: 'All Others',
          isAllOtherSetting: true,
          pricingInformation: '',
          pricingNotes: '',
          saleTypeName: '',
        }
      ].concat(items);
    }
    const allOtherItem = items.find(i => i.isAllOtherSetting);
    return [{ ...allOtherItem }]
      .concat(items.filter(i => !i.isAllOtherSetting))
      .map(item => {
        if (item.isAllOtherSetting) {
          return {
            ...item,
            countryName: 'All Others'
          };
        }
        return item;
      });
  }
);

export const getListsDistributionSelector = createSelector(
  [distributionSelector],
  (distribution) => ({
    pricePeriods: distribution.pricePeriods,
    trialDayList: distribution.trialDayList,
    licenseTypeList: distribution.licenseTypeList,
    currencyList: distribution.currencyList,
  })
);

export const getDistributionSelector = createSelector(
  [distributionSelector],
  (distribution) => ({
    loading: distribution.loading,
    isPurchased: distribution.isPurchased,
    platformId: distribution.platformId,
    isLicenseChangeDisabled: distribution.isLicenseChangeDisabled,
    directDownload: distribution.isDirectDownload,
    marketplaceIntegration: distribution.isIntegrationDownload,
    distributeElsewhere: distribution.isDistributedElsewhere,
    distributionExplanation: distribution.distributeElsewhereDetails,
    billing: distribution.isBilledInMarketplace ? IS_BILLED_MARKETPLACE : IS_BILLED_ELSEWHERE,
    directDownloadLastUploadedVersion: distribution.directDownloadLastUploadedVersion || 'None',
    directDownloadCurrentVersion: distribution.directDownloadCurrentVersion || 'None',
    integrationDownloadLastUploadedVersion: distribution.integrationDownloadLastUploadedVersion || 'None',
    integrationDownloadCurrentVersion: distribution.integrationDownloadCurrentVersion || 'None',
    freeTrial: distribution.trialDays || false,
    trialDays: distribution.trialDays,
    licenseID: distribution.licenseType,
    isNotDistributedVisible: distribution.isNotDistributedVisible,
    noDistribution: distribution.isNotDistributed,
    isIntegrationDownloadVisible: distribution.isIntegrationDownloadVisible,
    licenseInfoIconText: distribution.licenseInfoIconText,
    priceId: distribution.price && distribution.price.id,
    currencyId: distribution.price && distribution.price.currencyId,
    priceQuantityValue: distribution.price && distribution.price.quantity,
    price: (distribution.price && priceType(distribution.price.priceType)) || DISTRIBUTION_FREE,
    priceCustom: (distribution.price && customTypeType(distribution.price.customPricingType)) || DISTRIBUTION_CUSTOM_REQUEST,
    priceCustomDescription: distribution.price && distribution.price.pricingExplanation,
    showAdditionalInformation: distribution.showAdditionalInformation,
    isDirectDownloadVisible: distribution.isDirectDownloadVisible,
    isDistributionElsewhereVisible: distribution.isDistributionElsewhereVisible
  })
);

export const getCountriesSelector = createSelector(
  [countriesSelector],
  (countries) => Array.isArray(countries)
    && countries
      .map(country => ({
        value: country,
        label: country
      }))
);

export function* createDistributionRequest() {
  const distributionState = yield select(distributionFormSelector);
  const applicationState = yield select(applicationSelector);
  const distribution = yield select(distributionSelector);
  return {
    price: {
      priceId: distributionState.priceId || 0,
      quantity: distributionState.priceQuantityValue || 0,
      currencyId: distributionState.currencyId,
      pricingExplanation: distributionState.priceCustomDescription,
      // priceType: priceType(distributionState.price),
      customPricingType: customTypeType(distributionState.priceCustom),
      priceByLocation: (distributionState.priceCustom === DISTRIBUTION_CUSTOM_PRICING_LOCATION)
    },
    isDirectDownload: distributionState.directDownload,
    isIntegrationDownload: distributionState.marketplaceIntegration,
    isDistributedElsewhere: distributionState.distributeElsewhere,
    distributeElsewhereDetails: distributionState.distributionExplanation,
    isNotDistributed: distributionState.noDistribution,
    customPriceType: customTypeType(distributionState.priceCustom),
    trialDays: distributionState.freeTrial ? distributionState.trialDays : null,
    isLicenseChangeDisabled: distributionState.licensing,
    isBilledInMarketplace: (distributionState.billing === 'isBilledInMarketplace') || false,
    licenseType: distributionState.licenseID,
    priceOption: priceType(distributionState.price),
    isInDraftMode: applicationState.isInDraftMode,
    isDraft: applicationState.isInDraftMode,
    id: applicationState.appid,
    publicGuid: applicationState.publicId,
    title: distribution.title,
    showAdditionalInformation: distributionState.showAdditionalInformation
  };
}
