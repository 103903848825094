/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

class IntegrationStatusModal extends PureComponent {
  constructor(props) {
    super(props);
    const productLicense = this.getCurrentStatus();
    this.state = {
      productLicenseStatusInEditMode: false,
      productLicenseStatusValue: productLicense.productLicenseStatusValue,
      productLicenseStatusText: productLicense.productLicenseStatusText,
    };
  }

  getCurrentStatus() {
    const { solutionProps } = this.props;
    if (solutionProps.selectedLicenseStatusOption) {
      if (solutionProps.selectedLicenseStatusOption.text === 'Active') {
        return { productLicenseStatusValue: '0', productLicenseStatusText: 'Activate' };
      }
      if (solutionProps.selectedLicenseStatusOption.text === 'Disabled') {
        return { productLicenseStatusValue: '1', productLicenseStatusText: 'Disable' };
      }
      return { productLicenseStatusValue: '2', productLicenseStatusText: 'Uninstall' };
    }
    return { productLicenseStatusValue: '', productLicenseStatusText: '' };
  }

  changeProductLicenseStatusValue = (e) => this.setState({ productLicenseStatusValue: e.target.value, productLicenseStatusText: e.target.options[e.target.value].innerHTML });

  changeProductLicenseStatusInEditMode = () => this.setState({ productLicenseStatusInEditMode: !this.state.productLicenseStatusInEditMode });

  confirm = (e) => {
    const { updateStatusAction, solutionProps, popUpHide } = this.props;
    e.preventDefault();
    updateStatusAction({
      newLicenseStatus: this.state.productLicenseStatusValue,
      licId: solutionProps.licenseId || null,
      applicationId: solutionProps.applicationId,
      onSuccess: popUpHide,
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  editStatus() {
    return (
      <select
        onChange={this.changeProductLicenseStatusValue}
        className="text-link-black"
        value={this.state.productLicenseStatusValue}
      >
        <option value="0">Activate</option>
        <option value="1">Disable</option>
        <option value="2">Uninstall</option>
      </select>
    );
  }

  render() {
    const {
      showModal,
      popUpHide,
      solutionProps,
      loading,
    } = this.props;
    return (
      <Modal
        id="sbModal"
        title={`Smartpoint Integration Status for ${solutionProps.name}`}
        hidden={!showModal}
        size="small"
        onDismiss={popUpHide}
      >
        <div className="modal-custom-text space-top">
          <p>{`This modal displays the current Smartpoint Integration status for ${solutionProps.name}. You can also make updates by selecting the new status below.`}</p>
        </div>
        <div className="space-top text-center">
          STATUS:
          {' '}
          {(this.state.productLicenseStatusInEditMode && this.editStatus())
            || (solutionProps.selectedLicenseStatusOption && solutionProps.selectedLicenseStatusOption.text)}
        </div>
        <div className="text-center space-top">
          <button
            type="button"
            gtm-event-label={`${solutionProps.name} - ${this.state.productLicenseStatusText}`}
            className={`action-button no-save ${this.state.productLicenseStatusInEditMode ? 'gtm-sp-license-change' : ''}`}
            onClick={this.state.productLicenseStatusInEditMode ? this.confirm : this.changeProductLicenseStatusInEditMode}
          >
            {this.state.productLicenseStatusInEditMode ? 'SAVE' : 'EDIT'}
          </button>
          <button
            type="button"
            className="cancel-button space-left no-save"
            onClick={this.state.productLicenseStatusInEditMode ? this.changeProductLicenseStatusInEditMode : this.cancel}
          >
            CANCEL
          </button>
        </div>
        <div className="modal-custom-text space-top">
          <p>
            <strong>Active</strong>
            {' '}
            - This solution should function as intended.
          </p>
          <p>
            <strong>Disabled</strong>
            {' '}
            - This solution is installed in Smartpoint, but it cannot be used by the user.
          </p>
          <p>
            <strong>Uninstalled</strong>
            {' '}
            - The next time a user at your agency starts up Smartpoint, it will receive a message that will uninstall this solution
            from their Smartpoint instance.
          </p>
        </div>
        {(loading) && <LoaderComponent show />}
      </Modal>
    );
  }
}

IntegrationStatusModal.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  solutionProps: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  updateStatusAction: PropTypes.func,
};

export default IntegrationStatusModal;
