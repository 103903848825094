import * as actionTypes from '../../actions/actionType';

export const getFieldsforDeveloperEditReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_FIELDS_FOR_DEVELOPER_EDIT:
      return action.data;
    default:
      return state;
  }
};
export default getFieldsforDeveloperEditReducer;
