import React from 'react';
import PropTypes from 'prop-types';
import {
  Table
} from '@devexpress/dx-react-grid-bootstrap4';

export default class RowComponent extends React.Component {
  render() {
    if (this.props.row.isSelected) {
      return <Table.Row className="child-org-tr" {...this.props} />;
    }
    return <Table.Row {...this.props} />;
  }
}

RowComponent.propTypes = {
  row: PropTypes.object,
};
