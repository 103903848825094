import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import {
  manageLicensesSearch,
  changeSearchType,
  resetManageLicensesSearch
} from 'reducers/modules/Administration/ManageLicenses/ManageLicenseSearch/manageLicenseSearch';
import {
  manageLicensesSearchSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';
import * as utils from 'utils/Common.Utils';
import AgenciesTable from './AgenciesTable/AgenciesTable';
import PluginsTable from './PluginsTable/PluginsTable';

class ManageLicensesSearch extends PureComponent {
  constructor(props) {
    super(props);
    const searchText = utils.getValueFromQueryString(props.location, 'searchText') || '';
    const searchType = utils.getValueFromQueryString(this.props.location, 'searchType');
    this.state = {
      searchText,
      searchType: searchType && searchType.toUpperCase() !== 'AGENCY' ? 'Plugin' : 'Agency'
    };
  }

  componentDidMount() {
    document.title = 'Manage License Search';
    if (this.state.searchType !== 'Agency') {
      this.props.changeSearchType('Plugin');
    }
    const showPreviousSearch = utils.getValueFromQueryString(this.props.location, 'showPreviousSearch');
    if (showPreviousSearch && showPreviousSearch.toUpperCase() === 'TRUE') {
      this.performSearch();
    }
  }

  componentWillUnmount() {
    this.props.resetManageLicensesSearch();
  }

  onChangeSearchText = (event) => {
    this.setState({ searchText: event.target.value });
  }

  getSearchParams = () => ({
    searchCriteria: this.state.searchText,
    searchAgencies: this.state.searchType === 'Agency'
  })

  performSearch = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.searchType !== this.props.searchType) {
      this.props.changeSearchType(this.state.searchType);
    }
    this.props.manageLicensesSearch(this.getSearchParams());
  }

  performAllSearch = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.searchType !== this.props.searchType) {
      this.props.changeSearchType(this.state.searchType);
    }
    this.props.manageLicensesSearch({ ...this.getSearchParams(), searchCriteria: '' });
  }

  changeSearchType = (value) => {
    this.setState({ searchType: value });
  }

  render() {
    return (
      <>
        <h2>Manage Plug-in Licenses</h2>
        <div className="container">
          <div className="row">
            <div className="col-sm-1 pad-top-sm">Search: </div>
            <div className="col-sm-3">
              <SimplePrettyRadioButton
                enabled
                displayText="Agencies"
                value="Agency"
                checked={this.state.searchType === 'Agency'}
                onChange={this.changeSearchType}
              />
              <SimplePrettyRadioButton
                enabled
                displayText="Plug-ins"
                value="Plugin"
                checked={this.state.searchType === 'Plugin'}
                onChange={this.changeSearchType}
              />
            </div>
          </div>
          <div className="row space-bottom-sm" id="search-label">
            {this.state.searchType === 'Agency' ? 'Agency/Customer #:' : 'Plug-in Title'}
          </div>
          <div className="row no-space">
            <div className="horizontal-input-group col-sm-4">
              <div>
                <input value={this.state.searchText} onChange={this.onChangeSearchText} className="text-input" id="search-text" />
              </div>
              <button type="button" className="cancel-button" onClick={this.performSearch} id="search-button">Search</button>
            </div>
            <div className="col-sm-1 text-center pad-top-sm">Or</div>
            <div className="col-sm-3">
              <button type="button" className="action-button no-space" id="view-all-button" onClick={this.performAllSearch}>
                {this.state.searchType === 'Agency' ? 'View All Agencies' : 'View All Plug-ins'}
              </button>
            </div>
          </div>
          {this.props.errorMessage
            && (
            <div className="row height-validation-error">
              {this.props.errorMessage}
            </div>
            )}
          {this.props.currentSearch
            && (
            <div className="row no-margin">
              <h3 className="no-margin no-border">
                <span id="search-section">Search results for: </span>
                <i><span id="current-search">{this.props.currentSearch}</span></i>
              </h3>
            </div>
            )}
          {this.props.searchType === 'Agency' && <AgenciesTable />}
          {this.props.searchType === 'Plugin' && <PluginsTable />}
        </div>
      </>
    );
  }
}

ManageLicensesSearch.propTypes = {
  searchType: PropTypes.string,
  changeSearchType: PropTypes.func,
  errorMessage: PropTypes.string,
  manageLicensesSearch: PropTypes.func,
  resetManageLicensesSearch: PropTypes.func,
  currentSearch: PropTypes.string,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  searchType: manageLicensesSearchSelector(state).searchType,
  errorMessage: manageLicensesSearchSelector(state).errorMessage,
  currentSearch: manageLicensesSearchSelector(state).currentSearch
});

const mapDispatchToProps = dispatch => ({
  changeSearchType: value => dispatch(changeSearchType(value)),
  manageLicensesSearch: params => dispatch(manageLicensesSearch.base(params)),
  resetManageLicensesSearch: () => dispatch(resetManageLicensesSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageLicensesSearch);
