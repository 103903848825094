/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Footer as Atlasfooter, TravelportLogo } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { roleNameConstants } from 'utils/rolesHelper';
import { withRouter } from 'react-router';
import { canNotifyDisableLinks } from '../../actions';
import * as Config from '../../config/apiConfig';
import { goToRoute } from '../../reducers/modules/Application/index';
import '@atlas-design-system/core/dist/styles/partials/footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.privacyURL = 'http://www.travelport.com/Privacy-Policy/Travelport-Websites';
    this.termsCondition = 'http://www.travelport.com/Terms-and-Conditions';
    this.environment = Config.ENVIRONMENT;
  }

  componentDidMount() {
    this.version = this.extractVersion(Config.BUILD_NUMBER);
  }

  extractSemVersion = (version) => {
    const semVersion = {
      major: '0',
      minor: '0',
      patch: '0',
      revision: '0'
    };
    if (version) {
      const versionList = version.split('.');
      semVersion.major = versionList[0] || '0';
      semVersion.minor = versionList[1] || '0';
      semVersion.patch = versionList[2] || '0';
      semVersion.revision = versionList[3] || '0';
    }
    return semVersion;
  }

  extractVersion = (versionNumber) => {
    const semVersion = {
      major: '0',
      minor: '0',
      patch: '0',
      revision: '0'
    };
    if (versionNumber) {
      const str = versionNumber.split('v');
      const strLength = str.length;
      if (strLength && strLength > 0 && strLength === 2) {
        return this.extractSemVersion(str[1]);
      }
    }
    return semVersion;
  }

  GetVersionInfo = (webapi, platformservices, isSuperAdmin) => {
    var vers = this.version ? `Version:${this.version.major}.${this.version.minor}` : '';

    if (this.props.user && isSuperAdmin) {
      vers += ` | Web:${this.version.major}.${this.version.minor}.${this.version.patch}.${this.version.revision}`;
      vers += ` | Web-API:${webapi.major}.${webapi.minor}.${webapi.patch}.${webapi.revision}`;
      vers += ` | PlatformServices:${platformservices.major}.${platformservices.minor}.${platformservices.patch}.${platformservices.revision}`;
    }

    return vers;
  }

  handleClick = (e, path) => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction();
      e.preventDefault();
    } else if (this.props.isPPCPFormModified) {
      this.props.goToRoute(path, this.props.history);
      e.preventDefault();
    }
  }

  render() {
    let webapi;
    let platformservices;
    let isSuperAdmin = false;
    const buildNumber = localStorage.buildNumber ? JSON.parse(localStorage.buildNumber) : '';

    if (this.props.user && this.props.user.profile && !this.props.user.expired) {
      isSuperAdmin = this.props.user.profile.role === roleNameConstants.MarketplaceTeamAdmin.name;
    }

    if (buildNumber && (buildNumber.webapi || buildNumber.platformservices)) {
      webapi = this.extractVersion(buildNumber.webapi);
      platformservices = this.extractVersion(buildNumber.platformservices);
    } else {
      webapi = this.extractVersion(this.props.buildNumber.webapi);
      platformservices = this.extractVersion(this.props.buildNumber.platformservices);
    }

    /* eslint-disable max-len */
    return (
      <Atlasfooter
        className="custom-footerlogo margin-align"
        copyright={`© ${(new Date()).getYear() + 1900} Travelport. ${this.GetVersionInfo(webapi, platformservices, isSuperAdmin)}`}
        logo={<TravelportLogo width="200" />}
      >
        <React.Fragment key=".0">
          <Atlasfooter.Menu>
            <Link onClick={(e) => this.handleClick(e, '/')} to="/">Home</Link>
            <Link onClick={(e) => this.handleClick(e, '/products')} to="/products">Products</Link>
            <Link onClick={(e) => this.handleClick(e, '/contactus')} to="/contactus">Contact us</Link>
          </Atlasfooter.Menu>
          <Atlasfooter.Menu title="Policies">
            <a href="https://www.travelport.com/privacy" target="_blank">Privacy Policy</a>
            <a href="/cookiepolicy" target="_blank">Cookie Policy</a>
            <a href="https://www.travelport.com/code-conduct" target="_blank">Code of Business Conduct</a>
            <a href="https://www.travelport.com/travelport-business-partner-conduct-and-ethics-policy" target="_blank">Business Partner Conduct & Ethics Policy</a>
            <a href="https://www.travelport.com/modern-slavery-act" target="_blank">Modern Slavery Act</a>
          </Atlasfooter.Menu>
          <Atlasfooter.Menu title="Help and resources">
            <Link onClick={(e) => this.handleClick(e, '/faqs')} to="/faqs">FAQs</Link>
            <a className="link-li" rel="noopener noreferrer" target="_blank" href="https://support.travelport.com/webhelp/TravelportMarketplace">Online User Guide</a>
          </Atlasfooter.Menu>
        </React.Fragment>
      </Atlasfooter>
    );
  }
}

Footer.propTypes = {
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  isPPCPFormModified: PropTypes.bool,
  goToRoute: PropTypes.func,
  buildNumber: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  disableLinks: state.disableLinks,
  isPPCPFormModified: state.application.applicationLayout.currentForm.modified,
  buildNumber: state.home.buildNumber || {},
  user: state.oidc.user
});

const mapDispatchToProps = (dispatch) => ({
  notifyDisableLinksAction: bindActionCreators(canNotifyDisableLinks, dispatch),
  goToRoute: (route, history) => dispatch(goToRoute.base({ route, queryString: '/', history }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
