/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Authorization from 'components/Authorization/Authorization';
import {
  loadDetailsForTerms, detailsForTermsLoadSuccess, detailsForTermsLoadFailure, buyApplication, buyApplicationSuccess, buyApplicationFailure
}
from '../../../actions/Order/order.Actions';
import StandardTermsPresentation from './StandardTermsPresentation';
import SpecificTermsPresentation from './SpecificTermsPresentation';
import PurchaseSuccessPresentation from './PurchaseSuccessPresentation';
import PlatformStandardTermsPresentation from './PlatformStandardTermsPresentation';
import SmartpointCloudTermsPresentation from './SmartpointCloudTermsPresentation';
import AdditionalCustomerInfoForm from './AdditionalCustomerInfoForm';
import OrderActions from './OrderActions';
import * as utils from '../../../utils/Common.Utils';
import * as SeoHelper from '../../../utils/SeoHelper';
import * as config from '../../../config/apiConfig';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import { showAlertAction } from '../../../reducers/popUps/alertPopUpReducer';
import { savePluginAssignmentData } from '../../../reducers/modules/SpCloudPluginsAssignment/SpCloudPluginAssignment';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsFor: this.props.showTermsFor || 'standard',
    };
  }

  UNSAFE_componentWillMount() {
    this.loadDetailsForTerms();
  }

  onSubmit(page) {
    if (page === 'buy') {
      this.buyProduct(null);
    } else if (page === 'download') {
      this.downloadProduct();
    } else if (page === 'downloadspc') {
      this.downloadspcProduct();
    } else {
      this.setState({ showTermsFor: page });
    }
  }

  getNextPage() {
    if (this.state.showTermsFor === 'standard') {
      if (this.props.order.detailsForTerms.data.hasSpecificTerms) {
        return 'specific';
      }
      if (this.props.order.detailsForTerms.data.showAddCustInfo) {
        return 'additionalCustInfo';
      }
      return 'buy';
    }
    if (this.state.showTermsFor === 'specific') {
      if (this.props.order.detailsForTerms.data.showAddCustInfo) {
        return 'additionalCustInfo';
      }
      return 'buy';
    }
    if (this.state.showTermsFor === 'platform') {
      return 'download';
    }
    if (this.state.showTermsFor === 'smartpointcloud') {
      return 'downloadspc';
    }
    return 'buy';
  }

  submitAdditionalCustomerInfo = (values) => {
    this.buyProduct(values);
  }

  successHandler = () => {
    if (this.props.getPluginAssigment === 'Success') {
      const link = document.createElement('a');
      link.href = `/Product/${SeoHelper.SEOFriendly(this.props.order.detailsForTerms.data.applicationName)}?pluginAssigment=${this.props.getPluginAssigment}`;
      link.click();
    }
  }

  downloadProduct() {
    const { id } = this.props.match.params;
    utils.GetService(`${config.BASE_URL}${config.GET_DOWNLOAD_URL}/${id}`).then((res) => {
      const link = document.createElement('a');
      link.href = res.downloadUrl;
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(res.downloadUrl, 100));
      this.props.history.push(`/Product/${SeoHelper.SEOFriendly(this.props.order.detailsForTerms.data.applicationName)}`);
    }).catch((error) => {
      this.props.showAlertAction(error.statusText);
    });
  }

  downloadspcProduct() {
    this.props.savePluginAssignmentDataAction(
      {
        Application: { PublicGuid: this.props.order.detailsForTerms.data.id ? this.props.order.detailsForTerms.data.id : '' }, Email: this.props.user.profile.email ? this.props.user.profile.email : ''
      }, this.successHandler
    );
  }

  goBack() {
    if (this.state.showTermsFor === 'standard' || this.state.showTermsFor === 'platform' || this.state.showTermsFor === 'smartpointcloud') {
      this.props.history.push(`/Product/${SeoHelper.SEOFriendly(this.props.order.detailsForTerms.data.applicationName)}`);
    } else if (this.state.showTermsFor === 'specific') {
      this.setState({ showTermsFor: 'standard' });
    }
    return 'buy';
  }

  loadDetailsForTerms() {
    const quantity = utils.getValueFromQueryString(this.props.location, 'quantity');
    const { id } = this.props.match.params;
    let hitUrl = `${config.BASE_URL}${config.GET_DETAILS_FOR_TERMS}/${id}`;
    if (quantity) {
      hitUrl = `${hitUrl}?quantity=${quantity}`;
    }
    this.props.loadDetailsForTerms();
    utils.GetService(hitUrl).then((res) => {
      this.props.detailsForTermsLoadSuccess(res);
    }).catch((error) => {
      this.props.detailsForTermsLoadFailure(error);
    });
  }

  buyProduct(addionalCustomerInfo) {
    const hitUrl = `${config.BASE_URL}${config.BUY_APPLICATION}`;
    const params = {
      id: this.props.match.params.id,
      quantity: utils.getValueFromQueryString(this.props.location, 'quantity'),
      showAddCustInfo: this.props.order.detailsForTerms.data.showAddCustInfo,
      addionalCustomerInfo
    };
    this.props.buyApplication();
    utils.Service('POST', hitUrl, params).then((res) => {
      this.props.buyApplicationSuccess(res);
    }).catch((error) => {
      this.props.buyApplicationFailure(error);
    });
  }

  render() {
    if (!this.props.order || !this.props.order.detailsForTerms) {
      return '';
    }
    if (this.props.order.detailsForTerms.loading
      || (this.props.order.orderDetails && this.props.order.orderDetails.loading)) {
      return (
        <LoaderComponent show />
      );
    }
    if (!this.props.order.orderDetails) {
      if (this.state.showTermsFor === 'additionalCustInfo') {
        return (
          <AdditionalCustomerInfoForm
            onSubmit={this.submitAdditionalCustomerInfo}
            developerName={this.props.order.detailsForTerms.data.developerName}
            onCancel={() => { this.props.history.push(`/Product/${SeoHelper.SEOFriendly(this.props.order.detailsForTerms.data.applicationName)}`); }}
          />
        );
      }
      return (
        <div className="container">
          {this.state.showTermsFor === 'standard'
            && (
              <StandardTermsPresentation
                hasSpecificTerms={this.props.order.detailsForTerms.data.hasSpecificTerms}
              />
            )}
          {this.state.showTermsFor === 'specific'
            && (
              <SpecificTermsPresentation
                applicationName={this.props.order.detailsForTerms.data.applicationName}
                logoUrl={this.props.order.detailsForTerms.data.logoUrl}
                specificTerms={this.props.order.detailsForTerms.data.specificTerms}
              />
            )}
          {this.state.showTermsFor === 'platform'
            && (
              <PlatformStandardTermsPresentation
                applicationName={this.props.order.detailsForTerms.data.applicationName}
              />
            )}
          {this.state.showTermsFor === 'smartpointcloud'
            && (
              <SmartpointCloudTermsPresentation
                applicationName={this.props.order.detailsForTerms.data.applicationName}
              />
            )}
          <OrderActions
            applicationName={this.props.order.detailsForTerms.data.applicationName}
            developerName={this.props.order.detailsForTerms.data.developerName}
            agreeButtonText={this.state.showTermsFor === 'platform' ? 'Agree and download now' : 'Agree to Terms & Conditions'}
            submitHandler={() => { this.onSubmit(this.getNextPage()); }}
            goBackHandler={() => this.goBack()}

          />
        </div>
      );
    }
    return (
      <>
        <PurchaseSuccessPresentation
          {...this.props.order.orderDetails.data}
          gtmAppVendorTriggerDetails={
            `Solution:${this.props.order.orderDetails.data.applicationName} / Developer:${this.props.order.orderDetails.data.developerName} / TriggerLocation:Purchase Success Page`
          }
          directDownloadHandler={() => this.downloadProduct()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.product.order,
  getPluginAssigment: state.gitSPCAssignmentInfo.getPluginAssigment,
  errorList: state.gitSPCAssignmentInfo.errorList,
});

const mapDispatchToProps = (dispatch) => ({
  loadDetailsForTerms: bindActionCreators(loadDetailsForTerms, dispatch),
  detailsForTermsLoadSuccess: bindActionCreators(detailsForTermsLoadSuccess, dispatch),
  detailsForTermsLoadFailure: bindActionCreators(detailsForTermsLoadFailure, dispatch),
  buyApplication: bindActionCreators(buyApplication, dispatch),
  buyApplicationSuccess: bindActionCreators(buyApplicationSuccess, dispatch),
  buyApplicationFailure: bindActionCreators(buyApplicationFailure, dispatch),
  showAlertAction: bindActionCreators(showAlertAction, dispatch),
  savePluginAssignmentDataAction: (args, successHandler) => dispatch(savePluginAssignmentData.base(args, successHandler))
});

Order.defaultProps = {
  showModalFlag: false,
  popUpHide: true
};
Order.propTypes = {
  showTermsFor: PropTypes.string,
  getPluginAssigment: PropTypes.string,
  showModalFlag: PropTypes.bool,
  popUpHide: PropTypes.bool,
  loadDetailsForTerms: PropTypes.func,
  detailsForTermsLoadSuccess: PropTypes.func,
  detailsForTermsLoadFailure: PropTypes.func,
  buyApplication: PropTypes.func,
  buyApplicationSuccess: PropTypes.func,
  buyApplicationFailure: PropTypes.func,
  savePluginAssignmentDataAction: PropTypes.func,
  order: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  showAlertAction: PropTypes.func,
  history: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization(Order, { path: false }));
