import * as React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  getHostIds,
  getHostIdsSelector,
  trasnformHostData,
} from 'reducers/modules/Profile/Agency/AgencyDetails/getHostIdsReducer';

class selectHSO extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hostIds !== prevState.hostIds) {
      return nextProps.hostIds;
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.onHostAutocomplete(props.value);
    this.state = { isLoading: false };
  }

  onHostAutocomplete = input => {
    if (!input || input.length < 3) {
      return [];
    }
    this.setState({ isLoading: true });
    this.props.getHostIds(input);
    return input;
  };

  handleOnChange = selectOption => {
    if (selectOption) {
      return this.props.onValueChange(selectOption.value);
    }

    return this.props.onValueChange('');
  };

  render() {
    const options = trasnformHostData(this.props.hostIds);
    const value = { value: this.props.value, label: this.props.value };
    return (
      <td>
        <Select
          className="travelport-select"
          resetValue=""
          escapeClearsValue={false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          value={value}
          onInputChange={this.onHostAutocomplete}
          options={options}
          onChange={this.handleOnChange}
        />
      </td>
    );
  }
}
selectHSO.defaultProps = {
  hostIds: []
};

selectHSO.propTypes = {
  onValueChange: PropTypes.func,
  getHostIds: PropTypes.func,
  value: PropTypes.string,
  hostValue: PropTypes.string,
  hostIds: PropTypes.array
};

const mapStateToProps = state => ({
  hostIds: getHostIdsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getHostIds: params => dispatch(getHostIds.base(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(selectHSO);
