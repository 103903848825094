/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Tabs, toast } from '@atlas-design-system/react';
import { Link, withRouter } from 'react-router-dom';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';
import * as Config from '../../../config/apiConfig';
import { productLoad, productLoadSuccess, productLoadFailure } from '../../../actions/index';
import {
  productRatingLoad, productRatingLoadSuccess, productRatingLoadFailure,
  productReviewsLoad, productReviewsLoadSuccess, productReviewsLoadFailure
} from '../../../actions/Product/reviewsAndRatings.Actions';
import {
  setReviewState,
  saveDemoRequest,
  directDownloadProduct,
  getAppsForPlatform,
  popularApplicationsForPlatformSelector
} from '../../../reducers/Product/Product/productReducers';
import {
  getAdminsForOrgs,
} from '../../../reducers/modules/Administration/OrganizationDirectory/ManageAdmins/getAdminsForOrg';
import * as CommonServices from '../../../Services/Common.Service';
import SocialShareIcons from '../../common/SocialShareIcons';
import DetailsSpecs from '../../common/DetailsSpecs';
import DetailsMedia from '../../common/DetailsMedia';
import * as utils from '../../../utils/Common.Utils';
import * as SeoHelper from '../../../utils/SeoHelper';
import PopularProducts from '../../common/PopularProducts/PopularProducts';
import StarComponent from '../../common/StarComponent';
import ProductRatingAndReviews from '../Ratings&Reviews/ProductRatingAndReviews';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import DemoRequestModal from './DemoRequestModal';
import ShowAdminList from './ShowAdminList';
import SpcNotification from './SpcNotification';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDemoReqModal: false,
      showMessage: false,
      errorMessage: '',
      defaultTab: this.props.match.params.action && this.props.match.params.action.toUpperCase() === 'REVIEWS' ? '1' : '0',
      showModal: false,
      networkURL: Config.ENVIRONMENT === 'PN' ? 'https://sp.travelport.com/' : 'https://sp-pp.travelport.com/'
    };
  }

  componentDidMount() {
    document.title = 'Product Details';
    CommonServices.SSOProcessing(this.props.user);
    const pluginAssigment = new URLSearchParams(this.props.location.search).get('pluginAssigment');
    if (typeof (pluginAssigment) === 'string' && pluginAssigment.toUpperCase() === 'SUCCESS') {
      this.spcNotificationModalOpen();
    } else {
      const reviewsState = utils.getValueFromQueryString(this.props.location, 'actionname')?.toUpperCase() === 'CREATEORUPDATE' ? 'createOrEdit' : 'view';
      this.props.setReviewState(reviewsState);
    }
    const response = this.loadProduct();
    response.then((res) => {
      this.props.productLoadSuccess(res);
      if (res.applicationDetails && res.applicationDetails.platformId) {
        this.props.getAppsForPlatform(res.applicationDetails.platformId);
      }
      this.getRatingsAndReviews();
    }).catch((error) => {
      this.props.productLoadFailure(error);
      this.props.history.push('/products');
    });
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.closePopup !== this.props.closePopup) && this.props.closePopup) {
      this.hideDemoReqModal();
    }
  }

  getRatingsAndReviews() {
    const ratingResonse = this.loadProductRatingDetails();
    ratingResonse.then((res) => {
      this.props.productRatingLoadSuccess(res);
    }).catch((error) => {
      this.props.productRatingLoadFailure(error);
    });
    if (this.props.match.params.action && this.props.match.params.action.toUpperCase() === 'REVIEWS') {
      this.showProductReviews();
    }
  }

  getDetailSpecItems() {
    const specItems = [];
    let name = '';
    if (this.props.product.platformName) {
      name = 'Platform';
      specItems.push({ name, data: this.props.product.platformName });
    }
    if (this.props.product.hostNames) {
      name = this.props.product.hostNames.indexOf(',') !== -1 ? 'Hosts' : 'Host';
      specItems.push({ name, data: this.props.product.hostNames });
    }
    if (this.props.product.categories) {
      name = this.props.product.categories.indexOf(',') !== -1 ? 'Categories' : 'Category';
      specItems.push({ name, data: this.props.product.categories });
    }
    if (!this.props.product.isInternal && this.props.product.regionsSupported) {
      name = 'Regions Supported';
      specItems.push({ name, data: this.props.product.regionsSupported });
    }
    return specItems;
  }

  spcNotificationModalOpen = () => this.setState({ showModal: true });

  spcNotificationModalClose = () => this.setState({ showModal: false });

  getDeveloperName() {
    if (!this.props.product.isInternal) {
      return (
        <>
          by
          {' '}
          <a developerorgname={this.props.product.vendorPublisher} href={`/DeveloperDirectory/${SeoHelper.SEOFriendly(this.props.product.vendorPublisher)}`}>{this.props.product.vendorPublisher}</a>
        </>
      );
    }
    return '';
  }

  previousVersionDownloadClick = (event) => {
    event.preventDefault();
    const userIsLoggedIn = this.props.user && !this.props.user.expired;
    if (userIsLoggedIn) {
      this.props.directDownloadProduct(this.props.product.publicId, this.props.product.previousVersionNumber);
    } else {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
      window.location = `${Config.BASE_URL}login`;
    }
  }

  differentialDownloadClick = (event) => {
    event.preventDefault();
    const userIsLoggedIn = this.props.user && !this.props.user.expired;
    if (userIsLoggedIn) {
      this.props.directDownloadProduct(this.props.product.publicId, '', true);
    } else {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
      window.location = `${Config.BASE_URL}login`;
    }
  }

  handleCustomerReviewsTabSelect = (key) => {
    if (parseInt(key) === 0) {
      this.props.history.push(`/Product/${SeoHelper.SEOFriendly(this.props.product.name)}/Reviews`);
    }
    this.showProductReviews();
    this.setState({ defaultTab: key });
  }

  hideDemoReqModal = () => {
    this.setState({
      showDemoReqModal: false
    });
  }

  saveDemoRequest = (demoReq) => {
    this.props.saveDemoRequest(demoReq);
  }

  loadProduct() {
    this.props.productLoad();
    return utils.GetService(`${Config.BASE_URL}${Config.GET_PRODUCT_DETAILS}/${this.props.match.params.seoname}?isPreviewMode=${this.props.isPreview || false}`);
  }

  loadProductRatingDetails() {
    this.props.productRatingLoad();
    return utils.GetService(`${Config.BASE_URL}${Config.GET_APP_RATING_DETAILS}?appId=${this.props.product.publicId}`);
  }

  loadProductReviews() {
    this.props.productReviewsLoad();
    return utils.GetService(`${Config.BASE_URL}${Config.GET_APP_REVIEWS}?appId=${this.props.product.publicId}`);
  }

  gtmAppVendorDetails() {
    return `Solution:${this.props.product.name} / Developer:${this.props.product.vendorPublisher}`;
  }

  gtmAppVendorTriggerDetails() {
    return `Solution:${this.props.product.name} / $Developer:${this.props.product.vendorPublisher} / TriggerLocation:Product Details Page`;
  }

  orderButtonClick() {
    const userIsLoggedIn = this.props.user && !this.props.user.expired;
    if (userIsLoggedIn) {
      if (this.props.product.isInternal && !this.props.product.orderButtonURL) {
        this.props.directDownloadProduct(this.props.product.publicId);
      } else {
        this.props.history.push(this.props.product.orderButtonURL);
      }
    } else {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
      window.location = `${Config.BASE_URL}login`;
    }
  }

  orderSection() {
    let content;
    if (!this.props.product.infoMessage) {
      if (this.props.product.orderButtonText === 'Download') {
        content = <Link to="#" onClick={() => this.orderButtonClick()} className="green-button gtm-order-button black-button" orderbuttonaction={this.props.product.orderButtonText} gtm-event-label={this.gtmAppVendorDetails()}>{this.props.product.orderButtonText}</Link>;
      } else {
        content = <Link to="#" onClick={() => this.orderButtonClick()} className="green-button gtm-order-button" orderbuttonaction={this.props.product.orderButtonText} gtm-event-label={this.gtmAppVendorDetails()}>{this.props.product.orderButtonText}</Link>;
      }
    } else {
      content = (
        <div className="details-info">
          <span className="icon-info-circle" />
          <span className="details-info-message">{this.props.product.infoMessage}</span>
          <br />
          <br />
          {(!this.props.product.isUserInRoleToOrder && !(this.props.product.distributionMethods === 'Direct Download' && this.props.product.hasPreviousPurchase))
            && <ShowAdminList currentUserOrgId={this.props.product.currentUserOrgId} />}
          {(this.props.product.showDirectDownloadIcon
            || this.props.product.showIntegrationDownloadIcon)
            && this.downloadAndIntegrationSection()}
        </div>
      );
    }
    return (
      <div className="col-sm-4 details-main-button">
        {content}
      </div>
    );
  }

  header() {
    return (
      <div className="row details-main-info-row">
        <div className="col-sm-8 details-main-info">
          <img className="details-logo" src={this.props.product.logoUrl} alt={this.props.product.name} />
          <div className="details-name">
            <h2>{this.props.product.name}</h2>
            {this.getDeveloperName()}
          </div>
        </div>
        {this.orderSection()}
      </div>
    );
  }

  ratingLinks() {
    if (this.props.ratingLoading) {
      return '';
    }
    let ratingStars = '';
    if (this.props.rating.netRating !== 0) {
      ratingStars = <StarComponent size={22} edit={false} value={this.props.rating.netRating} color2="#4E6659" />;
    }
    const productReviewUrl = `/Product/${SeoHelper.SEOFriendly(this.props.product.name)}/Reviews`;
    return (
      <>
        <div className="left rating-star-div">{ratingStars}</div>
        <span className="pad-left pad-right border-separator-right">
          <Link to={productReviewUrl} onClick={() => this.reviewsSelection()}>
            {this.props.rating.totalCount}
            {' '}
            Review(s)
          </Link>
        </span>
        <span className="pad-left pad-right border-separator-right">
          <Link to={`${productReviewUrl}?actionName=createorupdate`} onClick={() => this.reviewsSelection()}>{this.props.rating.reviewButtonText}</Link>
        </span>
      </>
    );
  }

  reviewsSelection() {
    this.showProductReviews();
    this.setState({ defaultTab: '1' });
  }

  showMessage(message) {
    this.setState({ errorMessage: message, showMessage: true });
    setTimeout(() => {
      this.setState({ showMessage: false });
    }, 3000);
  }

  errorOrSuccessMessage(message) {
    toast.warning(message, {
      id: message.replaceAll(' ', '-'),
      position: 'top-center',
      showProgressBar: true
    });
  }

  requestDemoClick() {
    const userIsLoggedIn = this.props.user && !this.props.user.expired;
    if (userIsLoggedIn) {
      if (this.props.product.checkDemoStatus
        && this.props.product.userDetails
        && (this.props.product.userDetails.currentRoleId !== 9
          && this.props.product.userDetails.currentRoleId !== 11
          && this.props.product.userDetails.currentRoleId !== 13)
        && this.props.product.agencyDetails) this.setState({ showDemoReqModal: true });
      else {
        this.showMessage('This feature is not applicable on your current user role.');
      }
    } else {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
      window.location = `${Config.BASE_URL}login`;
    }
  }

  detailsSummaryHeader() {
    const recommendButton = (
      <a className="prod-detail-recommend gtm-order-button" orderbuttonaction="Recommend Product" href={`/recommend/${this.props.product.publicId}`} gtm-event-label={this.gtmAppVendorDetails()}>
        <span className="icon-recommend-product gtm-order-button" orderbuttonaction="Recommend Product" gtm-event-label={this.gtmAppVendorDetails()} />
        {' '}
        <span>Recommend</span>
        <br />
        <span style={{ paddingLeft: '16px' }}>Product</span>
      </a>
    );
    const internalTag = <span className="prod-detail-recommend">Internal</span>;
    const requestDemoButton = (
      <Link
        to="#"
        className="prod-detail-recommend blue-button-small gtm-order-button"
        orderbuttonaction="Request a Demo"
        onClick={(event) => { event.preventDefault(); this.requestDemoClick(); }}
        gtm-event-label={this.gtmAppVendorDetails()}
      >
        Request a Demo
      </Link>
    );
    return (
      <div className="row no-space-bottom">
        {this.state.showMessage
          && this.errorOrSuccessMessage(this.state.errorMessage)}
        <div className="details-summary-header">
          {this.ratingLinks()}
          {!this.props.product.isInternal && !this.props.product.checkDemoStatus ? recommendButton : ''}
          {this.props.product.isInternal ? internalTag : ''}
          {this.props.product.checkDemoStatus ? requestDemoButton : ''}
        </div>
      </div>
    );
  }

  downloadAndIntegrationSection() {
    const directDownloadIcon = (
      <Link
        className="green-button gtm-order-button"
        gtm-event-label={this.gtmAppVendorTriggerDetails()}
        id="direct-download"
        title="Download this solution direct from the Travelport Marketplace website."
        to="#"
        onClick={() => this.props.directDownloadProduct(this.props.product.publicId)}
      >
        Direct Download
      </Link>
    );
    const integrationDownloadIcon = (
      <Link
        className="green-button gtm-order-button"
        gtm-event-label={this.gtmAppVendorTriggerDetails()}
        id="automatic-install"
        title="Deliver this solution directly to Smartpoint via the Marketplace Integration, if you have it enabled."
        to={this.props.product.urlToAssignments}
      >
        Automatic Install
      </Link>
    );
    return (
      <div className="">
        {this.props.product.showDirectDownloadIcon ? directDownloadIcon : ''}
        {this.props.product.showIntegrationDownloadIcon ? integrationDownloadIcon : ''}
      </div>
    );
  }

  productVersion() {
    if (this.props.product.productVersion) {
      return (
        <>
          Product Version:
          {' '}
          {this.props.product.productVersion}
          <br />
        </>
      );
    }
    return '';
  }

  productLicense() {
    if (this.props.product.showLicenseType) {
      return (
        <>
          License:
          {' '}
          {this.props.product.licenseName}
          <br />
        </>
      );
    }
    return '';
  }

  productPrice() {
    if (this.props.product.showPricingText) {
      return (
        <>
          Price:
          <span id="product-price">{this.props.product.pricingText}</span>
          {this.props.product.pricingNotes ? (
            <>
              {this.props.product.pricingNotes}
            </>
          ) : ''}
          {this.props.product.trialPeriod ? <em>{this.props.product.trialPeriod}</em> : ''}
          <br />
        </>
      );
    }
    return '';
  }

  productKeywords() {
    if (this.props.product.keywords) {
      return (
        <>
          Keywords:
          {' '}
          {this.props.product.keywords}
          <br />
        </>
      );
    }
    return '';
  }

  productDistributionMethods() {
    if (this.props.product.distributionMethods && this.props.product.platformId !== 2) {
      return (
        <>
          Distribution Methods:
          {' '}
          {this.props.product.distributionMethods}
          <br />
        </>
      );
    }
    return '';
  }

  productDetailsSummary() {
    return (
      <p className="detail pad-bottom">
        {this.productVersion()}
        {this.productLicense()}
        {this.productPrice()}
        {this.productKeywords()}
        {this.productDistributionMethods()}
      </p>
    );
  }

  priceIcon() {
    if (this.props.product.isFree) {
      return (
        <span>
          <span className="inverse-text inverse-text--free">Free</span>
          &nbsp;
        </span>
      );
    }

    return (
      <>
        <span className="inverse-text inverse-text--paid">Paid</span>
        &nbsp;
        {this.props.product.hasTrialPeriod && (
          <span>
            <span className="inverse-text inverse-text--free">Free Trial</span>
            &nbsp;
          </span>
        )}
      </>
    );
  }

  cloudIcon() {
    if (this.props.product.platformId === 2) {
      return (
        <span>
          <span className="inverse-text inverse-text--free">Cloud</span>
          &nbsp;
        </span>
      );
    }
    return '';
  }

  tpCertIcon() {
    if (this.props.product.showTraveportCertifiedButton) {
      return (
        <span id="tp-certifited" className="inverse-text inverse-text--tp-cert" title="Successfully passed Cyber Security, PCI and Performance tests performed by Travelport">
          <span className="icon-travelport-certified" />
          {' '}
          Travelport Certified
        </span>
      );
    }
    return '';
  }

  priceAndSocialIcons() {
    return (
      <div className="no-margin">
        {this.props.product.showNewButton && (
          <span>
            <span className="inverse-text inverse-text--free-trial">New</span>
            &nbsp;
          </span>
        )}
        {this.priceIcon()}
        {this.tpCertIcon()}
        {this.cloudIcon()}
        <SocialShareIcons url={window.location.href} title={`Travelport Marketplace Product - ${this.props.product.name}`} />
      </div>
    );
  }

  detailsSummary() {
    return (
      <div className="col-xs-12 details-summary">
        <div className="details-summary-content">
          {this.detailsSummaryHeader()}
          {this.props.product.basicDescription ? <p className="pad-bottom">{this.props.product.basicDescription}</p> : ''}
          {this.productDetailsSummary()}
          {!this.props.product.isInternal && this.priceAndSocialIcons()}
        </div>
      </div>
    );
  }

  detailsSummarySection() {
    return (
      <div className="row details-summary-row">
        {this.detailsSummary()}
        <DetailsSpecs items={this.getDetailSpecItems()} />
      </div>
    );
  }

  screenshotsAndVideos() {
    if (this.props.product.hasVideos || this.props.product.hasScreenshots) {
      return (
        <DetailsMedia
          screenshotCarousels={this.props.product.screenshotCarouselUrls}
          screenshotPreviews={this.props.product.screenshotUrls}
          screenshotThumbnails={this.props.product.screenshotThumbnailUrls}
          videos={this.props.product.videoUrls}
        />
      );
    }
    return (
      <div className="col-xs-12 col-sm-4 col-sm-pull-8 details-media-col">
        <div className="details-media">
          <div id="product-screenshots">
            <div className="msg-media-unavailable">
              <img src={require('../../../Styles/img/prod-detail-media-template.svg')} alt="No Media Uploaded" />
              <span>No Media Uploaded</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  descriptionTab() {
    return (
      <p>
        {/* eslint-disable react/no-array-index-key */}
        {this.props.product.description
          ? this.props.product.description.split('\n').map((line, k) => (
            <React.Fragment key={k}>
              {line}
              <br />
            </React.Fragment>
          ))
          : 'No product description'}
      </p>
    );
  }

  customerReviewsTab() {
    return (
      <>
        {this.props.product.isInternal
          && <div className="pad-left pad-top">No Reviews</div>}
        {!this.props.product.isInternal
          && (
            <ProductRatingAndReviews
              productId={this.props.product.applicationId}
              productPublicId={this.props.product.publicId}
              canUserAccessRatingLinks={this.props.canAccessRatingLinks}
            />
          )}
      </>
    );
  }

  supportDocumentDownload(event, filename) {
    const response = utils.GetService(`${Config.BASE_URL}${Config.GET_PRODUCT_SUPPORT_DOCUMENT_URL}/${this.props.product.publicId}/${filename}`);

    response.then((res) => {
      const link = document.createElement('a');
      link.href = res;
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(res, 100));
    });

    event.preventDefault();
  }

  showProductReviews() {
    const reviewsResonse = this.loadProductReviews();
    reviewsResonse.then((res) => {
      this.props.productReviewsLoadSuccess(res);
    }).catch((error) => {
      this.props.productReviewsLoadFailure(error);
    });
  }

  supportTab() {
    return (
      <>
        <div className="row">
          <div className="col-sm-6 prod-detail-support-info">
            {(this.props.product.supportContactPerson
              || this.props.product.developerPhoneNumber) && (
                <>
                  <h3>Support Contact</h3>
                  <p>
                    {this.props.product.supportContactPerson}
                    <br />
                    <a href={`tel:${this.props.product.developerPhoneNumber}`}>{this.props.product.developerPhoneNumber}</a>
                  </p>
                </>
            )}
            {this.props.product.supportPageUrl && (
              <>
                <h3>Product Support/Contact Page</h3>
                <p>
                  <a className="gtm-product-support-page" gtm-event-label={this.gtmAppVendorDetails()} href={this.props.product.supportPageUrl}>{this.props.product.supportPageUrl}</a>
                </p>
              </>
            )}
            {this.props.product.supportEmailAddress && (
              <>
                <h3>Email Address</h3>
                <p>
                  <a className="gtm-product-support-email" gtm-event-label={this.gtmAppVendorDetails()} href={`mailto:${this.props.product.supportEmailAddress}`}>{this.props.product.supportEmailAddress}</a>
                </p>
              </>
            )}
          </div>
          {this.props.product.supportDocuments
            && this.props.product.supportDocuments.length > 0 ? (
              <div className="col-sm-6 prod-detail-support-docs">
                <h3>{this.props.product.supportDocumentsText}</h3>
                {this.props.product.supportDocuments.map(doc => (
                  <Link key={doc.id} className="prod-detail-support-doc-tile gtm-product-support-doc" gtm-event-label={this.gtmAppVendorDetails()} to="#" onClick={(e) => this.supportDocumentDownload(e, doc.fileName)}>
                    <span className="icon-general-download-file gtm-product-support-doc" gtm-event-label={this.gtmAppVendorDetails()} />
                    <span className="prod-detail-support-doc-desc gtm-product-support-doc" gtm-event-label={this.gtmAppVendorDetails()}>{doc.documentName}</span>
                  </Link>
                ))}
              </div>
            ) : <div className="col-sm-6">&nbsp;</div>}
          {this.props.product.displayIncrementalDifferencesFile && (
            <div className="col-sm-6 prod-detail-support-docs">
              <h3>Incremental Update File</h3>
              <ul>
                <li><Link to="#" onClick={this.differentialDownloadClick}>Download Incremental Update File</Link></li>
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }

  previousVersionTab() {
    return (
      <>
        <div className="row">
          <div className="col-lg-3">
            {
              this.props.product.orderButtonText === 'Download'
                ? (
                  <Link
                    className="green-button gtm-order-button black-button"
                    orderButtonAction={this.props.product.orderButtonText}
                    gtm-event-label={this.gtmAppVendorDetails()}
                    to="#"
                    onClick={this.previousVersionDownloadClick}
                  >
                    {this.props.product.orderButtonText}
                  </Link>
                )
                : (
                  <Link
                    className="green-button gtm-order-button"
                    orderButtonAction={this.props.product.orderButtonText}
                    gtm-event-label={this.gtmAppVendorDetails()}
                    to="#"
                    onClick={this.previousVersionDownloadClick}
                  >
                    {this.props.product.orderButtonText}
                  </Link>
                )
            }
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                Product Version :
                {`${this.props.product.previousVersionTitle}`}
              </li>
              <li>
                Release Date :
                {this.props.product.previousVersionReleaseDate}
              </li>
              <li>
                Version Build # :
                {this.props.product.previousVersionNumber}
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }

  tabsSection() {
    return (
      <div className="col-sm-12 prod-detail-tabs">
        <Tabs activeKey={this.state.defaultTab} id="faq-tabs" productseoname={this.props.product.name} onChange={(e) => this.handleCustomerReviewsTabSelect(e)}>
          <Tabs.Tab title="Description">
            <div className="tab-content tab-pane active">
              {this.descriptionTab()}
            </div>
          </Tabs.Tab>
          <Tabs.Tab title="Customer Reviews">
            <div className="tab-content reviewsPanel">
              {this.customerReviewsTab()}
            </div>
          </Tabs.Tab>
          {this.props.product.showSupportTab
            ? (
              <Tabs.Tab title="Support">
                <div className="tab-content tab-pane active">
                  {this.supportTab()}
                </div>
              </Tabs.Tab>
            ) : (<></>)}
          {this.props.product.displayPreviousVersion
            ? (
              <Tabs.Tab title="Previous Version">
                <div className="tab-content tab-pane active">
                  {this.previousVersionTab()}
                </div>
              </Tabs.Tab>
            ) : (<></>)}
        </Tabs>
      </div>
    );
  }

  prodDetailsFeatures() {
    let featuresSection = <span>&nbsp;</span>;
    if (this.props.product.productFeatures && this.props.product.productFeatures.length > 0) {
      featuresSection = (
        <>
          <h3>Key Product Features</h3>
          <ul>
            {this.props.product.productFeatures.map((feature, index) => <li key={index}>{feature}</li>)}
          </ul>
        </>
      );
    }
    return (
      <div className="col-sm-12 prod-detail-features">
        {featuresSection}
      </div>
    );
  }

  backButton() {
    return (
      <div className="row">
        <a href="#" onClick={() => this.props.history.push({ pathname: '/products', cleanFilter: false })} className="prod-detail-back-to-results">
          <svg viewBox="0 0 9 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M9,13L2.8,6.5,9,0H6.2L0,6.5,6.2,13H9Z" />
          </svg>
          Back to product results
        </a>
      </div>
    );
  }

  popularApplications() {
    if (this.props.popularApplicationsForPlatform && this.props.popularApplicationsForPlatform.length > 0) {
      const heading = this.props.product.platformName.indexOf('Stand Alone') === -1
        ? `Popular Products for ${this.props.product.platformName}`
        : `Popular ${this.props.product.platformName} Products`;
      return (
        <>
          <div className="text-center">
            <h2 className="popular-products-title">
              {heading}
            </h2>
          </div>
          <PopularProducts
            data={this.props.popularApplicationsForPlatform}
            showBrowseAll={false}
            gtmFeatured={false}
          />
        </>
      );
    }
    return '';
  }

  canonicalSection() {
    if (this.props.match.params.action && this.props.match.params.action.toUpperCase() === 'REVIEWS') {
      return (
        <Link rel="canonical" to={`/Product/${SeoHelper.SEOFriendly(this.props.product.name)}/Reviews`} />
      );
    }
    return <Link rel="canonical" to={`/Product/${SeoHelper.SEOFriendly(this.props.product.name)}`} />;
  }

  render() {
    if (this.props.loading) {
      return (
        <LoaderComponent show />
      );
    }
    if (!this.props.loading && this.props.product.name === '') {
      const userIsLoggedIn = this.props.user && !this.props.user.expired;
      if (this.props.isAppPublished) {
        if (userIsLoggedIn && !this.props.isAppVisibleToNonParticipantUsers) {
          return (
            <div className="container">
              <h2> Page Not Found</h2>
              <div className="large-alert row">
                You are not authorised to view the page
              </div>
            </div>
          );
        }
        if (!userIsLoggedIn && !this.props.isAppVisibleToNonParticipants) {
          return (
            <div className="container">
              <h2> Page Not Found</h2>
              <div className="large-alert row">
                Please sign in to check if you have access to the product
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="container">
            <h2> Page Not Found</h2>
            <div className="large-alert row">
              The product is unpublished hence not accessible to the public.
            </div>
          </div>
        );
      }
    }
    return (
      <>
        {this.canonicalSection()}
        <div className="container details-layout">
          <div className="row details-media-row">
            <div className="col-xs-12 col-sm-8 col-sm-push-4 details-main-info-col">
              {this.header()}
              {this.detailsSummarySection()}
            </div>
            {this.screenshotsAndVideos()}
          </div>
          <div className="row prod-detail-tabs-row" id="hash-details-tabs">
            {this.tabsSection()}
            {this.prodDetailsFeatures()}
          </div>
          {this.backButton()}
          <div className="row">
            {this.popularApplications()}
          </div>
          {
            this.state.showDemoReqModal
              ? (
                <DemoRequestModal
                  showDemoReqModal={this.state.showDemoReqModal}
                  hideDemoReqModal={this.hideDemoReqModal}
                  onSubmit={this.saveDemoRequest}
                />
              )
              : null
          }
          {(this.state.showModal && (
            <SpcNotification
              showModal={this.state.showModal}
              popUpHide={this.spcNotificationModalClose}
              NetworkURL={this.state.networkURL}
              productName={this.props.product.name}
              currentUserOrgId={this.props.product.currentUserOrgId}
              usersRole={this.props.user.profile.role}
            />
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product.details.data.applicationDetails || { name: '' },
  canAccessRatingLinks: state.product.details.data.canAccessRatingLinks,
  isAppPublished: state.product.details.data.isAppPublished,
  isAppVisibleToNonParticipants: state.product.details.data.isAppVisibleToNonParticipants,
  isAppVisibleToNonParticipantUsers: state.product.details.data.isAppVisibleToNonParticipantUsers,
  loading: state.product.details.loading,
  ratingLoading: state.product.rating.loading,
  rating: state.product.rating.data || { netRating: '' },
  reviews: state.product.reviews.reviewList.data,
  user: state.oidc.user,
  closePopup: state.product.details.closePopup,
  popularApplicationsForPlatform: popularApplicationsForPlatformSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  productLoad: bindActionCreators(productLoad, dispatch),
  productLoadSuccess: bindActionCreators(productLoadSuccess, dispatch),
  productLoadFailure: bindActionCreators(productLoadFailure, dispatch),
  productRatingLoad: bindActionCreators(productRatingLoad, dispatch),
  productRatingLoadSuccess: bindActionCreators(productRatingLoadSuccess, dispatch),
  productRatingLoadFailure: bindActionCreators(productRatingLoadFailure, dispatch),
  productReviewsLoad: bindActionCreators(productReviewsLoad, dispatch),
  productReviewsLoadSuccess: bindActionCreators(productReviewsLoadSuccess, dispatch),
  productReviewsLoadFailure: bindActionCreators(productReviewsLoadFailure, dispatch),
  setReviewState: bindActionCreators(setReviewState, dispatch),
  saveDemoRequest: (args) => dispatch(saveDemoRequest.base(args)),
  directDownloadProduct: (id, version, isDifferentialDownload) => dispatch(directDownloadProduct(id, version, isDifferentialDownload)),
  getAppsForPlatform: (platformId) => dispatch(getAppsForPlatform.base(platformId)),
  getAdminsForOrg: params => dispatch(getAdminsForOrgs.base(params)),
  loadUserInfo: () => dispatch(loadUserInfo.base()),
});

ProductDetails.propTypes = {
  productLoad: PropTypes.func,
  productLoadSuccess: PropTypes.func,
  productLoadFailure: PropTypes.func,
  productRatingLoad: PropTypes.func,
  productRatingLoadSuccess: PropTypes.func,
  productRatingLoadFailure: PropTypes.func,
  productReviewsLoad: PropTypes.func,
  productReviewsLoadSuccess: PropTypes.func,
  productReviewsLoadFailure: PropTypes.func,
  product: PropTypes.object,
  canAccessRatingLinks: PropTypes.bool,
  loading: PropTypes.bool,
  rating: PropTypes.object,
  reviews: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  productseoname: PropTypes.string,
  user: PropTypes.object,
  setReviewState: PropTypes.func,
  ratingLoading: PropTypes.bool,
  saveDemoRequest: PropTypes.func,
  loadUserInfo: PropTypes.func,
  closePopup: PropTypes.bool,
  directDownloadProduct: PropTypes.func,
  isPreview: PropTypes.bool,
  getAppsForPlatform: PropTypes.func,
  popularApplicationsForPlatform: PropTypes.array,
  isAppPublished: PropTypes.bool,
  isAppVisibleToNonParticipants: PropTypes.bool,
  isAppVisibleToNonParticipantUsers: PropTypes.bool,
  getAdminsForOrg: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetails));
