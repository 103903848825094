import {
  takeLatest, call, put, take, race
} from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';
import createFetchTypes from '../../../utils/createFetchTypes';
import fetchEntity from '../../../utils/fetchEntitySaga';
import ApiClient from '../../../../Services/ApiClient';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export const GET_INITIAL_CERTIFICATION_DATA = createFetchTypes('Marketplace/Administration/ManageApplicaitonCertification/GET_INITIAL_CERTIFICATION_DATA');
export const SAVE_CERTIFICATION_DATA = createFetchTypes('Marketplace/Administration/ManageApplicaitonCertification/SAVE_CERTIFICATION_DATA');
export const SAVE_CHECKED_DATA = 'Marketplace/Administration/ManageApplicaitonCertification/SAVE_CERTIFICATION_DATA';
export const DELETE_CERTIFICATION = createFetchTypes('Marketplace/Administration/ManageApplicationCertification/DELETE_CERTIFICATION');
export const UNCERTIFYAPP = createFetchTypes('Marketplace/Administration/ManageApplicaitonCertification/UNCERTIFYAPP');
export const DOWNLOAD_VERSION = 'Marketplace/Administration/ManageApplicaitonCertification/DOWNLOAD_VERSION';

const getCertificationProducts = (data) => {
  const certifiedPrds = data.filter((app) => app.appIsActivated === true).sort((a, b) => (a.appName.toLowerCase() < b.appName.toLowerCase() ? -1 : 1));
  const uncertifiedPrds = data.filter(app => app.appIsActivated === false).sort((a, b) => (a.appName.toLowerCase() < b.appName.toLowerCase() ? -1 : 1));
  return {
    certified: certifiedPrds,
    uncertified: uncertifiedPrds
  };
};

const manageApplicationCertificateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_INITIAL_CERTIFICATION_DATA.REQUEST:
    case SAVE_CERTIFICATION_DATA.REQUEST:
    case DELETE_CERTIFICATION.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_INITIAL_CERTIFICATION_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        applicationCertification: getCertificationProducts(action.payload.data)
      };
    case GET_INITIAL_CERTIFICATION_DATA.FAILURE:
    case SAVE_CERTIFICATION_DATA.FAILURE:
    case DELETE_CERTIFICATION.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SAVE_CERTIFICATION_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_CERTIFICATION.SUCCESS:
      return {
        ...state,
        loading: false,
        deletedAppId: action.payload.deletedAppId,
        deletedAppPrefix: action.payload.deletedAppPrefix
      };
    case 'SHOWERROR':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        showErrorModal: true
      };
    case 'CLEARERROR':
      return {
        ...state,
        errorMessage: '',
        showErrorModal: false
      };
    default:
      return state;
  }
};

export const getInitialCertification = {
  base: () => ({
    type: GET_INITIAL_CERTIFICATION_DATA.BASE,
    url: 'certifications/pending'
  }),
  request: () => ({ type: GET_INITIAL_CERTIFICATION_DATA.REQUEST }),
  success: (data) => ({
    type: GET_INITIAL_CERTIFICATION_DATA.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: GET_INITIAL_CERTIFICATION_DATA.FAILURE })
};

export const saveCertificationForm = {
  base: (data) => ({
    type: SAVE_CERTIFICATION_DATA.BASE,
    url: 'certifications/savepending',
    payload: {
      data
    }
  }),
  request: () => ({ type: SAVE_CERTIFICATION_DATA.REQUEST }),
  success: (data) => ({
    type: SAVE_CERTIFICATION_DATA.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: SAVE_CERTIFICATION_DATA.FAILURE })
};

export const showErrorMessage = {
  base: (data) => ({
    type: 'SHOWERROR',
    payload: data
  })
};

export const clearErrorMessage = {
  base: (data) => ({
    type: 'CLEARERROR',
    payload: data
  })
};

export const uncertifyForm = {
  base: (data) => ({
    type: UNCERTIFYAPP.BASE,
    url: 'certifications/uncertify',
    payload: {
      data
    }
  }),
  request: () => ({ type: UNCERTIFYAPP.REQUEST }),
  success: (data) => ({
    type: UNCERTIFYAPP.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: UNCERTIFYAPP.FAILURE })
};

export const onDeleteCertification = {
  base: data => ({
    type: DELETE_CERTIFICATION.BASE,
    url: 'certifications/removecertification',
    data
  }),
  request: () => ({ type: DELETE_CERTIFICATION.REQUEST }),
  success: payload => ({ type: DELETE_CERTIFICATION.SUCCESS, payload }),
  failure: error => ({ type: DELETE_CERTIFICATION.FAILURE, error }),
};
export const onDownloadVersion = (data) => ({
  type: DOWNLOAD_VERSION,
  data
});

export function* fetchManageAppCertificateDetailsSaga(action) {
  try {
    yield put(showLoader());
    yield call(() => fetchEntity(getInitialCertification, ApiClient.httpClient.get, action.url));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* saveCertificationSaga(action) {
  try {
    yield put(
      showConfirmationModal('Are you sure you want to certify?', '')
    );
    const { accept } = yield race({
      accept: take(CONFIRMATION_ACCEPTED),
      reject: take(CONFIRMATION_REJECTED)
    });
    if (accept) {
      yield put(showLoader());
      yield call(() => fetchEntity(saveCertificationForm, ApiClient.httpClient.post, action.url, action.payload.data));
      yield put(hideLoader());
      yield put(showAlertAction('Successfully Certified'));
      yield put(getInitialCertification.base());
    }
  } catch (error) {
    yield put(hideLoader());
  }
}

function* showConfirmationMessage(msg, submsg, action, data) {
  yield put(
    showConfirmationModal(msg, submsg)
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });
  if (accept) {
    yield put(showLoader());
    yield call(() => fetchEntity(uncertifyForm, ApiClient.httpClient.post, action.url, data));
    yield put(hideLoader());
    yield put(showAlertAction('Successfully Uncertified'));
    yield put(getInitialCertification.base());
  }
}
export function* uncertifyAppSaga(action) {
  try {
    const { data } = action.payload;

    if (data.integrationVersionId > 0 || data.directDownloadVersionId > 0) {
      if (data.appVerTotalCount === 1 && data.appIsActivated) {
        yield put(showErrorMessage.base({
          errorMessage: 'This product has only one certified version. Please uncertify the product first in order to uncertify the version or remove the version'
        }));
      } else {
        yield showConfirmationMessage('Are you sure you want to uncertify this version?', '', action, data);
      }
    } else {
      yield showConfirmationMessage('Are you sure you want to uncertify this product?', 'if you uncertify, corresponding download version will also be uncertified.', action, data);
    }
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* deleteCertificationSaga({ data }) {
  try {
    if (data.appVerTotalCount === 1 && data.appIsActivated && data.isActivated === true) {
      yield put(showErrorMessage.base({
        errorMessage: 'This product has only one certified version. Please uncertify the product first in order to uncertify the version or remove the version'
      }));
      return;
    }
    yield put(
      showConfirmationModal('Are you sure you want to delete this version?', 'Once the version is deleted, it will not be available.')
    );
    const { accept } = yield race({
      accept: take(CONFIRMATION_ACCEPTED),
      reject: take(CONFIRMATION_REJECTED)
    });

    if (accept) {
      yield put(showLoader());
      try {
        yield put(onDeleteCertification.request());
        yield call(() => ApiClient.httpClient.delete(onDeleteCertification.base().url, { params: { appVersionId: data.id, prefix: data.prefix } }));
        yield put(onDeleteCertification.success({
          deletedAppId: data.id,
          deletedAppPrefix: data.prefix,
          deletedAppName: data.name
        }));
        yield put(hideLoader());
        yield put(showAlertAction('Successfully deleted the version'));
      } catch (error) {
        yield put(showAlertAction(error && error.response && error.response.data));
      }
      yield put(hideLoader());
      yield put(getInitialCertification.base());
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    onDeleteCertification.failure({ error });
  }
}
export function* downloadProductVersionSaga({ data }) {
  const url = 'certifications/downloadurl';
  yield put(showLoader());
  try {
    const res = yield call(ApiClient.httpClient.get, url,
      {
        params: {
          appId: data.appId, versionId: data.versionId, version: data.version, type: data.type
        }
      });
    const link = document.createElement('a');
    link.href = res.data.downloadUrl || res.data;
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(res.data.downloadUrl || res.data, 100));
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
  yield put(hideLoader());
}

export function* watchFetchManageAppCertificateDetailsSaga() {
  yield takeLatest(GET_INITIAL_CERTIFICATION_DATA.BASE, fetchManageAppCertificateDetailsSaga);
}

export function* watchSaveCertificationSaga() {
  yield takeLatest(SAVE_CERTIFICATION_DATA.BASE, saveCertificationSaga);
}
export function* watchDeleteCertificationSaga() {
  yield takeLatest(DELETE_CERTIFICATION.BASE, deleteCertificationSaga);
}
export function* watchUnCertifyAppSaga() {
  yield takeLatest(UNCERTIFYAPP.BASE, uncertifyAppSaga);
}
export function* watchDownloadProductVersionSaga() {
  yield takeLatest(DOWNLOAD_VERSION, downloadProductVersionSaga);
}
export default manageApplicationCertificateReducer;
