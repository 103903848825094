/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IntegratedSorting,
  SortingState,
  TableColumnResizing
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';
import { fetchAgencyHierarchy, emulateAgency } from 'reducers/modules/Profile';
import { roleNameConstants } from 'utils/rolesHelper';
import LoaderComponent from '../../../common/Loader/LoaderComponent';
import RowComponent from './RowComponent';

class AgencyHierarchy extends Component {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'name', title: 'Organization Name' },
      { name: 'customerNumber', title: 'Customer #' },
      { name: 'pccs', title: 'PCC(s)' },
      { name: 'organizationType', title: 'Org Type' },
      { name: 'action', title: 'Action' },
    ];
    this.columnWidths = [
      { columnName: 'name', width: 300 },
      { columnName: 'customerNumber', width: 150 },
      { columnName: 'pccs', width: 500 },
      { columnName: 'organizationType', width: 140 },
      { columnName: 'action', width: 100 }
    ];
    this.sortingStateColumnExtensions = [
      { columnName: 'action', sortingEnabled: false },
    ];
  }

  componentDidMount() {
    if (this.props.agencyDetails && this.props.agencyDetails.id) {
      this.props.fetchAgencyHierarchy(
        {
          orgId: this.props.agencyDetails.id,
          customerNumber: this.props.agencyDetails.customerNumber
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    const previousAgencyId = prevProps.agencyDetails && prevProps.agencyDetails.id;
    const newAgencyId = this.props.agencyDetails && this.props.agencyDetails.id;
    if (newAgencyId !== previousAgencyId) {
      this.props.fetchAgencyHierarchy(
        {
          orgId: this.props.agencyDetails.id,
          customerNumber: this.props.agencyDetails.customerNumber
        }
      );
    }
  }

  getAllRowsWithAction = () => {
    const allRows = [...this.props.parents, ...this.props.children];
    const userIsInOwnProfile = window.location.href.toLowerCase().indexOf('myprofile') !== -1;
    const userIsSuperAgencyAdmin = this.props.currentUser.profile.role === roleNameConstants.AgencySuperAdmin.name;

    return allRows.map(r => {
      const row = { ...r, action: '' };
      if (userIsSuperAgencyAdmin && userIsInOwnProfile) {
        if (row.id !== this.props.agencyDetails.id) {
          row.action = <span><Link to="#" onClick={(event) => this.emulateAgency(event, row.id)}>Select</Link></span>;
        } else {
          row.action = <span>Selected</span>;
        }
      } else {
        row.action = <span>Select</span>;
      }
      if (row.id === this.props.agencyDetails.id) {
        row.isSelected = true;
      }
      return row;
    });
  }

  emulateAgency = (event, orgId) => {
    event.preventDefault();
    this.props.emulateAgency(orgId);
  }

  render() {
    if (this.props.agencyHierarchy.loading === undefined) {
      return <LoaderComponent show />;
    }
    return (
      <div className="row extra-small-font pad-left pad-right no-space">
        <div className="row ajax-dependent-div space-top-double">
          {/* Loader */}
          {(this.props.agencyHierarchy.loading)
            && <LoaderComponent show />}
          {/* Note */}
          <div className="col-sm-12 pad-left-sm">
            <strong>Note:</strong>
&nbsp;If you log out of Travelport Marketplace, when you log back in, your organization will be reset to your home organization.
          </div>
          {/* Agency Hierarchy Table */}
          <div className="row space-top-double">
            <div className="travelport-table-sticky gray-theme-table" id="organisation-table">
              <Grid columns={this.columnDefinitions} rows={this.getAllRowsWithAction()}>
                <SortingState columnExtensions={this.sortingStateColumnExtensions} />
                <IntegratedSorting />
                <VirtualTable height={400} rowComponent={RowComponent} />
                <TableColumnResizing
                  columnWidths={this.columnWidths}
                />
                <TableHeaderRow showSortingControls />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agencyHierarchy: state.profile.agency.agencyHierarchy,
  parents: (state.profile.agency.agencyHierarchy.data && state.profile.agency.agencyHierarchy.data.parents) || [],
  children: (state.profile.agency.agencyHierarchy.data && state.profile.agency.agencyHierarchy.data.children) || [],
  agencyDetails: state.profile.userProfile.agencyDetails,
  currentUser: state.oidc.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAgencyHierarchy: (args) => dispatch(fetchAgencyHierarchy.base(args)),
  emulateAgency: (args) => dispatch(emulateAgency.base(args))
});

AgencyHierarchy.propTypes = {
  fetchAgencyHierarchy: PropTypes.func,
  emulateAgency: PropTypes.func,
  agencyHierarchy: PropTypes.object,
  agencyDetails: PropTypes.object,
  currentUser: PropTypes.object,
  parents: PropTypes.array,
  children: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyHierarchy);
