import { select } from 'redux-saga/effects';

/* SAGA SELECTOR */

const communicationSelector = state => state.form.communication;

export function* createCommunicationRequest() {
  const communicationState = yield select(communicationSelector);
  return communicationState.values;
}
