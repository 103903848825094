import { put } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from '../../../../../Services/ApiClient';
import { BASE_URL, API_PREFIX } from '../../../../../config/apiConfig';

export const UPDATE_AGENCY_USER = 'UPDATE_AGENCY_USER';
const UPDATE_AGENCY_USER_SUCCESS = 'UPDATE_AGENCY_USER_SUCCESS';
const UPDATE_AGENCY_USER_FAILURE = 'UPDATE_AGENCY_USER_FAILURE';

export const updateAgencyUser = data => ({
  type: UPDATE_AGENCY_USER,
  payload: {
    ...data
  }
});

const updateAgencyUserSuccess = () => ({
  type: UPDATE_AGENCY_USER_SUCCESS
});

const updateAgencyUserFailure = error => ({
  type: UPDATE_AGENCY_USER_FAILURE,
  payload: {
    error
  }
});
export const agencyUserUpdateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_AGENCY_USER:
      return {
        loading: true,
        error: null
      };

    case UPDATE_AGENCY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case UPDATE_AGENCY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* updateAgencyUserWatcher(action) {
  yield put(showLoader());
  try {
    const entityData = { ...action.payload.entityData };
    const tmpObj = {
      userId: entityData.userId,
      roleId: Number(entityData.roles[0].value)
    };
    const url = `${BASE_URL}${API_PREFIX}/profile/saveagencymemberrole`;
    yield ApiClient.httpClient.put(url, tmpObj);
    // yield call(() => fetchEntity(updateUserRoles, ApiClient.httpClient.put, url, params));
    // yield ApiClient.httpClient.put('http://localhost:44366/api/profile/saveagencymemberrole', tmpObj);
    // yield ApiClient.agencyUser.update(tmpObj);
    yield put(updateAgencyUserSuccess());
    yield put(showAlertAction('Changes to the user has been saved successfully'));
    action.payload.callback();
  } catch (error) {
    console.error(error);
    yield put(updateAgencyUserFailure());
    yield put(showAlertAction(error.message));
  }
  yield put(hideLoader());
}
export default agencyUserUpdateReducer;

export const roleIdSelector = state => state.profile.userProfile.userDetails.currentRoleIds[0];
