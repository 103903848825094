import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@atlas-design-system/react';
import StarComponent from '../../common/StarComponent';
import RatingCountPresentation from './RatingCountPresentation';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';

const RatingPresentation = (props) => {
  const { rating, onTriggerReviewAction } = props;
  return (
    <div className="col-sm-3 ratings-summary">
      <div className="row">
        <StarComponent size={25} edit={false} value={rating.netRating} color2="#4E6659" />
      </div>
      <div className="row">
        {rating.totalCount}
        {' '}
        reviews |
        {rating.netRating}
        {' '}
        out of 5
      </div>
      <div className="rating-splits">
        <RatingCountPresentation text="5 stars" starCount={rating.fiveStarCount} totalCount={rating.totalCount} />
        <RatingCountPresentation text="4 stars" starCount={rating.fourStarCount} totalCount={rating.totalCount} />
        <RatingCountPresentation text="3 stars" starCount={rating.threeStarCount} totalCount={rating.totalCount} />
        <RatingCountPresentation text="2 stars" starCount={rating.twoStarCount} totalCount={rating.totalCount} />
        <RatingCountPresentation text="1 stars" starCount={rating.oneStarCount} totalCount={rating.totalCount} />
      </div>
      <div className="row pad-top-double">
        <Button className="atls-blue-button" href="#" onClick={onTriggerReviewAction}>{rating.reviewButtonText}</Button>
      </div>
    </div>
  );
};

RatingPresentation.propTypes = {
  rating: PropTypes.object,
  onTriggerReviewAction: PropTypes.func
};

export default RatingPresentation;
