import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { roleNameConstants } from 'utils/rolesHelper';

const Reports = (props) => {
  const isValidUser = props.currentUser && props.currentUser.profile && !props.currentUser.expired;
  const isUserSuperAdmin = isValidUser && (props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name);
  const isUserTPAdmin = isValidUser && (props.currentUser.profile.role === roleNameConstants.TPAdmin.name);
  const isSupportUser = isValidUser && (props.currentUser.profile.role === roleNameConstants.TPSupport.name);
  return (
    <>
      <h2>Reports</h2>
      <div className="container">
        <ul>
          {(isUserSuperAdmin)
            && (
            <li>
              <Link to="/admin/reports/productReviews">Product Reviews</Link>
            </li>
            )}
          {
            (isUserSuperAdmin || isUserTPAdmin || isSupportUser)
            && (
            <li>
              <Link to="/admin/reports/userLoginReport">User Login Report</Link>
            </li>
            )
          }
          {
            (isUserSuperAdmin)
            && (
            <li>
              <Link to="/admin/reports/userFeedbackReport">User Feedback Report</Link>
            </li>
            )
          }
          {
            (isUserSuperAdmin || isUserTPAdmin || isSupportUser)
            && (
            <li>
              <Link to="/admin/reports/devStatsReport">Developer Statistics Report</Link>
            </li>
            )
          }
        </ul>
      </div>
    </>
  );
};

Reports.propTypes = {
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  currentUser: state.oidc.user
});

export default connect(mapStateToProps, null)(Reports);
