/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const RemoveEntityPopUp = (props) => (
  <Modal
    id="details-product-remove-video-modal"
    title={props.title}
    size="small"
    onDismiss={props.popUpHide}
    hidden={false}
  >
    <div className="space-left-double space-right-double">
      <p>
        {props.bodyContent}
      </p>
    </div>
    <div className="text-center">
      <Link
        to="#"
        id="remove-video-link"
        onClick={(e) => {
          e.preventDefault();
          props.remove();
        }}
        className="action-button"
      >
        Remove
      </Link>
      <Link
        to="#"
        id="remove-video-link-cancel"
        onClick={(e) => {
          e.preventDefault();
          props.popUpHide();
        }}
        className="cancel-button space-left"
      >
        Cancel
      </Link>
    </div>
  </Modal>
);

export default RemoveEntityPopUp;

RemoveEntityPopUp.propTypes = {
  popUpHide: PropTypes.func,
  remove: PropTypes.func,
  title: PropTypes.string.isRequired,
  bodyContent: PropTypes.string.isRequired
};
