/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { AES, enc } from 'crypto-js';
import './CookiePopup.less';

var cnames = '';
const cryptokey = 'cryptokey';
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  cnames = AES.encrypt(cname, cryptokey).toString();
  document.cookie = `${cnames}=${cvalue};${expires};path=/`;
};

const getCookie = (cname) => {
  const cookies = document.cookie.split(';');
  const cnamesd = AES.decrypt(cname, cryptokey).toString(enc.Utf8);
  const cookieExist = cookies.find(row => row.trim().startsWith(`${cnamesd.toString()}=`));
  return cookieExist && cookieExist.split('=')[1];
};

const CookiePopup = () => {
  const [cookies, setCookies] = useState(getCookie(cnames.toString()) === 'true');
  return !cookies && (
    <div className="cookie_popup">
      <div className="cookie_box">
        <div className="cookie_content">
          <span className="contentText1">
            To offer a better experience we use cookies, including those of third parties.
            <br />
            To learn more and manage your choices, read the
            {' '}
            <a href="/cookiepolicy" target="_blank" style={{ color: '#159CDF', textDecorationLine: 'underline' }}>cookie policy</a>
            {' '}
            and
            {' '}
            {' '}
            <a href="https://www.travelport.com/privacy" target="_blank" style={{ color: '#159CDF', textDecorationLine: 'underline' }}>privacy policy</a>
            .
            {' '}
          </span>
        </div>
        <button type="button" className="cookie_button green-button" onClick={() => { setCookie('COOKIE_ACCEPTED', true, 180); setCookies(true); }}>
          ok
        </button>
      </div>
    </div>

  );
};

export default CookiePopup;
