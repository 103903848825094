/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import { createVerification } from 'reducers/modules/Administration';
import dateHelper from '../../../utils/dateHelper';
import LoaderComponent from '../../common/Loader/LoaderComponent';

class CreateVerification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: '',
    };
  }

  updateOrderNumberWithValidation = (e, orderNumber) => {
    e.preventDefault();
    this.setState({ orderNumber: e.target.validity.valid ? e.target.value : orderNumber });
  }

  confirm = (e) => {
    const { createVerificationAction, buildRow } = this.props;
    e.preventDefault();
    createVerificationAction(this.updateOrderNumberJSON(buildRow));
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  updateOrderNumberJSON(buildRow) {
    const { popUpHide } = this.props;
    return {
      changeOrderNumber: this.state.orderNumber,
      verifiedByText: buildRow.verifiedUserName || '',
      posVersionId: this.props.posVersionId || 0,
      buildId: buildRow.id || 0,
      onSuccess: popUpHide,
      onFail: popUpHide,
    };
  }

  render() {
    const {
      showModal,
      buildRow,
      loading,
      firstname,
      lastname
    } = this.props;
    return (
      <>
        <Modal
          id="sbModal"
          title={`${buildRow.versionNumber} : Verification`}
          hidden={!showModal}
          size="small"
          onDismiss={this.cancel}
        >
          <div className="modal-custom-text space-top">
            <p>You must have an approved Change Order to make this product available to customers.</p>
            <div className="space-top custom-line-height">
              <p>Please enter the Change Order number below:</p>
              <input
                type="text"
                pattern="[0-9]*"
                id="CreateVerification"
                className="text-input custom-input-text"
                placeholder="Ex:52253"
                name="CreateVerification"
                onChange={(e) => this.updateOrderNumberWithValidation(e, this.state.orderNumber)}
                value={this.state.orderNumber}
              />
              <p>*Change Order number is required.</p>
            </div>
            <p className="space-top custom-line-height">
              <strong>Note:</strong>
              By clicking SAVE below, you agree that you have obtained un-conditional
              approval for the Change Order listed above and that you performing this release
              activity only within in the time window as specified in the Change Order.
            </p>
          </div>
          <div className="div-inline space-top-double custom-line-height">
            <div className="two-columns">
              {/* Verified By : {`${firstname && firstname} ${lastname && lastname}`} */}
              Verified By :
              {' '}
              {`${firstname} ${lastname}`}
            </div>
            <div className="two-columns">
              Verified On :
              {' '}
              {dateHelper.getDateTime(Date.now())}
            </div>
          </div>
          {(loading) && <LoaderComponent show />}
          <div className="text-center space-top-double">
            <button type="button" className="action-button no-save" onClick={this.confirm}>SAVE</button>
            <button type="button" className="cancel-button space-left no-save" onClick={this.cancel}>CANCEL</button>
          </div>
        </Modal>
      </>
    );
  }
}

CreateVerification.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  buildRow: PropTypes.object,
  createVerificationAction: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  posVersionId: PropTypes.number,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
};

const mapStateToProps = state => ({
  loading: state.administration.buildVersionControl.loading,
  error: state.administration.buildVersionControl.error,
  posVersionId: state.administration.buildVersionControl.posVersionId,
  firstname: state.oidc.user.profile.FirstName,
  lastname: state.oidc.user.profile.LastName,
});

const mapDispatchToProps = dispatch => ({
  createVerificationAction: (data) => dispatch(createVerification.base(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateVerification);
