import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  input,
  type,
  textAreaClass,
  textValue,
  maxLength,
  labelName,
  message,
  labelClass,
  placeholder,
  showTitleCount
}) => {
  const calculateTotal = () => {
    const characters = input.value.split('').length;
    const total = maxLength - parseInt(characters, 0);
    return total;
  };

  return (
    <>
      <div className="row">
        <label htmlFor="none" className={labelClass}>
          {labelName}
        </label>
        <label htmlFor="none" className="label-h4">
          <span id="Title_counter" className="max-chars">
            {showTitleCount && (
            <span id="Title_count">
              {calculateTotal()}
              {' '}
            </span>
            )}
            {message}
          </span>
          <span id="title-char-count" />
        </label>
        <textarea type={type} value={textValue} {...textValue || { ...input }} className={textAreaClass} maxLength={maxLength} placeholder={placeholder} />
      </div>
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  labelName: PropTypes.string,
  maxLength: PropTypes.number,
  message: PropTypes.string,
  textAreaClass: PropTypes.string,
  labelClass: PropTypes.string,
  input: PropTypes.object,
  type: PropTypes.string,
  meta: PropTypes.object,
  textValue: PropTypes.string,
  placeholder: PropTypes.string,
  showTitleCount: PropTypes.bool
};
TextArea.defaultProps = {
  labelName: '',
  labelClass: '',
  textValue: '',
  showTitleCount: true
};
