import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { roleNameConstants } from 'utils/rolesHelper';
import AgencyDetailsInfo from './AgencyDetailsInfo';
import AgencyDetailsPccs from './AgencyDetailsPccs';
import AgencyDetailsHost from './AgencyDetails/AgencyDetailsHost';
import {
  saveAgencyDetails,
  canAgencyDownload,
} from '../../../reducers/modules/Profile/userProfileReducer';
import LoaderComponent from '../../common/Loader/LoaderComponent';

class AgencyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editAgencyProfile: false,
      errorMessage: '',
      showErrorMessage: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(props) {
    if (props.editMode === false) {
      this.setState({
        editAgencyProfile: false
      });
    }
  }

  changeToDetailView = () => {
    this.setState({
      editAgencyProfile: false
    });
  };

  changeToFormView = () => {
    this.setState({
      editAgencyProfile: true
    });
  };

  canAgentDownload = data => {
    this.props.canAgencyDownloadAction({ id: this.props.agencyDetails.id, candownload: !data });
  };

  formSubmit = agencyFormData => {
    const agencyFormDataTmp = agencyFormData;
    agencyFormDataTmp.username = this.props.userData.username;
    this.props.saveAgencyDetails(agencyFormDataTmp);
  };

  render() {
    let { agencyDetails } = this.props;
    if (this.props.match.path === '/Profile/AgencyProfile/:id') {
      agencyDetails = { ...this.props.agencyDetailsProfile, ...this.props.agencyDetails }; // this.props.agencyDetailsProfile;
    }

    const orgName = agencyDetails ? agencyDetails.name : '';

    let showAgencyDetailsHost = false;
    if (this.props.hostEntries) {
      showAgencyDetailsHost = true;
    }

    if (this.props.match.path === '/Profile/UserProfile/:username') {
      agencyDetails.userProfile = true;
    }
    if (this.props.match.path === '/Profile/AgencyProfile/:id') {
      showAgencyDetailsHost = false;
      agencyDetails.userProfile = true;
    }

    if (!agencyDetails) return <LoaderComponent show />;

    return (
      <>
        <div
          className="tab-pane"
          id="agency-details-tab-content"
          style={{ display: 'block' }}
        >
          <div
            className="row ajax-dependent-div standard-padding"
            id="agency-details-container"
          >
            <div className="row" id="agency-details-agency-name-block">
              <div className="col-sm-11" id="agency-details-agency-name">
                {orgName}
              </div>
            </div>
            <div className="col-sm-6 pad-right-sm">
              <AgencyDetailsInfo
                {...agencyDetails}
                canView={this.props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name
                  || this.props.currentUser.profile.role === roleNameConstants.TPSupport.name
                  || this.props.currentUser.profile.role === roleNameConstants.AgencyAdmin.name}
                canAgentDownloadHandler={this.canAgentDownload}
                currentRoleId={this.props.userDetailsFull.currentRoleId}
              />
            </div>
            <div className="col-sm-6 pad-left-sm">
              <AgencyDetailsPccs {...agencyDetails} />
              {showAgencyDetailsHost ? (
                <AgencyDetailsHost hostEntries={this.props.hostEntries} />
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AgencyDetails.propTypes = {
  editMode: PropTypes.bool,
  agencyDetails: PropTypes.object,
  agencyDetailsProfile: PropTypes.object,
  loadUserProfileAction: PropTypes.func,
  getUserProfileAction: PropTypes.func,
  userData: PropTypes.object,
  hostEntries: PropTypes.array,
  saveAgencyDetailsLogo: PropTypes.func,
  saveAgencyDetails: PropTypes.func,
  match: PropTypes.object,
  canAgencyDownloadAction: PropTypes.func,
  currentUser: PropTypes.object,
  userDetailsFull: PropTypes.array
};

const mapStateToProps = state => ({
  editMode: state.profile.userProfile.editMode,
  agencyDetails: state.profile.userProfile.agencyDetails,
  agencyDetailsProfile: state.profile.getAgencyProfile.data,
  userData: state.profile.userProfile.userDetails,
  hostEntries: state.profile.fullProfile.hostEntries,
  agencyDetailsFull: state.profile.fullProfile.agencyDetails,
  userDetailsFull: state.profile.fullProfile.userDetails,
  currentUser: state.oidc.user
});

const mapDispatchToProps = dispatch => ({
  saveAgencyDetails: args => dispatch(saveAgencyDetails.base(args)),
  canAgencyDownloadAction: (args) => dispatch(canAgencyDownload.base(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgencyDetails));
