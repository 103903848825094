/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { React_Bootstrap_Carousel as ReactBootstrapCarousel } from 'react-bootstrap-carousel';
import { Modal } from '@atlas-design-system/react';
import playButton from '../../Styles/img/youtube-style-play-button-md.png';

/* eslint-disable react/no-array-index-key */
class DetailsMedia extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.showCarouselModal = this.showCarouselModal.bind(this);
    this.hideCarouselModal = this.hideCarouselModal.bind(this);
    this.state = { selectedIndex: 0, showOpenScreenshotOverlay: false, showCarouselModal: false };
  }

  setSelectedIndex(index) {
    this.setState({ selectedIndex: index });
  }

  getVideoAndScreenshotThumbnailSlides() {
    const videoSlides = this.props.videos
      ? this.props.videos.map((video, index) => <li key={index}>{this.thumbnailVideoSlide(video, index)}</li>) : [];
    const screenshotSlides = this.props.screenshotThumbnails
      ? this.props.screenshotThumbnails.map((screenshotThumbnail, index) => (
        <li key={this.videosCount() + index}>
          {this.thumbnailScreenshotSlide(screenshotThumbnail, this.videosCount() + index)}
        </li>
      )) : [];
    return [...videoSlides, ...screenshotSlides];
  }

  getVideoAndScreenshotSlides() {
    const videoSlides = this.props.videos
      ? this.props.videos.map((video, index) => <React.Fragment key={index}>{this.videoSlide(video)}</React.Fragment>) : 0;
    const screenshotSlides = this.props.screenshotCarousels
      ? this.props.screenshotCarousels.map((screenshotCarousel, index) => (
        <React.Fragment key={this.videosCount() + index}>
          {this.screenshotSlide(screenshotCarousel)}
        </React.Fragment>
      )) : [];
    return [...videoSlides, ...screenshotSlides];
  }

  getVideoAndScreenshotPreviewSlides() {
    const videoSlides = this.props.videos
      ? this.props.videos.map((video, index) => <div key={index}>{this.videoPreviewSlide(video, index)}</div>) : 0;
    const screenshotSlides = this.props.screenshotPreviews
      ? this.props.screenshotPreviews.map((screenshotPreview, index) => <div key={this.videosCount() + index}>{this.screenshotSlide(screenshotPreview)}</div>) : [];
    return [...videoSlides, ...screenshotSlides];
  }

  showOpenScreenshotOverlay() {
    this.setState({ showOpenScreenshotOverlay: true });
  }

  hideOpenScreenshotOverlay() {
    this.setState({ showOpenScreenshotOverlay: false });
  }

  showCarouselModal() {
    this.setState({ showCarouselModal: true });
  }

  hideCarouselModal() {
    this.setState({ showCarouselModal: false });
  }

  goToSlide(index) {
    this.slider.goToSlide(index);
  }

  videosCount() {
    return this.props.videos ? this.props.videos.length : 0;
  }

  thumbnailVideoSlide(video, index) {
    return (
      <a
        id="carousel-selector"
        onClick={() => {
          this.setSelectedIndex(index);
          this.goToSlide(index);
        }}
        className={`carousel-selector-image ${this.state.selectedIndex === index ? 'selected' : ''}`}
        style={{ backgroundImage: `url('//img.youtube.com/vi/${video}/1.jpg'` }}
      >
        <div className="disabled-image-overlay" />
      </a>
    );
  }

  thumbnailScreenshotSlide(screenshotThumbnail, index) {
    return (
      <a
        id="carousel-selector"
        onClick={() => {
          this.setSelectedIndex(index);
          this.goToSlide(index);
        }}
        className={`carousel-selector-image ${this.state.selectedIndex === index ? 'selected' : ''}`}
        style={{ backgroundImage: `url('${screenshotThumbnail.value}')` }}
      >
        <div className="disabled-image-overlay" />
      </a>
    );
  }

  videoSlide(video) {
    return (
      <>
        <img className="fakeYouTubePlayButton" src={playButton} alt="play" />
        <img className="screenshot" style={{ zIndex: -1 }} src={`//img.youtube.com/vi/${video}/0.jpg`} alt="youtube" />
      </>
    );
  }

  screenshotSlide(screenshotCarousel) {
    return <img className="screenshot" src={screenshotCarousel.value} alt={screenshotCarousel.key} />;
  }

  videoPreviewSlide(video, index) {
    return (
      <div className="item">
        <div className="youtube-canvas-modal-container">
          <div className="youtube-canvas">
            <iframe
              width="640"
              height="390"
              style={{
                height: '100%', width: '100%', 'max-width': '100%', border: 0
              }}
              frameBorder="0"
              src={`//www.youtube.com/embed/${video}?hl=en&amp;autoplay=0&amp;
cc_load_policy=0&amp;loop=0&amp;iv_load_policy=3&amp;fs=1&amp;showinfo=0&amp;enablejsapi=1&amp;listType=user_uploads&amp;modestbranding=1`}
              title={index}
            />
          </div>
        </div>
      </div>
    );
  }

  screenshotAndVideoThumbnails() {
    return (
      <div id="screenshotPreview">
        <ul className="list-inline">
          {this.getVideoAndScreenshotThumbnailSlides().map((item) => item)}
        </ul>
      </div>
    );
  }

  screenshotsAndVideos() {
    return (
      <div id="product-screenshots">
        <div
          id="screenshotCarousel"
          onClick={this.showCarouselModal}
          onMouseEnter={() => this.showOpenScreenshotOverlay()}
          onMouseLeave={() => this.hideOpenScreenshotOverlay()}
          className="carousel slide"
          data-interval="false"
        >
          <div
            className="carousel-inner"
            role="listbox"
          >
            <ReactBootstrapCarousel
              animation
              autoplay={false}
              slideshowSpeed={10000}
              indicators={false}
              ref={r => { this.slider = r; }}
              className="carousel-fade"
            >
              {this.getVideoAndScreenshotSlides().map((item) => item)}
            </ReactBootstrapCarousel>
          </div>
          <span className="open-screenshots" style={{ display: this.state.showOpenScreenshotOverlay ? 'inline' : 'none' }} />
        </div>
        {this.screenshotAndVideoThumbnails()}
      </div>
    );
  }

  modal() {
    const leftIcon = <span className="sr-only">Previous</span>;
    const rightIcon = <span className="sr-only">Next</span>;
    // css issue
    return (
      <Modal
        id="modalCarousel"
        title="Product Gallery"
        hidden={!this.state.showCarouselModal}
        onDismiss={this.hideCarouselModal}
        size="medium"
        className="tp-details-media"
      >
        <div id="modalScreenshotCarousel">
          <div className="carousel-inner imgSize">
            <ReactBootstrapCarousel
              animation
              defaultActiveIndex={this.state.selectedIndex}
              autoplay={false}
              slideshowSpeed={10000}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
              indicators={false}
              ref={r => { this.sliderLarge = r; }}
              className="carousel-fade"
            >
              {this.getVideoAndScreenshotPreviewSlides().map((item) => item)}
            </ReactBootstrapCarousel>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <div className="col-xs-12 col-sm-4 col-sm-pull-8 details-media-col">
          <div className="details-media">
            {this.screenshotsAndVideos()}
          </div>
        </div>
        {this.modal()}
      </>
    );
  }
}

DetailsMedia.propTypes = {
  videos: PropTypes.array,
  screenshotCarousels: PropTypes.array,
  screenshotPreviews: PropTypes.array,
  screenshotThumbnails: PropTypes.array,
};

export default DetailsMedia;
