/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import RemoveEntityPopUp from './RemoveEntityPopUp';
import ProfileImagePopup from './ProfileImagePopUp';

const SmallSizeImage = (props) => (
  <Link
    to="#"
    id="add-product-logo"
    className="add-logo-prompt"
    onClick={(e) => {
      e.preventDefault();
      props.addScreenShot();
    }}
  >
    <span>
      <i>+</i>
      Add
      {' '}
      <br />
      Product Logo
    </span>
  </Link>
);

const NormalSizeImage = (props) => (
  <Link
    to="#"
    className="add-screenshot-prompt"
    onClick={(e) => {
      e.preventDefault();
      props.addScreenShot();
    }}
  >
    <span>
      <i>+</i>
      Add Screenshot
    </span>
    {props.optional
      ? (
        <>
          <br />
          <em>(Optional)</em>
        </>
      ) : null}
  </Link>
);

const AddScreenShotImage = (props) => {
  if (props.hasScreenshot) {
    return null;
  }
  return (
    (!props.isSmallImage)
      ? <NormalSizeImage optional={props.optional} addScreenShot={() => props.addScreenShot()} /> : <SmallSizeImage addScreenShot={() => props.addScreenShot()} />
  );
};

const ScreenShot = (props) => {
  if (props.hasScreenshot) {
    if (!props.isSmallImage) {
      return (
        <div>
          <img src={props.screenshotThumbnailUrl} alt="" />
          <input type="hidden" value={props.screenshotPreviewUrl} id="screenshot1-previewurl" />
          <span className="screenshot-title" id="screenshot1-title">{props.screenshotTitle}</span>
          <div className="add-screenshot-actions">
            {!props.isOptional
              ? (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    props.addScreenShot();
                  }}
                >
                  Replace
                </Link>
              )
              : (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    props.removeScreenShot();
                  }}
                >
                  Remove
                </Link>
              )}
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                props.showPreviewPopUp();
              }}
            >
              Preview
            </Link>
          </div>
        </div>
      );
    }
    if (props.isSmallImage) {
      return (
        <div className="add-logo-tile">
          <img src={props.screenshotThumbnailUrl} alt="" />
          <div className="add-logo-actions">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                props.addScreenShot();
              }}
              id="logo-actions-remove"
            >
              Replace
            </Link>
          </div>
        </div>
      );
    }
  }
  return null;
};

const PreviewScreenShot = (props) => {
  if (props.showPreviewPopUp) {
    return (
      <Modal
        id="sbModal"
        hidden={!props.showPreviewPopUp}
        title="Preview"
        size="medium"
        onDismiss={props.popUpHide}
        className="tp-atlas-modal-img"
      >
        <div>
          <img id="img-preview" alt="" src={props.screenshotPreviewUrl} />
          {/* <div id="img-name">{props.screenshotTitle}</div> */}
        </div>
      </Modal>
    );
  }
  return null;
};

const ImagePopup = (props) => {
  if (props.showUploadPopUp) {
    return (
      <ProfileImagePopup
        validationTypeUploadPopup={props.validationTypeUploadPopup}
        acceptedFormat={props.uploadPopupAcceptedFormat}
        acceptedImageSize={props.uploadPopupAcceptedImgSize}
        showModal={props.showUploadPopUp}
        modalTitle={props.modalTitle}
        onHide={() => props.hideUploadPopUp()}
        submitImageUpload={(image, title = '') => props.saveScreenShot(image, title)}
        titleRequired={props.uploadPopupTitleRequired}
      />
    );
  }
  return null;
};

const PreviewPopup = (props) => {
  if (props.showPopup) {
    return (
      <RemoveEntityPopUp
        title="Remove screenshot"
        bodyContent="Are you sure you want to remove this item?"
        remove={() => props.remove()}
        popUpHide={() => props.popUpHide()}
      />
    );
  }
  return null;
};

class AddScreenShot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: props.hasScreenshot || false,
      showPopup: false,
      showPreviewPopUp: false,
      showUploadPopUp: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        isEdit: nextProps.hasScreenshot || false,
        showPopup: false,
        showPreviewPopUp: false,
        showUploadPopUp: false
      });
    }
  }

  removeScreenShot = () => {
    this.setState({
      showPopup: true
    });
  }

  saveScreenShot = (image, title = '') => {
    this.props.saveScreenShot(image, title);
    this.hideUploadPopUp();
  }

  addScreenShot() {
    this.setState({
      showUploadPopUp: true
    });
  }

  hideUploadPopUp() {
    this.setState({ showUploadPopUp: false });
  }

  showPreviewPopUp() {
    this.setState({
      showPreviewPopUp: true
    });
  }

  render() {
    return (
      <div className={this.props.isSmallImage ? 'add-logo-tile' : 'add-screenshot-tile'}>
        <AddScreenShotImage
          isSmallImage={this.props.isSmallImage}
          addScreenShot={() => this.addScreenShot()}
          optional={this.props.optional}
          hasScreenshot={this.props.hasScreenshot}
        />
        <ScreenShot
          screenshotThumbnailUrl={this.props.screenshotThumbnailUrl}
          screenshotPreviewUrl={this.props.screenshotPreviewUrl}
          screenshotTitle={this.props.screenshotTitle}
          addScreenShot={() => this.addScreenShot()}
          removeScreenShot={this.removeScreenShot}
          hasScreenshot={this.props.hasScreenshot}
          isSmallImage={this.props.isSmallImage}
          showPreviewPopUp={() => this.showPreviewPopUp()}
          isOptional={this.props.optional}
        />
        <PreviewPopup
          showPopup={this.state.showPopup}
          removeScreenShot={this.removeScreenShot}
          title={this.props.title}
          bodyContent={this.props.bodyContent}
          popUpHide={() => this.setState({ showPopup: false })}
          remove={() => {
            this.setState({ showPopup: false });
            this.props.removeScreenShot();
          }}
        />
        <PreviewScreenShot
          showPreviewPopUp={this.state.showPreviewPopUp}
          screenshotPreviewUrl={this.props.screenshotPreviewUrl}
          screenshotTitle={this.props.screenshotTitle}
          popUpHide={() => this.setState({ showPreviewPopUp: false })}
        />
        <ImagePopup
          showUploadPopUp={this.state.showUploadPopUp}
          modalTitle={this.props.modalTitle}
          hideUploadPopUp={() => this.hideUploadPopUp()}
          saveScreenShot={(image, title = '') => this.saveScreenShot(image, title)}
          validationTypeUploadPopup={this.props.validationTypeUploadPopup}
          uploadPopupAcceptedFormat={this.props.uploadPopupAcceptedFormat}
          uploadPopupAcceptedImgSize={this.props.uploadPopupAcceptedImgSize}
          uploadPopupTitleRequired={this.props.uploadPopupTitleRequired}
        />
      </div>
    );
  }
}

ImagePopup.propTypes = {
  ...AddScreenShot.propTypes
};

PreviewPopup.propTypes = {
  ...AddScreenShot.propTypes
};

AddScreenShotImage.propTypes = {
  ...AddScreenShot.propTypes
};

SmallSizeImage.propTypes = {
  ...AddScreenShot.propTypes
};

NormalSizeImage.propTypes = {
  ...AddScreenShot.propTypes
};

ScreenShot.propTypes = {
  ...AddScreenShot.propTypes
};

PreviewScreenShot.propTypes = {
  screenshotPreviewUrl: PropTypes.string,
  popUpHide: PropTypes.func,
  showPreviewPopUp: PropTypes.bool,
  screenshotTitle: PropTypes.string
};

AddScreenShot.propTypes = {
  screenshotThumbnailUrl: PropTypes.string,
  screenshotPreviewUrl: PropTypes.string,
  hasScreenshot: PropTypes.bool,
  screenshotTitle: PropTypes.string,
  previewScreenShot: PropTypes.func,
  saveScreenShot: PropTypes.func,
  removeScreenShot: PropTypes.func,
  isSmallImage: PropTypes.bool,
  title: PropTypes.string,
  bodyContent: PropTypes.string,
  modalTitle: PropTypes.string,
  validationTypeUploadPopup: PropTypes.bool,
  validationDocFile: PropTypes.bool,
  uploadPopupAcceptedFormat: PropTypes.string,
  uploadPopupAcceptedImgSize: PropTypes.string,
  uploadPopupTitleRequired: PropTypes.bool,
  optional: PropTypes.bool
};

AddScreenShot.defaultProps = {
  isSmallImage: false,
  validationTypeUploadPopup: false,
  validationDocFile: false,

  uploadPopupTitleRequired: false,
  optional: false,
  modalTitle: 'Upload Agency Logo'
};
export default AddScreenShot;
