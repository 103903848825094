/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import * as SeoHelper from 'utils/SeoHelper';
import PropTypes from 'prop-types';
import ManageAdmins from './ManageAdmins/ManageAdmins';

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChild: false,
      childLoaded: false
    };
  }

  getClassNameForChildItem = () => {
    if (this.state.showChild) {
      return 'child-org-tr';
    }
    return 'hidden';
  };

  handeleChangeIsDisplayed = item => {
    const tmpItem = item;
    tmpItem.isDisplayed = !item.isDisplayed;
    this.setState({ item: tmpItem });
    this.props.onSetVisiblity(item);
  };

  handleViewClicked = (e, item) => {
    e.preventDefault();
    this.props.onViewClicked(item.id, item.name);
  }

  displayItem = (item) => {
    if (item.isDisplayed === null) {
      return (<span>N/A</span>);
    }
    return (
      <SimplePrettyCheckBox
        value={item.isDisplayed}
        onChange={() => {
          this.handeleChangeIsDisplayed(item);
        }}
      />
    );
  }

  hasChild = row => {
    if (row.hasChildOrganisations) {
      this.state.childLoaded = false;
      return (
        <td className="td-organizationdirectory-carat-width">
          <a
            href=""
            className={this.state.showChild ? 'hidden' : ''}
            onClick={e => {
              e.preventDefault();
              if (!this.state.childLoaded) {
                if (this.props.item.child) {
                  this.props.item.child.splice(0, this.props.item.child.length);
                }
                this.props.onGetChild(this.props.item);
              }
              this.setState({ showChild: true, childLoaded: true });
            }}
          >
            { this.state.showChild && !this.props.currentRow ? <span className="glyphicon glyphicon-chevron-down" /> : <span className="glyphicon glyphicon-chevron-right" />}
          </a>
          <a
            href=""
            className={this.state.showChild ? '' : 'hidden'}
            onClick={e => {
              e.preventDefault();
              this.setState({ showChild: false });
            }}
          >
            { this.state.showChild && this.props.currentRow ? <span className="glyphicon glyphicon-chevron-down" /> : <span className="glyphicon glyphicon-chevron-right" />}

          </a>
        </td>
      );
    }

    return <td className="td-organizationdirectory-carat-width" />;
  };

  renderChild = item => {
    if (typeof item.child !== 'undefined') {
      return item.child.map(childItem => (
        <React.Fragment key={childItem.publicGuid}>
          <tr className={this.getClassNameForChildItem()}>
            <td className="td-organizationdirectory-carat-width" />
            <td>
              <Link to={`/Profile/AgencyProfile/${childItem.id}`}>
                {childItem.name}
                {' '}
              </Link>
            </td>
            <td className="">{childItem.customerNumber}</td>
            <td>
              <ManageAdmins organization={childItem} />
            </td>
            <td>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  return this.props.onViewClicked(
                    childItem.id,
                    childItem.name
                  );
                }}
              >
                View
              </a>
            </td>
            <td className="">{childItem.country}</td>
            <td>
              <span className="">{childItem.organizationType}</span>
            </td>
            <td className="text-center ">
              {' '}
              {childItem.primaryAgency}
            </td>
            <td className="text-center">
              {this.displayItem(this.props.item)}
            </td>
            <td>
              {childItem.organizationType === 'Developer' ? <Link to={`/DeveloperDirectory/${SeoHelper.SEOFriendly(childItem.name)}`}>View</Link> : <span>N/A</span>}
            </td>
          </tr>
        </React.Fragment>
      ));
    }
    return null;
  };

  render() {
    return (
      <>
        <tr
          className={
            typeof this.props.item.child !== 'undefined' && this.state.showChild
              ? 'parent-org-expanded-tr '
              : ''
          }
        >
          {this.hasChild(this.props.item)}
          <td>
            <Link to={`/Profile/AgencyProfile/${this.props.item.id}`}>
              {this.props.item.name}
            </Link>
          </td>
          <td className="">{this.props.item.customerNumber}</td>
          <td>
            <ManageAdmins
              key={this.props.item.id}
              organization={this.props.item}
            />
          </td>
          <td>
            <a
              href="#"
              onClick={e => this.handleViewClicked(e, this.props.item)}
            >
              View
            </a>
          </td>
          <td className="">{this.props.item.country}</td>
          <td>
            <span className="">{this.props.item.organizationType}</span>
          </td>
          <td className="text-center ">
            {' '}
            {this.props.item.primaryAgency}
          </td>
          <td className="text-center">
            {this.props.item.isDisplayed === null
              ? <span>N/A</span>
              : (
                <SimplePrettyCheckBox
                  value={this.props.item.isDisplayed}
                  onChange={() => {
                    this.handeleChangeIsDisplayed(this.props.item);
                  }}
                />
              )}
          </td>
          <td>
            {this.props.item.organizationType === 'Developer' ? <Link to={`/DeveloperDirectory/${SeoHelper.SEOFriendly(this.props.item.name)}`}>View</Link> : <span>N/A</span>}
          </td>
        </tr>
        {this.renderChild(this.props.item)}
      </>
    );
  }
}

ListItem.propTypes = {
  item: PropTypes.object,
  currentRow: PropTypes.bool,
  onGetChild: PropTypes.func,
  onSetVisiblity: PropTypes.func,
  onViewClicked: PropTypes.func
};

export default ListItem;
