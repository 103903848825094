/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import '../../../Styles/datepicker.css';
import MpDatePicker from '../../common/FormInputs/Datepicker/MpDatePicker';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import DateTimeHelper from '../../../utils/dateHelper';

const renderCheckbox = ({ input, displayText }) => (
  <SimplePrettyCheckBox
    {...input}
    displayText={displayText}
    onChange={input.onChange}
  />
);

class FilterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.datehandler = this.datehandler.bind(this);
  }

  clearSearch = () => {
    this.props.reset();
    this.props.resetHandler();
  };

  datehandler = (e, field) => {
    this.props.datehandler(e, field);
  };

  render() {
    const { productList, handleSubmit } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          id="filter-report-generator"
          className="product-download-filter-report row"
        >
          <div className="row">
            <div className="col-sm-3 col-sm-offset-1">
              <Field
                name="productId"
                className="select full-width"
                component="select"
              >
                {productList.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-sm-4">
              <div className="col-sm-2 text-right space-top-sm">
                <label htmlFor="startDate">From:&nbsp;</label>
              </div>
              <div className="col-sm-4">

                <MpDatePicker
                  name="startDate"
                  value={this.props.startDate}
                  onChange={e => this.datehandler(e, 'startDate')}
                  isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                />
              </div>
              <div className="col-sm-2 text-right space-top-sm">
                <label htmlFor="endDate">To:&nbsp;</label>
              </div>
              <div className="col-sm-4">
                <MpDatePicker
                  name="endDate"
                  value={this.props.endDate}
                  onChange={e => this.datehandler(e, 'endDate')}
                  isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                />
              </div>
            </div>
            <div className="col-sm-1 col-sm-offset-2 pad-left">
              <button
                type="button"
                onClick={this.clearSearch}
                className="action-button"
              >
                Clear
              </button>
            </div>
          </div>
          <div className="row no-space">
            <div className="col-sm-9">
              <div className="col-sm-2 text-right space-top-sm">
                <label htmlFor="searchString">Search:&nbsp;</label>
              </div>
              <div className="col-sm-3">
                <Field
                  component="input"
                  type="text"
                  className="text-input text-left"
                  name="searchString"
                  placeholder="Search"
                />
              </div>
              <div className="col-sm-6 space-left pad-left">
                <Field
                  name="includeOrders"
                  displayText="Orders"
                  type="checkbox"
                  component={renderCheckbox}
                />
                <Field
                  name="includeDownloads"
                  displayText="Downloads"
                  type="checkbox"
                  component={renderCheckbox}
                />
                <Field
                  name="excludeTPEmployees"
                  displayText="Exclude Travelport Employees"
                  type="checkbox"
                  component={renderCheckbox}
                />
                <Field
                  name="includeDemos"
                  displayText="Demos"
                  type="checkbox"
                  component={renderCheckbox}
                />
                <Field
                  name="excludeAutoUpdates"
                  displayText="Exclude SP Auto Downloads / Updates"
                  type="checkbox"
                  className="exclude-autoupdate"
                  component={renderCheckbox}
                />
              </div>
            </div>
            <div className="col-sm-1 col-sm-offset-1 pad-left">
              <button
                className="action-button"
                type="submit"
                to="#"
                onClick={handleSubmit}
              >
                &nbsp;Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FilterForm.propTypes = {
  productList: PropTypes.array,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  resetHandler: PropTypes.func,
  datehandler: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

renderCheckbox.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  checked: PropTypes.bool
};

export default reduxForm({
  form: 'salesReportfilter',
  initialValues: {
    productId: 0,
    searchString: '',
    includeDemos: true,
    includeDownloads: true,
    includeOrders: true,
    excludeTPEmployees: true,
    excludeAutoUpdates: true
  }
})(FilterForm);
