/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios/index';
import ApiClient from '../../../Services/ApiClient';
import { SP_CLOUD_API_ENDPOINT } from '../../../config/apiConfig';
import createFetchTypes from '../../utils/createFetchTypes';
import { showLoader, hideLoader } from '../../popUps/loaderReducer';

const SAVE_PLUGIN_ASSIGNMENT_DATA = createFetchTypes(
    'Marketplace/SAVE_PLUGIN_ASSIGNMENT_DATA'
);

export const savePluginAssignmentData = {
    base: (data, successHandler) => ({
        type: SAVE_PLUGIN_ASSIGNMENT_DATA.BASE,
        data,
        url: 'spwebservice/api/Plugin/AddSpPluginAssignment',
    successHandler
    }),
    request: () => ({ type: SAVE_PLUGIN_ASSIGNMENT_DATA.REQUEST }),
    success: data => ({ type: SAVE_PLUGIN_ASSIGNMENT_DATA.SUCCESS, data }),
    failure: error => ({ type: SAVE_PLUGIN_ASSIGNMENT_DATA.FAILURE, error })
};

export default function gitSPCAssignmentInfo(state = {}, action = {}) {
    switch (action.type) {
         case SAVE_PLUGIN_ASSIGNMENT_DATA.BASE:
            return {
                ...state,
            };
         case SAVE_PLUGIN_ASSIGNMENT_DATA.SUCCESS:
            return {
                ...state,
                getPluginAssigment: action.data // we will remove this later
            };
        case SAVE_PLUGIN_ASSIGNMENT_DATA.FAILURE:
            return {
                ...state,
                errorList: action.error
            };
        default:
            return state;
    }
}

export function* savePluginAssignmentSaga(action) {
  yield put(showLoader());
  try {
    const params = action.data;
    const baseUrl = SP_CLOUD_API_ENDPOINT;
    const customhttpConfig = {
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    ApiClient.httpClient = Axios.create(customhttpConfig);
    const response = yield ApiClient.httpClient.post(action.url, params);
    if (response.data || response.status === 200) {
        response.data = 'Success';
        yield put(savePluginAssignmentData.success(response.data));
    } else if (response.statusText !== 'OK') {
        const messages = undefined;
        yield put(savePluginAssignmentData.failure(messages));
    }
        action.successHandler();
        yield put(hideLoader());
    } catch (error) {
            let message;
            if (error.response.data !== undefined) {
                message = error.response.data;
            } else {
                message = 'Some error occured while processing the request';
            }
            yield put(savePluginAssignmentData.failure(message));
            action.successHandler();
            yield put(hideLoader());
        }
}

export function* watchSavePluginAssigmentSaga() {
    yield takeLatest(SAVE_PLUGIN_ASSIGNMENT_DATA.BASE, savePluginAssignmentSaga);
}
