import {
  takeLatest, call, put, race, take, select
} from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import fetchEntity from '../../../utils/fetchEntitySaga';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';
import {
  CONFIRMATION_ACCEPTED, CONFIRMATION_REJECTED, showConfirmationModal,
} from '../../../popUps/confirmationModalReducer';

const FETCH_POS_DISTRIBUTION_VM_URL = 'distribution/GetPOSDistibutionViewModels';
const CREATE_POS_VERSION_URL = 'distribution/saveposversion';
const UPDATE_POS_VERSION_URL = 'distribution/updateposversion';
const DELETE_POS_VERSION_URL = 'distribution/deleteposversion';
const TOGGLE_PUBLISH_URL = 'publish/tppospublish';
const SAVE_POS_NAME_URL = 'distribution/SavePOSProductName';

const FETCH_POS_DISTRIBUTION_VM = createFetchTypes('Marketplace/Administration/productVersionControl/GetPOSDistibutionViewModels');
const CREATE_POS_VERSION = createFetchTypes('Marketplace/Administration/productVersionControl/SavePOSVersion');
const UPDATE_POS_VERSION = createFetchTypes('Marketplace/Administration/productVersionControl/UpdatePOSVersion');
const DELETE_POS_VERSION = createFetchTypes('Marketplace/Administration/productVersionControl/DeletePOSVersion');
const TOGGLE_PUBLISH = createFetchTypes('Marketplace/Administration/productVersionControl/TogglePublish');
const SAVE_POS_NAME = createFetchTypes('Marketplace/Administration/productVersionControl/SavePOSProductName');

export const getPosDistributionViewModels = state => state.administration.productVersionControl.data;
export const isProductVersionControlLoading = state => state.administration.productVersionControl.loading;

function productVersionControl(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_POS_DISTRIBUTION_VM.REQUEST:
    case CREATE_POS_VERSION.REQUEST:
    case UPDATE_POS_VERSION.REQUEST:
    case DELETE_POS_VERSION.REQUEST:
    case SAVE_POS_NAME.REQUEST:
    case TOGGLE_PUBLISH.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_POS_DISTRIBUTION_VM.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.posDistributionViewModels,
        error: null
      };
    case CREATE_POS_VERSION.SUCCESS:
    case UPDATE_POS_VERSION.SUCCESS:
    case DELETE_POS_VERSION.SUCCESS:
    case SAVE_POS_NAME.SUCCESS:
    case TOGGLE_PUBLISH.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };
    case FETCH_POS_DISTRIBUTION_VM.FAILURE:
    case CREATE_POS_VERSION.FAILURE:
    case UPDATE_POS_VERSION.FAILURE:
    case DELETE_POS_VERSION.FAILURE:
    case SAVE_POS_NAME.FAILURE:
    case TOGGLE_PUBLISH.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export const fetchPosDistributionViewModels = {
  base: () => ({
    type: FETCH_POS_DISTRIBUTION_VM.BASE,
    payload: {
      apiUrl: FETCH_POS_DISTRIBUTION_VM_URL
    }
  }),
  request: () => ({
    type: FETCH_POS_DISTRIBUTION_VM.REQUEST
  }),
  success: (data) => ({
    type: FETCH_POS_DISTRIBUTION_VM.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_POS_DISTRIBUTION_VM.FAILURE,
    payload: {
      error
    }
  })
};

export const createPosVersion = {
  base: (params) => ({
    type: CREATE_POS_VERSION.BASE,
    payload: {
      params,
      apiUrl: CREATE_POS_VERSION_URL
    }
  }),
  request: () => ({
    type: CREATE_POS_VERSION.REQUEST
  }),
  success: (data) => ({
    type: CREATE_POS_VERSION.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: CREATE_POS_VERSION.FAILURE,
    payload: {
      error
    }
  })
};

export const updatePosVersion = {
  base: (params) => ({
    type: UPDATE_POS_VERSION.BASE,
    payload: {
      params,
      apiUrl: UPDATE_POS_VERSION_URL
    }
  }),
  request: () => ({
    type: UPDATE_POS_VERSION.REQUEST
  }),
  success: (data) => ({
    type: UPDATE_POS_VERSION.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: UPDATE_POS_VERSION.FAILURE,
    payload: {
      error
    }
  })
};

export const deletePosVersion = {
  base: (params) => ({
    type: DELETE_POS_VERSION.BASE,
    payload: {
      params,
      apiUrl: DELETE_POS_VERSION_URL
    }
  }),
  request: () => ({
    type: DELETE_POS_VERSION.REQUEST
  }),
  success: (data) => ({
    type: DELETE_POS_VERSION.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: DELETE_POS_VERSION.FAILURE,
    payload: {
      error
    }
  })
};

export const savePosName = {
  base: (params, callback) => ({
    type: SAVE_POS_NAME.BASE,
    payload: {
      params,
      callback,
      apiUrl: SAVE_POS_NAME_URL
    }
  }),
  request: () => ({
    type: SAVE_POS_NAME.REQUEST
  }),
  success: (data) => ({
    type: SAVE_POS_NAME.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: SAVE_POS_NAME.FAILURE,
    payload: {
      error
    }
  })
};

export const togglePublish = {
  base: (params) => ({
    type: TOGGLE_PUBLISH.BASE,
    payload: {
      params,
      apiUrl: TOGGLE_PUBLISH_URL
    }
  }),
  request: () => ({
    type: TOGGLE_PUBLISH.REQUEST
  }),
  success: (data) => ({
    type: TOGGLE_PUBLISH.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: TOGGLE_PUBLISH.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchPosDistributionViewModelsSaga(action) {
  try {
    const { apiUrl } = action.payload;
    yield call(() => fetchEntity(fetchPosDistributionViewModels, ApiClient.httpClient.get, apiUrl));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchFetchPosDistributionViewModelsSaga() {
  yield takeLatest(FETCH_POS_DISTRIBUTION_VM.BASE, fetchPosDistributionViewModelsSaga);
}

export function* createPosVersionSaga(action) {
  try {
    const { params, apiUrl } = action.payload;
    yield call(() => fetchEntity(createPosVersion, ApiClient.httpClient.post, apiUrl, params));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchCreatePosVersionSaga() {
  yield takeLatest(CREATE_POS_VERSION.BASE, createPosVersionSaga);
}

export function* updatePosVersionSaga(action) {
  try {
    const { params, apiUrl } = action.payload;
    yield call(() => fetchEntity(updatePosVersion, ApiClient.httpClient.put, apiUrl, { newName: params.version, id: params.id }));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchUpdatePosVersionSaga() {
  yield takeLatest(UPDATE_POS_VERSION.BASE, updatePosVersionSaga);
}

export function* deletePosVersionSaga(action) {
  const { params, apiUrl } = action.payload;
  let confirmDeletion = true;
  if (params) {
    if (params.hasAssociatedBuild) {
      yield put(showConfirmationModal('Delete Version', 'This Version has builds associated with it. Are you sure you want to delete this version from Marketplace?'));
    } else yield put(showConfirmationModal('Are you sure you want to delete this version?'));

    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    confirmDeletion = accept;
  }
  if (confirmDeletion) {
    try {
      yield call(() => fetchEntity(deletePosVersion, ApiClient.httpClient.delete, `${apiUrl}/${params.id}`));
    } catch (error) {
      put(showAlertAction(error));
    }
  }
}

export function* watchDeletePosVersionSaga() {
  yield takeLatest(DELETE_POS_VERSION.BASE, deletePosVersionSaga);
}

export function* savePosNameSaga(action) {
  const { params, callback, apiUrl } = action.payload;
  try {
    yield put(savePosName.request());
    const response = yield call(ApiClient.httpClient.post, apiUrl, params);
    const posDistributionViewModels = yield select(getPosDistributionViewModels);
    const vmWithNewProductName = posDistributionViewModels.map(product => {
      if (product.productGuid === params.productGuid) {
        return { ...product, productName: params.newName };
      }
      return product;
    });
    yield put(savePosName.success(vmWithNewProductName));
    yield put(showAlertAction(response.data.responseMessage));
  } catch (error) {
    let responseMessage = '';
    if (error.response && error.response.data) {
      responseMessage = error.response.data.responseMessage;
    }
    yield put(savePosName.failure(responseMessage || error));
    yield put(showAlertAction(responseMessage || error));
  }
  callback();
}

export function* watchSavePosNameSaga() {
  yield takeLatest(SAVE_POS_NAME.BASE, savePosNameSaga);
}

export function* togglePublishSaga(action) {
  const { params, apiUrl } = action.payload;
  yield put(showConfirmationModal(`Are you sure you want to ${params.publish ? 'publish' : 'unpublish'} this product?`));
  const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
  if (accept) {
    const posDistributionViewModels = yield select(getPosDistributionViewModels);
    const currentPosVm = posDistributionViewModels.find((p) => p.productId === params.id);
    try {
      yield put(togglePublish.request());
      const response = yield call(ApiClient.httpClient.post, apiUrl, params);
      const vmWithNewProductName = posDistributionViewModels.map(product => {
        if (product.productId === params.id) {
          return { ...product, isPublished: response.data.isPublished };
        }
        return product;
      });
      yield put(togglePublish.success(vmWithNewProductName));
      yield put(showAlertAction(`${currentPosVm.productName} has been successfully ${params.publish ? 'Published' : 'Unpublished'}.`));
    } catch (error) {
      yield put(togglePublish.failure(error.response.data));
      yield put(showAlertAction(error.response.data));
    }
  }
}

export function* watchTogglePublishSaga() {
  yield takeLatest(TOGGLE_PUBLISH.BASE, togglePublishSaga);
}

export default productVersionControl;
