/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import {
  fetchBasicInformationDetails,
  updateCurrentForm
} from 'reducers/modules/Application/index';
import { FormHeader } from 'components/Application';
import PropTypes from 'prop-types';
import TextArea from 'components/common/TextArea';
import ToolTip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';
import * as ppcp from '../../../constants/PPCP';
import { getBasicInformationData } from '../../../reducers/modules/Application/BasicInformation/selector';
import LoaderComponent from '../../common/Loader/LoaderComponent';

const maxCharsMessage = () => 'characters left';

class BasicInformation extends Component {
  componentDidMount() {
    this.props.updateCurrentFormAction();
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = this.props.location.search.toLowerCase().substring(1);
    this.props.fetchBasicInformationDetails({ publicId, organizationId });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dirty !== this.props.dirty) {
      this.props.updateCurrentFormAction(this.props.dirty);
    }
  }

  render() {
    if (this.props.initialValues === undefined) {
      return <LoaderComponent show />;
    }
    return (
      <form>
        {this.props.loading && <LoaderComponent show />}
        <FormHeader
          header="Basic Information"
          infoText="Enter information about your product, and include details that make your solution special."
        />
        <Field
          component={TextArea}
          name="title"
          labelName="Product Title"
          maxLength={50}
          textAreaClass="text-input-textarea"
          labelClass="required-field-star"
          message={maxCharsMessage()}
        />
        <div className="row no-space-bottom">
          <label className="required-field-star" htmlFor="none">
            Product Features
            {' '}
            <ToolTip labelName="Provide a minimum of three distinguishing characteristics for the product. (e.g. a single, integrated solution)" />
          </label>
          <p>
            <em>Minimum of three bullets are required for submission.</em>
          </p>
        </div>
        <Field component={TextArea} name="productFeatures[0]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <Field component={TextArea} name="productFeatures[1]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <Field component={TextArea} name="productFeatures[2]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <Field component={TextArea} name="productFeatures[3]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <Field component={TextArea} name="productFeatures[4]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <Field component={TextArea} name="productFeatures[5]" maxLength={140} textAreaClass="text-input-textarea" message={maxCharsMessage()} />
        <div className="row">
          <label htmlFor="ShortDescription" className="required-field-star">
            Product Short Description
            {' '}
            <ToolTip labelName="A brief summary of your product. What does it do? Who is it for? Limit 180 characters (including spaces)" />
          </label>
          <Field component={TextArea} name="shortDescription" maxLength={180} textAreaClass="textarea small-textarea" message={maxCharsMessage()} />
        </div>
        <div className="row">
          <label htmlFor="LongDescription" className="required-field-star">
            Product Long Description
            {' '}
            <ToolTip labelName={'Expands on Short Description. Add other details you feel important. Limit 5000 characters (including spaces). '
              + 'You have the option of styling your content using bold, italic and features lists.'}
            />
          </label>
          <Field component={TextArea} name="longDescription" maxLength={5000} textAreaClass="textarea" message={maxCharsMessage()} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: getBasicInformationData(state).data,
  loading: getBasicInformationData(state).loading
});

const mapDispatchToProps = (dispatch) => ({
  fetchBasicInformationDetails: (args) => dispatch(fetchBasicInformationDetails.base(args)),
  changeFieldValue: (field, value) => dispatch(change(ppcp.BASIC_INFORMATION_ID, field, value)),
  updateCurrentFormAction: (modified) => dispatch(updateCurrentForm(ppcp.BASIC_INFORMATION_ID, modified))
});

BasicInformation.propTypes = {
  handleSubmit: PropTypes.func,
  fetchBasicInformationDetails: PropTypes.func,
  changeFieldValue: PropTypes.func,
  initialValues: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  updateCurrentFormAction: PropTypes.func,
  dirty: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: ppcp.BASIC_INFORMATION_ID,
    enableReinitialize: true
  })(BasicInformation)
);
