import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  fetchMediaDetails,
  updateCurrentForm,
  saveMediaApplicationLogo,
  removeMediaApplicationLogo,
  saveMediaApplicationScreenshot,
  removeMediaApplicationScreenshot,
  addVideoMedia,
  mediaSelector,
  showServerErrors
} from 'reducers/modules/Application/index';
import ToolTip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';
import { MEDIA_ID } from '../../../constants/PPCP';
import AddScreenShot from '../../common/AddScreenShot';
import VideoScreenShotComponent from '../../common/VideoScreenShotComponent';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import { isInternalPPCP, isTravelportPOSApplicationPPCP } from '../../../reducers/modules/Application/selectors';
import FormHeader from '../FormHeader/FormHeader';

class Media extends PureComponent {
  componentDidMount() {
    document.title = 'Media';
    this.props.updateCurrentFormAction(MEDIA_ID, false);
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.fetchMediaDetails({ publicId, organizationId });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.media !== undefined && prevProps.media !== undefined) {
      if (JSON.stringify(this.compareObject(this.props.media)) !== JSON.stringify(this.compareObject(prevProps.media))) {
        this.props.updateCurrentFormAction(MEDIA_ID, true);
      } else {
        this.props.updateCurrentFormAction(MEDIA_ID, false);
      }
    } else {
      this.props.updateCurrentFormAction(MEDIA_ID, false);
    }
  }

  getVideoList = (videoList) => {
    this.props.addVideoMedia({ videoList });
  }

  saveLogo = (image) => {
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.saveMediaApplicationLogo({ publicId, image, organizationId });
  }

  removeLogo = () => {
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.removeMediaApplicationLogo({ publicId, organizationId });
  }

  saveScreenShot = (image, screenshotTitle, screenshotIndex) => {
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.saveMediaApplicationScreenshot({
      publicId, image, screenshotTitle, screenshotIndex, organizationId
    });
  }

  removeScreenShot = (screenshotIndex) => {
    if (this.numberOfScreensshots() === 1) {
      this.props.showServerErrors(['Atleast one Screenshot is required.']);
      return;
    }
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.props.removeMediaApplicationScreenshot({ publicId, screenshotIndex, organizationId });
  }

  numberOfScreensshots() {
    let numberOfScreenshots = 0;
    if (this.props.media.hasScreenshot1) {
      numberOfScreenshots += 1;
    }
    if (this.props.media.hasScreenshot2) {
      numberOfScreenshots += 1;
    }
    if (this.props.media.hasScreenshot3) {
      numberOfScreenshots += 1;
    }
    if (this.props.media.hasScreenshot4) {
      numberOfScreenshots += 1;
    }
    return numberOfScreenshots;
  }

  compareObject(props) {
    return {
      videoURLs: props.videoURLs
    };
  }

  render() {
    const { media, handleSubmit } = this.props;
    if (media === undefined) {
      return <LoaderComponent show />;
    }
    return (
      <>
        <FormHeader
          header="Media"
          infoText="Add a logo, screenshots, and videos for your solution."
        />
        <form id="mediaForm" onSubmit={handleSubmit}>
          {this.props.loading && <LoaderComponent show />}
          <div className="row">
            <h4 className="required-field-star">
              Product Logo
              {' '}
              <ToolTip
                labelName={'Displays on product page. Recommended size 80x80 pixels at 72 dpi. Accepted formats are JPG, PNG, GIF. '
                  + 'If logo is not available for product, use your company logo.'}
              />
            </h4>
            <em>The product logo is seen as users browse Marketplace. Your logo displays on the product page. Recommended image size is 80x80 pixels at 72 dpi.</em>
          </div>
          <div className="row">
            <AddScreenShot
              saveScreenShot={(image) => this.saveLogo(image)}
              hasScreenshot={media.hasLogo}
              screenshotThumbnailUrl={media.logoUrl}
              removeScreenShot={() => this.removeLogo()}
              isSmallImage
              uploadPopupAcceptedFormat=".jpg .png .gif formats accepted"
              uploadPopupAcceptedImgSize="80x80px 72dpi"
              modalTitle="Upload Product Logo"
              validationTypeUploadPopup
            />
          </div>
          <div className="row">
            <div className="col-sm-7 pad-right-double">
              <div className="row">
                <h4>
                  YouTube Video(s)
                  {' '}
                  <ToolTip labelName="To include a video, enter the YouTube video URL copied from the share button or YouTube video ID and click Add." />
                </h4>
                <p><em>You may provide up to three videos (not mandatory). Ensure the link for each video is copied from the share button on YouTube</em></p>
              </div>
              <VideoScreenShotComponent
                videoList={(media.videoURLs) ? media.videoURLs : []}
                getVideoList={(videosList) => this.getVideoList(videosList)}
              />
            </div>
            <div className="col-sm-5">
              <div className="row">
                <h4 className={!this.props.isInternal ? 'required-field-star' : ''}>
                  Screenshots
                  {' '}
                  <ToolTip
                    labelName={this.props.isInternal
                      ? 'Screenshots are optional.'
                      : 'One image is required; others are optional. Click on the first empty slot to upload your image.'
                      + 'A thumbnail of your screenshot will appear. You can edit or delete any image.'}
                  />
                </h4>
                <p>
                  <em>
                    {!this.props.isInternal
                      ? 'One screenshot is required. Up to 4 may be provided. Recommended image size is 800x600 pixels at 72 dpi resolution.'
                      : 'Up to 4 may be provided. Recommended image size is 800x600 pixels at 72 dpi resolution.'}
                  </em>
                </p>
              </div>
              <div className="row">
                <AddScreenShot
                  saveScreenShot={(image, title) => this.saveScreenShot(image, title, 1)}
                  hasScreenshot={media.hasScreenshot1}
                  screenshotThumbnailUrl={media.screenshot1ThumbnailUrl}
                  screenshotPreviewUrl={media.screenshot1PreviewUrl}
                  screenshotTitle={media.screenshot1Title}
                  removeScreenShot={() => this.removeScreenShot(1)}
                  uploadPopupAcceptedFormat=".jpg .png .gif formats accepted"
                  uploadPopupAcceptedImgSize="800x600px 72dpi"
                  modalTitle="Upload Screenshots"
                  validationTypeUploadPopup
                  uploadPopupTitleRequired
                  optional={false}
                />
                <AddScreenShot
                  saveScreenShot={(image, title) => this.saveScreenShot(image, title, 2)}
                  hasScreenshot={media.hasScreenshot2}
                  screenshotThumbnailUrl={media.screenshot2ThumbnailUrl}
                  screenshotPreviewUrl={media.screenshot2PreviewUrl}
                  screenshotTitle={media.screenshot2Title}
                  removeScreenShot={() => this.removeScreenShot(2)}
                  uploadPopupAcceptedFormat=".jpg .png .gif formats accepted"
                  uploadPopupAcceptedImgSize="800x600px 72dpi"
                  modalTitle="Upload Screenshots"
                  validationTypeUploadPopup
                  uploadPopupTitleRequired
                  optional
                />
                <AddScreenShot
                  saveScreenShot={(image, title) => this.saveScreenShot(image, title, 3)}
                  hasScreenshot={media.hasScreenshot3}
                  screenshotThumbnailUrl={media.screenshot3ThumbnailUrl}
                  screenshotPreviewUrl={media.screenshot3PreviewUrl}
                  screenshotTitle={media.screenshot3Title}
                  removeScreenShot={() => this.removeScreenShot(3)}
                  uploadPopupAcceptedFormat=".jpg .png .gif formats accepted"
                  uploadPopupAcceptedImgSize="800x600px 72dpi"
                  modalTitle="Upload Screenshots"
                  validationTypeUploadPopup
                  uploadPopupTitleRequired
                  optional
                />
                <AddScreenShot
                  saveScreenShot={(image, title) => this.saveScreenShot(image, title, 4)}
                  hasScreenshot={media.hasScreenshot4}
                  screenshotThumbnailUrl={media.screenshot4ThumbnailUrl}
                  screenshotPreviewUrl={media.screenshot4PreviewUrl}
                  screenshotTitle={media.screenshot4Title}
                  removeScreenShot={() => this.removeScreenShot(4)}
                  uploadPopupAcceptedFormat=".jpg .png .gif formats accepted"
                  uploadPopupAcceptedImgSize="800x600px 72dpi"
                  modalTitle="Upload Screenshots"
                  validationTypeUploadPopup
                  uploadPopupTitleRequired
                  optional
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

Media.propTypes = {
  handleSubmit: PropTypes.func,
  updateCurrentFormAction: PropTypes.func,
  location: PropTypes.object,
  fetchMediaDetails: PropTypes.func,
  saveMediaApplicationLogo: PropTypes.func,
  removeMediaApplicationLogo: PropTypes.func,
  saveMediaApplicationScreenshot: PropTypes.func,
  removeMediaApplicationScreenshot: PropTypes.func,
  addVideoMedia: PropTypes.func,
  media: PropTypes.object,
  loading: PropTypes.bool,
  saveForm: PropTypes.func,
  isInternal: PropTypes.bool,
  isTravelportPOSApplication: PropTypes.bool,
  showServerErrors: PropTypes.func
};

const mapStateToProps = (state) => ({
  media: mediaSelector(state),
  loading: state.application.media.loading || state.application.applicationLayout.loading,
  isInternal: isInternalPPCP(state),
  isTravelportPOSApplication: isTravelportPOSApplicationPPCP(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchMediaDetails: (args) => dispatch(fetchMediaDetails.base(args)),
  updateCurrentFormAction: (idForm, dirty) => dispatch(updateCurrentForm(idForm, dirty)),
  saveMediaApplicationLogo: (args) => dispatch(saveMediaApplicationLogo.base(args)),
  removeMediaApplicationLogo: (args) => dispatch(removeMediaApplicationLogo.base(args)),
  saveMediaApplicationScreenshot: (args) => dispatch(saveMediaApplicationScreenshot.base(args)),
  removeMediaApplicationScreenshot: (args) => dispatch(removeMediaApplicationScreenshot.base(args)),
  addVideoMedia: (args) => dispatch(addVideoMedia.base(args)),
  showServerErrors: (message) => dispatch(showServerErrors(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'media',
  })(Media)
);
