import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { recommendationSelectors, filterBuyersEmail } from '../../../reducers/Product/selectors';

const NoEmailMessage = () => (<div className="large-text">No email has been sent.</div>);
const NoMessage = () => (<p>No message was entered.</p>);

const RecommendationSuccess = ({
  selectedBuyers,
  userComments
}) => (
  <>
    <h2>{selectedBuyers.length === 0 ? 'Failed to send email' : 'Email Sent'}</h2>
    <div className="row">
      <h4>Email Recipients</h4>
      {selectedBuyers && selectedBuyers.map((v, i) => (<p key={i.toString()}>{v}</p>))}
      {selectedBuyers.length === 0 ? <NoEmailMessage /> : null}
    </div>
    <div className="row">
      <h4>Message</h4>
      <p>
        {userComments && userComments.split('\n').map((v) => (
          <>
            {v}
            <br />
          </>
        ))}
      </p>
      {!userComments ? <NoMessage /> : ''}
    </div>
    <div>
      <Link to="/products">
        <span className="link-carot-left" />
        {' '}
        Return to Browse
      </Link>
    </div>
  </>
);

RecommendationSuccess.propTypes = {
  userComments: PropTypes.string,
  selectedBuyers: PropTypes.array
};

const mapStateToProps = (state) => ({
  selectedBuyers: filterBuyersEmail(state),
  userComments: recommendationSelectors(state).userComments
});

export default connect(mapStateToProps)(RecommendationSuccess);
