import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as CommonServices from '../../Services/Common.Service';

class Contactus extends Component {
  componentDidMount() {
    CommonServices.SSOProcessing(this.props.user);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container">
        <h2 className="page-title">Contact Us</h2>
        <h3>Questions about Marketplace?</h3>
        <p className="medium-text space-bottom-double">
          Please raise a ticket via
          <a href="https://newmy.travelport.com/" style={{ color: '#159CDF' }}>MyTravelport</a>
          {' '}
          site.
        </p>
        <h3>Questions regarding a product or service?</h3>
        <p className="medium-text">
          Each product page has a link to a support page under
          {' '}
          <strong>Additional Information</strong>
          .
        </p>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user
});

Contactus.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps, null)(Contactus);
