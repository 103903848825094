/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import {
  FormControl, toast
} from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ToolTip from 'components/common/ToolTip';
import '@atlas-design-system/core/dist/styles/partials/toast.css';

const keywordInit = (props) => {
  const keywordArr = [];
  keywordArr.push(props.tagItems);
  return ((props.tagItems.indexOf(';') !== -1) ? props.tagItems.split(';') : keywordArr);
};

class SearchTag extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchTagItems: (this.props.tagItems) ? keywordInit(this.props) : [],
      value: '',
      showErrorMessage: false,
    };
  }

  storeKeyword = (event) => {
    event.preventDefault();
    if (this.state.searchTagItems.length === 5) {
      this.setState({
        showErrorMessage: true
      });

      setTimeout(() => {
        this.setState({
          showErrorMessage: false
        });
      }, 0);
      toast.warning('Maximum of five search tags can be added', {
        id: 'Maximum of five search tags can be added'.replaceAll(' ', '-'),
        position: 'top-center',
        showProgressBar: true
      });
      return;
    }
    if (this.state.value.length) {
      this.setState({
        searchTagItems: [...this.state.searchTagItems, this.state.value],
        value: ''
      }, () => {
        this.props.handleChange(this.state.searchTagItems);
      });
    }
  }

  removeKeyword = (event) => {
    event.preventDefault();

    this.state.searchTagItems.splice(parseInt(event.target.attributes.value.value, 10), 1);
    this.setState({
      searchTagItems: this.state.searchTagItems
    }, () => {
      this.props.handleChange(this.state.searchTagItems);
    });
  }

  render() {
    return (
      <>
        <div>
          <>
            <div className="row">
              <h4>Search Tags</h4>
              <div className="col-sm-6">
                <FormControl>
                  <input
                    type="text"
                    autoComplete="off"
                    maxLength="25"
                    value={this.state.value}
                    onChange={(e) => this.setState({ value: e.target.value })}
                  />
                </FormControl>

              </div>
              <div className="col-sm-2">
                <FormControl>
                  <Link className="cancel-button space-left-sm" to="#" onClick={this.storeKeyword}>Add</Link>
                </FormControl>
              </div>
            </div>
          </>

          <>
            <div className="row">
              <h4>
                Keywords
                {' '}
                <ToolTip labelName="Only upto 5 search keywords can be added" />
              </h4>
              <div className="col-sm-6">
                {(this.state.searchTagItems.length) ? this.state.searchTagItems.map((value, key) => (
                  <div key={key.toString()} className="pad-bottom">
                    {value}
                    {' '}
                    <Link to="#" onClick={this.removeKeyword}><span value={key} className="cancel-icon" /></Link>
                  </div>
                )) : <span>None</span>}
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}

SearchTag.propTypes = {
  tagItems: PropTypes.any,
  handleChange: PropTypes.func,
};

export default SearchTag;
