import {
  takeLatest, put, select, call, all, race, take
} from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { getAllCountries } from 'reducers/modules/countries';
import { getAllRegions } from 'reducers/modules/regions';
import updateStateArray from 'reducers/utils/updateStateArray';
import ApiClient from 'Services/ApiClient';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal,
} from 'reducers/popUps/confirmationModalReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import FileDownload from 'js-file-download';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import {
  INIT_PRODUCT_ACCESS_PAGE,
  GET_ALL_GROUPS,
  GET_USER_NAMES,
  GET_ORGANIZATION_NAMES,
  GET_PCC_ORG,
  GLOBAL_VISIBILITY,
  VISIBILITY_NON_PARTICIPANTS,
  VISIBILITY_EMPLOYEES,
  GET_PRODUCT_ACCESS,
  SAVE_PRODUCT_ACCESS,
  DELETE_PRODUCT_ACCESS,
  DOWNLOAD_EXCEL,
  CLEAR,
  initProductAccessPage,
  getOrganizationNames,
  getPccOrg,
  getUserNames,
  getAllGroups,
  changeGlobalAssignment,
  changeVisibilityNonParticipants,
  changeVisibilityTPEmployees,
  getProductAccess,
  saveProductAccess,
  deleteProductAccess,
  downloadExcel,
  GET_ORG_NAME_FROM_PCC,
  GET_PCC_LIST,
  getPccList,
  getOrgNameFromPcc
} from './actions';
import { getAccessCategorySelector, getGroupsSelector } from './selectors';

const DELETE_CONFIRM_MSG = 'Are you sure you want to delete the entry?';

const typeaheadCalls = (state, action) => {
  switch (action.type) {
    case GET_USER_NAMES.SUCCESS:
      return {
        ...state,
        userNames: action.payload
      };
    case GET_USER_NAMES.FAILURE:
    case GET_ORGANIZATION_NAMES.FAILURE:
    case GET_PCC_ORG.FAILURE:
    case GET_ORG_NAME_FROM_PCC.FAILURE:
    case GET_PCC_LIST.FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case GET_ORGANIZATION_NAMES.SUCCESS:
      return {
        ...state,
        orgNames: action.payload,
        getOrgNamesActionFired: true,
        getPccListActionFired: false,
      };
    case GET_PCC_ORG.SUCCESS:
      return {
        ...state,
        pccOrg: action.payload,
        getOrgNamesActionFired: false,
        getPccListActionFired: false,
      };
    case GET_PCC_LIST.SUCCESS:
      return {
        ...state,
        pcc: action.payload,
        getOrgNamesActionFired: false,
        getPccListActionFired: true,
      };
    case GET_ORG_NAME_FROM_PCC.SUCCESS:
      return {
        ...state,
        orgNameFromPcc: action.payload,
        getOrgNamesActionFired: false,
        getPccListActionFired: false,
      };
    case CLEAR:
      return {};
    default:
      return state;
  }
};

const productAccessReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case INIT_PRODUCT_ACCESS_PAGE:
      return action.data ? {
        ...state,
        filters: action.data
      } : state;
    case GET_PRODUCT_ACCESS.REQUEST:
    case SAVE_PRODUCT_ACCESS.REQUEST:
    case VISIBILITY_EMPLOYEES.REQUEST:
    case VISIBILITY_NON_PARTICIPANTS.REQUEST:
    case GLOBAL_VISIBILITY.REQUEST:
    case DOWNLOAD_EXCEL.REQUEST:
    case DELETE_PRODUCT_ACCESS.REQUEST:
    case GET_ALL_GROUPS.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCT_ACCESS.SUCCESS:
      return {
        ...state,
        ...action.payload.productAccess,
        loading: false,
      };

    case SAVE_PRODUCT_ACCESS.SUCCESS: {
      const accessCategoryItem = state.accessCategories.find(item => item.text === action.payload.accessCategory);
      const indexItem = state.productAccessList.pagedCollection.findIndex(item => item.id === action.payload.productAccessId);
      let arr;
      if (indexItem === -1) {
        arr = [
          {
            id: action.payload.productAccessId,
            accessCategory: accessCategoryItem,
            accessCategoryText: accessCategoryItem.text,
            categoryValueOne: action.payload.categoryValueOne,
            categoryValueTwo: action.payload.categoryValueTwo,
            userFullName: null,
            product: null
          },
          ...state.productAccessList.pagedCollection,
        ];
      } else {
        arr = updateStateArray(
          state.productAccessList.pagedCollection,
          indexItem,
          {
            id: action.payload.productAccessId,
            accessCategory: accessCategoryItem,
            accessCategoryText: accessCategoryItem.text,
            categoryValueOne: action.payload.categoryValueOne,
            categoryValueTwo: action.payload.categoryValueTwo,
            userFullName: null,
            product: null
          }
        );
      }
      return {
        ...state,
        productAccessList: {
          ...state.productAccessList,
          pagedCollection: arr
        },
        loading: false,
      };
    }
    case VISIBILITY_EMPLOYEES.SUCCESS:
      return {
        ...state,
        loading: false,
        isVisibleToTPEmployees: !state.isVisibleToTPEmployees
      };
    case VISIBILITY_NON_PARTICIPANTS.SUCCESS:
      return {
        ...state,
        loading: false,
        isVisibleToNonParticipants: !state.isVisibleToNonParticipants
      };
    case GLOBAL_VISIBILITY.SUCCESS:
      return {
        ...state,
        isGlobal: !state.isGlobal,
        loading: false,
      };
    case DELETE_PRODUCT_ACCESS.SUCCESS:
      return {
        ...state,
        productAccessList: {
          ...state.productAccessList,
          pagedCollection: state.productAccessList.pagedCollection.filter(item => item.id !== action.payload.id)
        },
        loading: false,
      };

    case GET_ALL_GROUPS.SUCCESS:
      return {
        ...state,
        groups: action.data
      };

    case GET_PRODUCT_ACCESS.FAILURE:
    case SAVE_PRODUCT_ACCESS.FAILURE:
    case VISIBILITY_EMPLOYEES.FAILURE:
    case VISIBILITY_NON_PARTICIPANTS.FAILURE:
    case GLOBAL_VISIBILITY.FAILURE:
    case DOWNLOAD_EXCEL.FAILURE:
    case DELETE_PRODUCT_ACCESS.FAILURE:
    case GET_ALL_GROUPS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return typeaheadCalls(state, action);
  }
};

const isGlobalSelector = state => state.administration.productAccess.isGlobal;
const visibilityNonParticipantsSelector = state => state.administration.productAccess.isVisibleToNonParticipants;
const visibilityEmployeesSelector = state => state.administration.productAccess.isVisibleToTPEmployees;
const paramsSelector = state => state.administration.productAccess.filters;
const countriesSelector = state => state.countries;
const regionsSelector = state => state.regions;

const getAccessCategoryItem = (items, value) => {
  const itemFound = items.find(item => item.label === value);

  return {
    text: itemFound.label,
    value: itemFound.value
  };
};

export function requestProductAccess({ accessCategoryItems, data }) {
  let selectedItem;
  let categoryValueOne;
  let categoryValueTwo;
  let isNew;
  let id;
  const { appPublicId } = data;
  if (data && data.addedRows && data.addedRows.length > 0) {
    selectedItem = getAccessCategoryItem(accessCategoryItems, data.addedRows[0].accessCategory);
    categoryValueOne = data.addedRows[0].categoryValueOne;
    categoryValueTwo = data.addedRows[0].categoryValueTwo;
    isNew = true;
    id = 0;
  }
  if (data && data.updatedRows) {
    selectedItem = getAccessCategoryItem(accessCategoryItems, data.updatedRows.accessCategory);
    categoryValueOne = data.updatedRows.categoryValueOne;
    categoryValueTwo = data.updatedRows.categoryValueTwo;
    isNew = false;
    id = data.updatedRows.id;
  }
  return {
    productAccessViewModel: {
      id,
      isNew,
      modifyMode: true,
      accessCategory: {
        isSelected: true,
        ...selectedItem
      },
      categoryValueOne,
      categoryValueTwo
    },
    appPublicId
  };
}

function* initProductAccessPageSaga({ data }) {
  yield put(showLoader());
  try {
    const params = yield select(paramsSelector);
    const filters = data || params;
    yield put(getProductAccess.request());

    const countriesList = yield select(countriesSelector);
    const regionsList = yield select(regionsSelector);
    const groupsList = yield select(getGroupsSelector);
    if (groupsList === undefined || countriesList.length === 0 || regionsList.length === 0) {
      const [countries, regions, groups, productAccess] = yield all([
        call(() => ApiClient.httpClient.get(getAllCountries.base().url)),
        call(() => ApiClient.httpClient.post(getAllRegions.base().url)),
        call(() => ApiClient.httpClient.get(getAllGroups.base().url)),
        call(() => ApiClient.httpClient.get(getProductAccess.base().url, { params: filters }))
      ]);

      yield all([
        put(getAllCountries.success(countries.data)),
        put(getAllRegions.success(regions.data)),
        put(getAllGroups.success(groups.data)),
        put(getProductAccess.success({ productAccess: productAccess.data }))
      ]);
    } else {
      const productAccess = yield (call(() => ApiClient.httpClient.get(getProductAccess.base().url, { params: filters })));
      yield put(getProductAccess.success({ productAccess: productAccess.data }));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* getOrganizationNamesSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getOrganizationNames, ApiClient.httpClient.get, url, { params: { orgName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getPccOrgSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getPccOrg, ApiClient.httpClient.get, url, { params: { orgName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getUserNamesSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getUserNames, ApiClient.httpClient.get, url, { params: { userName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* saveProductAccessSaga({ url, data }) {
  yield put(showLoader());
  try {
    const accessCategoryItems = yield select(getAccessCategorySelector);
    const request = requestProductAccess({ accessCategoryItems, data });
    const result = yield ApiClient.httpClient.post(url, { ...request });
    const rows = (data.addedRows && data.addedRows[0]) || data.updatedRows;
    yield put(saveProductAccess.success({
      ...rows,
      ...result.data
    }));
    yield put(showAlertAction('Product access setting has been successfully added.'));
    yield put((initProductAccessPage()));
    data.onSuccess();
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* getPccListSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getPccList, ApiClient.httpClient.get, url, { params: { pccName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getOrgNameFromPccSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getOrgNameFromPcc, ApiClient.httpClient.get, url, { params: { pccAndHostName: data.value } }));
  } catch (error) {
    if (error.response) {
      yield put(showAlertAction(error && error.response && error.response.data));
    }
  }
}

function* deleteProductAccessSaga({ url, data }) {
  try {
    yield put(showConfirmationModal(DELETE_CONFIRM_MSG, ''));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      deleteProductAccess.request();
      yield put(showLoader());
      const result = yield ApiClient.httpClient.delete(url, { params: { productAccessId: data.row.id } });
      yield put(deleteProductAccess.success({
        id: data.row.id,
        message: result.data
      }));
      yield put((initProductAccessPage()));
      yield put(showAlertAction('Product access setting has been successfully deleted.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* globalVisibilitySaga({ url, appPublicId }) {
  yield put(showLoader());
  try {
    const data = { appPublicId };
    yield call(() => fetchEntity(changeGlobalAssignment, ApiClient.httpClient.post, url, { ...data }));
    yield put((initProductAccessPage()));
    const isGlobal = yield select(isGlobalSelector);
    if (isGlobal) {
      yield put(showAlertAction('Global Assignment has been successfully done'));
    } else {
      yield put(showAlertAction('Global Assignment has been successfully removed'));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* visibilityNonParticipantsSaga({ url, appPublicId }) {
  yield put(showLoader());
  try {
    const data = { appPublicId };
    yield call(() => fetchEntity(changeVisibilityNonParticipants, ApiClient.httpClient.post, url, { ...data }));
    const visibilityNonParticipants = yield select(visibilityNonParticipantsSelector);
    if (visibilityNonParticipants) {
      yield put(showAlertAction('The product page is now viewable to non-list members'));
    } else {
      yield put(showAlertAction('The product page is now not viewable to non-list members'));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* visibilityEmployeesSaga({ url, appPublicId }) {
  yield put(showLoader());
  try {
    const data = { appPublicId };
    yield call(() => fetchEntity(changeVisibilityTPEmployees, ApiClient.httpClient.post, url, { ...data }));
    const visibilityEmployees = yield select(visibilityEmployeesSelector);
    if (visibilityEmployees) {
      yield put(showAlertAction('The product page is now viewable to TP Employees'));
    } else {
      yield put(showAlertAction('The product page is now not viewable to TP Employees'));
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
  yield put(hideLoader());
}

function* downloadExcelSaga({ url, data }) {
  yield put(showLoader());
  try {
    yield put(downloadExcel.request());
    const params = {
      appPublicId: data.appPublicId,
      filter: data.filter,
      sortOnColumn: data.sortOnColumn,
      sortAsc: data.sortAsc
    };
    const response = yield call(ApiClient.httpClient.post, url, params);
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      let filename = 'report.csv';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
    yield put(downloadExcel.success());
  } catch (error) {
    yield put(downloadExcel.failure(error));
    yield put(showAlertAction('Some Error Occured. Please try again.'));
  }
  yield put(hideLoader());
}

export function* productAccessMiddleware() {
  yield takeLatest(INIT_PRODUCT_ACCESS_PAGE, initProductAccessPageSaga);
  yield takeLatest(GET_USER_NAMES.BASE, getUserNamesSaga);
  yield takeLatest(GET_ORGANIZATION_NAMES.BASE, getOrganizationNamesSaga);
  yield takeLatest(GET_PCC_ORG.BASE, getPccOrgSaga);
  yield takeLatest(DELETE_PRODUCT_ACCESS.BASE, deleteProductAccessSaga);
  yield takeLatest(SAVE_PRODUCT_ACCESS.BASE, saveProductAccessSaga);
  yield takeLatest(GLOBAL_VISIBILITY.BASE, globalVisibilitySaga);
  yield takeLatest(VISIBILITY_NON_PARTICIPANTS.BASE, visibilityNonParticipantsSaga);
  yield takeLatest(VISIBILITY_EMPLOYEES.BASE, visibilityEmployeesSaga);
  yield takeLatest(DOWNLOAD_EXCEL.BASE, downloadExcelSaga);
  yield takeLatest(GET_PCC_LIST.BASE, getPccListSaga);
  yield takeLatest(GET_ORG_NAME_FROM_PCC.BASE, getOrgNameFromPccSaga);
}

export default productAccessReducer;
