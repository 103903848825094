import {
  takeLatest, call, put, take, race
} from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';

import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const FETCH_BANNER_INFO = createFetchTypes(
  'Marketplace/Adminstration/banner/FETCH_BANNER_INFO'
);

const SAVE_BANNER_INFO = createFetchTypes(
  'Marketplace/Adminstration/banner/SAVE_BANNER_INFO'
);
const DELETE_BANNER_INFO = createFetchTypes(
  'Marketplace/Adminstration/banner/DELETE_BANNER_INFO'
);
const ORDER_BANNER_INFO = createFetchTypes(
  'Marketplace/Adminstration/banner/ORDER_BANNER_INFO'
);
const FETCH_URL = 'banner/getbannerinfo';
const DELETE_URL = 'banner/deletebannerinfo';
const SAVE_URL = 'banner/savebannerinfo';
const ORDER_BANNER_URL = 'banner/orderbannerinfo';

function banner(state = { data: { pagedCollection: [] } }, action = {}) {
  switch (action.type) {
    case FETCH_BANNER_INFO.REQUEST:
    case ORDER_BANNER_INFO.REQUEST:
    case DELETE_BANNER_INFO.REQUEST:
    case SAVE_BANNER_INFO.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BANNER_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case ORDER_BANNER_INFO.SUCCESS:
    case DELETE_BANNER_INFO.SUCCESS:
    case SAVE_BANNER_INFO.SUCCESS:
    case FETCH_BANNER_INFO.ERROR:
    case ORDER_BANNER_INFO.ERROR:
    case DELETE_BANNER_INFO.ERROR:
    case SAVE_BANNER_INFO.ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export const fetchBannerInfo = {
  base: params => ({
    type: FETCH_BANNER_INFO.BASE,
    params,
    url: FETCH_URL
  }),
  request: () => ({ type: FETCH_BANNER_INFO.REQUEST }),
  success: data => ({ type: FETCH_BANNER_INFO.SUCCESS, data }),
  failure: error => ({ type: FETCH_BANNER_INFO.FAILURE, error })
};

export const saveBannerInfo = {
  base: (params, callback) => ({
    type: SAVE_BANNER_INFO.BASE,
    params,
    url: SAVE_URL,
    callback
  }),
  request: () => ({ type: SAVE_BANNER_INFO.REQUEST }),
  success: data => ({ type: SAVE_BANNER_INFO.SUCCESS, data }),
  failure: error => ({ type: SAVE_BANNER_INFO.FAILURE, error })
};

export const deletBannerInfo = {
  base: (params, callback) => ({
    type: DELETE_BANNER_INFO.BASE,
    params,
    url: DELETE_URL,
    callback
  }),
  request: () => ({ type: DELETE_BANNER_INFO.REQUEST }),
  success: data => ({ type: DELETE_BANNER_INFO.SUCCESS, data }),
  failure: error => ({ type: DELETE_BANNER_INFO.FAILURE, error })
};

export const orderBannerInfo = {
  base: (params, callback) => ({
    type: ORDER_BANNER_INFO.BASE,
    params,
    url: ORDER_BANNER_URL,
    callback
  }),
  request: () => ({ type: ORDER_BANNER_INFO.REQUEST }),
  success: data => ({ type: ORDER_BANNER_INFO.SUCCESS, data }),
  failure: error => ({ type: ORDER_BANNER_INFO.FAILURE, error })
};

export function* fetchBannerInfoSaga({ url }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchBannerInfo, ApiClient.httpClient.get, url));
  } catch (error) {
    yield put(showAlertAction(error.response.data));
  }
  yield put(hideLoader());
}

export function* saveBannerInfoSaga({ url, params, callback }) {
  if (!params.fromDate || !params.toDate || !params.headerInfo) {
    yield put(showAlertAction('Please fill the mandatory fields'));
    return;
  }
  if (params.headerInfo && params.headerInfo.trim().length > 150) {
    yield put(showAlertAction('Title restricted upto 150 characters'));
    return;
  }
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(saveBannerInfo, ApiClient.httpClient.post, url, params));
    yield put(showAlertAction('Banner saved'));
    callback();
  } catch (error) {
    yield put(showAlertAction(error.response.data && error.response.data.message));
  }
  yield put(hideLoader());
}

export function* deleteBannerInfoSaga({ url, params, callback }) {
  yield put(
    showConfirmationModal('Sure to delete?', '')
  );
  const { accept } = yield race({
    accept: take(CONFIRMATION_ACCEPTED),
    reject: take(CONFIRMATION_REJECTED)
  });
  if (accept) {
    yield put(showLoader());
    try {
      yield call(() => fetchEntity(deletBannerInfo, ApiClient.httpClient.post, url, params));
      yield put(showAlertAction('Banner deleted'));
      callback();
    } catch (error) {
      yield put(showAlertAction(error.response.data));
    }
    yield put(hideLoader());
  }
}

export function* orderBannerInfoSaga({ url, params, callback }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(orderBannerInfo, ApiClient.httpClient.post, url, params));
    callback();
  } catch (error) {
    yield put(showAlertAction(error.response.data));
  }
  yield put(hideLoader());
}

export default banner;

export function* bannerMiddleware() {
  yield takeLatest(FETCH_BANNER_INFO.BASE, fetchBannerInfoSaga);
  yield takeLatest(SAVE_BANNER_INFO.BASE, saveBannerInfoSaga);
  yield takeLatest(DELETE_BANNER_INFO.BASE, deleteBannerInfoSaga);
  yield takeLatest(ORDER_BANNER_INFO.BASE, orderBannerInfoSaga);
}
