import * as actionTypes from '../actions/actionType';

const popularProductsReducer = (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.LOADPOPULARPRODUCTS:
      return action.data;
    default:
      return state;
  }
};

export default popularProductsReducer;
