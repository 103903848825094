/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAdminsForOrgs, getAdminsForOrgsSelector } from '../../../reducers/modules/Administration/OrganizationDirectory/ManageAdmins/getAdminsForOrg';
import './style.css';

class ShowAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  handleShow = () => {
    this.props.getAdminForOrg({ orgId: this.props.currentUserOrgId });
    this.setState({ show: true });
  }

  handleClose = () => {
    this.setState({ show: false });
  }

renderGrid = (data) => (
  <table className="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
      </tr>
    </thead>
    <tbody>
      {
        data.map(x => (
          <tr>
            <td>
              <i>{x.name}</i>
            </td>
            <td>
              <i>{x.email}</i>
            </td>
          </tr>
        ))
      }
    </tbody>
  </table>
)

render() {
  const listAdminData = this.props.adminsForOrgs ? this.props.adminsForOrgs.admins : [];
  const formatedData = listAdminData.length ? listAdminData.map(x => ({ name: x.name, email: x.email })) : ['No Admin'];

  return (
    <div>
      <a onClick={this.handleShow}>{'Click here to view your agency\'s admins'}</a>
      <Modal
        id="sbModal"
        title="Agency Admins"
        hidden={!this.state.show}
        size="small"
        onDismiss={this.handleClose}
        className="tp-atlas-modal-agency-admins center-screen divWithScroll"
      >
        <div>
          {formatedData.length ? this.renderGrid(formatedData) : 'No Admin Users is assigned for this Agency'}
        </div>
      </Modal>
    </div>
  );
}
}
const mapStateToProps = state => ({
  adminsForOrgs: getAdminsForOrgsSelector(state)
});
const mapDispatchToProps = dispatch => ({
  getAdminForOrg: params => dispatch(getAdminsForOrgs.base(params))
});

ShowAdminList.propTypes = {
  getAdminForOrg: PropTypes.func,
  currentUserOrgId: PropTypes.string,
  adminsForOrgs: PropTypes.object,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowAdminList);
