import { takeLatest, call, put } from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const FETCH_USERLOGIN_REPORTS = createFetchTypes(
  'Marketplace/Reports/userLoginReport/FETCH_USERLOGIN_REPORTS'
);

const FETCH_URL = 'reports/GetFilteredUserLoginReport';

function userLoginReport(state = { data: { pagedCollection: [] } }, action = {}) {
  switch (action.type) {
    case FETCH_USERLOGIN_REPORTS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERLOGIN_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case FETCH_USERLOGIN_REPORTS.ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export const fetchUserLoginReport = {
  base: params => ({
    type: FETCH_USERLOGIN_REPORTS.BASE,
    params,
    url: FETCH_URL
  }),
  request: () => ({ type: FETCH_USERLOGIN_REPORTS.REQUEST }),
  success: data => ({ type: FETCH_USERLOGIN_REPORTS.SUCCESS, data }),
  failure: error => ({ type: FETCH_USERLOGIN_REPORTS.FAILURE, error })
};

export function* fetchUserLoginReportSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchUserLoginReport, ApiClient.httpClient.post, url, params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export default userLoginReport;

export function* userLoginReportMiddleware() {
  yield takeLatest(FETCH_USERLOGIN_REPORTS.BASE, fetchUserLoginReportSaga);
}
