import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCountriesSelector, getAllCountries } from 'reducers/modules/countries';
import LoaderComponent from 'components/common/Loader/LoaderComponent';
import AdditionalCustomerInfoReduxForm from './AdditionalCustomerInfoReduxForm';

class AdditionalCustomerInfoForm extends PureComponent {
  componentDidMount() {
    if (!this.props.countries || this.props.countries.length < 1) {
      this.props.getAllCountries();
    }
  }

  render() {
    if (!this.props.countries || this.props.countries.length < 1) {
      return <LoaderComponent show />;
    }
    return <AdditionalCustomerInfoReduxForm {...this.props} />;
  }
}

AdditionalCustomerInfoForm.propTypes = {
  countries: PropTypes.array,
  getAllCountries: PropTypes.func,
};
AdditionalCustomerInfoForm.defaultProps = {
  countries: []
};
const mapStateToProps = (state) => ({
  countries: getCountriesSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries.base())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCustomerInfoForm);
