/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTimeHelper from 'utils/dateHelper';
import DatePickerTime from './DatepickerTime';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import MPEditor from '../../common/MPEditor/MPEditor';

class BannerForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerInfo: props.params.headerInfo || '',
      fromDate: DateTimeHelper.getDateTime(props.params.fromDate, 'MM-DD-YYYY hh:mm:ss A') || moment.utc().startOf('day'),
      toDate: DateTimeHelper.getDateTime(props.params.toDate, 'MM-DD-YYYY hh:mm:ss A') || moment.utc().add(30, 'days').startOf('day'),
      isActive: props.params.isActive || true,
      description: props.params.description || '',
      order: props.params.order || 0,
      id: props.params.id || 0,
      highImportant: props.params.highImportant || false
    };
  }

  datehandler = (e, field) => {
    if (field === 'fromDate' && this.state.fromDate && this.state.toDate && moment(e).isAfter(this.state.toDate, 'minute')) {
      this.setState({ toDate: e, fromDate: e });
    } else if (field === 'toDate' && this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(e, 'minute')) {
      this.setState({ toDate: e, fromDate: e });
    } else {
      this.setState({ [field]: e });
    }
  }

  changeHandler = (e, field) => {
    if (field === 'highImportant' && e === true) {
      this.setState({ isActive: true, highImportant: true }, this.datehandler(moment.utc().startOf('day'), 'fromDate'));
    } else {
      this.setState({ [field]: e });
    }
  }

  isValidDate = (currentDate, selectedDate, type) => {
    if (!this.state.fromDate && !this.state.toDate) {
      const diff = moment.utc().diff(currentDate, 'days');
      if (diff > 0) {
        return false;
      }
      return true;
    }
    if (type === 'toDate' && this.state.fromDate) {
      if (moment.utc(currentDate).isBefore(moment.utc(this.state.fromDate), 'minutes')) {
        return false;
      }
      return true;
    }
    if (type === 'fromDate' && this.state.toDate) {
      if (moment.utc().startOf('day').isAfter(moment.utc(currentDate), 'minutes') || moment.utc(currentDate).isAfter(moment(this.state.toDate))) {
        return false;
      }
      return true;
    }
    return true;
  }

  submitForm = () => {
    this.props.formData(this.state);
  }

  render() {
    return (
      <form className="banner-form">
        <div className="form-group">
          <div className="flexClass">
            <div className="banner-datepicker">
              <label htmlFor="exampleInputEmail1" className="required-field-star">From Date (UTC)</label>
              <DatePickerTime
                value={this.state.fromDate}
                removeCalender={this.state.highImportant}
                isValidDate={(currentDate, selectedDate) => this.isValidDate(currentDate, selectedDate, 'fromDate')}
                onChange={(e) => this.datehandler(e, 'fromDate')}
              />
            </div>
            <div className="banner-datepicker">
              <label htmlFor="exampleInputEmail1" className="required-field-star">To Date (UTC)</label>
              <DatePickerTime
                value={this.state.toDate}
                isValidDate={(currentDate, selectedDate) => this.isValidDate(currentDate, selectedDate, 'toDate')}
                onChange={(e) => this.datehandler(e, 'toDate')}
              />
            </div>
            {!this.state.highImportant
              ? (
                <div className="flexClass">
                  <SimplePrettyCheckBox displayText="Enable Banner" value={this.state.isActive} onChange={(e) => this.changeHandler(e, 'isActive')} />
                </div>
              ) : null}
            {!this.props.params.highImportant ? (
              <div className="flexClass">
                <SimplePrettyCheckBox displayText="High Priority" value={this.state.highImportant} onChange={(e) => this.changeHandler(e, 'highImportant')} />
              </div>
            ) : null}
            <div className="empty">
              <label htmlFor="empty">Empty Content</label>
            </div>
            <div className="empty">
              <label htmlFor="empty">Empty Content</label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1" className="required-field-star">
            Banner Title
            <small>(150 characters only)</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            placeholder="Banner Title"
            value={this.state.headerInfo}
            onChange={(e) => this.changeHandler(e.target.value, 'headerInfo')}
          />
        </div>
        <label htmlFor="exampleInputEmail1">Banner Description</label>
        <MPEditor getContent={(e) => this.changeHandler(e, 'description')} content={this.state.description} />
        <div className="btn-row">
          <button type="button" className="btn btn-primary" onClick={() => this.props.formData(this.state)}>Submit</button>
&nbsp;
          <button type="button" className="btn btn-danger" onClick={this.props.cancel}>Back</button>
        </div>
      </form>
    );
  }
}

BannerForm.propTypes = {
  formData: PropTypes.func,
  cancel: PropTypes.func,
  params: PropTypes.object,
  showAlertAction: PropTypes.func
};

export default BannerForm;
