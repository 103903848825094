import {
  takeLatest, call, put, race, take, select
} from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import { editLicenseStatusSelector } from '../selectors';

const GET_LICENSES_URL = 'license/GetLicenses';
const SAVE_LICENSE_CHANGE_URL = 'license/SaveLicenseChange';
const GET_LICENSE_HISTORY_URL = 'license/GetLicenseHistory';
const GET_TEXT_FOR_LICENSE_CHANGE_CONF_URL = 'license/GetTextForLicenseChangeConfirmation';

const GET_LICENSES = createFetchTypes('Marketplace/Administration/ManageLicenses/editLicense/GET_LICENSES');
const SAVE_LICENSE_CHANGE = createFetchTypes('Marketplace/Administration/ManageLicenses/editLicense/SAVE_LICENSE_CHANGE');
const GET_LICENSE_HISTORY = createFetchTypes('Marketplace/Administration/ManageLicenses/editLicense/GET_LICENSE_HISTORY');
const GET_TEXT_FOR_LICENSE_CHANGE_CONF = createFetchTypes('Marketplace/Administration/ManageLicenses/editLicense/GET_TEXT_FOR_LICENSE_CHANGE_CONF');

const SUBMIT_LICENSE_CHANGE_CONF = 'Marketplace/Administration/ManageLicenses/editLicense/SUBMIT_LICENSE_CHANGE_CONF';
const CANCEL_LICENSE_CHANGE_CONF = 'Marketplace/Administration/ManageLicenses/editLicense/CANCEL_LICENSE_CHANGE_CONF';
const HIDE_LICENSE_HISTORY_MODAL = 'Marketplace/Administration/ManageLicenses/editLicense/HIDE_LICENSE_HISTORY_MODAL';
const SHOW_LICENSE_CHANGED_MODAL = 'Marketplace/Administration/ManageLicenses/editLicense/SHOW_LICENSE_CHANGED_MODAL';
const HIDE_LICENSE_CHANGED_MODAL = 'Marketplace/Administration/ManageLicenses/editLicense/HIDE_LICENSE_CHANGED_MODAL';
const RESET_EDIT_LICENSE_STATUS = 'Marketplace/Administration/ManageLicenses/editLicense/RESET_EDIT_LICENSE_STATUS';

function editLicenseStatus(state = {}, action = {}) {
  switch (action.type) {
    case GET_LICENSES.BASE:
      return {
        ...state,
        fetchParams: action.params
      };
    case GET_LICENSES.REQUEST:
    case SAVE_LICENSE_CHANGE.REQUEST:
    case GET_LICENSE_HISTORY.REQUEST:
    case GET_TEXT_FOR_LICENSE_CHANGE_CONF.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_LICENSES.SUCCESS:
      return {
        ...state,
        loading: false,
        entities: action.data.listOfLicenseDetailRow,
        totalPages: action.data.totalPages,
        title: action.data.licenseDetailsEntityName,
        error: null
      };
    case GET_LICENSE_HISTORY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        licenseHistories: action.data.history,
        showLicenseHistoryModal: true
      };
    case HIDE_LICENSE_HISTORY_MODAL:
      return {
        ...state,
        showLicenseHistoryModal: false
      };
    case GET_TEXT_FOR_LICENSE_CHANGE_CONF.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        licenseChangeInfo: action.data,
        showLicenseChangeConfModal: true
      };
    case SUBMIT_LICENSE_CHANGE_CONF:
      return {
        ...state,
        licenseChangeInfo: {
          ...state.licenseChangeInfo,
          reason: action.reason,
          otherReason: action.otherReason
        },
        showLicenseChangeConfModal: false
      };
    case CANCEL_LICENSE_CHANGE_CONF:
      return {
        ...state,
        showLicenseChangeConfModal: false
      };
    case SAVE_LICENSE_CHANGE.SUCCESS:
      return {
        ...state,
        licenseChangedInfo: {
          title: action.data[0],
          message: action.data[1]
        }
      };
    case SHOW_LICENSE_CHANGED_MODAL:
      return {
        ...state,
        showLicenseChangedModal: true
      };
    case HIDE_LICENSE_CHANGED_MODAL:
      return {
        ...state,
        showLicenseChangedModal: false
      };
    case GET_LICENSES.FAILURE:
    case SAVE_LICENSE_CHANGE.FAILURE:
    case GET_LICENSE_HISTORY.FAILURE:
    case GET_TEXT_FOR_LICENSE_CHANGE_CONF.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case RESET_EDIT_LICENSE_STATUS:
      return {};
    default:
      return state;
  }
}

export const getLicenses = {
  base: (params) => ({
    type: GET_LICENSES.BASE,
    params,
    url: GET_LICENSES_URL
  }),
  request: () => ({ type: GET_LICENSES.REQUEST }),
  success: (data) => ({ type: GET_LICENSES.SUCCESS, data }),
  failure: (error) => ({ type: GET_LICENSES.FAILURE, error })
};

export const saveLicenseChange = {
  base: (params) => ({
    type: SAVE_LICENSE_CHANGE.BASE,
    params,
    url: SAVE_LICENSE_CHANGE_URL
  }),
  request: () => ({ type: SAVE_LICENSE_CHANGE.REQUEST }),
  success: (data) => ({ type: SAVE_LICENSE_CHANGE.SUCCESS, data }),
  failure: (error) => ({ type: SAVE_LICENSE_CHANGE.FAILURE, error })
};

export const getLicenseHistory = {
  base: (licenseId) => ({
    type: GET_LICENSE_HISTORY.BASE,
    licenseId,
    url: GET_LICENSE_HISTORY_URL
  }),
  request: () => ({ type: GET_LICENSE_HISTORY.REQUEST }),
  success: (data) => ({ type: GET_LICENSE_HISTORY.SUCCESS, data }),
  failure: (error) => ({ type: GET_LICENSE_HISTORY.FAILURE, error })
};

export const getTextForLicenseChangeConf = {
  base: (licenseStatus) => ({
    type: GET_TEXT_FOR_LICENSE_CHANGE_CONF.BASE,
    licenseStatus,
    url: GET_TEXT_FOR_LICENSE_CHANGE_CONF_URL
  }),
  request: () => ({ type: GET_TEXT_FOR_LICENSE_CHANGE_CONF.REQUEST }),
  success: (data) => ({ type: GET_TEXT_FOR_LICENSE_CHANGE_CONF.SUCCESS, data }),
  failure: (error) => ({ type: GET_TEXT_FOR_LICENSE_CHANGE_CONF.FAILURE, error })
};

export const submitLicenseChangeConf = (reason, otherReason) => ({
  type: SUBMIT_LICENSE_CHANGE_CONF,
  reason,
  otherReason
});

export const cancelLicenseChangeConf = () => ({ type: CANCEL_LICENSE_CHANGE_CONF });
export const hideLicenseHistoryModal = () => ({ type: HIDE_LICENSE_HISTORY_MODAL });
export const hideLicenseChangedModal = () => ({ type: HIDE_LICENSE_CHANGED_MODAL });
export const resetEditLicenseStatus = () => ({ type: RESET_EDIT_LICENSE_STATUS });

const showLicenseChangedModal = () => ({ type: SHOW_LICENSE_CHANGED_MODAL });

function* getLicensesSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getLicenses, ApiClient.httpClient.get, url, { params }));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

function* saveLicenseChangeSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getTextForLicenseChangeConf, ApiClient.httpClient.get, GET_TEXT_FOR_LICENSE_CHANGE_CONF_URL, { params: { licenseStatus: params.change } }));
    yield put(hideLoader());
    const { submit } = yield race({ submit: take(SUBMIT_LICENSE_CHANGE_CONF), reject: take(CANCEL_LICENSE_CHANGE_CONF) });
    if (submit) {
      const { licenseChangeInfo } = yield select(editLicenseStatusSelector);
      const apiParams = { ...params };
      apiParams.reason = licenseChangeInfo.reason;
      apiParams.otherReason = licenseChangeInfo.otherReason;
      yield put(showLoader());
      yield call(() => fetchEntity(saveLicenseChange, ApiClient.httpClient.post, url, apiParams));
      const { fetchParams } = yield select(editLicenseStatusSelector);
      yield call(() => fetchEntity(getLicenses, ApiClient.httpClient.get, GET_LICENSES_URL, { params: { ...fetchParams } }));
      yield put(showLicenseChangedModal());
    }
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

function* getLicenseHistorySaga({ url, licenseId }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getLicenseHistory, ApiClient.httpClient.get, url, { params: { licenseId } }));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* editLicenseStatusMiddleWare() {
  yield takeLatest(GET_LICENSES.BASE, getLicensesSaga);
  yield takeLatest(SAVE_LICENSE_CHANGE.BASE, saveLicenseChangeSaga);
  yield takeLatest(GET_LICENSE_HISTORY.BASE, getLicenseHistorySaga);
}

export default editLicenseStatus;
