/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable no-undef */
class SocialShareIcons extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillMount() {
    this.scriptLoaded();
  }

  scriptLoaded() {
    let script = document.createElement('script');
    script.appendChild(document.createTextNode('var switchTo5x = true;'));
    script.async = true;
    document.body.appendChild(script);
    script = document.createElement('script');
    script.src = 'https://ws.sharethis.com/button/buttons.js';
    script.async = true;
    document.body.appendChild(script);
    script = document.createElement('script');
    script.appendChild(
      document.createTextNode(
        'window.onload = function() { if (stLight !== undefined) { stLight.options({ "publisher": "dr-803b30ee-ff74-8441-bea-126ee7e76ce2", "version": "5x" }); }}'
      )
    );
    script.async = true;
    document.body.appendChild(script);
    const tags = document.getElementsByTagName('script');
    for (let i = tags.length; i >= 0; i -= 1) {
      if (tags[i] && tags[i].getAttribute('src') !== null) {
        tags[i].parentNode.removeChild(tags[i]);
      }
    }
  }

  scriptloading(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    return (
      <span className="share-this-icon-row">
        &nbsp;
        <a href="#" st_url={this.props.url} st_title={this.props.title} className="st_linkedin_custom share-this-icon" title="Share this on LinkedIn" onClick={(e) => this.scriptloading(e)}>
          <span className="icon-share-this-linkedin" />
        </a>
        &nbsp;
        <a href="#" st_url={this.props.url} st_title={this.props.title} className="st_twitter_custom share-this-icon" title="Share this on twitter" onClick={(e) => this.scriptloading(e)}>
          <span className="icon-share-this-twitter" />
        </a>
        &nbsp;
        <a href="#" st_url={this.props.url} st_title={this.props.title} className="st_facebook_custom share-this-icon" title="Share this on Facebook" onClick={(e) => this.scriptloading(e)}>
          <span className="icon-share-this-facebook" />
        </a>
      </span>
    );
  }
}

SocialShareIcons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
};

export default SocialShareIcons;
