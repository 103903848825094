import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { getFullProfile } from 'reducers/modules/Profile/getfulluserprofileReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import Axios from 'axios/index';
import { API_PREFIX, BASE_URL } from '../../../config/apiConfig';

const ACCESING_USER_PROFILE = createFetchTypes(
  'Marketplace/Agency/ACCESING_USER_PROFILE'
);

export const accessingUserProfileReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ACCESING_USER_PROFILE.REQUEST:
      return state;
    case ACCESING_USER_PROFILE.SUCCESS:
      return action.payload.model || action.payload;
    case ACCESING_USER_PROFILE.FAILURE:
      return state;
    default:
      return state;
  }
};

const url = [];
url.agencyProfile = id => `/profile/agencyprofile?id=${id}`;
url.userProfile = id => `/profile/accessinguserprofile?id=${id}`;
url.myAccount = () => '/profile/userprofile';

const getUrl = param => url[param];

export const accessingUserProfile = {
  base: (data = null, params = {}) => ({
    type: ACCESING_USER_PROFILE.BASE,
    data,
    url: getUrl(params)
  }),
  request: () => ({ type: ACCESING_USER_PROFILE.REQUEST }),
  success: payload => ({ type: ACCESING_USER_PROFILE.SUCCESS, payload }),
  failure: error => ({ type: ACCESING_USER_PROFILE.FAILURE, error })
};

function* accessingUserProfileSaga(action) {
  yield put(showLoader());
  const httpConfig = {
    baseURL: BASE_URL + API_PREFIX,
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache'
    }
  };
  ApiClient.httpClient = Axios.create(httpConfig);
  try {
    yield call(() => fetchEntity(
      accessingUserProfile,
      ApiClient.httpClient.get,
      action.url(action.data.id)
    ));
  } catch (error) {
    console.error(error);
  }
  yield put(hideLoader());
}

export function* watchaccessingUserProfileSaga() {
  yield takeLatest(ACCESING_USER_PROFILE.BASE, accessingUserProfileSaga);
}

function* accessingUserProfileSagaSucces(params) {
  yield put(getFullProfile.base(params.payload.username));
}

export function* watchaccessingUserProfileSagaSucces() {
  yield takeLatest(
    ACCESING_USER_PROFILE.SUCCESS,
    accessingUserProfileSagaSucces
  );
}

export default accessingUserProfileReducer;
