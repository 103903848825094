import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { fork } from 'redux-saga/effects';
import { authSetViewReducer } from 'reducers/modules/Auth/Authorization/authorizationReducer';
import { organizationDirectoryMiddleware } from 'reducers/modules/Administration/OrganizationDirectory/middleware';
import watchGetMatchingHostSaga from 'reducers/modules/Profile/Agency/AgencyDetails/getHostIdsReducer';
import watchGetAgencyProfileSaga from 'reducers/modules/Profile/Agency/AgencyProfile/AgencyProfile';
import { watchaccessingUserProfileSaga, watchaccessingUserProfileSagaSucces } from 'reducers/modules/Profile/accessingUserProfile';
import { watchGetFullProfileSaga } from 'reducers/modules/Profile/getfulluserprofileReducer';
import {
  manageLicensesSearchSagaWatcher,
  editLicenseStatusMiddleWare
} from 'reducers/modules/Administration/ManageLicenses';
import { loadingBarReducer } from 'react-redux-loading-bar';
import popularProductsReducer from './popularProductsReducer.jsx';
import { searchResultReducer, searchResultText } from './searchResultReducers.jsx';
import filterDetailsReducer from './filterDetailsReducer';
import browseProductReducer from './browseProductReducer';
import organizationReducer from './organizationReducer';
import productReducer, {
  watchSaveDemoRequestSaga,

  watchDirectDownloadProductSaga,
  watchrRedirectToProductSaga,
  watchGetAppsForPlatformSaga
} from './Product/Product/productReducers';
import productRatingReducer from './Product/Ratings&Reviews/productRatingReducer';
import productReviewsReducer, { watchSaveReviewReplySaga } from './Product/Ratings&Reviews/productReviewsReducer';
import saveReviewReducer from './Product/Ratings&Reviews/saveReviewReducer';
import deleteReviewReducer from './Product/Ratings&Reviews/deleteReviewReducer';
import filterDeveloperDetailsReducer from './filterDeveloperDetailsReducer';
import browseDeveloperReducers from './browseDeveloperReducers';
import profile, {
  watchHostInformationSaga,
  watchDeleteHostInformationSaga,
  watchfetchAgencyHierarchySaga,
  watchEmulateAgencySaga,
  watchExportProductEventReportSaga,
  watchFetchproductEventReportSaga,
  watchFetchProductSelectListSaga,
  watchSaveAgencyLogoSaga,
  watchSaveAgencyDetailsSaga,
  watchChangeUserRoleSaga,
  watchCanAgencyDownloadSaga
} from './modules/Profile';

import application,
{
  watchSaveFormSaga,
  watchGetDistributionSaga,
  watchGetPriceByLocationSaga,
  watchSavePriceByLocationSaga,
  watchDeletePriceByLocationSaga,
  watchFetchCommunicationDetailsSaga,
  watchFetchClassificationDetailsSaga,
  watchFetchSupportDetailsSaga,
  watchSaveSupportDocumentationSaga,
  watchFetchListForClassificationDetailsSaga,
  watchFetchBasicInformationDetailsSaga,
  watchFetchMediaDetailsSaga,
  watchSaveApplicationLogoSaga,
  watchRemoveApplicationLogoSaga,
  watchSaveApplicationScreenshotSaga,
  watchRemoveApplicationScreenshotSaga,
  watchAddVideoMediaSaga,
  watchAddSupportDocumentSaga,
  watchGetReviewSaga,
  watchGetSolnUploadGuideUrl,
  watchUploadCloudBuildSaga,
  watchUploadCertificateSaga,
  watchSaveReviewSaga,
  watchUploadPluginSaga,
  watchGetUserNamesSaga,
  watchInitAudiencePageSaga,
  watchSaveAudiencePageSaga,
  watchGoToRouteSaga,
  watchppcpFormModifiedSaga,
  watchCheckPPCPConfirmation,
  watchVisibilityNonParticipantsSaga,
  watchVisibilityEmployeesSaga,
  watchGlobalVisibilitySaga,
  watchDeleteAccessCategorySaga,
  watchTermsAndConditionsDisplaySaga,
  watchDeleteCetificateSaga,
  watchDownloadCertificateSaga,
  CLEAR
} from './modules/Application';
import { countryReducer } from './countryReducer';
import ordersReducer from './Product/Order/ordersReducer';
import recommendReducer, { watchFetchRecommendationsSaga, watchSaveRecommendationsSaga } from './Product/Recommend/recommendReducer';
import { disableLinksReducer, canNotifyDisableLinksReducer } from './disableLinks';
import { getDeveloperOrgDetailsReducer } from './DeveloperProfile/developerProfileReducers';
import { getFieldsforDeveloperEditReducer } from './DeveloperProfile/developerProfileFieldReducers';
import alertPopUpReducer, { watchErrorSaga } from './popUps/alertPopUpReducer';
import countries, {
  watchgetAllCountriesSaga
} from './modules/countries';
import regions, {
  watchGetAllRegionsSaga
} from './modules/regions';
import agencyUserReducer, { agencyUserMiddleware } from './modules/Profile/Agency/AgencyUser';
import { watchDeleteSupportDocumentSaga } from './modules/Application/Support/supportReducer';

import {
  watchAccountSettingSaga, watchAccountSettingNewSolutionSaga, watchAccountSettingPluginOptionForAgencySaga,
  watchAccountSettingSolutionVisibilityForAgencySaga
} from './Profile/AccountSetting/accoutSettingReducer';
import { loaderReducer } from './popUps/loaderReducer';
import { confirmationModalReducer } from './popUps/confirmationModalReducer';
import { watchHostListSaga } from './modules/Profile/Agency/AgencyDetails';
import mySolutionReducer, { mySolutionMiddleware } from './modules/Profile/Solution/mySolution';
import agencyGroup, { agencyGroupMiddleware } from './modules/Profile/Agency/AgencyGroup/index';

import licenseAssignmentsMiddleware from './modules/Administration/LicenseAssignments/licenseAssignmentsMiddleware';
import { userRolesMiddleware } from './modules/Administration/UserRoles/userRoles';

import auth, {
  watchAutoUpgradeActionSaga,
  watchDeclineUpgradeActionSaga,
  watchCheckRolePromotionSaga,
  watchuserProfileNavigationActionSaga
} from './modules/Auth';

import administration,
{
  watchGetInitialDataSaga,
  watchGetUsersIDSaga,
  watchGetPccsIDSaga,
  watchGetGroupsSaga,
  watchGetSettingValuesSaga,
  watchOnSaveSaga,
  watchOnDeleteSaga,
  watchToggleActivationSaga,
  watchFetchPosDistributionViewModelsSaga,
  watchCreatePosVersionSaga,
  watchUpdatePosVersionSaga,
  watchDeletePosVersionSaga,
  watchSavePosNameSaga,
  watchTogglePublishSaga,
  watchFetchPCCsSaga,
  watchAddPCCsSaga,
  watchDeletePCCsSaga,
  watchGetBuildsSaga,
  watchGetUserDetailsSaga,
  watchUnreleaseOptionSaga,
  watchCreateVerificationSaga,
  watchUploadBuildSaga,
  watchReleaseOptionSaga,
  watchExportSmartPointConfigurationSaga,
  watchFetchManageAppCertificateDetailsSaga,
  watchSaveCertificationSaga,
  watchDeleteUnReleaseVersionOptionSaga,
  watchDeleteCertificationSaga,
  watchUnCertifyAppSaga,
  watchDownloadProductVersionSaga,
  watchAutoUpdateSaga
} from './modules/Administration';
import { distributionAdminMiddleware } from './modules/Administration/DistributionAdminAssignments/distributionAdminAssignments';
import { manageHostUserMiddleware } from './modules/Administration/ManageHostUsers/manageHostUsersReducer';
import {
  productReviewReportMiddleware, userLoginReportMiddleware, userFeedbackReportMiddleware, exportToExcelMiddleware, devStatsReportMiddleware
} from './modules/Administration/Reports';
import homeReducer, { watchGetAppNameGuidMappingSaga, watchGetBannerNotificationSaga, watchGetBuildNumberSaga } from './modules/Home/home';
import { auditLogMiddleware } from './modules/Administration/AuditLog/AuditLog';
import { bannerMiddleware } from './modules/Administration/Banner/Banner';
import { productAccessMiddleware } from './modules/Administration/ProductAccess/productAccess';
import feedbackReducer, { watchFeedbackSaga } from './Feedback/FeedbackFormReducer';

import oAuth, { watchLoadUserInfoSaga, watchLogOutSaga } from './modules/OAuth/oAuth';
import gitHistory, { watchGitHistorySaga, watchSaveGitSaga, watchValidatedPluginForCertSaga } from './modules/SpCloudCertification/SpCloudCertification';
import gitSPCPluginLinkageInfo, { watchValidatedPluginSaga, watchSavePluginSubmitForApprovalSaga } from './modules/SpCloudPluginsAssociation/SpCloudPluginAssociation';
import gitSPCAssignmentInfo, { watchSavePluginAssigmentSaga } from './modules/SpCloudPluginsAssignment/SpCloudPluginAssignment';

const reducers = combineReducers({
  home: homeReducer,
  popularProductsReducer,
  searchResult: searchResultReducer,
  organization: organizationReducer,
  searchResultText,
  filterDetailsReducer,
  browseProductReducer,
  product: combineReducers({
    details: productReducer,
    rating: productRatingReducer,
    reviews: combineReducers({
      reviewList: productReviewsReducer,
      save: saveReviewReducer,
      remove: deleteReviewReducer
    }),
    order: ordersReducer,
    recommend: recommendReducer
  }),
  view: authSetViewReducer,
  profile,
  oidc: oAuth,
  filterDeveloperDetailsReducer,
  browseDeveloperReducers,
  country: countryReducer,
  disableLinks: disableLinksReducer,
  form: formReducer.plugin({
    account: (state, action) => {
      switch (action.type) {
        case CLEAR:
          return undefined;
        default:
          return state;
      }
    }
  }),
  canNotifyDisableLinks: canNotifyDisableLinksReducer,
  getFieldsforDeveloperEdit: getFieldsforDeveloperEditReducer,
  getDeveloperOrgDetails: getDeveloperOrgDetailsReducer,
  agencyGroup,
  agencyUser: agencyUserReducer,
  solution: mySolutionReducer,
  alertPopUp: alertPopUpReducer,
  coreLoader: loaderReducer,
  confirmationModal: confirmationModalReducer,
  application,
  countries,
  regions,
  administration,
  auth,
  loadingBar: loadingBarReducer,
  feedback: feedbackReducer,
  gitHistory,
  gitSPCPluginLinkageInfo,
  gitSPCAssignmentInfo
});

export function* rootSaga() {
  yield fork(watchHostInformationSaga);
  yield fork(mySolutionMiddleware);
  yield fork(agencyGroupMiddleware);
  yield fork(agencyUserMiddleware);
  yield fork(watchHostListSaga);
  yield fork(watchErrorSaga);
  yield fork(watchDeleteHostInformationSaga);
  yield fork(watchfetchAgencyHierarchySaga);
  yield fork(watchEmulateAgencySaga);
  yield fork(watchExportProductEventReportSaga);
  yield fork(watchFetchproductEventReportSaga);
  yield fork(watchFetchProductSelectListSaga);
  yield fork(watchSaveAgencyLogoSaga);
  yield fork(watchSaveAgencyDetailsSaga);
  yield fork(watchGetMatchingHostSaga);
  yield fork(watchGetAgencyProfileSaga);
  yield fork(watchSaveFormSaga);
  yield fork(watchChangeUserRoleSaga);
  yield fork(watchCanAgencyDownloadSaga);
  yield fork(watchAccountSettingSaga);
  yield fork(watchAccountSettingNewSolutionSaga);
  yield fork(watchAccountSettingPluginOptionForAgencySaga);
  yield fork(watchAccountSettingSolutionVisibilityForAgencySaga);
  yield fork(watchaccessingUserProfileSaga);
  yield fork(watchaccessingUserProfileSagaSucces);
  yield fork(watchGetFullProfileSaga);
  yield fork(watchGetDistributionSaga);
  yield fork(watchFetchCommunicationDetailsSaga);
  yield fork(watchGetReviewSaga);
  yield fork(watchGetSolnUploadGuideUrl);
  yield fork(watchUploadCertificateSaga);
  yield fork(watchDeleteCetificateSaga);
  yield fork(watchDownloadCertificateSaga);
  yield fork(watchSaveReviewSaga);
  yield fork(watchFetchClassificationDetailsSaga);
  yield fork(watchFetchSupportDetailsSaga);
  yield fork(watchSaveSupportDocumentationSaga);
  yield fork(watchDeleteSupportDocumentSaga);
  yield fork(watchFetchListForClassificationDetailsSaga);
  yield fork(watchGoToRouteSaga);
  yield fork(watchppcpFormModifiedSaga);
  yield fork(watchCheckPPCPConfirmation);
  yield fork(watchGetSettingValuesSaga);
  yield fork(watchTermsAndConditionsDisplaySaga);
  yield fork(watchUploadCloudBuildSaga);

  yield fork(watchGetPriceByLocationSaga);
  yield fork(watchGetAllRegionsSaga);
  yield fork(watchgetAllCountriesSaga);
  yield fork(watchSavePriceByLocationSaga);
  yield fork(watchDeletePriceByLocationSaga);
  yield fork(watchFetchBasicInformationDetailsSaga);
  yield fork(watchSaveAudiencePageSaga);

  yield fork(watchFetchMediaDetailsSaga);
  yield fork(watchSaveApplicationLogoSaga);
  yield fork(watchRemoveApplicationLogoSaga);
  yield fork(watchSaveApplicationScreenshotSaga);
  yield fork(watchRemoveApplicationScreenshotSaga);
  yield fork(watchAddVideoMediaSaga);
  yield fork(watchAddSupportDocumentSaga);
  yield fork(watchFetchPCCsSaga);
  yield fork(watchAddPCCsSaga);
  yield fork(watchDeletePCCsSaga);
  yield fork(organizationDirectoryMiddleware);
  yield fork(watchUploadPluginSaga);
  yield fork(watchFetchPosDistributionViewModelsSaga);
  yield fork(watchCreatePosVersionSaga);
  yield fork(watchUpdatePosVersionSaga);
  yield fork(watchDeletePosVersionSaga);
  yield fork(watchSavePosNameSaga);
  yield fork(watchTogglePublishSaga);
  yield fork(watchSaveDemoRequestSaga);
  yield fork(watchSaveReviewReplySaga);
  yield fork(watchDirectDownloadProductSaga);
  yield fork(watchrRedirectToProductSaga);
  yield fork(watchFetchRecommendationsSaga);
  yield fork(watchSaveRecommendationsSaga);
  yield fork(watchGetAppsForPlatformSaga);

  yield fork(watchGetUserNamesSaga);
  yield fork(watchInitAudiencePageSaga);

  yield fork(watchGetInitialDataSaga);
  yield fork(watchGetUsersIDSaga);
  yield fork(watchOnSaveSaga);
  yield fork(watchOnDeleteSaga);
  yield fork(watchToggleActivationSaga);
  yield fork(watchGetPccsIDSaga);
  yield fork(watchGetGroupsSaga);
  yield fork(watchExportSmartPointConfigurationSaga);
  yield fork(watchDeleteAccessCategorySaga);
  yield fork(watchGetBuildsSaga);
  yield fork(watchGetUserDetailsSaga);
  yield fork(watchAutoUpdateSaga);
  yield fork(watchAutoUpgradeActionSaga);
  yield fork(watchDeclineUpgradeActionSaga);
  yield fork(watchCheckRolePromotionSaga);
  yield fork(watchUnreleaseOptionSaga);
  yield fork(watchCreateVerificationSaga);
  yield fork(watchVisibilityNonParticipantsSaga);
  yield fork(watchVisibilityEmployeesSaga);
  yield fork(watchGlobalVisibilitySaga);
  yield fork(licenseAssignmentsMiddleware);
  yield fork(watchUploadBuildSaga);
  yield fork(watchReleaseOptionSaga);
  yield fork(watchFetchManageAppCertificateDetailsSaga);
  yield fork(watchSaveCertificationSaga);
  yield fork(watchDeleteCertificationSaga);
  yield fork(watchUnCertifyAppSaga);
  yield fork(watchDownloadProductVersionSaga);
  yield fork(watchDeleteUnReleaseVersionOptionSaga);

  yield fork(userRolesMiddleware);
  yield fork(distributionAdminMiddleware);
  yield fork(manageHostUserMiddleware);

  yield fork(watchuserProfileNavigationActionSaga);

  yield fork(manageLicensesSearchSagaWatcher);
  yield fork(editLicenseStatusMiddleWare);

  yield fork(watchGetAppNameGuidMappingSaga);

  yield fork(productReviewReportMiddleware);
  yield fork(userLoginReportMiddleware);
  yield fork(userFeedbackReportMiddleware);
  yield fork(devStatsReportMiddleware);
  yield fork(exportToExcelMiddleware);

  yield fork(productAccessMiddleware);
  yield fork(watchFeedbackSaga);

  yield fork(watchLoadUserInfoSaga);
  yield fork(watchLogOutSaga);
  yield fork(auditLogMiddleware);
  yield fork(bannerMiddleware);
  yield fork(watchGetBannerNotificationSaga);

  yield fork(watchGetBuildNumberSaga);
  yield fork(watchGitHistorySaga);
  yield fork(watchSaveGitSaga);
  yield fork(watchValidatedPluginForCertSaga);
  yield fork(watchValidatedPluginSaga);
  yield fork(watchSavePluginSubmitForApprovalSaga);
  yield fork(watchSavePluginAssigmentSaga);
}

export default reducers;
