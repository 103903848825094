import { combineReducers } from 'redux';
import getAllFilterOptionsReducer from 'reducers/modules/Administration/OrganizationDirectory/getFilters';
import getOrganizationListReducer from 'reducers/modules/Administration/OrganizationDirectory/getOrganizationList';
import getChildOrgReducer from 'reducers/modules/Administration/OrganizationDirectory/getChildOrgs';
import setOrganiztionVisibilityReducer from 'reducers/modules/Administration/OrganizationDirectory/setOrganiztionVisibility';
import getAdminsForOrgsReducer from 'reducers/modules/Administration/OrganizationDirectory/ManageAdmins/getAdminsForOrg';
import manageApplicationCertificateReducer from 'reducers/modules/Administration/ManageApplicationCertification/manageAppCertificateReducer';
import auditLog from 'reducers/modules/Administration/AuditLog/AuditLog';
import banner from 'reducers/modules/Administration/Banner/Banner';
import { viewPccs } from './OrganizationDirectory/ViewPccsModal/viewPccs';
import productVersionControl from './ProductVersionControl/productVersionControl';
import buildVersionControl from './BuildVersionControl/buildVersionControl';
import licenseAssignments from './LicenseAssignments';
import smartpointConfigSettings from './SmartpointConfigSettings/smartpointConfigSettings';
import userRoles from './UserRoles/userRoles';
import manageLicenses from './ManageLicenses';
import distributionAdminAssignments from './DistributionAdminAssignments/distributionAdminAssignments';
import manageHostUsersReducer from './ManageHostUsers/manageHostUsersReducer';
import reports from './Reports';
import productAccessReducer from './ProductAccess/productAccess';

export {
  getSettingsSelector,
  getSmartpointSettingsSelector,
  getSettingsAttributeSelector,
  getUserIDSelector,
  getCountriesSelector,
  getRegionsSelector,
  getGroupsSelector,
  getSettingsValueSelector,
  getPccSelector
} from './SmartpointConfigSettings/selector';

export {
  getInitialData,
  getUsersID,
  getPccs,
  onSave,
  onDelete,
  toggleActivation,
  watchGetInitialDataSaga,
  getSettingValues,
  watchGetUsersIDSaga,
  watchGetPccsIDSaga,
  watchGetGroupsSaga,
  watchGetSettingValuesSaga,
  watchOnSaveSaga,
  watchOnDeleteSaga,
  watchToggleActivationSaga,
  exportSmartConfiguration,
  watchExportSmartPointConfigurationSaga,
} from './SmartpointConfigSettings/smartpointConfigSettings';

export {
  getBuilds,
  enableAutoUpdate,
  unreleaseOption,
  createVerification,
  uploadBuild,
  releaseOption,
  deleteUnreleasedVersion,
  watchGetBuildsSaga,
  watchGetUserDetailsSaga,
  watchAutoUpdateSaga,
  watchUnreleaseOptionSaga,
  watchCreateVerificationSaga,
  watchUploadBuildSaga,
  watchReleaseOptionSaga,
  watchDeleteUnReleaseVersionOptionSaga,
} from './BuildVersionControl/buildVersionControl';

export {
  fetchPosDistributionViewModelsSaga,
  watchFetchPosDistributionViewModelsSaga,
  createPosVersionSaga,
  watchCreatePosVersionSaga,
  updatePosVersionSaga,
  watchUpdatePosVersionSaga,
  deletePosVersionSaga,
  watchDeletePosVersionSaga,
  savePosNameSaga,
  watchSavePosNameSaga,
  togglePublishSaga,
  watchTogglePublishSaga
} from './ProductVersionControl/productVersionControl';

export {
  watchFetchPCCsSaga,
  watchAddPCCsSaga,
  watchDeletePCCsSaga
} from './OrganizationDirectory/ViewPccsModal/viewPccs';

export {
  getInitialCertification,
  fetchManageAppCertificateDetailsSaga,
  watchFetchManageAppCertificateDetailsSaga,
  watchSaveCertificationSaga,
  watchDeleteCertificationSaga,
  saveCertificationSaga,
  saveCertificationForm,
  clearErrorMessage,
  watchUnCertifyAppSaga,
  uncertifyForm,
  onDownloadVersion,
  watchDownloadProductVersionSaga,
  onDeleteCertification
} from './ManageApplicationCertification/manageAppCertificateReducer';

export {
  fetchAuditLogSaga,
  auditLogMiddleware
} from './AuditLog/AuditLog';

export { productReviewReportMiddleware, exportToExcelMiddleware } from './Reports';
export {
  fetchBannerInfo,
  bannerMiddleware
} from './Banner/Banner';

const administration = combineReducers({
  productVersionControl,
  buildVersionControl,
  smartpointConfigSettings,
  organizationDirectory: combineReducers({
    getAllFilterOptionsReducer,
    getOrganizationListReducer,
    getChildOrgReducer,
    setOrganiztionVisibilityReducer,
    viewPccs,
    manageAdmins: combineReducers({
      getAdminsForOrgsReducer,
    })
  }),

  manageApplicationCertificateReducer,
  auditLog,
  banner,
  licenseAssignments,
  userRoles,
  manageLicenses,
  distributionAdminAssignments,
  manageHostUsers: manageHostUsersReducer,
  reports,
  productAccess: productAccessReducer
});

export default administration;
