export const SEOFriendly = (text) => {
  if (text) {
    const regExp = /["$&+,/:;=?@'"<>#%{}|\\^~[]`.*_"]/gi;
    let removedChar = text.replace(regExp, '').split(' ').join('-');
    removedChar = removedChar.replaceAll('/', '');
    removedChar = removedChar.replaceAll('.', '');
    return removedChar;
  }
  return '';
};
