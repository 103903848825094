import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as AtlasTooltip, InformationIcon } from '@atlas-design-system/react';
import './Tooltip.less';

const ToolTip = (props) => (
  <AtlasTooltip placement="top" content={props.labelName}>
    {!props.hideInfoIcon ? <InformationIcon width="18" className="atlas-tooltip-icon-header" /> : props.children}
  </AtlasTooltip>
);

ToolTip.propTypes = {
  show: PropTypes.bool,
  hideInfoIcon: PropTypes.bool,
  children: PropTypes.any
};

ToolTip.default = {
  hideInfoIcon: false
};

export default ToolTip;
