import * as actionType from '../actionType';

export const loadFieldsforDeveloperEdit = (data) => ({
  type: actionType.LOAD_FIELDS_FOR_DEVELOPER_EDIT,
  data
});

export const getDeveloperOrgDetails = (data) => ({
  type: actionType.GET_DEVELOPER_ORG_DETAILS,
  data
});

export const loadDeveloperOrgDetails = () => ({
  type: actionType.LOAD_DEVELOPER_ORG_DETAILS
});

export const loadDeveloperOrgSave = (data) => ({
  type: actionType.LOAD_DEVELOPER_ORG_SAVE,
  data
});

export const loadDeveloperOrgSaveSuccess = (data) => ({
  type: actionType.LOAD_DEVELOPER_ORG_SAVE_SUCCESS,
  data
});
