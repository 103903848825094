/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';

const RatingCountPresentation = (props) => {
  const { text, starCount } = props;
  const progressBarFillPercent = props.totalCount === 0 ? 0 : (starCount / props.totalCount) * 100;
  return (
    <div className="rating-count">
      <div className="col-sm-3">{text}</div>
      <div className="col-sm-8 progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={progressBarFillPercent}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${progressBarFillPercent}%` }}
        />
      </div>
      <div className="col-sm-1 rating-total">{starCount}</div>
    </div>
  );
};

RatingCountPresentation.propTypes = {
  text: PropTypes.string,
  starCount: PropTypes.number,
  totalCount: PropTypes.number
};

export default RatingCountPresentation;
