import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PriceType extends Component {
  render() {
    const Free = () => <span gtm-event-label={this.props.gtmEventLabel} className="inverse-text inverse-text--free gtm-featured-product-link">Free</span>;

    const FreeTrial = () => <span gtm-event-label={this.props.gtmEventLabel} className="inverse-text inverse-text--free-trial gtm-featured-product-link">Free Trial</span>;

    const Paid = () => <span gtm-event-label={this.props.gtmEventLabel} className="inverse-text inverse-text--paid gtm-featured-product-link">Paid</span>;

    const New = () => <span gtm-event-label={this.props.gtmEventLabel} className="icon-product-tile-new gtm-featured-product-link" />;
    return (
      <div>
        {(this.props.type === 'Free') ? <Free /> : null}
        {(this.props.type === 'FreeTrial') ? <FreeTrial /> : null}
        {(this.props.type === 'Paid') ? <Paid /> : null}
        {(this.props.type === 'New') ? <New /> : null}
      </div>
    );
  }
}

PriceType.propTypes = {
  type: PropTypes.string.isRequired,
  gtmEventLabel: PropTypes.string
};

export default PriceType;
