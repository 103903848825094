import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Redirect, withRouter, BrowserRouter as Router, Switch
} from 'react-router-dom';
import * as Constants from 'constants/PPCP';
import {
  BasicInformation,
  Distribution,
  Communication,
  Support,
  Classification,
  Media,
  ReviewSubmit,
  AudienceAccess,
  MainLayout,
  EmptyLayout,
  ReviewSubmitCompleted,
  InternalAppTerms,
  TermsAndConditionsDisplay
} from '.';

const AppRoute = ({ component: Components, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={
      props => (
        <Layout {...rest}>
          <Components {...props} />
        </Layout>
      )
    }
  />
);

AppRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any
};

class ApplicationRoutes extends Component {
  defaultRoute = () => {
    if (this.props.location.pathname === '/Application') {
      return <Redirect to={Constants.BASIC_INFORMATION_ROUTE} />;
    }
    return null;
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <AppRoute path={Constants.BASIC_INFORMATION_ROUTE} layout={MainLayout} exact component={BasicInformation} />
            <AppRoute path={Constants.DISTRIBUTION_ROUTE} layout={MainLayout} exact component={Distribution} />
            <AppRoute path={Constants.COMMUNICATION_ROUTE} layout={MainLayout} exact component={Communication} />
            <AppRoute path={Constants.SUPPORT_ROUTE} exact layout={MainLayout} component={Support} />
            <AppRoute path={Constants.CLASSIFICATION_ROUTE} layout={MainLayout} exact component={Classification} />
            <AppRoute path={Constants.AUDIENCE_ROUTE} exact layout={MainLayout} component={AudienceAccess} />
            <AppRoute path={Constants.MEDIA_ROUTE} exact layout={MainLayout} component={Media} />
            <AppRoute path={Constants.REVIEW_ROUTE} exact layout={EmptyLayout} component={ReviewSubmit} />
            <AppRoute path="/application/ReviewSubmitCompleted" exact layout={EmptyLayout} component={ReviewSubmitCompleted} />
            <AppRoute path="/application/InternalAppTerms" exact layout={EmptyLayout} component={InternalAppTerms} />
            <AppRoute path="/application/TermsAndConditionsDisplay/:id" exact layout={EmptyLayout} component={TermsAndConditionsDisplay} />
            {this.defaultRoute()}
          </Switch>
        </Router>
      </>
    );
  }
}

ApplicationRoutes.propTypes = {
  updateCurrentFormAction: PropTypes.func,
  getFormAction: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(ApplicationRoutes);
