import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';

class NavigationConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showModal: this.props.showModal
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.showModal
    });
  }

  handleClick = (e) => {
    if (this.props.blockContinueButton) {
      e.preventDefault();
    }
    window.open(this.props.NetworkURL, '_blank', 'noopener,noreferrer');
    this.hideModal();
  }

  hideModal = () => {
    this.setState({
      showModal: false
    });

    if (this.props.onHideModal) {
      this.props.onHideModal();
    }
  }

  render() {
    return (
      <Dialog
        appearance="warning"
        id="sbDialog"
        hidden={!this.state.showModal}
        buttons={
          (
            <>
              <Button
                id="continueOutOfSiteLink"
                onClick={(e) => this.handleClick(e)}
                className="action-button"
              >
                Continue
              </Button>
              <Button
                id="cancelOutOfSiteLink"
                onClick={() => this.hideModal()}
                className="cancel-button space-left"
              >
                Cancel
              </Button>
            </>
          )
        }
      >
        <p className="medium-text">
          You are now leaving
          TravelportMarketplace.com
          and being directed to an external site.
        </p>
      </Dialog>
    );
  }
}
NavigationConfirmationPopup.propTypes = {
  NetworkURL: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func,
  blockContinueButton: PropTypes.bool
};

export default NavigationConfirmationPopup;
