import { put } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient.js';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

import { API_GET_CHILD_ORGS } from 'config/apiConfig';

export const GET_CHILD_ORGS = createFetchTypes('Marketplace/Administration/OrganizationDirectory/GET_CHILD_ORGS');

export const getChildOrgs = {
  base: (params) => ({
    type: GET_CHILD_ORGS.BASE,
    params
  }),
  request: () => ({
    type: GET_CHILD_ORGS.REQUEST
  }),
  success: (data) => ({
    type: GET_CHILD_ORGS.SUCCESS,
    payload: { data }
  }),
  failure: (error) => ({
    type: GET_CHILD_ORGS.FAILURE,
    payload: {
      error
    }
  })
};

const getChildOrgReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_CHILD_ORGS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CHILD_ORGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.childOrganisations,
      };

    case GET_CHILD_ORGS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export function* getChildOrgReducerWatcher(params) {
  yield put(showLoader());
  try {
    const response = yield ApiClient.httpClient.get(
      API_GET_CHILD_ORGS,
      params
    );
    yield put(getChildOrgs.success(response.data));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(getChildOrgs.failure(error));
  }
  yield put(hideLoader());
}

export const getChildOrgSelector = state => state.administration.organizationDirectory.getChildOrgReducer.data;

export default getChildOrgReducer;
