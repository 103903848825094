import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/pagination.css';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';
import './Paginataion.less';

const PaginationComponent = props => {
  const pageSize = parseInt(props._pageSize, 0);
  const pageIndex = parseInt(props.currentPage, 0) - 1;
  let currentPageIndex = parseInt(props.currentPage, 0);
  const totalPages = parseInt(props.totalPages, 0);
  let totalRecords = 0;
  totalRecords = parseInt(props.totalRecords, 0);
  const canNext = pageIndex >= totalPages - 1;
  const canPrev = pageIndex <= 0;

  const handlePageChanges = (page) => {
    props.handleChangePageNo(page);
  };

  const onShowRowsChange = () => {
    // handlePageChanges(pageIndex);
  };

  const nextPage = (e) => {
    if (pageIndex >= totalPages) {
      e.preventDefault();
    } else {
      currentPageIndex += 1;
      handlePageChanges(currentPageIndex);
    }
  };

  const previousPage = (e) => {
    if (pageIndex < 1) {
      e.preventDefault();
    } else {
      currentPageIndex -= 1;
      handlePageChanges(currentPageIndex);
    }
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      {
        !Number.isNaN(totalRecords) && (
          <Pagination
            canNextPage={!canNext}
            canPreviousPage={!canPrev}
            onShowRowsChange={onShowRowsChange}
            dataSize={Number.isNaN(totalRecords) ? 0 : totalRecords}
            defaultPageSize={pageSize}
            nextPage={nextPage}
            pageIndex={parseInt(props.currentPage, 0) - 1}
            pageSize={pageSize}
            pageSizeOptions={[
              { label: '16', value: 16 }
            ]}
            previousPage={previousPage}
          />
        )
      }
    </div>
  );
};

PaginationComponent.propTypes = {
  handleChangePageNo: PropTypes.func,
  totalPages: PropTypes.string,
  currentPage: PropTypes.string,
  _pageSize: PropTypes.number,
  _pageIndex: PropTypes.number,
  totalRecords: PropTypes.number
};

export default PaginationComponent;
