/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ButtonGroup, Button } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { getRequestDemoData } from '../../../reducers/Product/selectors';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';
import '@atlas-design-system/core/dist/styles/partials/button-group.css';

const DemoRequestModal = (props) => (
  <Modal
    id="demo-Request-modal"
    title={`Request a Demo for ${props.initialValues.productname}`}
    hidden={!props.showDemoReqModal}
    size="small"
    onDismiss={props.hideDemoReqModal}
    className="tp-atlas-modal-demo-request center-screen"
    actionButtons={
      (
        <ButtonGroup align="right">
          <Button appearance="primary" size="small" onClick={props.handleSubmit}>SEND</Button>
          <Button appearance="secondary" size="small" onClick={props.hideDemoReqModal}>CANCEL</Button>
        </ButtonGroup>
      )
    }
  >
    <p>
      You are about to send an email to the developer of this solution requesting a possible Product Demonstration.
      Please enter your information below to be included in the email.
    </p>
    <form onSubmit={props.handleSubmit} id="demoRequestForm">
      <div className="row demo-row">
        <label htmlFor="firstname">Name:</label>
        <Field component="input" className="text-input" type="text" name="firstname" />
        <Field component="input" type="hidden" name="productname" />
        <Field component="input" type="hidden" name="productid" />
        <Field component="input" type="hidden" name="userid" />
        <Field component="input" type="hidden" name="hosts" />
        <Field component="input" type="hidden" name="salescontactname" />
        <Field component="input" type="hidden" name="salesemailaddress" />
        <Field component="input" type="hidden" name="pccsstring" />
        <Field component="input" type="hidden" name="demoemailreq" />
      </div>
      <div className="row demo-row">
        <label htmlFor="emailaddress">Email Address:</label>
        <Field component="input" className="text-input" type="text" name="emailaddress" />
      </div>
      <div className="row demo-row">
        <label htmlFor="phoneno">Phone Number:</label>
        <Field component="input" className="text-input" type="text" name="phonenumber" />
      </div>
      <div className="row demo-row">
        <label htmlFor="organization">Organization:</label>
        <Field component="input" className="text-input" type="text" name="organization" />
        <Field component="input" className="text-input" type="hidden" name="organisationcountry" />
      </div>
      <div className="row demo-row">
        <label htmlFor="location">Location:</label>
        <Field component="input" className="text-input" type="text" name="location" />
      </div>
      <div className="row demo-row">
        <label htmlFor="additionalinfo">Additional Information:</label>
        <Field style={{ color: 'black' }} component="textarea" rows="4" cols="50" type="text" name="additionalinfo" />
      </div>
    </form>
  </Modal>
);

DemoRequestModal.propTypes = {
  hideDemoReqModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  showDemoReqModal: PropTypes.bool,
  initialValues: PropTypes.object
};

const mapStateToProps = (state) => ({
  initialValues: getRequestDemoData(state)
});

export default connect(mapStateToProps, null)(
  reduxForm({
    form: 'demorequestmodal'
  })(DemoRequestModal)
);
