/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './GlobalSearch.less';

const GlobalSearch = ({ searchFilter, clearSearchBox }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const [filter] = useState([{ name: 'name', value: 'Name' }, { name: 'keywords', value: 'Keywords' }, { name: 'description', value: 'Description' }, { name: 'All', value: 'All' }]);
  const [selectedFilter, setSelectedFilter] = useState({ name: 'name', value: 'Name' });
  const [inputVal, setInputVal] = useState('');

  useEffect(() => {
    if (clearSearchBox) {
      setInputVal('');
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  const handleChange = (obj) => {
    setSelectedFilter(obj);
    setOpen(!open);
    setInputVal('');
  };
  return (
    <div className="input-group global-search-box" ref={ref}>
      <div className={open ? 'input-group-btn open' : 'input-group-btn'}>
        <button type="button" className="btn btn-default dropdown-toggle" onClick={() => setOpen(!open)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {selectedFilter.value}
          {' '}
          <span className="caret" />
        </button>
        <ul className="dropdown-menu">
          {filter.map((i) => <li key={i.name}><Link onClick={(e) => { e.preventDefault(); handleChange(i); }} to="#">{i.value}</Link></li>)}
        </ul>
      </div>
      <form onSubmit={(e) => { e.preventDefault(); searchFilter(selectedFilter.name, inputVal); }}><input type="text" value={inputVal} onChange={(e) => setInputVal(e.target.value)} placeholder="search" className="form-control" aria-label="..." /></form>
      <div className="input-group-btn">
        <button className="btn btn-default" type="button" onClick={() => searchFilter(selectedFilter.name, inputVal)}><i className="glyphicon glyphicon-search" /></button>
      </div>
    </div>
  );
};

GlobalSearch.propTypes = {
  searchFilter: PropTypes.func,
  clearSearchBox: PropTypes.bool
};
export default GlobalSearch;
