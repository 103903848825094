import * as actionType from './actionType';

export const loadPopularProducts = (data) => ({
  type: actionType.LOADPOPULARPRODUCTS,
  data
});

export const loadSearchResults = (data) => ({
  type: actionType.SEARCHRESULTS,
  data
});

export const getSearchResultText = (data) => ({
  type: actionType.SEARCHRESULTSTEXT,
  data
});

export const organizationLoad = (data) => ({
  type: actionType.LOAD_ORGANIZATION,
  data
});

export const organizationLoadSuccess = (data) => ({
  type: actionType.LOAD_ORGANIZATION_SUCCESS,
  data
});

export const organizationLoadFailure = (error) => ({
  type: actionType.LOAD_ORGANIZATION_FAILURE,
  error
});

export const loadFilterDetails = (data) => ({
  type: actionType.FILTERDETAILS,
  data
});

export const loadBrowseProduct = (data) => ({
  type: actionType.BROWSEPRODUCT,
  data
});

export const productLoad = () => ({
  type: actionType.LOAD_PRODUCT
});

export const productLoadSuccess = (data) => ({
  type: actionType.LOAD_PRODUCT_SUCCESS,
  data
});

export const productLoadFailure = (error) => ({
  type: actionType.LOAD_PRODUCT_FAILURE,
  error
});

export const loadDeveloperFilterDetails = (data) => ({
  type: actionType.DEVELOPER_FILTERDETAILS,
  data
});

export const loadDeveloperBrowse = (data) => ({
  type: actionType.DEVELOPER_BROWSE,
  data
});

export const loadUserProfile = (data) => ({
  type: actionType.LOAD_USER_PROFILE,
  data
});

export const loadAllCountry = (data) => ({
  type: actionType.LOAD_ALL_COUNTRY,
  data
});

export const disableLinks = () => ({
  type: actionType.DISABLE_LINKS
});

export const enableLinks = () => ({
  type: actionType.ENABLE_LINKS
});

export const canNotifyDisableLinks = () => ({
  type: actionType.SEND_LINK_CLICK_MESSAGE
});

export const hideNotifyDisableLinks = () => ({
  type: actionType.HIDE_SEND_LINK_CLICK_MESSAGE
});
