import PropTypes from 'prop-types';
import React from 'react';
import {
  buildStyles, CircularProgressbar
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CircularProgressHelper from './CircularProgressHelper';
import './KnobComponent.less';

const KnobComponent = props => {
  const className = props.completed ? 'knob-completed' : 'knob-incomplete';
  return (
    <div className="row no-space percent-complete">
      <div className="col-sm-7 no-space pad-top-sm">
        <span className={props.completed ? 'complete-text' : 'incomplete-text'}>
          {props.percentageCompletion}
          {'%'}
        </span>
        <br />
        <span className="complete-text-lg">complete</span>
      </div>
      <div className="col-sm-5 no-space">
        <CircularProgressHelper values={[0, props.percentageCompletion]} className={className}>
          {percentage => (
            <CircularProgressbar
              styles={buildStyles({
                textSize: '10px',
                textColor: props.completed ? '#78BE20' : '#3e98c7',
                pathColor: props.completed ? '#78BE20' : '#3e98c7'
              })}
              value={percentage}
              text={`${percentage}% complete`}
            />
          )}
        </CircularProgressHelper>
      </div>
    </div>
  );
};

KnobComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  radius: PropTypes.number,
  innerRadius: PropTypes.number,
  completed: PropTypes.bool,
  percentageCompletion: PropTypes.number
};

KnobComponent.defaultProps = {
  width: 97,
  height: 65,
  radius: 31,
  innerRadius: 22
};

export default KnobComponent;
