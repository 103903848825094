/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const commandComponentProps = {
  add: {
    text: 'Add New Version'
  },
  edit: {
    text: 'Edit'
  },
  delete: {
    text: 'Delete'
  },
  commit: {
    text: 'SAVE'
  },
  cancel: {
    text: 'Cancel'
  },
};

const CommandButton = ({
  onExecute, id
}) => {
  if (commandComponentProps[id].text === 'SAVE') {
    return (
      <button
        type="button"
        onClick={(e) => {
          onExecute();
          e.preventDefault();
          e.stopPropagation();
        }}
        className="action-button small-button space-left-sm space-right-sm"
      >
        {commandComponentProps[id].text}
      </button>
    );
  }
  return (
    <Link
      to="#"
      onClick={(e) => {
        onExecute();
        e.preventDefault();
        e.stopPropagation();
      }}
      className="space-left-sm space-right-sm"
    >
      {commandComponentProps[id].text}
    </Link>
  );
};

CommandButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onExecute: PropTypes.func,
};

export default CommandButton;
