/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import { toast } from '@atlas-design-system/react';
import { roleNameConstants } from 'utils/rolesHelper';
import { isInternalPPCP, isTravelportPOSApplicationPPCP } from 'reducers/modules/Application/selectors';
import {
  fetchClassificationDetails,
  fetchListForClassificationDetails,
  updateCurrentForm,
  displayErrorMessage,
  getClassificationSelector,
  hostIdsEmpty,
  storeKeywords,
  storeIds
} from '../../../reducers/modules/Application/index';
import '../../../Styles/datepicker.css';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import SimplePrettyRadioButton from '../../common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import ToolTip from '../../common/ToolTip';
import * as utils from '../../../utils/Common.Utils';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import { CLASSIFICATION_ID } from '../../../constants/PPCP';
import SearchTag from '../../common/SearchTag/SearchTag';

import FormHeader from '../FormHeader/FormHeader';

const renderCheckbox = ({
  input,
  displayText,
  tooltip,
  enabled,
  style
}) => (
  <SimplePrettyCheckBox
    {...input}
    value={!!input.value}
    displayText={displayText}
    onChange={input.onChange}
    tooltip={tooltip}
    enabled={enabled}
    style={style}
  />
);

const renderRadioButton = ({
  input,
  displayText,
  tooltip
}) => (
  <SimplePrettyRadioButton
    {...input}
    displayText={displayText}
    onChange={input.onChange}
    tooltip={tooltip}
  />
);

const renderField = ({
  input,
  type,
  meta: { touched, error }
}) => (
  <>
    <input className={touched && error ? 'input-validation-error text-input' : 'text-input'} {...input} type={type} />
    {touched
      && (error && <span className="field-validation-error">{error}</span>)}
  </>
);

class Classification extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hostServiceDisable: false,
      checkedSmartpoint: false,
      regionDisableExceptGlobal: false,
      regionGlobalDisbale: false,
      isPaid: false,
      isPriceTypeChanged: false
    };
  }

  componentDidMount() {
    this.props.updateCurrentFormAction();

    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');

    this.props.fetchClassificationAction({ publicId, organizationId });
    this.props.fetchListForClassificationAction();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dirty !== this.props.dirty) {
      this.props.updateCurrentFormAction(this.props.dirty);
    }
  }

  storeKeywords = (keywordValue) => {
    this.props.storeKeyword(keywordValue);
  }

  regionSupport() {
    const {
      supportedRegionIds
    } = this.props;

    const { regionDisableExceptGlobal, regionGlobalDisbale } = this.state;

    if (supportedRegionIds) {
      this.disableRegionExceptGlobal(supportedRegionIds);
      this.disableGlobalRegion(supportedRegionIds);
    }

    return (
      <div className="row">
        {this.props.productCategories.regions.map((data, index) => {
          if (supportedRegionIds) {
            this.regionDisableFeature(supportedRegionIds, index, data);
          }
          return (

            <div key={data.name} className="col-sm-12 col-md-12">
              {data.name !== 'Global' || (
                <div className={regionGlobalDisbale ? 'disable-div' : ''}>
                  <Field
                    name={`supportedRegionIds[${[index]}]`}
                    displayText={data.name}
                    type="checkbox"
                    component={renderCheckbox}
                    enabled={!regionGlobalDisbale}
                    onChange={() => this.props.storeIds({ key: 'supportedRegionIds', value: data.id })}
                    style={{ border: 'none' }}
                  />
                </div>
              )}
              {data.name === 'Global' || (
                <div style={{ border: 'none' }} className={regionDisableExceptGlobal ? 'disable-div' : ''}>
                  <Field
                    name={`supportedRegionIds[${[index]}]`}
                    displayText={data.name}
                    type="checkbox"
                    onChange={() => this.props.storeIds({ key: 'supportedRegionIds', value: data.id })}
                    component={renderCheckbox}
                    enabled={!regionDisableExceptGlobal}
                    style={{ border: 'none' }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  disableGlobalRegion(supportedRegionIds) {
    this.setState({ regionGlobalDisbale: (supportedRegionIds.includes(true) && supportedRegionIds.length > 1 && !supportedRegionIds[0]) });
  }

  disableRegionExceptGlobal(supportedRegionIds) {
    this.setState({ regionDisableExceptGlobal: (supportedRegionIds.length === 0) ? false : supportedRegionIds[0] });
  }

  regionDisableFeature(supportedRegionIds, index, data) {
    if (supportedRegionIds.includes(index + 1)) {
      if (data.name === 'Global') {
        this.setState({ regionDisableExceptGlobal: true });
      } else { this.setState({ regionGlobalDisbale: true }); }
    }
  }

  productCategory() {
    const { appTypeIds } = this.props;
    const selectedIndex = [];

    if (appTypeIds === undefined) {
      return <LoaderComponent show />;
    }

    appTypeIds.forEach((elem, index) => {
      if (elem) {
        selectedIndex.push(index);
      }
    });

    return (
      <div className="row">
        {this.props.productCategories.appTypes.map((data, index) => (
          <div key={data.name} className={selectedIndex.length >= 5 ? 'disable-div-input' : 'col-sm-12 col-md-12'}>
            <Field
              name={`appTypeIds[${[index]}]`}
              displayText={data.name}
              type="checkbox"
              onChange={(event, checked) => {
                if (selectedIndex.length >= 5 && checked) {
                  event.preventDefault();
                  this.props.displayErrorMessage(true);

                  setTimeout(() => {
                    this.props.displayErrorMessage(false);
                  }, 0);
                } else {
                  this.props.storeIds({ key: 'appTypeIds', value: data.id });
                }
              }}
              component={renderCheckbox}
              value={data.id}
            />
          </div>
        ))}
      </div>
    );
  }

  hostService() {
    const { hostServiceDisable } = this.state;

    if (hostServiceDisable) this.props.makeHostIdsEmpty(this.props.platformId);

    return (
      <div className={hostServiceDisable ? 'disable-div' : ''}>
        <div className="row">
          {Object.keys(this.props.hostServiceList).map((data, index) => (
            <div key={this.props.hostServiceList[data].name} className="col-sm-12 col-md-12">
              <Field
                name={`hostIds[${[index]}]`}
                displayText={this.props.hostServiceList[data].name}
                type="checkbox"
                component={renderCheckbox}
                onChange={() => {
                  this.props.storeIds({ key: 'hostIds', value: this.props.hostServiceList[data].id });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  platformSupport() {
    const { checkedSmartpoint } = this.state;
    const canUserViewSpCloud = this.props.currentUser && this.props.currentUser.profile && (this.props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name
      || this.props.currentUser.profile.role === roleNameConstants.SPCDeveloperUser.name
    );
    return (
      <div className="row">

        {Object.keys(this.props.platformSupportList).map(data => {
          const hideTravelSmartpoint = (this.props.platformSupportList[data].name !== 'Smartpoint Desktop');
          return (
            <div key={this.props.platformSupportList[data].name} className="col-sm-12 col-md-12">
              {canUserViewSpCloud && this.props.platformSupportList[data].name === 'Smartpoint Cloud' && (
                <Field
                  name="platformId"
                  displayText={this.props.platformSupportList[data].name}
                  type="radio"
                  id={this.props.platformSupportList[data].name}
                  value={this.props.platformSupportList[data].id}
                  onChange={() => {
                    this.setState({ hostServiceDisable: true });
                    this.setState({ checkedSmartpoint: hideTravelSmartpoint });
                    this.props.storeIds({ key: 'platformId', value: this.props.platformSupportList[data].id });
                  }}
                  component={renderRadioButton}
                />
              )}
              {this.props.platformSupportList[data].name !== 'Smartpoint Cloud'
                && (
                  <Field
                    name="platformId"
                    displayText={this.props.platformSupportList[data].name}
                    type="radio"
                    id={this.props.platformSupportList[data].name}
                    value={this.props.platformSupportList[data].id}
                    onChange={() => {
                      this.setState({ hostServiceDisable: false });
                      this.setState({ checkedSmartpoint: hideTravelSmartpoint });
                      this.props.storeIds({ key: 'platformId', value: this.props.platformSupportList[data].id });
                    }}
                    component={renderRadioButton}
                  />
                )}
              {(checkedSmartpoint === false && this.props.platformId === 1 && this.props.platformSupportList[data].name === 'Smartpoint Desktop')
                && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 col-md-7" style={{ marginLeft: '6.5%' }}>
                        <Field
                          name="versionSupported"
                          component={renderField}
                          type="text"
                          onChange={(e) => this.props.storeIds({ key: 'versionSupported', value: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-7 highlighted-div pad-top-sm pad-left-sm pad-bottom-sm" style={{ marginLeft: '6.5%' }}>
                        Plug-in ID
                        {' '}
                        <ToolTip labelName="Plug-in ID" />
                        <br />
                        <span id="smartpointId">{this.props.classficaitonData.tempGuid}</span>
                      </div>

                    </div>
                  </>
                )}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    if (this.props.platformSupportList === undefined
      || this.props.hostServiceList === undefined
      || this.props.classficaitonData === undefined
      || this.props.productCategories === undefined) {
      return <LoaderComponent show />;
    }

    return (
      <>
        {this.props.showErrorMessage
          && toast.info('Maximum of 5 selections are allowed for Product Categories', {
            id: 'Maximum of 5 selections are allowed for Product Categories'.replaceAll(' ', '-'),
            position: 'top-center',
            showProgressBar: true
          })}
        <form onSubmit={this.props.handleSubmit}>
          {this.props.loading && <LoaderComponent show />}
          <FormHeader
            header="Classification"
            infoText={`Use the groupings below to define filtering options and search keywords for your solution.
             These classifications define which filtering options on the front page and which search terms will display your solution.`}
          />
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <label className="required-field-star" htmlFor="platformSupport">PLATFORM SUPPORTED</label>
                </div>
              </div>
              {this.platformSupport()}
              <div hidden={this.props.platformId === 2 || this.state.hostServiceDisable ? 'hidden' : ''}>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <label className="required-field-star">HOST(S) SERVICED</label>
                  </div>
                </div>
                {this.hostService()}
              </div>

              {(!this.props.isInternal)
                && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <label className="required-field-star" htmlFor="regionSupport">REGION(S) SUPPORTED</label>
                      </div>
                    </div>
                    {this.regionSupport()}
                  </>
                )}
              {(this.props.IsTravelportPOSApplication)
                ? (
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <label className="required-field-star" htmlFor="Price">
                        Price
                        <ToolTip labelName={'This classification notifies the user if there is a cost associated with activating the software once they download it. '
                          + 'Changing this option will not prevent users from downloading the product.'}
                        />
                      </label>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <Field
                          name="isPaid"
                          displayText="Free"
                          type="radio"
                          value={0}
                          component={renderRadioButton}
                        />
                        <Field
                          name="isPaid"
                          displayText="Paid"
                          type="radio"
                          value={1}
                          component={renderRadioButton}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <label className="required-field-star" htmlFor="productCategory">
                    PRODUCT CATEGORIES
                    {' '}
                    {(this.props.isInternal)
                      ? <ToolTip labelName="This is an internal product page for your organization and must be marked as such." />
                      : <em>(maximum 5)</em>}
                  </label>
                </div>
              </div>
              {(this.props.isInternal)
                ? (
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <SimplePrettyCheckBox
                        name="ProductCategory"
                        displayText="Internal"
                        type="checkbox"
                        value
                        enabled={false}
                        style={{ width: '50%' }}
                      />
                    </div>
                  </div>
                )
                : this.productCategory()}
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <SearchTag
                    tagItems={this.props.classficaitonData.keywords}
                    handleChange={this.storeKeywords}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

Classification.propTypes = {
  handleSubmit: PropTypes.func,
  fetchClassificationAction: PropTypes.func,
  fetchListForClassificationAction: PropTypes.func,
  platformSupportList: PropTypes.object,
  hostServiceList: PropTypes.object,
  classficaitonData: PropTypes.object,
  productCategories: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  updateCurrentFormAction: PropTypes.func,
  dirty: PropTypes.bool,
  supportedRegionIds: PropTypes.array,
  appTypeIds: PropTypes.array,
  displayErrorMessage: PropTypes.func,
  showErrorMessage: PropTypes.bool,
  isInternal: PropTypes.bool,
  IsTravelportPOSApplication: PropTypes.bool,
  platformId: PropTypes.number,
  makeHostIdsEmpty: PropTypes.func,
  isPaid: PropTypes.bool,
  currentUser: PropTypes.object,
  storeKeyword: PropTypes.func,
  storeIds: PropTypes.func
};

renderCheckbox.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
  enabled: PropTypes.bool,
  style: PropTypes.any
};

renderRadioButton.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  tooltip: PropTypes.string

};

renderField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  meta: PropTypes.object
};

const selector = formValueSelector(CLASSIFICATION_ID);
const mapStateToProps = (state) => ({
  initialValues: getClassificationSelector(state),
  platformSupportList: state.application.classification.classficaitonPlatform,
  hostServiceList: state.application.classification.classficaitonHost,
  classficaitonData: state.application.classification.classficaitonModel,
  productCategories: state.application.classification.productCategoryAndRegion,
  showErrorMessage: state.application.classification.showProductCategoryErrorMessage,
  loading: state.application.classification.loading || state.application.applicationLayout.loading,
  supportedRegionIds: selector(state, 'supportedRegionIds'),
  appTypeIds: selector(state, 'appTypeIds'),
  platformId: selector(state, 'platformId'),
  currentUser: state.oidc.user,
  isInternal: isInternalPPCP(state),
  IsTravelportPOSApplication: isTravelportPOSApplicationPPCP(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchClassificationAction: (args) => dispatch(fetchClassificationDetails.base(args)),
  fetchListForClassificationAction: () => dispatch(fetchListForClassificationDetails.base()),
  updateCurrentFormAction: (dirty) => dispatch(updateCurrentForm(CLASSIFICATION_ID, dirty)),
  displayErrorMessage: (args) => dispatch(displayErrorMessage(args)),
  makeHostIdsEmpty: (args) => dispatch(hostIdsEmpty(args)),
  storeKeyword: (args) => dispatch(storeKeywords(args)),
  storeIds: (args) => dispatch(storeIds(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: CLASSIFICATION_ID,
    enableReinitialize: true
  })(Classification)
);
