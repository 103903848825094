import React from 'react';
import PropTypes from 'prop-types';

const PartnerLevel = (props) => (
  <div className="row">
    <div className="col-sm-6">
      <h4>Partner Tag</h4>
      <select className="select" value={props.partnerLevel} onChange={(e) => props.getChangeHandler(e)}>
        {
          props.partnerLevels
          && props.partnerLevels.map(
            (value, i) => <option key={i.toString()} value={value.id}>{value.name}</option>
          )
        }
      </select>
    </div>
  </div>
);

PartnerLevel.propTypes = {
  partnerLevel: PropTypes.number,
  getChangeHandler: PropTypes.func,
  partnerLevels: PropTypes.array
};

export default PartnerLevel;
