/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MpDatePicker from 'components/common/FormInputs/Datepicker/MpDatePicker';

export const DatePanel = (props) => (
  <>
    <div className="col-sm-5">
      <div className="col-sm-5 col-sm-offset-1">
        <span
          id="datepicker-start-date"
          className="input-append date"
          data-date="12/1/2013"
          data-date-format="mm/dd/yyyy"
        >
          <label htmlFor="startDate">From:</label>
          <span
            className="add-on"
            style={{ display: 'inline-block' }}
          >
            <MpDatePicker
              value={props.startDate}
              onChange={e => props.datehandler(e, 'startDate')}
            />
          </span>
        </span>
      </div>
      <div className="col-sm-5 col-sm-offset-1">
        <span
          id="datepicker-end-date"
          className="input-append date"
          data-date="12/31/2013"
          data-date-format="mm/dd/yyyy"
        >
          <label htmlFor="endDate">To:</label>
          <span
            className="add-on"
            style={{ display: 'inline-block' }}
          >
            <MpDatePicker
              value={props.endDate}
              onChange={e => props.datehandler(e, 'endDate')}
            />
          </span>
        </span>
      </div>
    </div>
  </>
);

export const SearchPanel = (props) => (
  <>
    <div className="col-sm-1 col-sm-offset-1 pad-left">
      <Link
        to="#"
        className="action-button"
        onClick={props.getFilteredAuditLog}
      >
        Search
      </Link>
    </div>
  </>
);

export const ExportToExcelPanel = (props) => (
  <>
    <div className="row text-right ajax-dependent-div">
      <Link
        to="#"
        id="downloadExcel"
        className="text-link-with-icon-left"
        onClick={props.exportToExcel}
      >
        <span className="download-icon" />
        Download in Microsoft Excel
      </Link>
    </div>
  </>
);

DatePanel.propTypes = {
  datehandler: PropTypes.func,
  startDate: PropTypes.func,
  endDate: PropTypes.func
};

SearchPanel.propTypes = {
  getFilteredAuditLog: PropTypes.func
};

ExportToExcelPanel.propTypes = {
  exportToExcel: PropTypes.func,
};
