import URLSearchParams from 'url-search-params';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

export const isValidResponse = (response) => {
  if (response.status === 200 || response.status === 201 || response.status === 204) {
    return true;
  }
  return false;
};

export const getValueFromQueryString = (location, key, convertToLowerCase = true) => {
  if (location && key) {
    const { search } = location;
    if (search) {
      const params = new URLSearchParams(convertToLowerCase ? search.toLowerCase() : search);
      return params.get(key.toLowerCase());
    }
  }
  return '';
};

const isJsonObject = (res) => {
  try {
    if (JSON.parse(res)) {
      return true;
    }
  } catch (err) {
    return false;
  }
  return true;
};

const parseResponseBody = (response) => new Promise((resolve) => {
  response.text().then((res) => {
    if (res.length > 0) {
      if (isJsonObject(res)) {
        resolve(JSON.parse(res));
      } else {
        resolve(res);
      }
    } else {
      resolve({});
    }
  });
});

export const GetService = (url) => new Promise((resolve, reject) => {
  fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    if (response.ok) {
      resolve(parseResponseBody(response));
    } else {
      if (response.status && response.status === 401) {
        handleUnauthorizedResponse();
      }
      reject(response);
    }
  }).catch((error) => reject(error));
});

export const Service = (serviceMethod, url, data) => new Promise((resolve, reject) => {
  fetch(url, {
    method: serviceMethod,
    credentials: 'include',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }).then((response) => {
    if (response.ok) {
      resolve(parseResponseBody(response));
    } else {
      if (response.status && response.status === 401) {
        handleUnauthorizedResponse();
      }
      reject(response);
    }
  }).catch((error) => reject(error));
});

export const PostFormData = (url, formData) => new Promise((resolve, reject) => {
  fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: formData
  }).then((response) => {
    if (response.ok) {
      resolve(parseResponseBody(response));
    } else {
      if (response.status && response.status === 401) {
        handleUnauthorizedResponse();
      }
      reject(response);
    }
  }).catch((error) => reject(error));
});
