/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@atlas-design-system/react';
import {
  uploadPlugin
} from 'reducers/modules/Application';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ToolTip from '../../../common/ToolTip';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

const TOOLTIP_INSTALLATION_PACKAGE = 'Provide the installation executable files to enable Marketplace to distribute the product to buyers.';
const TOOLTIP_VERSION = 'Assign a product version number in the format x.x.x.x.  The version number must be higher than the previous version.';

class UploadPlugin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      version: '',
      versionValid: true,
      isSpPlugin: false,
    };
  }

  handleFile = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.size > 524288000) {
        this.props.showAlertAction('File size should not exceed 500 MB');
      } else {
        this.setState({ file: fileUploaded, fileName: fileUploaded.name });
      }
    }
  };

  updateVersion = (e) => {
    e.preventDefault();
    this.setState({ version: e.target.value });
    if (e.target.validity.valid) this.setState({ versionValid: true });
    else this.setState({ versionValid: false });
  }

  upload = (e) => {
    const { uploadFileAction, isSpPlugin, popUpHide } = this.props;
    e.preventDefault();
    uploadFileAction({
      file: this.state.file,
      version: this.state.version,
      onSuccess: popUpHide,
      isSpPlugin
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  render() {
    const {
      showModal,
    } = this.props;

    return (
      <Modal
        id="sbModal"
        title="Upload Plugin"
        hidden={!showModal}
        size="small"
        onDismiss={this.cancel}
      >
        <label htmlFor="files">
          Upload Installation Package
          <ToolTip labelName={TOOLTIP_INSTALLATION_PACKAGE} />
        </label>
        <div className="horizontal-input-group">
          <div className="text-input-width">
            <input type="text" value={this.state.fileName} className="text-input" id="upload-logo-modal-text-input" />
          </div>
          <button type="button" onClick={(e) => e.preventDefault()} className="modal-browse-button">Browse</button>
          <input
            type="file"
            id="upload-logo-modal-file-input"
            onChange={this.handleFile}
            onClick={(e) => e.target.value = null}
            file-model="myFile"
            name="appIcon"
            className="file-input transparent"
          />
        </div>
        <div className="row">
          <label htmlFor="version">
            Version
            <ToolTip labelName={TOOLTIP_VERSION} />
          </label>
          <input type="text" className="text-input text-input-lg full-width" id="version" onChange={this.updateVersion} pattern="\d+\.\d+\.\d+\.\d+" placeholder="3.2.0.0" />
        </div>
        {!this.state.versionValid
          && (
            <div className="alert alert-danger space-bottom-double text-center ">
              Invalid Build #
            </div>
          )}
        {/* <LoaderComponent show /> */}
        {(this.props.loading) && <LoaderComponent show />}
        <div className="text-center">
          <button
            disabled={this.props.loading}
            type="button"
            className="action-button no-save gtm-myprofile-image-upload"
            gtm-event-label="Profile Image"
            onClick={this.state.versionValid && this.state.file ? this.upload : null}
          >
            UPLOAD
          </button>
          <button type="button" className="cancel-button space-left no-save" data-dismiss="modal" onClick={this.cancel}>CANCEL</button>
        </div>
      </Modal>
    );
  }
}

UploadPlugin.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  isSpPlugin: PropTypes.bool,
  error: PropTypes.string,
  uploadFileAction: PropTypes.func,
  showAlertAction: PropTypes.func,
  loading: PropTypes.bool
};

const mapStateToProps = state => ({
  loading: state.application.distribution.loading,
  error: state.application.distribution.error
});

const mapDispatchToProps = dispatch => ({
  uploadFileAction: (data) => dispatch(uploadPlugin.base(data)),
  showAlertAction: (args) => dispatch(showAlertAction(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPlugin);
