import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';

export default class CommandCell extends PureComponent {
  render() {
    if (!this.props.row.dateReleased && !this.props.row.hasUnReleasedBuild) {
      return <VirtualTable.Cell {...this.props} />;
    }
    const allChildrenExceptDelete = this.props.children.find(c => !c.props || c.props.id !== 'delete');
    return (
      <VirtualTable.Cell>
        {allChildrenExceptDelete}
        <span className="space-left-sm space-right-sm">Delete</span>
      </VirtualTable.Cell>
    );
  }
}
CommandCell.propTypes = {
  row: PropTypes.object,
  children: PropTypes.array
};
