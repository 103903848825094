/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@atlas-design-system/react';
import {
  uploadCertificate, clear
} from 'reducers/modules/Application';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ToolTip from '../../../common/ToolTip';
import ConfDeleteModal from '../../../common/MPModal/ConfDeleteModal';

const TOOLTIP_DOCUMENT = 'Provide the certificate received from the product certification team.';

class UploadCertificate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      integrationDownloadVersion: this.props.model.integrationDownloadLastUploadedVersion,
      directDownloadVersion: this.props.model.directDownloadLastUploadedVersion,
      versionValid: true,
      isSpPlugin: false,
      appVersType: this.props.model.isDirectDownload ? 'directDownloadVersion' : 'integrationDownloadVersion',
      showConfModal: false,
      uploaded: false,
      docName: ''
    };

    this.handleFile = this.handleFile.bind(this);
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this.setState({
        directDownloadVersion: newProps.model.directDownloadLastUploadedVersion,
        integrationDownloadVersion: newProps.model.integrationDownloadLastUploadedVersion,
        appVersType: newProps.model.isDirectDownload ? 'directDownloadVersion' : 'integrationDownloadVersion'
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  handleFile = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      const uploadedFileName = fileUploaded.name;
      const fileExtsn = uploadedFileName.slice(uploadedFileName.lastIndexOf('.'));
      if (fileUploaded.size > 5000000) {
        this.props.showAlertAction('File size should not exceed 5MB');
      } else if (!(fileExtsn === '.txt' || fileExtsn === '.pdf' || fileExtsn === '.docx' || fileExtsn === '.doc' || fileExtsn === '.msg')) {
        this.props.showAlertAction('Invalid Uploaded File Format');
      } else {
        if (this.props.model.isDirectDownload && this.props.model.directDownloadVersions.length && this.state.appVersType === 'directDownloadVersion') {
          this.setState({ directDownloadVersion: this.props.model.directDownloadVersions[0].version });
        } else if (this.props.model.isIntegrationDownload && this.props.model.versions.length && this.state.appVersType === 'integrationDownloadVersion') {
          this.setState({ integrationDownloadVersion: this.props.model.versions[0].versionNumber });
        }
        this.setState({ file: fileUploaded, fileName: fileUploaded.name, uploaded: true });
      }
    }
  };

  upload = (e) => {
    const { docList } = this.props;
    e.preventDefault();
    const filterItem = {
      applicationVersion: this.state.appVersType === 'integrationDownloadVersion' ? this.state.integrationDownloadVersion : this.state.directDownloadVersion,
      applicationVersionType: this.state.appVersType
    };
    const docExists = docList.filter(item => {
      if ((item.applicationVersionType === filterItem.applicationVersionType) && (item.applicationVersion === filterItem.applicationVersion)) { return true; }
      return false;
    });
    if (docExists.length > 0) {
      this.setState({ showConfModal: true });
    } else {
      this.dispatchAction();
    }
  }

  uploadCertificateDocument = () => {
    this.dispatchAction();
    this.setState({ showConfModal: false });
  }

  dispatchAction = () => {
    const { isSpPlugin, publicId } = this.props;
    this.props.uploadCertFileAction({
      file: this.state.file,
      version: this.state.appVersType === 'integrationDownloadVersion' ? this.state.integrationDownloadVersion : this.state.directDownloadVersion,
      onSuccess: this.props.popUpHide,
      isSpPlugin,
      appVersType: this.state.appVersType,
      publicId,
      docName: this.state.docName
    });
    this.setState({
      uploaded: true, fileName: '', file: null, directDownloadVersion: this.props.model.directDownloadLastUploadedVersion, integrationDownloadVersion: this.props.model.integrationDownloadLastUploadedVersion, docName: ''
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    this.setState({
      fileName: '', file: null, directDownloadVersion: this.props.model.directDownloadLastUploadedVersion, integrationDownloadVersion: this.props.model.integrationDownloadLastUploadedVersion, docName: ''
    });
    popUpHide();
  }

  handleRadioButtonChange = (event) => {
    this.setState({
      appVersType: event.currentTarget.value
    });
    this.isLatestFileUploaded();
  }

  handleProductVersionType = () => (this.setState({ appVersType: 'integrationDownloadVersion', isSpPlugin: true }));

  isLatestFileUploaded = () => {
    if ((this.state.appVersType === 'integrationDownloadVersion' && this.state.integrationDownloadVersion === this.props.model.integrationDownloadCurrentVersion)
      || (this.state.appVersType === 'directDownloadVersion' && this.state.directDownloadVersion === this.props.model.directDownloadCurrentVersion)) {
      return <><p>Please upload a latest file first before uploading document</p></>;
    }

    return (
      <>
        <div className="row">
          <label htmlFor="files">
            Upload Certificate Document
            <ToolTip labelName={TOOLTIP_DOCUMENT} />
          </label>
          <div className="horizontal-input-group">
            <div className="text-input-width">
              <input type="text" value={this.state.fileName} className="text-input" id="upload-logo-modal-text-input" />
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.target.files = null;
                e.preventDefault();
              }}
              className="modal-browse-button"
              disabled={this.state.uploaded}
            >
              {' '}
              Browse
            </button>
            <input
              type="file"
              id="upload-logo-modal-file-input"
              onChange={this.handleFile}
              onClick={(e) => e.target.value = null}
              file-model="myFile"
              name="appIcon"
              className="file-input transparent"
            />
          </div>
          <br />
          <div>
            Note: The file is mandatory. Only when the file is uploaded will the option to “Submit for approval” be enabled.
            Uploaded file should be of .pdf, .doc, .msg, .txt, extensions only with file size limit of 5MB.
          </div>
        </div>
        <div className="text-center">
          <button
            className="action-button no-save gtm-myprofile-image-upload"
            gtm-event-label="Profile Image"
            onClick={this.state.file ? this.upload : null}
            disabled={!this.state.uploaded}
            type="button"
          >
            UPLOAD
          </button>
          <button type="button" className="cancel-button space-left no-save" data-dismiss="modal" onClick={this.cancel}>CANCEL</button>
        </div>
      </>
    );
  }

  render() {
    const {
      showModal,
      popUpHide,
    } = this.props;
    return (
      <Modal
        id="sbModal"
        title="Upload Certificate"
        hidden={!showModal}
        size="small"
        onDismiss={popUpHide}
      >
        <div className="row">
          {(this.props.model.isDirectDownload && this.props.model.platformId === 1 && this.props.model.isIntegrationDownload) && (
            <div className="col-sm-4">
              <input
                type="radio"
                id="drctDownld"
                name="directDwnld"
                checked={this.state.appVersType === 'directDownloadVersion'}
                value="directDownloadVersion"
                onChange={this.handleRadioButtonChange}
              />
              <label htmlFor="drctDownld">Direct Download Version</label>
            </div>
          )}
          {
            (this.props.model.isIntegrationDownload && this.props.model.platformId === 1 && this.props.model.isDirectDownload) && (
              <div className="col-sm-4">
                {!this.props.model.isDirectDownload && this.handleProductVersionType()}
                <input
                  type="radio"
                  id="intgrationDwnld"
                  name="integrationDwnld"
                  checked={this.state.appVersType === 'integrationDownloadVersion' || !this.props.model.isDirectDownload}
                  value="integrationDownloadVersion"
                  onChange={this.handleRadioButtonChange}
                />
                <label htmlFor="intgrationDwnld">Integration Download Version</label>
              </div>
            )
          }
        </div>
        <div className="row-sm-6">
          {this.isLatestFileUploaded()}
        </div>
        {this.state.showConfModal ? (
          <ConfDeleteModal
            text="A file for the same version exists which will be replaced with the current uploaded file. Are you sure you want to continue?"
            showModal
            onHideModal={() => this.setState({ showConfModal: false })}
            onDelete={() => this.uploadCertificateDocument()}
          />
        ) : null}
      </Modal>
    );
  }
}

UploadCertificate.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  isSpPlugin: PropTypes.bool,
  error: PropTypes.string,
  uploadCertFileAction: PropTypes.func,
  publicId: PropTypes.string,
  docName: PropTypes.string,
  docList: PropTypes.array,
  model: PropTypes.object,
  uploading: PropTypes.bool,
  onUnmount: PropTypes.func,
  showAlertAction: PropTypes.func
};

const mapStateToProps = state => ({
  error: state.application.distribution.error,
  publicId: state.application.applicationLayout.publicId,
  certResponse: state.application.review.certresponse,
});

const mapDispatchToProps = dispatch => ({
  uploadCertFileAction: (data) => dispatch(uploadCertificate.base(data)),
  showAlertAction: (args) => dispatch(showAlertAction(args)),
  onUnmount: () => dispatch(clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCertificate);
