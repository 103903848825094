import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../../utils/createFetchTypes';
import ApiClient from '../../../../../Services/ApiClient';
import * as apiConfig from '../../../../../config/apiConfig';
import fetchEntity from '../../../../utils/fetchEntitySaga';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';

export const FETCH_AGENCY_HIERARCHY = createFetchTypes('Marketplace/Profile/Agency/AgencyHierarchy/FETCH_AGENCY_HIERARCHY');
export const EMULATE_AGENCY = createFetchTypes('Marketplace/Profile/Agency/AgencyHierarchy/EMULATE_AGENCY');

export const EMULATE_AGENCY_URL = 'account/ChangeUserOrg';

export const agencyHierarchyReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_AGENCY_HIERARCHY.REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null
      };
    case FETCH_AGENCY_HIERARCHY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.agencyHierarchy,
        error: null
      };
    case FETCH_AGENCY_HIERARCHY.FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    case EMULATE_AGENCY.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EMULATE_AGENCY.SUCCESS:
      return {
        ...state,
        emaultionSuccess: action.emulationSuccess,
        loading: false,
        error: null
      };
    case EMULATE_AGENCY.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export const fetchAgencyHierarchy = {
  base: (params) => ({
    type: FETCH_AGENCY_HIERARCHY.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_AGENCY_HIERARCHY.REQUEST
  }),
  success: (data) => ({
    type: FETCH_AGENCY_HIERARCHY.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_AGENCY_HIERARCHY.FAILURE,
    payload: {
      error
    }
  })
};

export const emulateAgency = {
  base: (orgId) => ({
    type: EMULATE_AGENCY.BASE,
    orgId,
    url: EMULATE_AGENCY_URL
  }),
  request: () => ({
    type: EMULATE_AGENCY.REQUEST
  }),
  success: (data) => ({
    type: EMULATE_AGENCY.SUCCESS,
    emulationSuccess: data.success
  }),
  failure: (error) => ({
    type: EMULATE_AGENCY.FAILURE,
    error
  })
};

export function* fetchAgencyHierarchySaga({ payload }) {
  try {
    const url = `${apiConfig.GET_HIERARCHY}?orgId=${payload.params.orgId}&customerNumber=${payload.params.customerNumber}`;
    yield call(() => fetchEntity(fetchAgencyHierarchy, ApiClient.httpClient.get, url));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* emulateAgencySaga({ orgId, url }) {
  try {
    yield call(() => fetchEntity(emulateAgency, ApiClient.httpClient.post, url, orgId));
    localStorage.updatingClaims = true;
    sessionStorage.setItem('lastUsedPathName', '/Account/MyProfile?selectedTab=agencyHierarchy');
    window.location = `${apiConfig.BASE_URL}login?resetUserOrg=false`;
  } catch (error) {
    yield put(showAlertAction('Some error occured during emulation. Please try again.'));
  }
}

export function* watchfetchAgencyHierarchySaga() {
  yield takeLatest(FETCH_AGENCY_HIERARCHY.BASE, fetchAgencyHierarchySaga);
}

export function* watchEmulateAgencySaga() {
  yield takeLatest(EMULATE_AGENCY.BASE, emulateAgencySaga);
}

export const emulationSuccessSelector = state => state.profile.agency.agencyHierarchy.emaultionSuccess;
