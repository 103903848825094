import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { roleNameConstants } from 'utils/rolesHelper';
import { getAppNameGuidMapping, getBannerNotification, getBuildNumber } from 'reducers/modules/Home/home';
import { SEOFriendly } from 'utils/SeoHelper';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';
import { loadPopularProducts } from '../../actions';
import * as Config from '../../config/apiConfig';
import Carousel from './Carousel/Carousel';
import CarouselItem from './Carousel/CarouselItem';
import PopularProducts from '../common/PopularProducts/PopularProducts';
import HomeBrandRow from './HomeBrandRow';
import * as CommonServices from '../../Services/Common.Service';

class Home extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    document.title = 'Home Page';
    CommonServices.SSOProcessing(this.props.user);
    if (!this.props.appNameGuidMapping) {
      const applicationGuids = [
        '47C78AE2-C1AD-4D5B-98D1-370207A9E4D5',
        '87AF347E-6FFF-4678-AF67-C59AA8733CE7',
        '7D5FE75A-8D07-4353-BD8A-C27D02391538',
        'F83AE8F4-B62A-4FDF-A175-4EAB5898EE32',
        'A3DF3778-CC2A-46D1-A6F0-EDFAEF179F23',
        '0C9FC57B-4444-4302-AF27-0D5E9FAC2B8D',
        '2447A3AA-621A-4DC4-9A1B-34FD49F0255F',
        '67649FC0-0AD7-4F81-A866-8B7E075C5D24',
        '0B62A3AD-81DF-4F0B-A9A2-2DCFD77CF510',
        'F090C27E-4049-4507-98F7-5E8C350C9D2F',
        'AD3530DD-24E4-4D56-9C2E-BA6B7092C2EA'
      ];
      this.props.getAppNameGuidMapping({ applicationGuids });
    }
    const response = this.fetchPropularProducts();
    response.then((res) => {
      this.props.loadPopularProducts(res);
    }).catch(() => {
      this.props.loadPopularProducts([]);
    });
    this.props.getBannerNotification();
    if (this.props.user && this.props.user.profile && !this.props.user.expired) {
      const isSuperAdmin = this.props.user.profile.role === roleNameConstants.MarketplaceTeamAdmin.name;
      if (isSuperAdmin) {
        this.props.getBuildNumber();
      }
    }
    window.scrollTo(0, 0);
  }

  getProductDetailsUrl(appGuid) {
    if (!this.props.appNameGuidMapping) {
      return '';
    }
    const app = this.props.appNameGuidMapping.find(a => a.publicId.toLowerCase() === appGuid.toLowerCase());
    if (!app) {
      return '';
    }
    return `/Product/${SEOFriendly(app.name)}`;
  }

  getCarousalItems = () => {
    const tmaUrl = this.getProductDetailsUrl('47C78AE2-C1AD-4D5B-98D1-370207A9E4D5');
    const tripgatecbtUrl = this.getProductDetailsUrl('7D5FE75A-8D07-4353-BD8A-C27D02391538');
    const visaeaseUrl = this.getProductDetailsUrl('F83AE8F4-B62A-4FDF-A175-4EAB5898EE32');
    const blueribbonUrl = this.getProductDetailsUrl('A3DF3778-CC2A-46D1-A6F0-EDFAEF179F23');
    const umbrellaFacesUrl = this.getProductDetailsUrl('0C9FC57B-4444-4302-AF27-0D5E9FAC2B8D');
    const simplenightUrl = this.getProductDetailsUrl('2447A3AA-621A-4DC4-9A1B-34FD49F0255F');
    const visahqUrl = this.getProductDetailsUrl('67649FC0-0AD7-4F81-A866-8B7E075C5D24');
    const globalexchangemanagerUrl = this.getProductDetailsUrl('0B62A3AD-81DF-4F0B-A9A2-2DCFD77CF510');
    const airportExpressUrl = this.getProductDetailsUrl('F090C27E-4049-4507-98F7-5E8C350C9D2F');
    const smartpointUrl = this.getProductDetailsUrl('AD3530DD-24E4-4D56-9C2E-BA6B7092C2EA');
    const tdnUrl = 'https://developer.travelport.com/home';
    const helpUrl = '/ContactUs';
    const manageOrgsUrl = '/admin/home/organizationDirectory';
    const toolsUrl = 'https://developer.travelport.com/access-and-partnership';
    const accountInfoUrl = '/Account/MyProfile';
    const conversionUrl = '/Account/MyProfile';

    let carousalItems = [
      new CarouselItem('carousel-link-tma', require('../../Styles/carousel-img/carousel-tma.jpg'),
        'Travelport Mobile Agent', 'A mobile application that provides access to your GDS, offering full mobility.', tmaUrl, 'Download'),
      new CarouselItem('carousel-link-tripgatecbt', require('../../Styles/carousel-img/carousel-tripgatecbt.jpg'),
        'TripGate CBT', 'A powerfull booking tool for your corporate travelers. Air, LCCs, NDC, hotels, rail on desktop and mobile.', tripgatecbtUrl, 'Download'),
      new CarouselItem('carousel-link-smartpoint', require('../../Styles/carousel-img/carousel-smartpoint.jpg'),
        'Smartpoint Desktop Bundle', 'Real-time content from over 400 airlines, 650,000 unique hotel properties and 36,000 car rental locations.', smartpointUrl, 'Download'),

      new CarouselItem('carousel-link-visaease', require('../../Styles/carousel-img/carousel-visaease-logo.jpg'),
        'VisaEase', 'Manually checking visa requirements is a thing of the past.', visaeaseUrl, 'Download'),
      new CarouselItem('carousel-link-blueribbon', require('../../Styles/carousel-img/carousel-blueribbonbags.jpg'),
        'Blue Ribbon Bags', 'Baggage protection for your customers.', blueribbonUrl, 'Download'),
      new CarouselItem('carousel-umbrella-faces', require('../../Styles/carousel-img/carousel-umbrella-faces.png'),
        'Umbrella Faces', 'State-of-the art travel profile management for Smartpoint Desktop.', umbrellaFacesUrl, 'Download'),

      new CarouselItem('carousel-link-simplenight', require('../../Styles/carousel-img/carousel-simplenight.jpg'),
        'SIMPLENIGHT', 'Tours, activities, dining, transportation, shows, special events and more.', simplenightUrl, 'Download'),
      new CarouselItem('carousel-link-visahq', require('../../Styles/carousel-img/carousel-visahq.jpg'),
        'Travel Visa Services', 'Check travel visa requirements for visas to all countries.', visahqUrl, 'Download'),
      new CarouselItem('carousel-link-smartpoint', require('../../Styles/carousel-img/carousel-smartpoint.jpg'),
        'Smartpoint Desktop Bundle', 'Real-time content from over 400 airlines, 650,000 unique hotel properties and 36,000 car rental locations.', smartpointUrl, 'Download'),

      new CarouselItem('carousel-link-globalexchangemanager', require('../../Styles/carousel-img/carousel-globalexchangemanager.jpg'),
        'TCP Exchange', 'Quick and safe ticket exchanges, reissues, refunds on desktop & mobile. Supports all Travelport+ carriers.', globalexchangemanagerUrl, 'Download'),
      new CarouselItem('carousel-link-airport-exp', require('../../Styles/carousel-img/carousel-airport-express.jpg'),
        'Airport Express', 'Book rail transfers from major airports to the city center without leaving Smartpoint Desktop.', airportExpressUrl, 'Download'),
      new CarouselItem('carousel-link-smartpoint', require('../../Styles/carousel-img/carousel-smartpoint.jpg'),
        'Smartpoint Desktop Bundle', 'Real-time content from over 400 airlines, 650,000 unique hotel properties and 36,000 car rental locations.', smartpointUrl, 'Download'),
    ];
    if (this.props.user && this.props.user.profile && !this.props.user.expired) {
      const isUserEmployee = this.props.user.profile.role === roleNameConstants.MarketplaceTeamAdmin.name
        || this.props.user.profile.role === roleNameConstants.TPSupport.name
        || this.props.user.profile.role === roleNameConstants.TPPlatformProductAdmin.name;
      const isuserDeveloper = this.props.user.profile.role === roleNameConstants.DeveloperUser.name || this.props.user.profile.role === 'SPCDeveloperUser';
      if (isUserEmployee) {
        carousalItems = [
          new CarouselItem('carousel-link-help', require('../../Styles/carousel-img/carousel-help.jpg'),
            'Help', 'Get in touch with us.', helpUrl, 'Learn More'),
          new CarouselItem('carousel-link-smartpoint', require('../../Styles/carousel-img/carousel-smartpoint.jpg'), 'Smartpoint Desktop Bundle',
            'Real-time content from over 400 airlines, 650,000 unique hotel properties and 36,000 car rental locations.', smartpointUrl, 'Download'),
          new CarouselItem('carousel-link-manage-orgs', require('../../Styles/carousel-img/carousel-manage-orgs.jpg'),
            'Organization Directory', 'Manage the Agency Administrators of your Agency customers.', manageOrgsUrl, 'Learn More'),

          new CarouselItem('carousel-link-smartpoint', require('../../Styles/carousel-img/carousel-smartpoint.jpg'),
            'Smartpoint Desktop Bundle', 'Real-time content from over 400 airlines, 650,000 unique hotel properties and 36,000 car rental locations.', smartpointUrl, 'Download'),
          new CarouselItem('carousel-link-manage-orgs', require('../../Styles/carousel-img/carousel-manage-orgs.jpg'),
            'Organization Directory', 'Manage the Agency Administrators of your Agency customers.', manageOrgsUrl, 'Learn More'),
          new CarouselItem('carousel-link-tp-dev-network', require('../../Styles/carousel-img/carousel-tp-marketplace.jpg'),
            'Travelport Developer Network', 'Build and promote your own travel technology business with Travelport.', tdnUrl, 'Apply'),
        ];
      } else if (isuserDeveloper) {
        carousalItems = [
          new CarouselItem('carousel-link-tools', require('../../Styles/carousel-img/carousel-tools.jpg'),
            'Developer Tools', 'Explore our portfolio of developer tools.', toolsUrl, 'Learn More'),
          new CarouselItem('carousel-link-account-info', require('../../Styles/carousel-img/carousel-account-info.jpg'),
            'Account Information', 'Manage your account and contact details.', accountInfoUrl, 'Learn More'),
          new CarouselItem('carousel-link-help', require('../../Styles/carousel-img/carousel-help.jpg'), 'Help', 'Get in touch with us.', helpUrl, 'Learn More'),

          new CarouselItem('carousel-link-account-info', require('../../Styles/carousel-img/carousel-account-info.jpg'),
            'Account Information', 'Manage your account and contact details.', accountInfoUrl, 'Learn More'),
          new CarouselItem('carousel-link-help', require('../../Styles/carousel-img/carousel-help.jpg'),
            'Help', 'Get in touch with us.', helpUrl, 'Learn More'),
          new CarouselItem('carousel-link-conversion', require('../../Styles/carousel-img/carousel-customer-engagement.jpg'),
            'Increased Conversion', 'Optimize your products with engaging content.', conversionUrl, 'Learn More'),
        ];
      }
    }
    return carousalItems;
  }

  getHomeBrandRowProps = () => {
    let homeBarndRowProps = {
      heading: 'Travelport Marketplace',
      description: 'Your first port of call for Travel Solutions from Travelport and our approved 3rd party partners.',
      navigationPath: '/products',
      buttonText: 'Browse'
    };
    if (this.props.user && this.props.user.profile && !this.props.user.expired) {
      const isUserEmployee = this.props.user.profile.role === roleNameConstants.MarketplaceTeamAdmin.name
        || this.props.user.profile.role === roleNameConstants.TPSupport.name
        || this.props.user.profile.role === roleNameConstants.TPPlatformProductAdmin.name;
      const isUserAgencyAdmin = this.props.user.profile.role === roleNameConstants.AgencyAdmin.name
        || this.props.user.profile.role === roleNameConstants.AgencySuperAdmin.name;
      const isuserDeveloper = this.props.user.profile.role === roleNameConstants.DeveloperUser.name;
      if (isUserEmployee) {
        homeBarndRowProps = {
          heading: 'Get in touch with us.',
          description: 'Do you need any help or you just want to send us feedback? We\'d like to hear from you!',
          navigationPath: 'https://my.travelport.com/',
          buttonText: 'Contact Us'
        };
      } else if (isUserAgencyAdmin) {
        homeBarndRowProps = {
          heading: 'Need talent to develop your own application?',
          description: 'Browse a community of certified developers who can help take your business forward.',
          navigationPath: '/developers',
          buttonText: 'Developer Network'
        };
      } else if (isuserDeveloper) {
        homeBarndRowProps = {
          heading: 'Resource Center',
          description: 'Find all the marketing and technical resources you need to develop new and exciting solutions.',
          navigationPath: 'https://developer.travelport.com/app/developer-network/resource-centre-dev-network',
          buttonText: 'View Details'
        };
      }
    }
    return homeBarndRowProps;
  }

  fetchPropularProducts() {
    return fetch(`${Config.BASE_URL}${Config.GET_FEATURED_PRODUCTS}`).then((response) => (this.isValidResponse(response) ? response.json() : []));
  }

  isValidResponse(response) {
    if (response.status === 200 || response.status === 201) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="container">
        {this.props.appNameGuidMapping && <Carousel carouselItems={this.getCarousalItems()} isLargeView />}
        {this.props.appNameGuidMapping && <Carousel carouselItems={this.getCarousalItems()} />}
        <HomeBrandRow {...(this.getHomeBrandRowProps())} />
        <div className="text-center">
          <h2 className="popular-products-title">Popular Products</h2>
        </div>
        <PopularProducts data={this.props.popularProducts} showWhiteTile={false} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  popularProducts: state.popularProductsReducer,
  user: state.oidc.user,
  appNameGuidMapping: state.home.appNameGuidMapping
});

const mapDispatchToProps = (dispatch) => ({
  loadPopularProducts: bindActionCreators(loadPopularProducts, dispatch),
  getAppNameGuidMapping: (applicationGuids) => dispatch(getAppNameGuidMapping.base(applicationGuids)),
  getBuildNumber: () => dispatch(getBuildNumber.base()),
  getBannerNotification: () => dispatch(getBannerNotification.base()),
  loadUserInfo: () => dispatch(loadUserInfo.base())
});

Home.propTypes = {
  loadPopularProducts: PropTypes.any,
  popularProducts: PropTypes.any,
  user: PropTypes.object,
  getAppNameGuidMapping: PropTypes.func,
  appNameGuidMapping: PropTypes.array,
  getBannerNotification: PropTypes.func,
  loadUserInfo: PropTypes.func,
  getBuildNumber: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
