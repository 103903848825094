import {
  takeLatest, call, put, select, race, take
} from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import {
  PRICEBYLOCATION_SALETYPE_NONE,
  PRICEBYLOCATION_SALETYPE_RESELLER,
  PRICEBYLOCATION_SALETYPE_REFERRAL
} from 'constants/PPCP';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal,
} from 'reducers/popUps/confirmationModalReducer';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import editArray from 'reducers/utils/updateStateArray';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import {
  publicIdSelector,
  priceIdSelector
} from '../selectors';

const DELETE_CONFIRM_MSG = 'Are you sure you want to delete the entry?';
const GET_PRICEBYLOCATION = createFetchTypes('Marketplace/Application/GET_PRICEBYLOCATION');
const SAVE_PRICEBYLOCATION = createFetchTypes('Marketplace/Application/SAVE_PRICEBYLOCATION');
const DELETE_PRICEBYLOCATION = createFetchTypes('Marketplace/Application/DELETE_PRICEBYLOCATION');

function priceByLocationReducer(state = [], action = {}) {
  switch (action.type) {
    case GET_PRICEBYLOCATION.SUCCESS:
      return action.payload;
    case SAVE_PRICEBYLOCATION.SUCCESS: {
      const index = state.findIndex(s => s.id === action.payload.id);
      if (index !== -1) {
        return editArray(state, index, action.payload);
      }
      return [
        ...state,
        action.payload
      ];
    }
    case DELETE_PRICEBYLOCATION.SUCCESS:
      return state.filter(s => s.id !== action.payload.id);
    default:
      return state;
  }
}

export const getPriceByLocation = {
  base: (data) => ({
    type: GET_PRICEBYLOCATION.BASE,
    url: 'application/getpricebylocations',
    data
  }),
  request: () => ({ type: GET_PRICEBYLOCATION.REQUEST }),
  success: payload => ({ type: GET_PRICEBYLOCATION.SUCCESS, payload }),
  failure: error => ({ type: GET_PRICEBYLOCATION.FAILURE, error }),
};

export const savePriceByLocation = {
  base: (data) => ({
    type: SAVE_PRICEBYLOCATION.BASE,
    url: 'application/SavePriceByLocation',
    data
  }),
  request: () => ({ type: SAVE_PRICEBYLOCATION.REQUEST }),
  success: payload => ({ type: SAVE_PRICEBYLOCATION.SUCCESS, payload }),
  failure: error => ({ type: SAVE_PRICEBYLOCATION.FAILURE, error }),
};

export const deletePriceByLocation = {
  base: (data) => ({
    type: DELETE_PRICEBYLOCATION.BASE,
    url: 'application/DeletePriceByLocation',
    data
  }),
  request: () => ({ type: DELETE_PRICEBYLOCATION.REQUEST }),
  success: payload => ({ type: DELETE_PRICEBYLOCATION.SUCCESS, payload }),
  failure: error => ({ type: DELETE_PRICEBYLOCATION.FAILURE, error }),
};

const saleType = (saleTypeName) => {
  switch (saleTypeName) {
    case PRICEBYLOCATION_SALETYPE_NONE:
      return 0;
    case PRICEBYLOCATION_SALETYPE_RESELLER:
      return 1;
    case PRICEBYLOCATION_SALETYPE_REFERRAL:
      return 2;
    default:
      return 0;
  }
};

function createPriceBylocationRequest(data, appId, priceId) {
  return {
    appId,
    priceId: priceId || 0,
    priceByLocationViewModel: {
      id: data.id || 0,
      countryId: 0,
      countryName: data.countryName,
      isAllOtherSetting: data.isAllOtherSetting,
      pricingInformation: data.pricingInformation,
      pricingNotes: data.pricingNotes,
      saleType: saleType(data.saleTypeName),
      modifyMode: true
    }
  };
}

function* savePriceByLocationSaga({ url, data }) {
  yield put(showLoader());
  try {
    const appId = yield select(publicIdSelector);
    const priceId = yield select(priceIdSelector);
    let changes;
    const index = 0;
    if (data.addedRows && data.addedRows.length > 0) {
      changes = data.addedRows[index];
    } else {
      changes = data.updateRows;
    }
    const request = createPriceBylocationRequest(changes, appId, priceId);
    savePriceByLocation.request();
    const response = yield ApiClient.httpClient.post(url, { ...request });
    yield put(savePriceByLocation.success({ id: response.data.priceByLocationID, ...changes }));
    yield put(showAlertAction('Entry was succesfully saved.'));
    data.onSuccess(); // Update datagrid UI
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
  yield put(hideLoader());
}

function* getPriceByLocationSaga({ url, data }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(getPriceByLocation, ApiClient.httpClient.get, url, { params: { appId: data.publicId, organizationId: data.organizationId } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
  yield put(hideLoader());
}

function* deletePriceByLocationSaga({ url, data }) {
  try {
    yield put(showConfirmationModal(DELETE_CONFIRM_MSG, ''));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      yield put(showLoader());
      deletePriceByLocation.request();
      const appId = yield select(publicIdSelector);
      yield ApiClient.httpClient.delete(url, { params: { appId, priceId: data.row.id } });
      yield put(deletePriceByLocation.success({ id: data.row.id }));
      yield put(showAlertAction('Entry was succesfully deleted.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
  yield put(hideLoader());
}

export function* watchDeletePriceByLocationSaga() {
  yield takeLatest(DELETE_PRICEBYLOCATION.BASE, deletePriceByLocationSaga);
}

export function* watchSavePriceByLocationSaga() {
  yield takeLatest(SAVE_PRICEBYLOCATION.BASE, savePriceByLocationSaga);
}

export function* watchGetPriceByLocationSaga() {
  yield takeLatest(GET_PRICEBYLOCATION.BASE, getPriceByLocationSaga);
}

export default priceByLocationReducer;
