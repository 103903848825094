/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HeaderContentTileArea extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { value: '' };
  }

  componentDidUpdate = newProps => {
    const newVDefaultvalue = newProps.sortByItems.filter(item => item.selected);

    if (newVDefaultvalue.length) {
      if (newVDefaultvalue[0].value !== this.state.value) {
        this.setState({ value: newVDefaultvalue[0].value });
      }
    }
  };

  handleChangeSortBy = event => {
    this.setState({ value: event.target.value });
    this.props.handleChangeSortBy(event.target.value);
  };

  render() {
    return (
      <div className="row">
        <div className="xs-col-12 col-sm-6 browse-products-showing">
          Showing
          {' '}
          {this.props.startPage}
          -
          {this.props.endPage}
          {' '}
          of
          {` ${
            this.props.totalPage}`}
        </div>
        <div className="xs-col-12 col-sm-6 browse-products-sort-by">
          <label htmlFor="sortProductsSelect"> Sort By:&nbsp;&nbsp;</label>
          <select
            id="sortProductsSelect"
            onChange={this.handleChangeSortBy}
            value={this.state.value}
          >
            {this.props.sortByItems.map((v, i) => (
              <option value={v.value} key={i.toString()}>
                {v.Name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

HeaderContentTileArea.propTypes = {
  applicationName: PropTypes.string,
  onChange: PropTypes.func,
  startPage: PropTypes.number,
  endPage: PropTypes.number,
  totalPage: PropTypes.number,
  handleChangeSortBy: PropTypes.func,
  sortByItems: PropTypes.array
};

export default HeaderContentTileArea;
