import React, { Component } from 'react';

class error500 extends Component {
  render() {
    return (
      <div className="container">
        <h2> System Error</h2>
        <div className="large-error row">
          A system error has occured.
          <br className="small-view-br" />
          {' '}
          We apologize
          for the inconvenience.
          <br className="small-view-br" />
          {' '}
          Please close this browser window and retry your action in a new window
        </div>
      </div>
    );
  }
}

export default error500;
