/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateTimeHelper from 'utils/dateHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchUserLoginReport } from 'reducers/modules/Administration/Reports';
import { userLoginReportTotalPages, userLoginReportSelector } from 'reducers/modules/Administration/Reports/UserLoginReport/selectors';
import { exportToExcelFunc } from 'reducers/modules/Administration/Reports/ExportToExcel/ExportToExcel';
import { ReportsComponent } from '../CommonParts/ReportsComponent';

class UserLoginReport extends PureComponent {
  constructor(props) {
    super(props);
    const utcDate = new Date(moment.utc().format());
    this.state = {
      startDate: moment(utcDate).subtract(2, 'years'),
      endDate: utcDate,
      sortAsc: false,
      excludeTPEmployees: false,
      sortOnColumn: '',
      sorting: {
        columnName: '',
        direction: 'asc'
      },
      paging: {
        currentPage: 0,
        pageSize: 20
      }
    };
    this.columnDefinitions = [
      { name: 'email', title: 'Email' },
      { name: 'pcc', title: 'PCC' },
      { name: 'organization', title: 'ORGANIZATION' },
      { name: 'customerNumber', title: 'CUSTOMER NUMBER' },
      { name: 'lastSuccessfulLogin', title: 'LAST SUCCESSFUL LOGIN' },
      { name: 'country', title: 'COUNTRY' },
      { name: 'roles', title: 'Roles' },
      { name: 'currentRole', title: 'CURRENT ROLE' }

    ];
  }

  componentDidMount() {
    document.title = 'User Login Report';
    this.getFilteredUserLogin();
  }

  getFilteredUserLogin = (e, page) => {
    if (e) {
      e.preventDefault();
    }
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentPage: page || this.state.paging.currentPage,
      excludeTPEmployees: this.state.excludeTPEmployees
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.fetchLoginReportAction(params);
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  };

  getSortDirection = () => this.state.sorting.direction === 'asc';

  handleClear = () => this.setState({ startDate: '', endDate: '', excludeTPEmployees: '' });

  searchHandler = (e) => {
    this.getFilteredUserLogin(e, '0');
  }

  sortHandler = newSorting => this.setState(
    { sorting: { ...newSorting[0] } },
    this.getFilteredUserLogin
  );

  pageHandler = currentPage => this.setState(
    { paging: { ...this.state.paging, currentPage } },
    this.getFilteredUserLogin
  );

  exportToExcel = (e) => {
    e.preventDefault();
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortOnColumn: this.getSortColumn() || '',
      excludeTPEmployees: this.state.excludeTPEmployees,
      sortAsc: this.getSortColumn() ? this.getSortDirection() : false
    };

    if (this.state.sorting.columnName !== '') {
      params.sortOnColumn = this.state.sorting.columnName;
    }

    this.props.userLoginReportDownloadExcel(params);
  };

  datehandler = (e, field) => {
    this.setState({
      [field]: DateTimeHelper.getDate(e)
    });
  };

  handleTPemp=(checked) => this.setState({ excludeTPEmployees: checked });

  render() {
    return (
      <ReportsComponent
        reportName="User Login Report"
        startDate={this.state.startDate}
        datehandler={this.datehandler}
        disableFuturePastDt={DateTimeHelper.disableFuturePastDt}
        endDate={this.state.endDate}
        excludeTPEmployees={this.state.excludeTPEmployees}
        handleTPemp={this.handleTPemp}
        searchHandler={this.searchHandler}
        handleClear={this.handleClear}
        exportToExcel={this.exportToExcel}
        columnDefinitions={this.columnDefinitions}
        rows={this.props.rows}
        currentPage={this.state.paging.currentPage}
        pageSize={this.state.paging.pageSize}
        pageHandler={this.pageHandler}
        sorting={this.sorting}
        sortHandler={this.sortHandler}
        showSortingControls
        totalPages={this.props.totalPages}
      />
    );
  }
}

UserLoginReport.propTypes = {
  fetchLoginReportAction: PropTypes.func,
  loginReportDownloadExcel: PropTypes.func,
  rows: PropTypes.array,
  totalPages: PropTypes.number,
  userLoginReportDownloadExcel: PropTypes.func
};

const mapStateToProps = state => ({
  rows: userLoginReportSelector(state),
  totalPages: userLoginReportTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  fetchLoginReportAction: args => dispatch(fetchUserLoginReport.base(args)),
  userLoginReportDownloadExcel: args => dispatch(exportToExcelFunc.userLoginReport(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLoginReport);
