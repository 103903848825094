import { put, all, call } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient';
import { roleNameConstants } from 'utils/rolesHelper';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

export const FETCH_AGENCY_USER_SET = 'FETCH_AGENCY_USER_SET';
const FETCH_AGENCY_USER_SET_SUCCESS = 'FETCH_AGENCY_USER_SET_SUCCESS';
const FETCH_AGENCY_USER_SET_FAILURE = 'FETCH_AGENCY_USER_SET_FAILURE';
const ROLE_SET_FETCHED = 'ROLE_SET_FETCHED';
const FETCH_AGENCY_USER = '/profile/getusersfororg';
const FETCH_USER_ROLE = '/Profile/GetRolesListForAgencyMembers';

export const fetchAgencyUserSet = {
  base: (params) => ({
    type: FETCH_AGENCY_USER_SET,
    payload: {
      params
    }
  })
};

const fetchAgencyUserSetSuccess = (data) => ({
  type: FETCH_AGENCY_USER_SET_SUCCESS,
  payload: {
    data
  }
});

const fetchAgencyUserSetError = (error) => ({
  type: FETCH_AGENCY_USER_SET_FAILURE,
  payload: {
    error
  }
});

const storeRoleSet = (data) => ({
  type: ROLE_SET_FETCHED,
  payload: {
    data
  }
});

const agencyUserIndexReducer = (state = { data: [] }, action = {}) => {
  switch (action.type) {
    case FETCH_AGENCY_USER_SET:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AGENCY_USER_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };

    case FETCH_AGENCY_USER_SET_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    default:
      return state;
  }
};

export const agencyUserMetaReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ROLE_SET_FETCHED:
      return {
        ...state,
        roleSet: action.payload.data
      };
    default:
      return state;
  }
};
export function* fetchAgencyUserSetWatcher(action) {
  yield put(showLoader());
  try {
    const { params } = action.payload;
    const [agencyUserSetResponse, roleSet] = yield all([call(() => ApiClient.httpClient.get(FETCH_AGENCY_USER, { params: { orgId: params.orgId, filter: '' } })), call(() => ApiClient.httpClient.get(FETCH_USER_ROLE))]);
    roleSet.data.push({ value: 0, text: roleNameConstants.AgencySuperAdmin.name, disabled: true });
    const agencyUserSet = agencyUserSetResponse.data.map(user => ({
      userId: user.id,
      email: user.email,
      name: user.name,
      groups: user.groups,
      roles: [user.role],
      checkRoleEdit: roleSet.data.map(data => data.text === user.role.text)
    }));
    yield put(storeRoleSet(roleSet.data));
    yield put(fetchAgencyUserSetSuccess(agencyUserSet));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(fetchAgencyUserSetError(error));
  }
  yield put(hideLoader());
}

export default agencyUserIndexReducer;
