/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StarComponent from '../../common/StarComponent';

class SubmitReviewPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: props.review,
    };
  }

  handleChange = (newInput) => {
    this.setState(prevState => ({
      ...prevState,
      review: {
        ...prevState.review,
        ...newInput
      }
    }));
  };

  render() {
    const { onCancelCreate, onSubmitReview } = this.props;
    return (
      <div className="col-sm-9 writeReview" id="writeReview">
        <div className="row">
          <div className="col-sm-3 left">
            <label htmlFor="starRating"> Your Rating:</label>
          </div>
          <div className="col-sm-9 left rating-star-div">
            <StarComponent name="starRating" half={false} size={25} edit value={this.state.review.rating} onChange={(val) => this.handleChange({ rating: val })} color2="#4E6659" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 left">
            <label htmlFor="reviewTitle">Review Title:</label>
          </div>
          <div className="col-sm-9 left">
            <input
              type="text"
              name="reviewTitle"
              className="text-input"
              maxLength="50"
              value={this.state.review.reviewTitle}
              onChange={(e) => this.handleChange({ reviewTitle: e.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 left">
            <label htmlFor="reviewText">Review:</label>
          </div>
          <div className="col-sm-9 left">
            <textarea
              rows="4"
              name="reviewText"
              className="text-input"
              cols="50"
              maxLength="1000"
              style={{ height: '110px' }}
              value={this.state.review.productReview}
              onChange={(e) => this.handleChange({ productReview: e.target.value })}
            />
          </div>
        </div>
        <div className="row no-space-bottom pad-top-sm right">
          <a className="blue-button" onClick={() => onSubmitReview(this.state.review)}>Submit Review</a>
          <a className="blue-button" onClick={onCancelCreate}>Cancel</a>
        </div>
      </div>
    );
  }
}

SubmitReviewPresentation.propTypes = {
  onCancelCreate: PropTypes.func,
  onSubmitReview: PropTypes.func,
  review: PropTypes.object
};

export default SubmitReviewPresentation;
