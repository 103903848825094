import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { call, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios/index';
import ApiClient from '../../../../../Services/ApiClient';
import createFetchTypes from '../../../../utils/createFetchTypes';
import fetchEntity from '../../../../utils/fetchEntitySaga';
import { API_PREFIX, BASE_URL } from '../../../../../config/apiConfig';

export const GET_HOST_LIST = createFetchTypes(
  'Marketplace/Agency/GET_HOST_LIST'
);

export default function hostList(state = [], action = {}) {
  switch (action.type) {
    case GET_HOST_LIST.REQUEST:
      return state;
    case GET_HOST_LIST.SUCCESS:
      return action.payload;
    case GET_HOST_LIST.FAILURE:
      return state;
    default:
      return state;
  }
}

export const getHostList = {
  base: data => ({
    type: GET_HOST_LIST.BASE,
    url: '/Profile/GetHostsDropdownForProfilePage',
    data
  }),
  request: () => ({ type: GET_HOST_LIST.REQUEST }),
  success: payload => ({ type: GET_HOST_LIST.SUCCESS, payload }),
  failure: error => ({ type: GET_HOST_LIST.FAILURE, error })
};

function* hostListSaga({ url, data }) {
  const httpConfig = {
    baseURL: BASE_URL + API_PREFIX,
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache'
    }
  };
  ApiClient.httpClient = Axios.create(httpConfig);
  try {
    yield call(() => fetchEntity(getHostList, ApiClient.httpClient.get, url, { data }));
  } catch (error) {
    yield put(showAlertAction(error.message));
  }
}

export function* watchHostListSaga() {
  yield takeLatest(GET_HOST_LIST.BASE, hostListSaga);
}
