import { takeLatest, call, put } from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const FETCH_USERFEEDBACK_REPORTS = createFetchTypes(
  'Marketplace/Reports/userFeedbackReport/FETCH_USERFEEDBACK_REPORTS'
);

const FETCH_URL = 'reports/GetFilteredUserFeedbackReport';

function userFeedbackReport(state = { data: { userFeedbackViewModels: { pagedCollection: [] } } }, action = {}) {
  switch (action.type) {
    case FETCH_USERFEEDBACK_REPORTS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERFEEDBACK_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case FETCH_USERFEEDBACK_REPORTS.ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export const fetchUserFeedbackReport = {
  base: params => ({
    type: FETCH_USERFEEDBACK_REPORTS.BASE,
    params,
    url: FETCH_URL
  }),
  request: () => ({ type: FETCH_USERFEEDBACK_REPORTS.REQUEST }),
  success: data => ({ type: FETCH_USERFEEDBACK_REPORTS.SUCCESS, data }),
  failure: error => ({ type: FETCH_USERFEEDBACK_REPORTS.FAILURE, error })
};

export function* fetchUserFeedbackReportSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchUserFeedbackReport, ApiClient.httpClient.post, url, params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export default userFeedbackReport;

export function* userFeedbackReportMiddleware() {
  yield takeLatest(FETCH_USERFEEDBACK_REPORTS.BASE, fetchUserFeedbackReportSaga);
}
