/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
  SortingState,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { getOrgId, isTpProfile } from 'reducers/modules/Profile/selectors';
import { roleNameConstants } from 'utils/rolesHelper';
import { fetchAgencyUserSet } from 'reducers/modules/Profile/Agency/AgencyUser/IndexReducer';
import { updateAgencyUser } from 'reducers/modules/Profile/Agency/AgencyUser/updateReducer';
import DataCells from './TableCellComponents/DataCells';
import EditCell from './TableCellComponents/EditCell';
import CommandCell from './TableCellComponents/CommandCell';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

const capitalizefunc = (text) => text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

class AgencyUsersTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'name', title: 'Name', getCellValue: row => capitalizefunc(row.name) },
      { name: 'email', title: 'Email' },
      { name: 'roles', title: 'Role' },
      { name: 'groups', title: 'Group(s)' },
    ];

    this.state = {
      filters: {
        orgId: props.orgId || props.match.params.id
      },
      paging: {
        currentPage: 0,
        pageSize: 18,
      },
      editingRowIds: [],
      addedRows: [],
      sortingStateColumnExtensions: [
        { columnName: 'roles', sortingEnabled: false },
        { columnName: 'groups', sortingEnabled: false },
      ],
    };
  }

  componentDidMount() {
    if (this.props.orgId) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgId && this.props.orgId !== prevProps.orgId) {
      this.fetch();
    }
  }

  getEntitySetWithPermissions = () => {
    const { currentUser } = this.props;
    const canUserEditAgencyMembers = !this.props.isTpProfile;
    const canUserEditSuperAgencyAdmins = currentUser.profile.role === roleNameConstants.AgencySuperAdmin.name
      || currentUser.profile.role === roleNameConstants.TPAdmin.name
      || currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name;
    return this.props.entitySet.map((entity) => ({
      ...entity,
      canUserEditAgencyMembers,
      canUserEditSuperAgencyAdmins
    }));
  };

  //  action that triggers the call to teh API
  fetch = () => (
    this.props.fetch({ orgId: this.props.orgId })
  );

  //  handlers for the pagination and sorting state (internal state of the component)
  //  handlers for edition and creation of rows
  handleEditRow = (rows) => {
    const row = rows.slice(-1); // quite bad implementation to obtain a single row edition at a time
    this.setState({ editingRowIds: row, addedRows: [] });
  };

  // callback function when add row button is clicked
  handleNewRow = (rows) => {
    const row = rows.slice(-1);// quite bad implementation to obtain a single row edition at a time
    this.setState({ editingRowIds: [], addedRows: row });
  };

  pageHandler = currentPage => this.setState({ paging: { ...this.state.paging, currentPage } });

  // calback function when save is clicked
  commitChanges = ({ changed }) => {
    // This functionality of adding user is not present in agency users table
    // if (added) {
    //   this.props.create(added[0]);
    // }
    if (changed) {
      const entity = { ...this.props.entitySet[this.state.editingRowIds], ...changed[this.state.editingRowIds] };
      this.props.entitySet[this.state.editingRowIds] = entity;
    }
    // This functionality of deleting user is not present in agency users table
    // if (deleted) {
    //   this.props.delete({ groupId: this.props.entitySet[deleted].groupId });
    // }
  };

  render() {
    return (
      <div className="travelport-table">
        <LoaderComponent show={this.props.loading} />
        <Grid
          columns={this.columnDefinitions}
          rows={this.getEntitySetWithPermissions()}
        >
          <EditingState
            editingRowIds={this.state.editingRowIds}
            onEditingRowIdsChange={this.handleEditRow}
            addedRows={this.state.addedRows}
            onAddedRowsChange={this.handleNewRow}
            onCommitChanges={this.commitChanges}
          />

          <PagingState
            currentPage={this.state.paging.currentPage}
            pageSize={this.state.paging.pageSize}
            onCurrentPageChange={this.pageHandler}
          />
          <IntegratedPaging />

          <SortingState
            columnExtensions={this.state.sortingStateColumnExtensions}
          />
          <IntegratedSorting />

          <Table
            cellComponent={DataCells}
          />
          <TableHeaderRow showSortingControls />
          <TableEditColumn
            showEditCommand
            cellComponent={CommandCell}
          />
          <TableEditRow
            cellComponent={EditCell}
          />
          <PagingPanel
            pageSizes={[]}
          />
        </Grid>
      </div>
    );
  }
}

AgencyUsersTable.propTypes = {
  entitySet: PropTypes.array,
  columnDefinitions: PropTypes.array,
  orgId: PropTypes.number,
  fetch: PropTypes.func,
  update: PropTypes.func,
  create: PropTypes.func,
  delete: PropTypes.func,
  match: PropTypes.object,
  isTpProfile: PropTypes.bool,
  currentUser: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  entitySet: state.agencyUser.index.data || [],
  loading: state.agencyUser.index.loading,
  orgId: getOrgId(state),
  creationStatus: state.agencyUser.create,
  updateStatus: state.agencyUser.update,
  currentUser: state.oidc.user,
  isTpProfile: isTpProfile(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (args) => dispatch(fetchAgencyUserSet.base(args)),
  // create: bindActionCreators(createAgencyUser.base, dispatch), We do not have this functionality in agency users table
  update: bindActionCreators(updateAgencyUser, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AgencyUsersTable));
