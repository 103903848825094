import { select } from 'redux-saga/effects';

/* SAGA SELECTOR */
const selectionSelector = state => state.form.support.values;

const filter = (docs, props) => {
  const list = [];
  for (let i = 0; i < docs.length; i += 1) {
    const id = docs[i][props];
    if (id) {
      list.push(id);
    }
  }
  return list;
};

const fetchDocumentationNames = (state) => [state.documentationName1, state.documentationName2, state.documentationName3];

const fetchFilterDescriptions = (state) => [state.fileDescription1, state.fileDescription2, state.fileDescription3];

export function* createSupportRequest() {
  const supportState = yield select(selectionSelector);
  return {
    ...supportState,
    supportPage: supportState.supportPage,
    supportContactPerson: supportState.supportContactPerson,
    supportContactEmail: supportState.supportContactEmail,
    supportDocuments: supportState.supportDocuments,
    documentNames: fetchDocumentationNames(supportState),
    supportDocIds: filter(supportState.supportDocuments, 'id'),
    fileDescriptions: fetchFilterDescriptions(supportState),
    title: supportState.title,
    publicGuid: supportState.publicGuid,
    lastUpdated: supportState.lastUpdated,
    gotoURLAfterSave: supportState.gotoURLAfterSave,
    displayIncrementalDifferenceFile: supportState.displayIncrementalDifferenceFile,
    displayPreviousVersion: supportState.displayPreviousVersion,
  };
}
