import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ButtonPanel,
  LeftNavigationPanel,
  CompletionIndicator
} from 'components/Application';
import { isInternalPPCP } from 'reducers/modules/Application/selectors';
import * as utils from '../../../utils/Common.Utils';
import ServerErrorPopup from '../ServerErrorPopup/ServerErrorPopup';

const MainLayout = ({ isInternal, children, location }) => (
  <>
    {isInternal || utils.getValueFromQueryString(location, 'isInternal') === 'true' ? <h2>Create Your Internal Solution Page</h2> : <h2> Manage Solution</h2>}
    <div className="container">
      <div className="row">
        <div className="col-sm-9 pull-right">
          {children}
          <ButtonPanel />
        </div>
        <div className="col-sm-3 pad-right-double space-top">
          <LeftNavigationPanel />
          <CompletionIndicator />
        </div>
      </div>
      <ServerErrorPopup />
    </div>
  </>
);

MainLayout.propTypes = {
  isInternal: PropTypes.bool,
  children: PropTypes.any,
  location: PropTypes
};

const mapStateToProps = state => ({
  isInternal: isInternalPPCP(state)
});

export default connect(mapStateToProps, null)(MainLayout);
