/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BASIC_INFORMATION_ROUTE } from 'constants/PPCP';
import * as utils from 'utils/Common.Utils';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import { Getter } from '@devexpress/dx-react-core';
import {
  PagingState,
  CustomPaging,
  SortingState,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableEditRow,
  Toolbar,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  initProductAccessPage,
  saveProductAccess,
  changeGlobalAssignment,
  changeVisibilityNonParticipants,
  changeVisibilityTPEmployees,
  deleteProductAccess,
  downloadExcel,
  clear
} from 'reducers/modules/Administration/ProductAccess/actions';
import {
  productAccessSelector,
  productAccessListSelector,
  totalPagesSelector,
  productNameSelector
} from 'reducers/modules/Administration/ProductAccess/selectors';
import LoaderComponent from 'components/common/Loader/LoaderComponent';
import EditCell from './TableCells/ProductAccessEditCells';

class ProductAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        appPublicId: utils.getValueFromQueryString(this.props.location, 'id'),
        sortOnColumn: '',
        sortAsc: true,
        currentPage: 0,
        isPos: true,
        filter: ''
      },
      pageSize: 20,
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      deletingRows: [],
      columns: [
        { name: 'accessCategory', title: 'ACCESS CATEGORY' },
        { name: 'categoryValueOne', title: 'CATEGORY VALUE' },
        { name: 'categoryValueTwo', title: 'CATEGORY VALUE' },
      ],
    };
  }

  componentDidMount() {
    this.props.initProductAccessPageAction(this.state.filters);
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  onFilterChange = (event) => this.setState({ filters: { ...this.state.filters, filter: event.target.value } });

  changeRowChanges = rowChanges => this.setState({ rowChanges })

  changeAddedRows = addedRows => this.setState({ addedRows });

  changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });

  deletedRowIdsChange = deletingRows => this.setState({ deletingRows });

  commitChanges = ({ deleted }) => {
    if (deleted) {
      const { onDelete, rows } = this.props;
      onDelete({
        row: rows[deleted[0]],
        appPublicId: utils.getValueFromQueryString(this.props.location, 'id'),
      });
    }
  }

  commitChangesAsync = (id, onExecute) => {
    const { onSave, rows } = this.props;
    switch (id) {
      case 'commit': {
        const save = {};
        if (this.state.addedRows.length > 0) {
          save.addedRows = this.state.addedRows;
        }
        if (this.state.rowChanges[this.state.editingRowIds[0]]) {
          save.updatedRows = {
            accessCategory: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].accessCategory,
            categoryValueOne: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].categoryValueOne,
            categoryValueTwo: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].categoryValueTwo,
            ...this.state.rowChanges[this.state.editingRowIds[0]],
            id: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].id,
          };
        }
        onSave({
          ...save,
          appPublicId: utils.getValueFromQueryString(this.props.location, 'id'),
          onSuccess: onExecute
        });
        break;
      }
      default:
        onExecute();
        break;
    }
  }

  command = ({ id, onExecute }) => {
    const commandComponentProps = {
      add: {
        text: 'New',
        hint: 'Create new row',
      },
      edit: {
        text: 'Edit',
        hint: 'Edit row',
      },
      delete: {
        text: 'Delete',
        hint: 'Delete row',
      },
      commit: {
        text: 'Save',
        hint: 'Save changes',
      },
      cancel: {
        text: 'Cancel',
        hint: 'Cancel changes',
      },
    };
    return (
      <a
        className="pad-sm"
        onClick={(e) => {
          e.preventDefault();
          this.commitChangesAsync(id, onExecute);
        }}
      >
        {commandComponentProps[id].text}
      </a>
    );
  }

  clearCategoryValues = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueOne = '';
      this.state.addedRows[0].categoryValueTwo = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueOne = '';
        this.state.rowChanges[rowIndex].categoryValueTwo = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueOne: '',
          categoryValueTwo: '',
        };
      }
    }
  };

  clearCategoryValueOne = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueOne = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueOne = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueOne: '',
        };
      }
    }
  };

  clearCategoryValueTwo = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueTwo = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueTwo = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueTwo: '',
        };
      }
    }
  };

  EditCell = (props) => {
    const { rows } = this.props;
    let currentAccessCategory;
    let currentCategoryValueOne;
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      currentAccessCategory = this.state.addedRows[0].accessCategory;
      currentCategoryValueOne = this.state.addedRows[0].categoryValueOne;
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      currentAccessCategory = (this.state.rowChanges && this.state.rowChanges[rowIndex] && this.state.rowChanges[rowIndex].accessCategory) || rows[rowIndex].accessCategory;
      currentCategoryValueOne = (this.state.rowChanges && this.state.rowChanges[rowIndex] && this.state.rowChanges[rowIndex].categoryValueOne) || rows[rowIndex].categoryValueOne;
    }
    return (
      <EditCell
        currentAccessCategory={currentAccessCategory}
        rows={rows}
        {...props}
        clearCategoryValues={this.clearCategoryValues}
        clearCategoryValueTwo={this.clearCategoryValueTwo}
        currentCategoryValueOne={currentCategoryValueOne}
        clearCategoryValueOne={this.clearCategoryValueOne}
      />
    );
  }

  changeVisibilityNonParticipants = () => {
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'id');
    this.props.changeVisibilityNonParticipantsAction(appPublicId);
  }

  changeVisibilityTPEmployees = () => {
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'id');
    this.props.changeVisibilityTPEmployeesAction(appPublicId);
  }

  changeGlobalAccess = () => {
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'id');
    this.props.changeGlobalAssignmentAction(appPublicId);
  }

  pageHandler = (currentPage) => this.setState({ filters: { ...this.state.filters, currentPage } }, () => this.props.initProductAccessPageAction(this.state.filters));

  pageSizeHandler = (pageSize) => this.setState({ pageSize }, () => this.props.initProductAccessPageAction(this.state.filters));

  sortHandler = (newSorting) => {
    let sortOnColumn = newSorting[0].columnName;
    sortOnColumn = sortOnColumn.charAt(0).toUpperCase() + sortOnColumn.slice(1);
    this.setState({
      filters: {
        ...this.state.filters,
        sortOnColumn,
        sortAsc: newSorting[0].direction === 'asc'
      }
    }, () => this.props.initProductAccessPageAction(this.state.filters));
  }

  downloadExcel = (event) => {
    event.preventDefault();
    this.props.downloadExcel(this.state.filters);
  }

  renderOptions = () => {
    const {
      isGlobal,
      isVisibleToTPEmployees,
      isVisibleToNonParticipants
    } = this.props;

    return (
      <>
        <div className="row distinct-division pad-top pad-bottom">
          <div>
            <SimplePrettyRadioButton
              enabled
              displayText="GLOBAL"
              value={isGlobal}
              checked={isGlobal}
              onChange={this.changeGlobalAccess}
            />
          </div>
          <div>
            <SimplePrettyRadioButton
              enabled
              displayText="Restricted Access"
              value={isGlobal}
              checked={!isGlobal}
              onChange={this.changeGlobalAccess}
            />
          </div>
        </div>
        {!isGlobal
          && (
          <div className="row">
            <div>
              <SimplePrettyCheckBox
                enabled
                value={isVisibleToNonParticipants}
                onChange={this.changeVisibilityNonParticipants}
                displayText="Allow unlisted Marketplace users to view the product page."
                tooltip="Only users that appear in the list will be allowed to order your solution even if you allow everyone to view the product page."
              >
                Allow unlisted Marketplace users to view the product page.
              </SimplePrettyCheckBox>
            </div>
            <div>
              <SimplePrettyCheckBox
                enabled
                value={isVisibleToTPEmployees}
                displayText="Should non-list Travelport Employees have access to view/download this product?"
                onChange={this.changeVisibilityTPEmployees}
                tooltip={'Selecting this option allows any Travelport employee to access this product page.  De-selecting this option means that only restricted list '
                + 'members will be able to access the product page, regardless of organization.'}
              />
            </div>
          </div>
          )}
      </>
    );
  }

  renderSearchAndDownloadSection = () => (
    <div className="row">
      <div className="col-sm-3">
        <div className="col-sm-8">
          <input type="text" className="text-input text-left" value={this.state.filters.filter} onChange={this.onFilterChange} placeholder="Search" />
        </div>
        <div className="col-sm-3 col-sm-offset-1">
          <button type="button" className="action-button" onClick={() => this.props.initProductAccessPageAction(this.state.filters)}>Search</button>
        </div>
      </div>
      <div className="col-sm-3 col-sm-offset-6 text-right">
        <div className="row text-right" ng-show="productAccessList.length > 0">
          <Link to="#" onClick={this.downloadExcel} id="downloadExcel" className="text-link-with-icon-left">
            <span className="download-icon" />
            Download in Microsoft Excel
          </Link>
        </div>
      </div>
    </div>
  );

  renderGrid = () => {
    const { rows } = this.props;
    return (
      <div className="row">
        <div className="travelport-table">
          <Grid
            rows={rows}
            columns={this.state.columns}
          >
            <Table />
            <EditingState
              addedRows={this.state.addedRows}
              rowChanges={this.state.rowChanges}
              editingRowIds={this.state.editingRowIds}
              deletedRowIds={this.state.deletingRows}
              onEditingRowIdsChange={this.changeEditingRowIds}
              onDeletedRowIdsChange={this.deletedRowIdsChange}
              onAddedRowsChange={this.changeAddedRows}
              onRowChangesChange={this.changeRowChanges}
              onCommitChanges={this.commitChanges}
            />

            <PagingState
              currentPage={this.state.filters.currentPage}
              onCurrentPageChange={this.pageHandler}
              pageSize={this.state.pageSize}
              onPageSizeChange={this.pageSizeHandler}
            />
            <CustomPaging totalCount={this.props.totalPages * this.state.pageSize} />

            <SortingState
              sorting={this.sorting}
              onSortingChange={this.sortHandler}
            />

            <TableHeaderRow showSortingControls />

            <TableEditRow
              cellComponent={this.EditCell}
            />
            <TableEditColumn
              width={100}
              showAddCommand={this.state.addedRows.length === 0}
              showEditCommand
              showDeleteCommand
              commandComponent={this.command}
            />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => [
                ...tableColumns.filter(c => c.type !== 'editCommand'),
                { key: 'editCommand', type: 'editCommand', width: 140 }]}
            />
            <Toolbar />
            <PagingPanel pageSizes={[]} />
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.productName === undefined) {
      return <div className="container"><LoaderComponent show /></div>;
    }
    const title = `${this.props.productName}: Access`;
    const productGuid = utils.getValueFromQueryString(this.props.location, 'id');
    return (
      <>
        <h2>{title}</h2>
        <div className="container audience-container">
          <div className="row">
            <Link to="/admin/distribution">&lt; Back to Product Version Control</Link>
          </div>
          <div className="row">
            <Link to={`${BASIC_INFORMATION_ROUTE}?publicId=${productGuid}`}>
              &lt;
              {this.props.productName}
              {' '}
              Product Page
            </Link>
          </div>
          {this.renderOptions()}
          {!this.props.isGlobal && this.renderSearchAndDownloadSection()}
          {!this.props.isGlobal && this.renderGrid()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rows: productAccessListSelector(state),
  isGlobal: productAccessSelector(state).isGlobal,
  isVisibleToTPEmployees: productAccessSelector(state).isVisibleToTPEmployees,
  isVisibleToNonParticipants: productAccessSelector(state).isVisibleToNonParticipants,
  totalPages: totalPagesSelector(state),
  productName: productNameSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  initProductAccessPageAction: (args) => dispatch(initProductAccessPage(args)),
  changeGlobalAssignmentAction: (appPublicId) => dispatch(changeGlobalAssignment.base(appPublicId)),
  changeVisibilityNonParticipantsAction: (appPublicId) => dispatch(changeVisibilityNonParticipants.base(appPublicId)),
  changeVisibilityTPEmployeesAction: (appPublicId) => dispatch(changeVisibilityTPEmployees.base(appPublicId)),
  onSave: (args) => dispatch(saveProductAccess.base(args)),
  onDelete: (args) => dispatch(deleteProductAccess.base(args)),
  downloadExcel: (args) => dispatch(downloadExcel.base(args)),
  onUnmount: () => dispatch(clear())
});

ProductAccess.defaultProps = {
  rows: [],
  isGlobal: true,
  isVisibleToNonParticipants: false,
  isVisibleToTPEmployees: false
};

ProductAccess.propTypes = {
  location: PropTypes.object,
  initProductAccessPageAction: PropTypes.func,
  onSave: PropTypes.func,
  getUserNamesAction: PropTypes.func,
  getOrganizationNamesAction: PropTypes.func,
  getPccOrgAction: PropTypes.func,
  rows: PropTypes.array,
  isGlobal: PropTypes.bool,
  isVisibleToTPEmployees: PropTypes.bool,
  isVisibleToNonParticipants: PropTypes.bool,
  onDelete: PropTypes.func,
  changeGlobalAssignmentAction: PropTypes.func,
  changeVisibilityNonParticipantsAction: PropTypes.func,
  changeVisibilityTPEmployeesAction: PropTypes.func,
  totalPages: PropTypes.number,
  productName: PropTypes.string,
  downloadExcel: PropTypes.func,
  onUnmount: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAccess);
