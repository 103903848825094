/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-quotes */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar, ArrowDownIcon, DropdownMenu, ProfileMenu, Typography
} from '@atlas-design-system/react';
import { logOut } from 'reducers/modules/OAuth/oAuth';
import { roleNameConstants } from 'utils/rolesHelper';
import { changeUserRole } from 'reducers/modules/Profile/userProfileReducer';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'config/apiConfig';
import AuthLinks from '../../Auth/AuthLinks';
import '@atlas-design-system/core/dist/styles/partials/profile-menu.css';
import '@atlas-design-system/core/dist/styles/partials/avatar.css';
import '@atlas-design-system/core/dist/styles/partials/typography.css';

const LogoHeader = (props) => {
  const [showMenu, hideMenu] = useState(false);
  const userLoggedIn = props.user && props.user.profile;
  const ProfileImageUrl = userLoggedIn && props.user.profile.ProfileImageUrl;
  let nameImage;
  if (userLoggedIn) {
    nameImage = !ProfileImageUrl ? `${userLoggedIn.FirstName.substring(0, 1)}${userLoggedIn.LastName.substring(0, 1)}` : ProfileImageUrl;
  }
  const userIsLoggedIn = props.user && !props.user.expired;
  const login = (event) => {
    event.preventDefault();
    const pathLocation = `${window.location.pathname}${window.location.search}`;
    if (pathLocation !== '/error/500' || pathLocation !== '/blockedUser') {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
    }
    window.location = `${BASE_URL}login`;
  };

  return (
    <div className="logoHeader">
      <Link to="/" className="logoImg" />
      {showMenu ? (
        <div
          onClick={() => hideMenu(false)}
          style={{
            position: 'fixed', width: '100%', top: '0px', left: '0px', bottom: '0px', right: '0px', height: '100%', zIndex: 1
          }}
        />
      ) : null}
      <AuthLinks userIsLoggedIn={userIsLoggedIn} loginHandler={login} isSmallView />
      {userLoggedIn ? (
        <div style={{
          zIndex: 99, padding: '5px',
        }}
        >
          <>
            <div className="profileMenu">
              <button type="button" className="menu-button" aria-expanded="true" aria-current="false" onClick={() => hideMenu(!showMenu)}>
                {ProfileImageUrl ? <img src={ProfileImageUrl} alt="" /> : <span>{nameImage}</span>}
                <i className="drop-down-arrow" />
              </button>
              <div className="profilePanel" style={{ display: (showMenu ? 'block' : 'none') }}>
                <ProfileMenu.Header>
                  <Avatar name={userLoggedIn ? `${props.user.profile.FirstName} ${props.user.profile.LastName}` : ''} size='xlarge' />
                  <ProfileMenu.Description>
                    <Typography variant='contentText1' bold>
                      {userLoggedIn ? `${props.user.profile.FirstName} ${props.user.profile.LastName}` : ''}
                    </Typography>
                    <Typography variant='contentText2'>
                      {userLoggedIn ? `${props.user.profile.email}` : ''}
                    </Typography>
                  </ProfileMenu.Description>
                </ProfileMenu.Header>
                <ProfileMenu.Divider />
                <ul className="profile-links">
                  <li className="link-item">
                    <div className='sb-profile-menu-option-with-icon'>
                      <span variant='contentText2' bold style={{ 'font-size': '14px', 'font-weight': 'bold', 'margin-left': '5px' }}>
                        Role:
                      </span>
                      <DropdownMenu
                        title={roleNameConstants[props.user.profile.role].text}
                        key={0}
                        id="split-button-basic-0"
                        style={
                          {
                            margin: '3px 10px',
                            border: '1px silver solid'
                          }
                        }
                        icon={(
                          <ArrowDownIcon
                            width="10"
                            style={
                              {
                                paddingTop: '3px',
                                marginLeft: '3px',
                                float: 'right'
                              }
                            }
                          />
                        )}
                        data-icon-align='left'
                        className="btnatls-drpdown"
                        trigger={roleNameConstants[props.user.profile.role].text}
                      >
                        {
                          props.user.profile.roleIds
                          && props.user.profile.roleIds.filter((role) => role.text !== props.user.profile.CurrentRoleName)
                            .map((val) =>
                              <DropdownMenu.Item style={{ textAlign: 'start', zIndex: 999, position: 'absolute !important' }}>
                                <div
                                  className="role-item"
                                  style={{ overflow: 'visible', width: '344px', paddingLeft: '40px' }}
                                  onClick={() => props.changeUserRole(val.value)}
                                  key={parseInt(val.value, 0)}
                                  eventKey={parseInt(val.value, 0)}
                                >
                                  {val.text}
                                </div>
                              </DropdownMenu.Item>)
                        }
                      </DropdownMenu>
                    </div>
                  </li>
                </ul>
                <ProfileMenu.Divider />
                <ProfileMenu.Footer>
                  <ProfileMenu.SignOut onClick={props.logOut}>
                    Sign out
                  </ProfileMenu.SignOut>
                </ProfileMenu.Footer>
              </div>
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

LogoHeader.propTypes = {
  user: PropTypes.object,
  logOut: PropTypes.func,
  changeUserRole: PropTypes.func,
  isSmallView: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user
  };
}

const mapDispatchToProps = (dispatch) => ({
  logOut: (args) => dispatch(logOut.base(args)),
  changeUserRole: roleId => dispatch(changeUserRole.base(roleId))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoHeader);
