import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import '@progress/kendo-theme-default/dist/all.css';
import {
  fetchBannerInfo, saveBannerInfo, deletBannerInfo, orderBannerInfo
} from '../../../reducers/modules/Administration/Banner/Banner';
import { bannerSelector } from '../../../reducers/modules/Administration/Banner/selector';
import DataCells from './TableCellComponents/DataCells';
import './Banner.less';
import BannerForm from './BannerForm';

class Banner extends PureComponent {
  constructor(props) {
    super(props);
    this.sortingStateColumnExtensions = [
      { columnName: 'text', sortingEnabled: false },
      { columnName: 'action', sortingEnabled: false }
    ];
    this.state = {
      isForm: false,
      sort: [{ field: 'order', dir: 'desc' }],
      params: {},
      utcTime: moment.utc().format('MM-DD-YYYY hh:mm:ss A')
    };
  }

  componentDidMount() {
    document.title = 'Banner Page';
    this.props.fetchBannerInfo();
  }

  addNew = () => {
    this.setState({ isForm: true, params: {} });
  }

  edit = (rowValue) => {
    this.setState({ isForm: true, params: rowValue });
  }

  delete = (rowValue) => {
    this.props.deletBannerInfo(rowValue, this.callback);
  }

  changeOrder = (rowValue, direction) => {
    const json = { ...rowValue };
    if (direction === 'up') {
      if (rowValue.order === 1) {
        return;
      }
      json.orderDirection = 'up';
    } else if (direction === 'down') {
      const lastRow = this.props.rows[this.props.rows.length - 1];
      if (rowValue.id === lastRow.id) {
        return;
      }
      json.orderDirection = 'down';
    }
    this.props.orderBannerInfo(json, this.callback);
  }

  callback = () => {
    this.setState({ isForm: false });
    this.props.fetchBannerInfo();
  }

  cancel = () => {
    this.setState({ isForm: false });
  }

  CommandCell = (props) => (
    <td>
      <div className="cmd-cell">
        <button
          type="button"
          className="k-button k-primary"
          onClick={() => this.edit(props.dataItem)}
        >
          Edit
        </button>
        <button
          type="button"
          className="k-button btn-danger"
          onClick={() => this.delete(props.dataItem)}
        >
          Delete
        </button>
        <button
          type="button"
          className="k-primary k-button"
          onClick={() => this.changeOrder(props.dataItem, 'up')}
        >
          <span className="glyphicon glyphicon-arrow-up" />
        </button>
        <button
          type="button"
          className="k-primary k-button"
          onClick={() => this.changeOrder(props.dataItem, 'down')}
        >
          <span className="glyphicon glyphicon-arrow-down" />
        </button>
      </div>
    </td>
  );

  formData = (data) => {
    this.props.saveBannerInfo(data, this.callback);
  }

  enableBanner = (bannerInfo, enabled) => {
    const data = { ...bannerInfo, isActive: enabled };
    this.props.saveBannerInfo(data, this.callback);
  }

  handleSortChange = (e) => {
    this.setState({
      sort: e.sort
    });
  };

  rowRender(trElement, props) {
    const currentUTCTime = moment.utc();

    const unscheduled = !props.dataItem.isActive;
    const expired = currentUTCTime.diff(moment.utc(props.dataItem.toDate), 'seconds');
    const active = currentUTCTime.isAfter(moment.utc(props.dataItem.fromDate), 'second') && currentUTCTime.isBefore(moment.utc(props.dataItem.toDate), 'second');
    const upcoming = currentUTCTime.isBefore(moment.utc(props.dataItem.fromDate), 'second');

    const green = { backgroundColor: '#dff0d8', color: 'green' };
    const blue = { backgroundColor: '#d9edf7', color: '#31708f' };
    const red = { backgroundColor: '#f2dede', color: '#a94442' };
    const grey = { backgroundColor: '#d6d8d9', color: '#1b1e21' };

    let trProps = {};

    if (unscheduled) {
      trProps = { style: grey };
      return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    if (expired > 0) {
      trProps = { style: red };
    } else if (active) {
      trProps = { style: green };
    } else if (upcoming) {
      trProps = { style: blue };
    }
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="no-margin">
            <div className="row">
              <Link to="/admin/home">&lt; Back to Administration</Link>
            </div>
            <div className="row no-space">
              <h2>Banner</h2>
            </div>
          </div>
          {this.state.isForm
            ? <BannerForm formData={this.formData} edit={this.state.isForm} params={this.state.params} cancel={this.cancel} />
            : (
              <div className="kendo-table">
                <Grid
                  style={{ maxHeight: '700px', minHeight: '450px' }}
                  data={orderBy(this.props.rows, this.state.sort)}
                  rowRender={this.rowRender}
                  sortable
                  onSortChange={this.handleSortChange}
                  sort={this.state.sort}
                >
                  <GridToolbar>
                    <div className="flex-toolbar">
                      <button type="button" title="New Banner" className="k-button k-primary add-new" onClick={this.addNew}>New Banner</button>
                      <div>
                        Current Utc Time:
                        <b>{moment.utc().format('MM-DD-YYYY hh:mm A')}</b>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
                          <div className="box-color upcoming" />
                          <b className="pull-left">Upcoming</b>
                        </div>
                        <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
                          <div className="box-color expired" />
                          <b className="pull-left">Expired</b>
                        </div>
                        <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
                          <div className="box-color active" />
                          <b className="pull-left">Active</b>
                        </div>
                        <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
                          <div className="box-color Unscheduled" />
                          <b className="pull-left">Unscheduled</b>
                        </div>
                      </div>
                    </div>
                  </GridToolbar>
                  <Column field="order" title="Order" width="100px" />
                  <Column field="headerInfo" title="Banner Title" cell={(props) => <DataCells {...props} />} />
                  <Column field="fromDate" title="Display From (UTC)" width="250px" cell={(props) => <DataCells {...props} />} />
                  <Column field="toDate" title="Display To (UTC)" width="250px" cell={(props) => <DataCells {...props} />} />
                  <Column
                    field="isActive"
                    title="Enabled"
                    width="100px"
                    cell={(props) => <DataCells {...props} onChange={this.enableBanner} />}
                  />
                  <Column width="300px" cell={this.CommandCell} />
                </Grid>
              </div>
            )}
        </div>

      </>
    );
  }
}

Banner.propTypes = {
  fetchBannerInfo: PropTypes.func,
  saveBannerInfo: PropTypes.func,
  deletBannerInfo: PropTypes.func,
  orderBannerInfo: PropTypes.func,
  rows: PropTypes.array
};

const mapStateToProps = (state) => ({
  rows: bannerSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchBannerInfo: args => dispatch(fetchBannerInfo.base(args)),
  saveBannerInfo: (args, callback) => dispatch(saveBannerInfo.base(args, callback)),
  deletBannerInfo: (args, callback) => dispatch(deletBannerInfo.base(args, callback)),
  orderBannerInfo: (args, callback) => dispatch(orderBannerInfo.base(args, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Banner);
