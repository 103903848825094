import { takeLatest, call, put } from 'redux-saga/effects';
import * as actionTypes from '../../../actions/actionType';
import createFetchTypes from '../../utils/createFetchTypes';
import ApiClient from '../../../Services/ApiClient';
import fetchEntity from '../../utils/fetchEntitySaga';
import { showAlertAction } from '../../popUps/alertPopUpReducer';
import * as SeoHelper from '../../../utils/SeoHelper';
import { showLoader, hideLoader } from '../../popUps/loaderReducer';

const initialProductState = {
  loading: true,
  data: {
    applicationDetails: null,
    canAccessRatingLinks: null,
    popularApplicationsForPlatform: []
  },
  reviewsState: 'view',
  error: null
};

const SET_REVIEW_STATE = 'Product/SetReviewState';
export const SAVE_DEMO_REQUEST_MODAL = createFetchTypes('Marketplace/Product/Product/ProductReducer/DEMO_REQUEST_MODAL');
const GET_APPS_FOR_PLATFORM = createFetchTypes('Marketplace/Product/Product/ProductReducer/GET_APPS_FOR_PLATFORM');
const REDIRECT_PRODUCTS = createFetchTypes('Marketplace/Product/Product/ProductReducer/REDIRECT_PRODUCTS');
const DIRECT_DOWNLOAD_PRODUCT = 'Marketplace/Product/Product/ProductReducer/DIRECT_DOWNLOAD_PRODUCT';

export const popularApplicationsForPlatformSelector = (state) => state.product.details.data.popularApplicationsForPlatform;

const productReducer = (state = initialProductState, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_PRODUCT:
      return {
        ...state,
        loading: true,
        data: {
          applicationDetails: null,
          canAccessRatingLinks: null,
          popularApplicationsForPlatform: []
        },
        error: null
      };
    case actionTypes.LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null
      };
    case actionTypes.LOAD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        data: {
          applicationDetails: null,
          canAccessRatingLinks: null,
          popularApplicationsForPlatform: [],
        },
        error: action.error
      };
    case GET_APPS_FOR_PLATFORM.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          popularApplicationsForPlatform: action.data
        }
      };
    case REDIRECT_PRODUCTS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          RedirectToProduct: action.data
        }
      };
    case SET_REVIEW_STATE:
      return {
        ...state,
        reviewsState: action.value
      };
    case SAVE_DEMO_REQUEST_MODAL.REQUEST:
      return {
        ...state,
        closePopup: false,
      };
    case SAVE_DEMO_REQUEST_MODAL.SUCCESS:
      return {
        ...state,
        closePopup: true,
        error: null
      };
    case SAVE_DEMO_REQUEST_MODAL.ERROR:
      return {
        ...state,
        error: action.error,
        closePopup: false
      };
    default:
      return state;
  }
};

export const setReviewState = (value) => ({
  type: SET_REVIEW_STATE,
  value
});

export const saveDemoRequest = {
  base: (params) => ({
    type: SAVE_DEMO_REQUEST_MODAL.BASE,
    url: 'application/savedemorequest',
    payload: {
      params
    }
  }),
  request: () => ({
    type: SAVE_DEMO_REQUEST_MODAL.REQUEST
  }),
  success: () => ({
    type: SAVE_DEMO_REQUEST_MODAL.SUCCESS
  }),
  failure: (error) => ({
    type: SAVE_DEMO_REQUEST_MODAL.FAILURE,
    payload: {
      error
    }
  })
};

export const getAppsForPlatform = {
  base: (platformId) => ({
    type: GET_APPS_FOR_PLATFORM.BASE,
    url: 'application/getAppsForPlatform',
    platformId
  }),
  request: () => ({ type: GET_APPS_FOR_PLATFORM.REQUEST }),
  success: (data) => ({ type: GET_APPS_FOR_PLATFORM.SUCCESS, data }),
  failure: (error) => ({ type: GET_APPS_FOR_PLATFORM.FAILURE, error })
};

export const redirectToProductsPage = {
  base: (appId) => ({
    type: REDIRECT_PRODUCTS.BASE,
    url: 'application/details',
    appId
  }),
  request: () => ({ type: REDIRECT_PRODUCTS.REQUEST }),
  success: (data) => ({ type: REDIRECT_PRODUCTS.SUCCESS, data }),
  failure: (error) => ({ type: REDIRECT_PRODUCTS.FAILURE, error })
};

export const directDownloadProduct = (id, version, isDifferentialDownload) => ({
  type: DIRECT_DOWNLOAD_PRODUCT,
  id,
  version,
  isDifferentialDownload
});

function* directDownloadProductSaga({ id, version, isDifferentialDownload }) {
  const url = isDifferentialDownload
    ? `downloads/getdifferentialdownloadpackageurl/${id}`
    : `downloads/getdownloadurl/${id}?version=${version || ''}`;
  yield put(showLoader());
  try {
    const res = yield call(ApiClient.httpClient.get, url);
    const link = document.createElement('a');
    link.href = res.data.downloadUrl || res.data;
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(res.data.downloadUrl || res.data, 100));
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
  yield put(hideLoader());
}

function* redirectToProductSaga({ appId, url }) {
  yield put(showLoader());
  try {
    const res = yield call(ApiClient.httpClient.get, `${url}/${appId}`);
    if (res.data) {
      const toRoute = SeoHelper.SEOFriendly(res.data);
      window.location.href = `/Product/${toRoute}`;
    } else {
      window.location.href = '/products';
    }
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
  yield put(hideLoader());
}

export function* watchrRedirectToProductSaga() {
  yield takeLatest(REDIRECT_PRODUCTS.BASE, redirectToProductSaga);
}

function* getAppsForPlatformSaga({ url, platformId }) {
  try {
    yield call(() => fetchEntity(getAppsForPlatform, ApiClient.httpClient.get, url, { params: { platformId } }));
  } catch (error) {
    put(showAlertAction('Some error occured while getting popular platform products.'));
  }
}

export function* watchDirectDownloadProductSaga() {
  yield takeLatest(DIRECT_DOWNLOAD_PRODUCT, directDownloadProductSaga);
}

export function* saveDemoRequestSaga(action) {
  try {
    yield put(showLoader());
    const { url } = action;
    yield call(() => fetchEntity(saveDemoRequest, ApiClient.httpClient.post, url, action.payload.params));
    yield put(hideLoader());
    yield put(showAlertAction('Demo Request has been submitted succesfully'));
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* watchSaveDemoRequestSaga() {
  yield takeLatest(SAVE_DEMO_REQUEST_MODAL.BASE, saveDemoRequestSaga);
}

export function* watchGetAppsForPlatformSaga() {
  yield takeLatest(GET_APPS_FOR_PLATFORM.BASE, getAppsForPlatformSaga);
}

export default productReducer;
