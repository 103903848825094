import React from 'react';
import PropTypes from 'prop-types';
import { InformationIcon } from '@atlas-design-system/react';

const FormHeader = ({
  header, infoText, importantText
}) => (
  <>
    <div className="row">
      <div className="col-sm-7">
        <h3>{header}</h3>
      </div>
      <div className="col-sm-5">
        <h3 className="text-right">
          <span className="small-text color-dark-gray">
            <InformationIcon width="18" className="atlas-tooltip-icon" />
            {' '}
            =
            <em>item details</em>
          </span>
        </h3>
      </div>
      {infoText && infoText.length > 0 ? <p><i>{infoText}</i></p> : ''}
      {importantText && importantText.length > 0 ? (
        <p>
          <b>Important! </b>
          {importantText}
        </p>
      ) : ''}

    </div>
  </>

);

FormHeader.propTypes = {
  header: PropTypes.string,
  infoText: PropTypes.string,
  importantText: PropTypes.string
};

export default FormHeader;
