import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import agencyUserIndexReducer, { agencyUserMetaReducer, fetchAgencyUserSetWatcher, FETCH_AGENCY_USER_SET } from './IndexReducer';
import agencyUserCreateReducer, { createAgencyUserWatcher, CREATE_AGENCY_USER } from './createReducer';
import updateReducer, { updateAgencyUserWatcher, UPDATE_AGENCY_USER } from './updateReducer';

const agencyUserReducer = combineReducers({
  index: agencyUserIndexReducer,
  create: agencyUserCreateReducer,
  update: updateReducer,
  meta: agencyUserMetaReducer,
});

export function* agencyUserMiddleware() {
  yield takeLatest(FETCH_AGENCY_USER_SET, fetchAgencyUserSetWatcher);
  yield takeLatest(CREATE_AGENCY_USER, createAgencyUserWatcher);
  yield takeLatest(UPDATE_AGENCY_USER, updateAgencyUserWatcher);
}

export default agencyUserReducer;
