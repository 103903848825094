import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@atlas-design-system/react';
import SelectionTabContent from './SelectionTabContent/SelectionTabContent';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

class SelectionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
  }

  getSelectionTabContent = (tab) => (
    <SelectionTabContent
      search={tab.search}
      tabName={tab.tabName}
      tabLabel={tab.tabLabel}
      tabSubLabel={tab.tabSubLabel}
      entities={tab.entities}
      onSelect={tab.onSelect}
      onDeselect={tab.onDeselect}
      toggleAllSelect={tab.toggleAllSelect}
      seatSiteLicenseInfo={tab.seatSiteLicenseInfo}
      isUnassignmentTab={tab.isUnassignmentTab}
    />
  );

  handleTabSelect = (activeTab) => {
    this.setState({ activeTab });
  }

  render() {
    return (
      <div className="container space-bottom">
        <div>
          {/* Info Message */}
          <div className="row col-md-6 text-license-assignment-info">
            {this.props.infoMessage}
          </div>
          {/* Left side Assignment box */}
          <div id="assign-tab-container" className="col-md-6 pad-right">
            <Tabs defaultActiveKey="0" id="add-assign-tab" onSelect={this.handleTabSelect}>
              {this.props.assignmentTabs && this.props.assignmentTabs.map((tab, i) => (
                <Tabs.Tab key={i.toString()} eventKey={i} title={tab.tabName}>
                  {this.getSelectionTabContent(tab)}
                </Tabs.Tab>
              ))}
            </Tabs>
            <div className="no-space row text-center head-bottom-panel">
              <button type="button" className="action-button" onClick={this.props.addAssignments} id="add-selected-enterprise-button">ADD SELECTED</button>
            </div>
          </div>
          {/* Right Side Unassignment Box */}
          <div id="assigned-list-container" className="col-md-6 pad-left">
            <Tabs defaultActiveKey="0" id="add-assign-tab" onSelect={this.handleTabSelect}>
              {this.props.unassignmentTabs && this.props.unassignmentTabs.map((tab, i) => (
                <Tabs.Tab key={i.toString()} eventKey={i} title={tab.tabName}>
                  {this.getSelectionTabContent(tab)}
                </Tabs.Tab>
              ))}
            </Tabs>
            <div className="no-space row text-center head-bottom-panel">
              <button type="button" className="action-button" onClick={this.props.removeAssignments} id="remove-selected-enterprise-button">REMOVE SELECTED</button>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

SelectionBox.propTypes = {
  infoMessage: PropTypes.string,
  assignmentTabs: PropTypes.array,
  addAssignments: PropTypes.func,
  unassignmentTabs: PropTypes.array,
  removeAssignments: PropTypes.func,
};

export default SelectionBox;
