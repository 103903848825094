/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../ToolTip';

const onClick = (e, props) => {
  e.preventDefault();
  if (props.enabled) props.onChange(!props.value);
};

const SimplePrettyCheckBox = (props) => (
  <div className={`clearfix prettycheckbox labelright  gray ${props.enabled ? '' : 'disabled'}`} style={props.style}>
    <span className={(props.value ? 'prettySpan checked' : 'prettySpan')} onClick={(e) => onClick(e, props)} />
    {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
    <label htmlFor={props.name} onClick={(e) => onClick(e, props)} className={(props.isAlign ? 'account-chk' : '')}>
      {props.displayText}
&nbsp;
      {props.tooltip && <Tooltip labelName={props.tooltip} />}
    </label>
  </div>
);

SimplePrettyCheckBox.defaultProps = {
  enabled: true,
  onChange: () => {},
  isAlign: false
};

SimplePrettyCheckBox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  enabled: PropTypes.bool,
  name: PropTypes.string,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
  style: PropTypes.any,
  isAlign: PropTypes.bool
};

export default SimplePrettyCheckBox;
