import React from 'react';
import PropTypes from 'prop-types';

const Counter = (props) => {
  const characters = props.inputValue ? props.inputValue.split('').length : 0;
  const total = parseInt(props.goal, 0) - parseInt(characters, 0);
  return (
    <span className="max-chars">
      <span>
        {total}
        {' '}
      </span>
      {props.message}
    </span>
  );
};

export default Counter;

Counter.propTypes = {
  goal: PropTypes.number,
  message: PropTypes.string,
  inputValue: PropTypes.string
};

Counter.defaultProps = {
  inputValue: '',
  message: ''
};
