import * as actionTypes from '../../../actions/actionType';

const saveReviewReducer = (state = { }, action = {}) => {
  switch (action.type) {
    case actionTypes.SAVE_PRODUCT_REVIEW:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.SAVE_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case actionTypes.SAVE_PRODUCT_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default saveReviewReducer;
