import React from 'react';
import ToolTip from '../../common/ToolTip';

const VideoRequiredMessage = () => (
  <>
    <div className="row">
      <h4>
        YouTube Video(s)
        <ToolTip labelName="To include a video, enter the YouTube video URL or YouTube video ID and click Add." />
      </h4>
    </div>
    <p>
      <em>You may provide upto three videos. Ensure the link for each video is copied from the share button on YouTube (Optional)</em>
    </p>
  </>
);

export default VideoRequiredMessage;
