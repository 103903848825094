import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_AGENCY_PROFILE } from 'reducers/modules/Profile/Agency/AgencyProfile/AgencyProfile';
import { CLEAN_USER_PROFILE } from 'reducers/modules/Profile/getfulluserprofileReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import * as Config from 'config/apiConfig';
import ApiClient from '../../../Services/ApiClient';
import createFetchTypes from '../../utils/createFetchTypes';
import fetchEntity from '../../utils/fetchEntitySaga';
import * as actionTypes from '../../../actions/actionType';
import { showAlertAction } from '../../popUps/alertPopUpReducer';
import { hideLoader, showLoader } from '../../popUps/loaderReducer';

export const SAVE_AGENCY_DETAILS_LOGO = createFetchTypes(
  'Marketplace/Agency/SAVE_AGENCY_DETAILS_LOGO'
);
export const SAVE_AGENCY_DETAILS = createFetchTypes(
  'Marketplace/Agency/SAVE_AGENCY_DETAILS'
);
export const CAN_AGENCY_DOWNLOAD = createFetchTypes(
  'Marketplace/Agency/CAN_AGENCY_DOWNLOAD'
);

const CHANGE_USER_ROLE = createFetchTypes('profile/userProfile/userProfile');

export const userProfileReducer = (
  state = { userDetails: { firstName: '' } },
  action = {}
) => {
  switch (action.type) {
    case CLEAN_USER_PROFILE:
      return {
        userDetails: {}
      };
    case actionTypes.LOAD_USER_PROFILE:
      return action.data;
    case GET_AGENCY_PROFILE.SUCCESS:
      return {
        ...state,
        agencyDetails: action.payload.data
      };
    case SAVE_AGENCY_DETAILS_LOGO.REQUEST:
      return {
        ...state,
        hideAgencyLogoPopup: false,
        editMode: true
      };
    case SAVE_AGENCY_DETAILS_LOGO.FAILURE:
      return {
        ...state,
        hideAgencyLogoPopup: true,
        editMode: true
      };
    case SAVE_AGENCY_DETAILS_LOGO.SUCCESS:
      return {
        ...state,
        agencyDetails: {
          ...state.agencyDetails,
          logoUrl: action.payload.data
        },
        hideAgencyLogoPopup: true,
        editMode: true
      };
    case CAN_AGENCY_DOWNLOAD.SUCCESS:
      return {
        ...state,
        agencyDetails: {
          ...state.agencyDetails,
          canAgentDownload: action.payload.data
        },
      };
    case SAVE_AGENCY_DETAILS.REQUEST:
    case SAVE_AGENCY_DETAILS.FAILURE:
      return {
        ...state,
        editMode: false
      };
    case SAVE_AGENCY_DETAILS.SUCCESS:
      return {
        ...state,
        editMode: true,
        message: action.payload.data
      };
    case CHANGE_USER_ROLE.REQUEST:
    case CAN_AGENCY_DOWNLOAD.REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHANGE_USER_ROLE.SUCCESS:
    case CHANGE_USER_ROLE.FAILURE:
    case CAN_AGENCY_DOWNLOAD.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const saveAgencyLogo = {
  base: params => ({
    type: SAVE_AGENCY_DETAILS_LOGO.BASE,
    params
  }),
  request: () => ({
    type: SAVE_AGENCY_DETAILS_LOGO.REQUEST
  }),
  success: data => ({
    type: SAVE_AGENCY_DETAILS_LOGO.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: SAVE_AGENCY_DETAILS_LOGO.FAILURE,
    payload: {
      error
    }
  })
};

export const canAgencyDownload = {
  base: params => ({
    type: CAN_AGENCY_DOWNLOAD.BASE,
    params
  }),
  request: () => ({
    type: CAN_AGENCY_DOWNLOAD.REQUEST
  }),
  success: data => ({
    type: CAN_AGENCY_DOWNLOAD.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: CAN_AGENCY_DOWNLOAD.FAILURE,
    payload: {
      error
    }
  })
};

export const saveAgencyDetails = {
  base: params => ({
    type: SAVE_AGENCY_DETAILS.BASE,
    params
  }),
  request: () => ({
    type: SAVE_AGENCY_DETAILS.REQUEST
  }),
  success: data => ({
    type: SAVE_AGENCY_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: SAVE_AGENCY_DETAILS.FAILURE,
    payload: {
      error
    }
  })
};

export const changeUserRole = {
  base: roleId => ({
    type: CHANGE_USER_ROLE.BASE,
    url: 'account/changeuserrole/',
    roleId
  }),
  request: () => ({ ype: CHANGE_USER_ROLE.REQUEST }),
  success: () => ({ type: CHANGE_USER_ROLE.SUCCESS }),
  failure: error => ({ type: CHANGE_USER_ROLE.FAILURE, error })
};

function* saveAgencyDetailsSaga(params) {
  try {
    yield put(showLoader());

    const param = params.params;
    yield put(saveAgencyDetails.request());
    const agencyResponse = yield ApiClient.httpClient.post(
      'Profile/SaveAgencyProfileDetails',
      param
    );

    if (param.userProfile) {
      yield put({
        type: GET_AGENCY_PROFILE.SUCCESS,
        payload: { data: agencyResponse.data.updatedAgencyProfileDetails }
      });
      yield put(hideLoader());
      return yield put(showAlertAction(agencyResponse.data.responseMessage));
    }

    const userData = yield ApiClient.httpClient.get(
      `profile/getfulluserprofile?username=${param.username}`
    );

    yield put({ type: actionTypes.LOAD_USER_PROFILE, data: userData.data });
    return yield put(hideLoader());
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(hideLoader());
    return put(showAlertAction(error));
  }
}

function* saveAgencyLogoSaga(params) {
  try {
    yield put(showLoader());
    const param = params.params;
    const formData = new FormData();
    formData.append('id', param.id);
    formData.append('orgLogo', param.image);
    yield put(saveAgencyLogo.request());
    const imageUrl = yield ApiClient.httpClient.post(
      'organisation/savelogo',
      formData
    );
    yield put(saveAgencyLogo.success(imageUrl.data));
    yield put(hideLoader());
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

function* changeUserRoleSaga({ url, roleId }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(changeUserRole, ApiClient.httpClient.post, url, roleId));
    localStorage.updatingClaims = true;
    sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
    window.location = `${Config.BASE_URL}login`;
  } catch (error) {
    yield put(
      showAlertAction(
        'Some error occured during role change. Please try again.'
      )
    );
  }
  yield put(hideLoader());
}
function* canAgencyDownloadSaga(params) {
  const param = params.params;
  // yield put(showLoader());
  try {
    yield put(canAgencyDownload.request());
    const imageUrl = yield ApiClient.httpClient.put(
      `profile/updateagentdwnsettings?id=${param.id}&candownload=${param.candownload}`
    );
    if (imageUrl.data) {
      yield put(canAgencyDownload.success(param.candownload));
    }
  } catch (error) {
    yield put(
      showAlertAction(
        'Some error occured during role change. Please try again.'
      )
    );
  }
  // yield put(hideLoader());
}
export function* watchCanAgencyDownloadSaga() {
  yield takeLatest(CAN_AGENCY_DOWNLOAD.BASE, canAgencyDownloadSaga);
}
export function* watchSaveAgencyDetailsSaga() {
  yield takeLatest(SAVE_AGENCY_DETAILS.BASE, saveAgencyDetailsSaga);
}

export function* watchSaveAgencyLogoSaga() {
  yield takeLatest(SAVE_AGENCY_DETAILS_LOGO.BASE, saveAgencyLogoSaga);
}

export function* watchChangeUserRoleSaga() {
  yield takeLatest(CHANGE_USER_ROLE.BASE, changeUserRoleSaga);
}
export default userProfileReducer;
