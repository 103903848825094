import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import UserNameCell from './UserNameCell';

export default class DataCells extends React.Component {
  render() {
    switch (this.props.column.name) {
      case 'email': {
        return <UserNameCell {...this.props} />;
      }
      case 'roles': {
        return <td>{this.props.row.roles.map(role => role.text).join(', ')}</td>;
      }
      case 'name': {
        return <td>{this.props.row.name}</td>;
      }
      default:
        return <Table.Cell {...this.props} />;
    }
  }
}
DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
};
