import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';
import CheckBoxInput from '../common/CheckBoxInput';
import { loadFilterDetails, loadBrowseProduct } from '../../actions';
import * as CommonServices from '../../Services/Common.Service';
import LeftPanelFilter from '../common/LeftPanelFilter';
import TileLayout from '../common/TileLayout';
import PageNation from '../common/PaginationComponent';
import HeaderContent from '../common/HeaderContentTileArea';
import ResultNotFound from '../common/ResultNotFound';
import LoaderComponent from '../common/Loader/LoaderComponent';
import { getFilterItem } from '../Developers/Developers';

const queryString = require('query-string');

export const handleSortByValues = (sortByValue) => {
  const sortByValueItems = [
    {
      value: 'New',
      Name: 'New'
    },
    {
      value: 'Name',
      Name: 'Name A to Z'
    }
  ];

  sortByValueItems.map(item => {
    const tmpItem = item;
    if (tmpItem.value === sortByValue) {
      tmpItem.selected = true;
    } else {
      tmpItem.selected = false;
    }

    return tmpItem;
  });
};

export const handleCategoryList = (catergoriesList, checkedArrayCatergories) => {
  catergoriesList.map(item => {
    const tmpItem = item;
    if (checkedArrayCatergories.includes(item.id)) {
      tmpItem.checked = true;
    } else {
      tmpItem.checked = false;
    }
    return tmpItem;
  });
};
class Products extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      searchOpen: true,
      categoriesOpen: true,
      categoriesMore: true,
      regionsOpen: true,
      pricingOpen: true,
      platformOpen: true,
      hostopen: true,
      activePage: 1,
      previousStatus: true,
      nextStatus: false,
      checkedArrayCatergories: [],
      checkedArrayRegions: [],
      checkedArrayPricing: [],
      checkedArrayPlatform: [],
      checkedArrayHost: [],
      sortByValue: 'New',
      searchText: '',
      searchFilter: '',
      showLoader: false,
      SearchNotFoundError: false
    };
    this.sortByValueItems = [
      {
        value: 'New',
        Name: 'New'
      },
      {
        value: 'Name',
        Name: 'Name A to Z'
      }
    ];
    this.search = this.getTextFromQueryString(props);
    if (typeof this.props.location.cleanFilter === 'undefined') {
      localStorage.removeItem('dataFilterProducts');
    }
  }

  componentDidMount = () => {
    CommonServices.SSOProcessing(this.props.user);
    const response = CommonServices.fetchFilterDetails();
    response
      .then(res => {
        this.props.loadFilterDetails(res);
      })
      .catch(() => {
        this.props.loadFilterDetails([]);
      });
    const search = this.getTextFromQueryString(this.props);
    if (search.key) {
      this.state.searchText = search.value;
      this.state.searchFilter = search.key;
      this.handleChangeSearchText();
    } else {
      this.restoreFilterData(this.storeGetDataFilter());
    }
    window.scrollTo(0, 0);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getTextFromQueryString(nextProps).value !== this.search.value || this.getTextFromQueryString(nextProps).key !== this.search.key) {
      this.search = this.getTextFromQueryString(nextProps);
      this.state.searchText = this.search.value;
      this.state.searchFilter = this.search.key;
      this.handleChangeSearchText();
    }
  }

  getTileLayout(products) {
    return products.map((v, i) => (
      <div className="col-sm-6 product-height" key={i.toString()}>
        <TileLayout {...v} linkFor="Product" />
      </div>
    ));
  }

  getTextFromQueryString(props) {
    return queryString.parse(props.location.search);
  }

  storeSetDataFilter = data => localStorage.setItem('dataFilterProducts', JSON.stringify(data));

  storeGetDataFilter = () => JSON.parse(localStorage.getItem('dataFilterProducts')) || {};

  restoreFilterData = data => {
    const dataItemLabel = {
      searchText: 'searchText',
      searchFilter: 'searchFilter',
      currentPage: 'activePage',
      sortBy: 'sortByValue',
      categoryIds: 'checkedArrayCatergories',
      gdsIds: 'checkedArrayHost',
      platformIds: 'checkedArrayPlatform',
      regionIds: 'checkedArrayRegions',
      pricingTypes: 'checkedArrayPricing'
    };

    const tmpObj = {};
    const resultFilterOptions = Object.keys(data).map(item => {
      const itemKey = item;
      const itemValue = data[item];
      tmpObj[dataItemLabel[itemKey]] = itemValue;
      return tmpObj;
    });

    this.setState(resultFilterOptions[0]);
    this.serviceCallProductJson(resultFilterOptions[0]);
  };

  serviceCallProductJson = data => {
    let dataTmp = {};
    if (data) {
      dataTmp = {
        searchText: data.searchText,
        searchFilter: data.searchFilter,
        currentPage: data.activePage,
        sortBy: data.sortByValue,
        categoryIds: data.checkedArrayCatergories,
        gdsIds: data.checkedArrayHost,
        platformIds: data.checkedArrayPlatform,
        regionIds: data.checkedArrayRegions,
        pricingTypes: data.checkedArrayPricing
      };
    } else {
      dataTmp = {
        searchText: this.state.searchText,
        searchFilter: this.state.searchFilter,
        currentPage: this.state.activePage,
        sortBy: this.state.sortByValue,
        categoryIds: this.state.checkedArrayCatergories,
        gdsIds: this.state.checkedArrayHost,
        platformIds: this.state.checkedArrayPlatform,
        regionIds: this.state.checkedArrayRegions,
        pricingTypes: this.state.checkedArrayPricing
      };
    }

    this.fetchBrowseProductsAll(dataTmp);
  };

  handleChangeSortBy = value => {
    this.setState({ sortByValue: value });
    const data = {
      searchText: this.state.searchText,
      searchFilter: this.state.searchFilter,
      currentPage: this.state.activePage,
      sortBy: value,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      regionIds: this.state.checkedArrayRegions,
      pricingTypes: this.state.checkedArrayPricing
    };
    this.fetchBrowseProductsAll(data);
  };

  handleChangeCheckBoxCatergories = (value, checked) => {
    if (checked) {
      this.state.checkedArrayCatergories.push(value);
    } else {
      this.state.checkedArrayCatergories = this.state.checkedArrayCatergories.filter(
        val => val !== value
      );
    }
    this.serviceCallProductJson();
  };

  handleChangeCheckBoxRegion = (value, checked) => {
    if (checked) {
      this.state.checkedArrayRegions.push(value);
    } else {
      this.state.checkedArrayRegions = this.state.checkedArrayRegions.filter(
        val => val !== value
      );
    }

    this.serviceCallProductJson();
  };

  handleChangeCheckBoxPricing = (value, checked) => {
    if (checked) {
      this.state.checkedArrayPricing.push(value);
    } else {
      this.state.checkedArrayPricing = this.state.checkedArrayPricing.filter(
        val => val !== value
      );
    }

    this.serviceCallProductJson();
  };

  handleChangeCheckBoxPlatform = (value, checked) => {
    if (checked) {
      this.state.checkedArrayPlatform.push(value);
    } else {
      this.state.checkedArrayPlatform = this.state.checkedArrayPlatform.filter(
        val => val !== value
      );
    }

    this.serviceCallProductJson();
  };

  handleChangeCheckBoxHosts = (value, checked) => {
    if (checked) {
      this.state.checkedArrayHost.push(value);
    } else {
      this.state.checkedArrayHost = this.state.checkedArrayHost.filter(
        val => val !== value
      );
    }

    this.serviceCallProductJson();
  };

  handleChangePageNo = pageNo => {
    const data = {
      searchText: this.state.searchText,
      searchFilter: this.state.searchFilter,
      currentPage: pageNo,
      sortBy: this.state.sortByValue,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      regionIds: this.state.checkedArrayRegions,
      pricingTypes: this.state.checkedArrayPricing
    };
    this.fetchBrowseProductsAll(data);
    window.scrollTo(0, 0);
  };

  handleSearchText = event => {
    this.setState({ searchText: event.target.value });
  };

  handleChangeSearchText = e => {
    if (e) {
      e.preventDefault();
    }
    const data = {
      searchText: this.state.searchText,
      searchFilter: this.state.searchFilter,
      currentPage: this.state.pageNo,
      sortBy: this.state.sortByValue,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      regionIds: this.state.checkedArrayRegions,
      pricingTypes: this.state.checkedArrayPricing
    };
    this.fetchBrowseProductsAll(data);
  };

  handleRegionsList = (regionsList) => {
    regionsList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayRegions.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handlePricingList = (pricingList) => {
    pricingList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayPricing.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handlePlatformList = (platformList) => {
    platformList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayPlatform.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handleHostList = (hostList) => {
    hostList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayHost.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  fetchBrowseProductsAll(data) {
    this.storeSetDataFilter(data);

    this.setState({
      showLoader: true
    });
    const responseData = CommonServices.fetchBrowseProductsAll(data);
    responseData
      .then(res => {
        this.props.loadBrowseProduct(res);

        this.setState({
          showLoader: false,
          SearchNotFoundError: false
        });

        if (
          !this.props.products.length
          && !this.props.products.productTileViewModel.length
        ) {
          this.setState({
            SearchNotFoundError: true
          });
        }
      })
      .catch(() => {
        this.props.loadBrowseProduct([]);
        this.setState({
          showLoader: false,
          SearchNotFoundError: true
        });
      });
  }

  render() {
    this.catergoriesList = this.props.filter.categoryList || [];
    this.regionsList = this.props.filter.regionList || [];
    this.pricingList = this.props.filter.priceList || [];
    this.platformList = this.props.filter.platformList || [];
    this.hostList = this.props.filter.hostList || [];
    if (this.hostList.length > 0) {
      this.hostList = this.hostList.filter(k => k.name !== 'AXESS CREA');
    }
    this.products = this.props.products && this.props.products.productTileViewModel
      ? this.props.products.productTileViewModel
      : [];

    handleSortByValues(this.state.sortByValue);
    handleCategoryList(this.catergoriesList, this.state.checkedArrayCatergories);
    this.handleRegionsList(this.regionsList);
    this.handlePricingList(this.pricingList);
    this.handlePlatformList(this.platformList);
    this.handleHostList(this.hostList);

    return (
      <div className="container">
        <LoaderComponent show={this.state.showLoader} />
        <h2 className="page-title">Browse Products</h2>
        <div className="row browse-products-main">
          <div className="hidden-xs col-sm-3 browse-products-filter-col">
            <form
              id="filter-search-form"
              onSubmit={e => this.handleChangeSearchText(e)}
            >
              <LeftPanelFilter header="Platform">
                {this.platformList.map((v, i) => (
                  <li key={i.toString()}>
                    <CheckBoxInput
                      {...v}
                      handleChangeCheckBoxExport={
                        this.handleChangeCheckBoxPlatform
                      }
                    />
                  </li>
                ))}
              </LeftPanelFilter>
              {getFilterItem('Categories', this.catergoriesList, this.handleChangeCheckBoxCatergories)}
              {getFilterItem('Regions', this.regionsList, this.handleChangeCheckBoxRegion)}

              <LeftPanelFilter header="Pricing">
                {this.pricingList.map((v, i) => (
                  <li key={i.toString()}>
                    <CheckBoxInput
                      {...v}
                      handleChangeCheckBoxExport={
                        this.handleChangeCheckBoxPricing
                      }
                    />
                  </li>
                ))}
              </LeftPanelFilter>
              {getFilterItem('Hosts', this.hostList, this.handleChangeCheckBoxHosts)}
            </form>
          </div>
          <div
            id="frmdiv"
            className="col-xs-12 col-sm-9"
            style={{ display: this.products.length !== 0 ? 'block' : 'none' }}
          >
            <HeaderContent
              startPage={parseInt(this.props.products.startAppNumber, 0)}
              endPage={parseInt(this.props.products.endAppNumber, 0)}
              handleChangeSortBy={this.handleChangeSortBy}
              totalPage={parseInt(this.props.products.totalApplication, 0)}
              sortByItems={this.sortByValueItems}
            />
            <div className="row browse-products-tiles-row rowwrap">
              {this.getTileLayout(this.products)}
            </div>
          </div>
          <div
            id="frmdiv"
            className="col-xs-12 col-sm-9"
            style={{
              display: this.state.SearchNotFoundError ? 'block' : 'none'
            }}
          >
            <ResultNotFound />
          </div>
          <div
            className="browse-products-pagination col-sm-offset-3"
            id="paging-div-product"
            style={{
              display:
                this.products.length > 0
                  && parseInt(this.props.products.totalApplication, 0) > 16
                  ? 'block'
                  : 'none'
            }}
          >
            <ul id="pagination-container-Product" className="pagination-md" />
            <PageNation
              handleChangePageNo={this.handleChangePageNo}
              currentPage={this.props.products.currentPage}
              totalPages={this.props.products.totalPages}
              _pageSize={16}
              _pageIndex={0}
              totalRecords={parseInt(this.props.products.totalApplication, 0)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.filterDetailsReducer,
  products: state.browseProductReducer,
  user: state.oidc.user
});

const mapDispatchToProps = dispatch => ({
  loadFilterDetails: bindActionCreators(loadFilterDetails, dispatch),
  loadBrowseProduct: bindActionCreators(loadBrowseProduct, dispatch),
  loadUserInfo: () => dispatch(loadUserInfo.base())
});

Products.propTypes = {
  loadFilterDetails: PropTypes.any,
  loadBrowseProduct: PropTypes.func,
  loadUserInfo: PropTypes.func,
  filter: PropTypes.any,
  products: PropTypes.any,
  totalPages: PropTypes.number,
  location: PropTypes.shape({
    cleanFilter: PropTypes.bool
  }),
  user: PropTypes.object
};
Products.defaultProps = {
  filter: [],
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Products));
