import { createSelector } from 'reselect';

export const productAccessSelector = state => state.administration.productAccess;
export const productNameSelector = state => state.administration.productAccess.productName;
export const totalPagesSelector = state => state.administration.productAccess.productAccessList
  && state.administration.productAccess.productAccessList.totalPages;

const groupsSelector = state => state.administration.productAccess.groups;
const accessCategories = state => state.administration.productAccess.accessCategories;
const productAccessList = state => state.administration.productAccess.productAccessList
  && state.administration.productAccess.productAccessList.pagedCollection;
const userNames = state => state.administration.productAccess.userNames;
const orgNames = state => state.administration.productAccess.orgNames;
const pccOrg = state => state.administration.productAccess.pccOrg;
const pcc = state => state.administration.productAccess.pcc;

export const productAccessListSelector = createSelector(
  [productAccessList],
  (items) => items && items.map(item => ({
    id: item.id,
    accessCategoryValue: item.accessCategory.value,
    accessCategory: item.accessCategory.text,
    categoryValueOne: item.categoryValueOne,
    categoryValueTwo: item.categoryValueTwo,
  }))
);

export const getAccessCategorySelector = createSelector(
  [accessCategories],
  (items) => items && items.map(item => ({
    label: item.text,
    value: item.value
  }))
);

export const getGroupsSelector = createSelector(
  [groupsSelector],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getPccListSelector = createSelector(
  [pcc],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getPccOrgNamesSelector = createSelector(
  [pccOrg],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getUserNamesSelector = createSelector(
  [userNames],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getOrgNamesSelector = createSelector(
  [orgNames],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);
