import React from 'react';
import PropTypes from 'prop-types';
import { roleNameConstants } from 'utils/rolesHelper';
import { DropdownMenu, ArrowDownIcon } from '@atlas-design-system/react';
import HeaderLink from '../HeaderLink/HeaderLink';
import '@atlas-design-system/core/dist/styles/partials/dropdown-menu.css';

const GetHeaderLink = (props, text, path) => (
  <HeaderLink
    linkText={text}
    path={path}
    isPPCPFormModified={props.isPPCPFormModified}
    goToRoutes={props.goToRoutes}
    disableLinks={props.disableLinks}
    notifyDisableLinksAction={props.notifyDisableLinksAction}
  />
);

const restrictCalling = (e) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  return false;
};

const MPNavigationBar = (props) => (
  <div className="links-list-header">
    {GetHeaderLink(props, 'Home', '/')}
    {GetHeaderLink(props, 'Products', '/products')}
    {/* {GetHeaderLink(props, 'Developers', '/developers')} */}
    {GetHeaderLink(props, 'FAQs', '/faqs')}
    {GetHeaderLink(props, 'Contact Us', '/contactus')}
    {props.isUserAuthenticated
      && GetHeaderLink(props, 'My Account', '/Account/MyProfile')}
    {props.canUserViewAdministrationLink
      && GetHeaderLink(props, 'Administration', '/admin/home')}
    {
      props.isSmallView ? (
        <div
          exact
          activeClassName="link-header-active"
          onClick={(e) => { e.preventDefault(); }}
          onKeyDown={(e) => { e.preventDefault(); }}
          className="global-nav-item gtm-nav-item visible-xs visible-sm"
          to="#"
        >
          Roles
          {' '}
          {props.isUserAuthenticated && (
            <DropdownMenu
              title={roleNameConstants[props.user.profile.role].text}
              key={0}
              id="roles-id"
              onMouseDown={(e) => { restrictCalling(e); }}
              type="button"
              icon={(
                <ArrowDownIcon
                  width="10"
                  style={
                    {
                      float: 'right',
                      paddingTop: '6px',
                      marginLeft: '3px',
                      background: 'white',
                      display: 'none'
                    }
                  }
                />
              )}
              style={
                {
                  marginBottom: '5px',
                  marginLeft: '5px',
                  cursor: 'pointer',
                  background: 'white'
                }
              }
              trigger={roleNameConstants[props.user.profile.role].text}
            >
              {
                props.user.profile.roleIds
                && props.user.profile.roleIds.filter((role) => role.text !== props.user.profile.CurrentRoleName).map((val) => <DropdownMenu.Item style={{ textAlign: 'start' }}><div className="role-item" style={{ overflow: 'visible', width: '244px', paddingLeft: '40px' }} onClick={() => props.changeUserRole(val.value)} onKeyDown={() => props.changeUserRole(val.value)} key={parseInt(val.value, 0)} eventKey={parseInt(val.value, 0)}>{val.text}</div></DropdownMenu.Item>)
              }
            </DropdownMenu>
          )}
        </div>
      ) : null
    }
  </div>
);

GetHeaderLink.propTypes = {
  isPPCPFormModified: PropTypes.bool,
  goToRoutes: PropTypes.func,
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  user: PropTypes.object
};
MPNavigationBar.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  canUserViewAdministrationLink: PropTypes.bool,
  user: PropTypes.object,
  changeUserRole: PropTypes.func,
  isSmallView: PropTypes.bool
};

export default MPNavigationBar;
