/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Panel } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { roleNameConstants } from 'utils/rolesHelper';
import { changeUserRole } from 'reducers/modules/Profile/userProfileReducer';
import { canNotifyDisableLinks } from '../../actions';
import MPNavigationBar from './MPNavigationBar/MPNavigationBar';
import Auth from '../Auth/Auth';
import { goToRoute } from '../../reducers/modules/Application/index';
import AutoUpgradeAgencyAdmin from '../Auth/AutoUpgradeAgencyAdmin/AutoUpgradeAgencyAdmin';
import { autoUpgradeAction, declineUpgradeAction } from '../../reducers/modules/Auth/AutoUpgradeAgencyAdmin/AutoUpgradeAdminReducer';
import BannerNotification from '../common/BannerNotification/BannerNotification';
import LogoHeader from './LogoHeader/LogoHeader';
import GlobalSearch from '../common/GlobalSearch/GlobalSearch';

const queryString = require('query-string');

class Header extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      slide: false,
      clearSearchBox: false,
      showBanner: true
    };
    this.history = props.history;
    this.search = this.getTextFromQueryString(props);
    this.searchBox = {
      type: 'header',
      inputStyle: {
        id: 'search-input', class: '', placeholder: 'search', name: 'searchText', value: this.search.searchText
      },
      inputStyleButton: {
        id: 'search-submit', class: '', placeholder: 'Search', value: 'Search'
      },
      formAttr: {
        action: 'application/browse', id: 'search-form', class: '', method: 'get'
      }
    };

    this.searchBoxMobile = {
      type: 'header',
      inputStyle: {
        id: 'small-search-input', class: '', placeholder: 'search', name: 'searchText', value: this.search.searchText
      },
      inputStyleButton: {
        id: 'small-search-submit', class: '', placeholder: 'Search', value: 'Search'
      },
      formAttr: {
        action: 'application/browse', id: 'small-search-form', class: '', method: 'get'
      }
    };

    this.props.history.listen((location) => {
      if (location.pathname !== '/products') {
        this.setState({
          clearSearchBox: true
        });
      } else {
        this.searchBox.inputStyle.value = queryString.parse(location.search).searchText;
        this.setState({
          clearSearchBox: false
        });
      }
    });
  }

  getSearchedCharacter = (searchText) => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction();
      return;
    }
    const queryStr = queryString.stringify({ searchText });
    this.history.push(`/searchresults?${queryStr}`);
  }

  getTextFromQueryString(props) {
    return queryString.parse(props.location.search);
  }

  slideToggle = () => {
    this.setState({
      slide: !this.state.slide
    });
  }

  goToRoutes = (route) => {
    this.props.goToRoutes(route, this.props.history);
  }

  logoHandler = (e) => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction();
      e.preventDefault();
    }
  }

  searchFilter = (filter, val) => {
    const queryStr = queryString.stringify({ key: filter, value: val });
    this.history.push(`/products?${queryStr}`);
  }

  render() {
    const isUserAuthenticated = this.props.user && !this.props.user.expired;
    const canUserViewAdministrationLink = isUserAuthenticated && this.props.user.profile
      && (
        (this.props.user.profile.role === roleNameConstants.MarketplaceTeamAdmin.name)
        || (this.props.user.profile.role === roleNameConstants.TPSupport.name)
        || (this.props.user.profile.role === roleNameConstants.TPAdmin.name)
        || (this.props.user.profile.role === roleNameConstants.TPApplicationCertifier.name)
      );
    return (
      <>
        <LogoHeader isSmallView />
        {this.props.location.pathname === '/'
          ? (
            <BannerNotification
              data={this.props.bannerNotification}
              bannerState={this.state.showBanner}
              closeBanner={() => this.setState({ showBanner: false })}
            />
          ) : null}
        <header>
          {/* Brand Div */}
          <div id="brand-div" className="row">
            {window.enviroment.ENVIRONMENT === 'PP2' ? (
              <header className="pp-banner row">
                Pre-Production system — Testing Only
              </header>
            ) : ''}
            {/* <div className="col-xs-12 col-sm-6">
              <h1><a onClick={this.logoHandler} href="/" id="travelport-marketplace-logo">Travelport Marketplace</a></h1>
            </div> */}
            <a id="global-nav-toggle" href="#" onClick={this.slideToggle}>
              <span className="sr-only">Toggle Navigation</span>
              <span />
            </a>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <nav id="global-nav">
                <MPNavigationBar
                  {...this.props}
                  goToRoutes={this.goToRoutes}
                  isUserAuthenticated={isUserAuthenticated}
                  canUserViewAdministrationLink={canUserViewAdministrationLink}
                />
              </nav>
              <nav id="global-nav-mobile" className="visible-xs visible-sm">
                <div id="collapsible-panel-example-1" expanded={this.state.slide} hidden={this.state.slide ? '' : 'hidden'} onToggle={() => this.setState({ slide: !this.state.slide })}>
                  <div>
                    <div>
                      <span>
                        {/* <SearchBox
                          {...this.searchBoxMobile}
                          clearSearchBox={this.state.clearSearchBox}
                          onFormSubmit={this.getSearchedCharacter}
                        /> */}
                        {/* <Auth isSmallView /> */}
                      </span>
                      <MPNavigationBar
                        {...this.props}
                        isSmallView
                        goToRoutes={this.goToRoutes}
                        isUserAuthenticated={isUserAuthenticated}
                        canUserViewAdministrationLink={canUserViewAdministrationLink}
                        changeUserRole={this.props.changeUserRole}
                      />
                    </div>
                  </div>
                </div>
              </nav>

            </div>
            <div className="col-sm-4">
              <div id="user-nav" className="large-view-display">
                <Auth />
                <AutoUpgradeAgencyAdmin
                  userDetail={this.props.user}
                  acceptClickHandler={this.props.acceptAgencyAdmin}
                  declineClickHandler={this.props.declineAgencyAdmin}
                />
                <GlobalSearch clearSearchBox={this.state.clearSearchBox} searchFilter={this.searchFilter} />
              </div>
            </div>
            <div className="small-view-display">
              <GlobalSearch clearSearchBox={this.state.clearSearchBox} searchFilter={this.searchFilter} />
            </div>
          </div>
        </header>
      </>
    );
  }
}

Header.propTypes = {
  history: PropTypes.any,
  location: PropTypes.object,
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  goToRoutes: PropTypes.func,
  isPPCPFormModified: PropTypes.bool,
  acceptAgencyAdmin: PropTypes.func,
  declineAgencyAdmin: PropTypes.func,
  checkRolePromotionResult: PropTypes.bool,
  user: PropTypes.object,
  bannerNotification: PropTypes.array,
  changeUserRole: PropTypes.func
};

const mapStateToProps = (state) => ({
  user: state.oidc.user,
  disableLinks: state.disableLinks,
  isPPCPFormModified: state.application.applicationLayout.currentForm.modified,
  bannerNotification: state.home.bannerNotification ? state.home.bannerNotification.pagedCollection : []
});

const mapDispatchToProps = (dispatch) => ({
  notifyDisableLinksAction: bindActionCreators(canNotifyDisableLinks, dispatch),
  goToRoutes: (route, history) => dispatch(goToRoute.base({ route, queryString: '/', history })),
  acceptAgencyAdmin: () => dispatch(autoUpgradeAction.base()),
  declineAgencyAdmin: () => dispatch(declineUpgradeAction.base()),
  changeUserRole: roleId => dispatch(changeUserRole.base(roleId))
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false // https://github.com/ReactTraining/react-router/issues/3536
})(withRouter(Header));
