/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import MpDatePicker from 'components/common/FormInputs/Datepicker/MpDatePicker';
import DateTimeHelper from 'utils/dateHelper';
import { fetchUserFeedbackReport } from 'reducers/modules/Administration/Reports';
import { userFeedbackReportTotalPages, userFeedbackReportSelector } from 'reducers/modules/Administration/Reports/UserFeedbackReport/selectors';
import { exportToExcelFunc } from 'reducers/modules/Administration/Reports/ExportToExcel/ExportToExcel';
import DisplayTable from '../CommonParts/DisplayTable';

class UserFeedbackReport extends PureComponent {
  constructor(props) {
    super(props);
    const utcDate = new Date(moment.utc().format());
    this.state = {
      startDate: moment(utcDate).subtract(2, 'years'),
      endDate: utcDate,
      sortAsc: false,
      sortOnColumn: '',
      sorting: {
        columnName: '',
        direction: 'asc'
      },
      paging: {
        currentPage: 0,
        pageSize: 20
      }
    };
    this.columnDefinitions = [
      { name: 'netPromoterScore', title: 'SCORE' },
      { name: 'emailAddress', title: 'EMAIL ADDRESS' },
      { name: 'organizationName', title: 'ORGANIZATION' },
      { name: 'countryName', title: 'COUNTRY' },
      { name: 'timestamp', title: 'TIMESTAMP' }
    ];
  }

  componentDidMount() {
    document.title = 'User Feedback Report';
    this.getFilteredUserFeedback();
  }

  getFilteredUserFeedback = e => {
    if (e) {
      e.preventDefault();
    }
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentPage: this.state.paging.currentPage
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.fetchFeedbackReportAction(params);
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  };

  getSortDirection = () => this.state.sorting.direction === 'asc';

  sortHandler = newSorting => this.setState(
    { sorting: { ...newSorting[0] } },
    this.getFilteredUserFeedback
  );

  pageHandler = currentPage => this.setState(
    { paging: { ...this.state.paging, currentPage } },
    this.getFilteredUserFeedback
  );

  exportToExcel = (e) => {
    e.preventDefault();
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortOnColumn: this.getSortColumn() || '',
      sortAsc: this.getSortColumn() ? this.getSortDirection() : false
    };

    if (this.state.sorting.columnName !== '') {
      params.sortOnColumn = this.state.sorting.columnName;
    }
    this.props.userFeedbackReportDownloadExcel(params);
  };

  datehandler = (e, field) => {
    this.setState({
      [field]: DateTimeHelper.getDate(e)
    });
  };

  render() {
    return (
      <>
        <h2>User Feedback Report</h2>
        <div className="container">
          <div className="no-margin">
            <div className="row">
              <Link to="/admin/reports">&lt; Back to Reports</Link>
            </div>
            <div className="row no-space">
              <h4>FILTER REPORT</h4>
            </div>
            <div id="filter-report-generator" className="row">
              <div className="col-sm-5">
                <div className="col-sm-5 col-sm-offset-1">
                  <span
                    id="datepicker-start-date"
                    className="input-append date"
                    data-date="12/1/2013"
                    data-date-format="mm/dd/yyyy"
                  >
                    <label htmlFor="startDate">From:</label>
                    <span
                      className="add-on"
                      style={{ display: 'inline-block' }}
                    >
                      <MpDatePicker
                        value={this.state.startDate}
                        onChange={e => this.datehandler(e, 'startDate')}
                        isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                      />
                    </span>
                  </span>
                </div>
                <div className="col-sm-5 col-sm-offset-1">
                  <span
                    id="datepicker-end-date"
                    className="input-append date"
                    data-date="12/31/2013"
                    data-date-format="mm/dd/yyyy"
                  >
                    <label htmlFor="endDate">To:</label>
                    <span
                      className="add-on"
                      style={{ display: 'inline-block' }}
                    >
                      <MpDatePicker
                        value={this.state.endDate}
                        onChange={e => this.datehandler(e, 'endDate')}
                        isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-sm-1 col-sm-offset-1 pad-left">
                <Link
                  to="#"
                  className="action-button"
                  onClick={this.getFilteredUserFeedback}
                >
                  Search
                </Link>
              </div>
            </div>
            <div className="row text-right ajax-dependent-div">
              <Link
                to="#"
                id="downloadExcel"
                className="text-link-with-icon-left"
                onClick={this.exportToExcel}
              >
                <span className="download-icon" />
                Download in Microsoft Excel
              </Link>
            </div>
            <div id="table-div" className="row">
              <DisplayTable
                columns={this.columnDefinitions}
                rows={this.props.rows}
                currentPage={this.state.paging.currentPage}
                pageSize={this.state.paging.pageSize}
                pageHandler={this.pageHandler}
                totalCount={
                  this.props.totalPages * this.state.paging.pageSize
                }
                sorting={this.sorting}
                sortHandler={this.sortHandler}
                showSortingControls
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

UserFeedbackReport.propTypes = {
  fetchFeedbackReportAction: PropTypes.func,
  rows: PropTypes.array,
  totalPages: PropTypes.number,
  userFeedbackReportDownloadExcel: PropTypes.func
};

const mapStateToProps = state => ({
  rows: userFeedbackReportSelector(state),
  totalPages: userFeedbackReportTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFeedbackReportAction: args => dispatch(fetchUserFeedbackReport.base(args)),
  userFeedbackReportDownloadExcel: args => dispatch(exportToExcelFunc.userFeedbackReport(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFeedbackReport);
