import {
  takeLatest, put, select, call, all
} from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
// import fetchEntity from 'reducers/utils/fetchEntitySaga';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import { publicIdSelector } from '../selectors';
import { updateFormApplication } from '../applicationReducer';
// import { createDistributionRequest } from '../Distribution/selector';

const URL_DISTRIBUTION_FORM = 'application/getpricinganddistribution';
const URL_LISTS_DISTRIBUTION = 'application/getlistsforpricinganddistribution';
const GET_LIST_DISTRIBUTION = createFetchTypes('Marketplace/Application/GET_LIST_DISTRIBUTION');
const GET_DISTRIBUTION = createFetchTypes('Marketplace/Application/GET_DISTRIBUTION');
const UPLOAD_PLUGIN = createFetchTypes('Marketplace/Application/UPLOAD_PLUGIN');
const UPDATE_DISTRIBUTION_PROPS = 'Marketplace/Application/UPDATE_DISTRIBUTION_PROPS';

function distributionReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPLOAD_PLUGIN.BASE:
      return {
        ...state,
        isSpPlugin: action.data.isSpPlugin
      };
    case GET_LIST_DISTRIBUTION.REQUEST:
    case GET_DISTRIBUTION.REQUEST:
    case UPLOAD_PLUGIN.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_LIST_DISTRIBUTION.SUCCESS:
    case GET_DISTRIBUTION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case GET_LIST_DISTRIBUTION.FAILURE:
    case GET_DISTRIBUTION.FAILURE:
    case UPLOAD_PLUGIN.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.response.data
      };
    case UPLOAD_PLUGIN.SUCCESS: {
      if (state.isSpPlugin) {
        return {
          ...state,
          loading: false,
          integrationDownloadLastUploadedVersion: action.payload.uploadedVersion
        };
      }
      return {
        ...state,
        loading: false,
        directDownloadLastUploadedVersion: action.payload.uploadedVersion
      };
    }
    case UPDATE_DISTRIBUTION_PROPS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}

export const getListsDistributionForm = {
  base: (data) => ({
    type: GET_LIST_DISTRIBUTION.BASE,
    url: 'application/getlistsforpricinganddistribution',
    data: { data }
  }),
  request: () => ({ type: GET_LIST_DISTRIBUTION.REQUEST }),
  success: payload => ({ type: GET_LIST_DISTRIBUTION.SUCCESS, payload }),
  failure: error => ({ type: GET_LIST_DISTRIBUTION.FAILURE, error }),
};

export const getDistributionForm = {
  base: (data) => ({
    type: GET_DISTRIBUTION.BASE,
    url: 'application/getpricinganddistribution',
    data
  }),
  request: () => ({ type: GET_DISTRIBUTION.REQUEST }),
  success: payload => ({ type: GET_DISTRIBUTION.SUCCESS, payload }),
  failure: error => ({ type: GET_DISTRIBUTION.FAILURE, error }),
};

export const uploadPlugin = {
  base: (data) => ({
    type: UPLOAD_PLUGIN.BASE,
    url: 'appversion/uploadplugin',
    data
  }),
  request: () => ({ type: UPLOAD_PLUGIN.REQUEST }),
  success: payload => ({ type: UPLOAD_PLUGIN.SUCCESS, payload }),
  failure: error => ({ type: UPLOAD_PLUGIN.FAILURE, error })
};

export const updateDistributionProperty = {
  base: (data) => ({
    type: UPDATE_DISTRIBUTION_PROPS,
    data
  })
};

function* getDistributionSaga({ data }) {
  try {
    /* we do a call for the form data and another for the dropdowns in the form */
    yield put(getDistributionForm.request());
    const [distributionForm, lists] = yield all([
      call(() => ApiClient.httpClient.get(URL_DISTRIBUTION_FORM, { params: { publicId: data.publicId, organizationId: data.organizationId } })),
      call(() => ApiClient.httpClient.get(URL_LISTS_DISTRIBUTION)),
    ]);
    yield put(getDistributionForm.success({
      ...distributionForm.data.model,
      licenseTypeList: lists.data.licenseType,
      pricePeriods: lists.data.pricePeriods,
      trialDayList: lists.data.trialDayList,
      currencyList: lists.data.currencyList,
    }));
    yield put(updateFormApplication(distributionForm.data.model));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
}

function* uploadPluginSaga({ url, data }) {
  try {
    yield put(uploadPlugin.request());
    const publicId = yield select(publicIdSelector);
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('id', publicId);
    formData.append('version', data.version);
    formData.append('isSpPlugin', data.isSpPlugin);
    formData.append('fileName', data.file.name);
    // const distributionFormValues = yield createDistributionRequest();
    // yield put(updateDistributionProperty.base(distributionFormValues));
    const response = yield ApiClient.httpClient.post(url, formData, { params: { fileName: data.file.name } });
    if (response.data) {
      data.onSuccess();
      yield put(uploadPlugin.success(response.data));
    }
  } catch (error) {
    yield put(uploadPlugin.failure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* watchGetDistributionSaga() {
  yield takeLatest(GET_DISTRIBUTION.BASE, getDistributionSaga);
}

export function* watchUploadPluginSaga() {
  yield takeLatest(UPLOAD_PLUGIN.BASE, uploadPluginSaga);
}

export default distributionReducer;
