export const SET_VIEW = 'Marketplace/authotization/SET_VIEW';

export const setView = data => ({
  type: SET_VIEW,
  view: data
});

export const authSetViewReducer = (state = '', action = {}) => {
  switch (action.type) {
    case 'Marketplace/oAuth/LOAD_USER_INFO_SUCCESS':
    case 'Marketplace/oAuth/LOAD_USER_INFO_FAILURE':
      return {
        ...state,
        userSearchComplete: true
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.view
      };

    default:
      return state;
  }
};
