import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import {
  hideLicenseHistoryModal
} from 'reducers/modules/Administration/ManageLicenses/EditLicenseStatus/editLicenseStatus';
import {
  editLicenseStatusSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';

const LicenseHistoryModal = (props) => {
  if (!props.licenseHistories) {
    return '';
  }
  return (
    <Modal
      id="sbModal"
      title="License history"
      hidden={!props.showLicenseHistoryModal}
      size="small"
      onDismiss={props.hideLicenseHistoryModal}
    >
      <div id="modal-history">
        {props.licenseHistories.map((history) => <p className="break-text">{history}</p>)}
      </div>
      <div className="text-center pad-top">
        <button type="button" className="action-button" data-dismiss="modal" onClick={props.hideLicenseHistoryModal}>Close Window</button>
      </div>
    </Modal>
  );
};

LicenseHistoryModal.propTypes = {
  showLicenseHistoryModal: PropTypes.bool,
  licenseHistories: PropTypes.array,
  hideLicenseHistoryModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  showLicenseHistoryModal: editLicenseStatusSelector(state).showLicenseHistoryModal,
  licenseHistories: editLicenseStatusSelector(state).licenseHistories
});

const mapDispatchToProps = (dispatch) => ({
  hideLicenseHistoryModal: () => dispatch(hideLicenseHistoryModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseHistoryModal);
