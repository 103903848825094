import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Authorization from 'components/Authorization/Authorization';
import Administration from 'components/Administration/Administration';
import ProductPreview from 'components/Product/ProductPreview/ProductPreview';
import AgencyProfile from 'components/Administration/AgencyProfile/AgencyProfile';
import UserProfileControl from 'components/UserProfileControl/UserProfileControl';
import UserRoles from 'components/Administration/UserRoles/userRoles';
import ManageLicenseSearch from 'components/Administration/ManageLicenses/ManageLicensesSearch/ManageLicensesSearch';
import EditLicenseStatus from 'components/Administration/ManageLicenses/EditLicenseStatus/EditLicenseStatus';
import NoMatch from 'components/NoMatch';
import error500 from 'components/error500';
import Home from './Home/Home';
import Products from './Products/Products';
import Faqs from './Faqs/Faqs';
import Contactus from './Contactus/Contactus';
import SearchResults from './SearchResults/SearchResult';
import DeveloperDetails from './DeveloperDetails/DeveloperDetails';
import ProductDetails from './Product/ProductDetails/ProductDetails';
import ProductDetailNavigate from './Product/ProductDetails/ProductDetailNavigate';
import Callback from './Auth/Callback';
import Profile from './Profile/Profile';
import Order from './Product/Order/Order';
import {
  ApplicationLayout
} from './Application';
import {
  ProductVersionControl,
  BuildVersionControl,
  OrganizationDirectory,
  SmartpointConfigSettings,
  ManageApplicationCertificate,
} from './Administration';
import Recommend from './Product/Recommend/Recommend';
import ManageLicenseAssignments from './Administration/Licenses/ManageLicenseAssignments/ManageLicenseAssignments';
import DistributionAdminAssignment from './Administration/UserRoles/DistributionAdminAssignment/DistributionAdminAssignment';
import ManageHostUsers from './Administration/ManageHostUsers/ManageHostUsers';
import {
  Reports, ProductReviews, UserLoginReport, UserFeedbackReport
} from './Administration/Reports';
import ProductAccess from './Administration/ProductAccess/ProductAccess';
import AuditLog from './Administration/AuditLog/AuditLog';
import BlockedUserPage from './BlockedUserPage';
import Banner from './Administration/Banner/Banner';
import BannerPreview from './BannerPreview/BannerPreview';
import DeveloperStatsReport from './Administration/Reports/DeveloperStatsReport/DeveloperStatsReport';
import CookiePolicy from './CookiePolicy/CookiePolicy';

class Router extends Component {
  render() {
    const noPath = { path: false };
    return (
      <main>
        <Switch>
          <Route exact path="/Home" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/banner/details/:bannerId" component={BannerPreview} />
          <Route exact path="/cookiepolicy" component={CookiePolicy} />
          <Route exact path="/searchresults" component={SearchResults} />
          <Route exact path="/DeveloperDirectory/:seoname" component={DeveloperDetails} />
          <Route exact path="/Product/:seoname" component={ProductDetails} />
          <Route exact path="/Application/Details/:appId" component={ProductDetailNavigate} />
          <Route exact path="/Preview/:seoname" component={ProductPreview(ProductDetails)} />
          <Route exact path="/Product/:seoname/:action" component={ProductDetails} />
          <Route exact path="/callback" component={Authorization(Callback)} />
          <Route exact path="/Account/MyProfile" component={Profile} />
          <Route exact path="/Order/:id" render={(props) => <Order showTermsFor="standard" {...props} />} />
          <Route exact path="/PlatformOrder/:id" render={(props) => <Order showTermsFor="platform" {...props} />} />
          <Route exact path="/SmartpointCloudOrder/:id" render={(props) => <Order showTermsFor="smartpointcloud" {...props} />} />
          <Route exact path="/admin/home" component={Authorization(Administration)} />
          <Route path="/application" component={Authorization(ApplicationLayout, noPath)} />
          <Route exact path="/admin/home/organizationDirectory" component={Authorization(OrganizationDirectory)} />
          <Route exact path="/admin/distribution" component={Authorization(ProductVersionControl)} />
          <Route exact path="/admin/auditLog" component={Authorization(AuditLog)} />
          <Route exact path="/admin/banner" component={Authorization(Banner)} />
          <Route exact path="/admin/distribution/buildVersionControl" component={Authorization(BuildVersionControl)} />
          <Route exact path="/recommend/:id" component={Authorization(Recommend)} />
          <Route exact path="/admin/licenses/manageLicenseAssignments" component={Authorization(ManageLicenseAssignments)} />
          <Route exact path="/admin/configuration" component={Authorization(SmartpointConfigSettings)} />
          <Route exact path="/Profile/AgencyProfile/:id" component={AgencyProfile} />
          <Route exact path="/Profile/UserProfile/:id" component={UserProfileControl} />
          <Route exact path="/admin/Certifications/Pending" component={Authorization(ManageApplicationCertificate)} />
          <Route exact path="/admin/manageUserRoles" component={Authorization(UserRoles)} />
          <Route exact path="/admin/licenses/manageLicenses" component={Authorization(ManageLicenseSearch)} />
          <Route exact path="/admin/licenses/editLicenseStatus/:id" component={Authorization(EditLicenseStatus)} />
          <Route exact path="/admin/distributeAdminAssignment" component={Authorization(DistributionAdminAssignment)} />
          <Route exact path="/admin/managehostusers" component={Authorization(ManageHostUsers)} />
          <Route exact path="/admin/reports" component={Authorization(Reports)} />
          <Route exact path="/admin/reports/userLoginReport" component={Authorization(UserLoginReport)} />
          <Route exact path="/admin/reports/productReviews" component={Authorization(ProductReviews)} />
          <Route exact path="/admin/reports/userFeedbackReport" component={Authorization(UserFeedbackReport)} />
          <Route exact path="/admin/reports/devStatsReport" component={Authorization(DeveloperStatsReport)} />
          <Route exact path="/admin/ProductAccess" component={Authorization(ProductAccess)} />
          <Route exact path="/error/500" component={error500} />
          <Route exact path="/blockedUser" component={BlockedUserPage} />
          <Route component={NoMatch} />
        </Switch>
      </main>
    );
  }
}

export default Router;
