import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  manageLicensesSearchSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';

const DataCells = (props) => {
  switch (props.column.name) {
    case 'action':
      return <td><Link to={`/admin/licenses/editLicenseStatus/${props.row.id}?searchType=Plugin&searchText=${props.searchText}`}>VIEW AGENCIES &gt;</Link></td>;
    default:
      return <Table.Cell {...props} />;
  }
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  searchText: PropTypes.string
};

const mapStateToProps = (state) => ({
  searchText: manageLicensesSearchSelector(state).pluginTableParams.searchCriteria,
});

export default connect(mapStateToProps, null)(DataCells);
