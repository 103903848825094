import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';

const DataCells = (props) => {
  switch (props.column.name) {
    case 'userId':
      return <td><Link to={`/Profile/UserProfile/${props.row.id}`}>{props.row.userId}</Link></td>;
    case 'organization':
      return <td><Link to={`/Profile/AgencyProfile/${props.row.organizationId}`}>{props.row.organization}</Link></td>;
    case 'roles':
      return <td>{props.row.roles.map(r => r.name).join(', ')}</td>;
    default:
      return <Table.Cell {...props} />;
  }
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object
};

export default DataCells;
