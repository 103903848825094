import { select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

/* SAGA SELECTOR */
const selectionSelector = state => state.form.classification.values;
const classificationSelector = state => state.application.classification.classficaitonModel;
const classificationHostSelector = state => state.application.classification.classficaitonHost;
const productCategoryAndRegionSelector = state => state.application.classification.productCategoryAndRegion;

/* Check the inpData array filter the index of selected checkbox and return the appropriate ID */
function classificationFormationData(inpData, appTypeData) {
  const value = inpData.map((data, index) => {
    if (data === true) {
      return index + 1;
    }
    return data;
  }).filter(x => !!x);

  const finalValue = value.map(data => appTypeData[data - 1].id);

  return finalValue;
}

function classificationPriceFormat(inpData) {
  if (inpData) return true;
  return false;
}
const hostAndSupportFormatDataBasedOnTypes = (appData, arrValue) => (
  (appData.map((data, index) => {
    if (arrValue.includes(data.id)) return index + 1;
    return null;
  })
  ).filter(data => data !== null)
);
const getReturnValue = (classicData) => {
  const returnValue = [];
  for (let count = 1; count <= Math.max(...classicData); count += 1) {
    if (classicData.includes(count)) {
      returnValue.push(count);
    } else {
      returnValue.push(null);
    }
  }
  return returnValue;
};
/* Get the Selected Checkbox from API in parameter and changing as index to set in Redux-Form InitialValues  */
const hostAndSupportFormatData = (arrValue, id, appTypeRegionData) => {
  if (arrValue && appTypeRegionData) {
    if (arrValue[id].length) {
      let classicData = arrValue[id];

      if (id === 'appTypeIds') {
        classicData = hostAndSupportFormatDataBasedOnTypes(appTypeRegionData.appTypes, arrValue[id]);
      }

      if (id === 'supportedRegionIds') {
        classicData = hostAndSupportFormatDataBasedOnTypes(appTypeRegionData.regions, arrValue[id]);
      }

      const returnValue = getReturnValue(classicData);
      return returnValue;
    }
  }
  return [];
};

const getPriceFormationData = (data) => {
  if (data) return data.isPaid ? 1 : 0;
  return null;
};

export const getClassificationSelector = createSelector(
  [classificationSelector, productCategoryAndRegionSelector],
  (classification, appTypeHostRegionData) => ({
    ...classification,
    isPaid: getPriceFormationData(classification),
    appTypeIds: hostAndSupportFormatData(classification, 'appTypeIds', appTypeHostRegionData),
    hostIds: hostAndSupportFormatData(classification, 'hostIds', appTypeHostRegionData),
    supportedRegionIds: hostAndSupportFormatData(classification, 'supportedRegionIds', appTypeHostRegionData),
  })
);

export function* createClassificationRequest() {
  const classificationState = yield select(selectionSelector);
  const appTypeIdState = yield select(productCategoryAndRegionSelector);
  const hostStateData = yield select(classificationHostSelector);
  const classificationData = yield select(classificationSelector);

  return {
    ...classificationState,
    hostIds: classificationFormationData(classificationState.hostIds, hostStateData),
    appTypeIds: classificationFormationData(classificationState.appTypeIds, appTypeIdState.appTypes),
    supportedRegionIds: classificationFormationData(classificationState.supportedRegionIds, appTypeIdState.regions),
    isPaid: classificationPriceFormat(classificationState.isPaid),
    keywords: classificationData.keywords
  };
}
