import { combineReducers } from 'redux';
import agencyDetails from './AgencyDetails/index';
import { agencyHierarchyReducer } from './AgencyHierarchy/agencyHierarchy';

const agency = combineReducers({
  agencyDetails,
  agencyHierarchy: agencyHierarchyReducer
});

export {
  getHostList,
  // getMatchingIDs,
  // watchHostListSaga,
  // watchMatchingIDSaga
} from './AgencyDetails/index';

export {
  fetchAgencyHierarchy,
  watchfetchAgencyHierarchySaga,
  emulateAgency,
  watchEmulateAgencySaga
} from './AgencyHierarchy/agencyHierarchy';

export default agency;
