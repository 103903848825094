/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import { Modal } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  VirtualTable,
  TableEditColumn,
  TableEditRow
} from '@devexpress/dx-react-grid-bootstrap4';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import { getViewPccsData } from '../../../reducers/modules/Administration/OrganizationDirectory/ViewPccsModal/selector';
import { fetchPCC, addPCC, deletePCC } from '../../../reducers/modules/Administration/OrganizationDirectory/ViewPccsModal/viewPccs';

class ViewPCCsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'pccOrSid', title: 'PCC' },
      { name: 'host', title: 'HOST' }
    ];
    this.addedPCCsColumnDefinitions = [
      { name: 'pccOrSid', title: 'PCC' },
      { name: 'host', title: 'HOST' }
    ];
    this.state = {
      pcc: '',
      host: ''
    };
  }

  componentDidUpdate(prevProps) {
    document.title = 'View PCC';
    if (prevProps.orgId !== this.props.orgId && this.props.orgId !== undefined) {
      this.props.fetchPCCDetails({ organizationId: this.props.orgId });
    }
  }

  handleRadioButtonChange = (event) => {
    this.setState({
      host: event.currentTarget.value
    });
  }

  pccTextBoxChangeHandler = (event) => {
    this.setState({
      pcc: event.currentTarget.value,
      host: this.state.host
    });
  }

  handleAddPccButtonClick = () => {
    this.props.addPCC({
      orgId: this.props.orgId,
      pccName: this.state.pcc,
      host: this.state.host
    });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      host: '',
      pcc: ''
    });
  }

  handleCloseModal = () => {
    this.setState({
      pcc: '',
      host: '1G'
    });
    this.props.closeModal();
  };

  confirmationHandler = () => {
    this.props.openModal();
  }

  commitChanges = ({ deleted }, data) => {
    if (deleted) {
      this.props.closeModal();
      this.props.deletePCC({ pccId: data[deleted].id, orgId: this.props.orgId, confirmationHandler: this.confirmationHandler });
    }
  }

  render() {
    const diasabledBtnClass = this.state.pcc.length !== 0 && this.state.host.length !== 0 ? 'action-button' : 'disable-button action-button';
    const diasabledBtn = this.state.pcc.length !== 0 && this.state.host.length !== 0;
    const isVisiblePccManually = this.props.initialValues.manuallyAddedPccs.length === 0 ? 'hide' : '';

    return (
      <>
        {this.props.initialValues.loading && <LoaderComponent show />}
        <Modal
          id="sbModal"
          title={`PCC(s) for ${this.props.orgName}`}
          hidden={!this.props.showModal}
          size="medium"
          onDismiss={this.props.closeModal}
        >
          <div className="row space-top-double">
            <div className="col-sm-12">
              <div className="row">
                <div>
                  <div className="col-sm-6 pad-sm text-right">
                    <div className="col-sm-4 pad-sm space-top-sm">
                      Add&nbsp;PCC:&nbsp;
                    </div>
                    <div className="col-sm-8 pad-sm">
                      <input className="text-input" id="pccToAdd" placeholder="Enter a valid PCC" value={this.state.pcc} onChange={this.pccTextBoxChangeHandler} />
                    </div>
                  </div>
                  <div className="col-sm-3 pad-sm space-top-sm">
                    <div className="col-sm-4">
                      <input
                        type="radio"
                        id="1G"
                        name="host"
                        checked={this.state.host === '1G'}
                        value="1G"
                        onChange={this.handleRadioButtonChange}
                        disabled={!this.state.pcc.length}
                      />
                      <label htmlFor="1G">1G</label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="radio"
                        id="1V"
                        name="host"
                        checked={this.state.host === '1V'}
                        value="1V"
                        onChange={this.handleRadioButtonChange}
                        disabled={!this.state.pcc.length}
                      />
                      <label htmlFor="1V">1V</label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="radio"
                        id="1P"
                        name="host"
                        checked={this.state.host === '1P'}
                        value="1P"
                        onChange={this.handleRadioButtonChange}
                        disabled={!this.state.pcc.length}
                      />
                      <label htmlFor="1P">1P</label>
                    </div>
                  </div>
                  <div className="col-sm-3 pad-sm">
                    <div className="pad-sm"><button type="button" href="#" disabled={!diasabledBtn} className={diasabledBtnClass} onClick={this.handleAddPccButtonClick}>ADD</button></div>
                  </div>
                </div>
              </div>
              <h3>System added PCCs</h3>
              <div className="travelport-table-sticky" id="sys-pcc">
                <Grid columns={this.columnDefinitions} rows={this.props.initialValues.askTravelportPccs}>
                  <VirtualTable height={200} />
                  <TableHeaderRow showSortingControls={false} />
                  <EditingState
                    onCommitChanges={(deleted) => this.commitChanges(deleted, this.props.initialValues.askTravelportPccs)}
                  />
                  <Table />
                  <TableHeaderRow />
                  <TableEditRow />
                  <TableEditColumn
                    showDeleteCommand
                  />
                </Grid>
              </div>
              <h2 className={`${isVisiblePccManually} pad-top`}>Manually added PCCs</h2>
              <div className={`${isVisiblePccManually} travelport-table-sticky`} id="manual-pcc">
                <Grid columns={this.addedPCCsColumnDefinitions} rows={this.props.initialValues.manuallyAddedPccs}>
                  <VirtualTable height={200} />
                  <TableHeaderRow showSortingControls={false} />
                  <EditingState
                    onCommitChanges={(deleted) => this.commitChanges(deleted, this.props.initialValues.manuallyAddedPccs)}
                  />
                  <Table />
                  <TableHeaderRow />
                  <TableEditRow />
                  <TableEditColumn
                    showDeleteCommand
                  />
                </Grid>
              </div>
            </div>
          </div>
          <div className="no-space row text-center">
            <a className="cancel-button" onClick={this.handleCloseModal}>Close</a>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: getViewPccsData(state).data
});

const mapDispatchToProps = (dispatch) => ({
  fetchPCCDetails: (args) => dispatch(fetchPCC.base(args)),
  addPCC: (args) => dispatch(addPCC.base(args)),
  deletePCC: (args) => dispatch(deletePCC.base(args))
});

ViewPCCsModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  orgName: PropTypes.string,
  orgId: PropTypes.number,
  initialValues: PropTypes.object,
  fetchPCCDetails: PropTypes.func,
  addPCC: PropTypes.func,
  openModal: PropTypes.func,
  deletePCC: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPCCsModal);
