import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap-css-only';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from '@atlas-design-system/react';
import App from './components/App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import './Styles/marketplace.less';
import store from './store';
// import '@atlas-design-system/core/dist/styles/main.css';
import '@atlas-design-system/tokens/dist/tokens.css';
/* eslint-disable react/jsx-filename-extension */
import '@atlas-design-system/core/dist/styles/partials/modal.css';
import SplunkOtelWeb from '@splunk/otel-web';
import * as Config from './config/apiConfig';

SplunkOtelWeb.init({
  realm: 'us1',
  rumAccessToken: window.enviroment.SPLUNK_RUM_TOKEN,
  applicationName: Config.CLIENT_ID,
  version: Config.BUILD_NUMBER,
  deploymentEnvironment: Config.ENVIRONMENT
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Toaster />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
unregisterServiceWorker();
