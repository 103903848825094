import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  fetchAccountSetting,
  fetchAccountSettingNewSolution,
  fetchAccountSettingPluginOptionForAgency,
  fetchAccountSettingVisibilityForAgency,
  getUserProfileId
} from 'reducers/Profile/AccountSetting/accoutSettingReducer';
import AccountSettingPresentation from './AccountSettingPresentation';

class AccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      showErrorMessage: false,
      handleChangeAccountSettingNewVersion: props.userDetails.userDetails.receiveEmailOnNewVersion,
      handleChangeAccountSettingNewSolution: props.userDetails.userDetails.receiveEmailOnNewSolution,
      handleChangeAccountSettingPluginOptionForAgency: props.userDetails.agencyDetails.enablePluginAdministration,
      handleChangeAccountSettingAssignedSolutionVisibilityForAgency: props.userDetails.agencyDetails.enableAgentsToSeeAssignedSolutions
    };
  }

  handleChangeAccountSettingNewVersion = (value, checked) => {
    this.setState({ handleChangeAccountSettingNewVersion: checked });
    this.props.fetchAccountSetting({ userId: this.props.userProfileId, checked });
  }

  handleChangeAccountSettingNewSolution = (value, checked) => {
    this.setState({ handleChangeAccountSettingNewSolution: checked });
    this.props.fetchAccountSettingNewSolution({ userId: this.props.userProfileId, checked });
  }

  handleChangeAccountSettingPluginOptionForAgency = (value, checked) => {
    this.setState({ handleChangeAccountSettingPluginOptionForAgency: checked });
    this.props.fetchAccountSettingPluginOptionForAgency({
      orgId: this.props.userDetails.agencyDetails.publicId,
      enablePluginAdministration: checked
    });
  }

  handleChangeAccountSettingAssignedSolutionVisibilityForAgency = (value, checked) => {
    this.setState({ handleChangeAccountSettingAssignedSolutionVisibilityForAgency: checked });
    this.props.fetchAccountSettingVisibilityForAgency({
      orgId: this.props.userDetails.agencyDetails.publicId,
      allowAgentsToSeeAssignedSolutions: checked
    });
  }

  render() {
    return (
      <>
        <AccountSettingPresentation
          handleChangeNotificationNewVersion={this.handleChangeAccountSettingNewVersion}
          handleChangeNewVersionChecked={this.state.handleChangeAccountSettingNewVersion}
          handleChangeNotificationNewSolution={this.handleChangeAccountSettingNewSolution}
          handleChangeAccountSettingNewSolutionChecked={this.state.handleChangeAccountSettingNewSolution}
          handleChangeNotificationPluginOptionForAgency={this.handleChangeAccountSettingPluginOptionForAgency}
          handleChangeAccountSettingPluginOptionForAgencyChecked={this.state.handleChangeAccountSettingPluginOptionForAgency}
          handleChangeNotificationAssignedSolutionVisibilityForAgency={this.handleChangeAccountSettingAssignedSolutionVisibilityForAgency}
          handleChangeAccountSettingAssignedSolutionVisibilityForAgencyChecked={this.state.handleChangeAccountSettingAssignedSolutionVisibilityForAgency}
          userProfilePermission={this.props.userProfilePermission}
        />
      </>
    );
  }
}

AccountSetting.propTypes = {
  userDetails: PropTypes.object,
  saveForm: PropTypes.func,
  fetchAccountSetting: PropTypes.func,
  fetchAccountSettingNewSolution: PropTypes.func,
  fetchAccountSettingPluginOptionForAgency: PropTypes.func,
  fetchAccountSettingVisibilityForAgency: PropTypes.func,
  userProfilePermission: PropTypes.object,
  userProfileId: PropTypes.number
};

const mapStateToProps = (state) => ({
  userDetails: state.profile.userProfile,
  userProfilePermission: state.profile.accessingUserProfileReducer,
  userProfileId: getUserProfileId(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountSetting: (args) => dispatch(fetchAccountSetting.base(args)),
  fetchAccountSettingNewSolution: (args) => dispatch(fetchAccountSettingNewSolution.base(args)),
  fetchAccountSettingPluginOptionForAgency: (args) => dispatch(fetchAccountSettingPluginOptionForAgency.base(args)),
  fetchAccountSettingVisibilityForAgency: (args) => dispatch(fetchAccountSettingVisibilityForAgency.base(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
