/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const CookiePolicy = () => (
  <div className="container">
    <div style={{ fontSize: '30px', marginBottom: '10px', fontFamily: 'gt_walsheim' }}>
      Use of cookies on
      <span>Marketplace</span>
    </div>
    <div style={{ fontSize: '20px', marginBottom: '10px', fontFamily: 'gt_walsheim' }}>What are cookies?</div>
    <p className="medium-text space-bottom-double" style={{ marginBottom: '10px' }}>
      Cookies are small pieces of information that a website sends to your computer for record-keeping purposes.
      This information is stored in a file on your computer's hard drive.
      Cookies make web-surfing easier for you by saving your preferences so that we can use the saved information to facilitate your use of our website when you return to the website.
      The use of cookies is an industry standard, and as such, you will find that most major websites use them.
      By showing how and when users use the website, cookies help us see which areas of the website are popular and which are not.
      Many improvements and updates to the website are based on such data as total number of visitors and pages viewed.
      This information is most easily tracked with cookies.
    </p>
    <p className="medium-text space-bottom-double" style={{ marginBottom: '10px' }}>
      Most cookies expire after a defined period of time, or you can delete your cookie file at any time.
      Most web browsers are initially set up to accept cookies.
      You can reset your web browser to refuse cookies or to indicate when a cookie is being sent.
      However, note that some parts of Marketplace‘s services will not function properly or may be considerably slower if you refuse cookies.
      For example, without cookies, you will not be able to set personalized preferences.
    </p>
    <div style={{ fontSize: '20px', marginBottom: '10px', fontFamily: 'gt_walsheim' }}>Our primary uses for cookies</div>
    <p className="medium-text space-bottom-double" style={{ marginBottom: '10px' }}>
      Marketplace has two primary uses for cookies.
      First, we use them to specify a user's preferences.
      Second, we use cookies to track website usage trends and patterns.
      This helps us understand our users' needs better and improve areas of our website.
      While both of these activities depend on the use of cookies, you have the option of disabling or refusing the cookies via your web browser preferences.
    </p>
    <div style={{ fontSize: '20px', marginBottom: '10px', fontFamily: 'gt_walsheim' }}>List of cookies used on this website</div>
    <ul>
      <li>_gid</li>
      <li>_ga</li>
      <li>connect.sid</li>
      <li>SMSESSION</li>
      <li>fs_uid</li>
      <li>fs_uid</li>
    </ul>
  </div>
);

export default CookiePolicy;
