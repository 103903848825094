/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Getter } from '@devexpress/dx-react-core';
import {
  EditingState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableEditRow,
  Toolbar,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  initAudiencePage,
  getVisibilitySelector,
  productAccessListSelector,
  totalPagesSelector,
  saveAudienceAccess,
  changeGlobalAssignment,
  deleteAudienceAccess,
  updateCurrentForm
} from 'reducers/modules/Application';
import { changeVisibilityNonParticipants, changeVisibilityTPEmployees } from 'reducers/modules/Application/Audience/actions';
import {
  FormHeader
} from 'components/Application';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import {
  publicIdSelector,
  isInternalPPCP
} from 'reducers/modules/Application/selectors';
import { roleNameConstants } from 'utils/rolesHelper';
import * as utils from 'utils/Common.Utils';
import * as ppcp from 'constants/PPCP';
import EditCell from '../../Administration/ProductAccess/TableCells/ProductAccessEditCells';
import LoaderComponent from '../../common/Loader/LoaderComponent';

class AudienceAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        sortOnColumn: '',
        sortAsc: '1',
        currentPage: 0,
        isPos: false
      },
      pageSize: 20,
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      deletingRows: [],
      columns: [
        { name: 'accessCategory', title: 'ACCESS CATEGORY' },
        { name: 'categoryValueOne', title: 'CATEGORY VALUE' },
        { name: 'categoryValueTwo', title: 'CATEGORY VALUE' },
      ],
    };
  }

  componentDidMount() {
    this.props.updateCurrentFormAction();
    const { initAudiencePageAction } = this.props;
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    initAudiencePageAction({ appPublicId, organizationId });
    window.scrollTo(0, 0);
  }

  changeRowChanges = rowChanges => this.setState({ rowChanges })

  changeAddedRows = addedRows => this.setState({ addedRows });

  changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });

  deletedRowIdsChange = deletingRows => this.setState({ deletingRows });

  commitChanges = ({ deleted }) => {
    if (deleted) {
      const { onDelete, rows } = this.props;
      onDelete({
        row: rows[deleted[0]],
        appPublicId: utils.getValueFromQueryString(this.props.location, 'publicid'),
        organizationId: utils.getValueFromQueryString(this.props.location, 'organizationId'),
        ...this.state.filters
      });
    }
  }

  commitChangesAsync = (id, onExecute) => {
    const { onSave, rows } = this.props;
    switch (id) {
      case 'commit': {
        const save = {};
        if (this.state.addedRows.length > 0) {
          save.addedRows = this.state.addedRows;
        }
        if (this.state.rowChanges[this.state.editingRowIds[0]]) {
          save.updatedRows = {
            accessCategory: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].accessCategory,
            categoryValueOne: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].categoryValueOne,
            categoryValueTwo: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].categoryValueTwo,
            ...this.state.rowChanges[this.state.editingRowIds[0]],
            id: rows[this.state.editingRowIds[0]] && rows[this.state.editingRowIds[0]].id,
          };
        }
        onSave({
          ...save,
          appPublicId: utils.getValueFromQueryString(this.props.location, 'publicid'),
          organizationId: utils.getValueFromQueryString(this.props.location, 'organizationId'),
          ...this.state.filters,
          onSuccess: onExecute
        });
        break;
      }
      default:
        onExecute();
        break;
    }
  }

  command = ({ id, onExecute }) => {
    const commandComponentProps = {
      add: {
        text: 'New',
        hint: 'Create new row',
      },
      edit: {
        text: 'Edit',
        hint: 'Edit row',
      },
      delete: {
        text: 'Delete',
        hint: 'Delete row',
      },
      commit: {
        text: 'Save',
        hint: 'Save changes',
      },
      cancel: {
        text: 'Cancel',
        hint: 'Cancel changes',
      },
    };
    return (
      <a className="pad-sm" onClick={(e) => { e.preventDefault(); this.commitChangesAsync(id, onExecute); }}>
        {commandComponentProps[id].text}
      </a>
    );
  }

  clearCategoryValues = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueOne = '';
      this.state.addedRows[0].categoryValueTwo = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueOne = '';
        this.state.rowChanges[rowIndex].categoryValueTwo = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueOne: '',
          categoryValueTwo: '',
        };
      }
    }
  };

  clearCategoryValueTwo = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueTwo = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueTwo = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueTwo: '',
        };
      }
    }
  };

  clearCategoryValueOne = () => {
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      this.state.addedRows[0].categoryValueOne = '';
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].categoryValueOne = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          categoryValueOne: '',
        };
      }
    }
  };

  EditCell = (props) => {
    const { rows } = this.props;
    let currentAccessCategory;
    let currentCategoryValueOne;
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      currentAccessCategory = this.state.addedRows[0].accessCategory;
      currentCategoryValueOne = this.state.addedRows[0].categoryValueOne;
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      currentAccessCategory = (this.state.rowChanges && this.state.rowChanges[rowIndex] && this.state.rowChanges[rowIndex].accessCategory) || rows[rowIndex].accessCategory;
      currentCategoryValueOne = (this.state.rowChanges && this.state.rowChanges[rowIndex] && this.state.rowChanges[rowIndex].categoryValueOne) || rows[rowIndex].categoryValueOne;
    }
    return (
      <EditCell
        currentAccessCategory={currentAccessCategory}
        rows={rows}
        {...props}
        clearCategoryValues={this.clearCategoryValues}
        clearCategoryValueTwo={this.clearCategoryValueTwo}
        currentCategoryValueOne={currentCategoryValueOne}
        clearCategoryValueOne={this.clearCategoryValueOne}
      />
    );
  }

  changeVisibilityNonParticipants = () => {
    const { changeVisibilityNonParticipantsAction } = this.props;
    changeVisibilityNonParticipantsAction();
  }

  changeVisibilityTPEmployees = () => {
    const { changeVisibilityTPEmployeesAction } = this.props;
    changeVisibilityTPEmployeesAction();
  }

  changeGlobalAccess = (value) => {
    const { changeGlobalAssignmentAction } = this.props;
    changeGlobalAssignmentAction(value);
  }

  pageHandler = (currentPage) => {
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.setState({ filters: { ...this.state.filters, currentPage } }, () => this.props.initAudiencePageAction({ appPublicId, organizationId, ...this.state.filters }));
  }

  pageSizeHandler = (pageSize) => {
    const appPublicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');
    this.setState({ pageSize }, () => this.props.initAudiencePageAction({ appPublicId, organizationId, ...this.state.filters }));
  }

  renderGrid = () => {
    const { rows } = this.props;
    return (
      <div className="travelport-table remove-word-wrap">
        <Grid
          rows={rows}
          columns={this.state.columns}
        >
          <Table />
          <TableHeaderRow />
          <EditingState
            addedRows={this.state.addedRows}
            rowChanges={this.state.rowChanges}
            editingRowIds={this.state.editingRowIds}
            deletedRowIds={this.state.deletingRows}
            onEditingRowIdsChange={this.changeEditingRowIds}
            onDeletedRowIdsChange={this.deletedRowIdsChange}
            onAddedRowsChange={this.changeAddedRows}
            onRowChangesChange={this.changeRowChanges}
            onCommitChanges={this.commitChanges}
          />

          <PagingState
            currentPage={this.state.filters.currentPage}
            onCurrentPageChange={this.pageHandler}
            pageSize={this.state.pageSize}
            onPageSizeChange={this.pageSizeHandler}
          />
          <CustomPaging totalCount={this.props.totalPages * this.state.pageSize} />
          <TableEditRow
            cellComponent={this.EditCell}
          />
          <TableEditColumn
            width={100}
            showAddCommand={this.state.addedRows.length === 0}
            showEditCommand
            showDeleteCommand
            commandComponent={this.command}
          />
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => [
              ...tableColumns.filter(c => c.type !== 'editCommand'),
              { key: 'editCommand', type: 'editCommand', width: 140 }]}
          />
          <Toolbar />
          <PagingPanel pageSizes={[]} />
        </Grid>
      </div>
    );
  }

  renderFormData = () => {
    const {
      isGlobal,
      isVisibleToTPEmployees,
      isVisibleToNonParticipants
    } = this.props;

    const canUserChangeTPEmployeeVisibility = this.props.currentUser && this.props.currentUser.profile
      && (
        (this.props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name)
        || (this.props.currentUser.profile.role === roleNameConstants.TPAdmin.name)
        || (this.props.currentUser.profile.role === roleNameConstants.DeveloperUser.name && this.props.currentUser.profile.IsTravelportEmployee)
        || (this.props.currentUser.profile.role === roleNameConstants.SPCDeveloperUser.name && this.props.currentUser.profile.IsTravelportEmployee)
      );
    return (
      <>
        <div className="global-access-radio-buttons">
          <SimplePrettyRadioButton
            enabled
            displayText={this.props.isInternal ? 'Agency Wide' : 'GLOBAL'}
            value={isGlobal}
            checked={isGlobal}
            onChange={this.changeGlobalAccess}
          />
          <SimplePrettyRadioButton
            enabled
            displayText="Restricted Access"
            value={isGlobal}
            checked={!isGlobal}
            onChange={this.changeGlobalAccess}
          />
        </div>
        {!isGlobal
          && (
          <div className="row ajax-dependent-div">
            <SimplePrettyCheckBox
              enabled
              value={isVisibleToNonParticipants}
              onChange={this.changeVisibilityNonParticipants}
              displayText={this.props.isInternal
                ? 'Allow all users from your organization to view the Product page'
                : 'Allow unlisted Marketplace users to view the product page.'}
              tooltip={this.props.isInternal
                ? 'Only users that appear in the list will be allowed to download your solution even if you allow everyone in your organization to view the product page.'
                : 'Only users that appear in the list will be allowed to order your solution even if you allow everyone to view the product page.'}
            >
              {this.props.isInternal
                ? 'Allow all users from your organization to view the Product page'
                : 'Allow unlisted Marketplace users to view the product page.'}
            </SimplePrettyCheckBox>
            {!this.props.isInternal && canUserChangeTPEmployeeVisibility
              && (
              <SimplePrettyCheckBox
                enabled
                value={isVisibleToTPEmployees}
                displayText="Should non-list Travelport Employees have access to view/download this product from the product page?"
                onChange={this.changeVisibilityTPEmployees}
                tooltip="Selecting this option allows any Travelport employee to access this product page.  De-selecting this option means that only restricted list members will be able to access the product page, regardless of organization."
              />
              )}
          </div>
          )}
        {!isGlobal && this.renderGrid()}
      </>
    );
  };

  render() {
    const { loading } = this.props;
    return (
      <div className="audience-container">
        <FormHeader
          header="Audience"
          infoText="Define who can search for, view, and order your solution. If your solution is not available in a country or region, use the “Restricted Access” option to define who may use it."
          importantText="Note that for ticketing solutions, in order to issue airline reservations through GDS, travel agencies are usually required to be accredited by either Airlines Reporting Corporation (ARC) when they are located in the United States, or Billing and Settlement Plan (BSP) outside of the US. Please classify your solution correctly to avoid customer disappointment."
        />
        {(loading) && <LoaderComponent show />}
        {this.renderFormData()}
      </div>
    );
  }
}

AudienceAccess.defaultProps = {
  rows: [],
  isGlobal: true,
  isVisibleToNonParticipants: false,
  isVisibleToTPEmployees: false
};

AudienceAccess.propTypes = {
  initAudiencePageAction: PropTypes.func,
  onSave: PropTypes.func,
  getUserNamesAction: PropTypes.func,
  getOrganizationNamesAction: PropTypes.func,
  getPccOrgAction: PropTypes.func,
  rows: PropTypes.array,
  location: PropTypes.object,
  isGlobal: PropTypes.bool,
  isVisibleToTPEmployees: PropTypes.bool,
  isVisibleToNonParticipants: PropTypes.bool,
  onDelete: PropTypes.func,
  changeGlobalAssignmentAction: PropTypes.func,
  changeVisibilityNonParticipantsAction: PropTypes.func,
  changeVisibilityTPEmployeesAction: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isInternal: PropTypes.bool,
  currentUser: PropTypes.object,
  updateCurrentFormAction: PropTypes.func,
  totalPages: PropTypes.number
};

const mapStateToProps = (state) => ({
  loading: getVisibilitySelector(state).loading,
  error: getVisibilitySelector(state).error,
  rows: productAccessListSelector(state),
  totalPages: totalPagesSelector(state),
  appPublicId: publicIdSelector(state),
  isGlobal: getVisibilitySelector(state).isGlobal,
  isVisibleToTPEmployees: getVisibilitySelector(state).isVisibleToTPEmployees,
  isVisibleToNonParticipants: getVisibilitySelector(state).isVisibleToNonParticipants,
  isInternal: isInternalPPCP(state),
  currentUser: state.oidc.user
});

const mapDispatchToProps = dispatch => ({
  initAudiencePageAction: (args) => dispatch(initAudiencePage(args)),
  changeGlobalAssignmentAction: (args) => dispatch(changeGlobalAssignment.base(args)),
  changeVisibilityNonParticipantsAction: () => dispatch(changeVisibilityNonParticipants.base()),
  changeVisibilityTPEmployeesAction: () => dispatch(changeVisibilityTPEmployees.base()),
  onSave: (args) => dispatch(saveAudienceAccess.base(args)),
  onDelete: (args) => dispatch(deleteAudienceAccess.base(args)),
  updateCurrentFormAction: (modified) => dispatch(updateCurrentForm(ppcp.AUDIENCE_ID, modified))
});

export default connect(mapStateToProps, mapDispatchToProps)(AudienceAccess);
