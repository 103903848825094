export const LOADPOPULARPRODUCTS = 'LOAD_POPULAR_PRODUCTS';
export const PRODUCTS = 'PRODUCTS';
export const SEARCHRESULTS = 'SEARCH_RESULTS';
export const FILTERDETAILS = 'FILTER_DETAILS';
export const BROWSEPRODUCT = 'BROWSE_PRODUCT';
export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
export const LOAD_ORGANIZATION_SUCCESS = 'LOAD_ORGANIZATION_SUCCESS';
export const LOAD_ORGANIZATION_FAILURE = 'LOAD_ORGANIZATION_FAILURE';
export const SEARCHRESULTSTEXT = 'SEARCH_RESULTS_TEXT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS';
export const LOAD_PRODUCT_FAILURE = 'LOAD_PRODUCT_FAILURE';

export const LOAD_APP_RATING = 'LOAD_APP_RATING';
export const LOAD_APP_RATING_SUCCESS = 'LOAD_APP_RATING_SUCCESS';
export const LOAD_APP_RATING_FAILURE = 'LOAD_APP_RATING_FAILURE';

export const LOAD_APP_REVIEWS = 'LOAD_APP_REVIEWS';
export const LOAD_APP_REVIEWS_SUCCESS = 'LOAD_APP_REVIEWS_SUCCESS';
export const LOAD_APP_REVIEWS_FAILURE = 'LOAD_APP_REVIEWS_FAILURE';

export const SAVE_PRODUCT_REVIEW = 'SAVE_PRODUCT_REVIEW';
export const SAVE_PRODUCT_REVIEW_SUCCESS = 'SAVE_PRODUCT_REVIEW_SUCCESS';
export const SAVE_PRODUCT_REVIEW_FAILURE = 'SAVE_PRODUCT_REVIEW_FAILURE';

export const DELETE_PRODUCT_REVIEW = 'DELETE_PRODUCT_REVIEW';
export const DELETE_PRODUCT_REVIEW_SUCCESS = 'DELETE_PRODUCT_REVIEW_SUCCESS';
export const DELETE_PRODUCT_REVIEW_FAILURE = 'DELETE_PRODUCT_REVIEW_FAILURE';

export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const DEVELOPER_FILTERDETAILS = 'DEVELOPER_FILTER_DETAILS';
export const DEVELOPER_BROWSE = 'DEVELOPER_BROWSE';
export const FETCH_ENTITY_SET = 'FETCH_ENTITY_SET';
export const FETCH_ENTITY_SET_SUCCESS = 'FETCH_ENTITY_SET_SUCCESS';
export const FETCH_ENTITY_SET_FAILURE = 'FETCH_ENTITY_SET_FAILURE';

export const FETCH_PRODUCT_SET = 'FETCH_PRODUCT_SET';
export const FETCH_PRODUCT_SET_SUCCESS = 'FETCH_PRODUCT_SET_SUCCESS';
export const FETCH_PRODUCT_SET_FAILURE = 'FETCH_PRODUCT_SET_FAILURE';

export const LOAD_ALL_COUNTRY = 'LOAD_ALL_COUNTRY';
export const LOAD_DETAILS_FOR_TERMS = 'LOAD_DETAILS_FOR_TERMS';
export const DETAILS_FOR_TERMS_LOAD_SUCCESS = 'DETAILS_FOR_TERMS_LOAD_SUCCESS';
export const DETAILS_FOR_TERMS_LOAD_FAILURE = 'DETAILS_FOR_TERMS_LOAD_FAILURE';
export const BUY_APPLICATION = 'BUY_APPLICATION';
export const BUY_APPLICATION_SUCCESS = 'BUY_APPLICATION_SUCCESS';
export const BUY_APPLICATION_FAILURE = 'BUY_APPLICATION_FAILURE';
export const DISABLE_LINKS = 'DISABLE_LINKS';
export const SEND_LINK_CLICK_MESSAGE = 'SEND_LINK_CLICK_MESSAGE';
export const HIDE_SEND_LINK_CLICK_MESSAGE = 'HIDE_SEND_LINK_CLICK_MESSAGE';
export const ENABLE_LINKS = 'ENABLE_LINKS';
export const LOAD_FIELDS_FOR_DEVELOPER_EDIT = 'LOAD_FIELDS_FOR_DEVELOPER_EDIT';
export const GET_DEVELOPER_ORG_DETAILS = 'GET_DEVELOPER_ORG_DETAILS';
export const LOAD_DEVELOPER_ORG_DETAILS = 'LOAD_DEVELOPER_ORG_DETAILS';
export const LOAD_DEVELOPER_ORG_SAVE = 'LOAD_DEVELOPER_ORG_SAVE';
export const LOAD_DEVELOPER_ORG_SAVE_SUCCESS = 'LOAD_DEVELOPER_ORG_SAVE_SUCCESS';
export const RESET_PRODUCT_DETAILS = 'RESET_PRODUCT_DETAILS';
export const RESET_DEVELOPER_DETAILS = 'RESET_DEVELOPER_DETAILS';

export const GET_PRODUCT_EVENT_REPORT = 'GET_PRODUCT_EVENT_REPORT';
export const PRODUCT_EVENT_REPORT_GET_SUCCESS = 'PRODUCT_EVENT_REPORT_GET_SUCCESS';
export const PRODUCT_EVENT_REPORT_GET_FAILURE = 'PRODUCT_EVENT_REPORT_GET_FAILURE';

export const EXPORT_PRODUCT_EVENT_REPORT = 'EXPORT_PRODUCT_EVENT_REPORT';
export const PRODUCT_EVENT_REPORT_EXPORT_SUCCESS = 'PRODUCT_EVENT_REPORT_EXPORT_SUCCESS';
export const PRODUCT_EVENT_REPORT_EXPORT_FAILURE = 'PRODUCT_EVENT_REPORT_EXPORT_FAILURE';

export const GET_PRODUCT_SELECT_LIST = 'GET_PRODUCT_SELECT_LIST';
export const PRODUCT_SELECT_LIST_GET_SUCCESS = 'PRODUCT_SELECT_LIST_GET_SUCCESS';
export const PRODUCT_SELECT_LIST_GET_FAILURE = 'PRODUCT_SELECT_LIST_GET_FAILURE';

// SOLUTIONS
export const FETCH_INHOUSE_SOLUTION_SET = 'FETCH_INHOUSE_SOLUTION_SET';
export const FETCH_INHOUSE_SOLUTION_SET_SUCCESS = 'FETCH_INHOUSE_SOLUTION_SET_SUCCESS';
export const FETCH_INHOUSE_SOLUTION_SET_FAILURE = 'FETCH_INHOUSE_SOLUTION_SET_FAILURE';
export const FETCH_ORDERED_SOLUTION_SET = 'FETCH_ORDERED_SOLUTION_SET';
export const FETCH_ORDERED_SOLUTION_SET_SUCCESS = 'FETCH_ORDERED_SOLUTION_SET_SUCCESS';
export const FETCH_ORDERED_SOLUTION_SET_FAILURE = 'FETCH_ORDERED_SOLUTION_SET_FAILURE';
export const FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET = 'FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET';
export const FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_SUCCESS = 'FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_SUCCESS';
export const FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_FAILURE = 'FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_FAILURE';

export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const GET_LICENSE_HISTORY = 'GET_LICENSE_HISTORY';
export const GET_LICENSE_HISTORY_SUCCESS = 'GET_LICENSE_HISTORY_SUCCESS';
export const GET_LICENSE_HISTORY_FAILURE = 'GET_LICENSE_HISTORY_FAILURE';

export const UPDATE_PUBLISH_STATUS = 'UPDATE_PUBLISH_STATUS';
export const UPDATE_PUBLISH_STATUS_SUCCESS = 'UPDATE_PUBLISH_STATUS_SUCCESS';
export const UPDATE_PUBLISH_STATUS_FAILURE = 'UPDATE_PUBLISH_STATUS_FAILURE';

export const DELETE_DRAFT = 'DELETE_DRAFT_STATUS';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE';
