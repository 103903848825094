import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoaderComponent from 'components/common/Loader/LoaderComponent';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';

class Callback extends Component {
  componentDidMount() {
    let lastUsedPathName = sessionStorage.getItem('lastUsedPathName');
    if (lastUsedPathName && lastUsedPathName.indexOf('unAuthorized') !== -1) {
      lastUsedPathName = '';
    }
    sessionStorage.removeItem('lastUsedPathName');
    const data = {
      history: this.props.history,
      callbackRouter: lastUsedPathName || '/'
    };
    this.props.loadUserInfo(data);
  }

  render() {
    return (
      <LoaderComponent show />
    );
  }
}

Callback.propTypes = {
  history: PropTypes.any,
  location: PropTypes.object,
  loadUserInfo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  loadUserInfo: (data) => dispatch(loadUserInfo.base(data))
});

export default connect(null, mapDispatchToProps)(Callback);
