import * as Constants from 'constants/PPCP';
import * as apiConfig from '../../../config/apiConfig';

export default {
  forms: {
    [Constants.BASIC_INFORMATION_ID]: {
      name: 'BASIC INFORMATION',
      route: Constants.BASIC_INFORMATION_ROUTE,
      saveUrl: apiConfig.SAVE_BASICINFORMATION_DETAILS,
      order: 1,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL,
        Constants.TRAVELPORT_POS_APPLICATION
      ]
    },
    [Constants.MEDIA_ID]: {
      name: 'MEDIA',
      route: Constants.MEDIA_ROUTE,
      saveUrl: 'application/savemedia',
      order: 2,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL,
        Constants.TRAVELPORT_POS_APPLICATION
      ]
    },
    [Constants.SUPPORT_ID]: {
      name: 'SUPPORT',
      route: Constants.SUPPORT_ROUTE,
      saveUrl: 'application/savesupport',
      order: 3,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL,
        Constants.TRAVELPORT_POS_APPLICATION
      ]
    },
    [Constants.AUDIENCE_ID]: {
      name: 'AUDIENCE',
      route: Constants.AUDIENCE_ROUTE,
      order: 4,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL
      ]
    },
    [Constants.CLASSIFICATION_ID]: {
      name: 'CLASSIFICATION',
      route: Constants.CLASSIFICATION_ROUTE,
      saveUrl: apiConfig.SAVE_CLASSIFICATION_DETAILS,
      order: 5,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL,
        Constants.TRAVELPORT_POS_APPLICATION
      ]
    },
    [Constants.DISTRIBUTION_ID]: {
      name: 'DISTRIBUTION',
      route: Constants.DISTRIBUTION_ROUTE,
      saveUrl: 'application/savepricinganddistribution',
      order: 6,
      completed: false,
      pageType: [
        Constants.GENERAL,
        Constants.INTERNAL
      ]
    },
    [Constants.COMMUNICATION_ID]: {
      name: 'COMMUNICATION',
      route: Constants.COMMUNICATION_ROUTE,
      saveUrl: apiConfig.SAVE_COMMUNICATION_DETAILS,
      order: 7,
      completed: false,
      pageType: [
        Constants.GENERAL
      ]
    }
  },
  currentForm: {
    name: Constants.BASIC_INFORMATION_ID,
    modified: false
  },
  publicId: '',
  organizationId: '',
  submitted: false,
  completionPoints: '',
  percentageCompletion: 0,
  isProductPageComplete: false,
  isInternal: false,
  isTravelportPOSApplication: false,
  pageType: Constants.GENERAL,
  isReviewCompleted: false
};
