import {
  takeLatest, call, put, race, take
} from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import createFetchTypes from '../../../../utils/createFetchTypes';
import ApiClient from '../../../../../Services/ApiClient';
import fetchEntity from '../../../../utils/fetchEntitySaga';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal,
} from '../../../../popUps/confirmationModalReducer';

export const FETCH_PCCS = createFetchTypes('Marketplace/Administration/OrganizationDirectory/ViewPCCs/FETCH_PCCS');
export const ADD_PCCS = createFetchTypes('Marketplace/Administration/OrganizationDirectory/ViewPCCs/ADD_PCCS');
export const DELETE_PCCS = createFetchTypes('Marketplace/Administration/OrganizationDirectory/ViewPCCs/DELETE_PCCS');

export function viewPccs(state = { askTravelportPccs: [], manuallyAddedPccs: [] }, action = {}) {
  switch (action.type) {
    case FETCH_PCCS.REQUEST:
      return {
        ...state,
        askTravelportPccs: [],
        manuallyAddedPccs: [],
        loading: true
      };
    case ADD_PCCS.REQUEST:
    case DELETE_PCCS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PCCS.SUCCESS:
      return {
        ...state,
        askTravelportPccs: action.payload.pcCs.filter(item => !item.isAddedManually),
        manuallyAddedPccs: action.payload.pcCs.filter(item => item.isAddedManually),
        loading: false,
        error: null
      };
    case ADD_PCCS.SUCCESS:
    case DELETE_PCCS.SUCCESS:
      return {
        ...state,
        askTravelportPccs: action.payload.pccs.filter(item => !item.isAddedManually),
        manuallyAddedPccs: action.payload.pccs.filter(item => item.isAddedManually),
        loading: false,
        error: null
      };
    case FETCH_PCCS.FAILURE:
    case ADD_PCCS.FAILURE:
    case DELETE_PCCS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.ResponseMessage
      };
    default: return state;
  }
}

export const fetchPCC = {
  base: (data) => ({
    type: FETCH_PCCS.BASE,
    url: '/organizationdirectory/getpccsfororganization',
    data
  }),
  request: () => ({ type: FETCH_PCCS.REQUEST }),
  success: payload => ({ type: FETCH_PCCS.SUCCESS, payload }),
  failure: error => ({ type: FETCH_PCCS.FAILURE, error })
};

export const addPCC = {
  base: (data) => ({
    type: ADD_PCCS.BASE,
    url: '/organizationdirectory/addpcc',
    data
  }),
  request: () => ({ type: ADD_PCCS.REQUEST }),
  success: payload => ({ type: ADD_PCCS.SUCCESS, payload }),
  failure: error => ({ type: ADD_PCCS.FAILURE, error })
};

export const deletePCC = {
  base: (data) => ({
    type: DELETE_PCCS.BASE,
    url: '/organizationdirectory/removepcc',
    data
  }),
  request: () => ({ type: DELETE_PCCS.REQUEST }),
  success: payload => ({ type: DELETE_PCCS.SUCCESS, payload }),
  failure: error => ({ type: DELETE_PCCS.FAILURE, error })
};

export function* fetchPCCsSaga(action) {
  try {
    const { data } = action;
    if (data) {
      yield call(() => fetchEntity(fetchPCC, ApiClient.httpClient.get, `${action.url}`, { params: { orgId: data.organizationId } }));
    }
  } catch (error) {
    yield put(showAlertAction(error));
  }
}

export function* addPCCsSaga(action) {
  try {
    const { data } = action;
    if (data && data.pccName) {
      const response = yield call(() => fetchEntity(addPCC, ApiClient.httpClient.post, `${action.url}`, data));

      yield put(showAlertAction(response.data.responseMessage));
    }
  } catch (error) {
    yield put(showAlertAction(error));
  }
}

export function* deletePCCsSaga(action) {
  yield put(showConfirmationModal('Are you sure you want to delete?'));
  const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
  if (accept) {
    try {
      const { data } = action;
      if (data) {
        const url = `${action.url}/${data.pccId}`;
        const response = yield call(() => fetchEntity(deletePCC, ApiClient.httpClient.put, url, data.orgId));
        if (response.data) {
          yield put(showAlertAction(response.data.responseMessage));
        }
      }
    } catch (error) {
      yield put(showAlertAction(error));
    }
  }
  action.data.confirmationHandler();
}

export function* watchFetchPCCsSaga() {
  yield takeLatest(FETCH_PCCS.BASE, fetchPCCsSaga);
}

export function* watchAddPCCsSaga() {
  yield takeLatest(ADD_PCCS.BASE, addPCCsSaga);
}

export function* watchDeletePCCsSaga() {
  yield takeLatest(DELETE_PCCS.BASE, deletePCCsSaga);
}

export default viewPccs;
