/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import { Form, Input, Button } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import '@atlas-design-system/core/dist/styles/partials/form.css';
import '@atlas-design-system/core/dist/styles/partials/form-control.css';
import '@atlas-design-system/core/dist/styles/partials/input.css';

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.clearSearchBox = props.clearSearchBox;
    this.onSubmit = this.onSubmit.bind(this);
    this.renderHeaderSearchBox = this.renderHeaderSearchBox.bind(this);
    this.renderFooterSearchBox = this.renderFooterSearchBox.bind(this);
    this.state = {
      value: this.props.inputStyle.value || '',
      errorMessage: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearSearchBox) {
      this.clearSearchBox = nextProps.clearSearchBox;
      this.setState({
        value: ''
      });
    } else {
      this.clearSearchBox = nextProps.clearSearchBox;
      this.setState({
        value: nextProps.inputStyle.value || ''
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let showError;
    if ((this.state.value).trim() === '' || this.state.value === this.props.inputStyle.placeholder) {
      showError = true;
      this.setState({
        errorMessage: showError
      });
      return;
    }

    if (this.props.onFormSubmit) {
      this.props.onFormSubmit(this.state.value);
      if (this.props.clearSearchBoxAfterSubmit) {
        this.setState({
          value: ''
        });
      }
    }
  }

  renderHeaderSearchBox() {
    return (
      <Form action={this.props.formAttr.action} id={this.props.formAttr.id} method={this.props.formAttr.method} noValidate="novalidate" onSubmit={this.onSubmit}>
        <Input
          type="search"
          id={this.props.inputStyle.id}
          className={this.props.inputStyle.class}
          placeholder={this.props.inputStyle.placeholder}
          onChange={(e) => this.setState({ value: e.target.value })}
          name={this.props.inputStyle.name}
          value={this.state.value}
        />
        &nbsp;
        <Input type="submit" id={this.props.inputStyleButton.id} />
      </Form>
    );
  }

  renderFooterSearchBox() {
    return (
      <Form action={this.props.formAttr.action} id={this.props.formAttr.id} method={this.props.formAttr.method} noValidate="novalidate" onSubmit={this.onSubmit}>
        <div className="horizontal-input-group">
          <label htmlFor="search-text-below">
            New Search
          </label>
          <div>
            <Input
              type="text"
              id={this.props.inputStyle.id}
              className={this.props.inputStyle.class}
              placeholder={this.props.inputStyle.placeholder}
              name={this.props.inputStyle.name}
              onChange={(e) => this.setState({ value: e.target.value })}
              value={this.state.value}
            />
          </div>
          <Button
            href="#"
            onClick={this.onSubmit}
            id={this.props.inputStyleButton.id}
            className={this.props.inputStyleButton.class}
          >
            {this.props.inputStyleButton.value}
          </Button>
        </div>
        <div id="search-error-message" style={{ display: (this.state.errorMessage) ? 'block' : 'none' }} className="alert alert-danger space-bottom-double">
          Please enter search text
        </div>
      </Form>
    );
  }

  render() {
    return (
      <>
        {this.props.type === 'header' ? this.renderHeaderSearchBox() : this.renderFooterSearchBox()}
      </>
    );
  }
}

SearchBox.propTypes = {
  type: PropTypes.string,
  inputStyle: PropTypes.any,
  onFormSubmit: PropTypes.func,
  inputStyleButton: PropTypes.any,
  formAttr: PropTypes.any,
  clearSearchBox: PropTypes.bool,
  clearSearchBoxAfterSubmit: PropTypes.bool
};

SearchBox.defaultProps = {
  clearSearchBox: false,
  clearSearchBoxAfterSubmit: false
};

export default SearchBox;
