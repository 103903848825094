/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Modal } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const PopupModal = (props) => (
  <Modal
    id="sbModal"
    title={props.title}
    hidden={!props.showModal}
    size="medium"
    onDismiss={props.popUpHide}
  >
    {props.children}
  </Modal>
);

const RenderTitle = (props) => (
  <div>
    <label htmlFor="title">Title (optional)</label>
    <div className="horizontal-input-group">
      <div className="text-input-width">
        <input
          type="text"
          className="text-input"
          id="screenshot-title"
          onChange={(e) => props.handleChange(e)}
          value={props.title}
          name="screenshotTitle"
        />
      </div>
      <div className="add-left-padding"> </div>
    </div>
  </div>
);

const RenderImageFile = (props) => (
  <div>
    {
      !props.docFlag
        ? (
          <label htmlFor="files">Image File</label>
        )
        : (
          <label htmlFor="files">Document File</label>
        )
    }
    <div className="horizontal-input-group">
      <div className="text-input-width">
        <input type="text" className="text-input" id="upload-logo-modal-text-input" value={props.fileName} />
      </div>
      <Link to="#" onClick={(e) => e.preventDefault()} className="modal-browse-button">Browse</Link>
      <input type="file" id="upload-logo-modal-file-input" onChange={props.handleFileupload} file-model="myFile" value="" name="appIcon" className="file-input transparent" />
    </div>
  </div>
);

const FooterButtons = (props) => (
  <div className="text-center">
    <Link
      to="#"
      className="action-button no-save gtm-myprofile-image-upload"
      gtm-event-label="Profile Image"
      onClick={(e) => {
        e.preventDefault();
        props.uploadImage();
      }}
    >
      Upload
    </Link>
    <Link
      to="#"
      className="cancel-button space-left no-save"
      data-dismiss="modal"
      onClick={(e) => {
        e.preventDefault();
        props.popUpHide();
      }}
    >
      Cancel
    </Link>
  </div>
);

const RequiredField = (props) => ((props.show)
  ? (
    <div id="upload-screenshot-error-message" className="alert alert-danger space-bottom-double text-center">
      <p>
        <strong>Please select an image to upload first</strong>
      </p>
    </div>
  ) : null);

const ValidateTitle = (props) => ((props.show)
  ? (
    <div id="upload-screenshot-error-message" className="alert alert-danger space-bottom-double text-center">
      <p>
        <strong>Screenshot Title should not exceed 255 characters.</strong>
      </p>
    </div>
  ) : null);

const ValidateExtension = (props) => ((props.show)
  ? (
    <div id="upload-screenshot-error-message" className="alert alert-danger space-bottom-double text-center">
      <p>
        {
          !props.docFlag
            ? (
              <strong>Image file format not accepted. Please upload in .jpg, .png or .gif formats.</strong>
            )
            : (
              <strong>Document file format not accepted. Please upload in .doc, .docx or .pdf formats.</strong>
            )
        }
      </p>
    </div>
  ) : null);

const ValidateFileLength = (props) => ((props.show)
  ? (
    <div id="upload-screenshot-error-message" className="alert alert-danger space-bottom-double text-center">
      <p>
        <strong>File Name should not exceed 500 characters.</strong>
      </p>
    </div>
  ) : null);

class ProfileImagePopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showModal: props.showModal,
      fileName: '',
      file: null,
      title: '',
      showMessage: false,
      showTitleMessage: false,
      showExtensionMessage: false,
      showFileLengthMessage: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.showModal
    });
  }

  handleFileupload = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({ fileName: file.name, file });
    }
  }

  uploadImage = () => {
    if (!this.state.file) {
      this.setState({
        showMessage: true
      });
      return;
    }
    if (this.state.title.length > 255 && this.props.titleRequired && this.props.validationTypeUploadPopup) {
      this.setState({
        showTitleMessage: true
      });
      return;
    }
    if (this.props.validationTypeUploadPopup && this.validateFileExtension(this.state.fileName)) {
      this.setState({
        showExtensionMessage: true
      });
      return;
    }
    if (this.props.validationTypeUploadPopup && this.state.fileName.length > 500) {
      this.setState({
        showFileLengthMessage: true
      });
      return;
    }
    this.setState({
      showMessage: false,
      showTitleMessage: false,
      showExtensionMessage: false,
      showFileLengthMessage: false
    });
    this.props.submitImageUpload(this.state.file, this.state.title);
  }

  popUpHide = () => {
    this.setState({
      showModal: false
    });
    this.props.onHide();
  }

  validateFileExtension(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    const validExtensions = ['gif', 'png', 'jpg', 'jpeg'];
    const validExtensionsDoc = ['doc', 'docx', 'pdf'];
    if (!this.props.validationDocFile && validExtensions.indexOf(extension) !== -1) {
      return false;
    }
    if (this.props.validationDocFile && validExtensionsDoc.indexOf(extension) !== -1) {
      return false;
    }
    return true;
  }

  render() {
    if (this.props.validationTypeUploadPopup) {
      return (
        <PopupModal title={this.props.modalTitle} showModal={this.state.showModal} popUpHide={() => this.popUpHide()}>
          <form>
            <div className="space-left-double space-right-double">
              <p>
                <strong>Format:</strong>
                {' '}
                {this.props.acceptedFormat}
              </p>
              <p>
                {this.props.validationDocFile ? <strong>Document size:</strong>
                  : <strong>Image size:</strong>}
                {' '}
                {this.props.acceptedImageSize}
              </p>
              <div className="space-top-double space-bottom-double">
                {this.props.titleRequired ? <RenderTitle handleChange={(event) => { const tempTitle = event.target.value; this.setState({ tempTitle }); }} /> : null}
                <RenderImageFile handleFileupload={this.handleFileupload} fileName={this.state.fileName} docFlag={this.props.validationDocFile} />
              </div>
              <RequiredField show={this.state.showMessage} />
              <ValidateTitle show={this.state.showTitleMessage} />
              <ValidateExtension show={this.state.showExtensionMessage} docFlag={this.props.validationDocFile} />
              <ValidateFileLength show={this.state.showFileLengthMessage} />
            </div>
            <FooterButtons uploadImage={() => this.uploadImage()} popUpHide={this.popUpHide} />
          </form>
        </PopupModal>
      );
    }
    return (
      <PopupModal title={this.props.modalTitle} showModal={this.state.showModal} popUpHide={() => this.popUpHide()}>
        <div className="row horizontal-input-group">
          <div className="text-input-width">
            <input type="text" className="text-input" id="app-version-text-input" value={this.state.fileName} />
          </div>
          <Link to="#" className="cancel-button no-save">Browse</Link>
          <input type="file" className="file-input transparent" name="zipfile" id="upload-input" onChange={this.handleFileupload} file-model="myFile" />
        </div>
        <RequiredField show={this.state.showMessage} />
        <FooterButtons uploadImage={() => this.uploadImage()} popUpHide={this.popUpHide} />
      </PopupModal>
    );
  }
}

FooterButtons.propTypes = {
  uploadImage: PropTypes.func,
  popUpHide: PropTypes.func
};

RenderImageFile.propTypes = {
  handleFileupload: PropTypes.func,
  fileName: PropTypes.string,
  docFlag: PropTypes.bool
};

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  children: PropTypes.any
};

RenderTitle.propTypes = {
  title: PropTypes.string,
  handleChange: PropTypes.func
};

RequiredField.propTypes = {
  show: PropTypes.bool
};

ValidateTitle.propTypes = {
  show: PropTypes.bool
};

ValidateExtension.propTypes = {
  show: PropTypes.bool,
  docFlag: PropTypes.bool
};

ValidateFileLength.propTypes = {
  show: PropTypes.bool
};

ProfileImagePopup.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  modalTitle: PropTypes.string,
  imageUpload: PropTypes.func,
  submitImageUpload: PropTypes.func,
  validationTypeUploadPopup: PropTypes.bool,
  validationDocFile: PropTypes.bool,
  acceptedFormat: PropTypes.string,
  acceptedImageSize: PropTypes.string,
  titleRequired: PropTypes.bool
};

export default ProfileImagePopup;
