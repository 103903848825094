import React from 'react';
import PropTypes from 'prop-types';
import DateTimeHelper from 'utils/dateHelper';
import SimplePrettyCheckBox from '../../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';

const DataCells = (props) => {
  const value = props.dataItem[props.field];
  if (props.field === 'fromDate') {
    const dateFormat = DateTimeHelper.getDateTime(value, 'MM-DD-YYYY hh:mm:ss A');
    return (
      <td>
        {dateFormat}
      </td>
    );
  }
  if (props.field === 'toDate') {
    const dateFormat = DateTimeHelper.getDateTime(value, 'MM-DD-YYYY hh:mm:ss A');
    return (
      <td>
        {dateFormat}
      </td>
    );
  }
  if (props.field === 'isActive') {
    return (
      <td>
        <SimplePrettyCheckBox value={value} onChange={(e) => props.onChange(props.dataItem, e)} />
      </td>
    );
  }
  if (props.field === 'headerInfo') {
    const { highImportant } = props.dataItem;
    return (
      <td>
        {highImportant ? <span className="glyphicon glyphicon-exclamation-sign" /> : ''}
&nbsp;
        {value}
      </td>
    );
  }
  return '<td/>';
};

DataCells.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.object,
  onChange: PropTypes.func
};

export default DataCells;
