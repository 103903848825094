/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button, Link, AlertV2Icon, Banner, HelpReverseIllustration, NoResultsReverseIllustration
}
    from '@atlas-design-system/react';
// import { HistoryFilledIcon, CheckCircleOutlineIcon } from '@atlas-ui/react';
import ToolTip from '../../../../common/ToolTip';
import { fetchGitPluginLinkageData, savePluginLinkageDataForCertification } from '../../../../../reducers/modules/SpCloudPluginsAssociation/SpCloudPluginAssociation';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';
import '@atlas-design-system/core/dist/styles/partials/icons.css';

class SpCloudPlugin extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            version: '',
            versionError: '',
            versionValid: true,
            isBannerVisible: false,
            isDeveloperFirstTime: true,
            pluginDetails: [{}]
        };
    }

    componentDidMount() {
        this.props.fetchGitPluginLinkageDataAction({ appId: this.props.appId }, this.callbackplugin);
        window.scrollTo(0, 0);
    }

    handleValidation() {
        let formIsValid = true;
        let versionErrors = '';
        if (this.state.version !== '') {
             const patternCheck = (/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d{1,3})){4}$/.test(this.state.version));
            if (!patternCheck) {
                formIsValid = false;
                versionErrors = 'Invalid Version Pattern';
            }
        } else {
            formIsValid = false;
            versionErrors = 'The version number should begin with version number minimum of 1';
        }
        this.setState({ versionError: versionErrors });
        return formIsValid;
    }

    getPreviousVersion = () => {
        let data = '';
        if (this.props.getValidatedPluginLinkageList === undefined) {
            data = 'None';
        } else {
            data = this.props.getValidatedPluginLinkageList.previousVersion;
        }
        return data;
    }

    callbackplugin = () => {
        if (this.props.fetchPluginError === undefined) {
            if ((this.props.getValidatedPluginLinkageList !== undefined)) {
                if (this.props.getValidatedPluginLinkageList.currentVersion === 'None') {
                    this.setState({ version: '' });
                } else {
                    this.setState({ version: this.props.getValidatedPluginLinkageList.currentVersion });
                }
                const obj = this.props.getValidatedPluginLinkageList.plugins.map(item => ({
                    PluginId: item.pluginId
                }));
                this.setState({
                    pluginDetails: obj
                });
                this.setState({ isDeveloperFirstTime: true });
            } else {
                this.setState({ isDeveloperFirstTime: false });
            }
        }
    }

    callback = () => {
        let versionErrors = '';
        if (this.props.errorList !== undefined) {
            versionErrors = this.props.errorList;
            this.setState({ versionError: versionErrors });
        }
    }

    changeHandler = (e, params) => {
        this.setState({
            [params]: e.target.value
        });
        if (e.target.validity.valid) {
            this.setState({ versionValid: true });
        } else {
            this.setState({ versionValid: false });
        }
        this.setState({ versionError: '' });
    }

    submitPluginApproval = () => {
        if (this.handleValidation()) {
            this.props.savePluginLinkageDataForCertificationAction(
                {
                    AppId: this.props.appId, SubmitForApprovalUser: this.props.email, IsActive: false, VersionNumber: this.state.version, PluginDetails: this.state.pluginDetails
                }, this.callback
            );
            this.setState({
                isBannerVisible: true
            });
        } else {
            this.setState({
                isBannerVisible: false
            });
        }
    }

    render() {
        return (
            <>
                {!this.state.isDeveloperFirstTime || (this.props.fetchPluginError !== undefined)
                    ? (
                        <div
                            style={{
                                display: 'table', width: '100%', background: 'rgb(248, 248, 248)', height: '500px', minHeight: '0px!important'
                            }}
                        >
                            <div className="sp-main" style={{ paddingLeft: '15px' }}>
                                <div
                                    style={{
                                        textAlign: 'center', padding: '150px'
                                    }}
                                >
                                    {this.props.fetchPluginError !== undefined
                                        && (
                                            <>
                                                <NoResultsReverseIllustration
                                                    width="60px"
                                                    style={{
                                                        margin: 'auto', display: 'block', marginBottom: '12px'
                                                    }}
                                                />
                                                &nbsp;
                                                &nbsp;
                                                <span>
                                                    <b>
                                                        A Smartpoint Cloud app need to be added before you can submit for approval
                                                    </b>
                                                </span>
                                                <span>
                                                    <p
                                                        style={{
                                                            paddingTop: '8px', fontSize: '11px'
                                                        }}
                                                    >
                                                        once an app has been added,you will see your app(s) listed here for approval
                                                    </p>
                                                </span>
                                            </>
                                        )}
                                    {!this.state.isDeveloperFirstTime
                                        && (
                                            <>
                                                <HelpReverseIllustration
                                                    width="60px"
                                                    style={{
                                                        margin: 'auto', display: 'block', marginBottom: '12px'
                                                    }}
                                                />
                                                &nbsp;
                                                &nbsp;
                                                <span>
                                                    <b>
                                                        your app &nbsp;
                                                        &quot;
                                                        {this.props.appName}
                                                        &quot;
                                                        &nbsp;
                                                        is currently being certified
                                                    </b>
                                                </span>
                                                <span>
                                                    <p
                                                        style={{
                                                            paddingTop: '8px', fontSize: '11px'
                                                        }}
                                                    >
                                                        once this is complete, you will be able to submit for approval
                                                    </p>
                                                </span>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'table', width: '100%', marginBottom: '15px' }}>
                            <div className="sp-header">
                                <p>
                                    <b>
                                        {this.props.appName}
                                    </b>
                                </p>
                            </div>
                            <div className="sp-main" style={{ paddingLeft: '15px' }}>
                                <div
                                    style={{
                                        width: '60%', float: 'left', background: 'white', padding: '10px'
                                    }}
                                >
                                    &nbsp;
                                    &nbsp;
                                    {
                                        (this.state.isBannerVisible && (this.props.errorList === undefined))
                                            ? (
                                                <form className="spcloud-form" autoComplete="false" style={{ marginBottom: '15px' }}>
                                                    <div className="form-group">
                                                        <Banner
                                                            appearance="success"
                                                            dismissable
                                                            id="sbBannerSucess"
                                                            className="spbanneralign"
                                                        >
                                                            <p><span><b>Submission received</b></span></p>
                                                        </Banner>
                                                    </div>
                                                    <div className="form-group">
                                                        <span>
                                                            <p
                                                                style={{
                                                                    fontSize: '13px'
                                                                }}
                                                            >
                                                                Our app certifier team will be in contact within 48 hours to discuss next steps.In the meantime,if you need to review your product page,see the link below.
                                                            </p>
                                                        </span>
                                                    </div>
                                                    <div className="btn-row">
                                                        <a href="/Account/MyProfile?selectedTab=mysolutions">
                                                            <Button type="button" appearance="primary" className="btn-sp-cloud">My solutions</Button>
                                                        </a>
                                                    </div>
                                                </form>
                                            ) : (
                                                <form className="spcloud-form" autoComplete="false" style={{ marginBottom: '15px' }}>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') ? 'hidden' : ''}
                                                    >
                                                        <label htmlFor="version" className={!this.state.versionValid || this.state.versionError !== '' ? 'required-field-star-spc-tooltip error-text-label' : 'required-field-star-spc-tooltip'}>
                                                            Version for approval
                                                            {' '}
                                                            <ToolTip labelName="Assign product version number in the format x.x.x.x. The version number must be higher than the previous version number." />
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="form-version"
                                                            className={!this.state.versionValid || this.state.versionError !== '' ? 'form-control error-text' : 'form-control'}
                                                            placeholder="eg., 1.0.0.0"
                                                            onChange={(e) => this.changeHandler(e, 'version')}
                                                            value={this.state.version}
                                                            pattern="(?!0)\d+\.\d+\.\d+\.\d+"
                                                        />
                                                        <span className="error-text-label">
                                                            {
                                                                (this.state.versionError !== '') ? (
                                                                    <p>
                                                                        <span><AlertV2Icon width="15" fill="red" /></span>
                                                                        &nbsp;
                                                                        &nbsp;
                                                                        <span>{this.state.versionError}</span>
                                                                    </p>
                                                                ) : ''
                                                            }
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') ? 'hidden' : ''}
                                                    >
                                                        <span><b>Previous version: </b></span>
                                                        <span>{(this.props.getValidatedPluginLinkageList === undefined) ? 'None' : this.getPreviousVersion()}</span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') ? 'hidden' : ''}
                                                    >
                                                        <p>
                                                            <b>
                                                                Validated apps for approval
                                                            </b>
                                                        </p>
                                                        <p />
                                                        <p>
                                                            The below apps are what will be sent for approval.Check
                                                            &nbsp;
                                                            <Link
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                href="https://support.travelport.com/webhelp/TravelportMarketplace/Content/SolutionMangement.htm"
                                                                style={{
                                                                    textDecoration: 'underline'
                                                                }}
                                                            >
                                                                how to manage your solution
                                                            </Link>
                                                            &nbsp;, before continuing
                                                        </p>
                                                        {this.props.getValidatedPluginLinkageList !== undefined && this.props.getValidatedPluginLinkageList.plugins.map(item => (
                                                            <ul
                                                                style={{
                                                                    marginLeft: '-22px'
                                                                }}
                                                            >
                                                                <li>
                                                                    {item.pluginName}
                                                                    &nbsp;  -  version &nbsp;
                                                                    {item.pluginVersion}
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') && (this.props.getValidatedPluginLinkageList.plugins[0].isActive === false) ? '' : 'hidden'}
                                                    >
                                                        <p>
                                                            <b>
                                                                The following apps, for
                                                                &quot;
                                                                {this.props.appName}
                                                                ,&nbsp;
                                                                {(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion)}
                                                                &quot;
                                                                are being reviewed for approval.
                                                            </b>
                                                        </p>
                                                        <p />
                                                        {this.props.getValidatedPluginLinkageList !== undefined && this.props.getValidatedPluginLinkageList.plugins.map(item => (
                                                            <ul className="historyIcon">
                                                                <li>
                                                                    <svg viewBox="0 0 24 24" width="24px" height="24px" data-atlas-ui="icon" class="atlas-ui">
                                                                        <path fill="#276BBE" d="M13.5 3C8.53 3 4.5 7.03 4.5 12H1.5L5.39 15.89L5.46 16.03L9.5 12H6.5C6.5 8.13 9.63 5 13.5 5C17.37 5 20.5 8.13 20.5 12C20.5 15.87 17.37 19 13.5 19C11.57 19 9.82 18.21 8.56 16.94L7.14 18.36C8.77 19.99 11.01 21 13.5 21C18.47 21 22.5 16.97 22.5 12C22.5 7.03 18.47 3 13.5 3ZM12.5 8V13L16.75 15.52L17.52 14.24L14 12.15V8H12.5Z" />
                                                                    </svg>
                                                                    <p className="text-margin">
                                                                        {item.pluginName}
                                                                        &nbsp;  -  version &nbsp;
                                                                        {item.pluginVersion}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') && (this.props.getValidatedPluginLinkageList.plugins[0].isActive === true) && (this.props.isPublished === false) ? '' : 'hidden'}
                                                    >
                                                        <p>
                                                            <b>
                                                                The following apps have been approved.
                                                                &quot;
                                                                {this.props.appName}
                                                                ,&nbsp;
                                                                {(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion)}
                                                                &quot;
                                                                is now ready to be published.
                                                            </b>
                                                        </p>
                                                        <p />
                                                        <p>
                                                            <b>
                                                                How to publish your solution:
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Visit the &nbsp;&quot;My Solutions&quot;&nbsp;
                                                            button below, find your solution, select the &nbsp;&quot;Manage Solution&quot;&nbsp;
                                                            dropdown, and select &nbsp;&quot;Publish&quot;&nbsp;
                                                            .
                                                        </p>
                                                        {this.props.getValidatedPluginLinkageList !== undefined && this.props.getValidatedPluginLinkageList.plugins.map(item => (
                                                            <ul className="checkCircleIcon">
                                                                <li>
                                                                    <svg viewBox="0 0 24 24" width="24px" height="24px" data-atlas-ui="icon" class="atlas-ui">
                                                                        <path fill="#08890D" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
                                                                    </svg>
                                                                    <p className="text-margin">
                                                                        {item.pluginName}
                                                                        &nbsp;  -  version &nbsp;
                                                                        {item.pluginVersion}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-22px'
                                                        }}
                                                        className="form-group"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') && (this.props.getValidatedPluginLinkageList.plugins[0].isActive === true) && (this.props.isPublished === true) ? '' : 'hidden'}
                                                    >
                                                        <p>
                                                            <b>
                                                                The following apps have been approved.
                                                                &quot;
                                                                {this.props.appName}
                                                                ,&nbsp;
                                                                {(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion)}
                                                                &quot;
                                                                has been published.
                                                            </b>
                                                        </p>
                                                        <p />
                                                        {this.props.getValidatedPluginLinkageList !== undefined && this.props.getValidatedPluginLinkageList.plugins.map(item => (
                                                            <ul className="checkCircleIcon">
                                                                <li>
                                                                    <svg viewBox="0 0 24 24" width="24px" height="24px" data-atlas-ui="icon" class="atlas-ui">
                                                                        <path fill="#08890D" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
                                                                    </svg>
                                                                    <p className="text-margin">
                                                                        {item.pluginName}
                                                                        &nbsp;  -  version &nbsp;
                                                                        {item.pluginVersion}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </div>
                                                    &nbsp;
                                                    &nbsp;
                                                    <div
                                                        style={{
                                                            marginLeft: '-15px'
                                                        }}
                                                        className="btn-row"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') ? 'hidden' : ''}
                                                    >
                                                        <Button type="button" appearance="primary" className="btn-sp-cloud" onClick={this.submitPluginApproval}>Submit for approval</Button>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '-15px'
                                                        }}
                                                        className="btn-row"
                                                        hidden={(this.props.getValidatedPluginLinkageList !== undefined) && (this.props.getValidatedPluginLinkageList.currentVersion !== '') && (this.props.getValidatedPluginLinkageList.currentVersion !== 'None') ? '' : 'hidden'}
                                                    >
                                                        <a href="/Account/MyProfile?selectedTab=mysolutions">
                                                            <Button type="button" appearance="primary" className="btn-sp-cloud">My solutions</Button>
                                                        </a>
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    )}
            </>
        );
    }
}

SpCloudPlugin.propTypes = {
    fetchGitPluginLinkageDataAction: PropTypes.func,
    savePluginLinkageDataForCertificationAction: PropTypes.func,
    appId: PropTypes.number,
    tabId: PropTypes.number,
    appName: PropTypes.string,
    email: PropTypes.string,
    isPublished: PropTypes.bool
};

const mapStateToProps = state => ({
    getValidatedPluginLinkageList: state.gitSPCPluginLinkageInfo.getValidatedPluginLinkageData,
    getSubmitForApprovalPluginList: state.gitSPCPluginLinkageInfo.getSubmitForApprovalPlugin,
    fetchPluginError: state.gitSPCPluginLinkageInfo.fetchPluginError,
    errorList: state.gitSPCPluginLinkageInfo.errorList,
    email: state.oidc.user ? state.oidc.user.profile.email : ''
});

const mapDispatchToProps = dispatch => ({
    fetchGitPluginLinkageDataAction: (args, callbackplugin) => dispatch(fetchGitPluginLinkageData(args, callbackplugin)),
    savePluginLinkageDataForCertificationAction: (args, callback) => dispatch(savePluginLinkageDataForCertification.base(args, callback))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpCloudPlugin);
