/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'Styles/css/tablesorter.custom-theme.css';
import ToolTip from 'components/common/ToolTip';
import {
  ListSelector,
  exportToExcel,
} from 'reducers/modules/Administration/OrganizationDirectory/middleware';
import {
  getChildOrgSelector
} from 'reducers/modules/Administration/OrganizationDirectory/getChildOrgs';

import ListItem from './ListItem';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOnColumn: '',
      sortAsc: false,
    };
  }

  setClassName = param => {
    let classCss = '';
    if (this.state.sortOnColumn === param && this.state.sortAsc === true) {
      classCss = ' tablesorter-headerAsc';
    }

    if (this.state.sortOnColumn === param && this.state.sortAsc === false) {
      classCss = ' tablesorter-headerDesc';
    }
    return classCss;
  };

  exportToExcel = (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = {
      searchString: this.props.organizationsParamsList.searchString,
      filter: this.props.organizationsParamsList.filter,
      sortOnColumn: this.props.organizationsParamsList.sortOnColumn,
      sortAsc: true,
      currentPage: this.props.organizationsParamsList.currentPage
    };
    this.props.exportToExcel(params);
  };

  handleOrder = (e, data) => {
    e.preventDefault();
    let tmpOrder = {};
    if (data === this.state.sortOnColumn) {
      tmpOrder = { sortOnColumn: data, sortAsc: !this.state.sortAsc };
    } else {
      tmpOrder = { sortOnColumn: data, sortAsc: true };
    }
    this.setState({ ...tmpOrder });
    this.props.onSetOrder(tmpOrder);
  };

  render() {
    const renderListItem = rows => rows.map((item, i) => {
      let currentRow = false;
      if (typeof item.publicGuid !== 'undefined') {
        if (this.props.onGetChild && this.props.onGetChild.length === 2
          && this.props.childOrganisations && i === this.props.position && item.child === undefined) {
          // eslint-disable-next-line no-param-reassign
          item = {
            ...item,
            child: this.props.childOrganisations
          };
          currentRow = true;
        }
        return (
          <ListItem
            key={item.publicGuid}
            item={item}
            currentRow={currentRow}
            onGetChild={row => this.props.onGetChild(row, i)}
            onSetVisiblity={row => this.props.onSetVisiblity(row)}
            onViewClicked={(id, name) => this.props.onViewClicked(id, name)}
          />
        );
      }
      return null;
    });

    return (
      <div className={this.props.className}>
        {/* Download Excel Section */}
        <div className="row text-right ajax-dependent-div">
          <Link to="#" id="downloadExcel" className="text-link-with-icon-left" onClick={this.exportToExcel}>
            <span className="download-icon" />
            Download in Microsoft Excel
          </Link>
        </div>

        <table
          id="organisation-table"
          className="tablesorter tablesorter-travelport tablesorter-odd-even"
        >
          <thead>
            <tr>
              <th className="sorter-false td-organizationdirectory-carat-width tablesorter-header">
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th
                className={`col-md-2 tablesorter-header ${this.setClassName(
                  'name'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'name');
                }}
              >
                Organization Name
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th
                className={`col-md-2 tablesorter-header ${this.setClassName(
                  'CustomerNumber'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'CustomerNumber');
                }}
              >
                Customer Number
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th className="sorter-false tablesorter-header">
                Organization Admins
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th className="sorter-false tablesorter-header">
                PCC
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th
                className={`col-md-2 tablesorter-header ${this.setClassName(
                  'PrimaryCountry.Name'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'PrimaryCountry.Name');
                }}
              >
                Country
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th
                className={`col-md-1 tablesorter-header ${this.setClassName(
                  'CustomerType'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'CustomerType');
                }}
              >
                Organization Type
                <span title="" name="tooltip" data-original-title="" />
              </th>
              <th
                className={`col-md-1 tablesorter-header ${this.setClassName(
                  'PrimaryAgency'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'PrimaryAgency');
                }}
              >
                Primary Agency&nbsp;
                <ToolTip labelName="This column shows you if the orgaization is considered Parent or child" />
              </th>
              <th
                className={`col-md-1 tablesorter-header ${this.setClassName(
                  'Visible'
                )}`}
                onClick={e => {
                  this.handleOrder(e, 'Visible');
                }}
              >
                Display&nbsp;
                <ToolTip labelName={'If the organization is classified as a Developer, then the check-box in this column will allow you control whether or not the '
                  + 'Organization is displayed on the Travelport Marketplace website. If the check-box is selected, the Developer Organization will be active on the website'}
                />
              </th>
              <th>
                Developer Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {renderListItem(this.props.rows)}
          </tbody>
        </table>
      </div>
    );
  }
}

List.propTypes = {
  organizationsParamsList: PropTypes.object,
  rows: PropTypes.array,
  position: PropTypes.number,
  className: PropTypes.string,
  onGetChild: PropTypes.func,
  onSetOrder: PropTypes.func,
  onSetVisiblity: PropTypes.func,
  onViewClicked: PropTypes.func,
  childOrganisations: PropTypes.array,
  exportToExcel: PropTypes.func
};

const mapStateToProps = (state) => ({
  List: ListSelector(state),
  childOrganisations: getChildOrgSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  exportToExcel: (args) => dispatch(exportToExcel.base(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
