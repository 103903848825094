import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import authorizationRules from 'components/Authorization/authorizationRules';
import Auth from 'components/Authorization/auth';

class AuthorizationChildren extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { disableParam: true, disableClass: 'disable' };
  }

  UNSAFE_componentWillMount = () => {
    const auth = this.createAuth();

    if (auth.checkRole(this.props.user)) {
      this.setState({ disableParam: false, disableClass: '' });
    }
  }

  createAuth = () => new Auth(
    this.props.user,
    authorizationRules,
    { path: false },
    this.props.buttonRef,
    this.props.buttonRef
  );

  render() {
    const { disableParam, disableClass } = this.state;

    return (
      <>
        {this.props.children(disableParam, disableClass)}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    view: state.view
  };
}

AuthorizationChildren.propTypes = {
  children: PropTypes.func,
  user: PropTypes.object,
  buttonRef: PropTypes.string
};

export default connect(
  mapStateToProps,
  null
)(AuthorizationChildren);
