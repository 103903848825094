import { takeLatest, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

const SHOW_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';

export const showAlertAction = (message, timeout = 2000) => ({
  type: SHOW_ALERT,
  payload: {
    message,
    timeout
  }
});

export const hideAlertAction = () => ({
  type: HIDE_ALERT,
});

export default function alertPopUpReducer(state = { showAlert: false }, action = {}) {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        showAlert: true,
        timeout: action.payload.timeout,
        message: action.payload.message
      };
    case HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
        message: '',
      };
    default:
      return state;
  }
}

export function* errorSaga(action) {
  yield delay(action.payload.timeout);
  yield put(hideAlertAction());
}

export function* watchErrorSaga() {
  yield takeLatest(SHOW_ALERT, errorSaga);
}
