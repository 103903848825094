/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import 'react-select/dist/react-select.css';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-bootstrap4';

import './style.css';

let index = 0;

const CommandButton = ({
  onExecute, text, hint, className
}) => (
  <button
    type="button"
    className="btn btn-link"
    onClick={e => {
      onExecute();
      e.stopPropagation();
    }}
    title={hint}
  >
    <span className={className}>{text}</span>
  </button>
);

CommandButton.propTypes = {
  onExecute: PropTypes.func,
  text: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string
};

const commandComponentProps = {
  add: {
    type: 'add',
    text: 'Add admin',
    hint: 'Add admin',
    className: 'btn_add'
  },
  delete: {
    type: 'delete',
    icon: 'trash',
    hint: 'Delete row',
    className: 'cancel-icon'
  },
  commit: {
    type: 'commit',
    text: 'Save',
    hint: 'Save changes'
  },
  cancel: {
    type: 'cancel',
    text: 'Cancel',
    hint: 'Cancel changes'
  }
};

export default class ManageAdminsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      originalData: { users: [], admins: [] },
      data: { users: [], admins: [] },
      columns: [
        { name: 'name', title: 'Name' },
        { name: 'userName', title: 'UserName' },
        { name: 'email', title: 'Email' }
      ],
      rows: [],
      optionsUser: [],
      selectedOption: {},
      itemToDelete: {},
      editing: false
    };
  }

  componentDidMount = () => {
    document.title = 'Manage Admin';
    this.setData();
  };

  componentDidUpdate = () => {
    if (this.props.adminsForOrgs !== this.state.originalData) {
      this.setData();
    }
  };

  setData = () => {
    this.setState(
      {
        originalData: this.props.adminsForOrgs,
        data: this.props.adminsForOrgs
      },
      () => this.initData()
    );
  };

  saveAdmin = item => {
    const params = { askTravelportId: item.userName, orgId: this.props.orgId };
    return this.props.saveAdmin(params);
  };

  unassignAdminRoleForUser = item => {
    this.props.unassignAdminRoleForUser(item);
  };

  initData = () => {
    const transformIdLowCaseAdmins = this.transformInternalId(
      this.state.data.admins
    );
    const transformIdLowCaseUsers = this.transformInternalId(
      this.state.data.users
    );

    const optionsUser = this.prepareSelectOptions(transformIdLowCaseUsers);
    this.setState({ rows: transformIdLowCaseAdmins, optionsUser });
  };

  prepareSelectOptions = data => data.map(item => ({ value: item, label: item.name }));

  transformInternalId = data => {
    const result = data.map(item => {
      const itemTmp = item;
      if (typeof itemTmp.internalId === 'undefined') {
        itemTmp.internalId = item.id;
      }

      index += 1;
      itemTmp.id = index;
      return itemTmp;
    });
    return result;
  };

  addItem = (item, type) => {
    const { data } = this.state;
    data[type].push(item);

    this.setState({ data });
  };

  removeItem = (item, type) => {
    const result = this.state.data[type].filter(
      removeItem => removeItem.id !== item.id
    );

    const { data } = this.state;
    data[type] = result;
    this.setState({ data, selectedOption: {} });
  };

  commitChanges = ({ added, deleted }) => {
    if (added) {
      if (Object.keys(this.state.selectedOption).length) {
        const newItem = this.state.data.users.filter(
          item => item.id === this.state.selectedOption.value.id
        )[0];

        this.addItem(newItem, 'admins');
        this.removeItem(newItem, 'users');
        this.saveAdmin(newItem);
      }
    }

    if (deleted) {
      const itemToDelete = this.state.data.admins.filter(
        item => item.id === deleted[0]
      )[0];
      this.setState({ itemToDelete });
      this.unassignAdminRoleForUser(itemToDelete);
    }

    this.initData();
  };

  handleOnchangeName = selectedOption => {
    this.setState({ selectedOption });
  };

  rederCell = (props, param) => {
    if (this.state.selectedOption.value) {
      return <Table.Cell>{this.state.selectedOption.value[param]}</Table.Cell>;
    }

    return <Table.Cell />;
  };

  rederSelect = props => {
    const style = {
      overflow: 'unset',
      width: '100px'
    };
    return (
      <Table.Cell style={style}>
        <Select
          className="travelport-select travelport-select-manage-admins"
          clearable={false}
          name="form-field-name"
          value={this.state.selectedOption}
          options={this.state.optionsUser}
          onChange={e => {
            this.handleOnchangeName(e);
            props.onValueChange(e.value.name);
          }}
        />
      </Table.Cell>
    );
  };

  Command = ({ id, onExecute }) => (
    <span className={`commandBtn-${id} `}>
      <CommandButton
        {...commandComponentProps[id]}
        onExecute={onExecute}
        that={this}
      />
    </span>
  );

  cellComponent = cell => {
    if (cell.column.name === 'name') {
      return (
        <Table.Cell>
          <Link to={`/Profile/UserProfile/${cell.row.internalId}`}>
            {cell.value}
          </Link>
        </Table.Cell>
      );
    }

    return <Table.Cell>{cell.value}</Table.Cell>;
  };

  changeAddedRows = addedRows => {
    this.setState({ editing: addedRows.length });
  };

  render() {
    const getRowId = row => row.id;
    let showAddUserBtn = this.state.data.users.length > 0;

    if (this.state.editing) {
      showAddUserBtn = false;
    }

    const Cell = props => {
      if (props.column.name === 'userName') {
        return this.rederCell(props, 'userName');
      }

      if (props.column.name === 'email') {
        return this.rederCell(props, 'email');
      }

      if (props.column.name === 'name') {
        return this.rederSelect(props);
      }

      return <Table.Cell {...props} />;
    };

    const { rows, columns } = this.state;
    const classNoContent = rows.length === 0 ? 'no-content' : '';

    const noDataCell = ({ colSpan }) => (
      <td colSpan={colSpan} className="text-center td-no-content">
        No agency admins have been assigned.
      </td>
    );

    return (
      <div className="row">
        <div className="row">
          <span>
            If you wish to assign a Marketplace User as an Admin for this
            organization, they must log into Travelport Marketplace at least
            once before they will be available to select as an Admin.
          </span>
        </div>
        <div className="row member-list">
          <div className="agency-admins-table-header">
            <div
              id="manage-admins-table"
              className={`${classNoContent} tablesorter tablesorter-travelport containsStickyHeaders`}
            >
              <Grid rows={rows} columns={columns} getRowId={getRowId}>
                <EditingState
                  onCommitChanges={this.commitChanges}
                  onAddedRowsChange={this.changeAddedRows}
                />
                <Table noDataCellComponent={noDataCell} cellComponent={this.cellComponent} />

                <TableHeaderRow />
                <TableEditRow cellComponent={Cell} />
                <TableEditColumn
                  showAddCommand={showAddUserBtn}
                  showDeleteCommand
                  commandComponent={this.Command}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ManageAdminsForm.propTypes = {
  text: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string,
  adminsForOrgs: PropTypes.object,
  saveAdmin: PropTypes.func,
  unassignAdminRoleForUser: PropTypes.func,
  orgId: PropTypes.number,
  organizationName: PropTypes.string
};

ManageAdminsForm.defaultProps = {
  adminsForOrgs: { admins: [], users: [] }
};
