import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  KnobComponent
} from 'components/Application';
import {
  getProductPageCompletionSelector, activatedSelector, publishedSelector, reviewSelector
} from 'reducers/modules/Application/selectors';

const ReviewSubmitCompleted = ({
  isProductPageComplete, reviewData, isActivated, isPublished
}) => (
  <>
    {
      isActivated || isPublished
        ? (
          <>
            <h2>Your Product Update has been submitted for Review</h2>
            <h3>Thank you for your update</h3>
          </>
        )
        : (
          <>
            <h2>Your Product has been created</h2>
            <h3>Thank you for your submission</h3>
          </>
        )
    }
    <div className="row">
      <div className="col-sm-3">
        {reviewData && (
        <KnobComponent
          height={120}
          innerRadius={42}
          radius={55}
          width={113}
          completed={isProductPageComplete}
          percentageCompletion={reviewData.percentageCompletion}
        />
        )}
      </div>
    </div>
    {
      isActivated || isPublished
        ? (
          <>
            <div className="row">
              <p>
                Thank you for your product update. Please allow up to 3 working days for review and response.
                If you make additional changes, you will have to resubmit your product for approval. If you have updated your
                product version, the previous version of the product will be available on the product page until the new version
                is approved.
              </p>
            </div>
          </>
        )
        : (
          <>
            <div className="row">
              <p>
                Your product has been submitted for inclusion in Marketplace by Travelport.
                Please allow up to 3 working days for review and response.
                During this time, if you wish to make any changes, you will be able to access your information.
                However, you will have to resubmit your product for approval after making changes.
              </p>
              <p>Thank you for your interest in Marketplace, powered by Travelport.</p>
            </div>
          </>
        )
    }
  </>
);

ReviewSubmitCompleted.propTypes = {
  isProductPageComplete: PropTypes.bool,
  percentageCompletion: PropTypes.number,
  isActivated: PropTypes.bool,
  isPublished: PropTypes.bool,
  reviewData: PropTypes.object
};

const mapStateToProps = state => ({
  isProductPageComplete: getProductPageCompletionSelector(state),
  reviewData: reviewSelector(state),
  isActivated: activatedSelector(state),
  isPublished: publishedSelector(state)
});

export default connect(mapStateToProps)(ReviewSubmitCompleted);
