/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CountryName = (props) => (
  <>
    {(props.countryIds === props.id) ? <span>{props.name}</span> : null}
  </>
);

const CountryList = (props) => (
  <div>
    <div className="row additional-location">
      {props.countries && props.countries.map((country, index) => (
        <CountryName
          key={index.toString()}
          countryIds={props.countryIds}
          {...country}
        />
      ))}
      <Link to="#" onClick={() => props.removeAdditionalCountry(props.countryIds)}>
        <span className="cancel-icon" />
        {' '}
        Remove
      </Link>
    </div>
  </div>
);

const LocationList = (props) => {
  if (!props.fields) {
    return null;
  }
  return (
    props.fields.map((countryIds, index) => (
      <CountryList
        key={index.toString()}
        countryIds={countryIds}
        countries={props.countries}
        removeAdditionalCountry={(countryId) => props.removeAdditionalCountry(countryId)}
      />
    ))
  );
};

CountryList.propTypes = {
  countryIds: PropTypes.number,
  removeAdditionalCountry: PropTypes.func,
  countries: PropTypes.array
};

CountryName.propTypes = {
  countryIds: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string
};

LocationList.propTypes = {
  fields: PropTypes.array,
  countries: PropTypes.array,
  removeAdditionalCountry: PropTypes.func,
};

export default LocationList;
