import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

export const agencyProfilePublicGuidSelector = state => state.profile.getAgencyProfile.data.publicId;

export const GET_AGENCY_PROFILE = createFetchTypes(
  'Marketplace/Profile/Agency/AgencyDetails/GET_AGENCY_PROFILE'
);

export const getAgencyProfile = {
  base: params => ({
    type: GET_AGENCY_PROFILE.BASE,
    url: 'profile/getagencyprofiledetails',
    payload: {
      params
    }
  }),
  request: () => ({
    type: GET_AGENCY_PROFILE.REQUEST
  }),
  success: data => ({
    type: GET_AGENCY_PROFILE.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: GET_AGENCY_PROFILE.FAILURE,
    payload: {
      error
    }
  })
};

export const getAgencyProfileReducer = (
  state = {
    data: {
      publicId: null
    }
  },
  action = {}
) => {
  switch (action.type) {
    case GET_AGENCY_PROFILE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: {}
      };

    case GET_AGENCY_PROFILE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data
      };

    case GET_AGENCY_PROFILE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* getAgencyProfileReducerWatcher(action) {
  try {
    const { url } = action;
    const params = { agencyId: action.payload.params };
    yield call(() => fetchEntity(getAgencyProfile, ApiClient.httpClient.get, url, { params }));
  } catch (error) {
    yield put(showAlertAction(error.message));
  }
}

export default function* watchGetAgencyProfileSaga() {
  yield takeLatest(GET_AGENCY_PROFILE.BASE, getAgencyProfileReducerWatcher);
}
