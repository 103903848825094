const MarketplaceTeamAdmin = { id: 1, name: 'MarketplaceTeamAdmin', text: 'Marketplace Team Admin' };
const TPAdmin = { id: 2, name: 'TPAdmin', text: 'TP Admin' };
const DeveloperUser = { id: 3, name: 'DeveloperUser', text: 'Developer User' };
const AgencyAdmin = { id: 4, name: 'AgencyAdmin', text: 'Agency Admin' };
const GeneralUser = { id: 9, name: 'GeneralUser', text: 'General User' };
const TPPlatformProductAdmin = { id: 10, name: 'TPPlatformProductAdmin', text: 'TP Platform Product Admin' };
const TPSupport = { id: 11, name: 'TPSupport', text: 'TP Support' };
const AgencySuperAdmin = { id: 12, name: 'AgencySuperAdmin', text: 'Agency Super Admin' };
const TPApplicationCertifier = { id: 13, name: 'TPApplicationCertifier', text: 'TP Application Certifier' };
const SPCDeveloperUser = { id: 14, name: 'SPCDeveloperUser', text: 'SPC Developer User' };
export const roleNameConstants = {
  MarketplaceTeamAdmin,
  TPAdmin,
  DeveloperUser,
  AgencyAdmin,
  GeneralUser,
  TPPlatformProductAdmin,
  TPSupport,
  AgencySuperAdmin,
  TPApplicationCertifier,
  SPCDeveloperUser
};

export const rolesArrayForManageRoles = [
  GeneralUser,
  AgencyAdmin,
  TPApplicationCertifier,
  AgencySuperAdmin,
  TPAdmin,
  DeveloperUser,
  TPPlatformProductAdmin,
  TPSupport,
  MarketplaceTeamAdmin,
  SPCDeveloperUser
];

// This is the list of permissible roles for Non Travelport Users
export const rolesArrayForNonTravelportUsers = [
  GeneralUser,
  AgencyAdmin,
  AgencySuperAdmin,
  DeveloperUser,
  SPCDeveloperUser,
  TPPlatformProductAdmin,
  TPSupport
];
