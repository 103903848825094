import { combineReducers } from 'redux';
import { put, all } from 'redux-saga/effects';
import licenseInformation from './LicenseInformation/licenseInformation';
import members, {
  addMemberAssignments,
  removeMemberAssignments,
} from './Members/members';
import pccs, {
  addPccAssignments,
  removePccAssignments,
} from './Pccs/pccs';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export {
  LICENSE_INFORMATION,
  getlicenseInformationSaga,
  getlicenseInformation,
  updateAssignedSeatSiteLicenses,
  UpdateSpcUserAccessSaga,
  updatelicenseSpcAccess,
  IS_SPC_USER_ACCESS
} from './LicenseInformation/licenseInformation';

export {
  GET_ASSIGNED_MEMBERS,
  GET_UNASSIGNED_MEMBERS,
  ADD_MEMBER_ASSIGNMENTS,
  REMOVE_MEMBER_ASSIGNMENTS,
  getAssignedMembers,
  getUnassignedMembers,
  memberSelection,
  addMemberAssignments,
  removeMemberAssignments,
  getAssignedMembersSaga,
  getUnassignedMembersSaga,
  addMemberAssignmentsSaga,
  removeMemberAssignmentsSaga,
} from './Members/members';

export {
  GET_ASSIGNED_PCCS,
  GET_UNASSIGNED_PCCS,
  ADD_PCC_ASSIGNMENTS,
  REMOVE_PCC_ASSIGNMENTS,
  getAssignedPccs,
  getUnassignedPccs,
  pccSelection,
  addPccAssignments,
  removePccAssignments,
  getAssignedPccsSaga,
  getUnassignedPccsSaga,
  addPccAssignmentsSaga,
  removePccAssignmentsSaga,
} from './Pccs/pccs';

export const ADD_ASSIGNMENTS = 'Marketplace/admin/licenses/addAssignments';
export const REMOVE_ASSIGNMENTS = 'Marketplace/admin/licenses/removeAssignments';

export const addAssignments = () => ({
  type: ADD_ASSIGNMENTS
});

export const removeAssignments = () => ({
  type: REMOVE_ASSIGNMENTS
});

export function* addAssignmentsSaga() {
  try {
    yield all([
      put(addMemberAssignments.base()),
      put(addPccAssignments.base()),
    ]);
    yield put(showAlertAction('Assignment/s added successfully.'));
  } catch (errorMessage) {
    yield put(showAlertAction(errorMessage));
  }
}

export function* removeAssignmentsSaga() {
  try {
    yield all([
      put(removeMemberAssignments.base()),
      put(removePccAssignments.base()),
    ]);
    yield put(showAlertAction('Assignment/s removed successfully.'));
  } catch (errorMessage) {
    yield put(showAlertAction(errorMessage));
  }
}

const licenseAssignments = combineReducers({
  licenseInformation,
  members,
  pccs
});

export default licenseAssignments;
