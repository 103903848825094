import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import fetchEntity from '../../../utils/fetchEntitySaga';

const USER_PROFILE_NAVIGATION = createFetchTypes('Marketplace/auth/userProfileNavigation');

function userNavigationReducer(state = { completed: true }, action = {}) {
  switch (action.type) {
    case USER_PROFILE_NAVIGATION.REQUEST:
      return state;
    case USER_PROFILE_NAVIGATION.SUCCESS:
      return {
        ...state,
        completed: action.payload
      };
    case USER_PROFILE_NAVIGATION.FAILURE:
      return state;
    default:
      return state;
  }
}

export const userProfileNavigationAction = {
  base: (history, callbackRouter) => ({
    type: USER_PROFILE_NAVIGATION.BASE,
    url: 'profile/userprofilerequiredfieldscheck',
    history,
    callbackRouter
  }),
  request: () => ({
    type: USER_PROFILE_NAVIGATION.REQUEST
  }),
  success: (data) => ({
    type: USER_PROFILE_NAVIGATION.SUCCESS,
    payload: data
  }),
  failure: (error) => ({
    type: USER_PROFILE_NAVIGATION.FAILURE,
    payload: {
      error: error.response && error.response.data ? error.response.data.errorMessage : 'Error'
    }
  })
};

export function* userProfileNavigationActionSaga(action) {
  try {
    const result = yield call(() => fetchEntity(userProfileNavigationAction, ApiClient.httpClient.get, action.url));
    if (!result.data) {
      action.history.push('/Account/MyProfile');
    } else {
      action.history.push(action.callbackRouter);
    }
  } catch (error) {
    yield put(userProfileNavigationAction.failure(error));
  }
}

export function* watchuserProfileNavigationActionSaga() {
  yield takeLatest(USER_PROFILE_NAVIGATION.BASE, userProfileNavigationActionSaga);
}

export default userNavigationReducer;
