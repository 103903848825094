import {
  takeLatest, put, call, all, select, take, race
} from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import { getAllCountries } from 'reducers/modules/countries';
import { getAllRegions } from 'reducers/modules/regions';
import updateStateArray from 'reducers/utils/updateStateArray';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';

const GET_INITIAL_DATA_BASE = 'GET_INITIAL_DATA_BASE';
export const GET_SETTINGS = createFetchTypes('Marketplace/Administration/GET_SETTINGS');
export const GET_USERS_ID = createFetchTypes('Marketplace/Administration/GET_USERS_ID');
export const GET_PCC = createFetchTypes('Marketplace/Administration/GET_PCC');
export const GET_ALL_GROUPS = createFetchTypes('Marketplace/Administration/GET_ALL_GROUPS');
export const ON_SAVE = createFetchTypes('Marketplace/Administration/ON_SAVE');
export const ON_DELETE = createFetchTypes('Marketplace/Administration/ON_DELETE');
export const TOGGLE_ACTIVATION = createFetchTypes('Marketplace/Administration/TOGGLE_ACTIVATION');
export const EXPORT_SMARTPOINT_CONFIGURATION = createFetchTypes('Marketplace/Administration/EXPORT_SMARTPOINT_CONFIGURATION');
export const GET_SETTINGS_VALUES = createFetchTypes('Marketplace/Administration/GET_SETTINGS_VALUES');

function smartpointConfigSettings(state = {}, action = {}) {
  switch (action.type) {
    case GET_SETTINGS.REQUEST:
    case ON_SAVE.REQUEST:
    case ON_DELETE.REQUEST:
    case TOGGLE_ACTIVATION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTINGS_VALUES.REQUEST: {
      return {
        ...state
      };
    }
    case GET_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload.settings.data.configurationViewModels,
        settingsAttributes: action.payload.settings.data.settingAttributes,
        smartpointSettings: action.payload.settings.data.smartpointSettings,
        countries: action.payload.countries.data,
        regions: action.payload.regions.data,
        groups: action.payload.groups.data,
        loading: false,
      };
    case GET_USERS_ID.SUCCESS:
      return {
        ...state,
        usersID: action.payload
      };
    case GET_SETTINGS_VALUES.SUCCESS:
      return {
        ...state,
        settingValues: action.payload
      };
    case GET_PCC.SUCCESS:
      return {
        ...state,
        pccList: action.payload
      };
    case ON_SAVE.SUCCESS: {
      const index = action.payload.data.updatedRows ? state.pagedCollection.findIndex((row) => row.id === action.payload.data.updatedRows.row.id) : -1;
      if (index === 0) {
        const newRow = { ...action.payload.request, userFullName: action.payload.result.data.userFullName };
        const arr = [
          newRow,
          ...state.pagedCollection.filter(item => item.id !== action.payload.result.data.id)
        ];
        return {
          ...state,
          pagedCollection: arr,
        };
      }
      if (index > 0) {
        return {
          ...state,
          pagedCollection: updateStateArray(state.pagedCollection, index, {
            ...state.pagedCollection[index],
            ...action.payload.request,
            userFullName: action.payload.result.data.userFullName
          }),
        };
      }
      const arr = [{
        ...action.payload.request,
        id: action.payload.result.data.id,
        userFullName: action.payload.result.data.userFullName
      },
      ...state.pagedCollection,
      ];
      return {
        ...state,
        pagedCollection: arr,
      };
    }
    case ON_DELETE.SUCCESS:
      return {
        ...state,
        pagedCollection: state.pagedCollection.filter(item => item.id !== action.payload.id),
        loading: false,
      };
    case TOGGLE_ACTIVATION.SUCCESS: {
      const index = state.pagedCollection.findIndex((row) => row.id === action.payload.data.id);
      if (index === 0) {
        const obj = {
          ...state.pagedCollection[0],
          activated: !state.pagedCollection[index].activated,
        };
        const arr = [
          obj,
          ...state.pagedCollection.filter(item => item.id !== action.payload.data.id)
        ];
        return {
          ...state,
          pagedCollection: arr,
          loading: false,
        };
      }
      return {
        ...state,
        pagedCollection: updateStateArray(state.pagedCollection, index, {
          activated: !state.pagedCollection[index].activated,
        }),
        loading: false,
      };
    }
    case GET_SETTINGS.FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case GET_SETTINGS_VALUES.FAILURE:
      return state;
    case GET_USERS_ID.FAILURE:
      return state;
    case GET_PCC.FAILURE:
      return state;
    case ON_SAVE.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ON_DELETE.FAILURE:
    case TOGGLE_ACTIVATION.FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export const getInitialData = (data) => ({
  type: GET_INITIAL_DATA_BASE,
  data
});

export const getSettings = {
  base: data => ({
    type: GET_SETTINGS.BASE,
    url: 'configuration/getpagedconfigurations',
    data
  }),
  request: () => ({ type: GET_SETTINGS.REQUEST }),
  success: payload => ({ type: GET_SETTINGS.SUCCESS, payload }),
  failure: error => ({ type: GET_SETTINGS.FAILURE, error }),
};

export const getUsersID = {
  base: data => ({
    type: GET_USERS_ID.BASE,
    url: 'Configuration/GetUsers',
    data
  }),
  request: () => ({ type: GET_USERS_ID.REQUEST }),
  success: payload => ({ type: GET_USERS_ID.SUCCESS, payload }),
  failure: error => ({ type: GET_USERS_ID.FAILURE, error }),
};

export const getSettingValues = {
  base: data => ({
    type: GET_SETTINGS_VALUES.BASE,
    url: 'Configuration/getsettingvalues',
    data
  }),
  request: () => ({ type: GET_SETTINGS_VALUES.REQUEST }),
  success: payload => ({ type: GET_SETTINGS_VALUES.SUCCESS, payload }),
  failure: error => ({ type: GET_SETTINGS_VALUES.FAILURE, error }),
};

export const getPccs = {
  base: data => ({
    type: GET_PCC.BASE,
    url: 'Configuration/GetPccs',
    data
  }),
  request: () => ({ type: GET_PCC.REQUEST }),
  success: payload => ({ type: GET_PCC.SUCCESS, payload }),
  failure: error => ({ type: GET_PCC.FAILURE, error }),
};

export const getAllGroups = {
  base: (data) => ({
    type: GET_ALL_GROUPS.BASE,
    url: 'configuration/getallgroups',
    data
  }),
  request: () => ({ type: GET_ALL_GROUPS.REQUEST }),
  success: payload => ({ type: GET_ALL_GROUPS.SUCCESS, payload }),
  failure: error => ({ type: GET_ALL_GROUPS.FAILURE, error }),
};

export const onSave = {
  base: data => ({
    type: ON_SAVE.BASE,
    url: 'configuration/saveconfiguration',
    data
  }),
  request: () => ({ type: ON_SAVE.REQUEST }),
  success: payload => ({ type: ON_SAVE.SUCCESS, payload }),
  failure: error => ({ type: ON_SAVE.FAILURE, error }),
};

export const onDelete = {
  base: data => ({
    type: ON_DELETE.BASE,
    url: 'configuration/removeconfiguration',
    data
  }),
  request: () => ({ type: ON_DELETE.REQUEST }),
  success: payload => ({ type: ON_DELETE.SUCCESS, payload }),
  failure: error => ({ type: ON_DELETE.FAILURE, error }),
};

export const toggleActivation = {
  base: data => ({
    type: TOGGLE_ACTIVATION.BASE,
    url: 'configuration/toggleconfigurationactivation',
    data
  }),
  request: () => ({ type: TOGGLE_ACTIVATION.REQUEST }),
  success: payload => ({ type: TOGGLE_ACTIVATION.SUCCESS, payload }),
  failure: error => ({ type: TOGGLE_ACTIVATION.FAILURE, error }),
};

export const exportSmartConfiguration = {
  base: (params) => ({
    type: EXPORT_SMARTPOINT_CONFIGURATION.BASE,
    url: 'configuration/exportconfigurationstoexcel',
    payload: {
      params
    }
  }),
  request: () => ({
    type: EXPORT_SMARTPOINT_CONFIGURATION.REQUEST
  }),
  success: () => ({
    type: EXPORT_SMARTPOINT_CONFIGURATION.SUCCESS,
  }),
  failure: (error) => ({
    type: EXPORT_SMARTPOINT_CONFIGURATION.FAILURE,
    payload: {
      error
    },
  })
};

const getQueryParamsSettings = (data) => ({
  params: {
    appPublicId: data.appPublicId,
    filter: data.filter || '',
    sortOnColumn: data.sortOnColumn,
    sortAsc: data.sortAsc,
    currentPage: data.currentPage,
    isPos: false
  }
});

function* getInitialDataSaga({ data }) {
  try {
    yield put(getSettings.request());
    const [countries, regions, settings, groups] = yield all([
      call(() => ApiClient.httpClient.get(getAllCountries.base().url)),
      call(() => ApiClient.httpClient.post(getAllRegions.base().url)),
      call(() => ApiClient.httpClient.get(getSettings.base().url, getQueryParamsSettings(data))),
      call(() => ApiClient.httpClient.get(getAllGroups.base().url)),
    ]);
    yield put(getSettings.success({
      countries,
      regions,
      settings,
      groups
    }));
    const settingValues = yield call(() => ApiClient.httpClient.get(getSettingValues.base().url, { params: { spSettingId: 1 } }));
    yield put(getSettingValues.success({
      settingValues: settingValues.data.settingValues,
      defaultSettingValue: settingValues.data.defaultSettingValue
    }));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getUsersIDSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getUsersID, ApiClient.httpClient.get, url, { params: { userName: data.value } }));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getPccsSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getPccs, ApiClient.httpClient.get, url, { params: { pccName: data.value } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getGroupsSaga({ url }) {
  try {
    yield call(() => fetchEntity(getAllGroups, ApiClient.httpClient.get, url));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

function* getSettingValuesSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getSettingValues, ApiClient.httpClient.get, url, { params: { spSettingId: data.value } }));
    if (data.successHandler) {
      data.successHandler();
    }
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}
function isActivated(value) {
  if (value.addedRows[0].activated) {
    return value.addedRows[0].activated;
  }
  return true;
}
function setAttributeText(settingAttribute) {
  if (settingAttribute && settingAttribute.text) {
    return settingAttribute.text;
  }
  return '';
}
function setSettingValue(value) {
  if (value.addedRows[0] && value.addedRows[0].settingValue) {
    return value.addedRows[0].settingValue;
  }
  return '';
}
function setSmartpointSettingText(smartpointSetting) {
  if (smartpointSetting && smartpointSetting.text) {
    return smartpointSetting.text;
  }
  return '';
}
function setAttributeValueOne(data) {
  if (data.addedRows[0].attributeValueOne) {
    return data.addedRows[0].attributeValueOne;
  }
  return null;
}
function setAttributeValueTwo(data) {
  if (data.addedRows[0].attributeValueTwo) {
    return data.addedRows[0].attributeValueTwo;
  }
  return null;
}
function setAttributeValueThree(data) {
  if (data.addedRows[0].attributeValueThree) {
    return data.addedRows[0].attributeValueThree;
  }
  return null;
}
function isRowActivated(data) {
  const hasRowActivated = data.updatedRows.activated || data.updatedRows.row.activated;
  return hasRowActivated;
}
function setAttributeValue(data, settingAttributes) {
  const attributeVal = (data.updatedRows.settingAttributeText && settingAttributes.find(c => c.text === data.updatedRows.settingAttributeText))
      || (data.updatedRows.row.settingAttributeText && settingAttributes.find(c => c.text === data.updatedRows.row.settingAttributeText));
  return attributeVal;
}
function setUpdatedRowAttributeText(data, settingAttributes) {
  const updatedRowAttributeText = data.updatedRows.settingAttributeText || settingAttributes.find(c => c.value === data.updatedRows.row.settingAttribute).text;
  return updatedRowAttributeText;
}
function setUpdatedRowSmartpointSetting(data, smartpointSettings) {
  const updatedRowSmartpointSetting = (data.updatedRows.smartpointSettingText && smartpointSettings.find(c => c.text === data.updatedRows.smartpointSettingText))
      || (data.updatedRows.smartpointSetting || smartpointSettings.find(c => c.value === data.updatedRows.row.smartpointSettingValue));
  return updatedRowSmartpointSetting;
}
function setUpdatedRowSmartpointSettingText(data, smartpointSettings) {
  const updatedRowSmatpointSettingText = data.updatedRows.smartpointSettingText || smartpointSettings.find(c => c.value === data.updatedRows.row.smartpointSettingValue).text;
  return updatedRowSmatpointSettingText;
}
function setUpdatedRowSettingValue(data) {
  const updatedRowSettingValue = data.updatedRows.settingValue !== null && data.updatedRows.settingValue !== undefined
    ? data.updatedRows.settingValue
    : data.updatedRows.row.settingValue;
  return updatedRowSettingValue;
}
function setAttributeValueOneWhenObjExists(data) {
  const attributeval = data.updatedRows.row.attributeValueOne || null;
  return attributeval;
}
function getAttributeValueOne(attributeValueOneObjExists, data) {
  let attributeValueOne;
  if (attributeValueOneObjExists) {
    attributeValueOne = data.updatedRows.attributeValueOne;
  } else {
    attributeValueOne = setAttributeValueOneWhenObjExists(data);
  }
  return attributeValueOne;
}
function requestSettings({ settingAttributes, data, smartpointSettings }) {
  let id;
  let activated;
  let isNew;
  let modifyMode;
  let settingAttribute;
  let settingAttributeText;
  let smartpointSetting;
  let smartpointSettingText;
  let settingValue;
  let attributeValueOne;
  let attributeValueTwo;
  let attributeValueThree;
  let userFullName;
  if (data && data.addedRows && data.addedRows.length > 0) {
    isNew = true;
    modifyMode = false;
    id = -1;
    activated = isActivated(data);
    settingAttribute = settingAttributes.find(c => c.text === data.addedRows[0].settingAttributeText);
    settingAttributeText = setAttributeText(settingAttribute);
    smartpointSetting = smartpointSettings.find(c => c.text === data.addedRows[0].smartpointSettingText);
    smartpointSettingText = setSmartpointSettingText(smartpointSetting);
    settingValue = setSettingValue(data);
    attributeValueOne = setAttributeValueOne(data);
    attributeValueTwo = setAttributeValueTwo(data);
    attributeValueThree = setAttributeValueThree(data);
  }
  if (data && data.updatedRows) {
    isNew = false;
    modifyMode = true;
    id = data.updatedRows.row.id;
    activated = isRowActivated(data);
    settingAttribute = setAttributeValue(data, settingAttributes);
    settingAttributeText = setUpdatedRowAttributeText(data, settingAttributes);
    smartpointSetting = setUpdatedRowSmartpointSetting(data, smartpointSettings);
    smartpointSettingText = setUpdatedRowSmartpointSettingText(data, smartpointSettings);
    settingValue = setUpdatedRowSettingValue(data);
    const attributeValueOneObjExists = Object.prototype.hasOwnProperty.call(data.updatedRows, 'attributeValueOne');

    attributeValueOne = getAttributeValueOne(attributeValueOneObjExists, data);

    const attributeValueTwoObjExists = Object.prototype.hasOwnProperty.call(data.updatedRows, 'attributeValueTwo');

    if (attributeValueTwoObjExists) {
      attributeValueTwo = data.updatedRows.attributeValueTwo;
    } else {
      attributeValueTwo = data.updatedRows.row.attributeValueTwo || null;
    }

    const attributeValueThreeObjExists = Object.prototype.hasOwnProperty.call(data.updatedRows, 'attributeValueThree');

    if (attributeValueThreeObjExists) {
      attributeValueThree = data.updatedRows.attributeValueThree;
    } else {
      attributeValueThree = data.updatedRows.row.attributeValueThree || null;
    }
    userFullName = data.updatedRows.row.userFullName || null;
  }
  return {
    id,
    activated,
    smartpointSetting,
    smartpointSettingText,
    settingValue,
    settingAttribute,
    settingAttributeText,
    modifyMode,
    isNew,
    attributeValueOne,
    attributeValueTwo,
    attributeValueThree,
    pccOrgname: 'Fox World Travel',
    userFullName
  };
}

const settingsAttributesSelector = state => state.administration.smartpointConfigSettings.settingsAttributes;
const smartpointSettingsSelector = state => state.administration.smartpointConfigSettings.smartpointSettings;
function* showAlerts(request, attributeValueTwoArray, attributeValueThreeArray, url, data) {
  if (!request.smartpointSetting || request.smartpointSetting === '') {
    yield put(showAlertAction('Please select a Smartpoint Setting'));
  } else if (!request.settingValue || request.settingValue === '') {
    yield put(showAlertAction('Please enter the Setting Value'));
  } else if (!request.settingAttributeText || request.settingAttributeText === '') {
    yield put(showAlertAction('Please select a Setting Attribute(s) combination'));
  } else if (!request.attributeValueOne || request.attributeValueOne === '') {
    yield put(showAlertAction('Please enter proper setting attribute(s)'));
  } else if (attributeValueTwoArray.includes(request.settingAttribute.value) && (!request.attributeValueTwo || request.attributeValueTwo === '')) {
    yield put(showAlertAction('Please enter proper setting attribute(s)'));
  } else if (attributeValueThreeArray.includes(request.settingAttribute.value) && (!request.attributeValueThree || request.attributeValueThree === '')) {
    yield put(showAlertAction('Please enter proper setting attribute(s)'));
  } else {
    yield put(showLoader());
    const result = yield ApiClient.httpClient.post(url, { ...request });
    yield put(onSave.success({ data, request, result }));
    yield put(showAlertAction('The configuration has been successfully saved.'));
    // To reset the grid to normal mode
    data.onSuccess();
    yield put(hideLoader());
  }
}
function* onSaveSaga({ url, data }) {
  try {
    const settingAttributes = yield select(settingsAttributesSelector);
    const smartpointSettings = yield select(smartpointSettingsSelector);
    const request = requestSettings({ settingAttributes, data, smartpointSettings });
    const attributeValueTwoArray = ['1', '2', '3', '5', '7', '9', '11', '12', '13', '15'];
    const attributeValueThreeArray = ['1', '11'];
    yield* showAlerts(request, attributeValueTwoArray, attributeValueThreeArray, url, data);
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(hideLoader());
    yield put(onSave.failure(error.response && error.response.data && error.response.data.responseMessage));
    yield put(showAlertAction(error.response && error.response.data && error.response.data.responseMessage));
  }
}

function* onDeleteSaga({ url, data }) {
  try {
    yield put(
      showConfirmationModal('Are you sure you want to delete this configuration?')
    );
    const { accept } = yield race({
      accept: take(CONFIRMATION_ACCEPTED),
      reject: take(CONFIRMATION_REJECTED)
    });
    if (accept) {
      onDelete.request();
      const result = yield ApiClient.httpClient.delete(`${url}/${data.row.id}`);
      yield put(onDelete.success({
        id: data.row.id,
        message: result.data
      }));
      yield put(showAlertAction('The configuration has been successfully deleted.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    onDelete.failure();
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

const toggleStatusSelector = state => state.administration.smartpointConfigSettings.pagedCollection;

function* toggleActivationSaga({ url, data }) {
  try {
    toggleActivation.request();
    const result = yield ApiClient.httpClient.put(`${url}/${data.id}`);
    yield put(toggleActivation.success({
      data,
      result
    }));
    const toggleStatus = yield select(toggleStatusSelector);
    if (toggleStatus.find(item => item.id === data.id).activated) yield put(showAlertAction('The configuration has been successfully activated.'));
    else yield put(showAlertAction('The configuration has been successfully deactivated.'));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* exportSmartPointConfigurationSaga(action) {
  try {
    yield put(showLoader());
    const { params } = action.payload;
    yield put(exportSmartConfiguration.request());
    const response = yield call(ApiClient.httpClient.post, action.url, { ...params });
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const currentDate = new Date();
      let filename = `SP_Configuration_${currentDate.getMonth() + 1}_${currentDate.getDate()}_${currentDate.getFullYear()}.csv`;
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
    yield put(exportSmartConfiguration.success());
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* watchExportSmartPointConfigurationSaga() {
  yield takeLatest(EXPORT_SMARTPOINT_CONFIGURATION.BASE, exportSmartPointConfigurationSaga);
}

export function* watchGetPccsIDSaga() {
  yield takeLatest(GET_PCC.BASE, getPccsSaga);
}

export function* watchGetGroupsSaga() {
  yield takeLatest(GET_ALL_GROUPS.BASE, getGroupsSaga);
}

export function* watchGetSettingValuesSaga() {
  yield takeLatest(GET_SETTINGS_VALUES.BASE, getSettingValuesSaga);
}

export function* watchGetUsersIDSaga() {
  yield takeLatest(GET_USERS_ID.BASE, getUsersIDSaga);
}

export function* watchOnSaveSaga() {
  yield takeLatest(ON_SAVE.BASE, onSaveSaga);
}

export function* watchOnDeleteSaga() {
  yield takeLatest(ON_DELETE.BASE, onDeleteSaga);
}

export function* watchToggleActivationSaga() {
  yield takeLatest(TOGGLE_ACTIVATION.BASE, toggleActivationSaga);
}

export function* watchGetInitialDataSaga() {
  yield takeLatest(GET_INITIAL_DATA_BASE, getInitialDataSaga);
}

export default smartpointConfigSettings;
