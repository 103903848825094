/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../ToolTip';

const onClick = (e, props) => {
  e.preventDefault();
  if (props.enabled) props.onChange(props.value);
};

const SimplePrettyRadioButton = (props) => (
  <>
    <div className={`clearfix prettyradio labelright  gray ${(props.enabled ? '' : 'disabled')}`}>
      <span
        {...props.input}
        className={props.checked ? 'prettySpan checked' : 'prettySpan'}
        onClick={(e) => onClick(e, props)}
      />
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
      <label
        htmlFor={props.name}
        onClick={(e) => onClick(e, props)}
      >
        {props.displayText}
&nbsp;
        {props.tooltip && <Tooltip labelName={props.tooltip} />}
      </label>
    </div>
  </>
);

SimplePrettyRadioButton.defaultProps = {
  enabled: true,
  onChange: () => {}
};

SimplePrettyRadioButton.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  enabled: PropTypes.bool,
  name: PropTypes.string,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default SimplePrettyRadioButton;
