import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: props.show,
      loaderText: props.text || 'Loading...'
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ showLoader: props.show });
  }

  render() {
    if (this.state.showLoader) {
      return (
        <div className="loader-container">
          <div id="ajax-loading" className="loader-component">
            <img src={require('../../../Styles/img/loader.gif').default} alt="" />
            &nbsp;
            {this.state.loaderText}
          </div>
        </div>
      );
    }
    return null;
  }
}

LoaderComponent.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string
};

export default LoaderComponent;
