/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Getter } from '@devexpress/dx-react-core';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableEditRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4';
import EditCell from './EditCell/EditCell';

class MarketplaceDatagrid extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingRowIds: [],
      addedRows: [],
      deletingRows: [],
      rowChanges: {},
    };
  }

  getUpdateObject = (rows, editingRowIds, updateRows) => ({
    ...rows[editingRowIds[0]],
    ...updateRows[editingRowIds[0]],
  });

  changeRowChanges = (rowChanges) => this.setState({ rowChanges });

  changeAddedRows = (addedRows) => this.setState({ addedRows });

  changeEditingRowIds = (editingRowIds) => this.setState({ editingRowIds });

  deletedRowIdsChange = (deletingRows) => this.setState({ deletingRows });

  command = ({ id, onExecute }) => {
    const commandComponentProps = {
      add: {
        text: 'New',
        hint: 'Create new row',
      },
      edit: {
        text: 'Edit',
        hint: 'Edit row',
      },
      delete: {
        text: 'Delete',
        hint: 'Delete row',
      },
      commit: {
        text: 'Save',
        hint: 'Save changes',
      },
      cancel: {
        text: 'Cancel',
        hint: 'Cancel changes',
      },
    };
    return (
      <a
        className="space-left"
        onClick={(e) => {
          e.preventDefault();
          this.commitChangesAsync(id, onExecute);
        }}
      >
        {commandComponentProps[id].text}
      </a>
    );
  };

  EditCell = (props) => {
    const { editcell } = this.props;
    return EditCell({ editcell, ...props });
  };

  CommandEditCell = (props) => {
    const { commandButtonFilter } = this.props;
    return (
      <TableEditColumn.Cell {...props}>
        {React.Children.toArray(props.children).filter((child) => {
          // child.props.id -> edit, add, delete, cancel, commit
          const idRows = commandButtonFilter[child.props.id]; // .find(prop => prop.idButton === child.props.id);
          if (idRows) {
            // Here we check for the row id where we should apply this filter
            if (!idRows.includes(props.tableRow.rowId)) {
              return true;
            }
            return false;
          }
          return true;
        })}
      </TableEditColumn.Cell>
    );
  };

  commitChanges = ({ added, changed, deleted }) => {
    console.log('commitChanges', added, changed, deleted); // eslint-disable-line no-console
    if (deleted) {
      const { onDelete, rows } = this.props;
      onDelete({
        row: rows[deleted[0]],
      });
    }
  };

  handleCommit = (id, onSave, rows, onExecute) => {
    switch (id) {
      case 'commit':
        onSave({
          addedRows: this.state.addedRows,
          updateRows: this.state.editingRowIds
            ? this.getUpdateObject(
              rows,
              this.state.editingRowIds,
              this.state.rowChanges
            )
            : undefined,
          onSuccess: onExecute,
        });
        break;
      default:
        onExecute();
        break;
    }
  };

  handleAdd = (props) => (
    (props.rows[0].pricingInformation === ''
      || props.rows[0].pricingInformation === null)
    && (props.rows[0].pricingNotes === '' || props.rows[0].pricingNotes === null));

  handlePricingInfo = (props) => (props.rows[0].pricingInformation === ''
  || this.props.rows[0].pricingInformation === null);

  handleCommitt = (state, rows) => {
    if (state.addedRows.length === 0) {
      return this.getUpdateObject(
        rows,
        state.editingRowIds,
        state.rowChanges
      );
    }
    return state.addedRows[0];
  }

    commitChangesAsync = (id, onExecute) => {
      const { onSave, rows } = this.props;

      if (id === 'add') {
        if (this.handleAdd(this.props)) {
          return this.props.showMsg(
            ' Data for ´All Others´ row should be filled our before adding a setting'
          );
        }

        if (this.handlePricingInfo(this.props)) {
          return this.props.showMsg('Please fill Pricing Information');
        }
      }

      if (id === 'commit') {
        let dataRow = {};
        dataRow = this.handleCommitt(this.state, rows);
        if (dataRow === {}) {
          return null;
        }

        if (
          !dataRow.countryName
        || dataRow.countryName === ''
        || dataRow.countryName === null
        ) {
          return this.props.showMsg('Please select a location');
        }

        if (
          !dataRow.pricingInformation
        || dataRow.pricingInformation === ''
        || dataRow.pricingInformation === null
        ) {
          return this.props.showMsg('Please fill Pricing Information');
        }
      }

      this.handleCommit(id, onSave, rows, onExecute);

      return null;
    };

    render() {
      const { rows, columns } = this.props;

      return (
        <div className="travelport-table">
          <Grid rows={rows} columns={columns}>
            <Table />
            <TableHeaderRow />
            <EditingState
              addedRows={this.state.addedRows}
              rowChanges={this.state.rowChanges}
              editingRowIds={this.state.editingRowIds}
              deletedRowIds={this.state.deletingRows}
              onEditingRowIdsChange={this.changeEditingRowIds}
              onDeletedRowIdsChange={this.deletedRowIdsChange}
              onAddedRowsChange={this.changeAddedRows}
              onRowChangesChange={this.changeRowChanges}
              onCommitChanges={this.commitChanges}
            />
            <TableEditRow cellComponent={this.EditCell} />
            <TableEditColumn
              width={100}
              showAddCommand={this.state.addedRows.length === 0}
              showEditCommand
              showDeleteCommand
              commandComponent={this.command}
              cellComponent={this.CommandEditCell}
            />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => [
                ...tableColumns.filter((c) => c.type !== 'editCommand'),
                { key: 'editCommand', type: 'editCommand', width: 140 },
              ]}
            />
            <Toolbar />
          </Grid>
        </div>
      );
    }
}

MarketplaceDatagrid.defaultProps = {
  commandButtonFilter: {},
};

MarketplaceDatagrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  editcell: PropTypes.object,
  commandButtonFilter: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  showMsg: PropTypes.func,
};

export default MarketplaceDatagrid;
