/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ExcelDownloadPresentation = (props) => (
  <div className="row text-right ajax-dependent-div">
    <Link to="#" id="downloadExcel" className="text-link-with-icon-left" onClick={props.onClick}>
      <span className="download-icon" />
      Download in Microsoft Excel
    </Link>
  </div>
);

ExcelDownloadPresentation.propTypes = {
  onClick: PropTypes.func
};

export default ExcelDownloadPresentation;
