import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-bootstrap4';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import licenseStatusCode from 'constants/licenseStatusCode';
import HandleNotesSection, { handleLicenseCodeUnInstalled } from './CommonParts';

const EditCell = (props) => {
  switch (props.column.title) {
    case 'ACTIVATE':
      return (
        <td className="text-center">
          {props.row.statusType === licenseStatusCode.Activated
            && <div className="prettycheck" />}
          {props.row.statusType !== licenseStatusCode.Activated
            && (
            <SimplePrettyRadioButton
              enabled
              value={licenseStatusCode.Activated}
              checked={props.value === licenseStatusCode.Activated}
              onChange={props.onValueChange}
            />
            )}
        </td>
      );
    case 'DISABLE':
    {
      return (
        <td className="text-center">
          {props.row.statusType === licenseStatusCode.Activated
            && (
            <SimplePrettyRadioButton
              enabled
              value={licenseStatusCode.Deactivated}
              checked={props.value === licenseStatusCode.Deactivated}
              onChange={props.onValueChange}
            />
            )}
          {props.row.statusType === licenseStatusCode.Deactivated
            && <div className="prettycheck" />}
        </td>
      );
    }
    case 'UNINSTALL':
      return (
        <td className="text-center">
          {props.row.statusType !== licenseStatusCode.Uninstalled
            && (
            <SimplePrettyRadioButton
              enabled
              value={licenseStatusCode.Uninstalled}
              checked={props.value === licenseStatusCode.Uninstalled}
              onChange={props.onValueChange}
            />
            )}
          {handleLicenseCodeUnInstalled(props)}
        </td>
      );
    case 'NOTES':
      return (<HandleNotesSection {...props} />);
    default:
      return <Table.Cell {...props} />;
  }
};

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default EditCell;
