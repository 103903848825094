import React, { Component } from 'react';

class NoMatch extends Component {
  render() {
    return (
      <div className="container">
        <h2> Page Not Found</h2>
        <div className="large-alert row">
          The page you were attempting to access does not exist.
          <p />
          <p>
            <b>Note:</b>
            If you are trying to access any product, try accessing it after signing in to Marketplace.
          </p>
        </div>
      </div>
    );
  }
}

export default NoMatch;
