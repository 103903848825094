/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Banner } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Authorization from 'components/Authorization/Authorization';
import Profile from 'components/Profile/Profile';
import { roleNameConstants } from 'utils/rolesHelper';

class UserProfileControl extends Component {
  render() {
    const tmpObj = {
      profile: {
        name: this.props.match.params.username
      }
    };

    const newObj = { ProfileControl: tmpObj, ...this.props };
    const currentUser = newObj.currentUserRole && roleNameConstants[newObj.currentUserRole].text;
    return (
      <div>
        <Banner.Stack
          id="sb-bannerStack"
          size="small"
        >
          <Banner
            appearance="warning"
            dismissable
            size="small"
            className="banneralign"
          >
            You are currently acting as a delegate for user
            {' '}
            <strong>{this.props.userName}</strong>
            {' '}
            having
            {' '}
            <strong>{currentUser}</strong>
            {' '}
            role
          </Banner>
        </Banner.Stack>
        <a className="TitleMarginLeft" onClick={this.props.history.goBack}>
          &lt; Back
        </a>
        <Profile {...newObj} />
      </div>
    );
  }
}

UserProfileControl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  userName: PropTypes.string
};

const mapStateToProps = state => ({
  userName: state.profile.userProfile.userDetails.email,
  currentUserRole: state.profile.accessingUserProfileReducer.currentRole,
  roles: state.profile.accessingUserProfileReducer.roles,
});

export default connect(
  mapStateToProps,
  null
)(Authorization(UserProfileControl, { path: false }));
