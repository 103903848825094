import { takeLatest, call, put } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';

export const EXPORT_PRODUCT_EVENT_REPORT = createFetchTypes('Marketplace/Profile/SalesReport/EXPORT_PRODUCT_EVENT_REPORT');

export const exportProductEventReportReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case EXPORT_PRODUCT_EVENT_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EXPORT_PRODUCT_EVENT_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case EXPORT_PRODUCT_EVENT_REPORT.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export const exportProductEventReport = {
  base: (params) => ({
    type: EXPORT_PRODUCT_EVENT_REPORT.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: EXPORT_PRODUCT_EVENT_REPORT.REQUEST
  }),
  success: () => ({
    type: EXPORT_PRODUCT_EVENT_REPORT.SUCCESS,
  }),
  failure: (error) => ({
    type: EXPORT_PRODUCT_EVENT_REPORT.FAILURE,
    payload: {
      error
    }
  })
};

export function* exportProductEventReportSaga(action) {
  try {
    const { params } = action.payload;
    yield put(exportProductEventReport.request());
    const response = yield call(ApiClient.httpClient.post, apiConfig.EXPORT_FILTERED_PRODUCT_EVENT_REPORT, { ...params });
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      let filename = 'report.csv';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
    yield put(exportProductEventReport.success());
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchExportProductEventReportSaga() {
  yield takeLatest(EXPORT_PRODUCT_EVENT_REPORT.BASE, exportProductEventReportSaga);
}
