/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  TableHeaderRow,
  Table,
  TableEditColumn,
  TableEditRow,
  PagingPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  PagingState,
  CustomPaging,
  SortingState,
  EditingState
} from '@devexpress/dx-react-grid';
import { Link } from 'react-router-dom';
import {
  getLicenses,
  saveLicenseChange,
  getLicenseHistory,
  getTextForLicenseChangeConf,
  resetEditLicenseStatus
} from 'reducers/modules/Administration/ManageLicenses/EditLicenseStatus/editLicenseStatus';
import {
  editLicenseStatusSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';
import * as utils from 'utils/Common.Utils';
import DataCells from './TableComponents/DataCells';
import EditCell from './TableComponents/EditCell';
import LicenseChangeConfModal from './LicenseChangeConfModal.jsx/LicenseChangeConfModal';
import LicenseHistoryModal from './LicenseHistoryModal/LicenseHistoryModal';
import LicenseChangedModal from './LicenseChangedModal/LicenseChangedModal';

class EditLicenseStatus extends Component {
  constructor(props) {
    super(props);
    let searchType = '';
    if (utils.getValueFromQueryString(props.location, 'searchType').toUpperCase() === 'AGENCY') {
      searchType = 'Agency';
    } else {
      searchType = 'Plugin';
    }
    this.state = {
      searchType,
      searchText: utils.getValueFromQueryString(props.location, 'searchText'),
      id: this.props.match.params.id,
      sorting: {
        columnName: '',
        direction: 'asc',
      },
      paging: {
        currentPage: 0,
        pageSize: 10,
      }
    };
    const firstColumn = {
      name: 'rowName',
      title: searchType === 'Agency' ? 'PLUG-IN' : 'AGENCY'
    };
    const getStatusType = (row) => row.statusType;
    const setStatusType = (row, value) => ({ ...row, statusType: value });
    this.columnDefinitions = [
      firstColumn,
      { name: 'activate', title: 'ACTIVATE', getCellValue: getStatusType },
      { name: 'disable', title: 'DISABLE', getCellValue: getStatusType },
      { name: 'uninstall', title: 'UNINSTALL', getCellValue: getStatusType },
      { name: 'notes', title: 'NOTES' }
    ];
    this.columnWidths = [
      { columnName: 'rowName', width: 300 },
      { columnName: 'activate', width: 100 },
      { columnName: 'disable', width: 100 },
      { columnName: 'uninstall', width: 100 },
      { columnName: 'notes', width: 520 }
    ];
    this.editingStateColumnExtensions = [
      { columnName: 'activate', createRowChange: setStatusType },
      { columnName: 'disable', createRowChange: setStatusType },
      { columnName: 'uninstall', createRowChange: setStatusType }
    ];
    this.sortingStateColumnExtensions = [
      { columnName: 'activate', sortingEnabled: false },
      { columnName: 'disable', sortingEnabled: false },
      { columnName: 'uninstall', sortingEnabled: false },
      { columnName: 'notes', sortingEnabled: false }
    ];
  }

  componentDidMount() {
    document.title = 'Edit License Status';
    this.getLicenses();
  }

  componentWillUnmount() {
    this.props.resetEditLicenseStatus();
  }

  getLicenses = () => {
    let sortColumn = this.state.sorting.columnName;
    if (this.state.sorting.columnName === 'rowName') {
      sortColumn = this.state.searchType === 'Agency' ? 'Plugin' : 'Agency';
    }
    const params = {
      searchAgency: this.state.searchType === 'Agency',
      id: this.state.id,
      page: this.state.paging.currentPage + 1,
      sortColumn,
      ascending: this.state.sorting.direction === 'asc'
    };
    this.props.getLicenses(params);
  }

  pageHandler = (currentPage) => this.setState({ paging: { ...this.state.paging, currentPage } }, this.getLicenses);

  sortHandler = (newSorting) => this.setState({ sorting: { ...newSorting[0] } }, this.getLicenses);

  commitChanges = ({ changed }) => {
    if (changed) {
      const changedRowDetails = { ...changed[Object.keys(changed)[0]] };
      const params = {
        licId: changedRowDetails.licenseId,
        change: changedRowDetails.statusType
      };
      this.props.saveLicenseChange(params);
    }
  };

  render() {
    if (!this.props.entities) {
      return '';
    }
    const title = this.state.searchType === 'Agency' ? `${this.props.title} Plug-ins` : this.props.title;
    return (
      <>
        <h2>{title}</h2>
        <div className="container">
          <div className="row">
            <Link to={`/admin/licenses/manageLicenses?searchType=${this.state.searchType}&searchText=${this.state.searchText}&showPreviousSearch=true`}>&lt; BACK</Link>
          </div>
          <div className="row space-top">
            <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
              <Grid columns={this.columnDefinitions} rows={this.props.entities}>
                <EditingState
                  columnExtensions={this.editingStateColumnExtensions}
                  onCommitChanges={this.commitChanges}
                />
                <PagingState
                  currentPage={this.state.paging.currentPage}
                  pageSize={this.state.paging.pageSize}
                  onCurrentPageChange={this.pageHandler}
                />
                <CustomPaging
                  totalCount={this.props.totalPages * this.state.paging.pageSize}
                />

                <SortingState
                  sorting={this.sorting}
                  onSortingChange={this.sortHandler}
                  columnExtensions={this.sortingStateColumnExtensions}
                />

                <Table cellComponent={DataCells} />
                <TableColumnResizing
                  columnWidths={this.columnWidths}
                />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  showEditCommand
                />
                <TableEditRow cellComponent={EditCell} />
                <PagingPanel pageSizes={[]} />
              </Grid>
            </div>
          </div>
        </div>
        <LicenseChangeConfModal />
        <LicenseHistoryModal />
        <LicenseChangedModal />
      </>
    );
  }
}

EditLicenseStatus.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  entities: PropTypes.array,
  totalPages: PropTypes.number,
  getLicenses: PropTypes.func,
  saveLicenseChange: PropTypes.func,
  getLicenseHistory: PropTypes.func,
  getTextForLicenseChangeConf: PropTypes.func,
  resetEditLicenseStatus: PropTypes.func,
  currentUserRole: PropTypes.string,
  title: PropTypes.string
};

const mapStateToProps = state => ({
  title: editLicenseStatusSelector(state).title,
  entities: editLicenseStatusSelector(state).entities,
  totalPages: editLicenseStatusSelector(state).totalPages,
});

const mapDispatchToProps = dispatch => ({
  getLicenses: params => dispatch(getLicenses.base(params)),
  saveLicenseChange: params => dispatch(saveLicenseChange.base(params)),
  getLicenseHistory: licenseId => dispatch(getLicenseHistory.base(licenseId)),
  getTextForLicenseChangeConf: licenseStatus => dispatch(getTextForLicenseChangeConf.base(licenseStatus)),
  resetEditLicenseStatus: () => dispatch(resetEditLicenseStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLicenseStatus);
