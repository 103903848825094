/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import dateHelper from '../../../utils/dateHelper';

const ReviewVerification = (props) => {
  const cancel = (e) => {
    e.preventDefault();
    props.popUpHide();
  };
  if (!props.userDetails || !props.userDetails.user) {
    return '';
  }
  return (
    <>
      <Modal
        id="sbModal"
        title={`${props.buildRow.changeOrderNumber} : Verification`}
        hidden={!props.showModal}
        size="small"
        onDismiss={cancel}
      >
        <div>
          <p>{`Change Order # : ${props.buildRow.changeOrderNumber}`}</p>
          <p>
            {'Verified By : '}
            <Link to={`/Profile/UserProfile/${props.userDetails.user}`}>{props.buildRow.verifiedUserName}</Link>
          </p>
          <p>{`Verified On : ${dateHelper.getDateTime(props.buildRow.verfiedReleaseDate)}`}</p>
        </div>
        <div className="text-center space-top">
          <button type="button" className="cancel-button space-left no-save" onClick={cancel}>CLOSE</button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  userDetails: state.administration.buildVersionControl.userDetails
});

ReviewVerification.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  buildRow: PropTypes.object,
  userDetails: PropTypes.object
};

export default connect(mapStateToProps, null)(ReviewVerification);
