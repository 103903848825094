import { put, call } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

export default function* fetchEntity(entity, apiFn, ...params) {
  yield put(entity.request());
  try {
    const response = yield call(apiFn, ...params);
    yield put(entity.success(response.data));
    return response;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(entity.failure(error));
    throw error;
  }
}
