/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* eslint-disable max-len */
const AdditionalLocation = props => (
  <>
    <div className="space-bottom" style={{ display: props.openAdditionalLocation ? 'block' : 'none' }}>
      <select className="select" id="additionalLocation" value={props.additionalLocation} onChange={(e) => props.getChangeHandler(e)}>
        {props.countries && props.countries.map((value, i) => <option key={i.toString()} value={value.id}>{value.name}</option>)}
      </select>
      <Link to="#" className="addplus action-button" onClick={() => props.addAdditionalCountry()}>Add</Link>
    </div>
    <Link to="#" id="addloc-plus" onClick={() => props.onClick()}>+ Add Additional Location</Link>
  </>
);

AdditionalLocation.propTypes = {
  countries: PropTypes.array,
  openAdditionalLocation: PropTypes.bool,
  additionalLocation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  getChangeHandler: PropTypes.func,
  addAdditionalCountry: PropTypes.func,
  onClick: PropTypes.func
};

export default AdditionalLocation;
