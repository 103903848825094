/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, toast } from '@atlas-design-system/react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import * as SeoHelper from 'utils/SeoHelper';
import { APPLICATION_CERTIFICATION_ID } from '../../../constants/PPCP';
import {
  getInitialCertification, saveCertificationForm, onDeleteCertification,
  uncertifyForm, onDownloadVersion, clearErrorMessage
} from '../../../reducers/modules/Administration/index';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';
import '@atlas-design-system/core/dist/styles/partials/modal.css';
import '@atlas-design-system/core/dist/styles/partials/toast.css';

export const Versions = {
  SPWeb: 'SPWebProductVersions',
  DirectDownload: 'DirectDownloadVersions',
  IntegrationDownload: 'AppVers'
};
const RemoveButton = ({ removeProduct }) => (<button type="button" className="btn btn-danger" onClick={() => { removeProduct(); }}>Remove</button>);
const CertifyButton = ({ certifyProduct, isAppActivated }) => (
  <button
    type="button"
    className="btn btn-primary"
    disabled={!isAppActivated}
    onClick={() => { if (isAppActivated) { certifyProduct(); } }}
  >
    Certify
  </button>
);
const UncertifyButton = ({ uncertifyProduct }) => (<button type="button" className="btn btn-default" onClick={() => { uncertifyProduct(); }}>Uncertify</button>);
const DownloadButton = ({ downloadProduct }) => (<button type="button" className="btn btn-default" onClick={() => { downloadProduct(); }}>Download</button>);

const Panelheading = ({
  appName, isActivated, certifyProduct, uncertifyProduct
}) => (
  <div className="panel-heading">
    <h4>
      <em>
        <Link
          style={{ color: '#3c763d' }}
          to={`/Preview/${SeoHelper.SEOFriendly(appName)}`}
          target="_blank"
        >
          {appName}
        </Link>
        &nbsp;
        {isActivated ? <span className="certify_text">is certified</span> : ''}
      </em>
    </h4>
    {isActivated ? <UncertifyButton uncertifyProduct={uncertifyProduct} /> : <CertifyButton isAppActivated certifyProduct={certifyProduct} />}
  </div>
);

function dataManipulation(versionList) {
  var tempContent = '';
  var i;
  var diffFlag = false;
  const versionCollection = versionList;
  for (i = 0; i < versionCollection.length; i += 1) {
    if (i === 0 && versionCollection.length === 1) {
      versionCollection[i].isDiffVersion = true;
      return versionCollection;
    }
    if (i + 1 < versionCollection.length) {
      if (versionCollection[i].applicationVersionNumber === versionCollection[i + 1].applicationVersionNumber) {
        tempContent = `${`${tempContent + versionCollection[i].pluginPath}\n`}`;
        versionCollection[i].pluginPath = tempContent;

        diffFlag = false;
      } else if (versionCollection[i].applicationVersionNumber !== versionCollection[i + 1].applicationVersionNumber) {
        tempContent = `${`${tempContent + versionCollection[i].pluginPath}`}`;
        versionCollection[i].pluginPath = tempContent;
        versionCollection[i].isDiffVersion = true;
        tempContent = '';
        diffFlag = true;
      }
    }
    if (versionCollection.length === i + 1 && diffFlag === false) {
      tempContent = `${`${`${tempContent + versionCollection[i].pluginPath}\n`}`}`;
      versionCollection[i].pluginPath = tempContent.trimEnd('\n');
      versionCollection[i].isDiffVersion = true;
      diffFlag = false;
    }
  }
  return versionCollection;
}
const GroupList = ({
  groupName, versionCollection, certifyProduct, removeProduct, uncertifyProduct, isAppActivated, platformId, downloadProduct
}) => (
  <>
    <div className="panel-body">
      <p>{groupName}</p>
      <ul className="list-group">
        {versionCollection.map((version, i) => (
          <>
            <>
              { platformId === 2 && version.isDiffVersion === true ? (
                <>
                  <div>
                    {version.applicationVersionNumber}
                  &nbsp;
                  </div>
                  <div>
                    <li key={i.toString()} className="list-group-item">
                      <>
                        <div>
                          {version.pluginPath.split('\n').map(str => <p>{str}</p>)}
                        &nbsp;
                        </div>
                      </>
                    </li>
                  </div>
                </>
              ) : (
                platformId !== 2 ? (
                  <div>
                    <li key={i.toString()} className="list-group-item">
                      <>
                        <div>
                          {version.versionNumber}
                    &nbsp;
                          {(version.isActivated) ? '  is certified' : ''}
                        </div>
                        <div>
                          {version.isActivated ? <UncertifyButton uncertifyProduct={() => uncertifyProduct(version)} />
                            : <CertifyButton isAppActivated={isAppActivated} certifyProduct={() => certifyProduct(version)} />}
                          <RemoveButton removeProduct={() => removeProduct({ ...version, isActivated: version.isActivated })} />
                          <DownloadButton downloadProduct={() => downloadProduct(version)} />
                        </div>
                      </>
                    </li>

                  </div>
                ) : '')}
            </>

          </>
        ))}
      </ul>
    </div>
  </>
);

const Products = ({
  productList, certifyProduct, removeProduct, uncertifyProduct, downloadProduct
}) => (
  <div className="row1 manageapp">
    <div className="container-col1">
      {productList.map((data, i) => (
        <div className="box-panel" key={i.toString()}>
          <div className={data.appIsActivated ? 'panel panel-success' : 'panel panel-default'}>
            <Panelheading
              appName={data.appName}
              isActivated={data.appIsActivated}
              uncertifyProduct={() => uncertifyProduct('Apps', data.appId, 0, data.directDownloadVersions, data.versions, data.spWebProductVersionFiles, data.appIsActivated)}
              certifyProduct={() => certifyProduct('Apps', data.appId)}
            />
            {(data.spWebProductVersionFiles.length && data.platformId === 2) ? (
              <GroupList
                groupName="SmartPoint cloud versions"
                versionCollection={dataManipulation(data.spWebProductVersionFiles)}
                certifyProduct={(version) => certifyProduct(Versions.SPWeb, version.appVersionId)}
                uncertifyProduct={(version) => uncertifyProduct(Versions.SPWeb, data.appId, version.appVersionId,
                  data.directDownloadVersions, data.versions, data.spWebProductVersionId, data.appIsActivated)}
                isAppActivated={data.appIsActivated}
                platformId={data.platformId}
              />
            ) : null}
            {(data.directDownloadVersions.length && data.platformId !== 2) ? (
              <GroupList
                groupName="DIRECT DOWNLOAD VERSIONS"
                versionCollection={data.directDownloadVersions}
                removeProduct={(version) => removeProduct(Versions.DirectDownload, version.appVersionId, data.directDownloadVersions, data.versions, data.appIsActivated, version.isActivated)}
                certifyProduct={(version) => certifyProduct(Versions.DirectDownload, version.appVersionId)}
                uncertifyProduct={(version) => uncertifyProduct(Versions.DirectDownload, data.appId, version.appVersionId,
                  data.directDownloadVersions, data.versions, data.spWebProductVersionFiles, data.appIsActivated)}
                downloadProduct={(version) => downloadProduct(Versions.DirectDownload, data.appId, version.appVersionId, version.versionNumber)}
                isAppActivated={data.appIsActivated}
                platformId={data.platformId}
              />
            ) : null}
            {(data.versions.length && data.platformId !== 2) ? (
              <GroupList
                groupName="INTEGRATION DOWNLOAD VERSIONS"
                versionCollection={data.versions}
                removeProduct={(version) => removeProduct(Versions.IntegrationDownload, version.appVersionId, data.directDownloadVersions, data.versions, data.appIsActivated, version.isActivated)}
                certifyProduct={(version) => certifyProduct(Versions.IntegrationDownload, version.appVersionId)}
                uncertifyProduct={(version) => uncertifyProduct(Versions.IntegrationDownload, data.appId, version.appVersionId, data.directDownloadVersions, data.versions, data.spWebProductVersionFiles, data.appIsActivated)}
                downloadProduct={(version) => downloadProduct(Versions.IntegrationDownload, data.appId, version.appVersionId, version.versionNumber)}
                isAppActivated={data.appIsActivated}
                platformId={data.platformId}
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const ErrorPopup = ({ errorMessages, showErrorModal, clearErrorMess }) => (
  !(errorMessages && errorMessages.length > 0 && showErrorModal)
    ? toast.error('Action cannot be done', {
      id: 'Action cannot be done'.replaceAll(' ', '-'),
      position: 'top-center',
      content: errorMessages,
      showProgressBar: true,
      heading: 'Action cannot be done'
    }, clearErrorMess()) : null
);

class ManageApplicationCertificate extends PureComponent {
  constructor(props) {
    super(props);

    this.saveFormRedirect = this.saveFormRedirect.bind(this);

    this.state = {
      Apps: [],
      AppVers: [],
      DirectDownloadVersions: [],
      SPWebProductVersionFiles: []
    };
  }

  componentDidMount() {
    document.title = 'Manage Certification';
    this.props.certificateInitialData();
  }

  saveFormRedirect() {
    this.props.saveForm(this.state);
  }

  certifyProduct = (type, id) => {
    const data = {
      Apps: [],
      AppVers: [],
      DirectDownloadVersions: [],
      SPWebProductVersionFiles: []
    };
    if (type === 'Apps') {
      data.Apps = [id];
    } else if (type === Versions.IntegrationDownload) {
      data.AppVers = [id];
    } else if (type === Versions.DirectDownload) {
      data.DirectDownloadVersions = [id];
    } else if (type === Versions.SPWeb) {
      data.SPWebProductVersionFiles = [id];
    }
    this.props.saveForm(data);
  }

  uncertifyProduct = (type, appId, id, dirDwnload, integration, spwebproduct, appIsActivated) => {
    const data = {
      appId: 0,
      integrationVersionId: 0,
      directDownloadVersionId: 0,
      spWebProductVersionId: 0,
      appVerTotalCount: 0
    };
    data.appId = appId;
    data.appIsActivated = appIsActivated;
    const integrationCount = integration ? integration.filter(app => app.isActivated).length : 0;
    const dirDwnloadCount = dirDwnload ? dirDwnload.filter(app => app.isActivated).length : 0;
    const spWebProductVersionCount = spwebproduct ? spwebproduct.filter(app => app.isActivated).length : 0;
    data.appVerTotalCount = integrationCount + dirDwnloadCount + spWebProductVersionCount;
    if (type === Versions.IntegrationDownload) {
      data.integrationVersionId = id;
    } else if (type === Versions.DirectDownload) {
      data.directDownloadVersionId = id;
    } else if (type === Versions.SPWeb) {
      data.spWebProductVersionId = id;
    }
    this.props.uncertify(data);
  }

  undoCertification(id, prefix, dirDwnload, integration, spwebproduct, appIsActivated, versionActivated) {
    const callbackFn = this.props.certificateInitialData;
    let directDwnloadCount = 0;
    let integrationCount = 0;
    let spWebProductVersionCount = 0;
    if (prefix === Versions.DirectDownload) {
      const currentVersionActivated = dirDwnload.find(app => app.appVersionId === id).isActivated;
      if (currentVersionActivated) {
        directDwnloadCount = parseInt(dirDwnload.filter(app => app.isActivated && app.appVersionId !== id).length, 0);
        directDwnloadCount += 1;
      }
      integrationCount = integration ? integration.filter(app => app.isActivated).length : 0;
    } else if (prefix === Versions.SPWeb) {
      const currentVersionActivated = spwebproduct.find(app => app.appVersionId === id).isActivated;
      if (currentVersionActivated) {
        spWebProductVersionCount = parseInt(spwebproduct.filter(app => app.isActivated && app.appVersionId !== id).length, 0);
        spWebProductVersionCount += 1;
      }
      integrationCount = integration ? integration.filter(app => app.isActivated).length : 0;
    } else if (prefix === Versions.IntegrationDownload) {
      const currentVersionActivated = integration.find(app => app.appVersionId === id).isActivated;
      if (currentVersionActivated) {
        integrationCount = parseInt(integration.filter(app => app.isActivated && app.appVersionId !== id).length, 0);
        integrationCount += 1;
      }
      directDwnloadCount = dirDwnload ? dirDwnload.filter(app => app.isActivated).length : 0;
    }

    const params = {
      id, prefix, appVerTotalCount: integrationCount + directDwnloadCount + spWebProductVersionCount, appIsActivated, isActivated: versionActivated
    };
    this.props.deleteCertification(params, callbackFn);
  }

  removeProduct = (type, id, dirDwnloadCount, integrationCount, appIsActivated, isActivated) => {
    this.undoCertification(id, type, dirDwnloadCount, integrationCount, appIsActivated, isActivated);
  }

  downloadProduct = (type, appId, versionId, version) => {
    const params = {
      appId, versionId, type, version
    };
    this.props.downloadVersion(params);
  }

  render() {
    const { certificationData } = this.props;

    if (certificationData === undefined) {
      return <LoaderComponent show />;
    }
    const certified = certificationData.certified || [];
    const uncertified = certificationData.uncertified || [];

    return (
      <>
        {this.props.showErrorModal ? (
          <ErrorPopup
            showErrorModal={this.props.showErrorModal}
            errorMessages={this.props.errorMessage}
            clearErrorMess={this.props.clearErrorMessage}
          />
        ) : null}
        <h2>Manage Application Certifications</h2>
        <div id="app-certification">
          <div className="container">
            <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
              <Tabs.Tab eventKey="certified" title="Certified Products">
                <Products
                  productList={certified}
                  uncertifyProduct={this.uncertifyProduct}
                  certifyProduct={this.certifyProduct}
                  removeProduct={this.removeProduct}
                  downloadProduct={this.downloadProduct}
                />
              </Tabs.Tab>
              <Tabs.Tab eventKey="uncertified" title="Uncertified Products">
                <Products
                  productList={uncertified}
                  uncertifyProduct={this.uncertifyProduct}
                  certifyProduct={this.certifyProduct}
                  removeProduct={this.removeProduct}
                  downloadProduct={this.downloadProduct}
                />
              </Tabs.Tab>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

Products.propTypes = {
  productList: PropTypes.array,
  certifyProduct: PropTypes.func,
  uncertifyProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  downloadProduct: PropTypes.func
};

ErrorPopup.propTypes = {
  errorMessages: PropTypes.string,
  showErrorModal: PropTypes.bool,
  clearErrorMess: PropTypes.func
};

CertifyButton.propTypes = {
  certifyProduct: PropTypes.func,
  isAppActivated: PropTypes.bool
};

UncertifyButton.propTypes = {
  uncertifyProduct: PropTypes.func
};

RemoveButton.propTypes = {
  removeProduct: PropTypes.func
};
DownloadButton.propTypes = {
  downloadProduct: PropTypes.func
};
Panelheading.propTypes = {
  appName: PropTypes.string,
  isActivated: PropTypes.bool,
  certifyProduct: PropTypes.func,
  uncertifyProduct: PropTypes.func,
};

GroupList.propTypes = {
  groupName: PropTypes.string,
  versionCollection: PropTypes.array,
  certifyProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  uncertifyProduct: PropTypes.func,
  isAppActivated: PropTypes.bool,
  downloadProduct: PropTypes.func,
  platformId: PropTypes.number
};

ManageApplicationCertificate.propTypes = {
  handleSubmit: PropTypes.func,
  certificateInitialData: PropTypes.func,
  certificationData: PropTypes.object,
  submitForm: PropTypes.func,
  saveForm: PropTypes.func,
  uncertify: PropTypes.func,
  deleteCertification: PropTypes.func,
  downloadVersion: PropTypes.func,
  showErrorModal: PropTypes.bool,
  errorMessage: PropTypes.string,
  clearErrorMessage: PropTypes.func
};

const mapStateToProps = state => ({
  certificationData: state.administration.manageApplicationCertificateReducer.applicationCertification,
  errorMessage: state.administration.manageApplicationCertificateReducer.errorMessage,
  showErrorModal: state.administration.manageApplicationCertificateReducer.showErrorModal
});

const mapDispatchToProps = dispatch => ({
  certificateInitialData: () => dispatch(getInitialCertification.base()),
  saveForm: (data) => dispatch(saveCertificationForm.base(data)),
  deleteCertification: (data) => dispatch(onDeleteCertification.base(data)),
  uncertify: (data) => dispatch(uncertifyForm.base(data)),
  downloadVersion: (data) => dispatch(onDownloadVersion(data)),
  clearErrorMessage: () => dispatch(clearErrorMessage.base())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: APPLICATION_CERTIFICATION_ID,
})(ManageApplicationCertificate));
