import React from 'react';
import PropTypes from 'prop-types';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import CheckBoxInput from '../../../common/CheckBoxInput';
import ToolTip from '../../../common/ToolTip';

const AccountSettingPresentation = props => {
  const handleChangeNotificationNewVersion = (value, checked) => {
    props.handleChangeNotificationNewVersion(value, checked);
  };
  const handleChangeNotificationNewSolution = (value, checked) => {
    props.handleChangeNotificationNewSolution(value, checked);
  };
  const handleChangeNotificationPluginOptionForAgency = (value, checked) => {
    props.handleChangeNotificationPluginOptionForAgency(value, checked);
  };
  const handleChangeNotificationAssignedSolutionVisibilityForAgency = (
    value,
    checked
  ) => {
    props.handleChangeNotificationAssignedSolutionVisibilityForAgency(
      value,
      checked
    );
  };
  const canUserUpdatePurchasedSolutionsForOrg = props.userProfilePermission && props.userProfilePermission.canUserUpdatePurchasedSolutionsForOrg;
  const canUserChangeEmailSettings = props.userProfilePermission && props.userProfilePermission.canUserChangeEmailSettings;
  return (
    <>
      <div className="row ajax-dependent-div space-top-double space-left-double">
        <div className="row">
          <h3 className="row">Personal Notification Settings</h3>
        </div>
        {
          (canUserChangeEmailSettings)
          && (
          <div className="row">
            <div className="row">
              <div className="pull-left">
                <CheckBoxInput
                  name="Receive an email notification when a new version of a solution that has been ordered becomes available."
                  checked={props.handleChangeNewVersionChecked}
                  id={Math.round(new Date().getTime() / 1000)}
                  handleChangeCheckBoxExport={handleChangeNotificationNewVersion}
                />
              </div>
              <div className="pad-top-sm">
                <ToolTip labelName={'This option means that the system will automatically send you an email notification '
                 + 'when a new version of any solution you have ordered passes certification.'}
                />
              </div>
            </div>
            <div className="row">
              <div className="pull-left">
                <CheckBoxInput
                  name="Receive an email notification when any new solution becomes available on Travelport Marketplace."
                  checked={props.handleChangeAccountSettingNewSolutionChecked}
                  id={Math.round(new Date().getTime() / 1000)}
                  handleChangeCheckBoxExport={handleChangeNotificationNewSolution}
                />
              </div>
              <div className="pad-top-sm">
                <ToolTip labelName={'This option means that the system will automatically send you an email notification when any new '
                  + 'Solution becomes available on Travelport Marketplace.'}
                />
              </div>
            </div>
          </div>
          )
        }
        {
          (canUserUpdatePurchasedSolutionsForOrg)
          && (
          <div className="row">
            <div className="row">
              <h3 className="row">Marketplace Website Settings</h3>
              <em>NOTE: These options are Agency-wide</em>
            </div>
            <div className="row">
              <div className="row">
                <div className="pull-left">
                  <CheckBoxInput
                    name="I want to give all of my users with 'General User' role the ability to download solutions that I have already purchased."
                    checked={
                      props.handleChangeAccountSettingAssignedSolutionVisibilityForAgencyChecked
                    }
                    id={Math.round(new Date().getTime() / 1000)}
                    handleChangeCheckBoxExport={
                      handleChangeNotificationAssignedSolutionVisibilityForAgency
                    }
                  />
                </div>
                <div className="pad-top-sm">
                  <ToolTip labelName={'If this option is selected, then your agents will see the My Solutions tab in their Marketplace Profile and will be able '
                    + 'to directly download solutions you have ordered.'}
                  />
                </div>
              </div>
            </div>
          </div>
          )
        }
        {
          (canUserUpdatePurchasedSolutionsForOrg)

          && (
          <div className="row">
            <div className="row">
              <h3 className="row">Smartpoint Integration Settings</h3>
              <em>NOTE: These options are Agency-wide</em>
              <br />
              <br />
              <em>
                NOTE: This option no longer deactivates the Travelport Marketplace
                plug-in in Smartpoint. It just controls the visibility of the Icon
                for the user.
              </em>
            </div>
            <div className="row">
              <div className="row">
                <div className="pull-left">
                  <CheckBoxInput
                    name="Show Travelport Marketplace Icon in Smartpoint"
                    checked={
                      props.handleChangeAccountSettingPluginOptionForAgencyChecked
                    }
                    id={Math.round(new Date().getTime() / 1000)}
                    handleChangeCheckBoxExport={
                      handleChangeNotificationPluginOptionForAgency
                    }
                    onChange={
                      handleChangeNotificationPluginOptionForAgency
                    }
                  />
                </div>
                <div className="pad-top-sm">
                  <ToolTip labelName="If this option is selected, then the Travelport Marketplace Icon in Smartpoint will be available for users in this Agency." />
                </div>
              </div>
            </div>
          </div>
          )
        }
      </div>
    </>
  );
};

AccountSettingPresentation.propTypes = {
  handleChangeNewVersionChecked: PropTypes.bool,
  handleChangeAccountSettingNewSolutionChecked: PropTypes.bool,
  handleChangeAccountSettingPluginOptionForAgencyChecked: PropTypes.bool,
  handleChangeAccountSettingAssignedSolutionVisibilityForAgencyChecked:
    PropTypes.bool,
  handleChangeNotificationNewVersion: PropTypes.func,
  handleChangeNotificationNewSolution: PropTypes.func,
  handleChangeNotificationPluginOptionForAgency: PropTypes.func,
  handleChangeNotificationAssignedSolutionVisibilityForAgency: PropTypes.func,
  userProfilePermission: PropTypes.object
};

export default AccountSettingPresentation;
