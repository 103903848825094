export const CLEAR = 'Marketplace/Application/CLEAR';

const applicationCommonReducer = (reducer) => (state, action) => {
  switch (action.type) {
    case CLEAR:
      return {};
    default:
      return reducer(state, action);
  }
};

export const clearValues = () => ({
  type: CLEAR
});

export default applicationCommonReducer;
