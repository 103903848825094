import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOrganization } from 'reducers/modules/Administration/OrganizationDirectory/addOrganization';

class AddOrganization extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: ''
    };
  }

  onChange = (event) => {
    this.setState({ customerNumber: event.target.value });
  }

  addOrganization = (event) => {
    event.preventDefault();
    this.props.addOrganization(this.state.customerNumber);
  }

  render() {
    return (
      <div className="col-sm-5 col-sm-offset-1">
        <div>Add/Edit Organization:</div>
        <div>
          <div className="col-sm-8">
            <input
              type="text"
              id="OrganisationIdentifier"
              className="text-input ng-pristine ng-valid"
              placeholder="Customer Number"
              name="OrganisationIdentifier"
              onChange={this.onChange}
              value={this.state.customerNumber}
            />
          </div>
          <div className="col-sm-3 col-sm-offset-1">
            <input
              type="submit"
              id="add-dev-org-link"
              className="action-button"
              value="SUBMIT"
              onClick={this.addOrganization}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddOrganization.propTypes = {
  addOrganization: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  addOrganization: (customerNumber) => dispatch(addOrganization.base(customerNumber))
});

export default connect(null, mapDispatchToProps)(AddOrganization);
