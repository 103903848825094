import { takeLatest, call, put } from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import createFetchTypes from '../../../utils/createFetchTypes';
import ApiClient from '../../../../Services/ApiClient';
import * as apiConfig from '../../../../config/apiConfig';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';

export const FETCH_BASICINFORMATION_DETAILS = createFetchTypes('Marketplace/Application/BasicInformation/FETCH_BASICINFORMATION_DETAILS');

export const basicInformationReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_BASICINFORMATION_DETAILS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_BASICINFORMATION_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.data
        },
        error: null
      };
    case FETCH_BASICINFORMATION_DETAILS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default: return state;
  }
};

export const fetchBasicInformationDetails = {
  base: (params) => ({
    type: FETCH_BASICINFORMATION_DETAILS.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_BASICINFORMATION_DETAILS.REQUEST
  }),
  success: (data) => ({
    type: FETCH_BASICINFORMATION_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_BASICINFORMATION_DETAILS.FAILURE,
    payload: {
      error
    }
  })
};

export function* fetchBasicInformationDetailsSaga(action) {
  try {
    const { params } = action.payload;
    let url = apiConfig.FETCH_BASICINFORMATION_DETAILS;
    if (params) {
      url = `${apiConfig.FETCH_BASICINFORMATION_DETAILS}?publicId=${params.publicId}&organizationId=${params.organizationId}&previewAfterSave=${params.previewAfterSave}`;
    }
    yield call(() => fetchEntityPPCP(fetchBasicInformationDetails, ApiClient.httpClient.get, url));
  } catch (error) {
    put(showAlertAction(error));
  }
}

export function* watchFetchBasicInformationDetailsSaga() {
  yield takeLatest(FETCH_BASICINFORMATION_DETAILS.BASE, fetchBasicInformationDetailsSaga);
}
