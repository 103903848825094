import createFetchTypes from 'reducers/utils/createFetchTypes';

export const INIT_AUDIENCE_PAGE = 'Marketplace/Application/INIT_AUDIENCE_PAGE';
export const GET_PRODUCT_ACCESS = createFetchTypes('Marketplace/Application/GET_PRODUCT_ACCESS');
export const GET_USER_NAMES = createFetchTypes('Marketplace/Application/GET_USER_NAMES');
export const GET_ORGANIZATION_NAMES = createFetchTypes('Marketplace/Application/GET_ORGANIZATION_NAMES');
export const GET_PCC_ORG = createFetchTypes('Marketplace/Application/GET_PCC_ORG');
export const GET_PCC_LIST = createFetchTypes('Marketplace/Application/GET_PCC_LIST');
export const GET_ORG_NAME_FROM_PCC = createFetchTypes('Marketplace/Application/GET_ORG_NAME_FROM_PCC');
export const SAVE_AUDIENCE_ACCESS = createFetchTypes('Marketplace/Application/SAVE_AUDIENCE_ACCESS');
export const DELETE_AUDIENCE_ACCESS = createFetchTypes('Marketplace/Application/DELETE_AUDIENCE_ACCESS');
export const GLOBAL_VISIBILITY = createFetchTypes('Marketplace/Application/GLOBAL_VISIBILITY');
export const VISIBILITY_NON_PARTICIPANTS = createFetchTypes('Marketplace/Application/VISIBILITY_NON_PARTICIPANTS');
export const VISIBILITY_EMPLOYEES = createFetchTypes('Marketplace/Ap/VISIBILITY_EMPLOYEES');
export const GET_AUDIENCE = createFetchTypes('Marketplace/Application/GET_AUDIENCE');
export const initAudiencePage = (data) => ({
  type: INIT_AUDIENCE_PAGE,
  data
});

export const getUserNames = {
  base: (data) => ({
    type: GET_USER_NAMES.BASE,
    url: 'ProductAccess/GetUserNames',
    data
  }),
  request: () => ({ type: GET_USER_NAMES.REQUEST }),
  success: payload => ({ type: GET_USER_NAMES.SUCCESS, payload }),
  failure: error => ({ type: GET_USER_NAMES.FAILURE, error }),
};

export const getProductAccess = {
  base: (data) => ({
    type: GET_PRODUCT_ACCESS.BASE,
    url: 'ProductAccess/GetPagedProductAccessList',
    data
  }),
  request: () => ({ type: GET_PRODUCT_ACCESS.REQUEST }),
  success: payload => ({ type: GET_PRODUCT_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: GET_PRODUCT_ACCESS.FAILURE, error }),
};

export const getAudience = {
  base: (data) => ({
    type: GET_AUDIENCE.BASE,
    data
  }),
  request: () => ({ type: GET_AUDIENCE.REQUEST }),
  success: payload => ({ type: GET_AUDIENCE.SUCCESS, payload }),
  failure: error => ({ type: GET_AUDIENCE.FAILURE, error }),
};

export const saveAudienceAccess = {
  base: (data) => ({
    type: SAVE_AUDIENCE_ACCESS.BASE,
    url: 'ProductAccess/SaveProductAccess',
    data
  }),
  request: () => ({ type: SAVE_AUDIENCE_ACCESS.REQUEST }),
  success: payload => ({ type: SAVE_AUDIENCE_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: SAVE_AUDIENCE_ACCESS.FAILURE, error }),
};

export const deleteAudienceAccess = {
  base: (data) => ({
    type: DELETE_AUDIENCE_ACCESS.BASE,
    url: 'ProductAccess/removeproductaccess',
    data
  }),
  request: () => ({ type: DELETE_AUDIENCE_ACCESS.REQUEST }),
  success: payload => ({ type: DELETE_AUDIENCE_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: DELETE_AUDIENCE_ACCESS.FAILURE, error }),
};

export const changeGlobalAssignment = {
  base: (data) => ({
    type: GLOBAL_VISIBILITY.BASE,
    url: 'productaccess/changeglobalassignment',
    data
  }),
  request: () => ({ type: GLOBAL_VISIBILITY.REQUEST }),
  success: payload => ({ type: GLOBAL_VISIBILITY.SUCCESS, payload }),
  failure: error => ({ type: GLOBAL_VISIBILITY.FAILURE, error }),
};

export const changeVisibilityNonParticipants = {
  base: (data) => ({
    type: VISIBILITY_NON_PARTICIPANTS.BASE,
    url: 'productaccess/changevisibilityfornonparticipants',
    data
  }),
  request: () => ({ type: VISIBILITY_NON_PARTICIPANTS.REQUEST }),
  success: payload => ({ type: VISIBILITY_NON_PARTICIPANTS.SUCCESS, payload }),
  failure: error => ({ type: VISIBILITY_NON_PARTICIPANTS.FAILURE, error }),
};

export const changeVisibilityTPEmployees = {
  base: (data) => ({
    type: VISIBILITY_EMPLOYEES.BASE,
    url: 'productaccess/changevisibilityfortpemployees',
    data
  }),
  request: () => ({ type: VISIBILITY_EMPLOYEES.REQUEST }),
  success: payload => ({ type: VISIBILITY_EMPLOYEES.SUCCESS, payload }),
  failure: error => ({ type: VISIBILITY_EMPLOYEES.FAILURE, error }),
};
