const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const CONFIRMATION_ACCEPTED = 'CONFIRMATION_ACCEPTED';
export const CONFIRMATION_REJECTED = 'CONFIRMATION_REJECTED';

export const showConfirmationModal = (text, subtext) => ({
  type: SHOW_CONFIRMATION_MODAL,
  payload: {
    text,
    subtext
  }
});

export const confirmationAccepted = () => ({
  type: CONFIRMATION_ACCEPTED
});

export const confirmationRejected = () => ({
  type: CONFIRMATION_REJECTED
});

export const confirmationModalReducer = (state = { showConfirmationModal: false, text: '', subtext: '' }, action = {}) => {
  switch (action.type) {
    case SHOW_CONFIRMATION_MODAL:
      return { showConfirmationModal: true, text: action.payload.text, subtext: action.payload.subtext };
    case CONFIRMATION_ACCEPTED:
    case CONFIRMATION_REJECTED:
      return { showConfirmationModal: false, text: '', subtext: '' };
    default:
      return state;
  }
};
