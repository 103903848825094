/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';

class MpTextCollapse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLess: true
    };
  }

  toggleState = () => {
    this.setState({ showLess: !this.state.showLess });
  };

  render() {
    const lessTextToShow = `${this.props.text.substr(0, this.props.maxlength)} ...`;
    const displayText = (this.state.showLess && this.props.text.length > this.props.maxlength) ? lessTextToShow : this.props.text;
    const showMoreLessText = this.state.showLess
      ? this.props.showMoreText : this.props.showLessText;
    return (
      <p>
        {displayText}
&nbsp;
        {!this.state.showLess && <br />}
        {this.props.text.length > this.props.maxlength
          && <Button className="linkbtnDesign" onClick={() => this.toggleState()}>{showMoreLessText}</Button>}
      </p>
    );
  }
}

MpTextCollapse.propTypes = {
  text: PropTypes.string,
  maxlength: PropTypes.number,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string
};

MpTextCollapse.defaultProps = {
  maxlength: 300,
  showMoreText: 'Read more...',
  showLessText: 'Read less...'
};

export default MpTextCollapse;
