/* eslint-disable func-names */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import authorizationRules from 'components/Authorization/authorizationRules';
import Auth from 'components/Authorization/auth';
import LoaderComponent from 'components/common/Loader/LoaderComponent';

export default function (ComposedComponent, customProps) {
  class Authorization extends Component {
    createAuth = () => new Auth(
      this.props.user,
      authorizationRules,
      customProps,
      this.props.view,
      this.props.location.pathname
    );

    renderUnAuthorized = () => {
      if (localStorage.isLoggingOut === 'true' || localStorage.updatingClaims === 'true' || !this.props.userSearchComplete) {
        return <LoaderComponent show />;
      }
      return (
        <div className="container pad-top">
          <h2>
            You have been logged out or are not authorized to access this content.
            <br />
            Please login with appropriate credentials.
          </h2>
        </div>
      );
    }

    renderComposedComponent = () => {
      const props = { ...this.props, ...customProps };
      if (this.props.user) {
        localStorage.updatingClaims = false;
      }
      return <ComposedComponent {...props} />;
    };

    render() {
      if (this.props.location.pathname !== '/callback') {
        if (this.props.userSearchComplete) {
          if (this.props.user) {
            const auth = this.createAuth();
            if (auth.checkRole(this.props.user)) {
              return this.renderComposedComponent();
            }
          }
        }
        return this.renderUnAuthorized();
      }

      return this.renderComposedComponent();
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.oidc.user,
      view: state.view.view,
      userSearchComplete: state.view.userSearchComplete,
      isLoadingUser: state.oidc.isLoadingUser
    };
  }

  Authorization.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    view: PropTypes.string,
    userSearchComplete: PropTypes.bool,
    isLoadingUser: PropTypes.bool
  };

  return connect(
    mapStateToProps,
    null
  )(Authorization);
}
