/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import MpDatePicker from 'components/common/FormInputs/Datepicker/MpDatePicker';
import DisplayTable from './DisplayTable';

export const ReportsComponent = (props) => (
  <>
    <h2>{props.reportName}</h2>
    <div className="container">
      <div className="no-margin">
        <div className="row">
          <Link to="/admin/reports">&lt; Back to Reports</Link>
        </div>
        <div className="row no-space">
          <h4>FILTER REPORT</h4>
        </div>
        <div id="filter-report-generator" className="row">
          <div className="col-sm-5">
            <div className="col-sm-5 col-sm-offset-1">
              <span
                id="datepicker-start-date"
                className="input-append date"
                data-date="12/1/2013"
                data-date-format="mm/dd/yyyy"
              >
                <label htmlFor="startDate">From:</label>
                <span
                  className="add-on"
                  style={{ display: 'inline-block' }}
                >
                  <MpDatePicker
                    value={props.startDate}
                    onChange={e => props.datehandler(e, 'startDate')}
                    isValidDate={current => props.disableFuturePastDt(current)}
                  />
                </span>
              </span>
            </div>
            <div className="col-sm-5 col-sm-offset-1">
              <span
                id="datepicker-end-date"
                className="input-append date"
                data-date="12/31/2013"
                data-date-format="mm/dd/yyyy"
              >
                <label htmlFor="endDate">To:</label>
                <span
                  className="add-on"
                  style={{ display: 'inline-block' }}
                >
                  <MpDatePicker
                    value={props.endDate}
                    onChange={e => props.datehandler(e, 'endDate')}
                    isValidDate={current => props.disableFuturePastDt(current)}
                  />
                </span>
              </span>
            </div>
          </div>
          <div className="col-sm-3 pad-left">
            <SimplePrettyCheckBox
              name="ExcludeTPEmployees"
              value={props.excludeTPEmployees}
              onChange={(checked) => props.handleTPemp(checked)}
              displayText="Exclude Travelport Employees"
            />
          </div>
          <div className="col-sm-1 col-sm-offset-1 pad-left">
            <Link
              to="#"
              className="action-button"
              onClick={props.searchHandler}
            >
              Search
            </Link>
          </div>
          <div className="row-sm-6 row-sm-offset-6 pad-left">
            <Link
              to="#"
              className="action-button"
              onClick={props.handleClear}
            >
              Clear
            </Link>
          </div>
        </div>
        <div className="row text-right ajax-dependent-div">
          <Link
            to="#"
            id="downloadExcel"
            className="text-link-with-icon-left"
            onClick={props.exportToExcel}
          >
            <span className="download-icon" />
            Download in Microsoft Excel
          </Link>
        </div>
        <DisplayTable
          columns={props.columnDefinitions}
          rows={props.rows}
          currentPage={props.currentPage}
          pageSize={props.pageSize}
          pageHandler={props.pageHandler}
          totalCount={
            props.totalPages * props.pageSize
          }
          sorting={props.sorting}
          sortHandler={props.sortHandler}
          showSortingControls
        />
      </div>
    </div>
  </>
);

ReportsComponent.propTypes = {
  sortHandler: PropTypes.func,
  sorting: PropTypes.object,
  reportName: PropTypes.string,
  startDate: PropTypes.string,
  datehandler: PropTypes.func,
  disableFuturePastDt: PropTypes.func,
  endDate: PropTypes.func,
  excludeTPEmployees: PropTypes.bool,
  exportToExcel: PropTypes.func,
  columnDefinitions: PropTypes.array,
  rows: PropTypes.array,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageHandler: PropTypes.func,
  totalPages: PropTypes.number,
  handleClear: PropTypes.func,
  handleTPemp: PropTypes.func,
  searchHandler: PropTypes.func
};
