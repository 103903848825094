import {
  takeLatest, call, put, select
} from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const MANAGE_LICENSES_SEARCH_URL = 'license/ManageLicensesSearchResult';

const MANAGE_LICENSES_SEARCH = createFetchTypes('Marketplace/Administration/ManageLicenses/ManageLicense/MANAGE_LICENSES_SEARCH');
const CHANGE_SEARCH_TYPE = 'Marketplace/Administration/ManageLicenses/ManageLicense/CHANGE_SEARCH_TYPE';
const SET_CURRENT_SEARCH = 'Marketplace/Administration/ManageLicenses/ManageLicense/SET_CURRENT_SEARCH';
const RESET_MANAGE_LICENSES_SEARCH = 'Marketplace/Administration/ManageLicenses/ManageLicense/RESET_MANAGE_LICENSES_SEARCH';

const tableParamsSelector = state => {
  if (state.administration.manageLicenses.manageLicenseSearch.searchType === 'Agency') {
    return state.administration.manageLicenses.manageLicenseSearch.agencyTableParams;
  }
  return state.administration.manageLicenses.manageLicenseSearch.pluginTableParams;
};

const initialState = {
  searchType: 'Agency',
  agencyTableParams: {
    columnSort: '',
    ascending: true,
    page: 1,
    searchCriteria: ''
  },
  pluginTableParams: {
    columnSort: '',
    ascending: true,
    page: 1,
    searchCriteria: ''
  },
  agencies: {
    data: [],
    totalPages: 0
  },
  plugins: {
    data: [],
    totalPages: 0
  }
};
function ascendingTableParams(action, state) {
  if (action.params.ascending === undefined) {
    if (state.searchType === 'Agency') {
      return state.agencyTableParams.ascending;
    }
    return state.pluginTableParams.ascending;
  }
  return action.params.ascending;
}
function searchCriteria(action, state) {
  if (action.params.searchCriteria === undefined) {
    if (state.searchType === 'Agency') {
      return state.agencyTableParams.searchCriteria;
    }
    return state.pluginTableParams.searchCriteria;
  }
  return action.params.searchCriteria;
}
function manageLicenseSearch(state = initialState, action = {}) {
  switch (action.type) {
    case MANAGE_LICENSES_SEARCH.BASE:
    {
      if (state.searchType === 'Agency') {
        return {
          ...state,
          agencyTableParams: {
            columnSort: action.params.columnSort || state.agencyTableParams.columnSort,
            ascending: ascendingTableParams(action, state),
            page: action.params.page || state.agencyTableParams.page,
            searchCriteria: searchCriteria(action, state)
          }
        };
      }
      return {
        ...state,
        pluginTableParams: {
          columnSort: action.params.columnSort || state.pluginTableParams.columnSort,
          ascending: ascendingTableParams(action, state),
          page: action.params.page || state.pluginTableParams.page,
          searchCriteria: searchCriteria(action, state)
        }
      };
    }
    case MANAGE_LICENSES_SEARCH.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MANAGE_LICENSES_SEARCH.SUCCESS:
    {
      const newState = {
        ...state,
        loading: false,
        error: null
      };
      if (state.searchType === 'Agency') {
        newState.agencies = {
          data: action.data.listOfPluginsForAgency,
          totalPages: action.data.totalPages,
        };
      } else {
        newState.plugins = {
          data: action.data.listOfAgenciesForPlugin,
          totalPages: action.data.totalPages,
        };
      }
      return newState;
    }
    case MANAGE_LICENSES_SEARCH.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CHANGE_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.value,
        agencyTableParams: initialState.agencyTableParams,
        pluginTableParams: initialState.pluginTableParams,
      };
    case SET_CURRENT_SEARCH:
      return {
        ...state,
        currentSearch: action.currentSearch
      };
    case RESET_MANAGE_LICENSES_SEARCH:
      return initialState;
    default:
      return state;
  }
}

export const manageLicensesSearch = {
  base: (params) => ({
    type: MANAGE_LICENSES_SEARCH.BASE,
    params,
    url: MANAGE_LICENSES_SEARCH_URL
  }),
  request: () => ({ type: MANAGE_LICENSES_SEARCH.REQUEST }),
  success: (data) => ({ type: MANAGE_LICENSES_SEARCH.SUCCESS, data }),
  failure: (error) => ({ type: MANAGE_LICENSES_SEARCH.FAILURE, error })
};

export const changeSearchType = (value) => ({ type: CHANGE_SEARCH_TYPE, value });
export const resetManageLicensesSearch = () => ({ type: RESET_MANAGE_LICENSES_SEARCH });
const setCurrentSearch = (currentSearch) => ({ type: SET_CURRENT_SEARCH, currentSearch });

function* manageLicensesSearchSaga({ url, params }) {
  yield put(showLoader());
  try {
    const tableParams = yield select(tableParamsSelector);
    const apiParams = { ...params, ...tableParams };
    yield call(() => fetchEntity(manageLicensesSearch, ApiClient.httpClient.get, url, { params: apiParams }));
    yield put(setCurrentSearch(apiParams.searchCriteria));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* manageLicensesSearchSagaWatcher() {
  yield takeLatest(MANAGE_LICENSES_SEARCH.BASE, manageLicensesSearchSaga);
}

export default manageLicenseSearch;
