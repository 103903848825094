import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchPosDistributionViewModels,
  createPosVersion,
  updatePosVersion,
  deletePosVersion,
  savePosName,
  togglePublish,
  getPosDistributionViewModels,
  isProductVersionControlLoading
} from 'reducers/modules/Administration/ProductVersionControl/productVersionControl';
import { roleNameConstants } from 'utils/rolesHelper';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import ProductVersionTable from './ProductVersionTable';

class ProductVersionControl extends PureComponent {
  componentDidMount() {
    document.title = 'Product Version Control';
    this.props.fetchPosDistributionViewModels();
  }

  render() {
    if (!this.props.posDistributionViewModels) {
      return <LoaderComponent show />;
    }

    const isValidUser = this.props.currentUser && this.props.currentUser.profile && !this.props.currentUser.expired;
    const isUserSuperAdmin = isValidUser && (this.props.currentUser.profile.role === roleNameConstants.MarketplaceTeamAdmin.name);
    return (
      <>
        {this.props.loading && <LoaderComponent show />}
        <h2>Platform Product Version Control</h2>
        <div className="container">
          {this.props.posDistributionViewModels.length < 1
            && (
            <div className="row sp-versions-div" ng-show="posDistributionViewModels.length < 1">
              <p>No platform products have been asigned to you.</p>
            </div>
            )}
          {this.props.posDistributionViewModels.length > 0
            && this.props.posDistributionViewModels.map((posDistVm, index) => (
              <ProductVersionTable
                key={index.toString()}
                isUserSuperAdmin={isUserSuperAdmin}
                indexNumber={index}
                posDistributionViewModel={posDistVm}
                createPosVersion={this.props.createPosVersion}
                updatePosVersion={this.props.updatePosVersion}
                deletePosVersion={this.props.deletePosVersion}
                savePosName={this.props.savePosName}
                togglePublish={this.props.togglePublish}
              />
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  posDistributionViewModels: getPosDistributionViewModels(state),
  loading: isProductVersionControlLoading(state),
  currentUser: state.oidc.user
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosDistributionViewModels: () => dispatch(fetchPosDistributionViewModels.base()),
  createPosVersion: (args) => dispatch(createPosVersion.base(args)),
  updatePosVersion: (args) => dispatch(updatePosVersion.base(args)),
  deletePosVersion: (args) => dispatch(deletePosVersion.base(args)),
  savePosName: (args, callback) => dispatch(savePosName.base(args, callback)),
  togglePublish: (args) => dispatch(togglePublish.base(args))
});

ProductVersionControl.propTypes = {
  posDistributionViewModels: PropTypes.array,
  fetchPosDistributionViewModels: PropTypes.func,
  createPosVersion: PropTypes.func,
  updatePosVersion: PropTypes.func,
  deletePosVersion: PropTypes.func,
  savePosName: PropTypes.func,
  togglePublish: PropTypes.func,
  loading: PropTypes.bool,
  currentUser: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductVersionControl);
