class Auth {
  constructor(user, authorizationRules, customProps, view, locationPathname) {
    this.user = user;
    this.authorizationRules = authorizationRules;
    this.customProps = customProps;
    this.view = view;
    this.locationPathname = locationPathname;
  }

  getPath = (props = false) => {
    let pathname = this.locationPathname;

    if (props !== false) {
      pathname = props.path;
    }

    return pathname;
  };

  allowdToSeeContent = (allowedRoles, role) => allowedRoles.includes(role);

  checkRole() {
    if (this.user === null) {
      return false;
    }

    if (this.user && !this.user.expired) {
      const { role } = this.user.profile;
      const pathname = this.getPath(this.customProps);
      const finalPAth = pathname || this.view;
      const allowedRoles = this.authorizationRules[finalPAth];

      if (typeof allowedRoles !== 'undefined') {
        if (!this.allowdToSeeContent(allowedRoles, role)) {
          return false;
        }
        return true;
      }
    }
    return true;
  }
}

export default Auth;
