/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

class MpDatePicker extends Component {
  renderInput = (props, openCalendar) => (
    <span className="input-append date">
      <span className="add-on">
        <input {...props} name={this.props.name} disabled className="text-input" />
&nbsp;
        <span className="calendar-icon" onClick={openCalendar} />
      </span>
    </span>
  );

  render() {
    return (
      <Datetime
        value={this.props.value}
        defaultValue=""
        renderInput={this.renderInput}
        dateFormat="MM/DD/YYYY"
        timeFormat={false}
        onChange={this.props.onChange}
        isValidDate={this.props.isValidDate}
        closeOnSelect
      />
    );
  }
}

MpDatePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isValidDate: PropTypes.func
};

export default MpDatePicker;
