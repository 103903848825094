import FileDownload from 'js-file-download';
import { takeLatest, call, put } from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

// Fetch Types

const AUDITLOG_DOWNLOAD_EXCEL = createFetchTypes(
  'Marketplace/Agency/AUDITLOG_DOWNLOAD_EXCEL'
);
const PRODUCT_REVIEW_DOWNLOAD_EXCEL = createFetchTypes(
  'Marketplace/Agency/PRODUCT_REVIEW_DOWNLOAD_EXCEL'
);
const USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL = createFetchTypes(
  'Marketplace/Agency/USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL'
);
const USER_LOGIN_REPORT_DOWNLOAD_EXCEL = createFetchTypes(
  'Marketplace/Agency/USER_LOGIN_REPORT_DOWNLOAD_EXCEL'
);
const DEV_STATS_REPORT_DOWNLOAD_EXCEL = createFetchTypes(
  'Marketplace/Agency/DEV_STATS_REPORT_DOWNLOAD_EXCEL'
);
// Action Types

const userLoginReportDownloadExcel = {
  base: params => ({
    type: USER_LOGIN_REPORT_DOWNLOAD_EXCEL.BASE,
    params,
    entity: userLoginReportDownloadExcel,
    url: '/reports/ExportUserLoginReportToExcel'
  }),
  request: () => ({ type: USER_LOGIN_REPORT_DOWNLOAD_EXCEL.REQUEST }),
  success: payload => ({
    type: USER_LOGIN_REPORT_DOWNLOAD_EXCEL.SUCCESS,
    payload
  }),
  failure: error => ({ type: USER_LOGIN_REPORT_DOWNLOAD_EXCEL.FAILURE, error })
};
const userFeedbackReportDownloadExcel = {
  base: params => ({
    type: USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL.BASE,
    params,
    entity: userFeedbackReportDownloadExcel,
    url: '/reports/ExportUserFeedbackReportToExcel'
  }),
  request: () => ({ type: USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL.REQUEST }),
  success: payload => ({
    type: USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL.SUCCESS,
    payload
  }),
  failure: error => ({ type: USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL.FAILURE, error })
};
const productReviewDownloadExcel = {
  base: params => ({
    type: PRODUCT_REVIEW_DOWNLOAD_EXCEL.BASE,
    params,
    entity: productReviewDownloadExcel,
    url: '/reports/ExportProductReviewReportToExcel'
  }),
  request: () => ({ type: PRODUCT_REVIEW_DOWNLOAD_EXCEL.REQUEST }),
  success: payload => ({
    type: PRODUCT_REVIEW_DOWNLOAD_EXCEL.SUCCESS,
    payload
  }),
  failure: error => ({ type: PRODUCT_REVIEW_DOWNLOAD_EXCEL.FAILURE, error })
};
const auditLogDownloadExcel = {
  base: params => ({
    type: AUDITLOG_DOWNLOAD_EXCEL.BASE,
    params,
    entity: auditLogDownloadExcel,
    url: '/reports/auditLogToExcel'
  }),
  request: () => ({ type: AUDITLOG_DOWNLOAD_EXCEL.REQUEST }),
  success: payload => ({
    type: AUDITLOG_DOWNLOAD_EXCEL.SUCCESS,
    payload
  }),
  failure: error => ({ type: AUDITLOG_DOWNLOAD_EXCEL.FAILURE, error })
};

const devStatsReportDownloadExcel = {
  base: params => ({
    type: DEV_STATS_REPORT_DOWNLOAD_EXCEL.BASE,
    params,
    entity: devStatsReportDownloadExcel,
    url: '/reports/ExportDevStatsReportToExcel'
  }),
  request: () => ({ type: DEV_STATS_REPORT_DOWNLOAD_EXCEL.REQUEST }),
  success: payload => ({
    type: DEV_STATS_REPORT_DOWNLOAD_EXCEL.SUCCESS,
    payload
  }),
  failure: error => ({ type: DEV_STATS_REPORT_DOWNLOAD_EXCEL.FAILURE, error })
};
export const exportToExcelFunc = {
  auditLog: params => auditLogDownloadExcel.base(params),
  productReviewReport: params => productReviewDownloadExcel.base(params),
  userFeedbackReport: params => userFeedbackReportDownloadExcel.base(params),
  userLoginReport: params => userLoginReportDownloadExcel.base(params),
  developerStatsReport: params => devStatsReportDownloadExcel.base(params)
};

function* exportToExcelSaga(action) {
  yield put(showLoader());
  try {
    const response = yield call(() => fetchEntity(
      action.entity,
      ApiClient.httpClient.post,
      action.url,
      action.params
    ));
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      let filename = 'report.csv';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
  } catch (error) {
    yield put(hideLoader());
    yield put(showAlertAction(error.response.data));
  }
  yield put(hideLoader());
}

export function* exportToExcelMiddleware() {
  yield takeLatest(AUDITLOG_DOWNLOAD_EXCEL.BASE, exportToExcelSaga);
  yield takeLatest(PRODUCT_REVIEW_DOWNLOAD_EXCEL.BASE, exportToExcelSaga);
  yield takeLatest(USER_LOGIN_REPORT_DOWNLOAD_EXCEL.BASE, exportToExcelSaga);
  yield takeLatest(USER_FEEDBACK_REPORT_DOWNLOAD_EXCEL.BASE, exportToExcelSaga);
  yield takeLatest(DEV_STATS_REPORT_DOWNLOAD_EXCEL.BASE, exportToExcelSaga);
}
