import { put, call } from 'redux-saga/effects';
import { updateFormApplication } from './applicationReducer';

export default function* fetchEntityPPCP(entity, apiFn, ...params) {
  yield put(entity.request());
  try {
    const response = yield call(apiFn, ...params);
    yield put(entity.success(response.data));
    yield put(updateFormApplication(response.data));
    return response;
  } catch (error) {
    yield put(entity.failure(error));
    throw error;
  }
}
