/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  TableHeaderRow,
  Table,
  TableEditColumn,
  TableEditRow,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  PagingState,
  CustomPaging,
  SortingState,
  EditingState
} from '@devexpress/dx-react-grid';
import DataCells from './TableCellComponents/DataCells';
import EditCell from './TableCellComponents/EditCell';
import {
  fetchdistributionAdminAssignments, distributionAdministratorSelector,
  updateDistributionAssignments
} from '../../../../reducers/modules/Administration/DistributionAdminAssignments/distributionAdminAssignments';
import LoaderComponent from '../../../common/Loader/LoaderComponent';

class DistributionAdminAssignment extends Component {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'email', title: 'Email' },
      { name: 'userName', title: 'USERNAME' },
      { name: 'assignedPlatformProducts', title: 'PLATFORM PRODUCT(S)' }
    ];

    this.sortingStateColumnExtensions = [
      { columnName: 'assignedPlatformProducts', sortingEnabled: false },
    ];

    this.state = {
      sorting: {
        columnName: 'userName',
        direction: 'asc',
      },
      paging: {
        currentPage: 0,
        pageSize: 20,
      },
      editingRowIds: []
    };
  }

  componentDidMount() {
    document.title = 'Admin Assignments';
    this.props.fetchDistributionAdminRoles({ currentPage: this.state.paging.currentPage + 1 });
  }

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  }

  getSortDirection = () => this.state.sorting.direction === 'asc';

  fetchDistributionAdminRoles = (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = {
      sortColumn: this.getSortColumn(),
      ascending: this.getSortDirection(),
      currentPage: this.state.paging.currentPage + 1
    };
    this.props.fetchDistributionAdminRoles(params);
  };

  handleEditRow = (rows) => {
    const row = rows.slice(-1);
    this.setState({ editingRowIds: row });
  };

  sortHandler = (newSorting) => this.setState({ sorting: { ...newSorting[0] } }, this.fetchDistributionAdminRoles);

  commitChanges = ({ changed }) => {
    const entity = { ...this.props.distributionAdmin.data[this.state.editingRowIds], ...changed[this.state.editingRowIds] };
    const query = {};
    if (this.getSortColumn()) {
      query.sortColumn = this.getSortColumn();
      query.ascending = this.getSortDirection();
    }
    query.currentPage = 1;
    const params = {
      userId: entity.id,
      platformProductIds: entity.assignedPlatformProducts.map(r => r.value),
      queryString: query
    };
    this.props.updateDistributionAssignments(params);
  };

  pageHandler = (currentPage) => { this.setState({ paging: { ...this.state.paging, currentPage } }, this.fetchDistributionAdminRoles); window.scrollTo(0, 0); }

  render() {
    if (!this.props.distributionAdmin.data) {
      return <LoaderComponent show />;
    }
    return (
      <>
        <h2>Organization, user and distribution management</h2>
        <div className="container">
          <div className="row">
            <Link to="/admin/home">&lt; Back to Administration</Link>
          </div>
          <div className="row bold-font space-top-double space-bottom-double">
            <span className="space-left-double"><Link to="/admin/home/organizationDirectory">Organization Directory</Link></span>
            <span className="space-left-double"><Link to="/admin/manageUserRoles">Assign Roles</Link></span>
            <span className="space-left-double">TP Platform Product Admin Assignments</span>
          </div>
          <div id="filter-report-generator" className="product-download-filter-report row">
            <div className="row">
              <div className="col-sm-7 col-sm-offset-1">
                <div className="col-sm-3 text-right space-top-sm">
                  View By Role:&nbsp;
                </div>
                <div className="col-sm-6 space-top-sm">
                  &nbsp;TP Platform Product Admin
                </div>
              </div>
            </div>
          </div>
          <div id="table-div" className="row">
            <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
              <Grid columns={this.columnDefinitions} rows={this.props.distributionAdmin.data}>
                <EditingState
                  editingRowIds={this.state.editingRowIds}
                  onEditingRowIdsChange={this.handleEditRow}
                  onCommitChanges={this.commitChanges}
                />
                <PagingState
                  currentPage={this.state.paging.currentPage}
                  pageSize={this.state.paging.pageSize}
                  onCurrentPageChange={this.pageHandler}
                />
                <CustomPaging
                  totalCount={this.props.distributionAdmin.totalPages * this.state.paging.pageSize}
                />
                <SortingState
                  sorted={this.sorting}
                  defaultSorting={[{ columnName: 'userName', direction: 'asc' }]}
                  onSortingChange={this.sortHandler}
                  columnExtensions={this.sortingStateColumnExtensions}
                />
                <Table cellComponent={DataCells} />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  showEditCommand
                />
                <TableEditRow
                  cellComponent={(props) => <EditCell {...props} platformlist={this.props.distributionAdmin.platformList} />}
                />
                <PagingPanel pageSizes={[]} />
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  distributionAdmin: distributionAdministratorSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchDistributionAdminRoles: (args) => dispatch(fetchdistributionAdminAssignments.base(args)),
  updateDistributionAssignments: (args) => dispatch(updateDistributionAssignments.base(args))
});

DistributionAdminAssignment.propTypes = {
  distributionAdmin: PropTypes.object,
  distributionAdminRoles: PropTypes.func,
  updateDistributionAdminRoles: PropTypes.func,
  fetchDistributionAdminRoles: PropTypes.func,
  updateDistributionAssignments: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionAdminAssignment);
