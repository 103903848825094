import React from 'react';

const BlockedUserPage = () => (
  <div className="container">
    <h2> Blocked User </h2>
    <div className="large-error row">
      User is blocked from logging in to Marketplace.
      <br className="small-view-br" />
      {' '}
      Please contact your Agency Admin or Account Manager.
      <br className="small-view-br" />
      {' '}
      For any further assistance kindly visit Contact us page or FAQs section.
    </div>
  </div>
);

export default BlockedUserPage;
