import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector, change } from 'redux-form';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import {
  MarketplaceDatagrid,
  CELL_DROPDOWN_TYPE,
  CELL_MULTISELECT_TYPE
} from 'components/common';
import {
  PRICEBYLOCATION_SALETYPE_NONE,
  PRICEBYLOCATION_SALETYPE_RESELLER,
  PRICEBYLOCATION_SALETYPE_REFERRAL,
  DISTRIBUTION_FREE,
  DISTRIBUTION_FIXED_PRICE,
  DISTRIBUTION_CUSTOM,
  DISTRIBUTION_CUSTOM_PRICING_LOCATION,
  DISTRIBUTION_ID
} from 'constants/PPCP';
import {
  savePriceByLocation,
  deletePriceByLocation,
  priceByLocationSelector
} from 'reducers/modules/Application';
import { getCountriesSelector } from 'reducers/modules/countries';
import {
  CustomPrice
} from 'components/Application';

import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

const PRICE_QUANTITY_VALUE = 'priceQuantityValue';
const CURRENCY_VALUE = 'currencyId';

const FREE_TRIAL = 'freeTrial';
const TRIAL_DAYS = 'trialDays';

const columns = [
  { name: 'countryName', title: 'Location' },
  { name: 'pricingInformation', title: 'Pricing Information' },
  { name: 'pricingNotes', title: 'Pricing Notes' },
  { name: 'saleTypeName', title: 'Sale Type' }
];
// Here is an example of an object that can be used to customize the datagrid edit cell in a reusable manner
// Each property has the same name as a datagrid column
// For each column, we specify the control type we want when entering in edit mode, and the items we want to display in it
const editcell = (countries) => ({
  saleTypeName: {
    editCellTypeCell: CELL_DROPDOWN_TYPE,
    editCellList: [
      {
        text: PRICEBYLOCATION_SALETYPE_NONE,
        value: 0
      },
      {
        text: PRICEBYLOCATION_SALETYPE_RESELLER,
        value: 1
      },
      {
        text: PRICEBYLOCATION_SALETYPE_REFERRAL,
        value: 2
      }
    ],
  },
  countryName: {
    nonEditableCells: ['All Others'],
    editCellTypeCell: CELL_MULTISELECT_TYPE,
    editCellList: countries,
    placeholder: 'Country'
  },
  pricingInformation: {
    placeholder: 'Ex. 50 USD'
  }
});

// This object has properties named like the command buttons
// Each property contains an array of row index where we want that specific command button filtered
// For example, here we are saying that we don't want the delete button for rows 0
const commandButtonFilter = {
  delete: [0]
};

const renderFreeTrial = ({
  input,
  displayText,
  enabled,
  tooltip
}) => (
  <SimplePrettyCheckBox
    {...input}
    value={input.checked}
    displayText={displayText}
    onChange={input.onChange}
    enabled={enabled}
    tooltip={tooltip}
  />
);

const renderDatagrid = (
  priceByLocation,
  countries,
  onSave,
  onDelete,
  showMsg
) => (
  <>
    <div className="row">
      <em>The &quot;All Others&quot; row covers pricing information for all countries that are not specifically listed in this table</em>
    </div>
    <div className="row">
      <div className="pricing-location-table-header" />
      <div className="pricing-location-table-scroll">
        <MarketplaceDatagrid
          rows={priceByLocation}
          columns={columns}
          editcell={editcell(countries)}
          commandButtonFilter={commandButtonFilter}
          onSave={onSave}
          onDelete={onDelete}
          showMsg={showMsg}
        />
      </div>
    </div>
  </>
);

class DistributionPrice extends PureComponent {
  priceChangeValue = () => this.props.changeAction(DISTRIBUTION_ID, FREE_TRIAL, false)

  renderRadioButton = ({
    input,
    displayText,
    tooltip
  }) => (
    <SimplePrettyRadioButton
      {...input}
      displayText={displayText}
      onChange={input.onChange}
      tooltip={tooltip}
    />
  );

  render() {
    const {
      price,
      freeTrial,
      currencyList,
      trialDayList,
      priceCustom,
      priceByLocation,
      countries,
      savePriceByLocationAction,
      deletePriceByLocationAction,
      showMsg
    } = this.props;

    return (
      <div className="distributionPriceLayout pad-top">
        <h4 className="required-field-star">Price</h4>
        <div className="row no-space-bottom">
          <div className="col-sm-2">
            <Field
              id={DISTRIBUTION_FREE}
              name="price"
              displayText="Free"
              onChange={this.priceChangeValue}
              component={this.renderRadioButton}
              type="radio"
              value={DISTRIBUTION_FREE}
            />
          </div>
        </div>
        <div className="row no-space-bottom">
          <div className="col-sm-2">
            <Field id={DISTRIBUTION_FIXED_PRICE} name="price" displayText="Fixed Price" component={this.renderRadioButton} type="radio" value={DISTRIBUTION_FIXED_PRICE} />
          </div>
          {(price === DISTRIBUTION_FIXED_PRICE) && (
          <div className="col-sm-5">
            <Field name={PRICE_QUANTITY_VALUE} className="text-input text-input-md" component="input" type="text" value={PRICE_QUANTITY_VALUE} />
            <Field name={CURRENCY_VALUE} className="select space-left" component="select" value={CURRENCY_VALUE}>
              {currencyList && currencyList.map(c => (<option key={c.value} value={c.value} disabled={c.value === '0'}>{c.text}</option>))}
            </Field>
          </div>
          )}
        </div>
        <div className="row no-space-bottom">
          <div className="col-sm-2">
            <Field id={DISTRIBUTION_CUSTOM} name="price" displayText="Custom" component={this.renderRadioButton} type="radio" value={DISTRIBUTION_CUSTOM} />
          </div>
          {(price === DISTRIBUTION_CUSTOM) && (
          <div className="col-sm-10">
            <CustomPrice />
          </div>
          )}
          {(price === DISTRIBUTION_CUSTOM
            && priceCustom === DISTRIBUTION_CUSTOM_PRICING_LOCATION)
            && renderDatagrid(priceByLocation, countries, savePriceByLocationAction, deletePriceByLocationAction, showMsg)}
        </div>
        <div className="row">
          <div className="col-sm-2">
            <div className="pull-left pad-right-sm">
              <Field
                name={FREE_TRIAL}
                component={renderFreeTrial}
                enabled={(price !== DISTRIBUTION_FREE)}
                displayText="Free Trial?"
                type="checkbox"
                value={FREE_TRIAL}
                tooltip="Do you wish to offer potential customers a free trial?"
              />
            </div>
          </div>
          {freeTrial && (
          <div className="col-sm-2">
            <Field name={TRIAL_DAYS} className="select" component="select" value={TRIAL_DAYS}>
              {trialDayList && trialDayList.map(t => <option key={t.value} value={t.value}>{t.text}</option>)}
            </Field>
          </div>
          )}
        </div>
      </div>
    );
  }
}

const selector = formValueSelector(DISTRIBUTION_ID);
const mapStateToProps = state => ({
  priceByLocation: priceByLocationSelector(state),
  countries: getCountriesSelector(state),
  freeTrial: selector(state, FREE_TRIAL)
});

const mapDispatchToProps = dispatch => ({
  savePriceByLocationAction: (args) => dispatch(savePriceByLocation.base(args)),
  deletePriceByLocationAction: (args) => dispatch(deletePriceByLocation.base(args)),
  changeAction: (form, field, value) => dispatch(change(form, field, value)),
  showMsg: (msg) => dispatch(showAlertAction(msg))
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributionPrice);

renderFreeTrial.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  enabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

DistributionPrice.defaultProps = {
  priceByLocation: []
};

DistributionPrice.propTypes = {
  price: PropTypes.string,
  priceCustom: PropTypes.string,
  freeTrial: PropTypes.string,
  currencyList: PropTypes.array,
  trialDayList: PropTypes.array,
  priceByLocation: PropTypes.array,
  countries: PropTypes.array,
  savePriceByLocationAction: PropTypes.func,
  deletePriceByLocationAction: PropTypes.func,
  changeAction: PropTypes.func,
  showMsg: PropTypes.func,
};
