import { takeLatest, call, put } from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import * as Config from 'config/apiConfig';

const AUTO_UPGRADE_ADMIN_URL = 'account/agencyadminloginvalidate';
const DECLINE_AUTO_UPGRADE_ADMIN_URL = 'account/declinerolepromotion';
const CHECK_ROLE_PROMITION_URL = 'account/checkrolepromotioneligibility';

const AUTO_UPGRADE_ADMIN = createFetchTypes('Marketplace/auth/autoupgradeadmin');
const DECLINE_AUTO_UPGRADE_ADMIN = createFetchTypes('Marketplace/auth/declineautoupgradeadmin');
const CHECK_ROLE_PROMITION = createFetchTypes('Marketplace/auth/checkrolepromotion');

const HIDE_PROMOTE_MODAL = createFetchTypes('Marketplace/auth/hidepromtemodal');

function autoUpgradeAdminReducer(state = {}, action = {}) {
  switch (action.type) {
    case AUTO_UPGRADE_ADMIN.REQUEST:
    case DECLINE_AUTO_UPGRADE_ADMIN.REQUEST:
    case CHECK_ROLE_PROMITION.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case AUTO_UPGRADE_ADMIN.SUCCESS:
    case DECLINE_AUTO_UPGRADE_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case AUTO_UPGRADE_ADMIN.FAILURE:
    case DECLINE_AUTO_UPGRADE_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CHECK_ROLE_PROMITION.SUCCESS:
      return {
        ...state,
        checkRolePromotionResult: action.data.canBePromoted,
        loading: false,
        error: null
      };
    case CHECK_ROLE_PROMITION.FAILURE:
      return {
        ...state,
        checkRolePromotionResult: false,
        loading: false,
      };
    case HIDE_PROMOTE_MODAL:
      return {
        ...state,
        checkRolePromotionResult: false
      };
    default:
      return state;
  }
}

export const hideRolePromoteModal = () => ({ type: HIDE_PROMOTE_MODAL });

export const autoUpgradeAction = {
  base: () => ({
    type: AUTO_UPGRADE_ADMIN.BASE,
    url: AUTO_UPGRADE_ADMIN_URL,
  }),
  request: () => ({
    type: AUTO_UPGRADE_ADMIN.REQUEST
  }),
  success: () => ({
    type: AUTO_UPGRADE_ADMIN.SUCCESS,
  }),
  failure: () => ({
    type: AUTO_UPGRADE_ADMIN.FAILURE,
  })
};

export const declineUpgradeAction = {
  base: () => ({
    type: DECLINE_AUTO_UPGRADE_ADMIN.BASE,
    url: DECLINE_AUTO_UPGRADE_ADMIN_URL,
  }),
  request: () => ({
    type: DECLINE_AUTO_UPGRADE_ADMIN.REQUEST
  }),
  success: () => ({
    type: DECLINE_AUTO_UPGRADE_ADMIN.SUCCESS,
  }),
  failure: () => ({
    type: DECLINE_AUTO_UPGRADE_ADMIN.FAILURE,
  })
};

export const checkRolePromotion = {
  base: () => ({
    type: CHECK_ROLE_PROMITION.BASE,
    url: CHECK_ROLE_PROMITION_URL,
  }),
  request: () => ({
    type: CHECK_ROLE_PROMITION.REQUEST
  }),
  success: (data) => ({
    type: CHECK_ROLE_PROMITION.SUCCESS,
    data
  }),
  failure: () => ({
    type: CHECK_ROLE_PROMITION.FAILURE,
  })
};

export function* autoUpgradeActionSaga(action) {
  try {
    yield call(() => fetchEntity(autoUpgradeAction, ApiClient.httpClient.post, action.url));
    localStorage.updatingClaims = true;

    sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
    window.location = `${Config.BASE_URL}login`;
  } catch (error) {
    yield put(autoUpgradeAction.failure());
  }
}

export function* declineUpgradeActionSaga(action) {
  try {
    yield call(() => fetchEntity(declineUpgradeAction, ApiClient.httpClient.post, action.url));
  } catch (error) {
    yield put(autoUpgradeAction.failure());
  }
}

export function* checkRolePromotionSaga(action) {
  try {
    yield call(() => fetchEntity(checkRolePromotion, ApiClient.httpClient.get, action.url));
  } catch (error) {
    yield put(autoUpgradeAction.failure());
  }
}

export function* watchAutoUpgradeActionSaga() {
  yield takeLatest(AUTO_UPGRADE_ADMIN.BASE, autoUpgradeActionSaga);
}

export function* watchDeclineUpgradeActionSaga() {
  yield takeLatest(DECLINE_AUTO_UPGRADE_ADMIN.BASE, declineUpgradeActionSaga);
}

export function* watchCheckRolePromotionSaga() {
  yield takeLatest(CHECK_ROLE_PROMITION.BASE, checkRolePromotionSaga);
}

export default autoUpgradeAdminReducer;
