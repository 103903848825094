import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import * as apiConfig from 'config/apiConfig';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

export const FETCH_ACCOUNT_SETTING = createFetchTypes('Marketplace/Profile/AccountSetting/FETCH_ACCOUNT_SETTING');
export const FETCH_ACCOUNT_SETTING_NEW_SOLUTION = createFetchTypes('Marketplace/Profile/AccountSetting/FETCH_ACCOUNT_SETTING_NEW_SOLUTION');
export const FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY = createFetchTypes('Marketplace/Profile/AccountSetting/FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY');
export const FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY = createFetchTypes('Marketplace/Profile/AccountSetting/FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY');

export const getUserProfileId = (state) => state.profile
  && state.profile.userProfile
  && state.profile.userProfile.userDetails
  && state.profile.userProfile.userDetails.userId;

export const accountSettingReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTING.REQUEST:
    case FETCH_ACCOUNT_SETTING_NEW_SOLUTION.REQUEST:
    case FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.REQUEST:
    case FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null
      };
    case FETCH_ACCOUNT_SETTING.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.accountSetting,
        error: null
      };
    case FETCH_ACCOUNT_SETTING.FAILURE:
    case FETCH_ACCOUNT_SETTING_NEW_SOLUTION.FAILURE:
    case FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.FAILURE:
    case FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    case FETCH_ACCOUNT_SETTING_NEW_SOLUTION.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.accountSettingNewSolution,
        error: null
      };
    case FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.accountSettingPluginForAgency,
        error: null
      };
    case FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.accountSettingVisibilityForAgency,
        error: null
      };
    default:
      return state;
  }
};

export const fetchAccountSetting = {
  base: (params) => ({
    type: FETCH_ACCOUNT_SETTING.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_ACCOUNT_SETTING.REQUEST
  }),
  success: (data) => ({
    type: FETCH_ACCOUNT_SETTING.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_ACCOUNT_SETTING.FAILURE,
    payload: {
      error
    }
  })
};

export const fetchAccountSettingNewSolution = {
  base: (params) => ({
    type: FETCH_ACCOUNT_SETTING_NEW_SOLUTION.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_ACCOUNT_SETTING_NEW_SOLUTION.REQUEST
  }),
  success: (data) => ({
    type: FETCH_ACCOUNT_SETTING.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_ACCOUNT_SETTING_NEW_SOLUTION.FAILURE,
    payload: {
      error
    }
  })
};

export const fetchAccountSettingPluginOptionForAgency = {
  base: (params) => ({
    type: FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.REQUEST
  }),
  success: (data) => ({
    type: FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.FAILURE,
    payload: {
      error
    }
  })
};

export const fetchAccountSettingVisibilityForAgency = {
  base: (params) => ({
    type: FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.BASE,
    payload: {
      params
    }
  }),
  request: () => ({
    type: FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.REQUEST
  }),
  success: (data) => ({
    type: FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.SUCCESS,
    payload: {
      data
    }
  }),
  failure: (error) => ({
    type: FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.FAILURE,
    payload: {
      error
    }
  })
};

export function* accountSettingSaga(param) {
  yield put(showLoader());
  try {
    const { userId } = param.payload.params;
    const emailOn = param.payload.params.checked;
    const url = `${apiConfig.PUT_UPDATE_EMAIL_OPTION_FOR_NEW_VERSION}?userId=${userId}&emailOn=${emailOn}`;
    yield call(() => fetchEntity(fetchAccountSetting, ApiClient.httpClient.put, url));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* accountSettingSagaNewSolution(param) {
  yield put(showLoader());
  try {
    const { userId } = param.payload.params;
    const emailOn = param.payload.params.checked;
    const url = `${apiConfig.PUT_UPDATE_EMAIL_OPTION_FOR_NEW_SOLUTION}?userId=${userId}&emailOn=${emailOn}`;
    yield call(() => fetchEntity(fetchAccountSettingNewSolution, ApiClient.httpClient.put, url));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* accountSettingSagaPluginOptionForAgency(param) {
  yield put(showLoader());
  try {
    const url = `${apiConfig.PUT_UPDATE_PLUGIN_ADMIN_OPTION_FOR_AGENCY}`;
    yield call(() => fetchEntity(fetchAccountSettingPluginOptionForAgency, ApiClient.httpClient.put, url, param.payload.params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* accountSettingSagaVisibilityForAgency(param) {
  yield put(showLoader());
  try {
    const url = `${apiConfig.PUT_UPDATE_ASSIGNED_SOLUTIONS_VISIBLITY_FOR_AGENCY}`;
    const data = param;
    yield call(() => fetchEntity(fetchAccountSettingVisibilityForAgency, ApiClient.httpClient.put, url, data.payload.params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* watchAccountSettingSaga() {
  yield takeLatest(FETCH_ACCOUNT_SETTING.BASE, accountSettingSaga);
}

export function* watchAccountSettingNewSolutionSaga() {
  yield takeLatest(FETCH_ACCOUNT_SETTING_NEW_SOLUTION.BASE, accountSettingSagaNewSolution);
}

export function* watchAccountSettingPluginOptionForAgencySaga() {
  yield takeLatest(FETCH_ACCOUNT_SETTING_PLUGIN_FOR_AGENCY.BASE, accountSettingSagaPluginOptionForAgency);
}

export function* watchAccountSettingSolutionVisibilityForAgencySaga() {
  yield takeLatest(FETCH_ACCOUNT_SETTING_VISIBILITY_FOR_AGENCY.BASE, accountSettingSagaVisibilityForAgency);
}
