/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';
import { withRouter } from 'react-router-dom';
import {
  fetchOrderedSolutionSet,
} from 'reducers/modules/Profile/Solution/mySolution';

class SpcNotification extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchOrderedTP();
  }

  fetchOrderedTP = () => {
    // const orgId = this.props.currentUserOrgId;
    this.props.fetchOrderedTP(this.props.currentUserOrgId, 'spcNotification');
  };

  handleClick = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    window.open(this.props.NetworkURL, '_blank', 'noopener,noreferrer');
    popUpHide();
  }

  hideModal = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  handleAssignmentClick = (e) => {
    e.preventDefault();
    const tpData = this.props.orderedTPSolutions.filter(o => o.name === this.props.productName);
    const platformId = 2;
    this.props.history.push(`/admin/licenses/manageLicenseAssignments?licId=${tpData[0].licenseId}&platformId=${platformId}`);
  }

  render() {
    return (
      <Dialog
        appearance="success"
        id="sbDialog"
        hidden={!this.props.showModal}
        buttons={
          (
            <>
              <Button
                className="action-button"
                appearance="primary"
                onClick={this.hideModal}
              >
                Done
              </Button>
              <Button
                className="cancel-button space-left"
                onClick={this.handleClick}
              >
                Go to Smartpoint Cloud
              </Button>
              {((this.props.usersRole === 'MarketplaceTeamAdmin') || (this.props.usersRole === 'TPAdmin') || (this.props.usersRole === 'AgencyAdmin') || (this.props.usersRole === 'AgencySuperAdmin'))
                && (
                  <Button
                    className="cancel-button space-left"
                    onClick={this.handleAssignmentClick}
                  >
                    Assignments
                  </Button>
                )}

            </>
          )
        }
      >
        {`${this.props.productName} is now available on your Smartpoint Cloud account.`}
      </Dialog>
    );
  }
}

SpcNotification.propTypes = {
  NetworkURL: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  productName: PropTypes.string,
  popUpHide: PropTypes.func,
  fetchOrderedTP: PropTypes.func,
  orderedTPSolutions: PropTypes.array,
  currentUserOrgId: PropTypes.number,
  usersRole: PropTypes.string,
};

SpcNotification.defaultProps = {
  orderedTPSolutions: [],
};

const mapStateToProps = state => ({
  orderedTPSolutions: state.solution.ordered.data,
});

const mapDispatchToProps = dispatch => ({
  fetchOrderedTP: bindActionCreators(fetchOrderedSolutionSet, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(SpcNotification)
);
