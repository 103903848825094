import moment from 'moment';

class DateTimeHelper {
  getDate = (dateString, dateFormat = 'MM/DD/YYYY') => (
    dateString && moment(dateString).format('MM/DD/YYYY') !== '01/01/0001'
      ? moment(dateString).format(dateFormat)
      : ''
  )

  getTimeAmPm = (dateString, dateFormat = 'MM/DD/YYYY HH:mm A') => (
    dateString && moment(dateString).format('MM/DD/YYYY') !== '01/01/0001'
      ? moment(dateString).format(dateFormat)
      : ''
  )

  getDateTime = (dateString, dateFormat = 'MM-DD-YYYY HH:mm:ss') => (
    dateString && moment(dateString).format('MM/DD/YYYY') !== '01/01/0001'
      ? moment(dateString).format(dateFormat)
      : ''
  )

  getPriorDate = (dateFormat, days) => (
    moment().subtract(days, 'days').format(dateFormat)
  )

  getCurrentDate = (dateFormat) => (
    moment().format(dateFormat)
  )

  disableFuturePastDt = (current) => {
    const utcDate = new Date(moment.utc().format());
    const pasttwoyears = moment().subtract(2, 'years');
    return moment(current).isBefore(moment(utcDate)) && moment(current).isAfter(pasttwoyears);
  };
}

export default new DateTimeHelper();
