import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { SEOFriendly } from 'utils/SeoHelper';
import StarComponent from '../StarComponent';
import PriceType from '../PriceType/PriceType';
import Image from '../Image/Image';

class ProductTile extends Component {
  getGtmEventLabel = () => `Solution:${this.props.applicationName} / ${this.props.developerName}`;

  getPricing() {
    if (this.props.isFree) {
      return <PriceType type="Free" gtmEventLabel={this.getGtmEventLabel()} />;
    }
    if (this.props.isVaries) {
      if (this.props.hasTrialPeriod) {
        return <PriceType type="FreeTrial" gtmEventLabel={this.getGtmEventLabel()} />;
      }
      return <PriceType type="Paid" gtmEventLabel={this.getGtmEventLabel()} />;
    }
    return <PriceType type="Paid" gtmEventLabel={this.getGtmEventLabel()} />;
  }

  PopulateClassName(classname) {
    if (this.props.gtmFeatured) {
      return `${classname} gtm-featured-product-link`;
    }
    return classname;
  }

  render() {
    const gtmEventLabel = this.getGtmEventLabel();
    const NetRating = () => {
      const starProperty = {
        edit: false,
        value: this.props.netRating
      };

      return (
        <span className="productRatingSpan" gtm-event-label={gtmEventLabel}>
          <StarComponent {...starProperty} color2="#4E6659" />
        </span>
      );
    };

    return (
      <>
        <div className="col-xs-12 col-sm-3">
          <a
            gtm-event-label={gtmEventLabel}
            className={this.PopulateClassName(this.props.showWhiteTile ? 'pop-products-tile pop-products-tile--white' : 'pop-products-tile')}
            href={`/Product/${SEOFriendly(this.props.applicationName)}`}
          >
            <Image
              gtm-event-label={gtmEventLabel}
              data={{ className: this.PopulateClassName(''), applicationName: this.props.applicationName, cdnLogoURL: this.props.cdnLogoUrl }}
            />
            <span gtm-event-label={gtmEventLabel} className={this.PopulateClassName('pop-products-title')}>
              {this.props.applicationName}
            </span>
            <span gtm-event-label={gtmEventLabel} className={this.PopulateClassName('pop-products-sugar')}>
              {this.props.netRating !== 0 ? <NetRating /> : null}
              <span>{this.getPricing()}</span>
            </span>
            {this.props.showNewButton ? <PriceType type="New" /> : null}
          </a>
        </div>
      </>
    );
  }
}

ProductTile.propTypes = {
  applicationName: PropTypes.string,
  developerName: PropTypes.string,
  logoUrl: PropTypes.string,
  cdnLogoUrl: PropTypes.string,
  isFree: PropTypes.bool,
  hasTrialPeriod: PropTypes.bool,
  isVaries: PropTypes.bool,
  showNewButton: PropTypes.bool,
  netRating: PropTypes.number,
  gtmFeatured: PropTypes.bool,
  showWhiteTile: PropTypes.bool
};

export default ProductTile;
