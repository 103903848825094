/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as queryString from 'query-string';
import { agencyProfilePublicGuidSelector } from 'reducers/modules/Profile/Agency/AgencyProfile/AgencyProfile';
import * as SeoHelper from 'utils/SeoHelper';
import AddScreenShot from '../../common/AddScreenShot';
import VideoScreenShotComponent from '../../common/VideoScreenShotComponent';
import * as Config from '../../../config/apiConfig';
import Counter from './Counter';
import {
  loadFieldsforDeveloperEdit,
  getDeveloperOrgDetails,
  loadDeveloperOrgDetails,
  loadDeveloperOrgSave,
  loadDeveloperOrgSaveSuccess
} from '../../../actions/DeveloperProfile/developerProfile.Action';
import * as Utils from '../../../utils/Common.Utils';
import { showLoader, hideLoader } from '../../../reducers/popUps/loaderReducer';
import { showAlertAction } from '../../../reducers/popUps/alertPopUpReducer';
import {
  saveAgencyLogo
} from '../../../reducers/modules/Profile/userProfileReducer';
import ProfileImagePopup from '../../common/ProfileImagePopUp';
import AgencyLogo from './AgencyLogo';
import CustomDevelopment from './CustomDevelopment';
import LocationList from './LocationList';
import AdditionalLocation from './AdditionalLocation';
import PartnerLevel from './PartnerLevel';
import VideoRequiredMessage from './VideoRequiredMessage';
import ImageRequiredMessage from './ImageRequiredMessage';
import FieldDataComponent from './FieldDataComponent';
import ToolTip from '../../common/ToolTip';

class DeveloperProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.loadDeveloperInformation(props.getDeveloperOrgDetails),
      additionalLocation: '',
      openAdditionalLocation: false,
      publicGuid: props.publicGuid,
      errorMessage: '',
      showErrorMessage: false,
      showModal: false
    };
    this.getVideoList = this.getVideoList.bind(this);
  }

  componentDidMount = () => {
    document.title = 'Developer Profile Page';
    Utils.GetService(
      `${Config.BASE_URL}${Config.GET_FIELDSFOR_DEVELOPER_EDIT}`
    ).then(res => {
      this.props.loadFieldsforDeveloperEditAction(res);
    });
    this.props.loadDeveloperOrgDetailsAction();
    this.props.showLoader();

    let { publicGuid } = this.props;
    if (this.props.match.path === '/Account/MyProfile') {
      if (this.props.agencyDetailsMyAccount && this.props.agencyDetailsMyAccount.publicId) {
        publicGuid = this.props.agencyDetailsMyAccount.publicId;
      } else {
        window.location.href = '/Account/MyProfile';
      }
    }

    if (this.props.match.path === '/Profile/AgencyProfile/:id') {
      publicGuid = this.props.agencyDetailsAgencyProfile.data.publicId;
      if (!publicGuid) {
        window.location.href = `/Profile/AgencyProfile/${this.props.match.params.id}`;
      }
    }

    Utils.GetService(
      `${Config.BASE_URL}${Config.GET_DEVELOPER_ORGANISATION}?id=${publicGuid}`
    ).then(res => {
      this.props.hideLoader();
      this.props.getDeveloperOrgDetailsAction(res);
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.getDeveloperOrgDetails.primaryCountryId !== this.state.primaryCountryId) {
        // eslint-disable-next-line no-param-reassign
        nextProps.getDeveloperOrgDetails.primaryCountryId = this.state.primaryCountryId;
      }
      this.setState({
        ...this.loadDeveloperInformation(nextProps.getDeveloperOrgDetails),
        publicGuid: nextProps.publicGuid
      });
    }
    if (this.props.hideAgencyLogoPopup) {
      this.hideModal();
    }
  }

  getSelectedFields = (id, checked, fieldIds) => {
    const ids = this.state[fieldIds];
    if (checked) {
      ids.push(id);
    } else {
      const index = ids.indexOf(id);
      ids.splice(index, 1);
    }
    this.setState({
      [fieldIds]: ids
    });
  };

  getVideoList = videoList => {
    this.setState({
      videoURLs: videoList
    });
  };

  getCountries(countries) {
    if (!countries) {
      return null;
    }
    return countries.map((value, i) => (
      <option key={i.toString()} value={value.id}>
        {value.name}
      </option>
    ));
  }

  getChangeHandler = (key, event) => {
    this.setState({ [key]: event.target.value });
    this.props.getDeveloperOrgDetails[key] = event.target.value;
  };

  getCustomDevelopment(checked) {
    this.setState({
      isCustomDevelopmentSupported: checked
    });
  }

  getName = props => (props.name || '');

  getDescription = props => (props.description || '');

  getshortDescription = props => (props.shortDescription || '');

  geturl = (props) => (props.url || '');

  getemail = (props) => (props.email || '');

  getprivacyPolicyLink = (props) => (props.privacyPolicyLink || '');

  getcontactPhone = (props) => props.contactPhone || '';

  getprimaryLanguageSpoken = (props) => props.primaryLanguageSpoken || '';

  getaddress1 = props => props.address1 || '';

  getaddress2 = props => props.address2 || '';

  getcity = props => props.city || '';

  getstateRegionv = props => props.stateRegion || '';

  getzipPostalCode = props => props.zipPostalCode || '';

  getcountry = props => props.country || '';

  getofficeEmail = props => props.officeEmail || '';

  gettravelportToolsId = props => props.travelportToolsId || [];

  gethostsId = props => props.hostsId || [];

  getdevelopmentLanguagesId = props => props.developmentLanguagesId || '';

  getregionsId = props => props.regionsId || [];

  getcountriesId = props => props.countriesId || [];

  getcategoriesId = props => props.categoriesId || [];

  getprimaryCountryId = props => props.primaryCountryId || -1;

  getisCustomDevelopmentSupported = props => props.isCustomDevelopmentSupported || false;

  getpartnerLevel = props => props.partnerLevel || -1;

  getvideoURLs = props => props.videoURLs || [];

  gethasScreenshot1 = props => props.hasScreenshot1 || false;

  gethasScreenshot2 = props => props.hasScreenshot2 || false;

  gethasScreenshot3 = props => props.hasScreenshot3 || false;

  gethasScreenshot4 = props => props.hasScreenshot4 || false;

  getscreenshot1Title = props => props.screenshot1Title || '';

  getscreenshot2Title = props => props.screenshot2Title || '';

  itemExists = (item, selectedCategories = []) => {
    for (let i = 0; i < selectedCategories.length; i += 1) {
      if (selectedCategories[i] === item) {
        return true;
      }
    }
    return false;
  };

  saveScreenShot = (image, index) => {
    const formData = new FormData();
    formData.append('id1', this.state.publicGuid);
    formData.append('id2', index);
    formData.append('screenshots', image);
    this.props.loadDeveloperOrgSaveAction(this.state);
    this.props.showLoader();
    Utils.PostFormData(
      `${Config.BASE_URL}${Config.SAVE_SCREENSHOTS}`,
      formData
    ).then(imageResponse => {
      const imageURLs = imageResponse.data;
      if (imageURLs) {
        const previewURL = this.findImageURLs(index).previewUrl;
        const thumbnailURL = this.findImageURLs(index).thumbnailUrl;
        const hasScreenshot = this.findImageURLs(index).hasScreenShot;
        this.setState(
          {
            [previewURL]: imageURLs.previewUrl,
            [thumbnailURL]: imageURLs.thumbnailUrl,
            [hasScreenshot]: true
          },
          () => {
            this.props.getDeveloperOrgDetailsAction(this.state);
          }
        );
      }
      this.props.hideLoader();
    });
  };

  removeScreenShot = screenshotId => {
    const inputParams = {
      id: this.state.publicGuid,
      screenshotId
    };
    this.props.loadDeveloperOrgSaveAction(this.state);
    this.props.showLoader();
    Utils.Service(
      'DELETE',
      `${Config.BASE_URL}${Config.REMOVE_SCREENSHOTS}?${queryString.stringify(
        inputParams
      )}`,
      {}
    ).then(imageResponse => {
      const imageURLs = imageResponse.success;
      if (imageURLs) {
        const previewURL = this.findImageURLs(screenshotId).previewUrl;
        const thumbnailURL = this.findImageURLs(screenshotId).thumbnailUrl;
        const hasScreenshot = this.findImageURLs(screenshotId).hasScreenShot;
        this.setState(
          {
            [previewURL]: '',
            [thumbnailURL]: '',
            [hasScreenshot]: false
          },
          () => {
            this.props.getDeveloperOrgDetailsAction(this.state);
          }
        );
      }
      this.props.hideLoader();
    });
  };

  addNewLocation() {
    this.setState({
      openAdditionalLocation: true,
      additionalLocation: this.props.getFields.countries[0].id
    });
  }

  removeAdditionalCountry(countryIds) {
    const ids = this.state.countriesId;
    const index = ids.indexOf(countryIds);
    ids.splice(index, 1);
    this.setState({
      countriesId: ids
    });
  }

  addAdditionalCountry() {
    const countryIds = parseInt(this.state.additionalLocation, 0);
    const ids = this.state.countriesId;
    const index = ids.indexOf(countryIds);
    if (index === -1) {
      ids.push(countryIds);
      this.setState({
        countriesId: ids,
        openAdditionalLocation: false,
      });
    } else {
      this.props.showAlertAction('Duplicates not allowed');
    }
  }

  saveOrg() {
    const developerInfo = this.loadDeveloperInformation(this.state);
    this.props.showLoader();
    this.props.loadDeveloperOrgSaveAction(developerInfo);

    const publicGuid = this.props.agencyProfilePublicGuid || this.props.publicGuid;
    Utils.Service(
      'POST',
      `${Config.BASE_URL}${Config.EDIT_DEVELOPER_ORGANISATION
      }?id=${publicGuid}`,
      developerInfo
    ).then(() => {
      this.props.loadDeveloperOrgSaveSuccessAction(developerInfo);
      this.props.hideLoader();
      this.props.showAlertAction('Changes have been saved succesfully');
    });
  }

  findImageURLs(screenshotId) {
    let thumbnailUrl;
    let previewUrl;
    let hasScreenShot;
    if (screenshotId === 1) {
      thumbnailUrl = 'screenshot1ThumbnailUrl';
      previewUrl = 'screenshot1PreviewUrl';
      hasScreenShot = 'hasScreenshot1';
    } else if (screenshotId === 2) {
      thumbnailUrl = 'screenshot2ThumbnailUrl';
      previewUrl = 'screenshot2PreviewUrl';
      hasScreenShot = 'hasScreenshot2';
    } else if (screenshotId === 3) {
      thumbnailUrl = 'screenshot3ThumbnailUrl';
      previewUrl = 'screenshot3PreviewUrl';
      hasScreenShot = 'hasScreenshot3';
    } else if (screenshotId === 4) {
      thumbnailUrl = 'screenshot4ThumbnailUrl';
      previewUrl = 'screenshot4PreviewUrl';
      hasScreenShot = 'hasScreenshot4';
    }
    return {
      previewUrl,
      thumbnailUrl,
      hasScreenShot
    };
  }

  loadDeveloperInformation(props) {
    if (!props) {
      return {};
    }
    return {
      name: this.getName(props),
      description: this.getDescription(props),
      shortDescription: this.getshortDescription(props),
      url: this.geturl(props),
      email: this.getemail(props),
      privacyPolicyLink: this.getprivacyPolicyLink(props),
      contactPhone: this.getcontactPhone(props),
      primaryLanguageSpoken: this.getprimaryLanguageSpoken(props),
      address1: this.getaddress1(props),
      address2: this.getaddress2(props),
      city: this.getcity(props),
      stateRegion: this.getstateRegionv(props),
      zipPostalCode: this.getzipPostalCode(props),
      country: this.getcountry(props),
      officeEmail: this.getofficeEmail(props),
      travelportToolsId: this.gettravelportToolsId(props),
      hostsId: this.gethostsId(props),
      developmentLanguagesId: this.getdevelopmentLanguagesId(props),
      regionsId: this.getregionsId(props),
      countriesId: this.getcountriesId(props),
      categoriesId: this.getcategoriesId(props),
      primaryCountryId: this.getprimaryCountryId(props),
      isCustomDevelopmentSupported: this.getisCustomDevelopmentSupported(props),
      partnerLevel: this.getpartnerLevel(props),
      videoURLs: this.getvideoURLs(props),
      hasScreenshot1: this.gethasScreenshot1(props),
      hasScreenshot2: this.gethasScreenshot2(props),
      hasScreenshot3: this.gethasScreenshot3(props),
      hasScreenshot4: this.gethasScreenshot4(props),
      screenshot1Title: this.getscreenshot1Title(props),
      screenshot2Title: this.getscreenshot2Title(props),
      screenshot3Title: props.screenshot3Title || '',
      screenshot4Title: props.screenshot4Title || '',
      screenshot1ThumbnailUrl: props.screenshot1ThumbnailUrl || '',
      screenshot2ThumbnailUrl: props.screenshot2ThumbnailUrl || '',
      screenshot3ThumbnailUrl: props.screenshot3ThumbnailUrl || '',
      screenshot4ThumbnailUrl: props.screenshot4ThumbnailUrl || '',
      screenshot1PreviewUrl: props.screenshot1PreviewUrl || '',
      screenshot2PreviewUrl: props.screenshot2PreviewUrl || '',
      screenshot3PreviewUrl: props.screenshot3PreviewUrl || '',
      screenshot4PreviewUrl: props.screenshot4PreviewUrl || '',
      hasLogo: props.hasLogo || false,
      logoUrl: props.logoUrl || '',
      publicGuid: props.publicGuid || ''
    };
  }

  showModal = () => {
    this.setState({
      showModal: true
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false
    });
  };

  agencyLogoUpload = image => {
    const { publicId } = this.props.agencyDetails;
    this.props.saveAgencyDetailsLogo({ id: publicId, image });
    this.hideModal();
  };

  render() {
    const orgImg = this.props.agencyDetails ? this.props.agencyDetails.logoUrl || require('Styles/img/logo-placeholder-square.jpg') : '';
    const orgName = this.props.agencyDetails ? this.props.agencyDetails.name : '';
    if (this.props.getDeveloperOrgDetails === undefined) {
      return null;
    }
    return (
      <div className="container">
        <div className="row relative-position" />
        <form id="edit-organisation-form">
          <div className="row">
            <div className="col-sm-4">
              <h4>Contact Information</h4>
              <div className="row">
                <label htmlFor="Email">Email</label>
                <input
                  type="text"
                  className="text-input"
                  id="Email"
                  onChange={e => this.getChangeHandler('email', e)}
                  value={this.state.email || ''}
                />
              </div>
              <div className="row">
                <label htmlFor="none">Primary Contact Phone</label>
                <input
                  type="text"
                  className="text-input"
                  id="ContactPhone"
                  onChange={e => this.getChangeHandler('contactPhone', e)}
                  value={this.state.contactPhone || ''}
                />
              </div>
              <div className="row">
                <label htmlFor="Website">Website</label>
                <input
                  type="text"
                  className="text-input"
                  id="Website"
                  onChange={e => this.getChangeHandler('url', e)}
                  value={this.state.url || ''}
                />
              </div>
            </div>
            <div className="col-sm-5 col-sm-offset-1">
              <h4>Headquarters Location</h4>
              {/* Primary Location */}
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="City">City</label>
                  <input
                    type="text"
                    className="text-input"
                    id="City"
                    onChange={e => this.getChangeHandler('city', e)}
                    value={this.state.city || ''}
                  />
                </div>
                <div className="col-sm-5 col-sm-offset-1">
                  <label htmlFor="StateRegion">State</label>
                  <input
                    type="text"
                    className="text-input text-input-md"
                    id="StateRegion"
                    onChange={e => this.getChangeHandler('stateRegion', e)}
                    value={this.state.stateRegion || ''}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="Country">Country</label>
                  <select
                    id="PrimaryCountryId"
                    className="select"
                    value={this.state.primaryCountryId || ''}
                    onChange={e => this.getChangeHandler('primaryCountryId', e)}
                  >
                    {this.getCountries(this.props.getFields.countries)}
                  </select>
                </div>
              </div>
              {/*  Additional Location  */}
              <div className="row">
                <label htmlFor="CountryName">Additional Locations</label>
                <LocationList
                  countries={this.props.getFields.countries}
                  fields={this.state.countriesId}
                  removeAdditionalCountry={countryIds => this.removeAdditionalCountry(countryIds)}
                />
                <AdditionalLocation
                  countries={this.props.getFields.countries}
                  additionalLocation={this.state.additionalLocation}
                  getChangeHandler={e => this.getChangeHandler('additionalLocation', e)}
                  openAdditionalLocation={this.state.openAdditionalLocation}
                  addAdditionalCountry={() => this.addAdditionalCountry()}
                  onClick={() => this.addNewLocation()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <label htmlFor="PrivacyPolicyLink">Privacy Policy</label>
            <input
              type="text"
              className="text-input"
              id="PrivacyPolicyLink"
              onChange={e => this.getChangeHandler('privacyPolicyLink', e)}
              value={this.state.privacyPolicyLink || ''}
            />
          </div>
          <div className="row">
            <label htmlFor="none" className="label-h4">
              Company Short Description
              <Counter
                goal={180}
                message="characters left"
                inputValue={this.state.shortDescription}
              />
            </label>
            <textarea
              className="textarea small-textarea valid"
              id="ShortDescription"
              maxLength={180}
              onChange={e => this.getChangeHandler('shortDescription', e)}
              value={this.state.shortDescription || ''}
            />
          </div>
          <div className="row">
            <label htmlFor="none" className="label-h4">
              Company Full Description
              <Counter
                goal={3200}
                message="characters left"
                inputValue={this.state.description}
              />
            </label>
            <textarea
              className="textarea"
              id="Description"
              maxLength={3200}
              onChange={e => this.getChangeHandler('description', e)}
              value={this.state.description || ''}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="none" className="label-h4">
                Travelport Tools
              </label>
              <FieldDataComponent
                fields={this.props.getFields.tools}
                fieldIds="travelportToolsId"
                itemExists={(id, fieldIds) => this.itemExists(id, this.state[fieldIds])}
                getSelectedFields={(id, val, fieldIds) => this.getSelectedFields(id, val, fieldIds)}
              />
            </div>
            <div className="col-sm-4">
              <label htmlFor="none" className="label-h4">
                Development Languages
              </label>
              <FieldDataComponent
                fields={this.props.getFields.devLangs}
                fieldIds="developmentLanguagesId"
                itemExists={(id, fieldIds) => this.itemExists(id, this.state[fieldIds])}
                getSelectedFields={(id, val, fieldIds) => this.getSelectedFields(id, val, fieldIds)}
              />
            </div>
            <div className="col-sm-4">
              <label htmlFor="none" className="label-h4">
                Regions Covered
              </label>
              <FieldDataComponent
                fields={this.props.getFields.regions}
                fieldIds="regionsId"
                itemExists={(id, fieldIds) => this.itemExists(id, this.state[fieldIds])}
                getSelectedFields={(id, val, fieldIds) => this.getSelectedFields(id, val, fieldIds)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="none">
                PRODUCT CATEGORIES
                {' '}
                <ToolTip labelName="Limited to 5 selections" />
              </label>
              <FieldDataComponent
                fields={this.props.getFields.categories}
                fieldIds="categoriesId"
                itemExists={(id, fieldIds) => this.itemExists(id, this.state[fieldIds])}
                getSelectedFields={(id, val, fieldIds) => this.getSelectedFields(id, val, fieldIds)}
              />
            </div>
            <div className="col-sm-4">
              <label htmlFor="none" className="label-h4">
                GDS Supported
              </label>
              <FieldDataComponent
                fields={this.props.getFields.gds}
                fieldIds="hostsId"
                itemExists={(id, fieldIds) => this.itemExists(id, this.state[fieldIds])}
                getSelectedFields={(id, val, fieldIds) => this.getSelectedFields(id, val, fieldIds)}
              />
            </div>
            <div className="col-sm-4">
              <CustomDevelopment
                isCustomDevelopmentSupported={
                  this.state.isCustomDevelopmentSupported
                }
                getCustomDevelopment={(val) => this.getCustomDevelopment(val)}
              />
            </div>
          </div>
          <div className="row">
            <div className="row">
              <h4>
                LOGO
              </h4>
            </div>
            <div className="row">
              <AgencyLogo
                orgImg={orgImg}
                showModal={() => this.showModal()}
                orgName={orgName}
              />
            </div>
            <div className="row">
              <ImageRequiredMessage />
            </div>
            <div className="row">
              <AddScreenShot
                saveScreenShot={e => this.saveScreenShot(e, 1)}
                hasScreenshot={this.state.hasScreenshot1}
                screenshotThumbnailUrl={this.state.screenshot1ThumbnailUrl}
                screenshotPreviewUrl={this.state.screenshot1PreviewUrl}
                screenshotTitle={this.state.screenshot1Title}
                removeScreenShot={() => this.removeScreenShot(1)}
              />
              <AddScreenShot
                saveScreenShot={e => this.saveScreenShot(e, 2)}
                hasScreenshot={this.state.hasScreenshot2}
                screenshotThumbnailUrl={this.state.screenshot2ThumbnailUrl}
                screenshotPreviewUrl={this.state.screenshot2PreviewUrl}
                screenshotTitle={this.state.screenshot2Title}
                removeScreenShot={() => this.removeScreenShot(2)}
              />
              <AddScreenShot
                saveScreenShot={e => this.saveScreenShot(e, 3)}
                hasScreenshot={this.state.hasScreenshot3}
                screenshotThumbnailUrl={this.state.screenshot3ThumbnailUrl}
                screenshotPreviewUrl={this.state.screenshot3PreviewUrl}
                screenshotTitle={this.state.screenshot3Title}
                removeScreenShot={() => this.removeScreenShot(3)}
              />
              <AddScreenShot
                saveScreenShot={e => this.saveScreenShot(e, 4)}
                hasScreenshot={this.state.hasScreenshot4}
                screenshotThumbnailUrl={this.state.screenshot4ThumbnailUrl}
                screenshotPreviewUrl={this.state.screenshot4PreviewUrl}
                screenshotTitle={this.state.screenshot4Title}
                removeScreenShot={() => this.removeScreenShot(4)}
              />
            </div>
            <div className="row">
              {/* Sample Video */}
              <div className="col-sm-7 pad-right-double">
                <div className="row">
                  <VideoRequiredMessage />
                </div>
                <VideoScreenShotComponent
                  videoList={this.state.videoURLs}
                  getVideoList={this.getVideoList}
                />
              </div>
            </div>
            {this.props.roleId === 1 ? (
              <div className="row">
                {/* only for SuperAdmin */}
                <PartnerLevel
                  partnerLevel={this.state.partnerLevel}
                  getChangeHandler={e => this.getChangeHandler('partnerLevel', e)}
                  partnerLevels={this.props.getFields.partnerLevels}
                />
              </div>
            ) : null}
          </div>
          <Link to="#" className="action-button" onClick={() => this.saveOrg()}>
            Save Changes
          </Link>
          {' '}
          <Link to={`/DeveloperDirectory/${SeoHelper.SEOFriendly(this.state.name)}`} className="action-button">
            Preview Profile
          </Link>
        </form>
        {this.state.showModal ? (
          <ProfileImagePopup
            onHide={() => {
              this.setState({ showModal: false });
            }}
            modalTitle="Upload Agency Logo"
            showModal={this.state.showModal}
            submitImageUpload={file => this.agencyLogoUpload(file)}
          />
        ) : null}
      </div>
    );
  }
}

DeveloperProfile.propTypes = {
  data: PropTypes.string,
  country: PropTypes.array,
  getFields: PropTypes.object,
  getDeveloperOrgDetails: PropTypes.object,
  publicGuid: PropTypes.string,
  agencyProfilePublicGuid: PropTypes.string,
  loading: PropTypes.bool,
  loadFieldsforDeveloperEditAction: PropTypes.func,
  getDeveloperOrgDetailsAction: PropTypes.func,
  loadDeveloperOrgDetailsAction: PropTypes.func,
  loadDeveloperOrgSaveAction: PropTypes.func,
  loadDeveloperOrgSaveSuccessAction: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  showAlertAction: PropTypes.func,
  roleId: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object,
  agencyDetailsMyAccount: PropTypes.object,
  agencyDetailsAgencyProfile: PropTypes.object,
  hideAgencyLogoPopup: PropTypes.bool,
  agencyDetails: PropTypes.object,
  publicId: PropTypes.string,
  saveAgencyDetailsLogo: PropTypes.func,
  logoUrl: PropTypes.string,
  name: PropTypes.string
};

const mapStateToProps = state => ({
  country: state.country,
  getFields: state.getFieldsforDeveloperEdit,
  getDeveloperOrgDetails: state.getDeveloperOrgDetails.data,
  publicGuid:
    state.profile.getAgencyProfile.data.publicId
    || state.profile.fullProfile.agencyDetails.publicId,
  agencyDetailsMyAccount: state.profile.userProfile.agencyDetails,
  agencyDetailsAgencyProfile: state.profile.getAgencyProfile,
  roleId: state.profile.userProfile.userDetails
    ? state.profile.userProfile.userDetails.currentRoleId
    : null,
  agencyProfilePublicGuid: agencyProfilePublicGuidSelector(state),
  hideAgencyLogoPopup: state.profile.userProfile.hideAgencyLogoPopup,
  agencyDetails: state.profile.userProfile.agencyDetails,
  agencyDetailsProfile: state.profile.getAgencyProfile.data,
});

const mapDispatchToProps = dispatch => ({
  loadFieldsforDeveloperEditAction: bindActionCreators(
    loadFieldsforDeveloperEdit,
    dispatch
  ),
  getDeveloperOrgDetailsAction: bindActionCreators(
    getDeveloperOrgDetails,
    dispatch
  ),
  loadDeveloperOrgDetailsAction: bindActionCreators(
    loadDeveloperOrgDetails,
    dispatch
  ),
  loadDeveloperOrgSaveAction: bindActionCreators(
    loadDeveloperOrgSave,
    dispatch
  ),
  loadDeveloperOrgSaveSuccessAction: bindActionCreators(
    loadDeveloperOrgSaveSuccess,
    dispatch
  ),
  showLoader: bindActionCreators(showLoader, dispatch),
  hideLoader: bindActionCreators(hideLoader, dispatch),
  showAlertAction: bindActionCreators(showAlertAction, dispatch),
  saveAgencyDetailsLogo: args => dispatch(saveAgencyLogo.base(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeveloperProfile));
