/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DateTimeHelper from 'utils/dateHelper';
import { fetchDevStatsReport } from 'reducers/modules/Administration/Reports';
import { devStatsReportTotalPages, devStatsReportSelector } from 'reducers/modules/Administration/Reports/DevStatsReport/selectors';
import { exportToExcelFunc } from 'reducers/modules/Administration/Reports/ExportToExcel/ExportToExcel';
import { ReportsComponent } from '../CommonParts/ReportsComponent';

class DeveloperStatsReport extends PureComponent {
  constructor(props) {
    super(props);
    this.datehandler = this.datehandler.bind(this);
    this.state = {
      startDate: moment(new Date(moment.utc().format())).subtract(2, 'years'),
      endDate: new Date(moment.utc().format()),
      sortAsc: false,
      excludeTPEmployees: false,
      sortOnColumn: '',
      sorting: {
        columnName: 'Developer',
        direction: 'asc'
      },
      paging: {
        currentPage: 0,
        pageSize: 20
      }
    };
    this.columnDefinitions = [
      { name: 'developer', title: 'Developer' },
      { name: 'developerEmail', title: 'Developer Email' },
      { name: 'developerCountry', title: 'Developer Country' },
      { name: 'productName', title: 'Product Name' },
      { name: 'version', title: 'Version' },
      { name: 'count', title: 'Count' },
      { name: 'productRegion', title: 'Product Region' },
      { name: 'productLastPublishedDate', title: 'Product Last Published Date' },
      { name: 'distributionType', title: 'Distribution Type' }
    ];
  }

  componentDidMount() {
    document.title = 'Developer Status Report';
    this.getFilteredDevStats();
  }

  getFilteredDevStats = (e, page) => {
    if (e) {
      e.preventDefault();
    }
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentPage: page || this.state.paging.currentPage,
      excludeTPEmployees: this.state.excludeTPEmployees
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.fetchDevStatsReportAction(params);
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  };

  getSortDirection = () => this.state.sorting.direction === 'asc';

  handleClear = () => this.setState({ startDate: '', endDate: '', excludeTPEmployees: '' });

  searchHandler = (e) => {
    this.getFilteredDevStats(e, '0');
  }

  sortHandler = newSorting => this.setState(
    { sorting: { ...newSorting[0] } },
    this.getFilteredDevStats
  );

  pageHandler = currentPage => {
    this.setState(
      { paging: { ...this.state.paging, currentPage } },
      this.getFilteredDevStats
    );
  }

  exportToExcel = (e) => {
    e.preventDefault();
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortOnColumn: this.getSortColumn() || '',
      excludeTPEmployees: this.state.excludeTPEmployees,
      sortAsc: this.getSortColumn() ? this.getSortDirection() : false
    };

    if (this.state.sorting.columnName !== '') {
      params.sortOnColumn = this.state.sorting.columnName;
    }

    this.props.devStatsReportDownloadExcel(params);
  };

  datehandler = (e, field) => {
    this.setState({
      [field]: DateTimeHelper.getDate(e)
    });
  };

  handleTPemp = (checked) => this.setState({ excludeTPEmployees: checked });

  render() {
    return (
      <ReportsComponent
        reportName="Developer Statistics Report"
        startDate={this.state.startDate}
        datehandler={this.datehandler}
        disableFuturePastDt={DateTimeHelper.disableFuturePastDt}
        endDate={this.state.endDate}
        excludeTPEmployees={this.state.excludeTPEmployees}
        handleTPemp={this.handleTPemp}
        searchHandler={this.searchHandler}
        handleClear={this.handleClear}
        exportToExcel={this.exportToExcel}
        columnDefinitions={this.columnDefinitions}
        rows={this.props.rows}
        currentPage={this.state.paging.currentPage}
        pageSize={this.state.paging.pageSize}
        pageHandler={this.pageHandler}
        sorting={this.sorting}
        sortHandler={this.sortHandler}
        showSortingControls
        totalPages={this.props.totalPages}
      />
    );
  }
}

DeveloperStatsReport.propTypes = {
  fetchDevStatsReportAction: PropTypes.func,
  loginReportDownloadExcel: PropTypes.func,
  rows: PropTypes.array,
  totalPages: PropTypes.number,
  devStatsReportDownloadExcel: PropTypes.func
};

const mapStateToProps = state => ({
  rows: devStatsReportSelector(state),
  totalPages: devStatsReportTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  fetchDevStatsReportAction: args => dispatch(fetchDevStatsReport.base(args)),
  devStatsReportDownloadExcel: args => dispatch(exportToExcelFunc.developerStatsReport(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeveloperStatsReport);
