/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as SeoHelper from '../../../utils/SeoHelper';

const OrderActions = (props) => {
  const gtmAppVendorDetails = `Solution:${props.applicationName} / Developer:${props.developerName}`;
  return (
    <div className="row text-center">
      <Link id="termsBackButton" to="#" onClick={props.goBackHandler} className="blue-button">&lt; Back</Link>
      <Link
        id="termsDoNotAgreeButton"
        gtm-event-label={gtmAppVendorDetails}
        to={`/Product/${SeoHelper.SEOFriendly(props.applicationName)}`}
        className="blue-button gtm-do-not-agree-button"
      >
        Do Not Agree
      </Link>
      <Link
        id="termsAgreeButton"
        gtm-event-label={gtmAppVendorDetails}
        to="#"
        onClick={props.submitHandler}
        className="green-button gtm-agree-button"
      >
        {props.agreeButtonText}
      </Link>
    </div>
  );
};

OrderActions.propTypes = {
  applicationName: PropTypes.string,
  developerName: PropTypes.string,
  agreeButtonText: PropTypes.string,
  submitHandler: PropTypes.func,
  goBackHandler: PropTypes.func
};

export default OrderActions;
