/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapsible } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/collapsible.css';

class LeftPanelFilter extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      hostopen: true,
    };
  }

  handleChangeCheckBox = (value, checked) => {
    this.props.handleChangeCheckBoxNewOthers(value, checked);
  };

  render() {
    return (
      <>
        <div>
          <Collapsible className="browse-products-filter-headers" id="collapsible-panel-hosts" isOpen={this.state.hostopen} header={this.props.header} onClick={() => this.setState({ hostopen: !this.state.hostopen })} defaultExpanded="true" expanded={this.state.hostopen} onToggle={this.state.hostopen}>
            <ul className="sb-collapsible-content browse-products-filter-section no-list-style no-list-style-atls">{this.props.children}</ul>
          </Collapsible>
        </div>
      </>
    );
  }
}

LeftPanelFilter.propTypes = {
  header: PropTypes.string,
  data: PropTypes.any,
  applicationName: PropTypes.string,
  onChange: PropTypes.func,
  handleChangeCheckBoxNewOthers: PropTypes.func,
  children: PropTypes.node,
};

export default LeftPanelFilter;
