import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BASIC_INFORMATION_ROUTE } from 'constants/PPCP';
import AuthorizationChildren from 'components/Authorization/AuthorizationChildren';
import addIcon from '../../../Styles/img/add-icon.svg';

const rulesBtn = [];

rulesBtn.MarketplaceTeamAdmin = ['solution', 'spcloud'];
rulesBtn.TPAdmin = ['solution'];
rulesBtn.TPSupport = [];
rulesBtn.AgencyAdmin = [];
rulesBtn.AgencySuperAdmin = [];
rulesBtn.DeveloperUser = ['solution', 'spcloud'];
rulesBtn.SPCDeveloperUser = ['solution', 'spcloud'];
rulesBtn.TPPlatformProductAdmin = ['platform'];
rulesBtn.GeneralUser = [];
rulesBtn.TPApplicationCertifier = [];

class SolutionHeader extends Component {
  solution = key => (
    <AuthorizationChildren buttonRef="btnAddSolution" key={key}>
      {disableParam => {
        if (!disableParam && this.props.showAddSolution) {
          let id = '';
          if (!this.props.ownProfile) {
            id = this.props.orgId ? `?${this.props.orgId}` : `?${this.props.emulatedOrgId}`;
          }
          return (
            <Link to={`${BASIC_INFORMATION_ROUTE}${id}`} className="itemContainer">
              <span>Add Solution</span>
              <img src={addIcon} alt="add" className="addIcon" />
            </Link>
          );
        }

        return null;
      }}
    </AuthorizationChildren>
  );

  platform = key => (
    <AuthorizationChildren buttonRef="btnAddInternalSolution" key={key}>
      {disableParam => {
        if (!disableParam) {
          return (
            <Link to="/admin/distribution" className="itemContainer">
              <span>Platform Product Version Control</span>
              <img src={addIcon} alt="add" className="addIcon" />
            </Link>
          );
        }

        return null;
      }}
    </AuthorizationChildren>
  );

  leftContainerHeader() {
    return (
      <>
        <div className="mySolution_icon_listView_on" />
        <div className="AvailableSolution">
          {`(${this.props.solutionsLength
          }) Solutions Available`}
        </div>
      </>
    );
  }

  render() {
    const result = rulesBtn[this.props.role].map((button, i) => {
      if (button === 'solution') {
        return this.solution(i);
      }

      if (button === 'platform') {
        return this.platform(i);
      }

      return null;
    });

    return (
      <>
        <div className="leftHeader">{this.leftContainerHeader()}</div>
        <div className="rightHeader">{result}</div>
      </>
    );
  }
}

SolutionHeader.propTypes = {
  solutionsLength: propTypes.number,
  role: propTypes.string,
  orgId: propTypes.number,
  emulatedOrgId: propTypes.number,
  orgGuid: propTypes.string,
  profile: propTypes.object,
  ownProfile: propTypes.bool,
  showAddSolution: propTypes.bool
};

export default SolutionHeader;
