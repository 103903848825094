/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  TableHeaderRow,
  Table,
  TableEditColumn,
  TableEditRow,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  PagingState,
  CustomPaging,
  SortingState,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  userRolesSelector,
  fetchUserRoles,
  updateUserRoles,
  exportToExcel,
  deleteUser
} from 'reducers/modules/Administration/UserRoles/userRoles';
import LoaderComponent from 'components/common/Loader/LoaderComponent';
import { rolesArrayForManageRoles } from 'utils/rolesHelper';
import DataCells from './TableCellComponents/DataCells';
import EditCell from './TableCellComponents/EditCell';
import AssociatedPluginModal from './AssociatedPluginsModal/AssociatedPluginModal';

class UserRoles extends Component {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'userId', title: 'Email' },
      { name: 'userName', title: 'USERNAME' },
      { name: 'organization', title: 'ORGANIZATION' },
      { name: 'roles', title: 'ROLE(S)' }
    ];

    this.sortingStateColumnExtensions = [
      { columnName: 'roles', sortingEnabled: false },
    ];

    this.state = {
      filters: {
        searchString: '',
        roleFilter: 'All'
      },
      sorting: {
        columnName: '',
        direction: 'asc',
      },
      paging: {
        currentPage: 0,
        pageSize: 20,
      },
      editingRowIds: []
    };
  }

  componentDidMount() {
    document.title = 'Manage User Roles';
    this.fetchUserRoles();
  }

  onChangeRoleFilter = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, filters: { ...this.state.filters, roleFilter: event.target.value } });
  };

  onChangeSearchStringFilter = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, filters: { ...this.state.filters, searchString: event.target.value } });
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  }

  getSortDirection = () => this.state.sorting.direction === 'asc';

  fetchUserRoles = (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = {
      searchString: this.state.filters.searchString,
      roleFilter: this.state.filters.roleFilter,
      sortColumn: this.getSortColumn(),
      ascending: this.getSortDirection(),
      currentPage: this.state.paging.currentPage + 1
    };
    this.props.fetchUserRoles(params);
  };

  clearAndFetchUserRoles = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState(
      { ...this.state, filters: { searchString: '', roleFilter: 'All' } },
      this.fetchUserRoles
    );
  };

  checkSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.fetchUserRoles();
    }
  }

  exportToExcel = (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = {
      searchString: this.state.filters.searchString,
      roleFilter: this.state.filters.roleFilter,
      sortColumn: this.getSortColumn(),
      ascending: this.getSortDirection()
    };
    this.props.exportToExcel(params);
  };

  handleEditRow = (rows) => {
    const row = rows.slice(-1);
    this.setState({ editingRowIds: row });
  };

  sortHandler = (newSorting) => this.setState({ sorting: { ...newSorting[0] } }, this.fetchUserRoles);

  pageHandler = (currentPage) => { this.setState({ paging: { ...this.state.paging, currentPage } }, this.fetchUserRoles); window.scrollTo(0, 0); };

  commitChanges = ({ changed, deleted }) => {
    if (changed) {
      const entity = { ...this.props.userRoles.data[this.state.editingRowIds], ...changed[this.state.editingRowIds] };
      const params = {
        userId: entity.id,
        roleIds: entity.roles.map(r => r.value)
      };
      this.props.updateUserRoles(params);
    }
    if (deleted) {
      this.props.deleteUser(this.props.userRoles.data[deleted]);
    }
  };

  render() {
    if (!this.props.userRoles.data) {
      return <LoaderComponent show />;
    }
    return (
      <>
        <h2>Organization, user and distribution management</h2>
        <div className="container">
          <div className="row">
            <Link to="/admin/home">&lt; Back to Administration</Link>
          </div>
          <div className="row bold-font space-top-double space-bottom-double">
            <span className="space-left-double"><Link to="/admin/home/organizationDirectory">Organization Directory</Link></span>
            <span className="space-left-double">Assign Roles</span>
            <span className="space-left-double"><Link to="/admin/distributeAdminAssignment">TP Platform Product Admin Assignments</Link></span>
          </div>

          {/* Filtering Section */}
          <div className="row no-space">
            <h4>FILTER REPORT</h4>
          </div>
          <div id="filter-report-generator" className="product-download-filter-report row">
            <div className="row">
              <div className="col-sm-7 col-sm-offset-1">
                <div className="col-sm-3 text-right space-top-sm">
                  View By Role:&nbsp;
                </div>
                <div className="col-sm-6">
                  <select className="select" value={this.state.filters.roleFilter} onChange={this.onChangeRoleFilter} style={{ width: '100%' }}>
                    <option value="All">All</option>
                    {rolesArrayForManageRoles.map((v, i) => <option value={v.id} key={i.toString()}>{v.text}</option>)}
                    <option value="multiple">Multiple</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-1 col-sm-offset-1 pad-left">
                <button type="button" className="action-button" onClick={this.fetchUserRoles}>Apply</button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7 col-sm-offset-1">
                <div className="col-sm-3 text-right space-top-sm">
                  Find Member(s):&nbsp;
                </div>
                <div className="col-sm-6">
                  <input
                    value={this.state.filters.searchString}
                    onChange={this.onChangeSearchStringFilter}
                    onKeyPress={this.checkSubmit}
                    className="text-input"
                    placeholder="Search"
                    id="searchTextInput"
                  />
                </div>
              </div>
              <div className="col-sm-1 col-sm-offset-1 pad-left">
                <button type="button" className="action-button" onClick={this.clearAndFetchUserRoles}>Clear</button>
              </div>
            </div>
          </div>

          {/* Download Excel Section */}
          {this.props.userRoles.data && this.props.userRoles.data.length > 0
            && (
              <div className="row text-right ajax-dependent-div">
                <Link to="#" id="downloadExcel" className="text-link-with-icon-left" onClick={this.exportToExcel}>
                  <span className="download-icon" />
                  Download in Microsoft Excel
                </Link>
              </div>
            )}

          {/* Table Section */}
          {this.props.userRoles.data
            && (
              <div className="row space-top">
                <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
                  <Grid columns={this.columnDefinitions} rows={this.props.userRoles.data}>
                    <EditingState
                      editingRowIds={this.state.editingRowIds}
                      onEditingRowIdsChange={this.handleEditRow}
                      onCommitChanges={this.commitChanges}
                    />

                    <PagingState
                      currentPage={this.state.paging.currentPage}
                      pageSize={this.state.paging.pageSize}
                      onCurrentPageChange={this.pageHandler}
                    />
                    <CustomPaging
                      totalCount={this.props.userRoles.totalPages * this.state.paging.pageSize}
                    />

                    <SortingState
                      sorting={this.sorting}
                      onSortingChange={this.sortHandler}
                      columnExtensions={this.sortingStateColumnExtensions}
                    />

                    <Table cellComponent={DataCells} />
                    <TableHeaderRow showSortingControls />
                    <TableEditColumn
                      showEditCommand
                      showDeleteCommand
                    />
                    <TableEditRow cellComponent={EditCell} />
                    <PagingPanel pageSizes={[]} />
                  </Grid>
                </div>
              </div>
            )}

        </div>
        <AssociatedPluginModal />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userRoles: userRolesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserRoles: (args) => dispatch(fetchUserRoles.base(args)),
  updateUserRoles: (args) => dispatch(updateUserRoles.base(args)),
  deleteUser: (args) => dispatch(deleteUser.base(args)),
  exportToExcel: (args) => dispatch(exportToExcel.base(args)),
});

UserRoles.propTypes = {
  userRoles: PropTypes.object,
  fetchUserRoles: PropTypes.func,
  updateUserRoles: PropTypes.func,
  deleteUser: PropTypes.func,
  exportToExcel: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
