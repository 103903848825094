import {
  takeLatest, call, put, race, take
} from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showConfirmationModal, CONFIRMATION_ACCEPTED, CONFIRMATION_REJECTED } from 'reducers/popUps/confirmationModalReducer';

export const GETHOSTS = createFetchTypes('Marketplace/Administration/ManageHostUsers/GETHOSTS');
export const FIND_HOST_USERS = createFetchTypes('Marketplace/Administration/ManageHostUsers/FIND_HOST_USERS');
export const ADD_HOST_USERS = createFetchTypes('Marketplace/Administration/ManageHostUsers/ADD_HOST_USERS');
export const DELETE_HOST_USERS = createFetchTypes('Marketplace/Administration/ManageHostUsers/DELETE_HOST_USERS');

const manageHostUsersReducer = (state = { hosts: [], hostUsers: [] }, action = {}) => {
  switch (action.type) {
    case GETHOSTS.REQUEST:
    case FIND_HOST_USERS.REQUEST:
    case ADD_HOST_USERS.REQUEST:
    case DELETE_HOST_USERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GETHOSTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        hosts: action.payload.data
      };
    case GETHOSTS.FAILURE:
    case FIND_HOST_USERS.FAILURE:
    case ADD_HOST_USERS.FAILURE:
    case DELETE_HOST_USERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FIND_HOST_USERS.SUCCESS:
      return {
        ...state,
        loading: false,
        hostUsers: action.payload.data.hostUsers,
        noResultFound: !action.payload.data.hostUsers.length > 0,
        error: null,
      };
    case ADD_HOST_USERS.SUCCESS:
    case DELETE_HOST_USERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const getHosts = {
  base: () => ({
    type: GETHOSTS.BASE,
    url: 'managehostusers/gethosts'
  }),
  request: () => ({ type: GETHOSTS.REQUEST }),
  success: (data) => ({
    type: GETHOSTS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: GETHOSTS.FAILURE })
};

export const findHostUsers = {
  base: (data) => ({
    type: FIND_HOST_USERS.BASE,
    url: 'managehostusers/findhostusers',
    payload: {
      data
    }
  }),
  request: () => ({ type: FIND_HOST_USERS.REQUEST }),
  success: (data) => ({
    type: FIND_HOST_USERS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: FIND_HOST_USERS.FAILURE })
};

export const deleteHostUsers = {
  base: (data, successHandler) => ({
    type: DELETE_HOST_USERS.BASE,
    url: 'managehostusers/deletehostuser',
    payload: {
      data
    },
    successHandlerAction: successHandler
  }),
  request: () => ({ type: DELETE_HOST_USERS.REQUEST }),
  success: (data) => ({
    type: DELETE_HOST_USERS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: DELETE_HOST_USERS.FAILURE })
};

export const addHostUsers = {
  base: (data, successHandler) => ({
    type: ADD_HOST_USERS.BASE,
    url: 'managehostusers/addhostuser',
    payload: {
      data
    },
    successHandlerAction: successHandler
  }),
  request: () => ({ type: ADD_HOST_USERS.REQUEST }),
  success: (data) => ({
    type: ADD_HOST_USERS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: ADD_HOST_USERS.FAILURE })
};

export function* fetchHostsSaga(action) {
  try {
    yield put(showLoader());
    yield call(() => fetchEntity(getHosts, ApiClient.httpClient.get, action.url));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* findHostUsersSaga(action) {
  try {
    yield put(showLoader());
    yield call(() => fetchEntity(findHostUsers, ApiClient.httpClient.get, action.url, { params: { ...action.payload.data } }));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

export function* addHostUsersSaga(action) {
  try {
    yield put(showLoader());
    const userResponse = yield call(() => fetchEntity(addHostUsers, ApiClient.httpClient.post, action.url, action.payload.data));
    if (userResponse.data) {
      yield put(showAlertAction(userResponse.data.responseMessage));
      action.successHandlerAction();
    }
    yield put(hideLoader());
  } catch (error) {
    yield put(showAlertAction('Unable to add User'));
    yield put(hideLoader());
  }
}

export function* deleteHostUsersSaga(action) {
  try {
    yield put(showConfirmationModal('Are you sure you want to delete this user from Marketplace?'));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      yield put(showLoader());
      const deleteUrl = `${action.url}/${action.payload.data.userName}`;
      const userResponse = yield call(() => fetchEntity(deleteHostUsers, ApiClient.httpClient.delete, deleteUrl));
      if (userResponse.data) {
        yield put(showAlertAction(userResponse.data.responseMessage));
        action.successHandlerAction();
      }
      yield put(hideLoader());
    }
  } catch (error) {
    yield put(hideLoader());
    yield put(showAlertAction('Unable to delete User'));
  }
}

export function* manageHostUserMiddleware() {
  yield takeLatest(GETHOSTS.BASE, fetchHostsSaga);
  yield takeLatest(FIND_HOST_USERS.BASE, findHostUsersSaga);
  yield takeLatest(ADD_HOST_USERS.BASE, addHostUsersSaga);
  yield takeLatest(DELETE_HOST_USERS.BASE, deleteHostUsersSaga);
}

export default manageHostUsersReducer;
