import React from 'react';
import { put } from 'redux-saga/effects';
import * as Config from '../config/apiConfig';
import * as Utils from '../utils/Common.Utils';
import LoaderComponent from '../components/common/Loader/LoaderComponent';
// import LoaderComponent from '../../../common/Loader/LoaderComponent';

export const fetchFilterDetails = () => fetch(`${Config.BASE_URL}${Config.GET_FILTER_DETAILS}`).then((response) => (Utils.isValidResponse(response) ? response.json() : []));

export const fetchBrowseProducts = () => fetch(`${Config.BASE_URL}${Config.GET_BROWSE_PRODUCT}`).then((response) => (Utils.isValidResponse(response) ? response.json() : []));

export const fetchSearchResults = (text) => Utils.GetService(`${Config.BASE_URL}${Config.GET_SEARCH_RESULTS}?${text}`);

export const fetchBrowseProductsAll = (params) => Utils.Service('POST', `${Config.BASE_URL}${Config.GET_BROWSE_PRODUCT}`, params);

export const checkForSMSESSIONCookie = () => fetch(`${Config.BASE_URL}api/SSO`).then((response) => (Utils.isValidResponse(response) ? response.json() : []));

export const fetchDeveloperFilterDetails = (params) => Utils.GetService(`${Config.BASE_URL}${Config.GET_FILTER_DEVELOPER_DETAILS}?${params}`, {});

export const getAllCountry = (params) => Utils.GetService(`${Config.BASE_URL}${Config.GET_ALL_COUNTRY}`, params);

export const getDevGuide = () => fetch(`${Config.BASE_URL}${Config.GET_DEVELOPER_GUIDE_URL}`).then((response) => (Utils.isValidResponse(response) ? response.json() : []));
export const getSolutionUploadGuide = () => fetch(`${Config.BASE_URL}${Config.GET_HOW_TO_UPLOAD_YOUR_SOLUTION}`)
  .then((response) => (Utils.isValidResponse(response) ? response.json() : []));
function* showLoader() {
  yield put({
    type: 'SHOW_LOADER'
  });
}
export const SSOEnablement = (user) => {
  const userIsLoggedIn = user && !user.expired;
  if (!userIsLoggedIn) {
    const doesSMSESSIONCookieExists = checkForSMSESSIONCookie();
    doesSMSESSIONCookieExists.then(res => {
      if (res.redirectToLogin) {
        sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
        showLoader();
        window.location = `${Config.BASE_URL}login`;
      }
    });
  }
};
export const SSOProcessing = (user) => (
  <LoaderComponent show>
    { SSOEnablement(user) }
  </LoaderComponent>
);
