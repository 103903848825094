import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SEOFriendly } from 'utils/SeoHelper';
import {
  getTermsAndConditionsDisplayDetails,
  fetchTermsAndConditionsDisplay
} from 'reducers/modules/Application/TermsAndConditionsDisplay/termsAndConditionsDisplay';

class TermsAndConditionsDisplay extends React.PureComponent {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchTermsAndConditionsDisplay(id);
  }

  render() {
    if (!this.props.displayDetails) {
      return null;
    }
    return (
      <div className="container">
        <h4 className="text-center">{this.props.displayDetails.productName}</h4>
        <p className="text-center">
          <a href={`/Product/${SEOFriendly(this.props.displayDetails.productName)}`}>Product Details</a>
        </p>
        {this.props.displayDetails.termsAndConditions
          && this.props.displayDetails.termsAndConditions.split(/\r?\n/).map((para, i) => (
            <React.Fragment key={i.toString()}>
              {para}
              <br />
            </React.Fragment>
          ))}
      </div>
    );
  }
}

TermsAndConditionsDisplay.propTypes = {
  displayDetails: PropTypes.object,
  match: PropTypes.object,
  fetchTermsAndConditionsDisplay: PropTypes.func
};

const mapStateToProps = (state) => ({
  displayDetails: getTermsAndConditionsDisplayDetails(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchTermsAndConditionsDisplay: (args) => dispatch(fetchTermsAndConditionsDisplay.base(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsDisplay);
