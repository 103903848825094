import {
  put, select, call, all
} from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import licenseTypes from 'constants/licenseTypes';
import {
  licenseIdSelector,
  orgIdSelector,
  membersSelector,
  licenseInfoSelector
} from '../selector';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { updateAssignedSeatSiteLicenses } from '../LicenseInformation/licenseInformation';

export const GET_ASSIGNED_MEMBERS = createFetchTypes('Marketplace/admin/licenses/members/getAssignedmembers');
export const GET_UNASSIGNED_MEMBERS = createFetchTypes('Marketplace/admin/licenses/members/getUnassignedmembers');
export const SELECT_ASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/selectedAssignedMembers';
export const SELECT_UNASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/selectedUnassignedMembers';
export const UNSELECT_ASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/unselectedAssignedMembers';
export const UNSELECT_UNASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/unselectedUnassignedMembers';
export const TOGGLE_ALL_SELECT_ASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/toggleAllSelectAssignedMembers';
export const TOGGLE_ALL_SELECT_UNASSIGNED_MEMBERS = 'Marketplace/admin/licenses/members/toggleAllSelectUnassignedMembers';
export const ADD_MEMBER_ASSIGNMENTS = createFetchTypes('Marketplace/admin/licenses/members/addMemberAssignments');
export const REMOVE_MEMBER_ASSIGNMENTS = createFetchTypes('Marketplace/admin/licenses/members/removeMemberAssignments');

const getmembersAfterSelect = (members, entityId) => (
  members.map((member) => {
    if (member.id === entityId) {
      const selectedMember = member;
      selectedMember.isSelected = true;
      return selectedMember;
    }
    return member;
  })
);

const getmembersAfterSelectAll = (members) => (
  members.map((member) => {
    const selectedMember = member;
    selectedMember.isSelected = true;
    return selectedMember;
  })
);

const getmembersAfterUnselect = (members, entityId) => (
  members.map((member) => {
    if (member.id === entityId) {
      const unselectedMember = member;
      unselectedMember.isSelected = false;
      return unselectedMember;
    }
    return member;
  })
);

const getmembersAfterUnselectAll = (members) => (
  members.map((member) => {
    const unselectedMember = member;
    unselectedMember.isSelected = false;
    return unselectedMember;
  })
);

const intialState = {
  assignedMembers: [],
  unassignedMembers: [],
};

const members = (state = intialState, action = {}) => {
  switch (action.type) {
    case GET_ASSIGNED_MEMBERS.REQUEST:
    case GET_UNASSIGNED_MEMBERS.REQUEST:
    case ADD_MEMBER_ASSIGNMENTS.REQUEST:
    case REMOVE_MEMBER_ASSIGNMENTS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ASSIGNED_MEMBERS.SUCCESS:
      return {
        ...state,
        loading: false,
        assignedMembers: action.data
      };
    case GET_UNASSIGNED_MEMBERS.SUCCESS:
      return {
        ...state,
        loading: false,
        unassignedMembers: action.data
      };
    case GET_ASSIGNED_MEMBERS.FAILURE:
    case GET_UNASSIGNED_MEMBERS.FAILURE:
    case ADD_MEMBER_ASSIGNMENTS.FAILURE:
    case REMOVE_MEMBER_ASSIGNMENTS.FAILURE:
    case ADD_MEMBER_ASSIGNMENTS.SUCCESS:
    case REMOVE_MEMBER_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SELECT_ASSIGNED_MEMBERS:
      return {
        ...state,
        assignedMembers: getmembersAfterSelect(state.assignedMembers, action.entityId)
      };
    case SELECT_UNASSIGNED_MEMBERS:
      return {
        ...state,
        unassignedMembers: getmembersAfterSelect(state.unassignedMembers, action.entityId)
      };
    case UNSELECT_ASSIGNED_MEMBERS:
      return {
        ...state,
        assignedMembers: getmembersAfterUnselect(state.assignedMembers, action.entityId)
      };
    case UNSELECT_UNASSIGNED_MEMBERS:
      return {
        ...state,
        unassignedMembers: getmembersAfterUnselect(state.unassignedMembers, action.entityId)
      };
    case TOGGLE_ALL_SELECT_ASSIGNED_MEMBERS:
      return {
        ...state,
        assignedMembers: action.value
          ? getmembersAfterSelectAll(state.assignedMembers)
          : getmembersAfterUnselectAll(state.assignedMembers)
      };
    case TOGGLE_ALL_SELECT_UNASSIGNED_MEMBERS:
      return {
        ...state,
        unassignedMembers: action.value
          ? getmembersAfterSelectAll(state.unassignedMembers)
          : getmembersAfterUnselectAll(state.unassignedMembers)
      };
    default:
      return state;
  }
};

export const getAssignedMembers = {
  base: () => ({
    type: GET_ASSIGNED_MEMBERS.BASE,
    url: 'license/getassignedmembers',
  }),
  request: () => ({ type: GET_ASSIGNED_MEMBERS.REQUEST }),
  success: data => ({ type: GET_ASSIGNED_MEMBERS.SUCCESS, data }),
  failure: error => ({ type: GET_ASSIGNED_MEMBERS.FAILURE, error }),
};

export const getUnassignedMembers = {
  base: (searchText) => ({
    type: GET_UNASSIGNED_MEMBERS.BASE,
    url: 'license/getnonassignedmembers',
    searchText
  }),
  request: () => ({ type: GET_UNASSIGNED_MEMBERS.REQUEST }),
  success: data => ({ type: GET_UNASSIGNED_MEMBERS.SUCCESS, data }),
  failure: error => ({ type: GET_UNASSIGNED_MEMBERS.FAILURE, error }),
};

export const memberSelection = {
  selectAssignedMember: entityId => ({ type: SELECT_ASSIGNED_MEMBERS, entityId }),
  selectUnassignedMember: entityId => ({ type: SELECT_UNASSIGNED_MEMBERS, entityId }),
  unselectAssignedMember: entityId => ({ type: UNSELECT_ASSIGNED_MEMBERS, entityId }),
  unselectUnassignedMember: entityId => ({ type: UNSELECT_UNASSIGNED_MEMBERS, entityId }),
  toggleAllSelectAssignedMember: value => ({ type: TOGGLE_ALL_SELECT_ASSIGNED_MEMBERS, value }),
  toggleAllSelectUnassignedMember: value => ({ type: TOGGLE_ALL_SELECT_UNASSIGNED_MEMBERS, value }),
};

export const addMemberAssignments = {
  base: () => ({
    type: ADD_MEMBER_ASSIGNMENTS.BASE
  }),
  request: () => ({ type: ADD_MEMBER_ASSIGNMENTS.REQUEST }),
  success: data => ({ type: ADD_MEMBER_ASSIGNMENTS.SUCCESS, data }),
  failure: error => ({ type: ADD_MEMBER_ASSIGNMENTS.FAILURE, error }),
};

export const removeMemberAssignments = {
  base: () => ({
    type: REMOVE_MEMBER_ASSIGNMENTS.BASE
  }),
  request: () => ({ type: REMOVE_MEMBER_ASSIGNMENTS.REQUEST }),
  success: data => ({ type: REMOVE_MEMBER_ASSIGNMENTS.SUCCESS, data }),
  failure: error => ({ type: REMOVE_MEMBER_ASSIGNMENTS.FAILURE, error }),
};

export function* getAssignedMembersSaga({ url }) {
  try {
    const licId = yield select(licenseIdSelector);
    yield call(() => fetchEntity(getAssignedMembers, ApiClient.httpClient.get, url, { params: { licId } }));
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to fetch assigned members. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* getUnassignedMembersSaga({ url, searchText }) {
  try {
    const licId = yield select(licenseIdSelector);
    const orgId = yield select(orgIdSelector);
    yield call(() => fetchEntity(getUnassignedMembers, ApiClient.httpClient.get, url, { params: { licId, orgId, searchText } }));
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to fetch unassigned members. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* addMemberAssignmentsSaga() {
  try {
    const licType = (yield select(licenseInfoSelector)).licenseType;
    let url = '';
    if (licType === licenseTypes.enterprise) {
      url = 'license/setenterpriselicense';
    } else if (licType === licenseTypes.seat) {
      url = 'license/setseatlicense';
    }
    if (url) {
      const licId = yield select(licenseIdSelector);
      const idList = (yield select(membersSelector)).unassignedMembers.filter(m => m.isSelected).map(m => m.id);
      yield call(() => fetchEntity(addMemberAssignments, ApiClient.httpClient.post, url, { idList, licId }));
      yield put(updateAssignedSeatSiteLicenses(idList.length));
      yield all([
        put(getAssignedMembers.base()),
        put(getUnassignedMembers.base(null))
      ]);
    }
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to assign license to selected members. Please try again';
    yield put(showAlertAction(message));
  }
}

export function* removeMemberAssignmentsSaga() {
  try {
    const licType = (yield select(licenseInfoSelector)).licenseType;
    let url = '';
    if (licType === licenseTypes.enterprise) {
      url = 'license/removeenterpriselicense';
    } else if (licType === licenseTypes.seat) {
      url = 'license/removeseatlicense';
    }
    if (url) {
      const licId = yield select(licenseIdSelector);
      const idList = (yield select(membersSelector)).assignedMembers.filter(m => m.isSelected).map(m => m.id);
      yield call(() => fetchEntity(removeMemberAssignments, ApiClient.httpClient.post, url, { idList, licId }));
      yield put(updateAssignedSeatSiteLicenses(0 - idList.length));
      yield all([
        put(getAssignedMembers.base()),
        put(getUnassignedMembers.base(null))
      ]);
    }
  } catch (error) {
    const message = (error && error.response && error.response.data)
      || 'Unable to unassign license for selected members. Please try again';
    yield put(showAlertAction(message));
  }
}

export default members;
