/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getAllFilterOptions,
  getAllFilterOptionsSelector
} from 'reducers/modules/Administration/OrganizationDirectory/getFilters';
import {
  getOrganizationList,
  getOrganizationListSelector,
  cleanOrganization
} from 'reducers/modules/Administration/OrganizationDirectory/getOrganizationList';
import {
  getChildOrgs,
  getChildOrgSelector
} from 'reducers/modules/Administration/OrganizationDirectory/getChildOrgs';
import { setOrganiztionVisibility } from 'reducers/modules/Administration/OrganizationDirectory/setOrganiztionVisibility';

import Filter from './Filter';
import AddOrganization from './AddOrganization';
import List from './List';
import ViewPCCsModal from './ViewPCCsModal';

class OrganizationDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      filterOptions: [],
      totalPages: 0,
      position: 0,
      organizationsParams: {
        searchString: '',
        sortOnColumn: '',
        sortAsc: {},
        currentPage: 0,
        filter: ''
      },
      showingModal: false,
      orgIdforViewPCC: 0,
      orgNameForViewPcc: '',
      childOrganisations: {}
    };
  }

  componentDidMount = () => {
    document.title = 'Organization Directory';
    this.props.cleanOrganization();
    this.props.getAllFilterOptions();
  };

  componentDidUpdate = newProps => {
    const items = this.props.items.organisations.pagedCollection;
    if (this.props.childOrganisations !== this.state.childOrganisations
      && this.props.childOrganisations.length !== 0
      && items.length !== 0 && items[this.state.position].child && items[this.state.position].child !== undefined) {
      this.setState({ childOrganisations: this.props.childOrganisations });
      this.insertChildInPosition(
        this.state.position,
        this.props.childOrganisations
      );
      this.checkItemsLength(newProps);
    }

    if (this.props.items !== this.state.items) {
      this.setState({
        items: this.props.items,
        totalPages: this.props.items.organisations.totalPages
      });
    }
  };

  onViewClicked = (orgId, orgName) => {
    this.setState(prevState => ({
      showingModal: !prevState.showingModal,
      orgIdforViewPCC: orgId,
      orgNameForViewPcc: orgName
    }));
  };

  getOrganiztion = organizationsParams => {
    this.props.getOrganizationList(organizationsParams);
  };

  getChild = (params, position) => {
    this.props.getChildOrgs(params, position);
    this.setState({ position });
  };

  setOrganizationParams = params => {
    const { organizationsParams } = this.state;
    Object.assign(organizationsParams, params);
    this.setState({ ...organizationsParams });
    return organizationsParams;
  };

  closeModal = () => {
    this.setState({
      showingModal: false
    });
  };

  handlePageClick = data => {
    this.handleFilterSubmit({ currentPage: data.selected });
  };

  handleOnSetOrder = data => {
    this.handleFilterSubmit({ ...data });
  };

  handleOnSetVisiblity = row => {
    const tmpObj = { organisationId: row.id, visibility: row.isDisplayed };
    this.props.setOrganiztionVisibility({ ...tmpObj });
  };

  handleGetChilds = (row, position) => {
    const params = { parentOrgId: row.id };
    this.getChild(params, position);
  };

  handleFilterSubmit = params => {
    const tmpParams = this.resetPagination(params);
    const organizationsParams = this.setOrganizationParams(tmpParams);
    this.getOrganiztion(organizationsParams);
  };

  insertChildInPosition = (position, childs) => {
    const items = this.props.items.organisations.pagedCollection;
    items[position] = {
      ...items[position],
      child: childs
    };
    this.setState({ items });
  };

  checkItemsLength = tmp => {
    if (tmp.items.organisations.pagedCollection.length === 0) {
      this.setState({
        totalPages: 0,
        items: []
      });
    } else {
      this.setState({
        totalPages: tmp.items.organisations.totalPages,
        items: tmp.items.organisations.pagedCollection
      });
    }
  };

  resetPagination = params => {
    if (typeof params.currentPage === 'undefined') {
      const tmpParams = params;
      tmpParams.currentPage = 0;
      return tmpParams;
    }

    return params;
  };

  render() {
    const showPagination = this.props.items.responseMessage !== ''
      ? 'hide'
      : 'row text-center space-top-double ';

    return (
      <>
        <h2>Organization, user and distribution management</h2>
        <div className="container">
          <div className="row">
            <Link to="/admin/home">&lt; Back to Administration</Link>
          </div>
          <div className="row bold-font space-top-double space-bottom-double">
            <span className="space-left-double">Organization Directory</span>
            <span className="space-left-double"><Link to="/admin/manageUserRoles">Assign Roles</Link></span>
            <span className="space-left-double"><Link to="/admin/distributeAdminAssignment">TP Platform Product Admin Assignments</Link></span>
          </div>
          <div id="filter-report-generator" className="row">
            <Filter
              options={this.props.filterOptions}
              onSubmit={this.handleFilterSubmit}
            />
            <AddOrganization />
          </div>
          <List
            organizationsParamsList={this.state.organizationsParams}
            className={showPagination}
            rows={this.props.items.organisations.pagedCollection}
            position={this.state.position}
            onGetChild={this.handleGetChilds}
            onSetOrder={this.handleOnSetOrder}
            onSetVisiblity={this.handleOnSetVisiblity}
            onViewClicked={this.onViewClicked}
          />
          <div className={showPagination}>
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel={<a href="">...</a>}
              breakClassName="break-me"
              forcePage={this.state.organizationsParams.currentPage}
              pageCount={this.state.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </div>
          {' '}
          <ViewPCCsModal
            showModal={this.state.showingModal}
            openModal={() => this.setState({ showingModal: true })}
            orgName={this.state.orgNameForViewPcc}
            orgId={this.state.orgIdforViewPCC}
            closeModal={() => this.closeModal()}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  filterOptions: getAllFilterOptionsSelector(state),
  items: getOrganizationListSelector(state),
  childOrganisations: getChildOrgSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getAllFilterOptions: () => dispatch(getAllFilterOptions.base()),
  getOrganizationList: params => dispatch(getOrganizationList.base(params)),
  getChildOrgs: params => dispatch(getChildOrgs.base(params)),
  setOrganiztionVisibility: params => dispatch(setOrganiztionVisibility.base(params)),
  cleanOrganization: () => dispatch(cleanOrganization)
});

OrganizationDirectory.propTypes = {
  filterOptions: PropTypes.array,
  getAllFilterOptions: PropTypes.func,
  getOrganizationList: PropTypes.func,
  getChildOrgs: PropTypes.func,
  items: PropTypes.object,
  childOrganisations: PropTypes.array,
  setOrganiztionVisibility: PropTypes.func,
  cleanOrganization: PropTypes.func
};

OrganizationDirectory.defaultProps = {
  filterOptions: [],
  items: {
    organisations: {
      pagedCollection: []
    }
  },
  childOrganisations: []
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationDirectory);
