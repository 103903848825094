import createFetchTypes from 'reducers/utils/createFetchTypes';

export const INIT_PRODUCT_ACCESS_PAGE = 'Marketplace/Administration/ProductVersionControl/ProductAccess/INIT_PRODUCT_ACCESS_PAGE';
export const GET_PRODUCT_ACCESS = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_PRODUCT_ACCESS');
export const GET_USER_NAMES = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_USER_NAMES');
export const GET_ORGANIZATION_NAMES = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_ORGANIZATION_NAMES');
export const GET_PCC_ORG = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_PCC_ORG');
export const GET_ALL_GROUPS = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_ALL_GROUPS');
export const SAVE_PRODUCT_ACCESS = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/SAVE_PRODUCT_ACCESS_ACCESS');
export const DELETE_PRODUCT_ACCESS = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/DELETE_PRODUCT_ACCESS_ACCESS');
export const GLOBAL_VISIBILITY = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GLOBAL_VISIBILITY');
export const VISIBILITY_NON_PARTICIPANTS = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/VISIBILITY_NON_PARTICIPANTS');
export const VISIBILITY_EMPLOYEES = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/VISIBILITY_EMPLOYEES');
export const DOWNLOAD_EXCEL = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/DOWNLOAD_EXCEL');
export const CLEAR = 'Marketplace/Administration/ProductVersionControl/ProductAccess/CLEAR';
export const GET_PCC_LIST = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_PCC_LIST');
export const GET_ORG_NAME_FROM_PCC = createFetchTypes('Marketplace/Administration/ProductVersionControl/ProductAccess/GET_ORG_NAME_FROM_PCC');

export const initProductAccessPage = (data) => ({
  type: INIT_PRODUCT_ACCESS_PAGE,
  data
});

export const clear = (data) => ({
  type: CLEAR,
  data
});

export const getAllGroups = {
  base: (data) => ({
    type: GET_ALL_GROUPS.BASE,
    url: 'ProductAccess/getAllGroups',
    data
  }),
  request: () => ({ type: GET_ALL_GROUPS.REQUEST }),
  success: data => ({ type: GET_ALL_GROUPS.SUCCESS, data }),
  failure: error => ({ type: GET_ALL_GROUPS.FAILURE, error }),
};

export const getUserNames = {
  base: (data) => ({
    type: GET_USER_NAMES.BASE,
    url: 'ProductAccess/GetUserNames',
    data
  }),
  request: () => ({ type: GET_USER_NAMES.REQUEST }),
  success: payload => ({ type: GET_USER_NAMES.SUCCESS, payload }),
  failure: error => ({ type: GET_USER_NAMES.FAILURE, error }),
};

export const getPccList = {
  base: (data) => ({
    type: GET_PCC_LIST.BASE,
    url: 'ProductAccess/GetPccs',
    data
  }),
  request: () => ({ type: GET_PCC_LIST.REQUEST }),
  success: payload => ({ type: GET_PCC_LIST.SUCCESS, payload }),
  failure: error => ({ type: GET_PCC_LIST.FAILURE, error }),
};

export const getOrgNameFromPcc = {
  base: (data) => ({
    type: GET_ORG_NAME_FROM_PCC.BASE,
    url: 'ProductAccess/GetOrganizationForPcc',
    data
  }),
  request: () => ({ type: GET_ORG_NAME_FROM_PCC.REQUEST }),
  success: payload => ({ type: GET_ORG_NAME_FROM_PCC.SUCCESS, payload }),
  failure: error => ({ type: GET_ORG_NAME_FROM_PCC.FAILURE, error }),
};

export const getProductAccess = {
  base: (data) => ({
    type: GET_PRODUCT_ACCESS.BASE,
    url: 'ProductAccess/GetPagedProductAccessList',
    data
  }),
  request: () => ({ type: GET_PRODUCT_ACCESS.REQUEST }),
  success: payload => ({ type: GET_PRODUCT_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: GET_PRODUCT_ACCESS.FAILURE, error }),
};

export const getOrganizationNames = {
  base: (data) => ({
    type: GET_ORGANIZATION_NAMES.BASE,
    url: 'ProductAccess/GetOrganizationNames',
    data
  }),
  request: () => ({ type: GET_ORGANIZATION_NAMES.REQUEST }),
  success: payload => ({ type: GET_ORGANIZATION_NAMES.SUCCESS, payload }),
  failure: error => ({ type: GET_ORGANIZATION_NAMES.FAILURE, error }),
};

export const getPccOrg = {
  base: (data) => ({
    type: GET_PCC_ORG.BASE,
    url: 'ProductAccess/GetPccsForOrg',
    data
  }),
  request: () => ({ type: GET_PCC_ORG.REQUEST }),
  success: payload => ({ type: GET_PCC_ORG.SUCCESS, payload }),
  failure: error => ({ type: GET_PCC_ORG.FAILURE, error }),
};

export const saveProductAccess = {
  base: (data) => ({
    type: SAVE_PRODUCT_ACCESS.BASE,
    url: 'ProductAccess/SaveProductAccess',
    data
  }),
  request: () => ({ type: SAVE_PRODUCT_ACCESS.REQUEST }),
  success: payload => ({ type: SAVE_PRODUCT_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: SAVE_PRODUCT_ACCESS.FAILURE, error }),
};

export const deleteProductAccess = {
  base: (data) => ({
    type: DELETE_PRODUCT_ACCESS.BASE,
    url: 'ProductAccess/removeproductaccess',
    data
  }),
  request: () => ({ type: DELETE_PRODUCT_ACCESS.REQUEST }),
  success: payload => ({ type: DELETE_PRODUCT_ACCESS.SUCCESS, payload }),
  failure: error => ({ type: DELETE_PRODUCT_ACCESS.FAILURE, error }),
};

export const changeGlobalAssignment = {
  base: (appPublicId) => ({
    type: GLOBAL_VISIBILITY.BASE,
    url: 'productaccess/changeglobalassignment',
    appPublicId
  }),
  request: () => ({ type: GLOBAL_VISIBILITY.REQUEST }),
  success: payload => ({ type: GLOBAL_VISIBILITY.SUCCESS, payload }),
  failure: error => ({ type: GLOBAL_VISIBILITY.FAILURE, error }),
};

export const changeVisibilityNonParticipants = {
  base: (appPublicId) => ({
    type: VISIBILITY_NON_PARTICIPANTS.BASE,
    url: 'productaccess/changevisibilityfornonparticipants',
    appPublicId
  }),
  request: () => ({ type: VISIBILITY_NON_PARTICIPANTS.REQUEST }),
  success: payload => ({ type: VISIBILITY_NON_PARTICIPANTS.SUCCESS, payload }),
  failure: error => ({ type: VISIBILITY_NON_PARTICIPANTS.FAILURE, error }),
};

export const changeVisibilityTPEmployees = {
  base: (appPublicId) => ({
    type: VISIBILITY_EMPLOYEES.BASE,
    url: 'productaccess/changevisibilityfortpemployees',
    appPublicId
  }),
  request: () => ({ type: VISIBILITY_EMPLOYEES.REQUEST }),
  success: payload => ({ type: VISIBILITY_EMPLOYEES.SUCCESS, payload }),
  failure: error => ({ type: VISIBILITY_EMPLOYEES.FAILURE, error }),
};

export const downloadExcel = {
  base: (data) => ({
    type: DOWNLOAD_EXCEL.BASE,
    url: 'productaccess/exportproductaccesslisttoexcel',
    data
  }),
  request: () => ({ type: DOWNLOAD_EXCEL.REQUEST }),
  success: () => ({ type: DOWNLOAD_EXCEL.SUCCESS }),
  failure: error => ({ type: DOWNLOAD_EXCEL.FAILURE, error }),
};
