import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Editor, ProseMirror } from '@progress/kendo-react-editor';
import './BannerPreview.less';
import { getBannerNotification } from '../../reducers/modules/Home/home';

const {
  EditorState, EditorView, Plugin, PluginKey
} = ProseMirror;

class BannerPreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { editable: false };
  }

  componentDidMount() {
    const { bannerId } = this.props.match.params;
    this.props.getBannerNotification(bannerId);
  }

  onMount = event => {
    const { state } = event.viewProps;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: this.editable },
        filterTransaction: ((tr) => this.editable() || !tr.docChanged)
      })
    ];

    return new EditorView(
      { mount: event.dom }, {
        ...event.viewProps,
        state: EditorState.create({ doc: state.doc, plugins })
      }
    );
  }

  editable = () => this.state.editable;

  render() {
    if (this.props.bannerNotification.length === 0) {
      return 'No Preview Available';
    }
    return (
      <div>
        {this.props.bannerNotification.map(item => (
          <div key={item.id} className="banner-preview">
            <h2>{item.headerInfo}</h2>
            <Editor
              contentStyle={{ height: 300 }}
              defaultContent={item.description}
              onMount={this.onMount}
              // defaultEditMode="div"
            />
          </div>
        ))}
      </div>
    );
  }
}

BannerPreview.propTypes = {
  bannerNotification: PropTypes.array,
  getBannerNotification: PropTypes.func,
  match: PropTypes.object
};

const mapStateToProps = (state) => ({
  bannerNotification: state.home.bannerNotification ? state.home.bannerNotification.pagedCollection : []
});

const mapDispatchToProps = (dispatch) => ({
  getBannerNotification: (args) => dispatch(getBannerNotification.base(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerPreview);
