import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';
import * as SeoHelper from 'utils/SeoHelper';
import MpTextCollapse from 'components/common/MpTextCollapse/MpTextCollapse';

const DataCells = (props) => {
  if (props.column.name === 'productName') {
    return (
      <td>
        <Link to={`/product/${SeoHelper.SEOFriendly(props.value)}`}>{props.value}</Link>
      </td>
    );
  }
  if (props.column.name === 'reviewerUserName') {
    return (
      <td>
        <Link to={`/Profile/UserProfile/${props.row.reviewerUserID}`}>{props.value}</Link>
      </td>
    );
  }
  if (props.column.name === 'productReview') {
    return (
      <td>
        {props.value
          ? <MpTextCollapse text={props.value} maxlength={50} showLessText="Show Less" showMoreText="Show More" />
          : 'N/A'}
      </td>
    );
  }
  if (props.column.name === 'reviewTitle') {
    return (
      <td>
        {props.value || 'N/A'}
      </td>
    );
  }
  return <Table.Cell {...props} />;
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  value: PropTypes.any
};

export default DataCells;
