import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Image extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.defaultUrl = this.props.data.defaultUrl || require('../../../Styles/img/logo-placeholder-square.jpg');
  }

  render() {
    return (
      <img
        className={this.props.data.className}
        src={this.props.data.cdnLogoURL !== null ? this.props.data.cdnLogoURL : this.defaultUrl}
        alt={`${this.props.data.applicationName} logo`}
      />
    );
  }
}

Image.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string,
  cdnLogoUrl: PropTypes.string,
  applicationName: PropTypes.string
};

Image.defaultProps = {
  className: '',
  cdnLogoUrl: '',
  applicationName: ''
};

export default Image;
