import * as actionTypes from '../../actions/actionType';

export const getDeveloperOrgDetailsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_DEVELOPER_ORG_DETAILS:
      return {
        ...state,
        data: {},
        loading: true
      };
    case actionTypes.GET_DEVELOPER_ORG_DETAILS:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case actionTypes.LOAD_DEVELOPER_ORG_SAVE:
      return {
        data: action.data,
        loading: true
      };
    case actionTypes.LOAD_DEVELOPER_ORG_SAVE_SUCCESS:
      return {
        data: action.data,
        loading: false
      };
    default:
      return state;
  }
};

export default getDeveloperOrgDetailsReducer;
