import * as actionTypes from '../actions/actionType';

const browseProductReducer = (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.BROWSEPRODUCT:
      return action.data;
    default:
      return state;
  }
};

export default browseProductReducer;
