import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { BASE_URL } from 'config/apiConfig';
import { logOut } from 'reducers/modules/OAuth/oAuth';
import AuthLinks from './AuthLinks';
import { ppcpFormConfirmation } from '../../reducers/modules/Application/index';
import { canNotifyDisableLinks } from '../../actions';
import { changeUserRole } from '../../reducers/modules/Profile/userProfileReducer';

class Auth extends Component {
  login = (event) => {
    event.preventDefault();
    const pathLocation = `${window.location.pathname}${window.location.search}`;
    if (pathLocation !== '/error/500' || pathLocation !== '/blockedUser') {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
    }
    window.location = `${BASE_URL}login`;
  }

  logout = (event) => {
    event.preventDefault();
    const { user } = this.props;
    this.props.logOut(user);
  }

  checkPPCPFormModified = () => {
    if (this.props.isPPCPFormModified) {
      this.props.ppcpFormConfirmation(this.props.history);
    }
  }

  linkAction = (e) => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction();
      e.preventDefault();
    }
  }

  render() {
    const userIsLoggedIn = this.props.user && !this.props.user.expired;
    return (
      <>
        <AuthLinks
          userIsLoggedIn={userIsLoggedIn}
          isSmallView={this.props.isSmallView}
          loginHandler={this.login}
          userDetails={this.props.user}
          logoutHandler={(event) => this.logout(event)}
          isPPCPFormModified={this.props.isPPCPFormModified}
          ppcpFormConfirmation={this.checkPPCPFormModified}
          linkAction={this.linkAction}
          changeUserRole={this.props.changeUserRole}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    isPPCPFormModified: state.application.applicationLayout.currentForm.modified,
    disableLinks: state.disableLinks
  };
}

const mapDispatchToProps = (dispatch) => ({
  ppcpFormConfirmation: (args) => dispatch(ppcpFormConfirmation.base(args)),
  notifyDisableLinksAction: () => dispatch(canNotifyDisableLinks()),
  logOut: (args) => dispatch(logOut.base(args)),
  changeUserRole: roleId => dispatch(changeUserRole.base(roleId)),
});

Auth.propTypes = {
  user: PropTypes.object,
  isSmallView: PropTypes.bool,
  history: PropTypes.object,
  isPPCPFormModified: PropTypes.bool,
  ppcpFormConfirmation: PropTypes.func,
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  logOut: PropTypes.func,
  changeUserRole: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
