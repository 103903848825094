/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-wrap-multilines */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import {
  getApplicationForms,
  getApplicationQueryString,
  reviewSelector,
  getSolnGuideUrlSelector
} from 'reducers/modules/Application/selectors';
import {
  saveReviewSubmit,
  getReviewSubmit,
  updateCurrentForm,
  deleteCertificate,
  downloadCertificate,
  getSolnGuideUrl,
  uploadCloudBuild,
  clear
} from 'reducers/modules/Application';
import {
  FormHeader,
} from 'components/Application';
import * as ppcp from '../../../constants/PPCP';
import * as utils from '../../../utils/Common.Utils';
import ToolTip from '../../common/ToolTip';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import UploadCertificate from './UploadCertificate/UploadCertificate';
import ConfDeleteModal from '../../common/MPModal/ConfDeleteModal';
import UploadPlugin from '../Distribution/UploadPlugin/UploadPlugin';
import UploadInstructions from '../Distribution/UploadInstructions/UploadInstructions';
import AddSPCSolution from './CloudProducts/AddSPCSolution';
import { loadUserProfile } from '../../../actions';

const DistributionSection = ({
  type, isSpPlugin, tooltip, showUploadModal, onHideUpload, showInstructions, onHideInstructions, upload, onshowInstructions, currentVersion, lastUploadedVersion, downloadLabel, instructionsLabel
}) => (
  <form onSubmit={(e) => e.preventDefault()}>
    <div className="row">
      <div className="col-lg" style={{ width: '70%' }}>
        {(showUploadModal) && <UploadPlugin showModal={showUploadModal} popUpHide={onHideUpload} isSpPlugin={isSpPlugin} />}
        {(showInstructions) && <UploadInstructions popUpHide={onHideInstructions} name={type} />}
        <div className="row no-space">
          <div className="col-sm-4">
            <label className="required-field-star" htmlFor="direct">
              {downloadLabel}
              {' '}
              <ToolTip labelName={tooltip} />
            </label>
          </div>
          <div className="col-sm-2">
            <button type="button" className="action-button space-left-sm no-save" onClick={upload}>UPLOAD</button>
          </div>
          <div className="col-sm-6">
            <a onClick={onshowInstructions}>{instructionsLabel}</a>
          </div>
        </div>
        <div className="row no-space">
          <div className="col-sm-3 col-sm-offset-1 md-4">
            <p>
              <i>
                Current Version:
                {currentVersion}
              </i>
            </p>
            <p>
              <i>
                Uploaded Version:
                {currentVersion === lastUploadedVersion ? 'None' : lastUploadedVersion}
              </i>
            </p>
          </div>

        </div>
      </div>
    </div>
  </form>
);

DistributionSection.propTypes = {
  showUploadModal: PropTypes.bool,
  onHideUpload: PropTypes.func,
  onshowInstructions: PropTypes.func,
  onHideInstructions: PropTypes.func,
  upload: PropTypes.func,
  showInstructions: PropTypes.bool,
  currentVersion: PropTypes.string,
  lastUploadedVersion: PropTypes.string,
  downloadLabel: PropTypes.string,
  instructionsLabel: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  isSpPlugin: PropTypes.bool
};

class ReviewSubmit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showUploadModalForDirectDownload: false,
      showUploadModalForIntegrationDownload: false,
      showConfDeleteModal: false,
      showInstructionsForDirectDownload: false,
      showInstructionsForIntegrationDownload: false
    };
  }

  componentDidMount() {
    document.title = 'Review Submit';
    const { getReviewSubmitAction, updateCurrentFormAction, location } = this.props;
    const publicId = utils.getValueFromQueryString(location, 'publicid');
    getReviewSubmitAction({ publicId });
    updateCurrentFormAction();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(newProps) {
    if (newProps.certResponse !== this.props.certResponse) {
      const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
      this.props.getReviewSubmitAction({ publicId });
      this.props.updateCurrentFormAction();
    }
  }

  componentWillUnmount() {
    this.props.onUnmount('');
  }

  onHideUpload = () => this.setState({ showModal: false });

  onHideUploadPluginForDirectDownload = () => {
    this.setState({ showUploadModalForDirectDownload: false });
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    this.props.getReviewSubmitAction({ publicId });
  }

  onHideUploadPluginForIntegrationDownload = () => {
    this.setState({ showUploadModalForIntegrationDownload: false });
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    this.props.getReviewSubmitAction({ publicId });
  }

  upload = () => this.setState({ showModal: true, docname: '' });

  certificateDoc = () => (
    <div className="pad-top">
      <div className="row no-space-bottom" style={{ width: '70%' }}>
        <div className="col-sm-4 required-field-star">
          Upload the certificate
        </div>
        <div className="col-sm-2">
          <span className="col-sm-1 text-right">
            <button
              type="button"
              className={(!this.props.certResponse && !(this.props.uploadError && this.props.uploadError.existingDocList)) ? 'action-button' : 'disable-button'}
              onClick={(e) => {
                e.preventDefault();
                this.upload();
              }}
              disabled={this.props.certResponse || this.props.uploadError}
            >
              {' '}
              Upload
            </button>
          </span>
        </div>
      </div>
    </div>
  );

  certificateDocumentList = (data) => {
    this.setState({ docname: '' });
    if (data) {
      this.setState({ listExists: true });
      return (
        <div>
          {data.applicationVersion === ''
            ? (
              <p>
                <i>
                  <b>
                    {' '}
                    {data.documentName}
                    {' '}
                    for
                    {' '}
                    {data.applicationVersionType === 'integrationDownloadVersion' ? 'Integration Download' : 'Direct Download'}
                    {' '}
                    version
                    {data.applicationVersion}
                    {' '}
                    has been uploaded successfully
                  </b>
                </i>
                {' '}
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <i
                    onClick={(e) => {
                      e.preventDefault();
                      this.deleteCertificateFile();
                    }}
                    className="glyphicon glyphicon-remove"
                    style={{ color: 'red' }}
                  />
                </a>

              </p>
            )
            : (
              <div>
                <p>
                  <i>
                    <b>
                      {' '}
                      {data.documentName}
                      {' '}
                      for
                      {' '}
                      {data.applicationVersionType === 'integrationDownloadVersion' ? 'Integration Download' : 'Direct Download'}
                      version
                      {' '}
                      {data.applicationVersion}
                      {' '}
                      has been uploaded successfully
                    </b>
                  </i>
                  {' '}
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <i
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteCertificateFile();
                      }}
                      className="glyphicon glyphicon-remove"
                      style={{ color: 'red' }}
                    />
                  </a>

                </p>
              </div>
            )}
        </div>
      );
    }
    this.setState({ listExists: false });
    return '';
  }

  deleteCertificateDocument = () => {
    const { certResponse, review } = this.props;
    const item = certResponse || review.certificationDocuments[review.certificationDocuments.length - 1];
    const data = { docId: item.id, appId: this.props.review.publicGuid, version: item.applicationVersion };
    this.deletingCertificate(data);
    this.setState({ showConfDeleteModal: false });
  }

  deleteCertificateFile = () => {
    this.setState({ showConfDeleteModal: true });
  }

  deleteCallback = (publicId) => {
    this.props.getReviewSubmitAction({ publicId });
  }

  deletingCertificate = (data) => {
    const callbackFn = this.deleteCallback;
    const publicGuid = this.state.publicId;
    const params = { ...data, callbackFn, publicGuid };
    this.props.deleteCertificateAction(params);
  }

  isVersionSubmittedForApproval = (review) => {
    const certificate = review.certificationDocuments[review.certificationDocuments.length - 1];
    if (certificate && (review.isDirectDownload || review.isIntegrationDownload)) {
      if (certificate.applicationVersionType === 'directDownloadVersion') {
        const fileVersion = review.directDownloadVersions.find(x => x.certificateId === certificate.id);
        return fileVersion ? fileVersion.isSubmittedForApproval : false;
      } if (certificate.applicationVersionType === 'integrationDownloadVersion') {
        const fileVersion = review.versions.find(x => x.certificateId === certificate.id);
        return fileVersion ? fileVersion.isSubmittedForApproval : false;
      }
    }
    return false;
  }

  isEnableSubmittedForApproval = (review) => {
    const certificate = review.certificationDocuments[review.certificationDocuments.length - 1];
    if (certificate && (review.isDirectDownload || review.isIntegrationDownload)) {
      if (certificate.applicationVersionType === 'directDownloadVersion') {
        return (review.directDownloadCurrentVersion === review.directDownloadLastUploadedVersion);
      } if (certificate.applicationVersionType === 'integrationDownloadVersion') {
        return (review.integrationDownloadCurrentVersion === review.integrationDownloadLastUploadedVersion);
      }
    }
    return false;
  }

  submit = () => {
    const { saveReviewSubmitAction, history, review } = this.props;
    saveReviewSubmitAction({ history, review });
  }

  submitActivated = (isActivated) => (
    <>
      {(isActivated)
        ? <text>All the essential components have been completed and hence the application has been Activated.</text>
        : (
          <text>
            <div className="row product-incomplete">
              <span className="error-icon" />
              <em>
                All the essential components have been completed but the application is still not Activated.
                {' '}
                <br />
                Please save to Activate
              </em>
            </div>
          </text>
        )}
    </>
  )

  submitReady = () => {
    const {
      isInternal,
      isTravelportPOSApplication,
      isActivated
    } = this.props.review;
    if (isInternal) {
      return null;
    }
    return (
      <>
        <div className="row">
          {(!isTravelportPOSApplication)
            ? (
              <text>
                Please review your information before you submit. Allow up to 3 working days for a response.
                You can check the status of your submission on your Administrator Home Page.
              </text>
            )
            : this.submitActivated(isActivated)}
        </div>
      </>
    );
  }

  submitNotReady = (isTravelportPOSApplication, isInternal, platformId) => (
    <>
      {platformId === 2 ? null
        : (
          <div className="row product-incomplete">
            <span className="error-icon" />
            {(isTravelportPOSApplication || isInternal)
              ? <text><i>Your information is incomplete. All essential components must be completed in order to activate the application.</i></text>
              : <text><i>Your information is incomplete. All essential components must be completed before you can submit your product for review.</i></text>}
          </div>
        )}
    </>
  )

  linkPages = (forms, queryString) => (
    <div className="row">
      <div className="col-sm-7">
        {forms && forms.map(form => {
          if (form.name === 'REVIEW AND SUBMIT') return null;

          if (this.props.review.isInternal && ppcp.hideInternalMenus.indexOf(form.name) !== -1) {
            return null;
          } if (this.props.review.isTravelportPOSApplication && ppcp.hidePOSApplicationMenus.indexOf(form.name) !== -1) {
            return null;
          }
          return (
            <div className="row" key={form.name}>
              {(form.completed) ? <span className="check-icon" /> : <span className="blank-icon" />}
              <a href={`${form.route}${queryString}`}>
                {form.name}
              </a>
              <span>{!form.completed && '(Not Complete)'}</span>
            </div>
          );
        })}
      </div>
      <div className="col-sm-5 pad-left-double">
        <ul>
          <p>
            <b>Other Links</b>
          </p>
          <li><a href="#" download="Developer's Guide.pdf">Developers Guide</a></li>
          <li><a href="#" download="How To Upload Your Solution.pdf">How To Upload Your Solution</a></li>
        </ul>

        {/* {!this.props.review.isInternal ? <KnobComponent
          height={120}
          innerRadius={42}
          radius={55}
          width={113}
          completed={this.props.review.isProductPageComplete}
          percentageCompletion={this.props.review.percentageCompletion}
        /> : null} */}
      </div>
    </div>
  );

  certificationInstructions = () => (
    <>
      {this.props.review.isTravelportPOSApplication || this.props.review.isInternal ? ''
        : (
          <p>
            <b>
              Please note a new version of a Product also has to be uploaded with a new certificate in order to meet the Travelport security and performance standards.
              Refer to this
              {' '}
              <a href="https://support.travelport.com/webhelp/Smartpointsdk/Content/default.htm" rel="noopener noreferrer" target="_blank">
                {' '}
                <u>link</u>
                {' '}
              </a>
              {' '}
              for more information.
            </b>
          </p>
        )}
      {this.props.review.isTravelportPOSApplication || this.props.review.isInternal ? '' : this.certificateDoc()}
      <UploadCertificate
        showModal={this.state.showModal}
        popUpHide={this.onHideUpload}
        name="certificateDoc"
        isSpPlugin={false}
        docName={this.state.docname}
        docList={this.props.review.certificationDocuments}
        model={this.props.review}
      />

      {this.props.certResponse ? this.certificateDocumentList(this.props.certResponse)
        : this.certificateDocumentList(this.props.review.certificationDocuments[this.props.review.certificationDocuments.length - 1])}
      {this.props.uploadError && this.props.uploadError.existingDocList ? this.certificateDocumentList(this.props.uploadError.existingDocList[0]) : ''}
      {this.state.showConfDeleteModal ? (
        <ConfDeleteModal
          text="Are you sure you want to delete this certificate?"
          showModal
          onHideModal={() => this.setState({ showConfDeleteModal: false })}
          onDelete={() => this.deleteCertificateDocument()}
        />
      ) : null}

    </>
  )

  submitButton = () => {
    const { review } = this.props;
    if ((review.isIntegrationDownload || review.isDirectDownload) && !(review.isInternal)) {
      return (
        <button
          className={(review.isProductPageComplete && !this.isEnableSubmittedForApproval(review) && (review.isDirectDownload || review.isIntegrationDownload)
            && ((this.props.certResponse || this.state.listExists) && !this.props.uploadError && !this.isVersionSubmittedForApproval(review))) ? 'action-button' : 'disable-button'}
          type="button"
          onClick={this.submit}
          disabled={!(review.isProductPageComplete) || (this.props.uploadError
            && !(this.props.uploadError.existingDocList))
            || (!this.state.listExists && !this.props.certResponse)
            || this.isVersionSubmittedForApproval(review) || this.isEnableSubmittedForApproval(review)}
        >
          {this.props.review.isInternal ? 'Complete Review' : 'SUBMIT FOR APPROVAL'}
        </button>
      );
    }
    return (
      <button
        className={(review.isProductPageComplete) && (!review.isPublished && (review.isDistributedElsewhere || review.isNoDistributionNeeded)) ? 'action-button' : 'disable-button'}
        type="button"
        onClick={this.submit}
        disabled={!(review.isProductPageComplete) || (review.isPublished && (review.isDistributedElsewhere || review.isNoDistributionNeeded))}
      >
        {this.props.review.isInternal ? 'Complete Review' : 'SUBMIT FOR APPROVAL'}
      </button>

    );
  }

  uploadCloudBuild = (version, selectedUrls) => {
    this.props.uploadCloudBuildAction({
      appVersion: version,
      urls: selectedUrls.map(i => i.name),
      appGuid: this.props.review.publicGuid,
      appId: this.props.review.id
    });
  }

  render() {
    const {
      review
    } = this.props;
    if (!review) {
      return <LoaderComponent show />;
    }
    const attributes = review.platformId === 2 ? { header: 'Add Solution' } : {
      header: (review.isInternal || review.isTravelportPOSApplication) ? 'Review' : 'Add Solution Files',
      infoText: 'Please raise a MyTravelport ticket for the new solution or the new version of the solution.After your solution is approved by the certification team, please attach the certification document here. If certification was not needed, please attach the email from the Certification team.',
      importantText: 'This step is mandatory for submitting a solution for approval.'
    };

    return (
      <>
        <FormHeader
          {...attributes}
        />
        {review.platformId === 2 ? <AddSPCSolution appId={review.id} appName={review.title} isPublished={review.isPublished} masterCustomerNumber={this.props.userProfile.agencyDetails.masterCustomernumber} /> : null}
        {review.platformId !== 2 && review.isDirectDownload ? (
          <DistributionSection
            showUploadModal={this.state.showUploadModalForDirectDownload}
            onHideUpload={() => this.onHideUploadPluginForDirectDownload()}
            showInstructions={this.state.showInstructionsForDirectDownload}
            onHideInstructions={() => this.setState({ showInstructionsForDirectDownload: false })}
            upload={() => this.setState({ showUploadModalForDirectDownload: true })}
            onshowInstructions={() => this.setState({ showInstructionsForDirectDownload: true })}
            currentVersion={review.directDownloadCurrentVersion}
            lastUploadedVersion={review.directDownloadLastUploadedVersion}
            downloadLabel="Direct Download from Marketplace"
            instructionsLabel="Downloadable file upload instructions"
            type="directDownload"
            isSpPlugin={false}
            tooltip={
              review.isInternal
                ? `Select this option if you want your Agency users to be able to download your solution from Travelport Marketplace.
              The file you upload should include an installer.`
                : 'Select if you wish Users to be able to download your solution directly from Travelport Marketplace. The file you upload here should include an installer.'
            }
          />
        ) : null}
        {this.props.review.isIntegrationDownload && !this.props.review.isTravelportPOSApplication && review.platformId !== 2 ? (
          <DistributionSection
            showUploadModal={this.state.showUploadModalForIntegrationDownload}
            onHideUpload={() => this.onHideUploadPluginForIntegrationDownload()}
            showInstructions={this.state.showInstructionsForIntegrationDownload}
            onHideInstructions={() => this.setState({ showInstructionsForIntegrationDownload: false })}
            upload={() => this.setState({ showUploadModalForIntegrationDownload: true })}
            onshowInstructions={() => this.setState({ showInstructionsForIntegrationDownload: true })}
            currentVersion={this.props.review.integrationDownloadCurrentVersion}
            lastUploadedVersion={this.props.review.integrationDownloadLastUploadedVersion}
            downloadLabel="Marketplace Integration Plug-in"
            instructionsLabel="Smartpoint Integration file upload instructions"
            isSpPlugin
            type="marketplaceIntegration"
            tooltip={
              review.isInternal
                ? `Select this option if you want your Agency user to utilize the Solution Management function in Smartpoint to download and install your solution.
              NOTE:  users will have to have the Markerplace plug-in installed for this function to work.`
                : `Select if you wish Users to be able to download and install your solution directly into Smartpoint  via the Marketplace Integration Plug-in.
              Not all users will have access to this plug-in in Smartpoint The file you upload here should just be the .dll file.`
            }
          />
        ) : null}
        {(review.isDirectDownload || review.isIntegrationDownload) && review.platformId !== 2 ? this.certificationInstructions() : ''}
        {/* {this.linkPages(forms, queryString)} */}

        {(review.isProductPageComplete && review.platformId !== 2 && (this.props.certResponse || !this.props.uploadError))
          ? this.submitReady() : this.submitNotReady(this.props.review.isTravelportPOSApplication, this.props.review.isInternal, review.platformId)}
        {review.platformId !== 2
          ? (
            <div className="row" style={{ paddingTop: '15px' }}>
              <a className="back-button" href="/Account/MyProfile?selectedTab=mysolutions">To MySolutions</a>
              {' '}
              {
                !review.isTravelportPOSApplication
                  ? this.submitButton()
                  : null
              }
              {' '}
              {this.props.review.isInternal
                ? (
                  <ToolTip labelName={`When you click Complete Review, you will be be asked to review and accept some Travelport Marketplace terms and conditions before your
Internal Product page can be published for your organization.`}
                  />
                ) : null}
            </div>) : null}
      </>
    );
  }
}

ReviewSubmit.propTypes = {
  forms: PropTypes.array,
  queryString: PropTypes.string,
  history: PropTypes.object,
  getReviewSubmitAction: PropTypes.func,
  saveReviewSubmitAction: PropTypes.func,
  updateCurrentFormAction: PropTypes.func,
  location: PropTypes.object,
  review: PropTypes.object,
  certResponse: PropTypes.object,
  uploadError: PropTypes.object,
  deleteCertificateAction: PropTypes.func,
  downloadFile: PropTypes.func,
  onUnmount: PropTypes.func,
  solnGuideUrl: PropTypes.string,
  getSolnGuideUrl: PropTypes.func,
  uploadCloudBuildAction: PropTypes.func,
  solnGuideUrlValue: PropTypes.string,
  loadUserProfileAction: PropTypes.func,
  userProfile: PropTypes.object,
};

ReviewSubmit.defaultProps = {
  queryString: ''
};

const mapStateToProps = state => ({
  forms: getApplicationForms(state),
  queryString: getApplicationQueryString(state),
  review: reviewSelector(state),
  certResponse: state.application.review.certresponse,
  deleteResponse: state.application.review.deleteResponse,
  uploadError: state.application.review.error,
  solnGuideUrl: getSolnGuideUrlSelector(state),
  solnGuideUrlValue: getSolnGuideUrlSelector(state),
  userProfile: state.profile.userProfile,
});

const mapDispatchToProps = dispatch => ({
  saveReviewSubmitAction: (args) => dispatch(saveReviewSubmit.base(args)),
  getReviewSubmitAction: (args) => dispatch(getReviewSubmit.base(args)),
  updateCurrentFormAction: () => dispatch(updateCurrentForm(ppcp.REVIEW_ID)),
  deleteCertificateAction: (data) => dispatch(deleteCertificate.base(data)),
  downloadFile: (data) => dispatch(downloadCertificate(data)),
  onUnmount: (data) => dispatch(clear(data)),
  getSolnGuideUrl: () => dispatch(getSolnGuideUrl.base()),
  uploadCloudBuildAction: (data) => dispatch(uploadCloudBuild.base(data)),
  loadUserProfileAction: bindActionCreators(loadUserProfile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSubmit);
