import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';
import MultiSelectComponent from 'components/common/FormInputs/SyncMultiSelect/MultiSelectComponent';
import { rolesArrayForManageRoles, rolesArrayForNonTravelportUsers } from 'utils/rolesHelper';

const EditCell = (props) => {
  switch (props.column.name) {
    case 'userId':
      return <td><Link to={`/Profile/UserProfile/${props.row.id}`}>{props.row.userId}</Link></td>;
    case 'organization':
      return <td><Link to={`/Profile/AgencyProfile/${props.row.organizationId}`}>{props.row.organization}</Link></td>;
    case 'roles': {
      const roleList = props.currentUser.profile.CurrentRoleName === 'TPSupport'
        ? rolesArrayForManageRoles.filter(x => x.id !== 2 && x.id !== 1) : rolesArrayForManageRoles;
      const rolesList = props.row.isTravelportEmployee ? roleList : rolesArrayForNonTravelportUsers;
      const options = rolesList.map(r => ({ value: r.id, label: r.text }));
      const selectedRoleIds = props.row.roles.map(r => r.id);
      const selectedValues = options.filter(o => selectedRoleIds.indexOf(o.value) !== -1);

      return (
        <td>
          <MultiSelectComponent
            value={selectedValues}
            options={options}
            onValueChange={props.onValueChange}
            textDescription="(n) Role(s) Assigned"
            placeholder="Select roles"
          />
        </td>
      );
    }
    default:
      return <Table.Cell {...props} />;
  }
};

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  platformlist: PropTypes.array,
  currentUser: PropTypes.object,
  profile: PropTypes.object,
  currentRoleName: PropTypes.string
};
const mapStateToProps = state => ({
  currentUser: state.oidc.user
});
export default connect(mapStateToProps, null)(EditCell);
