import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as SeoHelper from '../../utils/SeoHelper';
import PriceType from './PriceType/PriceType';
import StarComponent from './StarComponent';
import Image from './Image/Image';

class TileLayout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Link
        className="browse-products-tile"
        to={`/Product/${SeoHelper.SEOFriendly(this.props.applicationName)}`}
      >
        <div className="row">
          <div className="col-sm-3">
            <Image
              data={{
                applicationName: this.props.applicationName,
                cdnLogoURL: this.props.logoUrl
              }}
            />
            {this.props.priceType === 0 ? <PriceType type="Free" /> : null}
            {(this.props.priceType === 1 || this.props.priceType === 2) && this.props.hasTrialPeriod ? <PriceType type="FreeTrial" /> : null}
            {(this.props.priceType === 1 || this.props.priceType === 2) && !this.props.hasTrialPeriod ? <PriceType type="Paid" /> : null}
          </div>
          <div className="col-sm-9 pad-right pad-top content">
            <div className="row browse-products-tile-title">{this.props.applicationName}</div>
            <div className="row browse-products-tile-dev">
              {!this.props.isDeveloperTile
                && (
                <div className="col-sm-8">
                  <strong>
                    {'by '}
                    {this.props.vendorPublisher}
                  </strong>
                </div>
                )}
              <div className="col-sm-4" style={{ fontStyle: 'initial' }}>
                {this.props.netRating && this.props.netRating !== 0
                  ? <StarComponent edit={false} value={this.props.netRating} color2="#4E6659" /> : null}
              </div>
            </div>
            <div className="row browse-products-tile-description">{this.props.basicDescription}</div>
            {this.props.platformName
              ? (
                <div className="row browse-products-tile-platform">
                  <strong>Platform:</strong>
                  {this.props.platformName}
                </div>
              ) : null}

            {this.props.showNewButton ? <PriceType type="New" /> : null}
          </div>
        </div>
      </Link>
    );
  }
}

TileLayout.propTypes = {
  applicationName: PropTypes.string,
  vendorPublisher: PropTypes.string,
  logoUrl: PropTypes.string,
  basicDescription: PropTypes.string,
  linkFor: PropTypes.string,
  platformName: PropTypes.string,
  netRating: PropTypes.number,
  showNewButton: PropTypes.bool,
  priceType: PropTypes.number,
  hasTrialPeriod: PropTypes.bool,
  isDeveloperTile: PropTypes.bool
};

export default TileLayout;
