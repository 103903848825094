import * as React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  getPccsSelector,
  trasnformPccData,
} from 'reducers/modules/Profile/Agency/AgencyDetails';

class SelectPcc extends React.PureComponent {
  handleOnChange = selectOption => {
    if (selectOption) {
      return this.props.onValueChange(selectOption.value);
    }

    return this.props.onValueChange('');
  };

  render() {
    const options = trasnformPccData(this.props.pccs);
    const value = { value: this.props.value, label: this.props.value };
    return (
      <td>
        <Select
          className="travelport-select"
          resetValue=""
          escapeClearsValue={false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          value={value}
          onInputChange={this.onHostAutocomplete}
          options={options}
          onChange={this.handleOnChange}
        />
      </td>
    );
  }
}
SelectPcc.defaultProps = {
  pccs: []
};

SelectPcc.propTypes = {
  onValueChange: PropTypes.func,
  getPccs: PropTypes.func,
  value: PropTypes.string,
  hostValue: PropTypes.string,
  pccs: PropTypes.array
};

const mapStateToProps = state => ({
  pccs: getPccsSelector(state)
});

export default connect(mapStateToProps, null)(SelectPcc);
