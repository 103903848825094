/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApiClient from 'Services/ApiClient';
import { loadUserInfo } from 'reducers/modules/OAuth/oAuth';

import CheckBoxInput from '../common/CheckBoxInput';
import { loadDeveloperFilterDetails, loadDeveloperBrowse } from '../../actions';
import * as CommonServices from '../../Services/Common.Service';
import LeftPanelFilter from '../common/LeftPanelFilter';
import DeveloperTileLayout from './DeveloperTileLayout';
import ResultNotFound from '../common/ResultNotFound';
import PageNation from '../common/PaginationComponent';
import HeaderContent from '../common/HeaderContentTileArea';
import LoaderComponent from '../common/Loader/LoaderComponent';
import { handleCategoryList, handleSortByValues } from '../Products/Products';

export const getFilterItem = (headerName, itemList, handleChangeCheckBox) => (
  <LeftPanelFilter header={headerName}>
    {itemList.map((v, i) => (
      <li key={i.toString()}>
        <CheckBoxInput
          {...v}
          handleChangeCheckBoxExport={
            handleChangeCheckBox
          }
        />
      </li>
    ))}
  </LeftPanelFilter>
);
export const searchFunctionality = (headerName, searchTextVal, searchFunc, handleChangeSearchText) => (
  <LeftPanelFilter header={headerName}>
    <input
      type="search"
      className="product-search-input"
      id="ProductSearchText"
      placeholder={headerName.toLowerCase()}
      name="searchText"
      value={searchTextVal}
      onChange={searchFunc}
    />
    <Link
      to="#"
      className="action-button search-tab"
      id="product-search"
      name="searchText"
      onClick={e => handleChangeSearchText(e)}
    >
      Search
    </Link>
  </LeftPanelFilter>
);
class Developers extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      searchOpen: true,
      categoriesOpen: true,
      categoriesMore: true,
      regionsOpen: true,
      pricingOpen: true,
      platformOpen: true,
      hostopen: true,
      activePage: 1,
      previousStatus: true,
      nextStatus: false,
      checkedArrayCatergories: [],
      checkedArrayRegions: [],
      checkedArrayPlatform: [],
      checkedArrayLang: [],
      checkedArrayCustomDevelopment: false,
      checkedArrayHost: [],
      sortByValue: '',
      searchText: '',
      showLoader: false,
      SearchNotFoundError: false
    };
    this.sortByValueItems = [
      {
        value: 'Name',
        Name: 'Name A to Z'
      },
      {
        value: 'NameDesc',
        Name: 'Name Z to A'
      }
    ];

    if (typeof this.props.location.cleanFilter === 'undefined') {
      localStorage.removeItem('dataFilterDevelopers');
    }
  }

  componentDidMount() {
    CommonServices.SSOProcessing(this.props.user);

    const response = CommonServices.fetchDeveloperFilterDetails();
    response
      .then(res => {
        this.props.loadDeveloperFilterDetails(res);
      })
      .catch(() => {
        this.props.loadDeveloperFilterDetails([]);
      });

    return this.restoreFilterData(this.storeGetDataFilter());
  }

  getTileLayout(developerProducts) {
    return developerProducts.map((v, i) => (
      <div className="col-sm-6" key={`developerProducts${Date.now() + i}`}>
        <DeveloperTileLayout key={i.toString()} {...v} />
      </div>
    ));
  }

  storeSetDataFilter = data => localStorage.setItem('dataFilterDevelopers', JSON.stringify(data));

  storeGetDataFilter = () => JSON.parse(localStorage.getItem('dataFilterDevelopers')) || {};

  restoreFilterData = data => {
    const dataItemLabel = {
      searchText: 'searchText',
      currentPage: 'activePage',
      sortBy: 'sortByValue',
      categoryIds: 'checkedArrayCatergories',
      gdsIds: 'checkedArrayHost',
      platformIds: 'checkedArrayPlatform',
      regionIds: 'checkedArrayRegions',
      LanguageList: 'checkedArrayLang',
      isCustDevSupported: 'checkedArrayCustomDevelopment'
    };

    const tmpObj = {};
    const resultFilterOptions = Object.keys(data).map(item => {
      const itemKey = item;
      const itemValue = data[item];
      tmpObj[dataItemLabel[itemKey]] = itemValue;
      return tmpObj;
    });

    this.setState(resultFilterOptions[0]);
    this.serviceCallProductJson(resultFilterOptions[0]);
  };

  serviceCallProductJson = data => {
    let dataTmp = {};
    if (data) {
      dataTmp = {
        searchText: data.searchText,
        currentPage: data.activePage,
        sortBy: data.sortByValue,
        categoryIds: data.checkedArrayCatergories,
        gdsIds: data.checkedArrayHost,
        platformIds: data.checkedArrayPlatform,
        LanguageList: data.checkedArrayLang,
        regionIds: data.checkedArrayRegions,
        isCustDevSupported: data.checkedArrayCustomDevelopment
      };
    } else {
      dataTmp = {
        searchText: this.state.searchText,
        currentPage: this.state.activePage,
        sortBy: this.state.sortByValue,
        categoryIds: this.state.checkedArrayCatergories,
        gdsIds: this.state.checkedArrayHost,
        platformIds: this.state.checkedArrayPlatform,
        LanguageList: this.state.checkedArrayLang,
        regionIds: this.state.checkedArrayRegions,
        isCustDevSupported: this.state.checkedArrayCustomDevelopment
      };
    }

    this.fetchBrowseProductsAll(dataTmp);
  };

  handleChangeSortBy = value => {
    this.setState({ sortByValue: value });
    const data = {
      searchText: this.state.searchText,
      currentPage: this.state.activePage,
      sortBy: value,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      LanguageList: this.state.checkedArrayLang,
      regionIds: this.state.checkedArrayRegions,
      isCustDevSupported: this.state.checkedArrayCustomDevelopment
    };
    this.fetchBrowseProductsAll(data);
  };

  handleChangeCheckBoxCatergories = (value, checked) => {
    if (checked) {
      this.state.checkedArrayCatergories.push(value);
    } else {
      this.state.checkedArrayCatergories = this.state.checkedArrayCatergories.filter(
        val => val !== value
      );
    }
    this.serviceCallProductJson();
  };

  handleChangeCheckBoxRegion = (value, checked) => {
    if (checked) {
      this.state.checkedArrayRegions.push(value);
    } else {
      this.setState({
        checkedArrayRegions: this.state.checkedArrayRegions.filter(
          val => val !== value
        )
      });
    }
    this.serviceCallProductJson();
  };

  handleChangeCheckBoxCustomDevelopment = (value, checked) => {
    if (checked) {
      this.setState({ checkedArrayCustomDevelopment: true });
      const data = {
        searchText: this.state.searchText,
        currentPage: this.state.activePage,
        sortBy: this.state.sortByValue,
        categoryIds: this.state.checkedArrayCatergories,
        gdsIds: this.state.checkedArrayHost,
        platformIds: this.state.checkedArrayPlatform,
        LanguageList: this.state.checkedArrayLang,
        regionIds: this.state.checkedArrayRegions,
        isCustDevSupported: true
      };
      this.fetchBrowseProductsAll(data);
    } else {
      this.setState({ checkedArrayCustomDevelopment: false });
      const data = {
        searchText: this.state.searchText,
        currentPage: this.state.activePage,
        sortBy: this.state.sortByValue,
        categoryIds: this.state.checkedArrayCatergories,
        gdsIds: this.state.checkedArrayHost,
        platformIds: this.state.checkedArrayPlatform,
        LanguageList: this.state.checkedArrayLang,
        regionIds: this.state.checkedArrayRegions,
        isCustDevSupported: false
      };
      this.fetchBrowseProductsAll(data);
    }
  };

  handleChangeCheckBoxLanguage = (value, checked) => {
    if (checked) {
      this.state.checkedArrayLang.push(value);
    } else {
      this.setState({
        checkedArrayLang: this.state.checkedArrayLang.filter(
          val => val !== value
        )
      });
    }
    this.serviceCallProductJson();
  };

  handleChangeCheckBoxPlatform = (value, checked) => {
    if (checked) {
      this.state.checkedArrayPlatform.push(value);
    } else {
      this.setState({
        checkedArrayPlatform: this.state.checkedArrayPlatform.filter(
          val => val !== value
        )
      });
    }
    this.serviceCallProductJson();
  };

  handleChangeCheckBoxHosts = (value, checked) => {
    if (checked) {
      this.state.checkedArrayHost.push(value);
    } else {
      this.setState({
        checkedArrayHost: this.state.checkedArrayHost.filter(
          val => val !== value
        )
      });
    }
    this.serviceCallProductJson();
  };

  handleChangePageNo = pageNo => {
    const data = {
      searchText: this.state.searchText,
      currentPage: pageNo,
      sortBy: this.state.sortByValue,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      LanguageList: this.state.checkedArrayLang,
      regionIds: this.state.checkedArrayRegions,
      isCustDevSupported: this.state.checkedArrayCustomDevelopment
    };
    this.fetchBrowseProductsAll(data);
  };

  handleSearchText = event => {
    this.setState({ searchText: event.target.value });
  };

  handleChangeSearchText = e => {
    e.preventDefault();
    const data = {
      searchText: this.state.searchText,
      currentPage: this.state.activePage,
      sortBy: this.state.sortByValue,
      categoryIds: this.state.checkedArrayCatergories,
      gdsIds: this.state.checkedArrayHost,
      platformIds: this.state.checkedArrayPlatform,
      LanguageList: this.state.checkedArrayLang,
      regionIds: this.state.checkedArrayRegions,
      isCustDevSupported: this.state.checkedArrayCustomDevelopment
    };
    this.fetchBrowseProductsAll(data);
  };

  handleRegionList = (regionsList) => {
    regionsList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayRegions.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handleToolsList = (toolsList) => {
    toolsList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayPlatform.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handleLanguageList = (languageList) => {
    languageList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayLang.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }
      return tmpItem;
    });
  }

  handleHostList =(hostList) => {
    hostList.map(item => {
      const tmpItem = item;
      if (this.state.checkedArrayHost.includes(item.id)) {
        tmpItem.checked = true;
      } else {
        tmpItem.checked = false;
      }

      return tmpItem;
    });
  }

  fetchBrowseProductsAll(data) {
    this.storeSetDataFilter(data);
    this.setState({
      showLoader: true
    });

    const url = '/DeveloperDirectory/GetDevelopers';
    ApiClient.httpClient
      .post(url, { ...data })
      .then(res => {
        this.props.loadDeveloperBrowse(res.data);
        this.setState({
          showLoader: false,
          SearchNotFoundError: false
        });

        if (
          !this.props.developerProducts.length
          && !this.props.developerProducts.developerTileViewModel.length
        ) {
          this.setState({
            SearchNotFoundError: true
          });
        }
      })
      .catch(() => {
        this.props.loadDeveloperBrowse([]);
        this.setState({
          showLoader: false,
          SearchNotFoundError: true
        });
      });
  }

  render() {
    this.catergoriesList = this.props.developerFilter.categoryList || [];
    this.regionsList = this.props.developerFilter.regionList || [];
    this.customeDevelopList = [
      {
        name: 'Offered by this Developer',
        checked: this.state.checkedArrayCustomDevelopment
      }
    ];
    this.toolsList = this.props.developerFilter.toolsList || [];
    this.languageList = this.props.developerFilter.languageList || [];
    this.hostList = this.props.developerFilter.hostList || [];
    this.developerProducts = this.props.developerProducts
        && this.props.developerProducts.developerTileViewModel
      ? this.props.developerProducts.developerTileViewModel
      : [];

    handleSortByValues(this.state.sortByValue);
    handleCategoryList(this.catergoriesList, this.state.checkedArrayCatergories);

    this.handleRegionList(this.regionsList);

    this.handleToolsList(this.toolsList);

    this.handleLanguageList(this.languageList);

    this.handleHostList(this.hostList);

    return (
      <div className="container">
        <LoaderComponent show={this.state.showLoader} />
        <h2 className="page-title">Browse Developer</h2>
        <div className="row browse-products-main">
          <div className="hidden-xs col-sm-3 browse-products-filter-col">
            <form
              id="filter-search-form"
              onSubmit={e => this.handleChangeSearchText(e)}
            >
              {searchFunctionality('Search Developers', this.state.searchText, this.handleSearchText, this.handleChangeSearchText)}
              {getFilterItem('Categories', this.catergoriesList, this.handleChangeCheckBoxCatergories)}
              {getFilterItem('Regions', this.regionsList, this.handleChangeCheckBoxRegion)}
              <LeftPanelFilter header="Custom Development">
                {this.customeDevelopList.map((v, i) => (
                  <li key={i.toString()}>
                    <CheckBoxInput
                      {...v}
                      handleChangeCheckBoxExport={
                        this.handleChangeCheckBoxCustomDevelopment
                      }
                    />
                  </li>
                ))}
              </LeftPanelFilter>
              <LeftPanelFilter header="Travelport Tools">
                {this.toolsList.map((v, i) => (
                  <li key={i.toString()}>
                    <CheckBoxInput
                      {...v}
                      handleChangeCheckBoxExport={
                        this.handleChangeCheckBoxPlatform
                      }
                    />
                  </li>
                ))}
              </LeftPanelFilter>
              <LeftPanelFilter header="Development Languages">
                {this.languageList.map((v, i) => (
                  <li key={i.toString()}>
                    <CheckBoxInput
                      {...v}
                      handleChangeCheckBoxExport={
                        this.handleChangeCheckBoxLanguage
                      }
                    />
                  </li>
                ))}
              </LeftPanelFilter>
              {getFilterItem('Hosts', this.hostList, this.handleChangeCheckBoxHosts)}
            </form>
          </div>
          <div
            id="frmdiv"
            className="col-xs-12 col-sm-9"
            style={{
              display: this.developerProducts.length !== 0 ? 'block' : 'none'
            }}
          >
            <HeaderContent
              startPage={parseInt(
                this.props.developerProducts.startAppNumber,
                0
              )}
              endPage={parseInt(this.props.developerProducts.endAppNumber, 0)}
              handleChangeSortBy={this.handleChangeSortBy}
              totalPage={parseInt(
                this.props.developerProducts.totalApplication,
                0
              )}
              sortByItems={this.sortByValueItems}
            />
            <div className="row browse-products-tiles-row">
              {this.getTileLayout(this.developerProducts)}
            </div>
          </div>
          <div
            id="frmdiv"
            className="col-xs-12 col-sm-9"
            style={{
              display: this.state.SearchNotFoundError ? 'block' : 'none'
            }}
          >
            <ResultNotFound />
          </div>
          <div
            className="browse-products-pagination col-sm-offset-3"
            id="paging-developer"
            style={{
              display:
                this.developerProducts.length > 0
                  && parseInt(this.props.developerProducts.totalApplication, 0) > 16
                  ? 'block'
                  : 'none'
            }}
          >
            <ul id="pagination-container" className="pagination-md" />
            <PageNation
              handleChangePageNo={this.handleChangePageNo}
              currentPage={this.props.developerProducts.currentPage}
              totalPages={this.props.developerProducts.totalPages}
              _pageSize={16}
              _pageIndex={0}
              totalRecords={parseInt(this.props.developerProducts.totalApplication, 0)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  developerFilter: state.filterDeveloperDetailsReducer,
  developerProducts: state.browseDeveloperReducers,
  user: state.oidc.user
});

const mapDispatchToProps = dispatch => ({
  loadDeveloperFilterDetails: bindActionCreators(
    loadDeveloperFilterDetails,
    dispatch
  ),
  loadDeveloperBrowse: bindActionCreators(loadDeveloperBrowse, dispatch),
  loadUserInfo: () => dispatch(loadUserInfo.base())
});

Developers.propTypes = {
  loadDeveloperFilterDetails: PropTypes.func,
  loadDeveloperBrowse: PropTypes.func,
  developerFilter: PropTypes.shape({
    categoryList: PropTypes.array,
    regionList: PropTypes.array,
    toolsList: PropTypes.array,
    languageList: PropTypes.array,
    hostList: PropTypes.array
  }),
  user: PropTypes.object,
  loadUserInfo: PropTypes.func,
  developerProducts: PropTypes.any,
  totalPages: PropTypes.number,
  startPage: PropTypes.number,
  endPage: PropTypes.number,
  currentPage: PropTypes.number,
  sortByItems: PropTypes.any,
  location: PropTypes.shape({
    cleanFilter: PropTypes.bool
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Developers);
