import { takeLatest, call, put } from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const FETCH_TERMS_AND_CONDITIONS_DISPLAY = createFetchTypes(
  'Marketplace/application/termsAndConditionsDisplay/FETCH_TERMS_AND_CONDITIONS_DISPLAY'
);
const FETCH_TERMS_AND_CONDITIONS_DISPLAY_URL = 'Application/TermsAndConditionsDisplay';

export const getTermsAndConditionsDisplayDetails = (state) => state.application.termsAndConditionsDisplay.data;

function termsAndConditionsDisplay(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_TERMS_AND_CONDITIONS_DISPLAY.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TERMS_AND_CONDITIONS_DISPLAY.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case FETCH_TERMS_AND_CONDITIONS_DISPLAY.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export const fetchTermsAndConditionsDisplay = {
  base: id => ({
    type: FETCH_TERMS_AND_CONDITIONS_DISPLAY.BASE,
    id,
    url: FETCH_TERMS_AND_CONDITIONS_DISPLAY_URL
  }),
  request: () => ({ type: FETCH_TERMS_AND_CONDITIONS_DISPLAY.REQUEST }),
  success: data => ({ type: FETCH_TERMS_AND_CONDITIONS_DISPLAY.SUCCESS, data }),
  failure: error => ({ type: FETCH_TERMS_AND_CONDITIONS_DISPLAY.FAILURE, error })
};

function* fetchTermsAndConditionsDisplaySaga({ url, id }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchTermsAndConditionsDisplay, ApiClient.httpClient.get, `${url}/${id}`));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* watchTermsAndConditionsDisplaySaga() {
  yield takeLatest(FETCH_TERMS_AND_CONDITIONS_DISPLAY.BASE, fetchTermsAndConditionsDisplaySaga);
}

export default termsAndConditionsDisplay;
