import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';

export const GET_MATCHING_HOST = createFetchTypes(
  'Marketplace/Profile/Agency/AgencyDetails/GET_MATCHING_HOST'
);

const GET_MATCHING_HOST_CLEAN = 'GET_MATCHING_HOST_CLEAN';
export const cleanGetHostMatchingId = () => ({ type: GET_MATCHING_HOST_CLEAN });

export const getHostIds = {
  base: params => ({
    type: GET_MATCHING_HOST.BASE,
    url: 'Profile/GetMatchingHostIds',
    payload: {
      params
    }
  }),
  request: () => ({
    type: GET_MATCHING_HOST.REQUEST
  }),
  success: data => ({
    type: GET_MATCHING_HOST.SUCCESS,
    payload: {
      data
    }
  }),
  failure: error => ({
    type: GET_MATCHING_HOST.FAILURE,
    payload: {
      error
    }
  })
};

export const getHostIdsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_MATCHING_HOST.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };

    case GET_MATCHING_HOST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.sort()
      };

    case GET_MATCHING_HOST.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case GET_MATCHING_HOST_CLEAN:
      return {};
    default:
      return state;
  }
};

export function* getHostIdsReducerWatcher(action) {
  try {
    const data = action.payload.params;
    const url = `${action.url}?hostIdSearchString=${data}`;
    yield call(() => fetchEntity(getHostIds, ApiClient.httpClient.get, url, { data }));
  } catch (error) {
    yield put(showAlertAction(error.message));
  }
}

export default function* watchGetMatchingHostSaga() {
  yield takeLatest(GET_MATCHING_HOST.BASE, getHostIdsReducerWatcher);
}

export const getHostIdsSelector = state => state.profile.hostList.data;

export const trasnformHostData = data => data.map(item => ({
  value: item,
  label: item
}));
