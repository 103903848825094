import { select } from 'redux-saga/effects';

const viewPCCsSelector = state => state.administration.organizationDirectory.viewPCCs;

export function* createViewPCCsRequest() {
  const viewPCCsState = yield select(viewPCCsSelector);
  return viewPCCsState.values;
}

export function getViewPccsData(state) {
  return {
    data: state.administration.organizationDirectory.viewPccs
  };
}
