import * as actionTypes from '../../../actions/actionType';

const ordersReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAILS_FOR_TERMS:
      return {
        ...state,
        detailsForTerms: {
          loading: true,
          data: {},
          error: null
        },
        orderDetails: null
      };
    case actionTypes.DETAILS_FOR_TERMS_LOAD_SUCCESS:
      return {
        ...state,
        detailsForTerms: {
          loading: false,
          data: action.payload.data,
          error: null
        },
        orderDetails: null
      };
    case actionTypes.DETAILS_FOR_TERMS_LOAD_FAILURE:
      return {
        ...state,
        detailsForTerms: {
          loading: false,
          data: {},
          error: action.payload.error
        },
        orderDetails: null
      };
    case actionTypes.BUY_APPLICATION:
      return {
        ...state,
        orderDetails: {
          loading: true,
          data: {},
          error: null
        }
      };
    case actionTypes.BUY_APPLICATION_SUCCESS:
      return {
        ...state,
        orderDetails: {
          loading: false,
          data: action.payload.data,
          error: null
        }
      };
    case actionTypes.BUY_APPLICATION_FAILURE:
      return {
        ...state,
        orderDetails: {
          loading: false,
          data: {},
          error: action.payload.error
        }
      };
    default:
      return state;
  }
};

export default ordersReducer;
