/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';
import {
  getOrganizationNames,
  getPccOrg,
  getUserNames,
  getPccList,
  getOrgNameFromPcc,
} from 'reducers/modules/Administration/ProductAccess/actions';
import {
  getUserNamesSelector,
  getOrgNamesSelector,
  getPccOrgNamesSelector,
  getAccessCategorySelector,
  getGroupsSelector,
  getPccListSelector
} from 'reducers/modules/Administration/ProductAccess/selectors';
import { getAccessCategorySelectorForApplication } from 'reducers/modules/Application';
import { getCountriesSelector } from 'reducers/modules/countries';
import { getRegionsSelector } from 'reducers/modules/regions';

const getFormattedValue = (label, value) => ({ label, value });

const checkTypeaheadData = (
  value,
  action,
  length
) => {
  if (value && value.length > length) {
    action({ value });
  }
};
const conditionForCategoryValueTwo = (currentAccessCategory) => (currentAccessCategory && currentAccessCategory.value === '3');
const getCurrentAccessCategory = (props) => {
  const currAccCategory = props.accessCategoryList ? props.accessCategoryList.find(a => a.label === props.currentAccessCategory)
    : props.accessCategoryListForProducts.find(a => a.label === props.currentAccessCategory);
  return currAccCategory;
};
const getItemsForAccessCategory = (props) => (props.accessCategoryList || props.accessCategoryListForProducts);
const getSelectedOptionForAccessCategory = (props, selectedText) => {
  const selectedOptionForAccessCategory = props.accessCategoryList ? props.accessCategoryList.find(a => a.label === selectedText)
    : props.accessCategoryListForProducts.find(a => a.label === props.currentAccessCategory);
  return selectedOptionForAccessCategory;
};
const getSelectedValueForAccessCategory = (selectedOption) => (selectedOption && selectedOption.value);
const getValue = (selectedValue, currentAccessCategory, props) => {
  let value;
  value = getFormattedValue(selectedValue, selectedValue);
  if (currentAccessCategory && currentAccessCategory.value === '3') {
    if (props.row && props.row.categoryValueTwo && props.orgNameFromPcc
    && !props.getOrgNamesActionFired) {
      if (props.getPccListActionFired && props.value.length) {
        value = props.value;
      } else if (props.getPccListActionFired) {
        value = props.resetCategory2Val;
      }
    }
  }
  return value;
};
const getItems = (props) => {
  let items;
  if (props.row && props.row.categoryValueTwo && props.orgNameFromPcc
    && !props.getOrgNamesActionFired) {
    const name = props.orgNameFromPcc.orgName;
    items = [{ label: name, value: name }];
  } else if (props.currentCategoryValueOne.length === 0 || props.getOrgNamesActionFired) {
    items = props.orgNames;
  }
  return items;
};
const getItemListBasedOnAccessCategory = (currentAccessCategory, props) => {
  let items;
  if (currentAccessCategory && (currentAccessCategory.value === '1' || currentAccessCategory.value === '2')) {
    items = props.userNames;
  }
  if (currentAccessCategory && currentAccessCategory.value === '3') {
    items = getItems(props);
  }
  if (currentAccessCategory && currentAccessCategory.value === '5') {
    items = props.regions;
  }
  if (currentAccessCategory && currentAccessCategory.value === '6') {
    items = props.countries;
  }
  return items;
};
const getItemsForCategoryValueTwo = (props) => {
  const itemForCategoryValueTwo = !props.getPccListActionFired ? props.pccOrg : props.pcc;
  return itemForCategoryValueTwo;
};
const getValueForCategoryTwo = (props, selectedValue) => {
  const valueForCategoryTwo = props.getOrgNamesActionFired ? props.resetCategory2Val : getFormattedValue(selectedValue, selectedValue);
  return valueForCategoryTwo;
};
const getSelectOption = (selectOption) => (selectOption.label || selectOption);
const handleOnInputChange = (props, currentAccessCategory) => {
  let onInputChange;
  if (currentAccessCategory && (currentAccessCategory.value === '1' || currentAccessCategory.value === '2')) {
    onInputChange = (val) => checkTypeaheadData(val, props.getUserNamesAction, 3);
  }
  if (currentAccessCategory && currentAccessCategory.value === '3') {
    onInputChange = (val) => checkTypeaheadData(val, props.getOrganizationNamesAction, 1);
  }
  return onInputChange;
};
const EditCell = (props) => {
  const handleOnChange = selectOption => {
    if (props.column.name === 'accessCategory') {
      props.clearCategoryValues();
    } else if (props.column.name === 'categoryValueOne' && props.getOrgNamesActionFired) {
      props.clearCategoryValueTwo();
    } else if (props.column.name === 'categoryValueOne' && props.orgNameFromPcc) {
      // This is needed to stop clearing the values in pcc field after choosing the organization from pcc search
      props.clearCategoryValueOne();
    } else if (props.column.name === 'categoryValueOne') {
      props.clearCategoryValueTwo();
    } else if (props.column.name === 'categoryValueTwo' && props.getPccListActionFired) {
      props.clearCategoryValueOne();
      props.getOrgNamefromPccAction(selectOption);
    } else if (props.column.name === 'categoryValueTwo') {
      props.getOrgNamefromPccAction(selectOption);
    }
    if (selectOption) {
      return props.onValueChange(getSelectOption(selectOption));
    }
    return props.onValueChange('');
  };
  const currentAccessCategory = getCurrentAccessCategory(props);
  let items;
  let value;
  let onInputChange;
  let onFocus = () => {};
  switch (props.column.name) {
    case 'accessCategory': {
      items = getItemsForAccessCategory(props);
      const selectedText = props.value;
      const selectedOption = getSelectedOptionForAccessCategory(props, selectedText);
      const selectedValue = getSelectedValueForAccessCategory(selectedOption);
      value = getFormattedValue(selectedText, selectedValue);
      break;
    }
    case 'categoryValueOne': {
      const selectedValue = props.value;
      items = getItemListBasedOnAccessCategory(currentAccessCategory, props);
      value = getValue(selectedValue, currentAccessCategory, props);
      onInputChange = handleOnInputChange(props, currentAccessCategory);
      break;
    }
    case 'categoryValueTwo':
      if (conditionForCategoryValueTwo(currentAccessCategory)) {
        onInputChange = (val) => checkTypeaheadData(val, props.getPccListAction, 1);
        onFocus = () => checkTypeaheadData(props.currentCategoryValueOne, props.getPccOrgAction, 0);
        items = getItemsForCategoryValueTwo(props);
        const selectedValue = props.value;
        value = getValueForCategoryTwo(props, selectedValue);
        break;
      }
      return <td><span /></td>;
    default:
      return <TableEditRow.Cell style={{ width: '100%' }} {...props} />;
  }

  return (
    <td>
      <Select
        style={{ width: '100%' }}
        resetValue=""
        escapeClearsValue={false}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        value={value}
        clearable={false}
        onInputChange={onInputChange}
        onChange={handleOnChange}
        onFocus={onFocus}
        options={items}
        menuContainerStyle={{ zIndex: 5 }}
      />
    </td>
  );
};

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  currentAccessCategory: PropTypes.string,
  currentCategoryValueOne: PropTypes.string,
  value: PropTypes.string,
  accessCategoryList: PropTypes.array,
  userNames: PropTypes.array,
  orgNames: PropTypes.array,
  countries: PropTypes.array,
  accessCategoryListForProducts: PropTypes.array,
  regions: PropTypes.array,
  groups: PropTypes.array,
  pccOrg: PropTypes.array,
  getUserNamesAction: PropTypes.func,
  getOrganizationNamesAction: PropTypes.func,
  getPccOrgAction: PropTypes.func,
  onValueChange: PropTypes.func,
  clearCategoryValues: PropTypes.func,
  clearCategoryValueTwo: PropTypes.func,
  getPccListAction: PropTypes.func,
  getOrgNamefromPccAction: PropTypes.func,
  orgNameFromPcc: PropTypes.object,
  getOrgNamesActionFired: PropTypes.bool,
  getPccListActionFired: PropTypes.bool,
  resetCategory2Val: PropTypes.string,
  pcc: PropTypes.array,
  clearCategoryValueOne: PropTypes.func
};

const mapStateToProps = (state) => ({
  accessCategoryList: getAccessCategorySelector(state),
  accessCategoryListForProducts: getAccessCategorySelectorForApplication(state),
  userNames: getUserNamesSelector(state),
  orgNames: getOrgNamesSelector(state),
  pccOrg: getPccOrgNamesSelector(state),
  countries: getCountriesSelector(state),
  regions: getRegionsSelector(state),
  groups: getGroupsSelector(state),
  pcc: getPccListSelector(state),
  orgNameFromPcc: state.administration.productAccess.orgNameFromPcc,
  resetCat2: state.administration.productAccess.resetCategory2Val,
  getOrgNamesActionFired: state.administration.productAccess.getOrgNamesActionFired,
  getPccListActionFired: state.administration.productAccess.getPccListActionFired,
});

const mapDispatchToProps = dispatch => ({
  getOrganizationNamesAction: (args) => dispatch(getOrganizationNames.base(args)),
  getPccOrgAction: (args) => dispatch(getPccOrg.base(args)),
  getUserNamesAction: (args) => dispatch(getUserNames.base(args)),
  getPccListAction: (args) => dispatch(getPccList.base(args)),
  getOrgNamefromPccAction: (args) => dispatch(getOrgNameFromPcc.base(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCell);
