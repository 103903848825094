import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { VirtualTable, TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';
import dateHelper from '../../../../utils/dateHelper';

export default class EditCell extends PureComponent {
  render() {
    switch (this.props.column.name) {
      case 'version': {
        return <TableEditRow.Cell {...this.props} />;
      }
      case 'dateReleased': {
        return <td>{dateHelper.getDate(this.props.row.dateReleased)}</td>;
      }
      default:
        return <VirtualTable.Cell {...this.props} />;
    }
  }
}

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
};
