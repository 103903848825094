const authorizationRules = {};

authorizationRules['/admin/manageUserRoles'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport'
];

authorizationRules['/admin/distributeAdminAssignment'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport'
];

authorizationRules['/admin/Certifications/Pending'] = ['MarketplaceTeamAdmin', 'TPApplicationCertifier'];

authorizationRules['/admin/licenses/manageLicenses'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport'
];

authorizationRules['/admin/home/organizationDirectory'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport'
];

authorizationRules['/admin/distribution'] = ['MarketplaceTeamAdmin', 'TPPlatformProductAdmin'];

authorizationRules['/admin/ProductAccess'] = ['MarketplaceTeamAdmin', 'TPPlatformProductAdmin'];

authorizationRules['/admin/configuration'] = ['MarketplaceTeamAdmin', 'TPSupport'];

authorizationRules['/admin/managehostusers'] = ['MarketplaceTeamAdmin', 'TPSupport'];

authorizationRules['/admin/reports'] = ['MarketplaceTeamAdmin', 'TPAdmin', 'TPSupport'];

authorizationRules['/admin/reports/productReviews'] = ['MarketplaceTeamAdmin'];

authorizationRules['/admin/reports/userLoginReport'] = ['MarketplaceTeamAdmin', 'TPAdmin', 'TPSupport'];

authorizationRules['/admin/reports/userFeedbackReport'] = ['MarketplaceTeamAdmin'];

authorizationRules['/admin/auditLog'] = ['MarketplaceTeamAdmin'];

authorizationRules['/admin/banner'] = ['MarketplaceTeamAdmin'];

authorizationRules['/spcloud'] = ['MarketplaceTeamAdmin'];

authorizationRules.mysolutions = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPPlatformProductAdmin',
  'GeneralUser',
  'TPApplicationCertifier'
];

authorizationRules['/Account/MyProfile'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPPlatformProductAdmin',
  'GeneralUser',
  'TPApplicationCertifier'
];

authorizationRules.agencyDetails = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPPlatformProductAdmin',
  'GeneralUser',
  'TPApplicationCertifier'
];

authorizationRules.agencyUsers = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin'
];

authorizationRules.agencyGroups = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin'
];

authorizationRules.agencyHierarchy = [
  'AgencySuperAdmin',
  'MarketplaceTeamAdmin',
  'TPSupport',
  'TPAdmin'
];

authorizationRules.salesreport = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPPlatformProductAdmin'
];

authorizationRules['developer-profile'] = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'DeveloperUser',
  'SPCDeveloperUser'
];

authorizationRules.btnEditAgency = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPSupport',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'DeveloperUser',
  'SPCDeveloperUser'
];

authorizationRules.btnAddSolution = [
  'MarketplaceTeamAdmin',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPAdmin',
  'TPPlatformProductAdmin',
  'AgencyAdmin',
  'AgencySuperAdmin'
];

authorizationRules.btnAddInternalSolution = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPPlatformProductAdmin',
  'AgencyAdmin',
  'AgencySuperAdmin'
];

authorizationRules.addspcloudcertification = [
  'MarketplaceTeamAdmin'
];

authorizationRules.solution = [
  'MarketplaceTeamAdmin',
  'DeveloperUser',
  'SPCDeveloperUser',
  'TPAdmin',
  'TPPlatformProductAdmin',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'TPSupport'
];

authorizationRules.internalSolution = [
  'MarketplaceTeamAdmin',
  'TPAdmin',
  'TPPlatformProductAdmin',
  'AgencyAdmin',
  'AgencySuperAdmin',
  'TPSupport'
];

export default authorizationRules;
