import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import DateTimeHelper from 'utils/dateHelper';

const DataCells = (props) => {
  if (props.column.name === 'lastSuccessfulLogin' || props.column.name === 'timestamp' || props.column.name === 'createdDateTime') {
    const dateFormat = DateTimeHelper.getDateTime(props.value, 'MM-DD-YYYY hh:mm:ss A');
    return (
      <td>
        {dateFormat}
      </td>
    );
  }
  return <Table.Cell {...props} />;
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  value: PropTypes.any
};

export default DataCells;
