/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

const licenseRequiredValidation = (value) => (value ? undefined : 'Number of Licenses is required');
const licenseNumberValidation = (value) => (value && Number.isNaN(Number(value)) ? 'The field Number of Licenses: must be a number.' : undefined);
const licenseRangeValidation = (value) => (value && (value < 1 || value > 999) ? 'Number of Licenses Should not exceed 999.' : undefined);

const streetRequiredValidation = (value) => (value ? undefined : 'Billing Address Street is required');
const street1LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Address Street 1 Should not exceed 500 characters' : undefined);
const street2LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Address Street 2 Should not exceed 500 characters' : undefined);
const street3LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Address Street 3 Should not exceed 500 characters' : undefined);

const cityRequiredValidation = (value) => (value ? undefined : 'Billing Address City is required');
const cityLengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Address City Should not exceed 500 characters' : undefined);

const countryRequiredValidation = (value) => (value ? undefined : 'Billing Address Country is required');
const countryLengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Address Country Should not exceed 500 characters' : undefined);

const billingEntityNameRequiredValidation = (value) => (value ? undefined : 'Billing Entity Name is required');
const billingEntityNameLengthValidation = (value) => (value && value.length > 100 ? 'Billing Entity Name Should not exceed 100 characters' : undefined);

const billingEmailLengthValidation = (value) => (value && value.length > 100 ? 'Billing Email Address Should not exceed 100 characters' : undefined);

const vatLengthValidation = (value) => (value && value.length > 25 ? 'VAT Number Should not exceed 25 characters' : undefined);

const billingContactNameRequiredValidation = (value) => (value ? undefined : 'Billing Contact Name is required');
const billingContactNameLengthValidation = (value) => (value && value.length > 100 ? 'Billing Contact Name Should not exceed 100 characters' : undefined);

const contactStreet1LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Contact Street 1 Should not exceed 500 characters' : undefined);
const contactStreet2LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Contact Street 2 Should not exceed 500 characters' : undefined);
const contactStreet3LengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Contact Street 3 Should not exceed 500 characters' : undefined);

const contactCityLengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Contact City Should not exceed 500 characters' : undefined);

const contactCountryLengthValidation = (value) => (value && value.length > 500 ? 'Your Billing Contact Country Should not exceed 500 characters' : undefined);

const renderField = ({
  input,
  type,
  meta: { touched, error }
}) => (
  <div>
    <input className={touched && error ? 'input-validation-error text-input' : 'text-input'} {...input} type={type} />
    {touched
        && (error && <span className="field-validation-error">{error}</span>)}
  </div>
);

const renderCountryField = ({
  input,
  meta: { touched, error },
  countries
}) => (
  <div>
    <select {...input} className={touched && error ? 'input-validation-error text-input' : 'text-input'}>
      <option value=""> - Select -</option>
      {countries.map((country, index) => <option key={index.toString()} value={country.value}>{country.label}</option>)}
    </select>
    {touched
        && (error && <span className="field-validation-error">{error}</span>)}
  </div>
);

/* eslint-disable import/no-mutable-exports */
/* eslint-disable max-len */
let AdditionalCustomerInfoReduxForm = (props) => {
  const { handleSubmit, onCancel, developerName } = props;
  return (
    <>
      <h2>Additional Information</h2>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row col-sm-6">
            <div className="row large-text">
              <p>
                {developerName}
                {' '}
                would like to thank you for your interest in our product.
              </p>
              <p>
                To successfully complete your order in a timely fashion, we need some additional information from you.
              </p>
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="NumberofLicenses">Number of Licenses:</label>
              <Field name="NumberofLicenses" component={renderField} type="text" validate={[licenseRequiredValidation, licenseNumberValidation, licenseRangeValidation]} />
            </div>
            <div className="row">
              <label htmlFor="SignonPCC">Signon & PCC for each of those licenses:</label>
              <label htmlFor="SignonPCCFirstInfo">Enter your information in the following format:  Signon/PCC, Signon/PCC, Signon/PCC, etc...</label>
              <label htmlFor="SignonPCCSecondInfo">Please make sure each Signon/PCC combination is separated by a comma</label>
              <Field name="SignonPCC" className="textarea small-textarea" component="textarea" type="text" />
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillingAddress">Your Billing Address:</label>
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillingAddressStreet1">Street 1:</label>
              <Field name="BillingAddressStreet1" component={renderField} validate={[streetRequiredValidation, street1LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingAddressStreet2">Street 2:</label>
              <Field name="BillingAddressStreet2" component={renderField} validate={[street2LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingAddressStreet3">Street 3:</label>
              <Field name="BillingAddressStreet3" component={renderField} validate={[street3LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillingAddressCity">City:</label>
              <Field name="BillingAddressCity" component={renderField} validate={[cityRequiredValidation, cityLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingAddressPostalCode">Postal Code:</label>
              <Field name="BillingAddressPostalCode" component={renderField} validate={[streetRequiredValidation, street1LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillingAddressCountry">Country:</label>
              <Field
                name="BillingAddressCountry"
                component={renderCountryField}
                countries={props.countries}
                validate={[countryRequiredValidation, countryLengthValidation]}
                type="text"
              />
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillingName">Billing Entity Name:</label>
              <Field name="BillingName" component={renderField} validate={[billingEntityNameRequiredValidation, billingEntityNameLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingEmailAddress">Billing Email Address:</label>
              <Field name="BillingEmailAddress" component={renderField} validate={[billingEmailLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="VatNo">VAT Number (where applicable):</label>
              <Field name="VatNo" component={renderField} validate={[vatLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label className="required-field-star" htmlFor="BillContactName">Billing Contact Name:</label>
              <Field name="BillContactName" component={renderField} validate={[billingContactNameRequiredValidation, billingContactNameLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddress">Billing Contact Address, if different from above:</label>
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressStreet1">Street 1:</label>
              <Field name="BillingContactAddressStreet1" component={renderField} validate={[contactStreet1LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressStreet2">Street 2:</label>
              <Field name="BillingContactAddressStreet2" component={renderField} validate={[contactStreet2LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressStreet3">Street 3:</label>
              <Field name="BillingContactAddressStreet3" component={renderField} validate={[contactStreet3LengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressCity">City:</label>
              <Field name="BillingContactAddressCity" component={renderField} validate={[contactCityLengthValidation]} type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressPostalCode">Postal Code:</label>
              <Field name="BillingContactAddressPostalCode" className="text-input" component="input" type="text" />
            </div>
            <div className="row">
              <label htmlFor="BillingContactAddressCountry">Country:</label>
              <Field name="BillingContactAddressCountry" component={renderCountryField} countries={props.countries} validate={[contactCountryLengthValidation]} type="text" />
            </div>
            <div className="row text-center">
              <button type="button" onClick={onCancel} className="blue-button">CANCEL</button>
              <button className="green-button gtm-agree-button space-left-sm" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

AdditionalCustomerInfoReduxForm = reduxForm({
  // a unique name for the form
  form: 'AdditionalCustomerInfo'
})(AdditionalCustomerInfoReduxForm);

AdditionalCustomerInfoReduxForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  countries: PropTypes.array,
  developerName: PropTypes.string,
  isTTS: PropTypes.bool
};

renderField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  meta: PropTypes.object,
};

renderCountryField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  countries: PropTypes.array,
};

export default AdditionalCustomerInfoReduxForm;
