import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import {
  hideLicenseChangedModal
} from 'reducers/modules/Administration/ManageLicenses/EditLicenseStatus/editLicenseStatus';
import {
  editLicenseStatusSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';

const LicenseChangedModal = (props) => {
  if (!props.licenseChangedInfo) {
    return '';
  }
  return (
    <Modal
      id="sbModal"
      title={props.licenseChangedInfo.title}
      hidden={!props.showLicenseChangedModal}
      size="small"
      onDismiss={props.hideLicenseChangedModal}
    >
      <div className="row" id="modal-response-message">
        {props.licenseChangedInfo.message}
      </div>
      <div className="row text-center no-space">
        <button type="button" className="action-button" data-dismiss="modal" onClick={props.hideLicenseChangedModal}>Close Window</button>
      </div>
    </Modal>
  );
};

LicenseChangedModal.propTypes = {
  licenseChangedInfo: PropTypes.object,
  showLicenseChangedModal: PropTypes.bool,
  hideLicenseChangedModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  showLicenseChangedModal: editLicenseStatusSelector(state).showLicenseChangedModal,
  licenseChangedInfo: editLicenseStatusSelector(state).licenseChangedInfo
});

const mapDispatchToProps = (dispatch) => ({
  hideLicenseChangedModal: () => dispatch(hideLicenseChangedModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseChangedModal);
