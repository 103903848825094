/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  TableEditColumn,
  TableEditRow
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  EditingState
} from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as SeoHelper from 'utils/SeoHelper';
import DataCells from './TableCellComponents/DataCells';
import EditCell from './TableCellComponents/EditCell';
import CommandCell from './TableCellComponents/CommandCell';
import CommandButton from './TableCellComponents/CommandButton';

class ProductVersionTable extends PureComponent {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'version', title: 'Version Name' },
      { name: 'dateReleased', title: 'Release Date' },
      { name: 'releasedBuildVersion', title: 'Build #' },
      { name: 'platformFileUpload', title: 'Platform File Upload' }
    ];
    this.state = {
      editingProductName: false,
      newProductName: props.posDistributionViewModel.productName,
      filters: {
        productId: 0,
        searchString: '',
        startDate: null,
        endDate: null,
        excludeDemos: false,
        excludeDownloads: false,
        excludeOrders: false,
        excludeTPEmployees: true
      },
      columnWidths: [
        { columnName: 'version', width: 400 },
        { columnName: 'dateReleased', width: 200 },
        { columnName: 'releasedBuildVersion', width: 200 },
        { columnName: 'platformFileUpload', width: 200 }
      ],
      editingRowIds: [],
      addedRows: [],
    };
  }

  onProductNameChange = title => {
    this.setState({ newProductName: title });
  }

  onProductNameSaved = () => {
    this.setState({ newProductName: this.props.posDistributionViewModel.productName.trim() });
    this.setState({ editingProductName: false });
  }

  getPosVersionsSelected = (props, posVersions) => {
    const posVersionsSelected = posVersions.filter(c => props.row.version === c.version);
    return posVersionsSelected;
  }

  editProductName = event => {
    event.preventDefault();
    this.setState({ editingProductName: true });
  }

  cancelEditingProductName = event => {
    event.preventDefault();
    this.setState({ newProductName: this.props.posDistributionViewModel.productName });
    this.setState({ editingProductName: false });
  }

  saveProductName = () => {
    this.props.savePosName({ productGuid: this.props.posDistributionViewModel.productGuid, newName: this.state.newProductName.trim() }, this.onProductNameSaved);
  }

  togglePublish = event => {
    event.preventDefault();
    this.props.togglePublish({ id: this.props.posDistributionViewModel.productId, publish: !this.props.posDistributionViewModel.isPublished });
  }

  handleEditRow = (rows) => {
    const row = rows.slice(-1);
    this.setState({ editingRowIds: row, addedRows: [] });
  };

  handleNewRow = (rows) => {
    const row = rows.slice(-1);
    this.setState({ editingRowIds: [], addedRows: row });
  };

  commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      this.props.createPosVersion({ name: added[0].version, posAppId: this.props.posDistributionViewModel.productId });
    }
    if (changed) {
      const entity = { ...this.props.posDistributionViewModel.posVersions[this.state.editingRowIds], ...changed[this.state.editingRowIds] };
      this.props.updatePosVersion(entity);
    }
    if (deleted) {
      this.props.deletePosVersion(this.props.posDistributionViewModel.posVersions[deleted]);
    }
  };

  command = ({ id, onExecute }) => (
    <CommandButton
      id={id}
      onExecute={onExecute}
    />
  )

  DataCellCustom = (props) => {
    const posVersionsSelected = this.getPosVersionsSelected(props, this.props.posDistributionViewModel.posVersions);
    return (
      DataCells({
        ...props,
        posversions: posVersionsSelected,
        productname: this.props.posDistributionViewModel.productName,
      })
    );
  }

  render() {
    if (!this.props.posDistributionViewModel) {
      return '';
    }
    return (
      <div className="row sp-versions-div">
        <div className="row">

          <div className="row">
            <span className="pos-table-name-heading">
              {this.props.indexNumber + 1}
              .&nbsp;
              {!this.state.editingProductName && <span>{this.props.posDistributionViewModel.productName}</span>}
              {this.state.editingProductName
                && <input type="text" value={this.state.newProductName} onChange={(e) => { const tempProductName = e.target.value; this.onProductNameChange(tempProductName); }} />}
            </span>
            <span className="space-left-sm">
              {!this.state.editingProductName && <Link to="#" onClick={(e) => this.editProductName(e)}>Edit</Link>}
              {this.state.editingProductName
                && (
                <>
                  <button type="button" className="action-button small-button" onClick={() => this.saveProductName()}>SAVE</button>
&nbsp;
                  <Link type="button" to="#" onClick={(e) => this.cancelEditingProductName(e)} className="action-button small-button">Cancel</Link>
                </>
                )}
            </span>
          </div>

          <div className="row no-space">
            <div className="col-sm-7">
              <span>Marketplace&nbsp;Product&nbsp;Page:&nbsp;</span>
              {!this.props.posDistributionViewModel.hasPageCreated
                && (
                <Link to={`/application/basicinformation?publicId=${this.props.posDistributionViewModel.productGuid}`}>
                  <span className="small-text">Add Product Page</span>
                </Link>
                )}
              {this.props.posDistributionViewModel.hasPageCreated
                && (
                <Link to={`/application/basicinformation?publicId=${this.props.posDistributionViewModel.productGuid}`}>
                  <span className="small-text">Edit</span>
                </Link>
                )}
              <span>&nbsp;|&nbsp;</span>
              {this.props.posDistributionViewModel.hasPageCreated && !this.props.posDistributionViewModel.isProductPageComplete
                && (
                <Link to={`/Preview/${SeoHelper.SEOFriendly(this.props.posDistributionViewModel.productName)}`} target="_blank">
                  <span className="small-text">Preview Page</span>
                </Link>
                )}
              {this.props.posDistributionViewModel.hasPageCreated && this.props.posDistributionViewModel.isProductPageComplete
                && (
                <Link to="#" onClick={(e) => this.togglePublish(e)}>
                  <span className="small-text">{this.props.posDistributionViewModel.isPublished ? 'Unpublish' : 'Publish'}</span>
                </Link>
                )}
              {this.props.posDistributionViewModel.hasPageCreated && <span className="small-text">&nbsp;|&nbsp;</span>}
              <Link to={`/admin/ProductAccess?id=${this.props.posDistributionViewModel.productGuid}`}>
                <span className="small-text">Access</span>
              </Link>
              {this.props.posDistributionViewModel.hasPageCreated && <span className="small-text">&nbsp;|&nbsp;</span>}
              {this.props.isUserSuperAdmin
              && (
              <Link to={`/admin/auditLog?id=${this.props.posDistributionViewModel.productId}&category=product`}>
                <span className="small-text">Audit Logs</span>
              </Link>
              )}
            </div>
          </div>

          <div className="row space-top">
            <div className="travelport-table-sticky tablesorter tablesorter-travelport tablesorter-odd-even">
              <Grid columns={this.columnDefinitions} rows={this.props.posDistributionViewModel.posVersions}>
                <EditingState
                  editingRowIds={this.state.editingRowIds}
                  onEditingRowIdsChange={this.handleEditRow}
                  addedRows={this.state.addedRows}
                  onAddedRowsChange={this.handleNewRow}
                  onCommitChanges={this.commitChanges}
                />
                <VirtualTable height={200} cellComponent={this.DataCellCustom} />
                <TableHeaderRow showSortingControls={false} />
                <TableEditColumn
                  showAddCommand
                  showDeleteCommand
                  commandComponent={this.command}
                  cellComponent={CommandCell}
                  width={200}
                />
                <TableEditRow
                  cellComponent={EditCell}
                />
              </Grid>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

ProductVersionTable.propTypes = {
  posDistributionViewModel: PropTypes.object,
  indexNumber: PropTypes.number,
  createPosVersion: PropTypes.func,
  updatePosVersion: PropTypes.func,
  deletePosVersion: PropTypes.func,
  savePosName: PropTypes.func,
  togglePublish: PropTypes.func,
  isUserSuperAdmin: PropTypes.bool
};

export default ProductVersionTable;
