import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';

const DataCells = (props) => {
  if (props.column.name === 'assignedPlatformProducts') {
    return (
      <td>
        {props.row.assignedPlatformProducts && props.row.assignedPlatformProducts.length > 0
          ? props.row.assignedPlatformProducts.map(r => r.text).join(', ') : 'None'}
      </td>
    );
  }
  return <Table.Cell {...props} />;
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object
};

export default DataCells;
