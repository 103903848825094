import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResultNotFound extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <>
        <div className="col-xs-12 large-text standard-padding">
          <h3>Sorry, no results were found.</h3>

          <p>Search suggestions:</p>
          <ul>
            <li>Search by product name or developer on the search box</li>
            <li>Use a less specific search by updating the selection on left-hand side menu</li>
            <li>
              Please raise a ticket via
              <a href="https://newmy.travelport.com/">MyTravelport</a>
              {' '}
              site and get help
            </li>
          </ul>
        </div>
      </>
    );
  }
}

ResultNotFound.propTypes = {
  applicationName: PropTypes.string,
};

export default ResultNotFound;
