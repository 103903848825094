import { createSelector } from 'reselect';
import * as Constants from 'constants/PPCP';
import querystring from 'querystring';

const stateApplication = state => state.application;
const formsSelector = state => state.application.applicationLayout.forms;
export const applicationSelector = state => state.application.applicationLayout;

export const getCompletionSelector = state => state.application.applicationLayout.completionPoints;
export const getSubmittedSelector = state => state.application.applicationLayout.submitted;
export const publicIdSelector = state => state.application.applicationLayout.publicId;
export const organizationIdSelector = state => state.application.applicationLayout.organizationId;
export const priceIdSelector = state => state.application.distribution.price && state.application.distribution.price.id;
export const supportDocument = state => state.application.support.supportData;
export const isInternalPPCP = state => state.application.applicationLayout.isInternal;
export const reviewSelector = state => state.application.review.data;
export const uploadCertificateSelector = state => state.application.review.certresponse;
export const isTravelportPOSApplicationPPCP = state => state.application.applicationLayout.isTravelportPOSApplication;
export const getProductPageCompletionSelector = state => state.application.applicationLayout.isProductPageComplete;
export const percentageCompletionSelector = state => state.application.applicationLayout.percentageCompletion;
export const isReviewCompleted = state => state.application.applicationLayout.isReviewCompleted;
export const activatedSelector = state => state.application.applicationLayout.isActivated;
export const publishedSelector = state => state.application.applicationLayout.isPublished;
export const getSolnGuideUrlSelector = state => state.application.applicationLayout.solnGuideUrl;
export const getUserProfileSelector = state => state.profile.userProfile;

const getCompletedInfo = (currentpage, application) => {
  switch (currentpage) {
    case Constants.BASIC_INFORMATION_ID:
      return application.basicInformation && application.basicInformation.data;
    case Constants.CLASSIFICATION_ID:
      return application.classification && application.classification.platormAndHost && application.classification.platormAndHost.model;
    case Constants.DISTRIBUTION_ID:
      return application.distribution && application.distribution.data;
    case Constants.COMMUNICATION_ID:
      return application.communication && application.communication.data;
    case Constants.AUDIENCE_ID:
      return application.audience && application.audience.data;
    case Constants.MEDIA_ID:
      return application.media && application.media.data;
    case Constants.SUPPORT_ID:
      return application.support && application.support.data;
    case Constants.REVIEW_ID:
      return null;
    default:
      return null;
  }
};

const getPageWiseLoader = (currentpage, application) => {
  switch (currentpage) {
    case Constants.BASIC_INFORMATION_ID:
      return application.basicInformation && application.basicInformation.loading;
    case Constants.CLASSIFICATION_ID:
      return application.classification && application.classification.loading;
    case Constants.DISTRIBUTION_ID:
      return application.distribution && application.distribution.loading;
    case Constants.COMMUNICATION_ID:
      return application.communication && application.communication.loading;
    case Constants.AUDIENCE_ID:
      return application.audience && application.audience.loading;
    case Constants.MEDIA_ID:
      return application.media && application.media.loading;
    case Constants.SUPPORT_ID:
      return application.support && application.support.loading;
    case Constants.REVIEW_ID:
      return null;
    default:
      return null;
  }
};

export const isFirstPage = createSelector(
  [applicationSelector],
  ({
    forms, currentForm, isInternal, isTravelportPOSApplication
  }) => {
    if (isTravelportPOSApplication) {
      const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.TRAVELPORT_POS_APPLICATION));
      return posMenus[0] === currentForm.name;
    } if (isInternal) {
      const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.INTERNAL));
      return posMenus[0] === currentForm.name;
    }
    const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.GENERAL));
    return posMenus[0] === currentForm.name;
  }
);

export const isLastPage = createSelector(
  [applicationSelector],
  ({
    forms, currentForm, isInternal, isTravelportPOSApplication
  }) => {
    if (isTravelportPOSApplication) {
      const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.TRAVELPORT_POS_APPLICATION));
      return posMenus[posMenus.length - 1].toString() === currentForm.name.toString();
    } if (isInternal) {
      const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.INTERNAL));
      return posMenus[posMenus.length - 1].toString() === currentForm.name.toString();
    }
    const posMenus = Object.keys(forms).filter(key => forms[key].pageType.includes(Constants.GENERAL));
    return posMenus[posMenus.length - 1].toString() === currentForm.name.toString();
  }
);

export const pagesCompleteSelector = createSelector(
  [formsSelector],
  (forms) => !(forms && Object.keys(forms).find(key => !forms[key].completed))
);

export const getApplicationQueryString = createSelector(
  [applicationSelector],
  ({ publicId, organizationId }) => {
    if (publicId || organizationId) {
      const queryObject = {};
      if (publicId) queryObject.publicId = publicId;
      if (organizationId) queryObject.organizationId = organizationId;
      return `?${querystring.stringify(queryObject)}`;
    }
    return '';
  }
);

export const getApplicationForms = createSelector(
  [formsSelector],
  (forms) => forms && Object.keys(forms).map(key => ({
    name: forms[key].name,
    route: forms[key].route,
    saved: forms[key].saved,
    completed: forms[key].completed,
  }))
);

export const getApplicationCurrentForm = createSelector(
  [applicationSelector],
  (application) => application && application.currentForm && application.currentForm.name
);

export const percentageCompleted = createSelector(
  [stateApplication],
  (application) => getCompletedInfo(application.applicationLayout.currentForm.name, application)
);

export const ppcpPageLoading = createSelector(
  [stateApplication],
  (application) => getPageWiseLoader(application.applicationLayout.currentForm.name, application)
);
