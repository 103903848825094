export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export default (base) => {
  const types = { BASE: base };

  [REQUEST, SUCCESS, FAILURE].forEach(type => {
    types[type] = `${base}_${type}`;
  });

  return types;
};
