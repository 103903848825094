/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  TableHeaderRow,
  Table,
  TableEditColumn,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  SortingState,
  EditingState,
  PagingState,
  CustomPaging
} from '@devexpress/dx-react-grid';
import DateTimeHelper from 'utils/dateHelper';
import {
  fetchProductReviews,
  deleteProductReviews,
} from 'reducers/modules/Administration/Reports';
import {
  productReviewsSelector,
  productReviewTotalPages
} from 'reducers/modules/Administration/Reports/ProductReviews/selectors';

import MpDatePicker from 'components/common/FormInputs/Datepicker/MpDatePicker';
import DataCells from './TableCellComponents/DataCells';
import { exportToExcelFunc } from '../../../../reducers/modules/Administration/Reports/ExportToExcel/ExportToExcel';

class ProductReviews extends PureComponent {
  constructor(props) {
    super(props);
    const utcDate = new Date(moment.utc().format());
    this.state = {
      startDate: moment(utcDate).subtract(2, 'years'),
      endDate: utcDate,
      sortAsc: false,
      sortOnColumn: '',
      sorting: {
        columnName: '',
        direction: 'asc'
      },
      paging: {
        currentPage: 0,
        pageSize: 20
      }
    };

    this.columnDefinitions = [
      { name: 'productName', title: 'PRODUCT NAME' },
      { name: 'reviewerUserName', title: 'REVIEWER' },
      { name: 'reviewTitle', title: 'REVIEW TITLE' },
      { name: 'productReview', title: 'PRODUCT REVIEW' },
      { name: 'rating', title: 'RATINGS' },
      { name: 'dateTime', title: 'DATE' }
    ];
  }

  componentDidMount() {
    document.title = 'Product Review';
    this.getFilteredProductReviews();
  }

  getFilteredProductReviews = e => {
    if (e) {
      e.preventDefault();
    }
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentPage: this.state.paging.currentPage
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.fetchProductReviewsAction(params);
  };

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  };

  getSortDirection = () => this.state.sorting.direction === 'asc';

  sortHandler = newSorting => this.setState(
    { sorting: { ...newSorting[0] } },
    this.getFilteredProductReviews
  );

  pageHandler = currentPage => this.setState(
    { paging: { ...this.state.paging, currentPage } },
    this.getFilteredProductReviews
  );

  exportToExcel = () => {
    const params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortOnColumn: this.getSortColumn() || 'DateTime',
      sortAsc: this.getSortColumn() ? this.getSortDirection() : false
    };

    if (this.state.sorting.columnName !== '') {
      params.sortOnColumn = this.state.sorting.columnName;
    }

    this.props.productReviewDownloadExcel(params);
  };

  commitChanges = ({ deleted }) => {
    this.props.deleteProductReviewsAction({ reviewId: this.props.rows[deleted[0]].id }, this.deleteSuccessHandler);
  }

  deleteSuccessHandler = () => {
    this.getFilteredProductReviews();
  }

  datehandler = (e, field) => {
    this.setState({
      [field]: DateTimeHelper.getDate(e)
    });
  };

  render() {
    return (
      <>
        <h2>Product Reviews</h2>
        <div className="container">
          <div className="no-margin">
            <div className="row">
              <Link to="/admin/reports">&lt; Back to Reports</Link>
            </div>
            <div className="row no-space">
              <h4>FILTER REPORT</h4>
            </div>
            <div id="filter-report-generator" className="row">
              <div className="col-sm-5">
                <div className="col-sm-5 col-sm-offset-1">
                  <span
                    id="datepicker-start-date"
                    className="input-append date"
                    data-date="12/1/2013"
                    data-date-format="mm/dd/yyyy"
                  >
                    <label htmlFor="startDate">From:</label>
                    <span
                      className="add-on"
                      style={{ display: 'inline-block' }}
                    >
                      <MpDatePicker
                        value={this.state.startDate}
                        onChange={e => this.datehandler(e, 'startDate')}
                        isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                      />
                    </span>
                  </span>
                </div>
                <div className="col-sm-5 col-sm-offset-1">
                  <span
                    id="datepicker-end-date"
                    className="input-append date"
                    data-date="12/31/2013"
                    data-date-format="mm/dd/yyyy"
                  >
                    <label htmlFor="endDate">To:</label>
                    <span
                      className="add-on"
                      style={{ display: 'inline-block' }}
                    >
                      <MpDatePicker
                        value={this.state.endDate}
                        onChange={e => this.datehandler(e, 'endDate')}
                        isValidDate={current => DateTimeHelper.disableFuturePastDt(current)}
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-sm-1 col-sm-offset-1 pad-left">
                <Link
                  to="#"
                  className="action-button"
                  onClick={this.getFilteredProductReviews}
                >
                  Search
                </Link>
              </div>
            </div>
            <div className="row text-right ajax-dependent-div">
              <Link
                to="#"
                id="downloadExcel"
                className="text-link-with-icon-left"
                onClick={this.exportToExcel}
              >
                <span className="download-icon" />
                Download in Microsoft Excel
              </Link>
            </div>
            <div id="table-div" className="row">
              <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
                <Grid columns={this.columnDefinitions} rows={this.props.rows}>
                  <EditingState onCommitChanges={this.commitChanges} />
                  <PagingState
                    currentPage={this.state.paging.currentPage}
                    pageSize={this.state.paging.pageSize}
                    onCurrentPageChange={this.pageHandler}
                  />
                  <CustomPaging
                    totalCount={
                      this.props.totalPages * this.state.paging.pageSize
                    }
                  />
                  <SortingState
                    sorting={this.sorting}
                    onSortingChange={this.sortHandler}
                    columnExtensions={this.sortingStateColumnExtensions}
                  />
                  <Table cellComponent={DataCells} />
                  <TableHeaderRow showSortingControls />
                  <TableEditColumn showDeleteCommand />
                  <PagingPanel pageSizes={[]} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProductReviews.propTypes = {
  fetchProductReviewsAction: PropTypes.func,
  productReviewDownloadExcel: PropTypes.func,
  deleteProductReviewsAction: PropTypes.func,
  rows: PropTypes.array,
  totalPages: PropTypes.number
};

const mapStateToProps = state => ({
  rows: productReviewsSelector(state),
  totalPages: productReviewTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  fetchProductReviewsAction: args => dispatch(fetchProductReviews.base(args)),
  productReviewDownloadExcel: args => dispatch(exportToExcelFunc.productReviewReport(args)),
  deleteProductReviewsAction: (reviewId, handler) => dispatch(deleteProductReviews.base(reviewId, handler)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductReviews);
