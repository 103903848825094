import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateId,
  clearValues,
  updateCurrentForm
} from 'reducers/modules/Application';
import {
  getSubmittedSelector,
  isInternalPPCP,
  isReviewCompleted
} from 'reducers/modules/Application/selectors';
import * as Constants from 'constants/PPCP';
import { setView } from 'reducers/modules/Auth/Authorization/authorizationReducer';
import ApplicationRoutes from '../ApplicationRoutes';
import * as utils from '../../../utils/Common.Utils';

class ApplicationLayout extends PureComponent {
  componentDidMount() {
    const {
      updateIdAction,
      location
    } = this.props;
    const publicId = utils.getValueFromQueryString(location, 'publicid');
    const organizationId = utils.getValueFromQueryString(location, 'organizationId');
    this.isInternal = utils.getValueFromQueryString(location, 'isInternal');
    updateIdAction('isInternal', this.isInternal === 'true');
    updateIdAction('publicId', publicId);
    updateIdAction('organizationId', organizationId);
    if (this.isInternal || this.props.isInternal) {
      updateIdAction('pageType', Constants.INTERNAL);
      return this.props.setView('internalSolution');
    }

    return this.props.setView('solution');
  }

  componentDidUpdate() {
    if (utils.getValueFromQueryString(this.props.location, 'isInternal') === 'true' && !this.props.isInternal) {
      this.props.updateIdAction('isInternal', true);
    }
  }

  componentWillUnmount() {
    const { clearValuesAction, updateCurrentFormAction, currentForm } = this.props;
    clearValuesAction();
    updateCurrentFormAction(currentForm, false);
  }

  render() {
    return (
      <ApplicationRoutes />
    );
  }
}

ApplicationLayout.propTypes = {
  updateCurrentFormAction: PropTypes.func,
  getFormAction: PropTypes.func,
  updateIdAction: PropTypes.func,
  clearValuesAction: PropTypes.func,
  location: PropTypes.object,
  submitted: PropTypes.bool,
  currentForm: PropTypes.string,
  updateCurrentForm: PropTypes.func,
  isInternal: PropTypes.bool,
  isReviewCompleted: PropTypes.bool,
  setView: PropTypes.func
};

const mapStateToProps = state => ({
  currentPage: state.application,
  submitted: getSubmittedSelector(state),
  currentForm: state.application.applicationLayout.currentForm.name,
  isInternal: isInternalPPCP(state),
  isReviewCompleted: isReviewCompleted(state)
});

const mapDispatchToProps = dispatch => ({
  updateIdAction: (key, value) => dispatch(updateId(key, value)),
  clearValuesAction: () => dispatch(clearValues()),
  updateCurrentFormAction: (name, dirty) => dispatch(updateCurrentForm(name, dirty)),
  setView: (name) => dispatch(setView(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLayout);
