import { takeLatest, call, put } from 'redux-saga/effects';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { createSelector } from 'reselect';

const GET_COUNTRIES = createFetchTypes('Marketplace/GET_COUNTRIES');

function countries(state = [], action = {}) {
  switch (action.type) {
    case GET_COUNTRIES.SUCCESS:
      return action.payload;
    case GET_COUNTRIES.FAILURE:
      return state;
    default:
      return state;
  }
}

// ********************************************** SELECTORS **********************************************/
// getCountriesSelector -> Formatted for the react-select typeahead control
const countriesSelector = state => state.countries;
export const getCountriesSelector = createSelector(
  [countriesSelector],
  (items) => items && items.map(item => ({
    label: item,
    value: item
  }))
);

export const getAllCountries = {
  base: (data) => ({
    type: GET_COUNTRIES.BASE,
    url: 'profile/getallcountries',
    data: { data }
  }),
  request: () => ({ type: GET_COUNTRIES.REQUEST }),
  success: payload => ({ type: GET_COUNTRIES.SUCCESS, payload }),
  failure: error => ({ type: GET_COUNTRIES.FAILURE, error }),
};

function* getAllCountriesSaga({ url, data }) {
  try {
    yield call(() => fetchEntity(getAllCountries, ApiClient.httpClient.get, url, { data }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
}

export function* watchgetAllCountriesSaga() {
  yield takeLatest(GET_COUNTRIES.BASE, getAllCountriesSaga);
}

export default countries;
