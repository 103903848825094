/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  saveSupportDocumentation,
  fetchSupportDetails,
  updateCurrentForm,
  addSupportDocument,
  deleteSupportDocument,
  updateSupportDocuments,
  updateSupportContactFields
} from 'reducers/modules/Application/index';
import '../../../Styles/datepicker.css';
import * as utils from '../../../utils/Common.Utils';
import { SUPPORT_ID } from '../../../constants/PPCP';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import TextArea from '../../common/TextArea';
import ProfileImagePopup from '../../common/ProfileImagePopUp';
import ConfDeleteModal from '../../common/MPModal/ConfDeleteModal';
import { isInternalPPCP, isTravelportPOSApplicationPPCP } from '../../../reducers/modules/Application/selectors';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import ToolTip from '../../common/ToolTip';
import FormHeader from '../FormHeader/FormHeader';

const emailLengthValidation = (value) => (value && value.length > 255 ? 'Email\'s length Should not exceed 255 characters' : undefined);
const newLocal = 'Please enter a valid email id';
const validEmailValidation = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? newLocal : undefined);

const renderField = ({
  input,
  type,
  textValue,
  placeholder,
  meta: { touched, error }
}) => (
  <>
    <input
      className={touched && error ? 'input-validation-error text-input' : 'text-input'}
      value={textValue}
      {...textValue || { ...input }}
      type={type}
      placeholder={placeholder}
    />
    {touched
      && (error && <span className="field-validation-error">{error}</span>)}
  </>
);

const renderTextArea = ({
  input,
  className,
  cols,
  meta: { touched, error }
}) => (
  <>
    <textarea {...input} onChange={input.onChange} className={className} cols={cols} />
    {touched
      && (error && <span className="field-validation-error">{error}</span>)}
  </>
);

const renderCheckbox = ({
  input,
  displayText,
  tooltip
}) => (
  <SimplePrettyCheckBox
    {...input}
    displayText={displayText}
    onChange={input.onChange}
    tooltip={tooltip}
  />
);

class Support extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showUploadPopUp: false,
      showConfDeleteModal: false,
      currentDocumentIndex: 0,
    };
  }

  componentDidMount() {
    const publicId = utils.getValueFromQueryString(this.props.location, 'publicid');
    const organizationId = utils.getValueFromQueryString(this.props.location, 'organizationId');

    this.props.fetchSupportAction({ publicId, organizationId });

    this.props.updateCurrentFormAction(SUPPORT_ID, false);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.dirty) !== JSON.stringify(this.props.dirty)) {
      this.props.updateCurrentFormAction(SUPPORT_ID, this.props.dirty);
    }
  }

  addSupportList = () => {
    const list = (this.props.supportData.supportDocuments.length === 0) ? [{}] : this.props.supportData.supportDocuments;
    if (list.length >= 3) {
      return;
    }
    list.push({

    });
    this.props.addSupportDocument(list);
  }

  addScreenShot = (id) => (
    this.setState({
      showUploadPopUp: true,
      currentDocumentIndex: id
    })
  );

  deleteSupportFile = (id) => (
    this.setState({
      showConfDeleteModal: true,
      currentDocumentIndex: id
    })
  );

  hideUploadPopUp() {
    this.setState({ showUploadPopUp: false });
  }

  savedFileDescription(file) {
    let fileName = '';
    let fileDescription = '';

    switch (this.state.currentDocumentIndex) {
      case 1:
        fileName = this.props.documentationNames.documentationName1;
        fileDescription = this.props.fileDescriptions.fileDescription1;
        break;
      case 2:
        fileName = this.props.documentationNames.documentationName2;
        fileDescription = this.props.fileDescriptions.fileDescription2;
        break;
      case 3:
        fileName = this.props.documentationNames.documentationName3;
        fileDescription = this.props.fileDescriptions.fileDescription3;
        break;
      default:
        break;
    }
    const publicId = this.props.initialValues.publicGuid;
    this.props.saveSupportFiles({
      id: publicId,
      file,
      fileName,
      fileDescription,
      errorHandler: (error) => {
        if (!error) {
          this.hideUploadPopUp();
        } else {
          this.props.showAlertAction(error);
        }
      }
    });
  }

  supportDocumentation() {
    const supportList = (this.props.supportData.supportDocuments.length === 0)
      ? [{ fileName: '', fileDescription: '', documentationName: '' }]
      : this.props.supportData.supportDocuments;
    return (
      supportList.map((item, index) => (
        <div className="pad-top" key={index.toString()}>
          <div className="row no-space-bottom">
            <div className="col-sm-4">
              <span className="col-sm-1 space-top-sm ng-binding">
                {index + 1}
                :&nbsp;
              </span>
              <span className="col-sm-11">
                <Field
                  name={`documentationName${index + 1}`}
                  onChange={(e) => this.props.updateSupportDocuments({
                    id: item.id, index, property: 'documentName', propertyValue: e.target.value
                  })}
                  component={renderField}
                  type="text"
                  maxLength={200}
                  placeholder="Document Name"
                  className="text-input"
                />
              </span>
            </div>
            <div className="col-sm-2 text-right">
              {(item.fileName) ? (
                <Link
                  to="#"
                  className="action-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteSupportFile(index + 1);
                  }}
                >
                  Delete
                </Link>
              )
                : (
                  <Link
                    to="#"
                    className="action-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.addScreenShot(index + 1);
                    }}
                  >
                    Upload
                  </Link>
                )}

            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 pad-left-double">
              <em className="ng-binding">
                Current file:
                {item.fileName}
              </em>
            </div>
          </div>
          <div className="row pad-left-double">
            <Field
              component={TextArea}
              onChange={(e) => this.props.updateSupportDocuments({
                id: item.id, index, property: 'fileDescription', propertyValue: e.target.value
              })}
              showTitleCount={false}
              textAreaClass="textarea small-textarea"
              name={`fileDescription${index + 1}`}
              textAreaClassName="text-input"
              placeholder="File description/explanation"
            />
          </div>
        </div>
      ))
    );
  }

  deleteSupportDocument() {
    const { supportData } = this.props;
    const { currentDocumentIndex } = this.state;

    this.props.deleteSupportFile({ appId: supportData.publicGuid, documentId: supportData.supportDocuments[currentDocumentIndex - 1].id });
    this.setState({ showConfDeleteModal: false });
  }

  render() {
    if (this.props.supportData === undefined || this.props.supportData.supportDocuments === undefined) {
      return <LoaderComponent show />;
    }

    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormHeader
          header="Support"
          infoText="Tell your customers how they get support if they run into a problem when using your solution."
        />
        <div className="row">
          <div className="col-sm-12">
            <label htmlFor="supportPage">
              <h4 className="required-field-star">
                Product Support/Contact Page
                {' '}
                <ToolTip
                  labelName="Product Support Page Url"
                />
              </h4>
            </label>
          </div>

          <div className="col-sm-5">
            <Field name="supportPage" component={renderField} type="text" placeholder="http://www" onChange={(e) => this.props.updateSupportContactFields({ property: 'supportPage', propertyValue: e.target.value })} />
          </div>

        </div>

        <div className="row">
          <div className="col-sm-12">
            <label htmlFor="supportContactPerson">Support Contact Person</label>
          </div>
          <div className="col-sm-5">
            <Field name="supportContactPerson" component={renderField} type="text" onChange={(e) => this.props.updateSupportContactFields({ property: 'supportContactPerson', propertyValue: e.target.value })} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <label htmlFor="supportContactEmail">Support Contact Email</label>
          </div>
          <div className="col-sm-5">
            <Field name="supportContactEmail" component={renderField} type="text" validate={[emailLengthValidation, validEmailValidation]} onChange={(e) => this.props.updateSupportContactFields({ property: 'supportContactEmail', propertyValue: e.target.value })} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-9">
            <h4 className={this.props.isInternal && this.props.supportData.isDistributedElsewhere ? 'required-field-star' : ''}>Support Documentation</h4>
          </div>
          <div className="col-sm-3 text-right">
            <Link
              to="#"
              id="addSupportFile"
              onClick={(e) => {
                e.preventDefault();
                this.addSupportList();
              }}
            >
              {'>'}
              Add Additional Support File
              {' '}
              <ToolTip labelName="At most 3 support files can be added" />
            </Link>
          </div>
        </div>
        <div className="row">
          Note: All support documents will be available to any users that are logged into the system
        </div>

        {this.supportDocumentation()}

        {this.state.showUploadPopUp ? (
          <ProfileImagePopup
            showModal
            modalTitle="Document Upload"
            titleRequired
            acceptedFormat=".pdf .doc .docx formats accepted"
            acceptedImageSize="< 10MB"
            validationTypeUploadPopup="false"
            validationDocFile="true"
            onHide={() => this.hideUploadPopUp()}
            submitImageUpload={(file) => this.savedFileDescription(file)}
          />
        ) : null}

        {this.state.showConfDeleteModal ? (
          <ConfDeleteModal
            text="Are you sure you want to delete this support document?"
            showModal
            onHideModal={() => this.setState({ showConfDeleteModal: false })}
            onDelete={() => this.deleteSupportDocument()}
          />
        ) : null}

        {this.props.supportData.isIncrementalDifferenceOptionVisible
          ? (
            <div className="row">
              <Field
                name="displayIncrementalDifferenceFile"
                displayText='Allow the New Version "Diff" (incremental update) to be added to the support area for download.'
                type="checkbox"
                component={renderCheckbox}
              />

            </div>
          )
          : null}

        {this.props.isTravelportPOSApplication
          ? (
            <>
              <div className="col-sm-9">
                <h4>Previous Version</h4>
              </div>
              <div className="row">
                <div className="pull-left pad-right-sm">
                  <Field
                    name="displayPreviousVersion"
                    displayText="Allow users to download the previous version of this product."
                    type="checkbox"
                    component={renderCheckbox}
                  />
                </div>
                <div className="pad-top-sm">
                  <ToolTip labelName={`Selecting this option will display a Previous Version tab on the product page.  This will allow Travelport Marketplace users to
see and download the previous version of this product (according to the Product Version Control page).`}
                  />
                </div>
              </div>
            </>
          ) : null}
      </form>
    );
  }
}

const selector = formValueSelector(SUPPORT_ID);

const mapStateToProps = (state) => ({
  initialValues: state.application.support.supportData,
  loading: state.application.support.loading || state.application.applicationLayout.loading,
  supportPageUrl: selector(state, 'supportPage'),
  contactPerson: selector(state, 'supportContactPerson'),
  contactEmail: selector(state, 'supportContactEmail'),
  documentationNames: selector(state, 'documentationName1', 'documentationName2', 'documentationName3'),
  fileDescriptions: selector(state, 'fileDescription1', 'fileDescription2', 'fileDescription3'),
  supportData: state.application.support.supportData,
  isInternal: isInternalPPCP(state),
  isTravelportPOSApplication: isTravelportPOSApplicationPPCP(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchSupportAction: (args) => dispatch(fetchSupportDetails.base(args)),
  saveSupportFiles: (args) => dispatch(saveSupportDocumentation.base(args)),
  updateCurrentFormAction: (idForm, dirty) => dispatch(updateCurrentForm(idForm, dirty)),
  addSupportDocument: (args) => dispatch(addSupportDocument.base(args)),
  deleteSupportFile: (args) => dispatch(deleteSupportDocument.base(args)),
  showAlertAction: (msg) => dispatch(showAlertAction(msg)),
  updateSupportDocuments: (args) => dispatch(updateSupportDocuments.success(args)),
  updateSupportContactFields: (args) => dispatch(updateSupportContactFields.success(args))

});

Support.propTypes = {
  handleSubmit: PropTypes.func,
  location: PropTypes.object,
  loading: PropTypes.bool,
  updateCurrentFormAction: PropTypes.func,
  fetchSupportAction: PropTypes.func,
  saveSupportFiles: PropTypes.func,
  initialValues: PropTypes.object,
  documentationNames: PropTypes.object,
  fileDescriptions: PropTypes.object,
  addSupportDocument: PropTypes.func,
  supportData: PropTypes.object,
  dirty: PropTypes.bool,
  showAlertAction: PropTypes.func,
  isInternal: PropTypes.bool,
  isTravelportPOSApplication: PropTypes.bool,
  deleteSupportFile: PropTypes.func,
  updateSupportDocuments: PropTypes.func,
  updateSupportContactFields: PropTypes.func
};

renderField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  textValue: PropTypes.string,
};

renderTextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  cols: PropTypes.string
};

renderCheckbox.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: SUPPORT_ID,
    enableReinitialize: true
  })(Support)
);
