import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';

export const GET_REVIEWSUBMIT = createFetchTypes('Marketplace/Application/GET_REVIEWSUBMIT');
export const UPLOAD_CERTIFICATE = createFetchTypes('Marketplace/Application/UPLOAD_CERTIFICATE');
export const DELETE_CERTIFICATE = createFetchTypes('Marketplace/Application/DELETE_CERTIFICATE');
export const DOWNLOAD_CERTIFICATE = 'Marketplace/Application/DOWNLOAD_CERTIFICATE';
export const CLEAR = 'Marketplace/Application/CLEAR';

export function reviewReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_REVIEWSUBMIT.REQUEST:
      return state;
    case GET_REVIEWSUBMIT.SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
        }
      };
    case GET_REVIEWSUBMIT.FAILURE:
      return state;
    case UPLOAD_CERTIFICATE.REQUEST:
    case DELETE_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPLOAD_CERTIFICATE.SUCCESS: {
      return {
        ...state,
        loading: false,
        certresponse: action.payload,
      };
    }
    case UPLOAD_CERTIFICATE.FAILURE:
    case DELETE_CERTIFICATE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.response.data
      };
    case DELETE_CERTIFICATE.SUCCESS: {
      const certificationDocuments = state.data.certificationDocuments.map((data) => {
        if (data.id === action.payload.data.documentId) {
          return null;
        }
        return data;
      }).filter(x => !!x);

      return {
        ...state,
        loading: false,
        certresponse: null,
        deletedDocId: action.payload.id,
        data: {
          ...state.data,
          certificationDocuments
        }
      };
    }
    case CLEAR:
      return {};
    default:
      return state;
  }
}

export const getReviewSubmit = {
  base: (data) => ({
    type: GET_REVIEWSUBMIT.BASE,
    url: 'application/getreviewandsubmit',
    data
  }),
  request: () => ({ type: GET_REVIEWSUBMIT.REQUEST }),
  success: payload => ({ type: GET_REVIEWSUBMIT.SUCCESS, payload }),
  failure: error => ({ type: GET_REVIEWSUBMIT.FAILURE, error }),
};
export const uploadCertificate = {
  base: (data) => ({
    type: UPLOAD_CERTIFICATE.BASE,
    url: '/application/uploadcertificate',
    data
  }),
  request: () => ({ type: UPLOAD_CERTIFICATE.REQUEST }),
  success: payload => ({ type: UPLOAD_CERTIFICATE.SUCCESS, payload }),
  failure: error => ({ type: UPLOAD_CERTIFICATE.FAILURE, error })
};

export const downloadCertificate = (data) => ({
  type: DOWNLOAD_CERTIFICATE,
  data
});

export const clear = () => ({ type: CLEAR });
export const deleteCertificate = {
  base: (data) => ({
    type: DELETE_CERTIFICATE.BASE,
    url: '/application/deletecertificate',
    data
  }),
  request: () => ({ type: DELETE_CERTIFICATE.REQUEST }),
  success: payload => ({ type: DELETE_CERTIFICATE.SUCCESS, payload }),
  failure: error => ({ type: DELETE_CERTIFICATE.FAILURE, error })
};

function* getReviewSaga({ url, data }) {
  yield put(showLoader());
  try {
    const { publicId } = data;
    yield call(() => fetchEntityPPCP(getReviewSubmit, ApiClient.httpClient.get, url, { params: { publicId } }));
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data.responseMessage));
  }
  yield put(hideLoader());
}

function* uploadCertificateSaga({ url, data }) {
  try {
    yield put(showLoader());
    const formData = new FormData();
    formData.append('appId', data.publicId);
    formData.append('file', data.file);
    formData.append('version', data.version);
    formData.append('isSpPlugin', data.isSpPlugin);
    formData.append('appVersType', data.appVersType);
    formData.append('documentName', data.docName);
    formData.append('filename', data.file.name);
    yield call(() => fetchEntity(uploadCertificate, ApiClient.httpClient.post, url, formData, { params: { ...formData } }));
    yield put(hideLoader());
    data.onSuccess();
  } catch (error) {
    yield put(showAlertAction(error && error.response && error.response.data && error.response.data.errorMsg));
    yield put(hideLoader());
    if (error.response.data && error.response.data.existingDocList) {
      data.onSuccess();
    }
  }
}
function* deleteCertificateSaga({ data }) {
  try {
    yield put(showLoader());
    yield put(deleteCertificate.request());
    const response = yield call(() => ApiClient.httpClient.delete(deleteCertificate.base().url, { params: { docId: data.docId, appId: data.appId, version: data.version } }));
    yield put(deleteCertificate.success({
      data: response.data
    }));
    if (response.data) {
      data.callbackFn(data.appId);
    }
    yield put(hideLoader());
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    deleteCertificate.failure({ error });
    yield put(hideLoader());
  }
}

function* downloadCertificateSaga({ data }) {
  const url = 'application/getcertificatedownloadurl';
  yield put(showLoader());
  try {
    const res = yield call(ApiClient.httpClient.get, url, { params: { id: data.publicGuid, version: data.data.applicationVersion, fileName: data.data.fileName } });
    const link = document.createElement('a');
    link.href = res.data.downloadUrl || res.data;
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(res.data.downloadUrl || res.data, 100));
  } catch (error) {
    put(showAlertAction('Some error occured during downloading. Please try again.'));
  }
  yield put(hideLoader());
}

export function* watchGetReviewSaga() {
  yield takeLatest(GET_REVIEWSUBMIT.BASE, getReviewSaga);
}
export function* watchUploadCertificateSaga() {
  yield takeLatest(UPLOAD_CERTIFICATE.BASE, uploadCertificateSaga);
}
export function* watchDeleteCetificateSaga() {
  yield takeLatest(DELETE_CERTIFICATE.BASE, deleteCertificateSaga);
}

export function* watchDownloadCertificateSaga() {
  yield takeLatest(DOWNLOAD_CERTIFICATE, downloadCertificateSaga);
}
export default reviewReducer;
