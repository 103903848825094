import { combineReducers } from 'redux';
import { getHostIdsReducer } from 'reducers/modules/Profile/Agency/AgencyDetails/getHostIdsReducer';
import { getAgencyProfileReducer } from 'reducers/modules/Profile/Agency/AgencyProfile/AgencyProfile';
import { accessingUserProfileReducer } from 'reducers/modules/Profile/accessingUserProfile';
import { getFullProfileReducer } from 'reducers/modules/Profile/getfulluserprofileReducer';

import userProfile from './userProfileReducer';
import agency from './Agency';
import salesReport from './SalesReport';

export {
  getHostList,
  // getMatchingIDs,
  // watchHostListSaga,
  // watchMatchingIDSaga,
  fetchAgencyHierarchy,
  watchfetchAgencyHierarchySaga,
  emulateAgency,
  watchEmulateAgencySaga
} from './Agency/index';

export {
  exportProductEventReport,
  fetchproductEventReport,
  fetchProductSelectList,
  watchExportProductEventReportSaga,
  watchFetchproductEventReportSaga,
  watchFetchProductSelectListSaga
} from './SalesReport/index';

export {
  watchDeleteHostInformationSaga,
  watchHostInformationSaga,
} from './getfulluserprofileReducer';

export {
  saveAgencyLogo,
  canAgencyDownload,
  watchSaveAgencyLogoSaga,
  watchSaveAgencyDetailsSaga,
  watchChangeUserRoleSaga,
  watchCanAgencyDownloadSaga
} from './userProfileReducer';

const profile = combineReducers({
  hostList: getHostIdsReducer,
  agency,
  userProfile,
  salesReport,
  getAgencyProfile: getAgencyProfileReducer,
  accessingUserProfileReducer,
  fullProfile: getFullProfileReducer
});

export default profile;
