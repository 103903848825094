import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';

class AgencyDetailsInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      roleValid: true
    };
  }

  componentDidMount() {
    if (this.props.masterCustomernumber === '9999999999' && this.props.customerNumber === '9999999999') {
      this.setState({ roleValid: true });
    } else if (this.props.currentRoleId === 4) {
      this.setState({ roleValid: true });
    } else {
      this.setState({ roleValid: false });
    }
  }

  render() {
    return (
      <>
        <span className="agency-details-title">
          Agency Details
        </span>
        <div className="agency-details-box space-top-sm">
          <div className="col-sm-4">
            Agency Type:
            {' '}
            {this.props.agencyType}
            <br />
            Customer Number:
            {' '}
            {parseInt(this.props.customerNumber, 10)}
            {' '}
            <br />
            Location:
            {' '}
            {this.props.country}
          </div>
          <div className="col-sm-12">
            {
              this.state.roleValid
                ? (
                  <SimplePrettyCheckBox
                    onChange={this.props.canAgentDownloadHandler}
                    value={!this.props.canAgentDownload}
                    displayText="Restrict agency users from accessing products if agency admin has not accessed"
                  />
                )
                : null
            }
          </div>
        </div>
      </>
    );
  }
}

AgencyDetailsInfo.propTypes = {
  country: PropTypes.string,
  region: PropTypes.string,
  agencyType: PropTypes.string,
  customerNumber: PropTypes.string,
  masterCustomernumber: PropTypes.string,
  parentOrganization: PropTypes.string,
  hostString: PropTypes.string,
  canAgentDownload: PropTypes.bool,
  canAgentDownloadHandler: PropTypes.func,
  canView: PropTypes.bool,
  enableAgentsToSeeParentAssignedSolutions: PropTypes.bool,
  currentRoleId: PropTypes.number
};

AgencyDetailsInfo.defaultProps = {
  country: '',
  region: '',
  agencyType: '',
  customerNumber: '',
  hostString: '',
};

export default AgencyDetailsInfo;
