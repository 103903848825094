import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import { connect } from 'react-redux';
import {
  submitLicenseChangeConf,
  cancelLicenseChangeConf
} from 'reducers/modules/Administration/ManageLicenses/EditLicenseStatus/editLicenseStatus';
import {
  editLicenseStatusSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';

class LicenseChangeConfModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      reasonText: '',
      otherReason: '',
      isInvalidOtherReason: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((this.props.showLicenseChangeConfModal !== nextProps.showLicenseChangeConfModal)
      && nextProps.showLicenseChangeConfModal) {
      this.setState({
        reason: '',
        reasonText: '',
        otherReason: '',
        isInvalidOtherReason: false
      });
    }
  }

  onChangeReason = (event) => {
    const reasonText = event.target.options[event.target.selectedIndex].text;
    this.setState({ reason: event.target.value, reasonText });
  }

  onChangeOtherReason = (event) => {
    let val = event.target.value;
    if (val.length > 100) {
      val = val.substring(0, 100);
    }
    this.setState({ otherReason: val });
  }

  submitChanges = () => {
    if (!this.state.reason) {
      this.setState({ reason: this.props.licenseChangeInfo.reasonSelectListItems[0].value },
        () => this.props.submitLicenseChangeConf(this.state.reason, this.state.otherReason));
    } else if (this.state.reasonText === 'Other' && !this.state.otherReason) {
      this.setState({ isInvalidOtherReason: true });
    } else {
      this.setState({ isInvalidOtherReason: false });
      this.props.submitLicenseChangeConf(this.state.reason, this.state.otherReason);
    }
  }

  render() {
    if (!this.props.licenseChangeInfo) {
      return '';
    }
    return (
      <Modal
        id="sbModal"
        title={this.props.licenseChangeInfo.title}
        hidden={!this.props.showLicenseChangeConfModal}
        size="small"
        onDismiss={this.props.cancelLicenseChangeConf}
      >
        <div id="modal-setReason">
          <div className="row">
            Select a reason
          </div>
          <div className="row space-bottom-double">
            <select className="select" value={this.state.reason} onChange={this.onChangeReason} style={{ width: '100%' }}>
              {this.props.licenseChangeInfo.reasonSelectListItems.map((v, i) => <option value={v.value} key={i.toString()}>{v.text}</option>)}
            </select>
          </div>
          {this.state.reasonText === 'Other'
            && (
              <div id="otherReason">
                <div className="row">Other reason</div>
                <div className="row space-bottom-double">
                  <textarea
                    className="textarea small-textarea"
                    rows="4"
                    cols="30"
                    maxLength="100"
                    onChange={this.onChangeOtherReason}
                    onKeyUp={this.onChangeOtherReason}
                    onPaste={this.onChangeOtherReason}
                    id="otherReasonText"
                  />
                  <br />
                  {this.state.isInvalidOtherReason
                    && <span id="otherReason-Error" className="field-validation-error">Please enter a valid reason</span>}
                </div>
              </div>
            )}
          <div className="text-center">
            <button type="button" onClick={this.submitChanges} className="action-button">{this.props.licenseChangeInfo.title}</button>
            <button type="button" onClick={this.props.cancelLicenseChangeConf} className="cancel-button space-left">CANCEL</button>
          </div>
        </div>
      </Modal>
    );
  }
}

LicenseChangeConfModal.propTypes = {
  licenseChangeInfo: PropTypes.object,
  showLicenseChangeConfModal: PropTypes.bool,
  submitLicenseChangeConf: PropTypes.func,
  cancelLicenseChangeConf: PropTypes.func
};

const mapStateToProps = (state) => ({
  showLicenseChangeConfModal: editLicenseStatusSelector(state).showLicenseChangeConfModal,
  licenseChangeInfo: editLicenseStatusSelector(state).licenseChangeInfo
});

const mapDispatchToProps = (dispatch) => ({
  submitLicenseChangeConf: (reason, otherReason) => dispatch(submitLicenseChangeConf(reason, otherReason)),
  cancelLicenseChangeConf: () => dispatch(cancelLicenseChangeConf()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseChangeConfModal);
