import { takeLatest, call, put } from 'redux-saga/effects';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import * as actionTypes from '../../../actions/actionType';
import { productReviewsLoad, productReviewsLoadSuccess, productReviewsLoadFailure } from '../../../actions/Product/reviewsAndRatings.Actions';

export const SAVE_REVIEWS_REPLY = createFetchTypes('Marketplace/Product/Product/ProductReducer/SAVE_REVIEWS_REPLY');

const productReviewsReducer = (state = { data: [] }, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_APP_REVIEWS:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };
    case actionTypes.LOAD_APP_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null
      };
    case actionTypes.LOAD_APP_REVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error
      };
    case SAVE_REVIEWS_REPLY.REQUEST:
      return {
        ...state,
        replySuccessful: false,
      };
    case SAVE_REVIEWS_REPLY.SUCCESS:
      return {
        ...state,
        replySuccessful: true,
        error: null
      };
    case SAVE_REVIEWS_REPLY.ERROR:
      return {
        ...state,
        error: action.error,
        replySuccessful: false
      };
    default:
      return state;
  }
};

export const saveReviewsReply = {
  base: (params, publicId, replySuccessHandler) => ({
    type: SAVE_REVIEWS_REPLY.BASE,
    url: 'application/SaveProductReviewReplies',
    payload: {
      params,
      publicId,
      replySuccessHandler
    }
  }),
  request: () => ({
    type: SAVE_REVIEWS_REPLY.REQUEST
  }),
  success: () => ({
    type: SAVE_REVIEWS_REPLY.SUCCESS
  }),
  failure: (error) => ({
    type: SAVE_REVIEWS_REPLY.FAILURE,
    payload: {
      error
    }
  })
};

export function* saveReviewsReplySaga(action) {
  try {
    yield put(showLoader());
    const { url } = action;
    yield call(() => fetchEntity(saveReviewsReply, ApiClient.httpClient.post, url, action.payload.params));
    yield put(hideLoader());
    yield put(productReviewsLoad());
    const productReviews = yield ApiClient.httpClient.get(`application/GetProductReviewsDetails?appId=${action.payload.publicId}`);
    if (productReviews.data) {
      yield put(productReviewsLoadSuccess(productReviews.data));
      yield put(showAlertAction('Reply has been submitted successfully'));
      action.payload.replySuccessHandler();
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(hideLoader());
    yield put(productReviewsLoadFailure(error));
    put(showAlertAction(error));
  }
}

export function* watchSaveReviewReplySaga() {
  yield takeLatest(SAVE_REVIEWS_REPLY.BASE, saveReviewsReplySaga);
}

export default productReviewsReducer;
