/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './BannerNotification.less';

class BannerNotification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false, };
  }

  getStyle = (data) => (!data ? 'glyphicon pull-right glyphicon-triangle-bottom spin-up'
    : 'glyphicon pull-right glyphicon-triangle-bottom spin-down');

  change = (e) => {
    if (this.props.data.length === 1) {
      e.preventDefault();
      return;
    }
    this.setState({ open: !this.state.open });
  }

  render() {
    if (this.props.data.length === 0) {
      return null;
    }
    const priority1 = this.props.data[0];
    const showAnchorLink = priority1.description.length > 0;
    return (
      <>
        <div className="banner-notifcation" style={{ display: this.props.bannerState ? 'block' : 'none' }}>
          <div className="flexClass" onClick={this.change}>
            <div>
              <span className="glyphicon glyphicon-warning-sign" />
&nbsp;
              <Link
                style={{ cursor: priority1.description.length === 0 ? 'default' : 'pointer' }}
                to={showAnchorLink ? `/banner/details/${priority1.id}` : '#'}
              >
                {priority1.headerInfo}
              </Link>
&nbsp;
            </div>
            {this.props.data.length !== 1
              ? (
                <i
                  className={this.getStyle(this.state.open)}
                />
              ) : null}
          </div>
          <div className={this.state.open ? 'notifcation-collapse' : 'notifcation-collapse notification-panel-close'} id="collapseExample">
            <ul className="list-group1">
              {this.props.data.slice(1).map(item => (
                <li key={item.id} className="list-group-item1">
                  <Link
                    style={{ cursor: item.description.length === 0 ? 'default' : 'pointer' }}
                    to={item.description.length > 0 ? `/banner/details/${item.id}` : '#'}
                  >
                    {item.headerInfo}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

      </>
    );
  }
}

BannerNotification.propTypes = {
  data: PropTypes.array,
  bannerState: PropTypes.bool,
  closeBanner: PropTypes.func
};

export default BannerNotification;
