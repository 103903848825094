import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl } from '@atlas-design-system/react';
import { fetchAgencyUserSet } from '../../../../reducers/modules/Profile/Agency/AgencyUser/IndexReducer';
import { getOrgId } from '../../../../reducers/modules/Profile/selectors';
import { createAgencyUser } from '../../../../reducers/modules/Profile/Agency/AgencyUser/createReducer';

const CustomInput = (props) => (
  <form onSubmit={(e) => props.submitHandler(e)}>
    <div className="formStyle">
      <FormControl
        id="search"
        className="note"
        label={props.text}
        hint={props.helpText}
      >
        <input
          className="customForm"
          type="text"
          value={props.inputValue}
          placeholder={props.placeholder}
          onChange={props.handleChange}
          disabled={props.disabled}
        />
      </FormControl>
      <FormControl>
        <button type="button" className="buttonCustom btncls" disabled={props.disabled}>{props.buttonText}</button>
      </FormControl>
    </div>
    <br />
  </form>
);

CustomInput.propTypes = {
  submitHandler: PropTypes.func,
  text: PropTypes.string,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  helpText: PropTypes.string
};

class AgencyUsersRightPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      addText: ''
    };
  }

  handleSearchText = (e) => (
    this.setState({ searchText: e.target.value })
  );

  handleAddText = (e) => (
    this.setState({ addText: e.target.value })
  );

  searchUser = (e) => {
    e.preventDefault();
    this.props.fetch({ filter: this.state.searchText, orgId: this.props.orgId });
  }

  addUser = (e) => {
    e.preventDefault();
    this.props.create({ orgId: this.props.emulatedOrgId, askTravelportId: this.state.addText });
  }

  render() {
    return (
      <div id="rightpanel">
        <div className="actions">
          <CustomInput
            text="FIND USER"
            placeholder="Name or Email"
            buttonText="SEARCH"
            helpText=""
            handleChange={this.handleSearchText}
            disabled={false}
            submitHandler={this.searchUser}
          />
        </div>
        <div className="actions">
          <CustomInput
            text="ADD USER"
            placeholder="My Travelport ID"
            buttonText="ADD"
            helpText="Note: Please Use My Travelport ID."
            handleChange={this.handleAddText}
            submitHandler={this.addUser}
            disabled={this.props.orgId === 1}
          />
        </div>
        <div className="aboutRoles">ABOUT ROLES</div>
        <div className="right-panel-info">
          <span className="bold">General Users can:</span>
          <ul className="green-bullets">
            <li><span>Browse Marketplace</span></li>
            <li><span>Recommend solutions</span></li>
            <li><span>Download assigned products</span></li>
          </ul>
          <span className="bold">Agency Administrator can:</span>
          <ul className="green-bullets">
            <li><span>Edit employee role</span></li>
            <li><span>Provide Marketplace access to new employee</span></li>
            <li><span>Remove Marketplace access</span></li>
            <li><span>Purchase a solution from Marketplace</span></li>
            <li><span>View all purchases</span></li>
            <li><span>Manage Groups</span></li>
            <li><span>Assign solutions for Smartpoint Desktop integration</span></li>
          </ul>
          <span className="bold">Blocked:</span>
          <ul className="green-bullets">
            <li><span>Lost all permission</span></li>
            <li><span>Removed from agency in Marketplace</span></li>
            <li><span>No access to Marketplace website/plug-in</span></li>
          </ul>
        </div>
      </div>
    );
  }
}

AgencyUsersRightPanel.propTypes = {
  agencyDetails: PropTypes.object,
  orgId: PropTypes.number,
  fetch: PropTypes.func,
  create: PropTypes.func,
  emulatedOrgId: PropTypes.number
};

const mapStateToProps = (state) => ({
  orgId: getOrgId(state),
  emulatedOrgId: state.profile.accessingUserProfileReducer.orgId
});

const mapDispatchToProps = (dispatch) => ({
  fetch: bindActionCreators(fetchAgencyUserSet.base, dispatch),
  create: bindActionCreators(createAgencyUser.base, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencyUsersRightPanel);
