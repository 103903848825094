import { put } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import ApiClient from '../../../../../Services/ApiClient';
import { showAlertAction } from '../../../../popUps/alertPopUpReducer';
import { hideLoader, showLoader } from '../../../../popUps/loaderReducer';

export const CREATE_AGENCY_GROUP = 'CREATE_AGENCY_GROUP';
export const createAgencyGroup = (data, id) => ({
  type: CREATE_AGENCY_GROUP,
  payload: {
    ...data,
  },
  id
});

export const CREATE_AGENCY_GROUP_SUCCESS = 'CREATE_AGENCY_GROUP_SUCCESS';
const createAgencyGroupSuccess = (params) => ({
  type: CREATE_AGENCY_GROUP_SUCCESS,
  payload: params
});

export const CREATE_AGENCY_GROUP_FAILURE = 'CREATE_AGENCY_GROUP_FAILURE';
const createAgencyGroupFailure = (error) => ({
  type: CREATE_AGENCY_GROUP_FAILURE,
  payload: {
    error
  }
});

const agencyGroupCreateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CREATE_AGENCY_GROUP:
      return {
        loading: true,
        error: null
      };

    case CREATE_AGENCY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_AGENCY_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export function* createAgencyGroupSaga(action) {
  try {
    const { name, assignedPlugins, members } = action.payload;
    if (!name) {
      yield put(showAlertAction('Please enter the group name.'));
    } else {
      yield put(showLoader());
      const orgId = action.id;
      const agencyGroupRequest = {
        groupId: 0,
        orgId,
        groupName: name,
        assignedPluginIds: assignedPlugins.map(plugin => plugin.value),
      };
      const creationResponse = yield ApiClient.agencyGroup.create(agencyGroupRequest);
      if (members && members.length > 0) {
        const data = {
          groupId: creationResponse.data.groupId,
          orgId,
          memberIds: members.map(member => member.id)
        };
        yield ApiClient.httpClient.post('groups/addmemberstogroup', data);
      }
      yield put(createAgencyGroupSuccess({ params: orgId }));
      yield put(showAlertAction('Group has been added successfully.'));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    let errorMessage = 'Some error occurred. Please try again.';
    if (error && error.response && error.response.data.result) {
      errorMessage = error.response.data.result;
    }
    yield put(showAlertAction(errorMessage));
    yield put(createAgencyGroupFailure());
  }
  yield put(hideLoader());
}

export default agencyGroupCreateReducer;
