import React from 'react';
import PropTypes from 'prop-types';
import SimplePrettyCheckBox from '../../common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';

const FieldDataComponent = props => (
  <ul className="no-list-style no-margin">
    {props.fields && props.fields.map((value, index) => (
      <div key={index.toString()}>
        <SimplePrettyCheckBox
          displayText={value.name}
          id={value.id}
          value={props.itemExists(value.id, props.fieldIds)}
          onChange={
              (val) => props.getSelectedFields(value.id, val, props.fieldIds)
            }
        />
      </div>
    ))}
  </ul>
);

FieldDataComponent.propTypes = {
  fields: PropTypes.array,
  fieldIds: PropTypes.string,
  checked: PropTypes.bool,
  itemExists: PropTypes.func,
  getSelectedFields: PropTypes.func
};

export default FieldDataComponent;
