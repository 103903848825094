import {
  takeLatest, call, put, take, race
} from 'redux-saga/effects';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import ApiClient from 'Services/ApiClient';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  CONFIRMATION_ACCEPTED,
  CONFIRMATION_REJECTED,
  showConfirmationModal
} from 'reducers/popUps/confirmationModalReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';

const FETCH_PRODUCT_REVIEWS = createFetchTypes(
  'Marketplace/Reports/productReviews/FETCH_PRODUCT_REVIEWS'
);
const DELETE_PRODUCT_REVIEWS = createFetchTypes(
  'Marketplace/Reports/productReviews/DELETE_PRODUCT_REVIEWS'
);
const FETCH_URL = 'reports/GetFilteredProductReviewReport';
const DELETE_URL = 'application/DeleteProductReview';

function productReviews(state = { data: { pagedCollection: [] } }, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCT_REVIEWS.REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCT_REVIEWS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case FETCH_PRODUCT_REVIEWS.ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export const fetchProductReviews = {
  base: params => ({
    type: FETCH_PRODUCT_REVIEWS.BASE,
    params,
    url: FETCH_URL
  }),
  request: () => ({ type: FETCH_PRODUCT_REVIEWS.REQUEST }),
  success: data => ({ type: FETCH_PRODUCT_REVIEWS.SUCCESS, data }),
  failure: error => ({ type: FETCH_PRODUCT_REVIEWS.FAILURE, error })
};

export const deleteProductReviews = {
  base: (params, successHandler) => ({
    type: DELETE_PRODUCT_REVIEWS.BASE,
    params,
    url: DELETE_URL,
    successHandler
  }),
  request: () => ({ type: DELETE_PRODUCT_REVIEWS.REQUEST }),
  success: data => ({ type: DELETE_PRODUCT_REVIEWS.SUCCESS, data }),
  failure: error => ({ type: DELETE_PRODUCT_REVIEWS.FAILURE, error })
};

export function* deleteProductReviewsSaga({ url, params, successHandler }) {
  try {
    yield put(
      showConfirmationModal(
        'Are you sure you want to delete the review from Marketplace?'
      )
    );
    const { accept } = yield race({
      accept: take(CONFIRMATION_ACCEPTED),
      reject: take(CONFIRMATION_REJECTED)
    });
    if (accept) {
      deleteProductReviews.request();
      const result = yield ApiClient.httpClient.delete(
        `${url}/${params.reviewId}`
      );
      if (result.status === 200) {
        yield put(showAlertAction('review has been successfully deleted.'));
        successHandler();
      }
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    deleteProductReviews.failure();
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* fetchProductReviewSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchProductReviews, ApiClient.httpClient.post, url, params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export default productReviews;

export function* productReviewReportMiddleware() {
  yield takeLatest(FETCH_PRODUCT_REVIEWS.BASE, fetchProductReviewSaga);
  yield takeLatest(DELETE_PRODUCT_REVIEWS.BASE, deleteProductReviewsSaga);
}
