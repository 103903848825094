/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { takeLatest, put, call } from 'redux-saga/effects';
import Axios from 'axios/index';
import ApiClient from '../../../Services/ApiClient';
import { SP_CLOUD_API_ENDPOINT } from '../../../config/apiConfig';
import createFetchTypes from '../../utils/createFetchTypes';
import { showLoader, hideLoader } from '../../popUps/loaderReducer';

const FETCH_GIT_PLUGIN_LINKAGE_DATA = 'FETCH_GIT_PLUGIN_LINKAGE_DATA';
const FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS = 'FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS';
const FETCH_GIT_PLUGIN_LINKAGE_DATA_FAILURE = 'FETCH_GIT_PLUGIN_LINKAGE_DATA_FAILURE';

const SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION = createFetchTypes(
    'Marketplace/SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION'
);

export const fetchGitPluginLinkageData = (data, callbackplugin) => ({
    type: FETCH_GIT_PLUGIN_LINKAGE_DATA,
    payload: {
        data,
    },
    callbackplugin
});

export const savePluginLinkageDataForCertification = {
    base: (data, callback) => ({
        type: SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.BASE,
        data,
        url: 'spwebservice/api/Plugin/SaveSubmitForApproval',
        callback
    }),
    request: () => ({ type: SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.REQUEST }),
    success: payload => ({ type: SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.SUCCESS, payload }),
    failure: error => ({ type: SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.FAILURE, error })
};

export const fetchGitPluginLinkageDataSuccess = (data) => ({
    type: FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS,
    data
});

export const fetchGitPluginLinkageDataFailure = (error) => ({
    type: FETCH_GIT_PLUGIN_LINKAGE_DATA_FAILURE,
    error
});

export default function gitSPCPluginLinkageInfo(state = {}, action = {}) {
    switch (action.type) {
        case FETCH_GIT_PLUGIN_LINKAGE_DATA:
        case SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.BASE:
            return {
                ...state,
            };
        case FETCH_GIT_PLUGIN_LINKAGE_DATA_SUCCESS:
            return {
                ...state,
                getValidatedPluginLinkageData: action.data
            };
        case FETCH_GIT_PLUGIN_LINKAGE_DATA_FAILURE:
            return {
                ...state,
                fetchPluginError: action.error
            };
        case SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.SUCCESS:
            return {
                ...state,
                getSubmitForApprovalPlugin: action.payload // we will remove this later
            };
        case SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.FAILURE:
            return {
                ...state,
                errorList: action.error
            };
        default:
            return state;
    }
}

export function* gitValidatedPluginSaga(action) {
    yield put(showLoader());
    try {
        const baseUrl = SP_CLOUD_API_ENDPOINT;
        const customhttpConfig = {
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        ApiClient.httpClient = Axios.create(customhttpConfig);

        const response = yield call(ApiClient.httpClient.get, `spwebservice/api/Plugin/linkage?appId=${action.payload.data.appId}`);
        if (response.data || response.statusText === 'OK' || response.data.message) {
            if (response.data.message !== undefined) {
                const obj = undefined;
                yield put(fetchGitPluginLinkageDataSuccess(obj));
            } else {
                yield put(fetchGitPluginLinkageDataSuccess(response.data));
            }
            const message = undefined;
            yield put(fetchGitPluginLinkageDataFailure(message));
            action.callbackplugin();
            yield put(hideLoader());
        }
    } catch (error) {
        const message = 'Request Id is not find';
        yield put(fetchGitPluginLinkageDataFailure(message));
        action.callbackplugin();
        yield put(hideLoader());
    }
}

export function* saveGitPluginSubmitForApprovalSaga(action) {
    yield put(showLoader());
    try {
        const params = action.data;
        const baseUrl = SP_CLOUD_API_ENDPOINT;
        const customhttpConfig = {
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        ApiClient.httpClient = Axios.create(customhttpConfig);
        const response = yield ApiClient.httpClient.post(action.url, params);
        if (response.data || response.statusText === 'OK' || response.status === 200) {
            yield put(savePluginLinkageDataForCertification.success(response.data));
            const messages = undefined;
            yield put(savePluginLinkageDataForCertification.failure(messages));
        }
        yield put(hideLoader());
    } catch (error) {
        let message;
        if (error.response.data !== undefined) {
            message = error.response.data;
        } else {
            message = 'Some error occured while processing the request';
        }
        yield put(savePluginLinkageDataForCertification.failure(message));
        action.callback();
        yield put(hideLoader());
    }
}

export function* watchValidatedPluginSaga() {
    yield takeLatest(FETCH_GIT_PLUGIN_LINKAGE_DATA, gitValidatedPluginSaga);
}

export function* watchSavePluginSubmitForApprovalSaga() {
    yield takeLatest(SAVE_PLUGIN_LINKAGE_DATA_FOR_CERTIFICATION.BASE, saveGitPluginSubmitForApprovalSaga);
}
