import React, { Component } from 'react';
import AgencyUsersRightPanel from './AgencyUsersRightPanel';
import AgencyUsersTable from './AgencyUsersTable';
import './AgencyUsers.less';

class AgencyUsers extends Component {
  render() {
    return (
      <div className="row extra-small-font pad-left pad-right space-top" id="agency-users">
        <AgencyUsersTable />
        <AgencyUsersRightPanel />
      </div>
    );
  }
}

export default AgencyUsers;
