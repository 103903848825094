/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable indent */

import React from 'react';

class CircularProgressHelper extends React.Component {
    constructor() {
        super();
        this.state = {
            valuesIndex: 0
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                valuesIndex: (this.state.valuesIndex + 1) % this.props.values.length
            });
        }, 3000);
    }

    render() {
        return this.props.children(this.props.values[this.state.valuesIndex]);
    }
}

export default CircularProgressHelper;
