import { createSelector } from 'reselect';

const spSettings = state => state.administration.smartpointConfigSettings && state.administration.smartpointConfigSettings.pagedCollection;
const settingsAttributes = state => state.administration.smartpointConfigSettings && state.administration.smartpointConfigSettings.settingsAttributes;
const smartpointSettings = state => state.administration.smartpointConfigSettings && state.administration.smartpointConfigSettings.smartpointSettings;
const userID = state => state.administration.smartpointConfigSettings.usersID;
const pccList = state => state.administration.smartpointConfigSettings.pccList;
const countries = state => state.administration.smartpointConfigSettings.countries;
const regions = state => state.administration.smartpointConfigSettings.regions;
const groups = state => state.administration.smartpointConfigSettings.groups;
const settingValues = state => state.administration.smartpointConfigSettings.settingValues;

export const smartPointConfigTotalPages = state => state.administration.smartpointConfigSettings.totalPages;

export const getUserIDSelector = createSelector(
  [userID],
  (users) => users && users.map(item => ({
    label: item,
    value: item
  }))
);

export const getPccSelector = createSelector(
  [pccList],
  (pccs) => pccs && pccs.map(item => ({
    label: item,
    value: item
  }))
);

export const getSettingsSelector = createSelector(
  [spSettings],
  (settings) => settings && settings.map(item => ({
    ...item,
    smartpointSettingValue: item.smartpointSetting.value,
    settingAttribute: item.settingAttribute.value,
  }))
);

export const getSettingsAttributeSelector = createSelector(
  [settingsAttributes],
  (settings) => settings && settings.map(item => ({
    label: item.text,
    value: item.value,
  }))
);

export const getSmartpointSettingsSelector = createSelector(
  [smartpointSettings],
  (settings) => settings && settings.map(item => ({
    label: item.text,
    value: item.value,
  }))
);

export const getCountriesSelector = createSelector(
  [countries],
  (country) => country && country.map(item => ({
    label: item,
    value: item
  }))
);

export const getRegionsSelector = createSelector(
  [regions],
  (region) => region && region.map(item => ({
    label: item,
    value: item
  }))
);

export const getGroupsSelector = createSelector(
  [groups],
  (group) => group && group.map(item => ({
    label: item,
    value: item
  }))
);

export const getSettingsValueSelector = createSelector(
  [settingValues],
  (value) => value && ({
    defaultSettingValue: value.defaultSettingValue,
    settingValues: value.settingValues.map(item => ({
      label: item,
      value: item
    }))
  })

);
