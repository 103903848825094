import { takeLatest, call, put } from 'redux-saga/effects';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import createFetchTypes from '../../../utils/createFetchTypes';
import fetchEntity from '../../../utils/fetchEntitySaga';
import ApiClient from '../../../../Services/ApiClient';
import { showAlertAction } from '../../../popUps/alertPopUpReducer';
import { showLoader, hideLoader } from '../../../popUps/loaderReducer';
import fetchEntityPPCP from '../fetchEntityPPCPSaga';

export const FETCH_SUPPORT_DETAILS = createFetchTypes('Marketplace/Application/Support/FETCH_SUPPORT_DETAILS');
export const SAVE_SUPPORT_DOCUMENTAITON = createFetchTypes('Marketplace/Application/Support/SAVE_SUPPORT_DOCUMENTATION');
export const ADD_SUPPORT_LIST = createFetchTypes('Marketplace/Application/Support/ADD_SUPPORT_LIST');
export const DELETE_SUPPORT_LIST = createFetchTypes('Marketplace/Application/Support/DELETE_SUPPORT_LIST');
export const UPDATE_SUPPORT_DOCUMENTS_ONCHANGE = 'Marketplace/Application/Support/UPDATE_SUPPORT_DOCUMENTS_ONCHANGE';
export const UPDATE_SUPPORT_CONTACT_FIELDS = 'Marketplace/Application/Support/UPDATE_SUPPORT_CONTACT_FIELDS';

function constructDocumentProps(docs) {
  const property = {
    documentationName1: '',
    documentationName2: '',
    documentationName3: '',
    fileDescription1: '',
    fileDescription2: '',
    fileDescription3: ''
  };
  for (let i = 0; i < docs.supportDocuments.length; i += 1) {
    const index = i + 1;
    property[`documentationName${index}`] = docs.supportDocuments[i].documentName || '';
    property[`fileDescription${index}`] = docs.supportDocuments[i].fileDescription || '';
  }
  return property;
}
export const supportReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_SUPPORT_DOCUMENTS_ONCHANGE: {
      if (state.supportData.supportDocuments && state.supportData.supportDocuments.length > 0 && action.payload.id) {
        const supportDocuments = state.supportData.supportDocuments.splice(0, state.supportData.supportDocuments.length);
        supportDocuments[action.payload.index][action.payload.property] = action.payload.propertyValue;
        return {
          ...state,
          supportData: {
            ...state.supportData,
            supportDocuments,
            ...constructDocumentProps({ supportDocuments })
          }
        };
      }
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_SUPPORT_CONTACT_FIELDS:
      return {
        ...state,
        supportData: {
          ...state.supportData,
          [action.payload.property]: action.payload.propertyValue
        }
      };
    case FETCH_SUPPORT_DETAILS.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SUPPORT_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        supportData: {
          ...action.payload.data.model,
          ...constructDocumentProps(action.payload.data.model)
        },
        error: null
      };
    case FETCH_SUPPORT_DETAILS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SAVE_SUPPORT_DOCUMENTAITON.REQUEST:
      return state;
    case SAVE_SUPPORT_DOCUMENTAITON.SUCCESS: {
      const supportDocuments = state.supportData.supportDocuments.concat({
        fileName: action.payload.data.fileName,
        id: action.payload.data.supportDocId,
        documentName: action.payload.data.documentName,
        fileDescription: action.payload.data.fileDescription
      }).filter(item => Object.keys(item).length);
      return {
        ...state,
        supportData: {
          ...state.supportData,
          supportDocuments,
          ...constructDocumentProps({ supportDocuments })
        }
      };
    }
    case SAVE_SUPPORT_DOCUMENTAITON.FAILURE:
      return state;

    case ADD_SUPPORT_LIST.SUCCESS: {
      const supportDocuments = action.payload.data.splice(0, action.payload.data.length);
      return {
        ...state,
        supportData: {
          ...state.supportData,
          supportDocuments,
          ...constructDocumentProps({ supportDocuments })
        }
      };
    }

    case DELETE_SUPPORT_LIST.REQUEST:
      return state;
    case DELETE_SUPPORT_LIST.SUCCESS: {
      const supportDocuments = state.supportData.supportDocuments.map((data) => {
        if (data.id === action.payload.documentId) {
          return null;
        }
        return data;
      }).filter(x => !!x);

      return {
        ...state,
        loading: false,
        supportData: {
          ...state.supportData,
          supportDocuments,
          ...constructDocumentProps({ supportDocuments })
        }
      };
    }
    case DELETE_SUPPORT_LIST.FAILURE:
      return state;
    default:
      return state;
  }
};

export const updateSupportDocuments = {
  success: (params) => ({
    type: UPDATE_SUPPORT_DOCUMENTS_ONCHANGE,
    payload: {
      ...params
    }
  }),
};

export const updateSupportContactFields = {
  success: (params) => ({
    type: UPDATE_SUPPORT_CONTACT_FIELDS,
    payload: {
      ...params
    }
  }),
};

export const fetchSupportDetails = {
  base: (params) => ({
    type: FETCH_SUPPORT_DETAILS.BASE,
    url: 'application/getsupport',
    payload: {
      params
    }
  }),
  request: () => ({ type: FETCH_SUPPORT_DETAILS.REQUEST }),
  success: (data) => ({
    type: FETCH_SUPPORT_DETAILS.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: FETCH_SUPPORT_DETAILS.FAILURE })
};

export const saveSupportDocumentation = {
  base: (params) => ({
    type: SAVE_SUPPORT_DOCUMENTAITON.BASE,
    url: 'appversion/uploadsupportfile',
    payload: {
      params
    }
  }),
  request: () => ({ type: SAVE_SUPPORT_DOCUMENTAITON.REQUEST }),
  success: (data) => ({
    type: SAVE_SUPPORT_DOCUMENTAITON.SUCCESS,
    payload: {
      data
    }
  }),
  failure: () => ({ type: SAVE_SUPPORT_DOCUMENTAITON.FAILURE })
};

export const addSupportDocument = {
  base: (params) => ({
    type: ADD_SUPPORT_LIST.BASE,
    params
  }),
  success: (data) => ({
    type: ADD_SUPPORT_LIST.SUCCESS,
    payload: {
      data
    }
  }),
};

export const deleteSupportDocument = {
  base: (data) => ({
    type: DELETE_SUPPORT_LIST.BASE,
    url: 'appversion/deletesupportfile',
    data
  }),
  request: () => ({ type: DELETE_SUPPORT_LIST.REQUEST }),
  success: payload => ({ type: DELETE_SUPPORT_LIST.SUCCESS, payload }),
  failure: error => ({ type: DELETE_SUPPORT_LIST.FAILURE, error })
};

export function* fetchSupportDetailsSaga(action) {
  try {
    yield put(showLoader());
    const { params } = action.payload;
    const { url } = action;

    yield call(() => fetchEntityPPCP(fetchSupportDetails, ApiClient.httpClient.get, url, { params: { ...params } }));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
  }
}

function* uploadSupportDoc(url, formData, params) {
  let response;
  let reqResponse;
  try {
    yield put(saveSupportDocumentation.request());
    response = yield ApiClient.httpClient.post(url, formData);
    if (response.data) {
      reqResponse = { ...response.data, documentName: params.fileName ? params.fileName : response.data.fileName, fileDescription: params.fileDescription || '' };
      yield put(saveSupportDocumentation.success(reqResponse));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    throw JSON.parse(JSON.stringify(error));
  }
  return response;
}

export function* saveSupportDocumentationSaga(action) {
  const { params } = action.payload;
  try {
    yield put(showLoader());
    const formData = new FormData();
    formData.append('appId', params.id);
    formData.append('file', params.file);
    formData.append('documentName', params.fileName);
    formData.append('fileDescription', params.fileDescription);
    formData.append('fileName', params.file.name);
    yield call(() => uploadSupportDoc(action.url, formData, params));
    yield put(hideLoader());
    params.errorHandler(null);
  } catch (error) {
    yield put(hideLoader());
    params.errorHandler((error && error.response && error.response.data) || 'Error occured');
    yield put(saveSupportDocumentation.failure());
  }
}

export function* addSupportDocumentSaga(action) {
  try {
    yield put(addSupportDocument.success(action.params));
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* deleteSupportDocumentSaga(action) {
  try {
    yield put(showLoader());
    yield call(() => fetchEntity(deleteSupportDocument, ApiClient.httpClient.delete, action.url, { params: { appId: action.data.appId, documentId: action.data.documentId } }));
    yield put(hideLoader());
  } catch (error) {
    yield put(hideLoader());
    put(showAlertAction(error));
  }
}

export function* watchFetchSupportDetailsSaga() {
  yield takeLatest(FETCH_SUPPORT_DETAILS.BASE, fetchSupportDetailsSaga);
}

export function* watchSaveSupportDocumentationSaga() {
  yield takeLatest(SAVE_SUPPORT_DOCUMENTAITON.BASE, saveSupportDocumentationSaga);
}

export function* watchAddSupportDocumentSaga() {
  yield takeLatest(ADD_SUPPORT_LIST.BASE, addSupportDocumentSaga);
}

export function* watchDeleteSupportDocumentSaga() {
  yield takeLatest(DELETE_SUPPORT_LIST.BASE, deleteSupportDocumentSaga);
}
