/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { filterValue: {} };
  }

  setValue = (type, value) => {
    const tmp = this.state.filterValue;
    tmp[type] = value;
    this.setState({ filterValue: tmp });
  };

  submit = () => {
    this.props.onSubmit(this.state.filterValue);
  };

  render() {
    const itemOption = this.props.options.map((item, i) => {
      const id = i + Date.now();
      return (
        <option key={id} value={item.value}>
          {item.text}
        </option>
      );
    });

    return (
      <div className="col-sm-5 col-sm-offset-1">
        <div className="row">
          <div className="col-sm-8">
            <div>Find Organization:</div>
            <input
              type="text"
              id="searchAgencyNameTextbox"
              className="text-input searchAgencyField ng-pristine ng-valid"
              placeholder="Name, PCC or Customer #"
              onChange={e => this.setValue('searchString', e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8">
            <div>Filters:</div>
            <select
              className="select text-input searchAgencyField ng-pristine ng-valid"
              onChange={e => this.setValue('filter', e.target.value)}
            >
              {itemOption}
            </select>
          </div>
          <div className="col-sm-3 col-sm-offset-1">
            <div>&nbsp;</div>

            <input
              type="submit"
              id="find-agency-link"
              className="action-button"
              value="Search"
              onClick={() => this.props.onSubmit(this.state.filterValue)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Filter.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.array
};

export default Filter;
