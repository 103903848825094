import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ReactStars from 'react-stars';

class StarComponent extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <ReactStars {...this.props} />
    );
  }
}
StarComponent.propTypes = {
  edit: PropTypes.bool,
  count: PropTypes.number,
  value: PropTypes.number,
  size: PropTypes.number,
  color1: PropTypes.string,
  color2: PropTypes.string,
  half: PropTypes.bool,
  onChange: PropTypes.func
};
StarComponent.defaultProps = {
  edit: true,
  count: 5,
  size: 19,
  color1: '#aaa',
  color2: '#00507f',
  half: true,
  onChange: (val) => {
    this.props.onChange(val);
  }
};
export default StarComponent;
