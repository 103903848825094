/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Getter } from '@devexpress/dx-react-core';
import { Link } from 'react-router-dom';
import {
  EditingState, PagingState, CustomPaging, SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableEditRow,
  Toolbar,
  TableColumnResizing,
  PagingPanel,
  VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  getSettingsSelector,
  getPccSelector,
  getSmartpointSettingsSelector,
  getCountriesSelector,
  getSettingsValueSelector,
  getRegionsSelector,
  getSettingsAttributeSelector,
  getUserIDSelector,
  getInitialData,
  getUsersID,
  getPccs,
  onSave,
  onDelete,
  toggleActivation,
  exportSmartConfiguration,
  getGroupsSelector,
  getSettingValues
} from 'reducers/modules/Administration';
import { smartPointConfigTotalPages } from 'reducers/modules/Administration/SmartpointConfigSettings/selector';
import ActiveToggle from '../ActiveToggle/ActiveToggle';
import EditCell from './TableCells/SettingsEditCell';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import ExcelDownloadPresentation from '../../common/Reports/ExcelDownloadPresentation';
import ToolTip from '../../common/ToolTip';

class SmartpointConfigSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.sortingStateColumnExtensions = [
      { columnName: 'activated', sortingEnabled: false },
    ];

    this.state = {
      filter: {
        filter: '',
        sortOnColumn: '',
        sortAsc: null
      },
      paging: {
        currentPage: 0,
        pageSize: 20,
      },
      sorting: {
        columnName: '',
        direction: 'asc',
      },
      searchText: '',
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      deletingRows: [],
      smartpointSettingChange: false,
      columns: [
        { name: 'activated', title: 'ACTIVATE' },
        { name: 'smartpointSettingText', title: 'SMARTPOINT SETTING' },
        { name: 'settingValue', title: 'SETTING VALUE' },
        { name: 'settingAttributeText', title: 'SETTING ATTRIBUTE(S)' },
        { name: 'attributeValueOne', title: 'ATTRIBUTE VALUE' },
        { name: 'attributeValueTwo', title: 'ATTRIBUTE VALUE' },
        { name: 'attributeValueThree', title: 'ATTRIBUTE VALUE' },
      ],
      columnWidths: [
        { columnName: 'activated', width: 150 },
        { columnName: 'smartpointSettingText', width: 205 },
        { columnName: 'settingValue', width: 150 },
        { columnName: 'settingAttributeText', width: 170 },
        { columnName: 'attributeValueOne', width: 150 },
        { columnName: 'attributeValueTwo', width: 150 },
        { columnName: 'attributeValueThree', width: 150 }
      ]
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = {
      filter: this.state.filter.filter,
      currentPage: this.state.paging.currentPage
    };
    if (this.getSortColumn()) {
      params.sortOnColumn = this.getSortColumn();
      params.sortAsc = this.getSortDirection();
    }
    this.props.getInitialDataAction(params);
  }

  getSortColumn = () => {
    if (!this.state.sorting.columnName) {
      return '';
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn[0].toUpperCase() + sortColumn.slice(1);
    return sortColumn;
  }

  getSortDirection = () => this.state.sorting.direction === 'asc';

  changeRowChanges = rowChanges => {
    const newRow = rowChanges;
    if (this.state.smartpointSettingChange) {
      const rowId = this.state.editingRowIds[0];
      const row = rowChanges[rowId.toString()];
      row.settingValue = this.props.settingsValue.defaultSettingValue;
    }
    this.setState({ rowChanges: newRow, smartpointSettingChange: false });
  }

  changeAddedRows = (addedRows) => {
    const newRow = addedRows;
    if (this.state.smartpointSettingChange && addedRows.length > 0 && Object.keys(addedRows[0]).length > 0) {
      const rowId = addedRows[0];
      rowId.settingValue = this.props.settingsValue.defaultSettingValue;
    }
    this.setState({ addedRows: newRow, smartpointSettingChange: false });
  };

  changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });

  deletedRowIdsChange = deletingRows => this.setState({ deletingRows });

  commitChanges = ({ deleted }) => {
    if (deleted) {
      const { onDeleteAction, rows } = this.props;
      onDeleteAction({
        row: rows[deleted[0]]
      });
    }
  }

  handleToggle = (props) => {
    const { toggleActivationAction } = this.props;
    toggleActivationAction(
      props.row,
    );
  }

  checkAttr = (attributeList, attr) => {
    for (let i = 0; i < attributeList.length; i += 1) {
      const attrName = attributeList[i].trim().replace(/\s+/g, '').toLowerCase();
      if (attrName === attr) {
        return i + 1;
      }
    }
    return -1;
  }

  Cell = (props) => {
    if (props.column.name === 'activated') {
      return (
        <td>
          <div onClick={() => this.handleToggle(props)}>
            <ActiveToggle
              active={props.value}
            />
          </div>
        </td>
      );
    }

    const attributeList = props.row.settingAttributeText.split('+');
    const pccIndex = this.checkAttr(attributeList, 'pcc');
    const userIdIndex = this.checkAttr(attributeList, 'userid');

    if (
      ((props.column.name === 'attributeValueOne' && pccIndex === 1)
        || (props.column.name === 'attributeValueTwo' && pccIndex === 2)
        || (props.column.name === 'attributeValueThree' && pccIndex === 3)) && props.row.pccOrgName) {
      return (
        <td>
          <ToolTip hideInfoIcon labelName={props.row.pccOrgName}>
            <span className="highlighted-tooltip">{props.value}</span>
          </ToolTip>
        </td>
      );
    }
    if (
      ((props.column.name === 'attributeValueOne' && userIdIndex === 1)
        || (props.column.name === 'attributeValueTwo' && userIdIndex === 2)
        || (props.column.name === 'attributeValueThree' && userIdIndex === 3)) && props.row.userFullName) {
      return (
        <td>
          <ToolTip hideInfoIcon labelName={props.row.userFullName}>
            <span className="highlighted-tooltip">{props.value}</span>
          </ToolTip>
        </td>
      );
    }
    return <Table.Cell {...props} />;
  }

  commitChangesAsync = (id, onExecute) => {
    const { onSaveAction, rows } = this.props;
    switch (id) {
      case 'commit': {
        const save = {};
        if (this.state.addedRows.length > 0) {
          save.addedRows = this.state.addedRows;
        }
        if (this.state.rowChanges[this.state.editingRowIds[0]]) {
          save.updatedRows = {
            ...this.state.rowChanges[this.state.editingRowIds[0]],
            row: rows[this.state.editingRowIds[0]]
          };
        }
        if (!this.state.rowChanges[this.state.editingRowIds[0]] && this.state.addedRows.length === 0) {
          save.updatedRows = {
            row: rows[this.state.editingRowIds[0]]
          };
        }
        onSaveAction({
          addedRows: save.addedRows,
          updatedRows: save.updatedRows,
          onSuccess: onExecute
        });
        break;
      }
      default:
        onExecute();
        break;
    }
  }

  searchHandler = (e) => {
    const filter = { ...this.state.filter };
    filter.filter = e.target.value;
    this.setState({
      filter,
      searchText: e.target.value
    });
  }

  fetchSearchResult = (e) => {
    e.preventDefault();
    const paging = { ...this.state.paging };
    paging.currentPage = 0;
    this.setState({
      paging
    }, this.getInitialData);
  }

  command = ({ id, onExecute }) => {
    const commandComponentProps = {
      add: {
        text: 'New',
        hint: 'Create new row',
      },
      edit: {
        text: 'Edit',
        hint: 'Edit row',
      },
      delete: {
        text: 'Delete',
        hint: 'Delete row',
      },
      commit: {
        text: 'Save',
        hint: 'Save changes',
      },
      cancel: {
        text: 'Cancel',
        hint: 'Cancel changes',
      },
    };
    return (
      <Link
        to="#"
        className="pad-sm"
        onClick={(e) => {
          e.preventDefault();
          this.commitChangesAsync(id, onExecute);
        }}
      >
        {commandComponentProps[id].text}
      </Link>
    );
  }

  sortHandler = (newSorting) => this.setState({ sorting: { ...newSorting[0] } }, this.getInitialData)

  pageHandler = (currentPage) => this.setState({ paging: { ...this.state.paging, currentPage } }, this.getInitialData);

  clearAttributeValues = () => {
    if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      if (this.state.rowChanges && this.state.rowChanges[rowIndex]) {
        this.state.rowChanges[rowIndex].attributeValueOne = '';
        this.state.rowChanges[rowIndex].attributeValueTwo = '';
        this.state.rowChanges[rowIndex].attributeValueThree = '';
      } else {
        this.state.rowChanges[rowIndex] = {
          attributeValueOne: '',
          attributeValueTwo: '',
          attributeValueThree: '',
        };
      }
    }
  };

  EditCell = (props) => {
    const {
      attributeSettings, rows, userID, pccList, getUsersIDAction, getPccsAction, spSettings, countries, regions, groups, getSettingValuesAction, settingsValue
    } = this.props;
    let attributeSettingSelected;
    if (this.state.addedRows && this.state.addedRows.length > 0) {
      attributeSettingSelected = this.state.addedRows[0] && this.state.addedRows[0].settingAttributeText;
    } else if (this.state.editingRowIds && this.state.editingRowIds.length > 0) {
      const rowIndex = this.state.editingRowIds[0];
      attributeSettingSelected = (this.state.rowChanges && this.state.rowChanges[rowIndex]
        && this.state.rowChanges[rowIndex].settingAttributeText) || rows[rowIndex].settingAttribute;
    }
    const { clearAttributeValues } = this;
    // userID:
    // pccList:
    return (
      <EditCell
        {...props}
        attributeSettings={attributeSettings}
        attributeSettingSelected={attributeSettingSelected}
        getUsersIDAction={getUsersIDAction}
        getPccsAction={getPccsAction}
        userID={userID}
        pccList={pccList}
        spSettings={spSettings}
        clearAttributeValues={clearAttributeValues}
        countries={countries}
        regions={regions}
        groups={groups}
        smartpointSettingChange={this.state.smartpointSettingChange}
        smartpointSettingChangeAction={() => this.setState({ smartpointSettingChange: true })}
        getSettingValuesAction={getSettingValuesAction}
        settingsValue={settingsValue}
      />
    );
  }

  handlerDownloadExcel = (e) => {
    e.preventDefault();
    this.props.downloadExcel(this.state.filter);
  }

  render() {
    const { rows, loading } = this.props;

    return (
      <>
        <LoaderComponent show={loading} />
        <h2>Smartpoint Desktop Configuration Settings</h2>
        <div className="container">
          <div className="row">
            <Link to="/admin/home">&lt; Back to Administration</Link>
          </div>
          <div className="row sp-versions-div">
            <div className="row">
              <div className="col-sm-3">
                <div className="col-sm-8">
                  <input type="text" className="text-input text-left" value={this.state.searchText} onChange={this.searchHandler} placeholder="Search" />
                </div>
                <div className="col-sm-3 col-sm-offset-1">
                  <Link to="#" className="action-button" onClick={this.fetchSearchResult}>Search</Link>
                </div>
              </div>
              <div className="col-sm-3 col-sm-offset-6 text-right">
                {
                  rows.length > 0
                  && (
                    <div className="row text-right">
                      <ExcelDownloadPresentation onClick={(e) => this.handlerDownloadExcel(e)} />
                    </div>
                  )
                }
                <div>
                  <Link to={`/admin/auditLog?id=${0}&category=spconfig`} className="k-primary k-button">Audit Log</Link>
                </div>
              </div>
            </div>
            <div className="travelport-table travelport-table-sticky tablesorter tablesorter-travelport tablesorter-odd-even remove-word-wrap">
              <Grid
                rows={rows}
                columns={this.state.columns}
              >
                <VirtualTable height={400} cellComponent={this.Cell} />
                <PagingState
                  currentPage={this.state.paging.currentPage}
                  pageSize={this.state.paging.pageSize}
                  onCurrentPageChange={this.pageHandler}
                />
                <EditingState
                  addedRows={this.state.addedRows}
                  rowChanges={this.state.rowChanges}
                  editingRowIds={this.state.editingRowIds}
                  deletedRowIds={this.state.deletingRows}
                  onEditingRowIdsChange={this.changeEditingRowIds}
                  onDeletedRowIdsChange={this.deletedRowIdsChange}
                  onAddedRowsChange={(addedRows) => this.changeAddedRows(addedRows)}
                  onRowChangesChange={(rowChanges) => this.changeRowChanges(rowChanges)}
                  onCommitChanges={this.commitChanges}
                />
                <CustomPaging
                  totalCount={this.props.smartPointConfigTotalPages * this.state.paging.pageSize}
                />
                <SortingState
                  sorting={this.sorting}
                  onSortingChange={this.sortHandler}
                  columnExtensions={this.sortingStateColumnExtensions}
                />
                <TableColumnResizing
                  columnWidths={this.state.columnWidths}
                />
                <TableEditRow
                  cellComponent={this.EditCell}
                />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={100}
                  showAddCommand
                  showEditCommand
                  showDeleteCommand
                  commandComponent={this.command}
                />
                <Getter
                  name="tableColumns"
                  computed={({ tableColumns }) => [
                    ...tableColumns.filter(c => c.type !== 'editCommand'),
                    { key: 'editCommand', type: 'editCommand', width: 140 }]}
                />
                <PagingPanel pageSizes={[]} />
                <Toolbar />
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SmartpointConfigSettings.propTypes = {
  rows: PropTypes.array,
  getInitialDataAction: PropTypes.func,
  onSaveAction: PropTypes.func,
  loading: PropTypes.bool,
  attributeSettings: PropTypes.array,
  userID: PropTypes.array,
  pccList: PropTypes.array,
  getUsersIDAction: PropTypes.func,
  getPccsAction: PropTypes.func,
  spSettings: PropTypes.array,
  onDeleteAction: PropTypes.func,
  toggleActivationAction: PropTypes.func,
  smartPointConfigTotalPages: PropTypes.number,
  getSettingValuesAction: PropTypes.func,
  downloadExcel: PropTypes.func,
  settingsValue: PropTypes.object,
  countries: PropTypes.array,
  regions: PropTypes.array,
  groups: PropTypes.array
};

SmartpointConfigSettings.defaultProps = {
  rows: [],
  loading: true,
};

const mapStateToProps = state => ({
  rows: getSettingsSelector(state),
  spSettings: getSmartpointSettingsSelector(state),
  attributeSettings: getSettingsAttributeSelector(state),
  userID: getUserIDSelector(state),
  pccList: getPccSelector(state),
  countries: getCountriesSelector(state),
  settingsValue: getSettingsValueSelector(state),
  regions: getRegionsSelector(state),
  groups: getGroupsSelector(state),
  loading: state.administration.smartpointConfigSettings.loading,
  smartPointConfigTotalPages: smartPointConfigTotalPages(state)
});

const mapDispatchToProps = dispatch => ({
  getInitialDataAction: (args) => dispatch(getInitialData(args)),
  getUsersIDAction: (args) => dispatch(getUsersID.base(args)),
  getPccsAction: (args) => dispatch(getPccs.base(args)),
  onSaveAction: (args) => dispatch(onSave.base(args)),
  onDeleteAction: (args) => dispatch(onDelete.base(args)),
  toggleActivationAction: (args) => dispatch(toggleActivation.base(args)),
  downloadExcel: (args) => dispatch(exportSmartConfiguration.base(args)),
  getSettingValuesAction: (args) => dispatch(getSettingValues.base(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartpointConfigSettings);
