/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  TableHeaderRow,
  Table,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  PagingState,
  CustomPaging,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  manageLicensesSearch
} from 'reducers/modules/Administration/ManageLicenses/ManageLicenseSearch/manageLicenseSearch';
import {
  manageLicensesSearchAgenciesSelector
} from 'reducers/modules/Administration/ManageLicenses/selectors';
import DataCells from './TableComponents/DataCell';

class AgenciesTable extends Component {
  constructor(props) {
    super(props);
    this.columnDefinitions = [
      { name: 'agency', title: 'AGENCY' },
      { name: 'customerNumber', title: 'CUSTOMER #' },
      { name: 'totalPlugins', title: 'TOTAL PLUG-INS' },
      { name: 'action', title: '' }
    ];
    this.sortingStateColumnExtensions = [
      { columnName: 'action', sortingEnabled: false },
    ];

    this.state = {
      sorting: {
        columnName: '',
        direction: 'asc',
      },
      paging: {
        currentPage: 0,
        pageSize: 10,
      },
    };
  }

  fetchAgencies = (event) => {
    if (event) {
      event.preventDefault();
    }
    let sortColumn = this.state.sorting.columnName;
    sortColumn = sortColumn && sortColumn[0].toUpperCase() + sortColumn.slice(1);
    const params = {
      searchAgencies: true,
      columnSort: sortColumn,
      ascending: this.state.sorting.direction === 'asc',
      page: this.state.paging.currentPage + 1
    };
    this.props.manageLicensesSearch(params);
  }

  pageHandler = (currentPage) => this.setState({ paging: { ...this.state.paging, currentPage } }, this.fetchAgencies);

  sortHandler = (newSorting) => this.setState({ sorting: { ...newSorting[0] } }, this.fetchAgencies);

  render() {
    return (
      <div className="row space-top">
        <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
          <Grid columns={this.columnDefinitions} rows={this.props.agencies}>
            <PagingState
              currentPage={this.state.paging.currentPage}
              pageSize={this.state.paging.pageSize}
              onCurrentPageChange={this.pageHandler}
            />
            <CustomPaging
              totalCount={this.props.totalPages * this.state.paging.pageSize}
            />

            <SortingState
              sorting={this.sorting}
              onSortingChange={this.sortHandler}
              columnExtensions={this.sortingStateColumnExtensions}
            />

            <Table cellComponent={DataCells} />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={[]} />
          </Grid>
        </div>
      </div>
    );
  }
}

AgenciesTable.propTypes = {
  agencies: PropTypes.array,
  totalPages: PropTypes.number,
  manageLicensesSearch: PropTypes.func
};

const mapStateToProps = state => ({
  agencies: manageLicensesSearchAgenciesSelector(state).data,
  totalPages: manageLicensesSearchAgenciesSelector(state).totalPages
});

const mapDispatchToProps = dispatch => ({
  manageLicensesSearch: params => dispatch(manageLicensesSearch.base(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesTable);
