import * as actionTypes from '../../../actions/actionType';

const productRatingReducer = (state = { data: {} }, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_APP_RATING:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };
    case actionTypes.LOAD_APP_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null
      };
    case actionTypes.LOAD_APP_RATING_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default productRatingReducer;
