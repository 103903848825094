import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from '@atlas-design-system/react';
import { withRouter } from 'react-router-dom';
import ConfDeleteModal from '../../common/MPModal/ConfDeleteModal';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import RatingPresentation from './RatingPresentation';
import ReviewsListPresentation from './ReviewsListPresentation';
import SubmitReviewPresentation from './SubmitReviewPresentation';
import {
  saveProductReview, saveProductReviewSuccess, saveProductReviewFailure,
  deleteProductReview, deleteProductReviewSuccess, deleteProductReviewFailure,
  productRatingLoad, productRatingLoadSuccess, productRatingLoadFailure,
  productReviewsLoad, productReviewsLoadSuccess, productReviewsLoadFailure
} from '../../../actions/Product/reviewsAndRatings.Actions';
import { setReviewState } from '../../../reducers/Product/Product/productReducers';
import { saveReviewsReply } from '../../../reducers/Product/Ratings&Reviews/productReviewsReducer';

import * as utils from '../../../utils/Common.Utils';
import * as Config from '../../../config/apiConfig';

class ProductRatingAndReviews extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showConfDeleteModal: false,
      errorMessage: '',
      showMessage: false,
      selectedReview: null,
      replyForm: false,
      selectedReply: null
    };
    this.onDeleteReview = this.onDeleteReview.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);

    this.props.history.listen((location) => {
      if (location.search === '?actionName=createorupdate') {
        this.actionTrigger(this.props.rating.reviewButtonAction);
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.reviewsState === 'createOrEdit' && nextProps.rating && nextProps.rating.reviewButtonAction) {
      this.actionTrigger(nextProps.rating.reviewButtonAction);
    }
  }

  onCancelCreate() {
    this.props.setReviewState('view');
  }

  onDeleteReview(review) {
    this.setState({ selectedReview: review, showConfDeleteModal: true });
  }

  onConfDeleteReview() {
    const review = this.state.selectedReview;
    this.props.deleteProductReview();
    const deleteReviewResponse = utils.Service('DELETE', `${Config.BASE_URL}${Config.DELETE_PRODUCT_REVIEW}/${review.id}`);
    deleteReviewResponse.then(() => {
      this.props.deleteProductReviewSuccess();
      this.errorOrSuccessMessage('Review has been deleted successfully.');
      this.getRatingsAndReviews();
    }).catch((error) => {
      this.props.deleteProductReviewFailure(error);
      this.errorOrSuccessMessage('Some error occured. Please try again.');
    });
    this.setState({ showConfDeleteModal: false });
  }

  onSubmitReview(review) {
    if (review.rating === 0) {
      this.showMessage('Please select Rating');
    } else {
      const param = review;
      let successMessage = 'Review has been created successfully.';
      if (review.id) {
        successMessage = 'Review has been updated successfully.';
      } else {
        param.id = 0;
      }
      this.props.saveProductReview();
      const saveReviewResponse = utils.Service('POST', `${Config.BASE_URL}${Config.SAVE_PRODUCT_REVIEW}`, param);
      saveReviewResponse.then(() => {
        this.props.saveProductReviewSuccess();
        this.errorOrSuccessMessage(successMessage);
        this.props.setReviewState('view');
        this.getRatingsAndReviews();
      }).catch((error) => {
        this.props.saveProductReviewFailure(error);
        this.errorOrSuccessMessage('Some error occured. Please try again.');
        this.props.setReviewState('view');
      });
    }
  }

  getRatingsAndReviews() {
    this.props.productRatingLoad();
    const ratingResonse = utils.GetService(`${Config.BASE_URL}${Config.GET_APP_RATING_DETAILS}?appId=${this.props.productPublicId}`);
    ratingResonse.then((res) => {
      this.props.productRatingLoadSuccess(res);
    }).catch((error) => {
      this.props.productRatingLoadFailure(error);
    });
    this.props.productReviewsLoad();
    const reviewsResonse = utils.GetService(`${Config.BASE_URL}${Config.GET_APP_REVIEWS}?appId=${this.props.productPublicId}`);
    reviewsResonse.then((res) => {
      this.props.productReviewsLoadSuccess(res);
    }).catch((error) => {
      this.props.productReviewsLoadFailure(error);
    });
  }

  replySuccessHandler = () => {
    this.setState({ replyForm: false });
  }

  submitReply = (reply) => {
    this.props.saveReviewsReply(reply, this.props.productPublicId, this.replySuccessHandler);
  }

  errorOrSuccessMessage(message) {
    toast.warning(message, {
      id: message.replaceAll(' ', '-'),
      position: 'top-center',
      showProgressBar: true
    });
  }

  showMessage(message) {
    this.setState({ errorMessage: message, showMessage: true });
    setTimeout(() => {
      this.setState({ showMessage: false });
    }, 3000);
  }

  actionTrigger(reviewButtonAction) {
    if (reviewButtonAction.toUpperCase() === 'NOTPURCHASEDPOPUP') {
      this.showMessage('Your organization has not purchased this product, so you are not eligible to submit a product review at this time.');
      this.props.setReviewState('view');
    } else if (reviewButtonAction.toUpperCase() === 'SHOWLOGINMODAL') {
      sessionStorage.setItem('lastUsedPathName', `${window.location.pathname}${window.location.search}`);
      window.location = `${Config.BASE_URL}login`;
    } else if (reviewButtonAction.toUpperCase() === 'NOTAVAILABLE') {
      this.props.setReviewState('view');
      this.errorOrSuccessMessage('Functionality not yet available for Travelport employees.');
    } else if (reviewButtonAction.toUpperCase() === 'CREATEREVIEW') {
      this.props.setReviewState('create');
    } else if (reviewButtonAction.toUpperCase() === 'EDITREVIEW') {
      this.props.setReviewState('edit');
    }
  }

  render() {
    if (this.props.loading) {
      return <LoaderComponent show />;
    }
    const showReviewList = this.props.reviewsState === 'view';
    const showEditReview = this.props.reviewsState === 'edit';
    const showCreateReview = this.props.reviewsState === 'create'
      && this.props.rating.reviewButtonAction && this.props.rating.reviewButtonAction.toUpperCase() === 'CREATEREVIEW';
    const newReview = {
      id: null,
      productID: this.props.productId,
      productReview: '',
      rating: 0,
      reviewTitle: ''
    };
    const userOwnReview = this.props.reviews.find((review) => review.isCurrentUser);
    return (
      <div className="row no-space-bottom">
        {this.state.showMessage
          && toast.warning(this.state.errorMessage, {
            id: this.state.errorMessage.replaceAll(' ', '-'),
            position: 'top-center',
            showProgressBar: true
          })}
        <RatingPresentation
          rating={this.props.rating}
          onTriggerReviewAction={() => this.actionTrigger(this.props.rating.reviewButtonAction)}
        />
        {showReviewList
          && (
            <ReviewsListPresentation
              reviews={this.props.reviews}
              canUserAccessRatingLinks={this.props.canUserAccessRatingLinks}
              isNotAvailable={this.props.rating.reviewButtonText && this.props.rating.reviewButtonAction.toUpperCase() === 'NOTAVAILABLE'}
              onTriggerReviewAction={() => this.actionTrigger(this.props.rating.reviewButtonAction)}
              onDeleteReview={this.onDeleteReview}
              onEditReview={() => this.props.setReviewState('edit')}
              replyForm={this.state.replyForm}
              onSubmit={(reply) => this.submitReply(reply)}
              cancel={() => { this.setState({ replyForm: false }); }}
              onReplyClick={(reviewId) => { this.setState({ replyForm: true, selectedReply: reviewId }); }}
              reviewId={this.state.selectedReply}
            />
          )}
        {showCreateReview
          && (
            <SubmitReviewPresentation
              review={newReview}
              onSubmitReview={this.onSubmitReview}
              onCancelCreate={() => this.onCancelCreate()}
            />
          )}
        {showEditReview
          && (
            <SubmitReviewPresentation
              review={userOwnReview}
              onSubmitReview={this.onSubmitReview}
              onCancelCreate={() => this.onCancelCreate()}
            />
          )}
        <ConfDeleteModal
          text="Are you sure you want to delete the reviews?"
          showModal={this.state.showConfDeleteModal}
          onHideModal={() => this.setState({ showConfDeleteModal: false })}
          onDelete={() => this.onConfDeleteReview()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rating: state.product.rating.data,
  reviews: state.product.reviews.reviewList.data,
  loading: state.product.rating.loading === undefined
    || state.product.reviews.reviewList.loading === undefined
    || state.product.rating.loading
    || state.product.reviews.reviewList.loading
    || state.product.reviews.save.loading
    || state.product.reviews.remove.loading,
  reviewsState: state.product.details.reviewsState
});

const mapDispatchToProps = (dispatch) => ({
  saveProductReview: bindActionCreators(saveProductReview, dispatch),
  saveProductReviewSuccess: bindActionCreators(saveProductReviewSuccess, dispatch),
  saveProductReviewFailure: bindActionCreators(saveProductReviewFailure, dispatch),
  deleteProductReview: bindActionCreators(deleteProductReview, dispatch),
  deleteProductReviewSuccess: bindActionCreators(deleteProductReviewSuccess, dispatch),
  deleteProductReviewFailure: bindActionCreators(deleteProductReviewFailure, dispatch),
  productRatingLoad: bindActionCreators(productRatingLoad, dispatch),
  productRatingLoadSuccess: bindActionCreators(productRatingLoadSuccess, dispatch),
  productRatingLoadFailure: bindActionCreators(productRatingLoadFailure, dispatch),
  productReviewsLoad: bindActionCreators(productReviewsLoad, dispatch),
  productReviewsLoadSuccess: bindActionCreators(productReviewsLoadSuccess, dispatch),
  productReviewsLoadFailure: bindActionCreators(productReviewsLoadFailure, dispatch),
  setReviewState: bindActionCreators(setReviewState, dispatch),
  saveReviewsReply: (args, publicId, replySuccessHandler) => dispatch(saveReviewsReply.base(args, publicId, replySuccessHandler))
});

ProductRatingAndReviews.propTypes = {
  rating: PropTypes.object,
  reviews: PropTypes.array,
  canUserAccessRatingLinks: PropTypes.bool,
  reviewsState: PropTypes.string,
  productId: PropTypes.number,
  productPublicId: PropTypes.string,
  saveProductReview: PropTypes.func,
  saveProductReviewSuccess: PropTypes.func,
  saveProductReviewFailure: PropTypes.func,
  deleteProductReview: PropTypes.func,
  deleteProductReviewSuccess: PropTypes.func,
  deleteProductReviewFailure: PropTypes.func,
  productRatingLoad: PropTypes.func,
  productRatingLoadSuccess: PropTypes.func,
  productRatingLoadFailure: PropTypes.func,
  productReviewsLoad: PropTypes.func,
  productReviewsLoadSuccess: PropTypes.func,
  productReviewsLoadFailure: PropTypes.func,
  loading: PropTypes.bool,
  setReviewState: PropTypes.func,
  history: PropTypes.object,
  saveReviewsReply: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductRatingAndReviews));
