import React, { Component } from 'react';
import { React_Bootstrap_Carousel as ReactBootstrapCarousel } from 'react-bootstrap-carousel';
import PropTypes from 'prop-types';
import CarouselItemBox from './CarouselItemBox';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  slideNext = () => {
    this.slider.slideNext();
  }

  slidePrev = () => {
    this.slider.slidePrev();
  }

  structurizeCarouselItem = (carouselItems, isLargeView) => {
    const items = [];
    const itemsCountToDisplay = isLargeView ? 3 : 1;
    for (let i = 0; i < carouselItems.length / itemsCountToDisplay; i += 1) {
      const splittedCarouselItems = [];
      for (let j = 0; j < itemsCountToDisplay; j += 1) {
        const index = (i * itemsCountToDisplay) + j;
        const carouselItem = carouselItems[index];
        splittedCarouselItems.push(
          <CarouselItemBox
            key={index}
            componentClassName={carouselItem.componentClassName}
            imageFile={carouselItem.imageFile.default}
            heading={carouselItem.heading}
            description={carouselItem.description}
            navigationPath={carouselItem.navigationPath}
            buttonText={carouselItem.buttonText}
            isLargeView={isLargeView}
          />
        );
      }
      const itemClassName = i === 0 ? 'item active' : 'item';
      items.push(
        <div className={itemClassName} key={i}>
          <div className="row">
            {
              splittedCarouselItems.map((splittedCarouselItem) => (splittedCarouselItem))
            }
          </div>
        </div>
      );
    }
    return items;
  }

  render() {
    const leftIcon = <span className="sr-only">Previous</span>;
    const rightIcon = <span className="sr-only">Next</span>;
    const carouselItems = this.structurizeCarouselItem(
      this.props.carouselItems, this.props.isLargeView
    );
    const parentDivId = this.props.isLargeView ? 'carousel-home-large' : 'carousel-home-small';
    const parentDivClassName = this.props.isLargeView ? 'carousel slide large-view-display lazy' : 'carousel slide small-view-display lazy';

    return (
      <div id={parentDivId} className={parentDivClassName}>
        <div className="carousel-inner" role="listbox">
          <ReactBootstrapCarousel
            animation
            slideshowSpeed={10000}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            indicators={false}
            ref={r => { this.slider = r; }}
            className="carousel-fade"
          >
            {
              carouselItems.map((item) => (item))
            }
          </ReactBootstrapCarousel>
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  carouselItems: PropTypes.array.isRequired,
  isLargeView: PropTypes.bool
};

export default Carousel;
