import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Table, TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';

import UserNameCell from './UserNameCell';

class EditCell extends Component {
  render() {
    switch (this.props.column.name) {
      case 'roles': {
        // kind of bad implementation due using simple select but having array of values
        const selectedOption = this.props.value[0] || [{}];
        const onChange = value => this.props.onValueChange([value]);
        const optionList = this.props.options.filter(o => o.value !== 0);
        return (
          <td>
            <Select
              closeOnSelect={false}
              removeSelected={false}
              value={selectedOption}
              options={optionList}
              labelKey="text"
              onChange={onChange}
              className="travelport-select"
            />
          </td>
        );
      }
      case 'email': {
        return <UserNameCell {...this.props} />;
      }
      case 'name':
        return <Table.Cell {...this.props} />;
      case 'groups':
        return <Table.Cell {...this.props} />;
      default:
        return <TableEditRow.Cell {...this.props} />;
    }
  }
}

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array
};

const mapStateToProps = (state) => ({
  options: state.agencyUser.meta.roleSet
});

export default connect(mapStateToProps)(EditCell);
