import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BulletDetails extends Component {
  render() {
    return (
      <li>
        <span>
          <strong>
            {this.props.name}
            :
          </strong>
          {' '}
          {this.props.children}
        </span>
      </li>
    );
  }
}

BulletDetails.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any
};

export default BulletDetails;
