/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import { Tabs } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import SpCloudUrl from './SpCloudUrls/SpCloudUrl';
import SpCloudPlugin from './SpCloudPlugins/SpCloudPlugin';
import '@atlas-design-system/core/dist/styles/partials/tabs.css';

class AddSPCSolution extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            defaultTab: '0',
            spCloudPlugin: null,
            spCloudUrl: <SpCloudUrl appId={this.props.appId} appName={this.props.appName} masterCustomerNumber={this.props.masterCustomerNumber} />
        };
    }

    handleTabSelect = (key) => {
        // window.location.reload();
        this.setState({ spCloudUrl: null });
        this.setState({ spCloudPlugin: null });
        if (parseInt(key) !== 2) {
            this.setState({ defaultTab: key });
        }
        if (key === '0') {
            this.setState({ spCloudUrl: <SpCloudUrl appId={this.props.appId} appName={this.props.appName} masterCustomerNumber={this.props.masterCustomerNumber} /> });
        }
        if (key === '1') {
            this.setState({ spCloudPlugin: <SpCloudPlugin appId={this.props.appId} appName={this.props.appName} isPublished={this.props.isPublished} /> });
        }
    }

    render() {
        return (
            <div className="container" style={{ marginTop: '-15px' }}>
                <Tabs activeKey={this.state.defaultTab} id="spc-tabs" onChange={(e) => this.handleTabSelect(e)}>
                    <Tabs.Tab title="Add your Smartpoint Cloud App">
                        <div style={{ marginTop: '15px' }}>
                            {this.state.spCloudUrl}
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab title="Submit for Approval">
                        <div style={{ marginTop: '15px' }}>
                        {this.state.spCloudPlugin}
                        </div>
                    </Tabs.Tab>
                </Tabs>
            </div>
        );
    }
}
AddSPCSolution.propTypes = {
    appId: PropTypes.number,
    appName: PropTypes.string,
    isPublished: PropTypes.bool,
    masterCustomerNumber: PropTypes.string,
};

export default AddSPCSolution;
