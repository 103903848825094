import {
  takeLatest, call, put, race, take, select
} from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import ApiClient from 'Services/ApiClient';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import {
  CONFIRMATION_ACCEPTED, CONFIRMATION_REJECTED, showConfirmationModal,
} from 'reducers/popUps/confirmationModalReducer';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import { roleNameConstants } from 'utils/rolesHelper';

const FETCH_USER_ROLES_URL = 'superAdmin/getusers';
const UPDATE_USER_ROLES_URL = 'superAdmin/updateuserroles';
const DELETE_USER_URL = 'superAdmin/deleteuser';
const EXPORT_TO_EXCEL_URL = 'superAdmin/GetExcelReportForUserRoles';

const FETCH_USER_ROLES = createFetchTypes('Marketplace/Administration/userRoles/FETCH_USER_ROLES');
const UPDATE_USER_ROLES = createFetchTypes('Marketplace/Administration/userRoles/UPDATE_USER_ROLES');
const DELETE_USER = createFetchTypes('Marketplace/Administration/userRoles/DELETE_USER');
DELETE_USER.ASSIGNED_PLUGINS_FAILURE = 'Marketplace/Administration/userRoles/DELETE_USER/ASSIGNED_PLUGINS_FAILURE';
const EXPORT_TO_EXCEL = createFetchTypes('Marketplace/Administration/userRoles/EXPORT_TO_EXCEL');
const ASSOCIATED_PLUGIN_MODAL_VISIBILITY = 'Marketplace/Administration/userRoles/ASSOCIATED_PLUGIN_MODAL_VISIBILITY';

export const userRolesSelector = state => state.administration.userRoles;
export const rolesSelector = state => state.administration.userRoles && state.administration.userRoles.roles;
export const deleteUserErrorSelector = state => state.administration.userRoles && state.administration.userRoles.deleteUserError;
const fetchParamsSelector = state => state.administration.userRoles.fetchParams;
const currentUserId = state => state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.Id;
const currentUserRole = state => state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role;

function userRoles(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_USER_ROLES.BASE:
      return {
        ...state,
        fetchParams: action.params
      };
    case FETCH_USER_ROLES.REQUEST:
    case UPDATE_USER_ROLES.REQUEST:
    case DELETE_USER.REQUEST:
    case EXPORT_TO_EXCEL.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_USER_ROLES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.users,
        totalPages: action.data.totalPages,
        error: null
      };
    case UPDATE_USER_ROLES.SUCCESS:
    case DELETE_USER.SUCCESS:
    case EXPORT_TO_EXCEL.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_USER_ROLES.FAILURE:
    case UPDATE_USER_ROLES.FAILURE:
    case EXPORT_TO_EXCEL.FAILURE:
    case DELETE_USER.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case DELETE_USER.ASSIGNED_PLUGINS_FAILURE:
      return {
        ...state,
        loading: false,
        deleteUserError: {
          ...action.data,
          userName: action.userName
        }
      };
    case ASSOCIATED_PLUGIN_MODAL_VISIBILITY:
      return {
        ...state,
        deleteUserError: {
          ...state.deleteUserError,
          showModal: action.isVisible
        }
      };
    default:
      return state;
  }
}

export const fetchUserRoles = {
  base: (params) => ({
    type: FETCH_USER_ROLES.BASE,
    params,
    url: FETCH_USER_ROLES_URL
  }),
  request: () => ({ type: FETCH_USER_ROLES.REQUEST }),
  success: (data) => ({ type: FETCH_USER_ROLES.SUCCESS, data }),
  failure: (error) => ({ type: FETCH_USER_ROLES.FAILURE, error })
};

export const updateUserRoles = {
  base: (params) => ({
    type: UPDATE_USER_ROLES.BASE,
    params,
    url: UPDATE_USER_ROLES_URL
  }),
  request: () => ({ type: UPDATE_USER_ROLES.REQUEST }),
  success: (data) => ({ type: UPDATE_USER_ROLES.SUCCESS, data }),
  failure: (error) => ({ type: UPDATE_USER_ROLES.FAILURE, error })
};

export const deleteUser = {
  base: (user) => ({
    type: DELETE_USER.BASE,
    user,
    url: DELETE_USER_URL
  }),
  request: () => ({ type: DELETE_USER.REQUEST }),
  success: (data) => ({ type: DELETE_USER.SUCCESS, data }),
  failure: (error) => ({ type: DELETE_USER.FAILURE, error }),
  assignedPluginsFailure: (data, userName) => ({ type: DELETE_USER.ASSIGNED_PLUGINS_FAILURE, data, userName }),
};

export const exportToExcel = {
  base: (params) => ({
    type: EXPORT_TO_EXCEL.BASE,
    params,
    url: EXPORT_TO_EXCEL_URL
  }),
  request: () => ({ type: EXPORT_TO_EXCEL.REQUEST }),
  success: (data) => ({ type: EXPORT_TO_EXCEL.SUCCESS, data }),
  failure: (error) => ({ type: EXPORT_TO_EXCEL.FAILURE, error })
};

export const associatedPluginModalAction = {
  show: () => ({ type: ASSOCIATED_PLUGIN_MODAL_VISIBILITY, isVisible: true }),
  hide: () => ({ type: ASSOCIATED_PLUGIN_MODAL_VISIBILITY, isVisible: false }),
};

function* fetchUserRolesSaga({ url, params }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(fetchUserRoles, ApiClient.httpClient.post, url, params));
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

function* updateUserRolesSaga({ params, url }) {
  const allUserRoles = (yield select(userRolesSelector)).data || [];
  const userHadSuperAdminRole = allUserRoles.find(u => u.id === params.userId).roles.find(r => r.id === roleNameConstants.MarketplaceTeamAdmin.id);
  const userHasSuperAdminRole = params.roleIds.find(r => r === roleNameConstants.MarketplaceTeamAdmin.id);
  const userHasAssignedTPAdminRole = params.roleIds.find(r => r === roleNameConstants.TPAdmin.id);
  const userHasTPAdminRoleAssigned = allUserRoles.find(u => u.id === params.userId).roles.find(r => r.id === roleNameConstants.TPAdmin.id);
  const loggedInUserId = yield select(currentUserId);
  const loggedInUserRole = yield select(currentUserRole);
  const isLoggedInUserSuperAdmin = (loggedInUserRole === roleNameConstants.MarketplaceTeamAdmin.name);
  if (params.userId.toString() === loggedInUserId.toString() && !userHasSuperAdminRole && userHadSuperAdminRole) {
    yield put(showAlertAction('Unable to change your individual role as an Admin'));
  } else if (!userHadSuperAdminRole && (userHasSuperAdminRole) && !isLoggedInUserSuperAdmin) {
    yield put(showAlertAction('Unable to assign Super Admin role'));
  } else if (userHadSuperAdminRole && !userHasSuperAdminRole && !isLoggedInUserSuperAdmin) {
    yield put(showAlertAction('Unable to remove Super Admin role'));
  } else if (loggedInUserRole === roleNameConstants.TPSupport.name && !userHasTPAdminRoleAssigned && userHasAssignedTPAdminRole) {
    yield put(showAlertAction('Unable to assign Travelport Admin role'));
  } else if (loggedInUserRole === roleNameConstants.TPSupport.name && !userHasAssignedTPAdminRole && userHasTPAdminRoleAssigned) {
    yield put(showAlertAction('Unable to remove Travelport Admin role'));
  } else if (params.roleIds.length < 1) {
    yield put(showAlertAction('Please select atleast one role for the user'));
  } else {
    yield put(showLoader());
    try {
      yield call(() => fetchEntity(updateUserRoles, ApiClient.httpClient.put, url, params));
      const fetchParams = yield select(fetchParamsSelector);
      yield call(() => fetchEntity(fetchUserRoles, ApiClient.httpClient.post, FETCH_USER_ROLES_URL, fetchParams));
      yield put(showAlertAction('Role(s) have been updated for the user.'));
    } catch (error) {
      put(showAlertAction(error));
    }
    yield put(hideLoader());
  }
}

function* deleteUserSaga({ user, url }) {
  const loggedInUserRole = yield select(currentUserRole);
  const userToBeDeletedHasAdminRoles = user.roles.find(r => r.id === roleNameConstants.TPAdmin.id || r.id === roleNameConstants.MarketplaceTeamAdmin.id);
  if (loggedInUserRole === roleNameConstants.TPSupport.name && userToBeDeletedHasAdminRoles) {
    yield put(showAlertAction('Support Role cannot delete users with TP Admin/ Super Admin roles'));
  } else {
    yield put(showConfirmationModal('Delete User', `Are you sure you want to delete ${user.userName} from Marketplace?`));
    const { accept } = yield race({ accept: take(CONFIRMATION_ACCEPTED), reject: take(CONFIRMATION_REJECTED) });
    if (accept) {
      yield put(showLoader());
      try {
        yield call(() => fetchEntity(deleteUser, ApiClient.httpClient.delete, url, { params: { id: user.id } }));
        const fetchParams = yield select(fetchParamsSelector);
        yield call(() => fetchEntity(fetchUserRoles, ApiClient.httpClient.post, FETCH_USER_ROLES_URL, fetchParams));
        yield put(showAlertAction('User has been deleted successfully.'));
      } catch (error) {
        if (error.response && error.response.data && error.response.data.associatedApplications) {
          yield put(deleteUser.assignedPluginsFailure(error.response.data, user.userName));
          yield put(associatedPluginModalAction.show());
        }
      }
      yield put(hideLoader());
    }
  }
}

function* exportToExcelSaga({ params, url }) {
  yield put(showLoader());
  try {
    yield put(exportToExcel.request());
    const response = yield call(ApiClient.httpClient.post, url, { ...params });
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      let filename = 'report.csv';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      FileDownload(response.data, filename);
    }
    yield put(exportToExcel.success());
  } catch (error) {
    put(showAlertAction(error));
  }
  yield put(hideLoader());
}

export function* userRolesMiddleware() {
  yield takeLatest(FETCH_USER_ROLES.BASE, fetchUserRolesSaga);
  yield takeLatest(UPDATE_USER_ROLES.BASE, updateUserRolesSaga);
  yield takeLatest(DELETE_USER.BASE, deleteUserSaga);
  yield takeLatest(EXPORT_TO_EXCEL.BASE, exportToExcelSaga);
}

export default userRoles;
