import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  DISTRIBUTION_CUSTOM_REQUEST,
  DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION,
  DISTRIBUTION_CUSTOM_PRICING_LOCATION,
} from 'constants/PPCP';
import SimplePrettyRadioButton from 'components/common/FormInputs/SimplePrettyRadioButton/SimplePrettyRadioButton';
import Tooltip from '../../../common/ToolTip';

const renderRadioButton = ({
  input,
  displayText,
  tooltip,
}) => (
  <SimplePrettyRadioButton
    {...input}
    displayText={displayText}
    onChange={input.onChange}
    tooltip={tooltip}
  />
);

const CustomPrice = () => (
  <>
    <div className="row">
      <div className="pull-left pad-right-sm">
        <Field
          id={DISTRIBUTION_CUSTOM_REQUEST}
          name="priceCustom"
          component={renderRadioButton}
          displayText="Price available upon request"
          tooltip="Selecting this option will add the text Price available upon request to the product page."
          type="radio"
          value={DISTRIBUTION_CUSTOM_REQUEST}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-sm-1">
        <Field
          id={DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION}
          name="priceCustom"
          component={renderRadioButton}
          type="radio"
          value={DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION}
        />
      </div>
      <div className="col-sm-11">
        <div className="pull-left pad-right-sm">
          <Field
            id={DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION}
            className="textarea small-textarea"
            maxLength="200"
            name="priceCustomDescription"
            component="textarea"
            cols="50"
            placeholder="Custom Pricing Description"
            value={DISTRIBUTION_CUSTOM_PRICING_DESCRIPTION}
          />
        </div>
        <div className="pad-left-sm pad-top-sm">
          <Tooltip labelName="Selecting this option will add the description you enter in the text box to the product page as the solution&apos;s price." />
        </div>
      </div>
    </div>
    <div className="row no-space-bottom">
      <div className="pull-left pad-right-sm">
        <Field
          id={DISTRIBUTION_CUSTOM_PRICING_LOCATION}
          name="priceCustom"
          component={renderRadioButton}
          displayText="Pricing by Location"
          tooltip="Selecting this option will allow you to set the displayed price of the solution based on the location of the user&#39;s Agency."
          type="radio"
          value={DISTRIBUTION_CUSTOM_PRICING_LOCATION}
        />
      </div>
    </div>
  </>
);

renderRadioButton.propTypes = {
  input: PropTypes.object,
  displayText: PropTypes.string,
  tooltip: PropTypes.string,
  ValueToBe: PropTypes.object
};

export default CustomPrice;
