/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Banner, AlertV2Icon, Link, ManualReverseIllustration
} from '@atlas-design-system/react';
import ToolTip from '../../../../common/ToolTip';
import { fetchGitHistory, fetchGitPluginLinkageDataForCert, saveGitUrl } from '../../../../../reducers/modules/SpCloudCertification/SpCloudCertification';
import dateHelper from '../../../../../utils/dateHelper';
import '@atlas-design-system/core/dist/styles/partials/buttons.css';
import '@atlas-design-system/core/dist/styles/partials/icons.css';
import '@atlas-design-system/core/dist/styles/partials/link.css';

class SpCloudUrl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUnCertifiedPlugin: true,
      url: '',
      branch: '',
      gitToken: '',
      gitOrganization: '',
      gitRepository: '',
      gitRef: '',
      errors: {
        gitRepositoryError: '',
        gitRefError: '',
        gitTokenError: '',
        gitOrganizationError: '',
      },
      isBannerError: false,
      isBannerSuccess: false,
      isBannerVisible: true
    };
  }

  componentDidMount() {
    const orgGuid = this.props.appId;
    this.props.fetchGitHistoryAction(orgGuid);
    this.props.fetchGitPluginLinkageForCertAction({ appId: orgGuid }, this.callbackplugin);
    window.scrollTo(0, 0);
  }

  handleBannerClose = () => {
    this.setState({
      isBannerVisible: false
    });
  };

  handleValidation() {
    let formIsValid = true;
    const error = {
      gitRepositoryError: '',
      gitRefError: '',
      gitTokenError: '',
      gitOrganizationError: '',
    };
    if (!this.state.gitRepository) {
      formIsValid = false;
      error.gitRepositoryError = 'Git Repository is a required field, Add one repository at a time';
    }
    if (!this.state.gitRef) {
      formIsValid = false;
      error.gitRefError = 'Git Ref is a required field';
    }
    if (!this.state.gitToken) {
      formIsValid = false;
      error.gitTokenError = 'Git Token is a required field';
    }
    if (!this.state.gitOrganization) {
      formIsValid = false;
      error.gitOrganizationError = 'Git Organization is a required field';
    }
    this.setState({ errors: error });
    return formIsValid;
  }

  callbackplugin = () => {
    if ((this.props.getValidatedPluginLinkageForCertList !== undefined) && (this.props.getValidatedPluginLinkageForCertList.currentVersion !== '') && (this.props.getValidatedPluginLinkageForCertList.currentVersion !== 'None') && (this.props.getValidatedPluginLinkageForCertList.plugins[0].isActive === false)) {
      this.setState({ isUnCertifiedPlugin: false });
    } else {
      this.setState({ isUnCertifiedPlugin: true });
    }
  }

  changeHandler = (e, params) => {
    this.setState({
      [params]: e.target.value
    });
  }

  callback = () => {
    const orgGuid = this.props.appId;
    this.props.fetchGitHistoryAction(orgGuid);
    if (this.props.historyListItem !== undefined) {
      this.setState({
        isBannerSuccess: true
      });
      this.setState({
        isBannerVisible: true
      });
    }
    if (this.props.errorList !== undefined) {
      this.setState({
        isBannerVisible: true
      });
    }
  }

  uploadGitUrl = () => {
    if (this.handleValidation()) {
      this.props.saveGitUrlAction(
        {
          gitToken: this.state.gitToken, gitOrganization: this.state.gitOrganization, gitRepository: this.state.gitRepository, gitRef: this.state.gitRef, email: this.props.email, appId: this.props.appId, masterCustomerNumber: this.props.masterCustomerNumber
        }, this.callback
      );
      this.setState({
        isBannerError: false
      });
      this.setState({
        gitToken: ''
      });
      this.setState({
        gitOrganization: ''
      });
      this.setState({
        gitRepository: ''
      });
      this.setState({
        gitRef: ''
      });
    } else {
      this.setState({
        isBannerError: true
      });
      this.setState({
        isBannerSuccess: false
      });
      this.setState({
        isBannerVisible: true
      });
    }
  }

  render() {
    const { historyList } = this.props;
    return (
      <>
        {!this.state.isUnCertifiedPlugin
          ? (
            <div
              style={{
                display: 'table', width: '100%', background: 'rgb(248, 248, 248)', height: '500px', minHeight: '0px!important'
              }}
            >
              <div className="sp-main" style={{ paddingLeft: '15px' }}>
                <div
                  style={{
                    textAlign: 'center', padding: '150px'
                  }}
                >
                  <ManualReverseIllustration
                    width="60px"
                    style={{
                      margin: 'auto', display: 'block', marginBottom: '12px'
                    }}
                  />
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <span>
                    <b>
                      your app &nbsp;
                      &quot;
                      {this.props.appName}
                      &quot;
                      &nbsp;
                      is currently being reviewed in our internal certification process
                    </b>
                  </span>
                  <span>
                    <p
                      style={{
                        paddingTop: '8px', fontSize: '11px'
                      }}
                    >
                      once this is complete, you will be able to upload another app
                    </p>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'table', width: '100%', marginBottom: '15px' }}>
              <div className="sp-header">
                <p style={{ marginLeft: '22px' }}>
                  <b>
                    {this.props.appName}
                  </b>
                </p>
              </div>
              <div className="sp-main" style={{ paddingLeft: '15px' }}>
                <div
                  className="left1"
                  style={{
                    width: '60%', height: '850px', float: 'left', background: 'white', padding: '10px'
                  }}
                >
                  <p>Add a Github repository for certification process.Note that only one repository is to be added at a time,If more than one repository is added it will fail the certification process.</p>
                  {(this.state.isBannerVisible) && (this.props.errorList === undefined) && (this.state.isBannerSuccess) && (
                    <Banner
                      appearance="success"
                      dismissable
                      id="sbBannerSucess"
                      className="spbanneralign"
                      onClose={() => this.handleBannerClose()}
                    >
                      <p>
                        {
                          (this.props.historyListItem !== undefined)
                            ? (
                              <p>
                                <p><span><b>Submitted</b></span></p>
                                <p><span>gitRepository URL submitted.We will be in touch soon by email for next steps.</span></p>
                              </p>
                            ) : ''
                        }
                      </p>
                    </Banner>
                  )}
                  {(this.state.isBannerVisible) && (this.state.isBannerError) && (
                    <Banner
                      appearance="error"
                      dismissable
                      id="sbBanner"
                      className="spbanneralign"
                      onClose={() => this.handleBannerClose()}
                    >
                      &nbsp;
                      {
                        Object.values(this.state.errors).filter(Boolean).length > 1
                          ? (
                            <ul style={{ marginTop: '-13px' }}>
                              <p style={{ marginLeft: '-30px' }}>
                                There are several errors in your form:
                              </p>
                              {Object.values(this.state.errors).map(element => (<li key={element} style={{ color: '#276bbe', marginBottom: '7px' }} hidden={element !== '' ? '' : 'hidden'}>{element}</li>))}
                            </ul>
                          )
                          : (Object.values(this.state.errors).map(element => (<p style={{ marginTop: '-18px' }}>{element}</p>)))
                      }
                    </Banner>
                  )}
                  {
                    (this.state.isBannerVisible) && (!this.state.isBannerError) && (this.props.errorList !== undefined)
                      ? (
                        <Banner
                          appearance="error"
                          dismissable
                          id="sbBannerError"
                          className="spbanneralign"
                          onClose={() => this.handleBannerClose()}
                        >
                          <p>
                            <p><span><b>Something went wrong.</b></span></p>
                            <p><span>Please try again later</span></p>
                          </p>
                        </Banner>
                      ) : ''
                  }
                  &nbsp;
                  &nbsp;
                  <form className="spcloud-form" autoComplete="false" style={{ marginBottom: '15px' }}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1" className={this.state.errors.gitRepositoryError !== '' ? 'required-field-star-spc error-text-label' : 'required-field-star-spc'}>Git Repository</label>
                      <input
                        type="text"
                        className={this.state.errors.gitRepositoryError !== '' ? 'form-control error-text' : 'form-control'}
                        id="form-gitRepository"
                        placeholder="GitRepository"
                        onChange={(e) => this.changeHandler(e, 'gitRepository')}
                        value={this.state.gitRepository}
                      />
                      <span className="error-text-label">
                        {
                          (this.state.errors.gitRepositoryError !== '') ? (
                            <p>
                              <span><AlertV2Icon width="15" fill="red" /></span>
                              &nbsp;
                              <span>{this.state.errors.gitRepositoryError}</span>
                            </p>
                          ) : ''
                        }
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1" className={this.state.errors.gitRefError !== '' ? 'required-field-star-spc-tooltip error-text-label' : 'required-field-star-spc-tooltip'}>
                        Git Ref
                        {' '}
                        <ToolTip labelName="A branch name,tag or SHA reference will be accepted." />
                      </label>
                      <input
                        type="text"
                        className={this.state.errors.gitRefError !== '' ? 'form-control error-text' : 'form-control'}
                        id="form-gitRef"
                        placeholder="GitRef"
                        onChange={(e) => this.changeHandler(e, 'gitRef')}
                        value={this.state.gitRef}
                      />
                      <span className="error-text-label">
                        {
                          (this.state.errors.gitRefError !== '') ? (
                            <p>
                              <span><AlertV2Icon width="15" fill="red" /></span>
                              &nbsp;
                              <span>{this.state.errors.gitRefError}</span>
                            </p>
                          ) : ''
                        }
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1" className={this.state.errors.gitTokenError !== '' ? 'required-field-star-spc error-text-label' : 'required-field-star-spc'}>Git Token</label>
                      <input
                        type="text"
                        className={this.state.errors.gitTokenError !== '' ? 'form-control error-text' : 'form-control'}
                        id="form-gitToken"
                        placeholder="GitToken"
                        onChange={(e) => this.changeHandler(e, 'gitToken')}
                        value={this.state.gitToken}
                      />
                      <span className="error-text-label">
                        {
                          (this.state.errors.gitTokenError !== '') ? (
                            <p>
                              <span><AlertV2Icon width="15" fill="red" /></span>
                              &nbsp;
                              <span>{this.state.errors.gitTokenError}</span>
                            </p>
                          ) : ''
                        }
                      </span>
                      <p>
                        If you don&apos;t have a gitToken, check this
                        &nbsp;
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#creating-a-fin[…]al-access-token"
                          style={{
                            textDecoration: 'underline'
                          }}
                        >
                          documentation
                        </Link>
                        &nbsp; about how to get one
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1" className={this.state.errors.gitOrganizationError !== '' ? 'required-field-star-spc error-text-label' : 'required-field-star-spc'}>Git Organization</label>
                      <input
                        type="text"
                        className={this.state.errors.gitOrganizationError !== '' ? 'form-control error-text' : 'form-control'}
                        id="form-gitOrganization"
                        placeholder="GitOrganization"
                        onChange={(e) => this.changeHandler(e, 'gitOrganization')}
                        value={this.state.gitOrganization}
                      />
                      <span className="error-text-label">
                        {
                          (this.state.errors.gitOrganizationError !== '') ? (
                            <p>
                              <span><AlertV2Icon width="15" fill="red" /></span>
                              &nbsp;
                              <span>{this.state.errors.gitOrganizationError}</span>
                            </p>
                          ) : ''
                        }
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Email
                        {' '}
                        <ToolTip labelName="The email associated with this account is automatically assigned to the upload." />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="form-email"
                        placeholder="Email"
                        disabled
                        value={this.props.email}
                      />
                    </div>
                    <div className="btn-row">
                      <Button type="button" appearance="primary" className="btn-sp-cloud" onClick={this.uploadGitUrl}>Submit</Button>
                    </div>
                  </form>
                  <p>
                    To find out more about the Smartpoint Cloud App process
                    &nbsp;
                    <Link
                      href="https://google.com"
                      target="_blank"
                      style={{
                        textDecoration: 'underline'
                      }}
                    >
                      click here
                    </Link>
                  </p>
                </div>
                <div
                  className="right1"
                  style={{
                    width: '40%', height: '500px', float: 'left', background: 'white', borderLeft: '1px solid #b1b1b1', padding: '0 10px'
                  }}
                >
                  <p
                    style={{
                      background: '#f8f8f8', margin: '0px', height: '30px', padding: '3px'
                    }}
                  >
                    <b>Submission History</b>
                  </p>
                  <hr className="brdr" />
                  <div
                    className="history-box"
                    style={{
                      overflow: 'auto', height: '450px', background: '#f8f8f8', fontSize: '13px'
                    }}
                  >
                    {!historyList || historyList.length === 0 ? 'No record found' : historyList.map(item => (
                      <div className="his" key={item.reqId} style={{ padding: '5px' }}>
                        <p className={item.reqStatus === 'Success' ? 'green-msg' : 'red-msg'}>
                          <b>{item.reqStatus === 'Success' ? 'Submitted' : 'Failed'}</b>
                        </p>
                        <p>
                          <span>{item.githubUrl}</span>
                        </p>
                        <p>
                          <span>{dateHelper.getTimeAmPm(item.createdDt)}</span>
                        </p>

                        {
                          (item.certificationId === 'xxxx')
                            ? <p><span>{item.errMsg}</span></p>
                            : (
                              <p>
                                <span>Certification ID:</span>
                                <span>
                                  &nbsp;
                                  {item.certificationId === 'xxxx' ? '' : item.certificationId}
                                </span>
                              </p>
                            )
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

SpCloudUrl.propTypes = {
  fetchGitHistoryAction: PropTypes.func,
  fetchGitPluginLinkageForCertAction: PropTypes.func,
  match: PropTypes.object,
  historyList: PropTypes.array,
  saveGitUrlAction: PropTypes.func,
  email: PropTypes.string,
  appId: PropTypes.number,
  appName: PropTypes.string,
  masterCustomerNumber: PropTypes.string,
};

const mapStateToProps = state => ({
  historyList: state.gitHistory.historyList,
  historyListItem: state.gitHistory.historyListItem,
  getValidatedPluginLinkageForCertList: state.gitHistory.getValidatedPluginLinkageDataForCert,
  errorList: state.gitHistory.errorList,
  email: state.oidc.user ? state.oidc.user.profile.email : ''
});

const mapDispatchToProps = dispatch => ({
  fetchGitHistoryAction: args => dispatch(fetchGitHistory(args)),
  fetchGitPluginLinkageForCertAction: (args, callbackplugin) => dispatch(fetchGitPluginLinkageDataForCert(args, callbackplugin)),
  saveGitUrlAction: (args, callback) => dispatch(saveGitUrl.base(args, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpCloudUrl);
