import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

class HeaderLink extends Component {
  handleClick = (e) => {
    if (this.props.disableLinks) {
      this.props.notifyDisableLinksAction(true);
      e.preventDefault();
    } else if (this.props.isPPCPFormModified) {
      this.props.goToRoutes(this.props.path);
      e.preventDefault();
    }
  }

  render() {
    return (
      <NavLink
        exact
        activeClassName="link-header-active"
        onClick={(e) => this.handleClick(e)}
        className="global-nav-item gtm-nav-item"
        to={this.props.path}
      >
        {this.props.linkText}
      </NavLink>
    );
  }
}

HeaderLink.propTypes = {
  path: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  disableLinks: PropTypes.bool,
  notifyDisableLinksAction: PropTypes.func,
  isPPCPFormModified: PropTypes.bool,
  goToRoutes: PropTypes.func
};

export default HeaderLink;
