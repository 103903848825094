import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getSolnGuideUrl } from 'reducers/modules/Application/index';
import { getSolnGuideUrlSelector } from 'reducers/modules/Application/selectors';

const CompletionIndicator = () => (
  <div id="create-product-progress" className="row bg-color-light pad-top text-center">
    <p><b>Useful Links</b></p>
    <div className="row">
      <a target="_blank" rel="noopener noreferrer" href="https://support.travelport.com/webhelp/TravelportMarketplace/Content/Developers.htm"><u>Developers Guide</u></a>
    </div>
    <div className="row">
      <a target="_blank" rel="noopener noreferrer" href="https://support.travelport.com/webhelp/TravelportMarketplace/Content/SolutionMangement.htm"><u>How To Upload Your Solution</u></a>
    </div>
  </div>
);
CompletionIndicator.propTypes = {
  percentageCompletion: PropTypes.number,
  completed: PropTypes.bool,
  info: PropTypes.object,
  history: PropTypes.object,
  getSolnGuideUrl: PropTypes.func,
  solnGuideUrlValue: PropTypes.string
};

CompletionIndicator.defaultProps = {
  percentageCompletion: 0,
  completed: false
};

const mapStateToProps = (state) => ({
  solnGuideUrlValue: getSolnGuideUrlSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getSolnGuideUrl: () => dispatch(getSolnGuideUrl.base())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompletionIndicator));
