import { put, call, takeLatest } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { roleNameConstants } from 'utils/rolesHelper';
import { BASE_URL } from 'config/apiConfig';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { userProfileNavigationAction } from 'reducers/modules/Auth/index';
import { checkRolePromotion } from 'reducers/modules/Auth/AutoUpgradeAgencyAdmin/AutoUpgradeAdminReducer';

const LOAD_USER_INFO = createFetchTypes('Marketplace/oAuth/LOAD_USER_INFO');
const LOG_OUT = createFetchTypes('Marketplace/oAuth/LOG_OUT');

const getUserProfileFromClaims = (claims) => ({
  FirstName: claims.firstName,
  LastName: claims.lastName,
  name: claims.userName,
  email: claims.email,
  Id: claims.id,
  role: claims.role && claims.role.name,
  OrganisationId: claims.organisationId,
  OrganisationName: claims.organisationName,
  CurrentRoleName: claims.currentRoleName,
  RightNowLogin: claims.rightNowLogin,
  IsTravelportEmployee: claims.isTravelportEmployee,
  ProfileImageUrl: claims.profileImageUrl,
  roleIds: claims.roleIds && claims.roleIds.sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1))
});

const oAuth = (state = {}, action = {}) => {
  switch (action.type) {
    case LOAD_USER_INFO.SUCCESS:
      if (action.payload.userInfo && action.payload.userInfo.userName) {
        return {
          ...state,
          user: {
            profile: getUserProfileFromClaims(action.payload.userInfo)
          }
        };
      }
      return state;
    default:
      return state;
  }
};

export const loadUserInfo = {
  base: (data) => ({
    type: LOAD_USER_INFO.BASE,
    url: 'getCurrentUser',
    data
  }),
  request: () => ({ type: LOAD_USER_INFO.REQUEST }),
  success: payload => ({ type: LOAD_USER_INFO.SUCCESS, payload }),
  failure: error => ({ type: LOAD_USER_INFO.FAILURE, error }),
};

export const logOut = {
  base: (data) => ({
    type: LOG_OUT.BASE,
    url: 'account/resetuserorgtobaseorg',
    data
  }),
};

export function* loadUserInfoSaga({ url, data }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(loadUserInfo, ApiClient.httpClient.get, url));
    // if data is not null, then it means it is coming from callback after login and we need to perform the below actions
    if (data) {
      yield put(userProfileNavigationAction.base(data.history, data.callbackRouter));
      if (data.callbackRouter !== '/Account/MyProfile?selectedTab=agencyHierarchy') {
        yield put(checkRolePromotion.base());
      }
    }
  } catch (error) {
    yield put(showAlertAction('Error encountered during login. Please try again.'));
    yield put(loadUserInfo.failure());
  }
  yield put(hideLoader());
}

export function* logOutSaga({ url, data }) {
  yield put(showLoader);
  try {
    const isValidUser = data && data.profile && !data.expired;
    const isSuperAgencyAdmin = isValidUser && (data.profile.role === roleNameConstants.AgencySuperAdmin.name);
    if (isSuperAgencyAdmin) {
      yield ApiClient.httpClient.post(url, {});
    }
    localStorage.isLoggingOut = true;
    localStorage.removeItem('buildNumber');
    window.location = `${BASE_URL}logout`;
  } catch (error) {
    localStorage.isLoggingOut = true;
    window.location = `${BASE_URL}logout`;
    yield put(showAlertAction('Error encountered during logout. Please try again.'));
  }
  yield put(hideLoader());
}

export function* watchLoadUserInfoSaga() {
  yield takeLatest(LOAD_USER_INFO.BASE, loadUserInfoSaga);
}

export function* watchLogOutSaga() {
  yield takeLatest(LOG_OUT.BASE, logOutSaga);
}

export default oAuth;
