/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProductReviewPresentation from './ProductReviewPresentation';

const ReviewsListPresentation = (props) => {
  const zeroReviewsSection = props.isNotAvailable
    ? <span>Functionality not yet available for Travelport employees</span>
    : (
      <span>
        Be the first person to submit a&nbsp;
        <Link onClick={props.onTriggerReviewAction} to="#">REVIEW</Link>
      </span>
    );
  return (
    <div className="col-sm-9 reviews" id="reviewsView">
      {props.reviews && props.reviews.length === 0
        && (
        <div className="row no-space review">
          {zeroReviewsSection}
        </div>
        )}
      {props.reviews
        && props.reviews.length > 0
        && props.reviews.map((review, i) => (
          <ProductReviewPresentation
            key={i.toString()}
            review={review}
            canUserAccessRatingLinks={props.canUserAccessRatingLinks}
            onDeleteReview={props.onDeleteReview}
            onEditReview={props.onEditReview}
            replyForm={props.replyForm}
            onReplyClick={(reviewId) => props.onReplyClick(reviewId)}
            onSubmit={(reply) => props.onSubmit({ ...review, reviewsReply: reply })}
            cancel={props.cancel}
            reviewId={props.reviewId}
          />
        ))}
    </div>
  );
};

ReviewsListPresentation.propTypes = {
  reviews: PropTypes.array,
  canUserAccessRatingLinks: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
  onTriggerReviewAction: PropTypes.func,
  onDeleteReview: PropTypes.func,
  onEditReview: PropTypes.func,
  replyForm: PropTypes.bool,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  onReplyClick: PropTypes.func,
  reviewId: PropTypes.number
};

export default ReviewsListPresentation;
