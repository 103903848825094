/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { Modal } from '@atlas-design-system/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '../../common/ToolTip';
import { hideRolePromoteModal } from '../../../reducers/modules/Auth/index';

class AutoUpgradeAgencyAdmin extends PureComponent {
  closeAgencyadminModal = () => {
    this.props.closeModal();
  }

  declineAgencyadminFunc = () => {
    this.props.declineClickHandler();
    this.props.closeModal();
  }

  acceptAgencyadminFunc = () => {
    this.props.acceptClickHandler();
    this.props.closeModal();
  }

  render() {
    const userIsLoggedIn = this.props.userDetail && !this.props.userDetail.expired;
    return (
      <>
        {userIsLoggedIn
          && (
            <Modal
              id="model"
              title={`Agency Admin form ${this.props.userDetail.profile.OrganisationName}`}
              hidden={!this.props.checkRolePromotionResult}
              size="medium"
              onDismiss={this.closeAgencyadminModal}
              className="tp-agency-modal center-screen"
            >
              <div id="admin-login-form">
                <div className="row">
                  There is currently no Agency Administrator assigned for your Travel Agency. The Agency Administrator is the authorized representative of your company
                  and can place orders for products, assign those products to their other agents and manage user access for the rest of your agency.
                </div>
                <div className="row">
                  I
                  {' '}
                  {this.props.userDetail.profile.FirstName}
                  {' '}
                  {this.props.userDetail.profile.LastName}
                  {' '}
                  confirm that am an
                  authorized representative of
                  {' '}
                  {this.props.userDetail.profile.OrganisationName}
                  {' '}
                  and that am entitled to be automatically assigned the Agency Administrator
                  role under the Travelport Marketplace agreement I read, understood and agree to be bound by its terms.
                </div>
                <div className="row">
                  Travelport Marketplace Agreement
                  {' '}
                  <Tooltip labelName="Detailed information on the terms and conditions between Travelport Marketplace and your company." />
                  <textarea className="textarea" readOnly disabled>
                    {`IMPORTANT – READ THIS TRAVELPORT MARKETPLACE AGREEMENT (“Agreement”) CAREFULLY BEFORE
USING THIS SITE:  Your use of the Travelport Marketplace and any associated software, applications, and
other digital products (“Marketplace Apps”) provided by Travelport or an authorized third party
developer (“Developer”), is subject to the terms and conditions of this Agreement, which include the
provisions of the subscriber agreement between your company and Travelport (“Subscriber
Agreement”), the Travelport Privacy Policy at http://www.travelport.com/Privacy-Policy/Travelport-
Websites (“Travelport Privacy Policy”), and the Travelport Terms and Conditions at
http://www.travelport.com/Terms-and-Conditions (“Travelport Terms and Conditions”).  Any conflict in
the terms of these documents will be resolved in the following order of precedence:  Subscriber
Agreement, this Agreement, Travelport Terms and Conditions, and Privacy Policy.
                You may use the Travelport Marketplace and purchase Marketplace Apps only if you are an
authorized representative of your company, your company is a current subscriber to the Travelport GDS
under a valid Subscriber Agreement with Travelport, and your company’s account is in good standing.
For purposes of this Agreement, “you” refers collectively to you and your company.
                BY CLICKING THE “ACCEPT” BUTTON BELOW, YOU REPRESENT THAT YOU MEET THESE CRITERIA
AND AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.  IF YOU DO NOT MEET THESE
CRITERIA OR YOU DO NOT SO AGREE, DO NOT PURCHASE, DOWNLOAD, INSTALL OR USE ANY OF THE
MARKETPLACE APPS.  BY PURCHASING, DOWNLOADING, INSTALLING OR OTHERWISE USING ANY
MARKETPLACE APPS, YOU AGREE TO PAY TRAVELPORT OR THE DEVELOPER, AS APPLICABLE, THE FEES
SET FORTH ON THE TRAVELPORT MARKETPLACE WEBSITE OR OTHERWISE ADVISED TO YOU FOR THOSE
MARKETPLACE APPS.
                1.  MARKETPLACE APP LICENSE.
                     a.  Grant of License.  Provided that you are in material compliance with the terms and
conditions of this Agreement, including but not limited to all payment obligations, Travelport grants to
you a nonexclusive, nontransferable license to use the Marketplace Apps you select in the purchase
process.  Marketplace Apps that are owned by Travelport shall be considered “Products and Services” or
“Services” and Marketplace Apps that are owned by Developers shall be considered “Third Party
Products” as those terms are used in your Subscriber Agreement.
                     b.  Restrictions on Use.  You agree to use the Marketplace Apps only for your own business,
including in conjunction with your affiliates.  Under no circumstances may you permit any third parties
to use the Marketplace Apps.  Use of the Marketplace Apps may be subject to additional terms
depending on the nature of the Marketplace App itself and subject to any Travelport or Developer
specific product terms.
                     c.  Modifications; Reverse Engineering.  You agree that you will not directly or through a third
party alter, maintain, enhance, disassemble, decompile, reverse engineer, create derivative works or
otherwise modify any Marketplace App.
                2.  PAYMENT
                     You agree to pay all fees and taxes applicable to the Marketplace Apps you purchase. Unless
otherwise stated, payment for Marketplace Apps will be made in accordance with your Subscriber
Agreement.  Marketplace Apps may have a free trial period and if so, the free trial period will be
described in the product description on the Travelport Marketplace Website. Following any trial period,
all sales are final and non-refundable unless otherwise specified.  Travelport may offset amounts you
owe under this Agreement from amounts Travelport may owe you under any agreement between you
and Travelport.
                3.  SUPPORT
                     You agree that Travelport has no responsibility or liability with respect to your use of a
Marketplace App or any content or functionality in the Marketplace App.  Travelport or a Developer may
provide help desk support for various Marketplace Apps from time to time and you agree to pay the
separate help desk fee, if applicable.  Any applicable fees and support information will be provided
alongside product information on the Travelport Marketplace Website.  These fees and support
information are hereby incorporated by reference into this Agreement.
                4.  GEOGRAPHIC RESTRICTIONS
                     Nothing in this Agreement permits you to access or use a Marketplace App where such
access or use is prohibited by law or regulation.  Travelport or a Developer may restrict access to the
Travelport Marketplace from time to time.  Usage of some Marketplace Apps may not limited to stated
geographical areas.
                5.  INTELLECTUAL PROPERTY RIGHTS
                     a.  Title.  Marketplace Apps are protected by copyright and other intellectual property laws
and treaties.  Unless otherwise expressly stated, Travelport, the Developers or their licensors own all
title, copyright and other intellectual property rights in the Marketplace Apps.  No title to any
Marketplace App is transferred to you.
                     b.  Transfers.  Under no circumstances may you sell, license, publish, display, distribute, or
otherwise transfer to a third party any Marketplace App or any copy thereof, in whole or in part, without
Travelport’s prior written consent.
                6.  DISCLAIMER OF WARRANTIES
                     THERE ARE NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, FOR ANY
MARKETPLACE APP, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO TITLE OR INFRINGEMENT OF
THIRD-PARTY RIGHTS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.  THE MARKETPLACE
APPS, DOCUMENTATION AND OTHER FILES ARE PROVIDED “AS IS.”  TRAVELPORT AND ITS DEVELOPERS
DO NOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE MARKETPLACE
APPS.  YOU AGREE THAT TRAVELPORT OR A DEVELOPER MAY MODIFY A MARKETPLACE APP AND THAT
SUCH MODIFICATION MAY IMPACT YOUR ABILITY TO ACCESS OR USE THE MARKETPLACE APP.
TRAVELPORT MAY RELEASE NEW DESKTOP VERSIONS OF THE TRAVELPORT GDS THAT DO NOT SUPPORT
MARKETPLACE APPS YOU HAVE DOWNLOADED.  Some jurisdictions do not allow the exclusion of
implied warranties, so the above exclusions may not apply to you if prohibited by applicable law.
                7.  EXCLUSION OF LIABILITY/LIMITATION OF LIABILITY
                     IN NO EVENT WILL TRAVELPORT BE LIABLE TO YOU OR ANY THIRD PARTY FOR DIRECT,
INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER SIMILAR DAMAGES,
INCLUDING LOST PROFITS, ARISING FROM THE USE OF OR INABILITY TO USE ANY MARKETPLACE APP,
EVEN IF TRAVELPORT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  TRAVELPORT IS NOT
RESPONSIBLE FOR ANY DEVELOPERS PROVIDING PRODUCTS OR SERVICES IN THE TRAVELPORT
MARKETPLACE.  IN NO EVENT (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW) WILL
TRAVELPORT’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING OUT OF OR RELATED TO YOUR USE
OR INABILITY TO USE THE MARKETPLACE APPS EXCEED THE LESSER OF (i) THE AMOUNT SPECIFIED IN
YOUR SUBSCRIBER AGREEMENT, OR (ii) THE PURCHASE PRICE OF THE MARKETPLACE APP GIVING RISE
TO THE CLAIM FOR LIABILITY.
                8.  GENERAL PROVISIONS
                     a.  Modifications.  Travelport may alter or revise the terms of this Agreement by giving you
prior notice of the changes to be made.  If you do not agree to accept the changes, your sole remedy
shall be to discontinue your use of the Travelport Marketplace and the Marketplace Apps.
                     b.  Choice of Law and Location for Resolving Disputes.  The choice of law and dispute
resolution provisions in your Subscriber Agreement will control any disputes under this Agreement.
                     c.  Termination.  Unless otherwise terminated as set forth in this Agreement
, this Agreement will terminate as of the termination of your Subscriber Agreement.  Travelport may
terminate this Agreement if you fail to comply with the terms and conditions of this Agreement,
including the provisions of your Subscriber Agreement, the Travelport Privacy Policy, and the Travelport
Terms and Conditions.  Upon any such termination, Travelport may deny access to the Marketplace
Apps and you must discontinue use of the Marketplace Apps.  Travelport may modify, suspend or
discontinue the Travelport Marketplace, in whole or in part, at any time without notice.
                     d.  Severability.  Except as otherwise set forth in this Agreement, the provisions of this
Agreement are severable, and if any one or more such provisions shall be determined to be invalid,
illegal or unenforceable, in whole or in part, the validity, legality and enforceability of any of the
remaining provisions or portions thereof shall not in any way be affected and shall nevertheless be
binding between the parties.  Any such invalid, illegal or unenforceable provision or portion of such
provision shall be changed and interpreted so as to best accomplish the objectives of such provision or
portion thereof within the limits of applicable law.
                     e.  Waiver.  Any waiver, either expressed or implied, by either party of any default by the
other in the observance and performance of any of the conditions and/or covenants of duties set forth
in this Agreement shall not constitute or be construed as a waiver of any subsequent or other default.
                     f.  Read and Understood.  You hereby acknowledge that you have read and understand this
Agreement and agree to be bound by its terms.`}
                  </textarea>
                </div>

                <div className="row space-bottom agency-login-submit-row">
                  <input type="button" onClick={this.acceptAgencyadminFunc} className="green-button" id="admin-login-agree-submit" value="AGREE" />
                  <input type="button" onClick={this.declineAgencyadminFunc} className="blue-button admin-login-cancel space-left small-view-no-space" value="DECLINE" />
                </div>
                <div className="row" id="login-admin-validation-error" style={{ display: 'none' }}>
                  Error while trying to assign Agency administrator role.
                  <br />
                  Please try it again.
                </div>

              </div>
            </Modal>
          )}
      </>
    );
  }
}

AutoUpgradeAgencyAdmin.propTypes = {
  userDetail: PropTypes.object,
  acceptClickHandler: PropTypes.func,
  declineClickHandler: PropTypes.func,
  checkRolePromotionResult: PropTypes.bool,
  closeModal: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    checkRolePromotionResult: state.auth.autoUpgradeAdminReducer.checkRolePromotionResult
  };
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(hideRolePromoteModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoUpgradeAgencyAdmin);
