/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

class DatePickerTime extends Component {
  renderInput = (props, openCalendar) => (
    <span className="input-append date">
      <span className="add-on">
        <input {...props} name={this.props.name} disabled className="text-input" style={{ width: '300px' }} />
&nbsp;
        {!this.props.removeCalender ? <span className="calendar-icon" onClick={openCalendar} /> : null }
      </span>
    </span>
  );

  render() {
    return (
      <Datetime
        value={this.props.value}
        utc
        initialViewDate={this.props.value}
        updateOnView="time"
        renderInput={this.renderInput}
        dateFormat="MM-DD-YYYY"
        timeFormat="hh:mm:ss A"
        onChange={this.props.onChange}
        isValidDate={this.props.isValidDate}
      />
    );
  }
}

DatePickerTime.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isValidDate: PropTypes.func,
  removeCalender: PropTypes.bool
};

export default DatePickerTime;
