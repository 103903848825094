import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import licenseStatusCode from 'constants/licenseStatusCode';
import HandleNotesSection, { handleLicenseCodeUnInstalled } from './CommonParts';

const DataCells = (props) => {
  switch (props.column.title) {
    case 'ACTIVATE':
      return (
        <td className="text-center">
          {props.row.statusType === licenseStatusCode.Activated
            && <div className="prettycheck" />}
        </td>
      );
    case 'DISABLE':
      return (
        <td className="text-center">
          {props.row.statusType === licenseStatusCode.Deactivated
            && <div className="prettycheck" />}
        </td>
      );
    case 'UNINSTALL':
      return (
        <td className="text-center">
          {handleLicenseCodeUnInstalled(props)}
        </td>
      );
    case 'NOTES':
      return (<HandleNotesSection {...props} />);
    default:
      return <Table.Cell {...props} />;
  }
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
};

export default DataCells;
