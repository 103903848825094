import React from 'react';
import PropTypes from 'prop-types';
import {
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Link } from 'react-router-dom';
import dateHelper from '../../../../utils/dateHelper';

const DataCells = (props) => {
  switch (props.column.name) {
    case 'platformFileUpload':
      return (
        <td>
          <Link to={{
            pathname: '/admin/distribution/buildversioncontrol',
            search: `?versionId=${props.row.id}`,
            state: { posversions: props.posversions, productname: props.productname }
          }}
          >
            {props.row.buildText}
          </Link>
        </td>
      );
    case 'dateReleased': {
      return <td>{dateHelper.getDate(props.row.dateReleased)}</td>;
    }
    default:
      return <VirtualTable.Cell {...props} />;
  }
};

DataCells.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  posversions: PropTypes.object,
  productname: PropTypes.string,
};

export default DataCells;
