/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

class CheckBoxInput extends React.PureComponent {
  handleClick = () => {
    this.props.handleChangeCheckBoxExport(this.props.id, !this.props.checked);
  };

  render() {
    return (
      <div className="clearfix prettycheckbox labelright  gray ">
        <input
          type="checkbox"
          className="styled-checkbox category"
          data-label="Agent Productivity Tools"
          data-group="category"
          name="CategoryIds"
          autoComplete="off"
          value={this.props.checked}
          style={{ display: 'none' }}
          onChange={this.props.onToggle}
        />
        <span
          className={this.props.checked ? 'prettySpan checked' : 'prettySpan'}
          onClick={this.handleClick}
        />
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <label onClick={this.handleClick} htmlFor="undefined">{this.props.name}</label>
      </div>
    );
  }
}

CheckBoxInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  handleChangeCheckBoxExport: PropTypes.func,
  onToggle: PropTypes.func,
  checked: PropTypes.bool
};

export default CheckBoxInput;
