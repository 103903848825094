import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-bootstrap4';
import MultiSelectComponent from 'components/common/FormInputs/SyncMultiSelect/MultiSelectComponent';

const EditCell = (props) => {
  if (props.column.name === 'assignedPlatformProducts') {
    const options = props.platformlist.map(r => ({ value: r.value, label: r.text }));
    const selectedRoleIds = props.row.assignedPlatformProducts.map(r => r.value);
    const selectedValues = options.filter(o => selectedRoleIds.indexOf(o.value) !== -1);
    return (
      <td>
        <MultiSelectComponent
          value={selectedValues}
          options={options}
          onValueChange={props.onValueChange}
          showSelectAll
          textDescription="(n) Platform Product(s) Assigned"
          placeholder="Select platforms"
        />
      </td>
    );
  }
  return <Table.Cell {...props} />;
};

EditCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  onValueChange: PropTypes.func,
  platformlist: PropTypes.array
};

export default EditCell;
