/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ActiveToggle = ({ active }) => (
  <>
    OFF&nbsp;
    <Link className="organisationToggleVisibility" to="#" onClick={(e) => e.preventDefault()}><span className={`slider ${active ? 'on' : ''}`} /></Link>
&nbsp;ON
  </>
);

ActiveToggle.propTypes = {
  active: PropTypes.bool,
};

export default ActiveToggle;
