import { takeLatest, call, put } from 'redux-saga/effects';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import fetchEntity from 'reducers/utils/fetchEntitySaga';
import ApiClient from 'Services/ApiClient';
import { showLoader, hideLoader } from 'reducers/popUps/loaderReducer';

const GET_APP_NAME_GUID_MAPPING_URL = 'home/getappnameguidmapping';

const GET_APP_NAME_GUID_MAPPING = createFetchTypes('Marketplace/home/GET_APP_NAME_GUID_MAPPING');

const GET_BANNER_NOTIFICATION = createFetchTypes('Marketplace/home/GET_BANNER_NOTIFICATION');
const GET_SERVER_BUILD_NUMBER = createFetchTypes('Marketplace/home/GET_SERVER_BUILD_NUMBER');

const GET_BANNER_NOTIFICATION_URL = 'banner/getbannernotification';
const GET_SERVER_BUILD_NUMBER_URL = 'home/getbuildnumber';

function homeReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_APP_NAME_GUID_MAPPING.REQUEST:
    case GET_SERVER_BUILD_NUMBER.REQUEST:
    case GET_BANNER_NOTIFICATION.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_APP_NAME_GUID_MAPPING.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        appNameGuidMapping: action.data
      };
    case GET_APP_NAME_GUID_MAPPING.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_BANNER_NOTIFICATION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bannerNotification: action.data
      };
    case GET_BANNER_NOTIFICATION.FAILURE:
    case GET_SERVER_BUILD_NUMBER.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_SERVER_BUILD_NUMBER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        buildNumber: action.data
      };
    default:
      return state;
  }
}

export const getBannerNotification = {
  base: (bannerId) => ({
    type: GET_BANNER_NOTIFICATION.BASE,
    url: GET_BANNER_NOTIFICATION_URL,
    bannerId
  }),
  request: () => ({ type: GET_BANNER_NOTIFICATION.REQUEST }),
  success: (data) => ({ type: GET_BANNER_NOTIFICATION.SUCCESS, data }),
  failure: (error) => ({
    type: GET_BANNER_NOTIFICATION.FAILURE,
    error: error.response && error.response.data ? error.response.data.errorMessage : 'Sorry, some error occured. Please check your input and try again.'
  }),
};

export const getAppNameGuidMapping = {
  base: (applicationGuids) => ({
    type: GET_APP_NAME_GUID_MAPPING.BASE,
    url: GET_APP_NAME_GUID_MAPPING_URL,
    applicationGuids
  }),
  request: () => ({ type: GET_APP_NAME_GUID_MAPPING.REQUEST }),
  success: (data) => ({ type: GET_APP_NAME_GUID_MAPPING.SUCCESS, data }),
  failure: (error) => ({
    type: GET_APP_NAME_GUID_MAPPING.FAILURE,
    error: error.response && error.response.data ? error.response.data.errorMessage : 'Sorry, some error occured. Please check your input and try again.'
  }),
};

export const getBuildNumber = {
  base: () => ({
    type: GET_SERVER_BUILD_NUMBER.BASE,
    url: GET_SERVER_BUILD_NUMBER_URL
  }),
  request: () => ({ type: GET_SERVER_BUILD_NUMBER.REQUEST }),
  success: (data) => ({ type: GET_SERVER_BUILD_NUMBER.SUCCESS, data }),
  failure: (error) => ({
    type: GET_SERVER_BUILD_NUMBER.FAILURE,
    error: error.response && error.response.data ? error.response.data.errorMessage : 'Sorry, some error occured. Please check your input and try again.'
  }),
};

function* getAppNameGuidMappingSaga({ url, applicationGuids }) {
  yield put(showLoader());
  try {
    yield call(() => fetchEntity(
      getAppNameGuidMapping,
      ApiClient.httpClient.post,
      url,
      applicationGuids
    ));
  } catch (error) {
    yield put(getAppNameGuidMapping.failure(error));
  }
  yield put(hideLoader());
}

export function* watchGetAppNameGuidMappingSaga() {
  yield takeLatest(GET_APP_NAME_GUID_MAPPING.BASE, getAppNameGuidMappingSaga);
}

function* getBannerNotificationSaga({ url, bannerId }) {
  yield put(showLoader());
  try {
    const bannerUrl = bannerId ? `${url}?id=${bannerId}` : url;
    const response = yield ApiClient.httpClient.get(bannerUrl);
    if (response.data) {
      yield put(getBannerNotification.success(response.data));
    } else {
      yield put(getBannerNotification.failure(''));
    }
  } catch (error) {
    yield put(getBannerNotification.failure(error));
  }
  yield put(hideLoader());
}

function* getBuidlNumberSaga({ url }) {
  yield put(showLoader());
  try {
    const response = yield ApiClient.httpClient.get(url);
    if (response.data) {
      localStorage.buildNumber = JSON.stringify(response.data);
      yield put(getBuildNumber.success(response.data));
    } else {
      yield put(getBuildNumber.failure(''));
    }
  } catch (error) {
    yield put(getBuildNumber.failure(error));
  }
  yield put(hideLoader());
}

export function* watchGetBuildNumberSaga() {
  yield takeLatest(GET_SERVER_BUILD_NUMBER.BASE, getBuidlNumberSaga);
}

export function* watchGetBannerNotificationSaga() {
  yield takeLatest(GET_BANNER_NOTIFICATION.BASE, getBannerNotificationSaga);
}

export default homeReducer;
