/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@atlas-design-system/react';
import {
  uploadBuild
} from 'reducers/modules/Administration';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import LoadingBar from 'react-redux-loading-bar';
import LoaderComponent from '../../common/Loader/LoaderComponent';
import ToolTip from '../../common/ToolTip';

const TOOLTIP_INSTALLATION_PACKAGE = 'Provide the installation executable files to enable Marketplace to distribute the product to buyers.';
const TOOLTIP_VERSION = 'Assign a product version number in the format x.x.x.x.  The version number must be higher than the previous version.';
const updateTimeValue = 2000;
const maxProgressValue = 95;
const progressIncreaseValue = 5;

class UploadBuild extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      fileName: '',
      fileValid: true,
      version: '',
      versionValid: true,
    };
  }

  handleFile = (e) => {
    const fileUploaded = e.target.files[0] || this.state.file;
    if (fileUploaded) {
      if (fileUploaded.size > 524288000) {
        this.props.showAlertAction('File size should not exceed 500 MB');
      } else {
        this.setState({ file: fileUploaded, fileName: fileUploaded.name });
        this.validationFile(fileUploaded);
      }
    }
  };

  updateVersion = (e) => {
    e.preventDefault();
    this.setState({ version: e.target.value });
    if (e.target.validity.valid) this.setState({ versionValid: true });
    else this.setState({ versionValid: false });
  }

  upload = (e) => {
    const { uploadBuildAction, posVersionId, popUpHide } = this.props;
    e.preventDefault();
    uploadBuildAction({
      file: this.state.file,
      version: this.state.version,
      onSuccess: popUpHide,
      onFail: popUpHide,
      posVersionId,
      autoUpdate: false
    });
  }

  cancel = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  validationFile(fileUploaded) {
    if (fileUploaded.name.slice(-4) === '.exe' || fileUploaded.name.slice(-4) === '.msi' || fileUploaded.name.slice(-4) === '.zip') {
      this.setState({ fileValid: true });
    } else {
      this.setState({ fileValid: false });
    }
  }

  render() {
    const {
      showModal,
      loading,
      uploading,
    } = this.props;
    return (
      <>
        <Modal
          id="sbModal"
          title="Platform Upload"
          hidden={!showModal}
          size="small"
          onDismiss={this.cancel}
        >
          <label htmlFor="files">
            Platform Installation Package  &nbsp;
            <ToolTip labelName={TOOLTIP_INSTALLATION_PACKAGE} />
          </label>
          <div className="horizontal-input-group">
            <div className="text-input-width">
              <input type="text" value={this.state.fileName} className="text-input" id="upload-logo-modal-text-input" onChange={this.handleFile} />
            </div>
            <button type="button" onClick={(e) => e.preventDefault()} className="modal-browse-button">Browse</button>
            <input
              type="file"
              id="upload-logo-modal-file-input"
              onChange={this.handleFile}
              onClick={(e) => e.target.value = null}
              file-model="myFile"
              name="appIcon"
              className="file-input transparent"
            />
          </div>
          <div className="row space-top-double">
            <label htmlFor="version">
              Build #  &nbsp;
              <ToolTip labelName={TOOLTIP_VERSION} />
            </label>
            <input
              type="text"
              className="text-input text-input-lg full-width"
              onChange={this.updateVersion}
              pattern="\d+\.\d+\.\d+\.\d+"
              value={this.state.version}
              placeholder="3.2.0.0"
            />
            <LoadingBar updateTime={updateTimeValue} maxProgress={maxProgressValue} progressIncrease={progressIncreaseValue} className="bar-upload-build" />
            {uploading && <label htmlFor="files">Uploading build, please be patient...</label>}
          </div>
          {!this.state.fileValid
            && (
              <div className="alert alert-danger space-bottom-double text-center ">
                Invalid upload file format
              </div>
            )}
          {!this.state.versionValid
            && (
              <div className="alert alert-danger space-bottom-double text-center ">
                Invalid Build #
              </div>
            )}
          {(loading) && <LoaderComponent show />}
          <div className="text-center">
            <button
              type="button"
              className="action-button no-save gtm-myprofile-image-upload"
              gtm-event-label="Profile Image"
              onClick={this.state.versionValid && this.state.fileValid ? this.upload : null}
            >
              UPLOAD
            </button>
            <button type="button" className="cancel-button space-left no-save" data-dismiss="modal" onClick={this.cancel}>CANCEL</button>
          </div>
          <div className="full-italic">
            <b>Note:</b>
            {' '}
            <p>Users are allowed to upload files with .exe, .msi, .zip extensions only</p>
            <p>The uploaded file size should be within 500MB.</p>
          </div>
        </Modal>
      </>
    );
  }
}

UploadBuild.propTypes = {
  showModal: PropTypes.bool,
  popUpHide: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  uploadBuildAction: PropTypes.func,
  posVersionId: PropTypes.number,
  uploading: PropTypes.bool,
  showAlertAction: PropTypes.func
};

const mapStateToProps = state => ({
  error: state.administration.buildVersionControl.error,
  loading: state.administration.buildVersionControl.loading,
  uploading: state.administration.buildVersionControl.uploading,
  posVersionId: state.administration.buildVersionControl.posVersionId,
});

const mapDispatchToProps = dispatch => ({
  uploadBuildAction: (data) => dispatch(uploadBuild.base(data)),
  showAlertAction: (args) => dispatch(showAlertAction(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadBuild);
