import { put } from 'redux-saga/effects';
import { hideLoader, showLoader } from 'reducers/popUps/loaderReducer';
import ApiClient from 'Services/ApiClient.js';
import createFetchTypes from 'reducers/utils/createFetchTypes';
import { API_GET_ORGANIZATION_LIST } from 'config/apiConfig';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import { ADD_ORGANIZATION } from './addOrganization';

export const GET_ORGANIZATION_LIST = createFetchTypes(
  'Marketplace/Administration/OrganizationDirectory/GET_ORGANIZATION_LIST'
);
const CLEAN_ORGANIZATION_LIST = 'CLEAN_ORGANIZATION_LIST';

export const cleanOrganization = {
  type: CLEAN_ORGANIZATION_LIST
};

export const getOrganizationList = {
  base: params => ({
    type: GET_ORGANIZATION_LIST.BASE,
    params
  }),
  request: () => ({
    type: GET_ORGANIZATION_LIST.REQUEST
  }),
  success: data => ({
    type: GET_ORGANIZATION_LIST.SUCCESS,
    payload: { data }
  }),
  failure: error => ({
    type: GET_ORGANIZATION_LIST.FAILURE,
    payload: {
      error
    }
  })
};

const getOrganizationListReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_ORGANIZATION_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_ORGANIZATION_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data
      };

    case ADD_ORGANIZATION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          organisations: {
            currentPage: action.data.currentPage,
            totalPages: action.data.totalPages,
            pagedCollection: action.data.organisations
          },
          responseMessage: ''
        }
      };

    case GET_ORGANIZATION_LIST.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case CLEAN_ORGANIZATION_LIST:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          organisations: {
            pagedCollection: [
              {
                child: []
              }
            ]
          }
        }
      };

    default:
      return state;
  }
};

export function* getOrganizationReducerWatcher(action) {
  yield put(showLoader());
  try {
    const response = yield ApiClient.httpClient.get(
      API_GET_ORGANIZATION_LIST,
      action
    );
    yield put(getOrganizationList.success(response.data));
    if (response.data.responseMessage) {
      yield put(showAlertAction(response.data.responseMessage));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(getOrganizationList.failure(error));
  }
  yield put(hideLoader());
}

export const getOrganizationListSelector = state => state.administration.organizationDirectory.getOrganizationListReducer.data;

export default getOrganizationListReducer;
