import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AlertMessage extends Component {
  render() {
    if (this.props.showAlert) {
      /* eslint-disable */
      return (
        <div className="alert alert-success alert-style fade in alert-front" style={this.props.style}>
          <span dangerouslySetInnerHTML={{
            __html: this.props.message
          }}
          />
        </div>
      );
      /* eslint-enable */
    }
    return null;
  }
}

AlertMessage.propTypes = {
  showAlert: PropTypes.bool,
  timeout: PropTypes.number,
  message: PropTypes.string
};

const mapStateToProps = (state) => ({
  showAlert: state.alertPopUp.showAlert,
  message: state.alertPopUp.message,
});

export default connect(mapStateToProps)(AlertMessage);
