/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '@atlas-design-system/react';
import {
  getAdminsForOrgs,
  addAdminsForOrgs,
  getAdminsForOrgsSelector,
  unassignAdminRoleForUser
} from 'reducers/modules/Administration/OrganizationDirectory/ManageAdmins/getAdminsForOrg';
import ManageAdminsForm from './ManageAdminsForm';

class ManageAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.props.getAdminsForOrgs({ orgId: this.props.organization.id });
    this.setState({ show: true });
  };

  render() {
    return (
      <div>
        <a onClick={this.handleShow}>Manage admins</a>

        <Modal
          title={this.props.organization.name}
          hidden={!this.state.show}
          size="medium"
          onDismiss={this.handleClose}
          className="atlas-tp-modal-manage-admin center-screen"
        >
          <div>
            <ManageAdminsForm
              adminsForOrgs={this.props.adminsForOrgs}
              saveAdmin={this.props.addAdminsForOrgs}
              unassignAdminRoleForUser={this.props.unassignAdminRoleForUser}
              orgId={this.props.organization.id}
              onClose={this.handleClose}
              organizationName={this.props.organization.name}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminsForOrgs: getAdminsForOrgsSelector(state),
  showDeleteConfirmation: state.confirmationModal.showConfirmationModal
});

const mapDispatchToProps = dispatch => ({
  getAdminsForOrgs: params => dispatch(getAdminsForOrgs.base(params)),
  addAdminsForOrgs: params => dispatch(addAdminsForOrgs.base(params)),
  unassignAdminRoleForUser: params => dispatch(unassignAdminRoleForUser.base(params))
});

ManageAdmins.propTypes = {
  organization: PropTypes.object,
  getAdminsForOrgs: PropTypes.func,
  addAdminsForOrgs: PropTypes.func,
  unassignAdminRoleForUser: PropTypes.func,
  adminsForOrgs: PropTypes.object,
  item: PropTypes.object,
  showDeleteConfirmation: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdmins);
