/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';

class ConfDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showModal: this.props.showModal
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.showModal
    });
  }

  hideModal = () => {
    this.setState({
      showModal: false
    });

    if (this.props.onHideModal) {
      this.props.onHideModal();
    }
  }

  render() {
    return (
      <>
        <Dialog
          appearance="warning"
          id="sbDialog"
          hidden={!this.state.showModal}
          buttons={
            (
              <>
                <Button
                  className="action-button"
                  appearance="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onDelete();
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="cancel-button space-left"
                  onClick={(e) => {
                    e.preventDefault();
                    this.hideModal();
                  }}
                >
                  No
                </Button>
              </>
            )
          }
        >
          {this.props.text}
        </Dialog>
      </>
    );
  }
}

ConfDeleteModal.propTypes = {
  text: PropTypes.string,
  onDelete: PropTypes.func,
  onHideModal: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
};

export default ConfDeleteModal;
