/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  TableHeaderRow,
  Table,
  TableEditColumn,
  TableEditRow
} from '@devexpress/dx-react-grid-bootstrap4';
import { SortingState, EditingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import LoaderComponent from 'components/common/Loader/LoaderComponent';
import SimplePrettyCheckBox from 'components/common/FormInputs/SimplePrettyCheckBox/SimplePrettyCheckBox';
import {
  getHosts, findHostUsers, addHostUsers, deleteHostUsers
} from 'reducers/modules/Administration/ManageHostUsers/manageHostUsersReducer';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import { manageHostUsers } from 'reducers/modules/Administration/ManageHostUsers/selectors';

class ManageHostUsers extends Component {
  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };
    if (newState.params.host === '' && props.hostLists.length > 0) {
      newState.params.host = props.hostLists[0].value;
      return newState;
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.sortingStateColumnExtensions = [
      { columnName: 'action', sortingEnabled: false },
    ];
    this.state = {
      params: {
        host: '',
        pcc: '',
        signon: '',
        firstName: '',
        lastName: '',
        isTravelportEmployee: false,
        isNdcUser: false,
        isMTypeUser: false,
        sortByLastUpdated: false
      },
      sorting: {
        columnName: '',
        direction: 'asc',
      },
    };

    this.columnDefinitions = [
      { name: 'agencyName', title: 'AGENCY NAME' },
      { name: 'customerNumber', title: 'CUSTOMER NO.' },
      { name: 'masterCustomerNumber', title: 'MASTER CUSTOMER NO.' },
      { name: 'name', title: 'NAME' },
      { name: 'pcc', title: 'PCC' },
      { name: 'host', title: 'HOST' },
      { name: 'signOn', title: 'SIGN ON' }
    ];
  }

  componentDidMount() {
    document.title = 'Manage Host Users';
    this.props.getHosts();
  }

  commitChanges = ({ deleted }) => {
    const { userName } = this.props.hostUsers[deleted[0]];
    this.deleteUser(userName, this.successHandler);
  }

  handleEditRow = (rows) => {
    const row = rows.slice(-1);
    this.setState({ editingRowIds: row });
  };

  command = ({ id, onExecute }) => {
    const commandComponentProps = {
      delete: {
        text: 'Delete',
        hint: 'Delete row',
      }
    };
    return (
      <Link
        to="#"
        className="pad-sm"
        onClick={(e) => {
          e.preventDefault();
          onExecute();
        }}
      >
        {commandComponentProps[id].text}
      </Link>
    );
  }

  changeHandler = (value, field) => {
    const params = { ...this.state.params };
    params[field] = value;
    this.setState({
      params
    });
  }

  checkboxHandler = (value, field) => {
    const params = { ...this.state.params };
    params[field] = value;
    if (field === 'isNdcUser') {
      params.isTravelportEmployee = false;
      params.isMTypeUser = false;
      params.pcc = '';
    } else if (field === 'isTravelportEmployee') {
      params.isNdcUser = false;
      params.isMTypeUser = false;
      params.pcc = '';
    } else if (field === 'isMTypeUser') {
      params.isNdcUser = false;
      params.isTravelportEmployee = false;
      params.pcc = '';
    }
    this.setState({
      params
    });
  }

  findUser = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (this.state.params.pcc.length === 0 && this.state.params.signon.length === 0
      && !((this.state.params.isTravelportEmployee || this.state.params.isNdcUser
        || this.state.params.isMTypeUser) && (this.state.params.firstName !== ''
        || this.state.params.lastName !== ''))) {
      this.props.showAlertAction('Please enter pcc or the signon to be searched');
      return;
    }
    if (!this.validateInput()) {
      return;
    }
    this.props.findHostUsers(this.state.params);
  }

  successHandler = () => {
    this.findUser();
  }

  addUser = (e) => {
    e.preventDefault();
    if (!this.state.params.signon) {
      this.props.showAlertAction('Please enter the signon to add a user');
      return;
    }
    if (!this.state.params.pcc && !this.state.params.isTravelportEmployee && !this.state.params.isNdcUser && !this.state.params.isMTypeUser) {
      this.props.showAlertAction('Please enter a PCC');
      return;
    }
    if (!this.state.params.firstName || !this.state.params.lastName) {
      this.props.showAlertAction('Please enter both first and last names');
      return;
    }
    if (!this.validateInput()) {
      return;
    }

    const stateParams = this.state.params;
    const params = {
      host: stateParams.host,
      pcc: stateParams.pcc,
      signon: stateParams.signon,
      isemployee: stateParams.isTravelportEmployee,
      firstName: stateParams.firstName,
      lastName: stateParams.lastName
    };
    this.props.addHostUsers(params, this.successHandler);
  }

  deleteUser = (userName, successHandler) => {
    this.props.deleteHostUsers({ userName }, successHandler);
  }

  enableSearchButton = () => {
    if ((this.state.params.pcc || this.state.params.signon)
    || ((this.state.params.isTravelportEmployee || this.state.params.isNdcUser
      || this.state.params.isMTypeUser) && (this.state.params.firstName
        || this.state.params.lastName))) {
      return true;
    }
    return false;
  }

  enableAddButton = () => {
    if (this.state.params.signon && (this.state.params.isTravelportEmployee || this.state.params.isNdcUser || this.state.params.isMTypeUser || this.state.params.pcc)) {
      return true;
    }
    return false;
  }

  validateInput = () => {
    const regex = new RegExp('^[a-z0-9]*$', 'i');
    const ndcSignonregex = new RegExp('^[n][a-z0-9]*$', 'i');
    const mTypeSignonregex = new RegExp('^[m][a-z0-9]*$', 'i');
    if (this.state.params.pcc !== '' && !regex.test(this.state.params.pcc)) {
      this.props.showAlertAction('Please enter a valid PCC');
      return false;
    }

    if (this.state.params.signon) {
      if (!this.state.params.isNdcUser && !this.state.params.isMTypeUser && !regex.test(this.state.params.signon)) {
        this.props.showAlertAction('Please enter a valid SignOn');
        return false;
      }
      if (this.state.params.isNdcUser && !ndcSignonregex.test(this.state.params.signon)) {
        this.props.showAlertAction('Please enter a valid NDC SignOn');
        return false;
      }
      if (this.state.params.isMTypeUser && !mTypeSignonregex.test(this.state.params.signon)) {
        this.props.showAlertAction('Please enter a valid M-Type SignOn');
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      hostLists, hostUsers, loading, noResultFound
    } = this.props;
    if (loading) {
      return <LoaderComponent show />;
    }
    return (
      <>
        <div className="container" id="agencyOrgList">
          <form>
            <div className="row">
              <div className="col-sm-2 col-sm-offset-1">
                <div className="row no-space">
                  <h4>Host</h4>
                </div>
                <div className="row no-space">
                  <select className="select col-sm-12" id="host" name="host" value={this.state.params.host} onChange={(e) => this.changeHandler(e.target.value, 'host')}>
                    {hostLists.map((v, i) => <option key={i.toString()} value={v.value}>{v.text}</option>)}
                  </select>
                </div>
              </div>

              <div className="col-sm-2 space-left-double">
                <div className="row no-space">
                  <h4>PCC</h4>
                </div>
                <div className="row no-space">
                  <input
                    type="text"
                    id="pcc"
                    className={this.state.params.isNdcUser
                    || this.state.params.isMTypeUser || this.state.params.isTravelportEmployee
                      ? 'disabled-control text-input' : 'text-input'}
                    disabled={this.state.params.isNdcUser || this.state.params.isMTypeUser || this.state.params.isTravelportEmployee}
                    value={this.state.params.pcc}
                    onChange={(e) => this.changeHandler(e.target.value, 'pcc')}
                    name="pcc"
                    maxLength="4"
                  />
                </div>
              </div>

              <div className="col-sm-2  space-left-double">
                <div className="row no-space">
                  <h4>Sign On</h4>
                </div>
                <div className="row no-space">
                  <input
                    type="text"
                    id="signon"
                    value={this.state.params.signon}
                    onChange={(e) => this.changeHandler(e.target.value, 'signon')}
                    className="text-input ng-pristine ng-valid"
                    name="signon"
                    maxLength="9"
                  />
                </div>
              </div>

              <div className="col-sm-1" />
              <div className="col-sm-1">
                <div className="row">&nbsp;</div>
                <div className="row no-space">
                  <Link
                    id="find-user-link"
                    to="#"
                    className={this.enableSearchButton()
                      ? 'action-button' : 'disable-button'}
                    onClick={this.findUser}
                  >
&nbsp;&nbsp;Search&nbsp;&nbsp;
                  </Link>
                </div>
              </div>
            </div>
            <div className="row space-bottom-double">
              <div className="col-sm-2  col-sm-offset-1">
                <div className="row no-space">
                  <h4>First Name</h4>
                </div>
                <div className="row no-space">
                  <input
                    type="text"
                    id="fName"
                    value={this.state.params.firstName}
                    onChange={(e) => this.changeHandler(e.target.value, 'firstName')}
                    className="text-input ng-pristine ng-valid"
                    name="fName"
                  />
                </div>
              </div>
              <div className="col-sm-2 space-left-double">
                <div className="row no-space">
                  <h4>Last Name</h4>
                </div>
                <div className="row no-space">
                  <input
                    type="text"
                    id="lName"
                    value={this.state.params.lastName}
                    onChange={(e) => this.changeHandler(e.target.value, 'lastName')}
                    className="text-input ng-pristine ng-valid"
                    name="lName"
                    ng-model="lName"
                  />
                </div>
              </div>
              <div className="col-sm-3 space-left-double">
                <div className="row no-space">
                  <SimplePrettyCheckBox
                    displayText="Travelport Employee"
                    value={this.state.params.isTravelportEmployee}
                    onChange={(checked) => this.checkboxHandler(checked, 'isTravelportEmployee')}
                  />
                </div>
                <div className="row no-space">
                  <SimplePrettyCheckBox displayText="NDC User" value={this.state.params.isNdcUser} onChange={(checked) => this.checkboxHandler(checked, 'isNdcUser')} />
                </div>
                <div className="row no-space">
                  <SimplePrettyCheckBox
                    displayText="Travelport Training User (M - Type)"
                    value={this.state.params.isMTypeUser}
                    onChange={(checked) => this.checkboxHandler(checked, 'isMTypeUser')}
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="row">&nbsp;</div>
                <div className="row no-space">
                  <Link id="add-user-link" to="#" onClick={this.addUser} className={this.enableAddButton() ? 'action-button' : 'disable-button'}>Add User</Link>
                </div>
              </div>
            </div>
            <div className="row">
              {hostUsers.length > 0 ? (
                <div className="travelport-table tablesorter tablesorter-travelport tablesorter-odd-even">
                  <Grid columns={this.columnDefinitions} rows={hostUsers}>
                    <EditingState
                      onCommitChanges={this.commitChanges}
                    />
                    <SortingState
                      sorting={this.sorting}
                      columnExtensions={this.sortingStateColumnExtensions}
                    />
                    <IntegratedSorting />
                    <Table />
                    <TableHeaderRow showSortingControls />
                    <TableEditColumn
                      showDeleteCommand
                      commandComponent={this.command}
                    />
                    <TableEditRow />
                  </Grid>
                </div>
              ) : null}
              {noResultFound ? 'No results returned' : null}
            </div>
          </form>
        </div>
      </>
    );
  }
}

ManageHostUsers.propTypes = {
  getHosts: PropTypes.func,
  hostLists: PropTypes.array,
  loading: PropTypes.bool,
  findHostUsers: PropTypes.func,
  addHostUsers: PropTypes.func,
  deleteHostUsers: PropTypes.func,
  hostUsers: PropTypes.array,
  showAlertAction: PropTypes.func,
  noResultFound: PropTypes.bool
};

const mapStateToProps = state => ({
  hostLists: manageHostUsers(state).hosts,
  hostUsers: manageHostUsers(state).hostUsers,
  noResultFound: manageHostUsers(state).noResultFound
});

const mapDispatchToProps = dispatch => ({
  getHosts: () => dispatch(getHosts.base()),
  findHostUsers: (args) => dispatch(findHostUsers.base(args)),
  addHostUsers: (data, handler) => dispatch(addHostUsers.base(data, handler)),
  deleteHostUsers: (data, handler) => dispatch(deleteHostUsers.base(data, handler)),
  showAlertAction: (args) => dispatch(showAlertAction(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageHostUsers);
